import React, {useEffect, useState} from 'react'

import {Col, Row} from 'antd'
import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'

import {
  StyledTabPane,
  StyledTabs,
  StyledTabTitle,
  StyledTabTitleContainer
} from '../../../../shared/styles/BasicStyles'
import ExchangeVouchers from './components/ExchangeVouchers'
import Vouchers from './components/Vouchers'

const VouchersTab = ({router}) => (
  <>
    <Row>
      <Col xs={24}>
        <StyledTabs destroyInactiveTabPane>
          <StyledTabPane
            tab={
              <StyledTabTitleContainer>
                <StyledTabTitle smaller>
                  <Translate id='VOUCHER' />
                </StyledTabTitle>
              </StyledTabTitleContainer>
            }
            key='voucher'
          >
            <Vouchers router={router} />
          </StyledTabPane>
          <StyledTabPane
            tab={
              <StyledTabTitleContainer>
                <StyledTabTitle smaller>
                  <Translate id='EXCHANGE_VOUCHERS' />
                </StyledTabTitle>
              </StyledTabTitleContainer>
            }
            key='exchange'
          >
            <ExchangeVouchers router={router} />
          </StyledTabPane>
        </StyledTabs>
      </Col>
    </Row>
  </>
)

VouchersTab.propTypes = {
  router: PropTypes.object.isRequired
}

export default VouchersTab
