import React from 'react'

import {PropTypes} from 'prop-types'

import {TableTextColor} from '../../styles/_colors'
import {BodyH3} from '../../styles/BasicStyles'
import {
  InputBox,
  InputLabel,
  ProductPriceInputBox,
  ProductPriceInputStyled
} from './InputStyles'

const DescriptiveTextInput = ({
  input,
  label,
  meta,
  placeholder,
  afterChange,
  disabled,
  descriptiveText
}) => {
  const handleChange = (event) => {
    input.onChange(event.target.value)
    if (typeof afterChange === 'function') {
      afterChange(event.target.value)
    }
  }

  const hasError = meta.invalid && meta.submitFailed

  return (
    <InputBox>
      {label && <InputLabel>{label}</InputLabel>}
      <ProductPriceInputBox disabled={disabled} $hasError={hasError}>
        <ProductPriceInputStyled
          type='text'
          placeholder={placeholder}
          value={input.value}
          onChange={handleChange}
          disabled={disabled}
        />
        {descriptiveText && (
          <BodyH3 $color={disabled ? 'white' : TableTextColor}>
            {descriptiveText}
          </BodyH3>
        )}
      </ProductPriceInputBox>
    </InputBox>
  )
}

DescriptiveTextInput.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  placeholder: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  afterChange: PropTypes.func,
  disabled: PropTypes.bool,
  descriptiveText: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ])
}

DescriptiveTextInput.defaultProps = {
  input: {value: undefined, onChange: () => null},
  label: undefined,
  placeholder: undefined,
  meta: {},
  afterChange: undefined,
  disabled: false,
  descriptiveText: undefined
}

export default DescriptiveTextInput
