import React from 'react'

import {message} from 'antd'
import {PropTypes} from 'prop-types'
import {Translate} from 'react-localize-redux'

import BaseButton from '../buttons/BaseButton'
import {
  AlertContainer,
  AlertBar,
  CloseIcon,
  AlertTitle,
  AlertDescription,
  AlertActions
} from './BaseAlertStyles'

const BaseAlert = ({title, description, type, callback, onCancel}) => (
  <AlertContainer>
    <AlertBar type={type} />
    <CloseIcon
      role='button'
      onClick={() => {
        message.destroy()
        if (typeof onCancel === 'function') onCancel()
      }}
    />
    <AlertTitle>{title}</AlertTitle>
    <AlertDescription>{description}</AlertDescription>
    {callback && (
      <AlertActions>
        <BaseButton
          auto
          type='secondary'
          onClick={() => {
            message.destroy()
            if (typeof onCancel === 'function') onCancel()
          }}
        >
          No. Cancel
        </BaseButton>
        <BaseButton
          auto
          type='primary'
          onClick={() => {
            message.destroy()
            if (typeof callback === 'function') callback()
          }}
        >
          Yes. Confirm
        </BaseButton>
      </AlertActions>
    )}
  </AlertContainer>
)

BaseAlert.propTypes = {
  title: PropTypes.string,
  type: PropTypes.oneOf(['success', 'error', 'warning']),
  callback: PropTypes.func,
  onCancel: PropTypes.func,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.elementType
  ]).isRequired
}

BaseAlert.defaultProps = {
  title: <Translate id='SUCCESS' />,
  type: 'success',
  callback: undefined,
  onCancel: undefined
}

export default BaseAlert
