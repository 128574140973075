import React, {Component} from 'react'

import {PropTypes} from 'prop-types'
import {Translate} from 'react-localize-redux'
import ArrowLeft from '../../../assets/icons/arrow_left.svg'
import ArrowRight from '../../../assets/icons/arrow_right.svg'
import {
  Measure,
  SliderContainer,
  SliderBox,
  ArrowsImage
} from './BaseSliderStyles'

class BaseSlider extends Component {
  changeSelected = (ind) => {
    const {setSelected} = this.props
    setSelected(ind)
  }

  calculateSlide = () => {
    const {slides, selected} = this.props
    const ind = slides.findIndex((x) => x.productMeasureId === selected)

    if (ind - 3 < 0) {
      return 0
    }

    return ind - 3
  }

  render() {
    const {slides, selected} = this.props

    return (
      <SliderContainer>
        <ArrowsImage
          src={ArrowLeft}
          alt={<Translate id='PREV_IMG' />}
          $left
          onClick={() => this.slide.prev()}
        />
        <SliderBox
          ref={(elem) => {
            this.slide = elem
          }}
          slidesToShow={4}
          infinite={false}
          initialSlide={this.calculateSlide()}
          speed={1000}
          dots={false}
        >
          {slides?.length > 0 &&
            slides.map((item) => (
              <Measure
                key={item.productMeasureId}
                onClick={() => this.changeSelected(item.productMeasureId)}
                $selected={item.productMeasureId === selected}
              >
                {item?.size?.number}
              </Measure>
            ))}
        </SliderBox>
        <ArrowsImage
          src={ArrowRight}
          alt={<Translate id='NEXT_IMG' />}
          $right
          onClick={() => this.slide.next()}
        />
      </SliderContainer>
    )
  }
}

BaseSlider.propTypes = {
  slides: PropTypes.array,
  selected: PropTypes.number,
  setSelected: PropTypes.func.isRequired
}

BaseSlider.defaultProps = {
  slides: [],
  selected: undefined
}

export default BaseSlider
