import React, {useEffect, useState} from 'react'

import {CheckOutlined} from '@ant-design/icons'
import {Col, Row} from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import {withLocalize, Translate} from 'react-localize-redux'
import {useParams} from 'react-router'

import {
  GetAppointment,
  UpdateEmployeeProducts,
  ConcludeAppointment
} from '../../../infra/requests/FittingRequests'
import AlertService from '../../../shared/components/alert/AlertService'
import BackButton from '../../../shared/components/buttons/BackButton'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import SelectInput from '../../../shared/components/inputs/SelectInput'
import BaseLoading from '../../../shared/components/loading/BaseLoading'
import GetCoverImage from '../../../shared/logic/arrays/ImageCoverFunction'
import ActiveTranslation from '../../../shared/logic/translations/ActiveTranslation'
import {PrimaryColor, SuccessColor} from '../../../shared/styles/_colors'
import {SemiBoldFontWeight} from '../../../shared/styles/_texts'
import {
  BodyH5,
  ContentContainer,
  Margin,
  TitleH5,
  TitleH6
} from '../../../shared/styles/BasicStyles'
import {
  ProductDescription,
  ProductImage
} from '../../orders/manage_b2b_b2b2c/components/Styles'
import {HoverImageButton} from '../../products/ProductsStyles'
import {AppointmentBox, FloatButton, ProductQuantity} from '../FittingStyles'
import ManageFittingPage from './ManageFittingPage'

const CalculateMaxQuantity = (qty) => {
  const result = [{id: 0, name: 0}]
  for (let i = 1; i <= qty; i++) {
    result.push({id: i, name: i})
  }
  return result
}

const AppointmentPage = ({router, translate}) => {
  const [freeDotation, setFreeDotation] = useState(false)
  const [saveDotation, setSaveDotation] = useState(false)
  const [loading, setLoading] = useState(true)
  const [info, setInfo] = useState()
  const [product, setProduct] = useState(undefined)
  const [employeeId, setEmployeeId] = useState()
  const {id} = useParams()

  const initAppointment = async () => {
    if (id !== 'new') {
      const {data, success} = await GetAppointment(id)
      if (success) {
        const fitting = {
          ...data,
          employee: {
            ...data.employee,
            employeeDotation: data.employee.employeeDotation.map((d) =>
              ({...d, dotation: d.product?.employeeProduct?.dotation}))
          }
        }
        setInfo(fitting)
        setFreeDotation(data?.employee?.position?.freeDotationChoice)
        setEmployeeId(data?.employee?.employeeId)
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    initAppointment()
  }, [])

  const GetMeasures = (measures, products) => {
    let measure = <Translate id='NA' />
    if (products.isUniqueSize === true) measure = <Translate id='UN' />
    if (info?.employee?.employeeFitting?.length > 0 && measures) {
      measures.forEach((element) => {
        const exist = info.employee.employeeFitting.find(
          (x) => x.fitting.productMeasureId === element.productMeasureId
        )
        if (exist) measure = element
      })
      return measure
    }
    return measure
  }

  const GetChecked = (measures, products) => {
    let check = false
    if (products.isUniqueSize === true) check = true
    if (info?.employee?.employeeFitting?.length > 0) {
      measures.forEach((element) => {
        const exist = info.employee.employeeFitting.find(
          (x) => x.fitting.productMeasureId === element.productMeasureId
        )
        if (exist) check = true
      })
      return check
    }
    return check
  }

  const completeAppointment = async () => {
    const {success} = await ConcludeAppointment(id)
    if (success) router.history.push('/fitting#calendar')
  }

  const ShowPopup = () =>
    AlertService.confirm(
      translate('COMPLETE_APPOINTMENT'),
      translate('COMPLETE_APPOINTMENT_CONFIRMATION_MESSAGE'),
      completeAppointment
    )

  const handleDotationChange = (index, qty) => {
    const newInfo = {...info}
    newInfo.employee.employeeDotation[index].dotation = qty
    setInfo(newInfo)
  }

  const handleCompleteDotation = async () => {
    let valid = true
    let firstProd
    info.employee.employeeDotation.forEach((item) => {
      if (!item.dotation && item.dotation !== 0) {
        valid = false
      } else if (!firstProd && !item?.product?.isUniqueSize) firstProd = item.product
    })
    if (valid) {
      setSaveDotation(true)
      await UpdateEmployeeProducts({
        employeeId: info?.employee?.employeeId,
        items: info.employee.employeeDotation.map((item) => ({
          productId: item.product.productId,
          quantity: item.dotation
        }))
      })
      setProduct(firstProd)
      setSaveDotation(false)
    } else {
      AlertService.error(
        translate('APPOINTMENT_INCOMPLETE_DOTATION'),
        translate('APPOINTMENT_INCOMPLETE_DOTATION_MESSAGE')
      )
    }
  }

  if (loading) return <BaseLoading margin='200' />

  if (product) {
    return (
      <ManageFittingPage
        router={router}
        fitting={info?.employee?.employeeFitting}
        measures={info?.employee?.employeeMeasure}
        product={product}
        setProduct={setProduct}
        employeeId={employeeId}
        info={info}
      />
    )
  }

  return (
    <ContentContainer>
      <Row>
        <Col xs={12}>
          <BackButton
            label={<Translate id='BACK_TO_CALENDAR' />}
            onClick={() => router.history.push('/fitting#calendar')}
          />
        </Col>
        <Col xs={12}>
          {!info?.concluded && (
            <FloatButton>
              <BaseButton auto type='primary' onClick={ShowPopup}>
                <Translate id='COMPLETE_APPOINTMENT' />
              </BaseButton>
            </FloatButton>
          )}
        </Col>
      </Row>
      <Margin size={29} />
      <Row>
        <AppointmentBox>
          <div style={{display: 'flex'}}>
            <TitleH6>
              <Translate id='EMPLOYEE' />
              :&nbsp;
            </TitleH6>
            <BodyH5>
              {info?.employee?.firstName} {info?.employee?.lastName}
            </BodyH5>
          </div>
          <div style={{display: 'flex'}}>
            <TitleH6>
              <Translate id='POSITION' />
              :&nbsp;
            </TitleH6>
            <BodyH5>{info?.employee?.position?.name}</BodyH5>
          </div>
          <div style={{display: 'flex'}}>
            <TitleH6>
              <Translate id='ITEMS_OUTFIT' />
              :&nbsp;
            </TitleH6>
            <BodyH5>{info?.employee?.totalItems}</BodyH5>
          </div>
          <div style={{display: 'flex'}}>
            <TitleH6>
              <Translate id='LAST_FITTING' />
              :&nbsp;
            </TitleH6>
            <BodyH5>
              {moment(info?.lastFitting).format('DD-MM-YYYY')}
            </BodyH5>
          </div>
        </AppointmentBox>
      </Row>
      <Margin size={20} />
      {freeDotation && (
        <BaseButton
          auto
          type='primary'
          onClick={handleCompleteDotation}
          loading={saveDotation}
        >
          <Translate id='DEFINE_DOTATION' />
        </BaseButton>
      )}
      <Margin size={20} />
      <Row gutter={[24, 40]}>
        {info?.employee?.employeeDotation?.length > 0 &&
          info.employee.employeeDotation.map((item, index) => {
            const fitting = GetMeasures(
              item?.product?.productMeasure,
              item?.product
            )
            const checked = GetChecked(
              item?.product?.productMeasure,
              item?.product
            )

            const clickable = !info?.concluded && !freeDotation && !item?.product?.isUniqueSize

            return (
              <Col
                xs={6}
                key={index}
                style={{cursor: clickable ? 'pointer' : 'default'}}
                onClick={() => clickable && setProduct(item.product)}
              >
                <ProductImage
                  src={GetCoverImage(item?.product?.productImage)}
                >
                  {checked && (
                    <HoverImageButton
                      $top='7px'
                      $right='20px'
                      $color={SuccessColor}
                    >
                      <CheckOutlined />
                    </HoverImageButton>
                  )}
                </ProductImage>
                <Margin size={10} />
                <TitleH5>
                  <ActiveTranslation
                    value={
                      item.product.productTranslation || (
                        <Translate id='NA' />
                      )
                    }
                    tag='name'
                  />
                </TitleH5>
                <ProductDescription
                  $color={PrimaryColor}
                  $weight={SemiBoldFontWeight}
                >
                  {fitting?.size?.number ? fitting.size.number : fitting}
                </ProductDescription>
                {freeDotation && (
                  <ProductQuantity>
                    <SelectInput
                      input={{value: item.dotation, onChange: (qty) => handleDotationChange(index, qty)}}
                      label='Quantity'
                      data={CalculateMaxQuantity(item.quantity)}
                      allowClear={false}
                      placeholder={<Translate id='DEFINE_DOTATION_QUANTITY' />}
                    />
                  </ProductQuantity>
                )}
              </Col>
            )
          })}
      </Row>
    </ContentContainer>
  )
}

AppointmentPage.propTypes = {
  translate: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired
}

export default withLocalize(AppointmentPage)
