import React, {useEffect, useState} from 'react'

import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {reduxForm, initialize, FieldArray, Field} from 'redux-form'

import {
  GetShippingRates,
  SaveShippingRates
} from '../../../infra/requests/LogisticsRequests'
import AlertService from '../../../shared/components/alert/AlertService'
import BackButton from '../../../shared/components/buttons/BackButton'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import TextInput from '../../../shared/components/inputs/TextInput'
import BaseLoading from '../../../shared/components/loading/BaseLoading'
import {
  Margin,
  PageForm,
  PageTitle
} from '../../../shared/styles/BasicStyles'
import ShippingRatesTable from './components/ShippingRatesTable'

const ShippingRates = ({router, dispatch, handleSubmit, translate}) => {
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    async function init() {
      const {data} = await GetShippingRates()
      dispatch(
        initialize('shipping_rate_form', {
          ...data,
          carrierRate: data?.carrierRate?.items
        })
      )
      setLoading(false)
    }
    init()
  }, [])

  const onSubmit = async (values) => {
    setSaving(true)
    const {success} = await SaveShippingRates(values)
    if (success) {
      setSaving(false)
      AlertService.success(translate('SHIPPING_RATES_CHANGED'), translate('SHIPPING_RATES_SUCCESSFULLY_SAVED')
      )
      router.history.push('/logistics#config')
    }
  }

  if (loading) return <BaseLoading margin='200' />

  return (
    <PageForm onSubmit={handleSubmit(onSubmit)} noValidate>
      <Row align='bottom' gutter={[0, 50]}>
        <Col xs={24}>
          <BackButton
            label={<Translate id='BACK' />}
            onClick={() => router.history.push('/logistics#config')}
          />
        </Col>
        <Col xs={12}>
          <PageTitle><Translate id='SHIPPING_RATES' /></PageTitle>
        </Col>

        <Col xs={12} align='end'>
          <BaseButton
            htmlType='submit'
            type='primary'
            auto
            loading={saving}
            disabled={saving}
          >
            <Translate id='SAVE' />
          </BaseButton>
        </Col>
      </Row>
      <Margin size={40} />
      <Row gutter={[16, 24]}>
        <Col xs={6}>
          <Field
            label={<Translate id='SHIPPING_THRESHOLD' />}
            name='orderShippingThreshold.value'
            component={TextInput}
          />
        </Col>
        <Col xs={6}>
          <Field
            label={<Translate id='RETURN_EXCHANGE_THRESHOLD' />}
            name='productReturnExchangeFreeThreshold.value'
            component={TextInput}
          />
        </Col>
      </Row>
      <Margin size={40} />
      <FieldArray name='carrierRate' component={ShippingRatesTable} />
    </PageForm>
  )
}

ShippingRates.propTypes = {
  router: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
}

const ShippingRatesPage = withLocalize(reduxForm({
  form: 'shipping_rate_form'
})(ShippingRates))

export default ShippingRatesPage
