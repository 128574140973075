export default (list, carriers, service, notifications) => {
  const errors = []
  const shipments = []

  list.forEach((element) => {
    const selectedCarrier = carriers.find((x) => x.carrierID == element.carrierId)
    const hasIntegration = selectedCarrier?.hasIntegration

    if (!element.carrierId || element.carrierId === '') {
      errors.push('SHIPPING_MISSING_CARRIER')
      return
    }
    if (!element.contents || element.contents === '') {
      errors.push('SHIPPING_MISSING_SHIPPING_CONTENTS')
    }
    if (!element.shippingValue || element.shippingValue === '') {
      errors.push('SHIPPING_MISSING_SHIPPING_VALUE')
    }
    if (!element.incoterms || element.incoterms === '') {
      errors.push('SHIPPING_MISSING_INCOTERMS')
    }
    if (!element.shippingDate || element.shippingDate === '') {
      errors.push('SHIPPING_MISSING_DATE')
    }

    if (hasIntegration) {
      if (!service || !service[0] || !service[0].record) {
        if (selectedCarrier.carrierID != null) {
          errors.push('SHIPPING_MISSING_SELECTED_TIME')
        } else {
          errors.push('SHIPPING_MISSING_SELECTED_CARRIER_OPTION')
        }
        return
      }
    } else {
      if (!element.estimatedShippingValue || element.estimatedShippingValue === '') {
        errors.push('SHIPPING_MISSING_ESTIMATE_VALUE')
      }
      if (!element.estimatedShippingDate || element.estimatedShippingDate === '') {
        errors.push('SHIPPING_MISSING_ESTIMATE_DATE')
      }
      if (!element.trackingNumber || element.trackingNumber === '') {
        errors.push('SHIPPING_MISSING_TRACKING_NUMBER')
      }
    }

    const requestBody = {
      carrierId: parseInt(element.carrierId, 10),
      shippingContents: element.contents,
      shippingValue: element.shippingValue.replace(',', '.'),
      incoterms: parseInt(element.incoterms, 10),
      shipmentCost: hasIntegration ? service[0].record?.amount?.toString() : element.estimatedShippingValue,
      trackingNumber: element.trackingNumber,
      shippingDate: element.shippingDate,
      shippingDeliveryDate: hasIntegration ? service[0].record?.deliveryTime : element.estimatedShippingDate,
      send_notification: notifications,
      shipmentType: hasIntegration ? service[0].key?.toString() : undefined,
      orderPackageIds: element.orderPackageId
    }
    shipments.push(requestBody)
  })

  return {errors, shipments}
}
