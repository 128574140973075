import client from '../config/AxiosConfig'

export const GetProductsCharacteristics = async (page, pageSize, search) =>
  client.get(
    `/CharacteristicValues/ListCharacteristicValues?ItemsPerPage=${pageSize}&Page=${page}&Search=${search}`
  )

export const GetBanners = async (page, pageSize, search) =>
  client.get(
    `/Banners/ListBanners?ItemsPerPage=${pageSize}&Page=${page}&Search=${search}`
  )

export const GetTrendingProducts = async () =>
  client.get('/Products/getTrending')

export const GetLostBagReminder = async () =>
  client.get('/Configurations/GetLostBagReminder')

export const ChangeLostBagReminder = async (value) =>
  client.put(`/Configurations/PutLostBagReminder/${value}`)

export const GetDiscountVouchers = async (page, pageSize, search) =>
  client.get(
    `/Vouchers/ListDiscountVouchers?ItemsPerPage=${pageSize}&Page=${page}&Search=${search}`
  )

export const GetExchangeVouchers = async (page, pageSize, search) =>
  client.get(
    `/Vouchers/ListExchangeVouchers?ItemsPerPage=${pageSize}&Page=${page}&Search=${search}`
  )

/** *************************** PRODUCT CHARACTERISTICS ********************************* */

export const GetProductCharacteristics = async (id) =>
  client.get(`/CharacteristicValues/GetCharacteristic/${id}`)

export const EditProductCharacteristics = async (id, data) =>
  client.put(`/CharacteristicValues/${id}/UpdateCharacteristic`, data)

export const AddValueProductCharacteristics = async (id, data) =>
  client.post(`/CharacteristicValues/PostCharacteristicValue/${id}`, data)

export const DeleteValueProductCharacteristics = async (id, valueId) =>
  client.delete(
    `/CharacteristicValues/${id}/DeleteCharacteristicValue/${valueId}`
  )

export const EditValueProductCharacteristics = async (id, valueId, data) =>
  client.put(
    `/CharacteristicValues/${id}/UpdateCharacteristicValue/${valueId}`,
    data
  )

export const CreateProductCharacteristics = async (data) =>
  client.post('CharacteristicValues/CreateNewCharacteristic', data)

export const DeleteProductCharacteristic = async (id) =>
  client.delete(`/CharacteristicValues/DeleteProductCharacteristics/${id}`)

/** *************************** BANNERS ********************************* */

export const GetBanner = async (id) =>
  client.get(`/Banners/${id}/BannerViewDetail`)

export const EditBanner = async (id, data) =>
  client.put(`/Banners/${id}/PutBannerViewDetail`, data)

export const EditBannerImage = async (id, data) =>
  client.put(`/Banners/${id}/PutImage`, data)

export const EditBannerImageMobile = async (id, data) =>
  client.put(`/Banners/${id}/PutImageMobile`, data)

export const EditBannerLPImage = async (id, data) =>
  client.put(`/Banners/${id}/PutImageLandingPage`, data)

export const EditBannerLPImageMobile = async (id, data) =>
  client.put(`/Banners/${id}/PutImageLandingPageMobile`, data)

export const CreateBanner = async (data) =>
  client.post('Banners/CreateNewBanner', data)

export const DeleteBanner = async (id) =>
  client.delete(`Banners/DeleteBanner/${id}`)

/** *************************** VOUCHERS ********************************* */

export const GetVoucherInformation = async (id) =>
  client.get(`/Vouchers/${id}/VoucherInformation`)

export const GetVoucherLog = async (id) =>
  client.get(`/Vouchers/${id}/Log`)

export const EditVoucher = async (id, data) =>
  client.put(`/Vouchers/${id}/PutInformation`, data)

export const CreateVoucher = async (data) =>
  client.post('Vouchers/PostVoucherInformation', data)

export const DeleteVoucher = async (id) =>
  client.delete(`Vouchers/DeleteVoucher/${id}`)
