import { ValidateDepartureStock } from '../../../../infra/requests/DepartureRequests'

export const ValidateDepartureData = (formValues) => {
  const errors = []
  const products = []

  if (!formValues.originId || formValues.originId === '') {
    errors.push('Define the origin warehouse')
  }

  if (!formValues.destinyId || formValues.destinyId === '') {
    errors.push('Define the destiny warehouse')
  }

  if (!formValues.departureDate || formValues.departureDate === '') {
    errors.push('Define the date of the departure')
  }

  if (!formValues.products || formValues.products.length === 0) {
    errors.push('The product list is empty')
  } else {
    formValues.products.forEach((prod) => {
      if (
        parseInt(prod.departQty || 0, 10) >
        parseInt(prod.stock || 0, 10)
      ) {
        errors.push(`There are no available stock for ${prod.reference}`)
      }

      products.push({
        ...prod,
        left: prod.departQty,
        box: undefined
      })
    })
  }
  return { errors, products }
}

export const ValidateStock = async (warehouseId, products) => {
  const payload = {
    warehouseId,
    skus: products.map((p) => ({
      productId: p.productId,
      productWidthId: p.productWidthId,
      sizeId: p.sizeId,
      colorId: p.colorId,
      units: p.departQty
    }))
  }
  const { data } = await ValidateDepartureStock(payload)
  let possible = true
  if (data && Array.isArray(data)) {
    data.forEach((p) => {
      const index = products.findIndex(
        (x) =>
          x.productId === p.productId &&
          x.productWidthId === p.productWidthId &&
          x.sizeId === p.sizeId &&
          x.colorId === p.colorId
      )
      if (index > -1) {
        const minor =
          parseInt(products[index].departQty, 10) <
          parseInt(p.available, 10)
        products[index].available = p.available
        products[index].minor = minor
        if (minor) possible = false
      }
    })
  }

  return possible
}
