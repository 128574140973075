import React, {Component} from 'react'

import {Col, Row} from 'antd'
import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'

import IconAdd from '../../../../../assets/icons/icon_add.svg'
import {GetExchangeVouchers} from '../../../../../infra/requests/MarketingRequests'
import BaseButton from '../../../../../shared/components/buttons/BaseButton'
import SearchInput from '../../../../../shared/components/inputs/SearchInput'
import StatusColumn from '../../../../../shared/components/Status/StatusColumn'
import BaseTable from '../../../../../shared/components/table/BaseTable'
import {Margin} from '../../../../../shared/styles/BasicStyles'

let timeout

class ExchangeVouchers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      rows: [],
      page: 1,
      pageSize: 12,
      total: 0,
      search: ''
    }
  }

  getColumnsExchange = () => [
    {
      dataIndex: 'name',
      type: 'text',
      title: <Translate id='NAME' />
    },
    {
      dataIndex: 'code',
      type: 'text',
      title: <Translate id='CODE' />
    },
    {
      dataIndex: 'email',
      type: 'text',
      title: <Translate id='EMAIL' />
    },
    {
      dataIndex: 'usageCounter',
      type: 'text',
      title: <Translate id='USAGE' />
    },
    {
      dataIndex: 'totalValue',
      type: 'text',
      title: <Translate id='VALUE' />
    },
    {
      dataIndex: 'totalValueRemaining',
      type: 'text',
      title: <Translate id='VALUE_REMAINING' />
    },
    {
      dataIndex: 'active',
      type: 'text',
      title: <Translate id='STATE' />,
      render: (value) => <StatusColumn value={value} />,
      width: 150
    }
  ]

  componentDidMount = async () => {
    try {
      this.updateTable()
    } catch (error) {
      console.warn(error)
    }
  }

  updateTable = async () => {
    try {
      const {page, pageSize, search} = this.state
      this.setState({loading: true})

      const {data, success} = await GetExchangeVouchers(
        page,
        pageSize,
        search
      )

      if (success) {
        this.setState({
          rows: data?.items,
          total: data?.totalItems,
          loading: false
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  onChangePagination = (page) => this.setState({page}, this.updateTable)

  setFilter = (search) => {
    this.setState({search, page: 1}, () => {
      if (timeout) clearTimeout(timeout)
      timeout = setTimeout(this.updateTable, 1000)
    })
  }

  render() {
    const {
      loading,
      rows,
      page,
      pageSize,
      total,
      search
    } = this.state
    const {router} = this.props

    return (
      <>
        <Row gutter={[24, 24]}>
          <Col xs={6}>
            <BaseButton
              auto
              imagePath={IconAdd}
              onClick={() =>
                router.history.push('/marketing/vouchers/exchange/new')
              }
            >
              <Translate id='ADD_NEW' />
            </BaseButton>
          </Col>
          <Col xs={6} offset={12}>
            <SearchInput
              input={{
                value: search,
                onChange: this.setFilter,
                onSearch: this.updateTable
              }}
            />
          </Col>
        </Row>
        <Margin size={17} />
        <Row>
          <Col xs={24}>
            <BaseTable
              datasource={rows}
              columns={this.getColumnsExchange()}
              pagination={{
                render: true,
                page,
                pageSize,
                total,
                onChangePagination: this.onChangePagination
              }}
              rowKey='voucherId'
              loading={loading}
              onClickRow={(row) => {
                router.history.push(
                  `/marketing/vouchers/exchange/${row.voucherId}`
                )
              }}
            />
          </Col>
        </Row>
      </>
    )
  }
}

ExchangeVouchers.propTypes = {
  router: PropTypes.object.isRequired
}

export default ExchangeVouchers
