import React, { useState, useEffect } from "react";

import { PieChartOutlined } from "@ant-design/icons";
import { Row, Col, Spin } from "antd";
import PropTypes from "prop-types";
import { Translate } from "react-localize-redux";
import { VictoryPie, VictoryContainer } from "victory";

import ActiveTranslation from "../../../shared/logic/translations/ActiveTranslation";
import { TitleH1 } from "../../../shared/styles/BasicStyles";
import {
  PieChartLabelContainer,
  PieChartLabelText,
  PieChartLabel,
  EmptyDashboardMessage,
  RelativeContainer,
  ChartLoading,
} from "../DashboardStyles";
import { ChartLabelScrollContainer } from "./ChartsStyles";
import LegendItem from "./LegendItem";

const PieComponent = ({ info, loading }) => {
  const [selected, setSelected] = useState(undefined);
  const handleSelect = ({ datum }) => {
    const index = info.items.findIndex(
      (i) => i[info.options.x] === datum[info.options.x]
    );
    setSelected({ ...datum, fill: info.options.colors[index] });
  };

  useEffect(() => {
    setSelected(undefined);
  }, [loading]);

  if (info.options.empty) {
    return (
      <EmptyDashboardMessage>
        <PieChartOutlined />
        <div>
          <Translate id="NO_AVAILABLE_INFO" />
        </div>
      </EmptyDashboardMessage>
    );
  }
  if (!info.options.x) {
    return (
      <EmptyDashboardMessage>
        <PieChartOutlined />
        <div>
          <Translate id="NO_XAXIS_DEFINITION" />
        </div>
      </EmptyDashboardMessage>
    );
  }
  if (!info.options.y) {
    return (
      <EmptyDashboardMessage>
        <PieChartOutlined />
        <div>
          <Translate id="NO_YAXIS_DEFINITION" />
        </div>
      </EmptyDashboardMessage>
    );
  }

  return (
    <RelativeContainer>
      {loading && (
        <ChartLoading>
          <Spin />
        </ChartLoading>
      )}
      <Row align="middle">
        <Col xs={24} lg={14}>
          <PieChartLabelContainer>
            <VictoryPie
              colorScale={info.options.colors}
              containerComponent={
                <VictoryContainer
                  style={{
                    pointerEvents: "none",
                    userSelect: "auto",
                    touchAction: "auto",
                  }}
                />
              }
              innerRadius={100}
              x={info.options.x}
              y={info.options.y}
              events={[
                {
                  target: "data",
                  eventHandlers: {
                    onClick: () => [
                      {
                        target: "data",
                        mutation: handleSelect,
                      },
                    ],
                  },
                },
              ]}
              data={info.items}
              labels={() => null}
            />
            {selected && (
              <PieChartLabel>
                <TitleH1 $color={selected.fill}>
                  {selected[info.options.y]}
                </TitleH1>
                <PieChartLabelText>
                  <ActiveTranslation
                    value={selected[info.options.x]}
                    tag="name"
                  />
                </PieChartLabelText>
              </PieChartLabel>
            )}
          </PieChartLabelContainer>
        </Col>
        <Col xs={24} lg={10}>
          <ChartLabelScrollContainer>
            {info.items.map((l, lIndex) => (
              <LegendItem
                onClick={() => handleSelect({ datum: l })}
                key={lIndex}
                justify="flex-start"
                color={info.options.colors[lIndex]}
                name={l[info.options.x]}
              />
            ))}
          </ChartLabelScrollContainer>
        </Col>
      </Row>
    </RelativeContainer>
  );
};

PieComponent.propTypes = {
  info: PropTypes.object.isRequired,
};

export default PieComponent;
