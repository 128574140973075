import React from 'react'

import moment from 'moment'
import {PropTypes} from 'prop-types'
import {Translate} from 'react-localize-redux'

import {
  InputBox,
  InputLabel,
  InputError,
  HourInputStyled
} from './InputStyles'

const HourInput = ({
  input,
  label,
  meta,
  placeholder,
  format,
  disabledTime,
  disabled,
  hasPaddingBottom,
  small,
  afterChange,
  defaultValue
}) => {
  const showError = meta.invalid && meta.submitFailed

  const handleChange = (value) => {
    input.onChange(value || undefined)
    if (typeof afterChange === 'function') {
      afterChange(value || undefined)
    }
  }

  return (
    <InputBox hasPaddingBottom={hasPaddingBottom}>
      {label && <InputLabel>{label}</InputLabel>}
      <HourInputStyled
        hasError={showError}
        $small={small}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={
          moment.isDate(input.value)
            ? moment.utc(input.value)
            : input.value
            ? moment.utc(input.value)
            : ''
        }
        format={format}
        onChange={handleChange}
        disabledTime={disabledTime}
        disabled={disabled}
      />
      {showError && (
        <InputError>
          {' '}
          <Translate id={meta.error} />
        </InputError>
      )}
    </InputBox>
  )
}

HourInput.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  placeholder: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  format: PropTypes.string,
  disabledTime: PropTypes.func,
  hasPaddingBottom: PropTypes.bool,
  small: PropTypes.bool,
  afterChange: PropTypes.func,
  disabled: PropTypes.bool
}

HourInput.defaultProps = {
  input: {value: undefined, onChange: () => null},
  label: undefined,
  placeholder: undefined,
  meta: {},
  format: 'HH:mm',
  disabledTime: () => null,
  hasPaddingBottom: false,
  small: false,
  afterChange: undefined,
  disabled: false
}

export default HourInput
