import client from '../config/AxiosConfig'

export const GetContactListTrue = async (page, pageSize, filters) =>
  client.get(
    `/Contacts/getListedContacts/true?Page=${page}&ItemsPerPage=${pageSize}&${filters}`
  )

export const GetContactListFalse = async (page, pageSize, filters) =>
  client.get(
    `/Contacts/getListedContacts/false?Page=${page}&ItemsPerPage=${pageSize}&${filters}`
  )

export const GetContactDetail = async (id) =>
  client.get(`/Contacts/ContactViewDetail/${id}/Information`)

export const GetContactMessages = async (id, page, pageSize, filters) =>
  client.get(
    `/Contacts/ContactViewDetail/${id}/Messages?Page=${page}&ItemsPerPage=${pageSize}${filters}`
  )

export const GetContactOrders = async (id, page, pageSize, filters) =>
  client.get(
    `/Contacts/ContactViewDetail/${id}/Orders?Page=${page}&ItemsPerPage=${pageSize}${filters}`
  )

export const GetContactVouchers = async (id) =>
  client.get(`/Contacts/ContactViewDetail/${id}/Vouchers`)

export const GetContactActivity = async (id) =>
  client.get(`/Contacts/ContactViewDetail/${id}/Activity`)

export const SaveContactDetail = async (id, data) =>
  client.put(`/Contacts/PutContactViewDetail/${id}/Information`, data)

export const SaveNewContact = async (data) =>
  client.post('/Contacts/CreateNewContact', data)
