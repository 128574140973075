export const FilterSendProducts = (form) => {
  const result = {...form}
  const productsToSend = form.items // .filter((i) => i.quantity > 0)

  if (form.movementTypeId === '1') {
    const products = productsToSend.filter((item) => {
      if (item.orderSupplierId) {
        if (item.client != form.clientId) return false
        if (item.owner != form.ownerId) return false
        if (item.location != form?.warehouse?.contactDetailId) return false
      }
      return true
    })
    result.items = products
  } else {
    result.items = productsToSend
  }
  return result
}

const ConstructArrivalFormData = (values) => {
  const payload = new FormData()
  payload.append('Date', values.date.toISOString())
  payload.append('ClientId', values.clientId)
  payload.append('WarehouseId', values.warehouseId)
  payload.append('OwnerId', values.ownerId)
  payload.append('SupplierId', values.supplierId)

  if (values.returnReasonId) {
    payload.append('ReturnReasonId', values.returnReasonId)
  }

  if (values.supplierOrderSetId) {
    payload.append('SupplierOrderSetId', values.supplierOrderSetId)
  }

  if (values.attachments && values.attachments.length) {
    const types = []
    values.attachments.forEach((attachment) => {
      payload.append('Attachments', attachment.file)
      types.push(attachment.type)
    })
    payload.append('AttachmentTypes', JSON.stringify(types))
  } else {
    payload.append('Attachments', JSON.stringify(null))
    payload.append('AttachmentTypes', JSON.stringify(null))
  }

  const items = values.items.map((item) => ({
    productId: item.productId,
    sizeId: item.sizeId,
    colorId: item.colorId,
    productWidthId: item.productWidthId,
    quantity: item.quantity,
    orderSupplierId: item.orderSupplierId,
    unitCost: item.totalValue
  }))

  payload.append('Items', JSON.stringify(items))
  return payload
}

export default ConstructArrivalFormData

export const ConstructConfirmArrivalFormData = (values) => {
  const payload = new FormData()

  const products = values.productCombinationWarehouseMovement.map((p) => ({
    ProductId: p.productId,
    ProductWidthId: p.productWidthId,
    ColorId: p.colorId,
    SizeId: p.sizeId,
    UnitValue: p.unitPriceReceived,
    QuantityReceived: p.recepQty,
    OrderPoId: p.orderPoId,
    OrderSupplierId: p.orderSupplierId
  }))
  payload.append('Products', JSON.stringify(products))

  if (values.attachments && values.attachments.length) {
    const types = []
    values.attachments.forEach((attachment) => {
      payload.append('Attachments', attachment.file)
      types.push(attachment.type)
    })
    payload.append('AttachmentTypes', JSON.stringify(types))
  } else {
    payload.append('Attachments', JSON.stringify(null))
    payload.append('AttachmentTypes', JSON.stringify(null))
  }

  return payload
}
