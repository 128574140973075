import React from 'react'

import PropTypes from 'prop-types'

import ActiveTranslation from '../../../shared/logic/translations/ActiveTranslation'
import {StyledFlex} from '../../../shared/styles/BasicStyles'
import {LegendItemColor, LegendItemText} from '../DashboardStyles'

const LegendItem = ({onClick, name, color, align, justify, left}) => (
  <StyledFlex
    $direction='row'
    $justify={justify}
    $align={align}
    $left={left}
    onClick={onClick}
    $clickable={typeof onClick === 'function'}
  >
    <LegendItemColor $color={color} />
    <LegendItemText>
      <ActiveTranslation value={name} tag='name' />
    </LegendItemText>
  </StyledFlex>
)

LegendItem.propTypes = {
  onClick: PropTypes.func,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  color: PropTypes.string,
  left: PropTypes.string,
  align: PropTypes.string,
  justify: PropTypes.string
}

LegendItem.defaultProps = {
  onClick: undefined,
  name: '',
  color: '#000',
  left: undefined,
  align: 'center',
  justify: 'center'
}

export default LegendItem
