import React, { Component } from 'react';

import { Row, Col } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import styled from 'styled-components';

import AddWhite from '../../../assets/icons/add_white.svg';
import { GetArrivalsList } from '../../../infra/requests/ArrivalRequests';
import { GetAllWarehousesDestiny } from '../../../infra/requests/LogisticsRequests';
import BaseButton from '../../../shared/components/buttons/BaseButton';
import SelectInput from '../../../shared/components/inputs/SelectInput';
import BaseTable from '../../../shared/components/table/BaseTable';
import RoundCurrency from '../../../shared/logic/numbers/RoundCurrency';
import { ConstructQuery, ConstructSort } from '../../../shared/logic/queries/EndpointQueries';
import ActiveTranslation from '../../../shared/logic/translations/ActiveTranslation';
import { SuccessColor, WarningColor } from '../../../shared/styles/_colors';
import { Margin } from '../../../shared/styles/BasicStyles';

let timeout;

const StatusSection = styled.div`
  color: ${({ status }) => (status ? SuccessColor : WarningColor)};
`;

const StatusColumn = ({ status }) => (
  <StatusSection status={status}>
    <Translate id={status ? 'COMPLETE' : 'PENDING'} />
  </StatusSection>
);

class ArrivalsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      rows: [],
      page: 1,
      pageSize: 20,
      total: 0,
      filters: {},
      warehouses2: [], // Note the state variable name change to warehouses2
    };
  }

  getColumns = () => {
    const columns = [
      {
        title: <Translate id='ID' />,
        type: 'ordered',
        sorter: true,
        dataIndex: 'movementId',
      },
      {
        title: <Translate id='ARRIVAL_TYPE' />,
        type: 'ordered',
        sorter: true,
        dataIndex: 'movementTypeId',
        render: (value, data) => (
          <ActiveTranslation value={data?.movementTypeTranslation} tag='name' />
        ),
      },
      {
        title: <Translate id='ARRIVAL_DATE' />,
        type: 'ordered',
        sorter: true,
        dataIndex: 'date',
        render: (value) =>
          value ? moment.utc(value).format('DD/MM/YYYY') : <Translate id='NA' />,
      },
      {
        title: <Translate id='WAREHOUSE' />,
        type: 'ordered',
        sorter: true,
        dataIndex: 'warehouse',
        render: (value, data) => data?.warehouse,
      },
      {
        title: <Translate id='MOV_QTY' />,
        type: 'text',
        dataIndex: 'quantity',
      },
      {
        title: <Translate id='TOTAL_VALUE_EUR' />,
        type: 'text',
        dataIndex: 'total',
        render: (value, row) => `${RoundCurrency(value * row.exchangeRateUsed)}€`,
      },
      {
        title: <Translate id='STATUS' />,
        type: 'text',
        dataIndex: 'isValid',
        render: (value) => <StatusColumn status={value} />,
      },
      {
        title: <Translate id='STAGE' />,
        type: 'text',
        dataIndex: 'stage',
        render: (value, data) => (
          <ActiveTranslation value={data?.movementStatusTranslation} tag='name' />
        ),
      },
    ];
    const { rows } = this.state;
    const currency = rows.find((s) => s.currencyId !== 1);

    if (currency) {
      columns.splice(6, 0, {
        title: <Translate id='TOTAL_VALUE' />,
        type: 'text',
        dataIndex: 'total',
        render: (value, row) =>
          row.currencyId !== 1 ? `${RoundCurrency(value)} ${row.currencyCode}` : '-',
      });
    }

    return columns;
  };

  componentDidMount = async () => {
    try {

      const { data, success } = await GetAllWarehousesDestiny();

      if (success) this.setState({ warehouses2: data?.items || [] });
      this.updateTable();
    } catch (error) {
      console.warn('ComponentDidMount: Error fetching warehouse data', error);
    }
  };

  updateTable = async () => {
    const { page, pageSize, filters, sort } = this.state;
    this.setState({ loading: true });
    try {

      const { data } = await GetArrivalsList(
        page,
        pageSize,
        ConstructQuery(filters),
        ConstructSort(sort)
      );

      this.setState({
        rows: data?.items || [],
        total: data?.totalItems || 0,
        loading: false,
      });
    } catch (error) {
      console.warn('updateTable: Error fetching arrivals data', error);
      this.setState({ loading: false });
    }
  };

  onChangePagination = (page) => {

    this.setState({ page }, this.updateTable);
  };

  onChangeSort = (sort) => {

    this.setState({ sort }, this.updateTable);
  };

  setFilters = (values, time) => {

    this.setState({ filters: values, page: 1 }, () => {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(this.updateTable, time);
    });
  };

  render() {
    const { loading, rows, page, pageSize, total, filters, warehouses2 } = this.state;
    const { router, arrivalTypes } = this.props;


    return (
      <>
        <Row gutter={[24, 16]}>
          <Col xs={24} sm={12} lg={6}>
            <BaseButton
              auto
              imagePath={AddWhite}
              onClick={() => router.history.push('/logistics/arrivals/new')}
            >
              Create new arrival
            </BaseButton>
          </Col>
          <Col xs={24} md={12} lg={6} />
          <Col xs={24} md={12} lg={6}>
            <SelectInput
              input={{
                value: filters.movementType,
                onChange: (value) => this.setFilters({ ...filters, movementType: value }, 0),
              }}
              data={arrivalTypes}
              placeholder={<Translate id='ARRIVAL_TYPE' />}
              dataKey='movementTypeId'
              dataLabel='movementTypeTranslation'
            />
          </Col>
          <Col xs={24} md={12} lg={6}>
            <SelectInput
              input={{
                value: filters.warehouse,
                onChange: (value) => this.setFilters({ ...filters, warehouse: value }, 0),
              }}
              data={warehouses2}
              placeholder={<Translate id='WAREHOUSE' />}
              dataKey='warehouseId'
              dataLabel='name'
            />
          </Col>
        </Row>
        <Margin size={15} />
        <Row>
          <Col xs={24}>
            <BaseTable
              rowKey='movementId'
              datasource={rows}
              columns={this.getColumns()}
              sortCallback={this.onChangeSort}
              pagination={{
                render: true,
                page,
                pageSize,
                total,
                onChangePagination: this.onChangePagination,
              }}
              onClickRow={(row) => {
                router.history.push(`/logistics/arrivals/${row.movementId}`);
              }}
              loading={loading}
            />
          </Col>
        </Row>
      </>
    );
  }
}

ArrivalsList.propTypes = {
  router: PropTypes.object.isRequired,
};

export default connect((state) => ({
  arrivalTypes: state.info.arrivalTypes,
}))(ArrivalsList);
