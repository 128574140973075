import React from 'react'

import PropTypes from 'prop-types'

import {
  IncomingButtonContainer,
  IncomingButtonImg,
  IncomingButtonText
} from '../MessagingStyles'

const IncomingButton = ({text, imgSource, onClick, hasRightBorder}) => (
  <IncomingButtonContainer
    onClick={onClick}
    $hasRightBorder={hasRightBorder}
  >
    <IncomingButtonImg src={imgSource} />
    <IncomingButtonText>{text}</IncomingButtonText>
  </IncomingButtonContainer>
)

IncomingButton.propTypes = {
  text: PropTypes.object.isRequired,
  imgSource: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  hasRightBorder: PropTypes.bool
}

IncomingButton.defaultProps = {
  hasRightBorder: false,
  onClick: () => null
}

export default IncomingButton
