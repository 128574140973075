import ValidateIfAnyObjectIsNull from '../../../../shared/logic/objects/ValidateIfAnyObjectIsNull'

export default (formValues) => {
  const requiredObject = {
    billingName: formValues.billingName,
    billingAddressLine1: formValues.billingAddressLine1,
    billingZipCode: formValues.billingZipCode,
    billingCity: formValues.billingCity,
    billingRegion: formValues.billingRegion,
    billingCountryId: formValues.billingCountryId,
    deliveryName: formValues.deliveryName,
    deliveryAddressLine1: formValues.deliveryAddressLine1,
    deliveryZipCode: formValues.deliveryZipCode,
    deliveryCity: formValues.deliveryCity,
    deliveryRegion: formValues.deliveryRegion,
    deliveryCountryId: formValues.deliveryCountryId
  }

  return ValidateIfAnyObjectIsNull(requiredObject)
}
