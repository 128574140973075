import React, {useEffect, useState} from 'react'

import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {useParams} from 'react-router-dom'
import {reduxForm, FieldArray, Field, initialize} from 'redux-form'

import DoneIcon from '../../../assets/icons/done_white.svg'
import SaveIcon from '../../../assets/icons/save.svg'
import {
  ConvertSmartOrder,
  GetSmartOrderDetails,
  SaveSmartOrder
} from '../../../infra/requests/SmartOrdersRequests'
import FormValidator from '../../../infra/services/validations/FormValidator'
import AlertService from '../../../shared/components/alert/AlertService'
import BackButton from '../../../shared/components/buttons/BackButton'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import DateInput from '../../../shared/components/inputs/DateInput'
import SelectInput from '../../../shared/components/inputs/SelectInput'
import TextInput from '../../../shared/components/inputs/TextInput'
import BaseLoading from '../../../shared/components/loading/BaseLoading'
import {
  Margin,
  PageForm,
  PageTitle,
  PageSubtitle
} from '../../../shared/styles/BasicStyles'
import DeliveryLocationSelect from '../b2b/components/DeliveryLocationSelect'
import SmartProductsTable from './SmartProductsTable'

const validation = FormValidator.make({
  paymentConditionId: 'required',
  deliveryContactDetailId: 'required',
  shippingConditionId: 'required',
  dateDelivery: 'required'
})

const ManageSmartOrder = ({
  router,
  handleSubmit,
  dispatch,
  paymentConditions,
  shippingConditions,
  translate
}) => {
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [clientId, setClient] = useState()
  const {id} = useParams()

  useEffect(() => {
    async function fetchOrder() {
      const {data, success} = await GetSmartOrderDetails(id)
      if (success) {
        setClient(data?.b2bclient?.b2bclientId)
        dispatch(initialize('edit_smart_order', data))
      }
      setLoading(false)
    }
    fetchOrder()
  }, [id, dispatch])

  const onSubmit = async (values) => {
    setSaving(true)
    const {success} = await SaveSmartOrder(id, {
      paymentConditionId: values.paymentConditionId,
      deliveryContactDetailId: values.deliveryContactDetailId,
      shippingConditionId: values.shippingConditionId,
      dateDelivery: values.dateDelivery
    })
    if (success) {
      const convert = await ConvertSmartOrder(id)
      if (convert.success) {
        AlertService.success(
          translate('SMART_ORDER_CONVERTED'),
          translate('SMART_ORDER_CONVERTED_TO_B2B_ORDER')
        )
        return router.history.push('/orders')
      }
    }
    return setSaving(false)
  }

  const onSave = async (values) => {
    setSaving(true)
    const {success} = await SaveSmartOrder(id, {
      paymentConditionId: values.paymentConditionId,
      deliveryContactDetailId: values.deliveryContactDetailId,
      shippingConditionId: values.shippingConditionId,
      dateDelivery: values.dateDelivery
    })
    if (success) {
      AlertService.success(
        translate('SMART_ORDER_CHANGED'),
        translate('SMART_ORDER_UPDATED_SUCCESSFULLY')
      )
    }
    setSaving(false)
  }

  if (loading) return <BaseLoading size='200' />

  return (
    <PageForm onSubmit={handleSubmit(onSave)}>
      <Row gutter={[0, 50]}>
        <Col xs={24}>
          <BackButton
            label={<Translate id='BACK_ORDERS' />}
            onClick={() => router.history.push('/orders#smart')}
          />
        </Col>
        <Col sm={24} md={8}>
          <PageTitle>
            <Translate id='NEW_SMART_ORDER' />
          </PageTitle>
        </Col>
      </Row>
      <Margin size='40' />
      <Row gutter={[40, 20]}>
        <Col xs={5}>
          <Field
            component={TextInput}
            name='b2bclient.name'
            label={<Translate id='CLIENT' />}
            disabled
          />
        </Col>
        <Col xs={5}>
          <Field
            component={DeliveryLocationSelect}
            name='deliveryContactDetailId'
            label={<Translate id='DELIVERY_LOCATION' />}
            placeholder={<Translate id='DELIVERY_LOCATION' />}
          />
        </Col>
        <Col xs={5}>
          <Field
            component={SelectInput}
            name='paymentConditionId'
            label={<Translate id='PAYMENT_CONDITION' />}
            placeholder={<Translate id='DEFINE_CONDITION' />}
            data={paymentConditions}
            dataLabel='name'
            dataKey='paymentConditionId'
            allowClear={false}
          />
        </Col>
        <Col xs={5}>
          <Field
            component={SelectInput}
            name='shippingConditionId'
            label={<Translate id='SHIPPING_CONDITION' />}
            placeholder={<Translate id='DEFINE_CONDITIONS' />}
            data={shippingConditions}
            dataLabel='value'
            dataKey='key'
            allowClear={false}
          />
        </Col>
        <Col xs={4}>
          <Field
            component={DateInput}
            name='dateDelivery'
            label={<Translate id='DELIVERY_DATE' />}
            placeholder={<Translate id='DELIVERY_DATE' />}
            allowClear={false}
          />
        </Col>
      </Row>
      <Margin size='50' />
      <PageSubtitle>
        <Translate id='PRODUCTS' />
      </PageSubtitle>
      <Margin size='30' />
      <FieldArray component={SmartProductsTable} name='orderSmartItem' />
      <Margin size='50' />
      <Row align='middle' justify='end' gutter={[24]}>
        <Col xs={4} style={{textAlign: 'right'}}>
          <BaseButton
            type='secondary'
            htmlType='button'
            onClick={handleSubmit(onSave)}
            imagePath={SaveIcon}
            auto
            loading={saving}
          >
            <Translate id='SAVE' />
          </BaseButton>
        </Col>
        <Col xs={6} style={{textAlign: 'right'}}>
          <BaseButton
            type='primary'
            htmlType='button'
            onClick={handleSubmit(onSubmit)}
            imagePath={DoneIcon}
            auto
            loading={saving}
          >
            <Translate id='CONVERT_SMART_ORDER' />
          </BaseButton>
        </Col>
      </Row>
    </PageForm>
  )
}

ManageSmartOrder.propTypes = {
  router: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  paymentConditions: PropTypes.array.isRequired,
  shippingConditions: PropTypes.array.isRequired
}

const ManageSmartOrderPage = reduxForm({
  form: 'edit_smart_order',
  validate: validation
})(ManageSmartOrder)

export default withLocalize(
  connect((state) => ({
    paymentConditions: state.info.paymentConditions,
    deliveryLocations: state.info.deliveryLocations,
    shippingConditions: state.info.shippingConditions
  }))(ManageSmartOrderPage)
)
