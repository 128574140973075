import React, { useState } from 'react'

import { Col, Row } from 'antd'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { useParams } from 'react-router'
import { ReactSVG } from 'react-svg'
import styled from 'styled-components'

import CloseBorderedImage from '../../../../assets/icons/cancel_border.svg'
import { ReactComponent as Icon } from '../../../../assets/icons/no_icon.svg'
import BaseButton from '../../../../shared/components/buttons/BaseButton'
import SelectInput from '../../../../shared/components/inputs/SelectInput'
import ActiveTranslation from '../../../../shared/logic/translations/ActiveTranslation'
import { StyledFlex, TitleH6 } from '../../../../shared/styles/BasicStyles'
import {
  ProductDetailsFeature,
  ProductDetailsFeatureImage
} from '../../ProductsStyles'

const ImportedIcon = styled(ReactSVG)`
  width: 30px;
  height: 30px;
  svg {
    width: 30px;
    height: 30px;
    path {
      fill: #231f20;
    }
  }
`

const EmptyIcon = styled(Icon)`
  width: 30px;
  height: 30px;
  path {
    fill: #231f20;
  }
`

const { REACT_APP_API_ROOT } = window.env

const TechnologiesList = ({ productTechnologies, fields }) => {
  const [technology, setTechnology] = useState(undefined)
  const { id } = useParams()
  const technologies = fields.getAll() || []

  const filteredTechnologies = () =>
    productTechnologies.filter(
      (p) => !technologies.find((t) => t.technologyId == p.technologyId)
    )

  const addCurrentTechnology = () => {
    const found = productTechnologies.find(
      (t) => t.technologyId == technology
    )
    setTechnology(undefined)
    const object = {
      productId: id,
      technologyId: found?.technologyId,
      technology: found
    }
    fields.push(object)
  }

  const renderIcon = (elem) => {
    const found = productTechnologies.find(
      (t) => t.technologyId == elem.technologyId
    )

    if (found && found.icon) {
      return (
        <ImportedIcon
          src={`${REACT_APP_API_ROOT}${found.url}${found.icon}`}
        />
      )
    }

    return <EmptyIcon />
  }

  return (
    <Row gutter={[8, 20]}>
      <Col xs={24} lg={13}>
        <SelectInput
          label={<Translate id='TECHNOLOGIES' />}
          input={{
            value: technology,
            onChange: setTechnology
          }}
          placeholder={<Translate id='SELECT' />}
          dataKey='technologyId'
          dataLabel='technologyTranslation'
          data={filteredTechnologies()}
        />
      </Col>
      <Col xs={0} lg={1} />
      <Col xs={12} lg={6}>
        <BaseButton
          type='secondary'
          onClick={addCurrentTechnology}
          disabled={!technology}
          style={{ marginTop: '20px' }}
        >
          <Translate id='ADD' />
        </BaseButton>
      </Col>
      {technologies.map((currentTechnology, index) => (
        <Col xs={24} key={index}>
          <ProductDetailsFeature>
            <StyledFlex $direction='row'>
              {renderIcon(currentTechnology)}
              <TitleH6 $left='6' $right='20'>
                <ActiveTranslation
                  value={
                    currentTechnology?.technology?.technologyTranslation
                  }
                  tag='name'
                />
              </TitleH6>
            </StyledFlex>
            <ProductDetailsFeatureImage
              onClick={() => fields.remove(index)}
              src={CloseBorderedImage}
            />
          </ProductDetailsFeature>
        </Col>
      ))}
    </Row>
  )
}

TechnologiesList.propTypes = {
  fields: PropTypes.object.isRequired,
  productTechnologies: PropTypes.array.isRequired
}

export default connect((state) => ({
  productTechnologies: state.info.productTechnologies
}))(TechnologiesList)
