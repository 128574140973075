import styled from 'styled-components'

import {
  PrimaryButtonColor,
  TableTextColor,
  BorderColor,
  TableHeaderBackground
} from '../../../styles/_colors'
import {
  BookFontWeight,
  BodyText,
  CaptionText,
  BoldFontWeight
} from '../../../styles/_texts'
import {TextInputStyled} from '../InputStyles'

export const ProductsSection = styled.div`
  width: 100%;
  margin-top: ${({$isEdition}) => ($isEdition ? '0' : '30')}px;
`

export const ProductActions = styled.div`
  width: 100%;
  margin-top: 20px;
  display: inline-block;
`
export const ImportButton = styled.div`
  float: right;
`

export const MatrixContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`
export const MatrixCell = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${TableTextColor};
  font-weight: ${BookFontWeight};
  font-size: ${BodyText};
  padding-right: 8px;
  padding-left: 8px;
  border-right: 1px solid ${BorderColor};
  line-height: 25px;

  ${TextInputStyled} {
    height: 25px;
  }
`

export const MatrixCellContainer = styled.div`
  width: 200px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-top: 1px solid ${BorderColor};
  border-bottom: 1px solid ${BorderColor};
  white-space: nowrap;
  overflow: hidden;

  &:first-child {
    border-left: 1px solid ${BorderColor};
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    ${MatrixCell} {
      color: ${PrimaryButtonColor};
    }
  }

  &:last-child {
    border-right: 1px solid ${BorderColor};
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;

    ${MatrixCell} {
      border-right: none;
    }
  }
`

export const MatrixHeader = styled.div`
  display: flex;
  flex-direction: row;

  ${MatrixCell} {
    color: ${PrimaryButtonColor};
  }
`

export const MatrixBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const MatrixBody = styled.div`
  display: flex;
  flex-direction: row;
`

export const ProductsFooter = styled.div`
  width: 100%;
  display: inline-block;
  background-color: ${TableHeaderBackground};
  border: 1px solid ${BorderColor};
  padding: 10px 20px 15px 20px;
  text-align: right;
  border-radius: 5px;
  margin-top: 3px;
`

export const ProductsFooterTitle = styled.div`
  color: ${TableTextColor};
  font-weight: ${({$total}) => ($total ? BoldFontWeight : BookFontWeight)};
  font-size: ${BodyText};
  line-height: 16.8px;
  text-align: left;
  margin-top: 5px;
  margin-right: 20px;
`

export const ProductsFooterValue = styled.div`
  color: #0267b2;
  font-weight: ${({$total}) => ($total ? BoldFontWeight : BookFontWeight)};
  font-size: ${CaptionText};
  line-height: 16px;
  text-align: left;
  margin-top: 5px;
`

export const ProductsFooterContainer = styled.div`
  display: inline-block;
`

export const StyledBordelessButton = styled.button`
  border-radius: 20px;
  border: none;
  display: inline-block;
  width: ${({$auto}) => ($auto ? 'auto' : '100%')};
  cursor: pointer;
  background-color: transparent;

  &:focus {
    outline: none;
  }
`

export const StyledBordelessButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: ${BookFontWeight};
  max-height: 16px;
  margin: 6px 0px;
  border: 0.5px solid #c6c8ca;
  border-radius: 11px;
  padding: 10px 10px;
`

export const StyledButtonImg = styled.img`
  font-size: 16px;
  max-width: 16px;
  max-height: 16px;
  margin-left: 10px;
`

export const ProductUploadFileInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 6px;
  border-radius: 5px;
  border: 1px solid ${BorderColor};
`

export const ProductUploadImage = styled.img`
  height: 35px;
  width: 35px;
  margin-right: 12px;
`

export const ProductFileRemoveImage = styled.img`
  position: absolute;
  cursor: pointer;
  top: ${({$top}) => $top || 10}px;
  right: 20px;
`

export const EmptyMessage = styled.div`
  margin-top: 30px;
  text-align: center;
  font-size: 16px;
`

export const HoverFile = styled.div`
  cursor: pointer;
  margin-top: 5px;
  &:hover {
    text-decoration: underline;
  }
`
