import React, {useState, useEffect} from 'react'

import {Col, Row} from 'antd'
import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'
import {connect} from 'react-redux'
import {Field, getFormValues} from 'redux-form'
import styled from 'styled-components'

import {GetProfilePermissions} from '../../infra/requests/ClientRequests'
import CheckboxInput from '../../shared/components/inputs/CheckboxInput'
import {CheckBoxInputBox} from '../../shared/components/inputs/InputStyles'
import SearchInput from '../../shared/components/inputs/SearchInput'
import SelectInput from '../../shared/components/inputs/SelectInput'
import SimpleCheckbox from '../../shared/components/inputs/SimpleCheckbox'
import BaseTable from '../../shared/components/table/BaseTable'

let timeout

const FilterSection = styled.div`
  display: flex;
  height: 34px;
  align-items: center;

  ${CheckBoxInputBox} {
    margin-right: 20px;
  }
`

const FilterList = (list, newFilters) => list.filter((elem) => {
  let valid = true

  if (newFilters.search !== '') {
    valid = elem.functionalityName.toLowerCase().indexOf(newFilters.search.toLowerCase()) > -1
  }
  if (valid && newFilters.create && !elem.permissions[0].active) {
    valid = false;
  }
  if (valid && newFilters.delete && !elem.permissions[1].active) {
    valid = false;
  }
  if (valid && newFilters.read && !elem.permissions[2].active) {
    valid = false;
  }
  if (valid && newFilters.update && !elem.permissions[3].active) {
    valid = false;
  }

  return valid
})

const Permissions = ({profiles, selected}) => {
  const [loading, setLoading] = useState(false)
  const [filters, setFilters] = useState({search: '', create: false, delete: false, read: false, update: false})
  const [permissions, setPermissions] = useState([])
  const [showList, setShowList] = useState([])

  useEffect(() => {
    async function init() {
      setLoading(true)
      const {data, success} = await GetProfilePermissions(selected)
      if (success) {
        setPermissions(data)
        setShowList(FilterList(data, filters))
      }
      setLoading(false)
    }
    if (selected) init()
  }, [filters, selected])

  const columns = [
    {
      dataIndex: 'functionalityName',
      type: 'text',
      title: <Translate id='FUNCTIONALITY' />
    },
    {
      title: <Translate id='CREATE' />,
      render: (data) => (
        <SimpleCheckbox
          disabled
          input={{value: !!data.permissions[0].active}}
        />
      )
    },
    {
      title: <Translate id='DELETE' />,
      render: (data) => (
        <SimpleCheckbox
          disabled
          input={{value: !!data.permissions[1].active}}
        />
      )
    },
    {
      title: <Translate id='READ' />,
      render: (data) => (
        <SimpleCheckbox
          disabled
          input={{value: !!data.permissions[2].active}}
        />
      )
    },
    {
      title: <Translate id='UPDATE' />,
      render: (data) => (
        <SimpleCheckbox
          disabled
          input={{value: !!data.permissions[3].active}}
        />
      )
    }
  ]

  const setFilter = (label, value, time) => {
    const newFilters = {...filters}
    newFilters[label] = value
    setFilters(newFilters)
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(() => setShowList(FilterList(permissions, newFilters)), time)
  }

  return (
    <>
      <Row gutter={[40, 20]} align='bottom'>
        <Col xs={6}>
          <Field
            component={SelectInput}
            name='profile.profileId'
            label={<Translate id='PROFILE' />}
            placeholder={<Translate id='SELECT' />}
            data={profiles}
            allowClear={false}
            dataKey='profileId'
            dataLabel='name'
          />
        </Col>

        <Col xs={6}>
          <SearchInput
            input={{
              value: filters.search,
              onChange: (value) => setFilter('search', value, 500)
            }}
            placeholder='Search functionality'
          />
        </Col>
        <Col xs={12}>
          <FilterSection>            
            <CheckboxInput
              input={{
                value: filters.create,
                onChange: (value) => setFilter('create', value, 0)
              }}
              label='Create'
            />
            <CheckboxInput
              input={{
                value: filters.delete,
                onChange: (value) => setFilter('delete', value, 0)
              }}
              label='Delete'
            />
            <CheckboxInput
              input={{
                value: filters.read,
                onChange: (value) => setFilter('read', value, 0)
              }}
              label='Read'
            />
            <CheckboxInput
              input={{
                value: filters.update,
                onChange: (value) => setFilter('update', value, 0)
              }}
              label='Update'
            />
          </FilterSection>
        </Col>

        <Col xs={24}>
          <BaseTable
            rowKey='functionalityId'
            loading={loading}
            datasource={showList}
            columns={columns}
            pagination={{
              render: false
            }}
          />
        </Col>
      </Row>
    </>
  )
}

Permissions.propTypes = {
  profiles: PropTypes.array.isRequired,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

Permissions.defaultProps = {
  selected: undefined
}

const SelectedProfile = (state) => {
  const form = getFormValues('user_form')(state)
  return form?.profile?.profileId
}

export default connect((state) => ({
  selected: SelectedProfile(state)
}))(Permissions)
