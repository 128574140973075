import React, {useEffect, useState} from 'react'

import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'

import {GetCarriers} from '../../../infra/requests/LogisticsRequests'
import BackButton from '../../../shared/components/buttons/BackButton'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import StatusColumn from '../../../shared/components/Status/StatusColumn'
import BaseTable from '../../../shared/components/table/BaseTable'
import {
  Margin,
  ContentContainer,
  PageTitle
} from '../../../shared/styles/BasicStyles'

const CarriersPage = ({router}) => {
  const [loading, setLoading] = useState(true)
  const [rows, setRows] = useState([])
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const pageSize = 20

  useEffect(() => {
    async function init() {
      const {data} = await GetCarriers(page, pageSize)
      setRows(data?.items || [])
      setTotal(data?.totalItems || 0)
      setLoading(false)
    }
    init()
  }, [])

  const columns = [
    {
      title: <Translate id='NAME' />,
      type: 'text',
      dataIndex: 'name'
    },
    {
      title: <Translate id='INTEGRATION' />,
      dataIndex: 'hasIntegration',
      render: (value) => (
        <StatusColumn
          value={value}
          onText={<Translate id='YES' />}
          offText={<Translate id='NO' />}
        />
      )
    },
    {
      title: <Translate id='ACTIVE' />,
      type: 'text',
      dataIndex: 'active',
      render: (value) => (
        <StatusColumn
          value={value}
          onText={<Translate id='YES' />}
          offText={<Translate id='NO' />}
        />
      )
    }
  ]

  const handlePageChange = async (p) => {
    setLoading(true)
    setPage(p)
    const {data} = await GetCarriers(p, pageSize)
    setRows(data?.items || [])
    setTotal(data?.totalItems || 0)
    setLoading(false)
  }

  return (
    <ContentContainer>
      <Row align='bottom' gutter={[0, 50]}>
        <Col xs={24}>
          <BackButton
            label={<Translate id='BACK' />}
            onClick={() => router.history.push('/logistics#config')}
          />
        </Col>
        <Col xs={12}>
          <PageTitle>Carriers</PageTitle>
        </Col>

        <Col xs={12} align='end'>
          <BaseButton
            htmlType='submit'
            type='primary'
            auto
            onClick={() => router.history.push('/logistics/carriers/new')}
          >
            New Carrier
          </BaseButton>
        </Col>
      </Row>
      <Margin size={40} />
      <Row>
        <Col xs={24}>
          <BaseTable
            rowKey='carrierId'
            datasource={rows}
            columns={columns}
            pagination={{
              render: true,
              page,
              pageSize,
              total,
              onChangePagination: handlePageChange
            }}
            onClickRow={(row) => {
              router.history.push(`/logistics/carriers/${row.carrierId}`)
            }}
            loading={loading}
          />
        </Col>
      </Row>
    </ContentContainer>
  )
}

CarriersPage.propTypes = {
  router: PropTypes.object.isRequired
}

export default CarriersPage
