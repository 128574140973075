import React from 'react'

import {Card} from 'antd'
import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'
import EmptyTable from '../../../assets/icons/empty_table.svg'
import {InputError} from '../inputs/InputStyles'
import TableBasePagination from './components/TableBasePagination'
import {
  StyledList,
  StyledListItem,
  EmptyTableIconContainer,
  EmptyTableIcon,
  EmptyTableTitle,
  EmptyTableText
} from './TableStyles'

const BaseList = ({
  datasource,
  pagination,
  PaginationComponent,
  FooterComponent,
  meta,
  renderItem,
  onClickRow,
  grid,
  rowKey,
  loading,
  emptyComponent,
  cardItem
}) => {
  const showError = meta.invalid && meta.submitFailed

  const locale = {
    emptyText: (
      <EmptyTableIconContainer>
        {emptyComponent || (
          <>
            <EmptyTableText>This list is empty</EmptyTableText>
          </>
        )}
        <EmptyTableIcon src={EmptyTable} />
      </EmptyTableIconContainer>
    )
  }

  const renderItemWrapper = (item) => (
    <>
      {cardItem ? (
        <Card style={{backgroundColor: 'transparent'}}>
          <StyledListItem
            $hasOnClick={typeof onClickRow === 'function'}
            onClick={() =>
              typeof onClickRow === 'function' ? onClickRow(item) : null
            }
          >
            {renderItem(item)}
          </StyledListItem>
        </Card>
      ) : (
        <StyledListItem
          $hasOnClick={typeof onClickRow === 'function'}
          onClick={() =>
            typeof onClickRow === 'function' ? onClickRow(item) : null
          }
        >
          {renderItem(item)}
        </StyledListItem>
      )}
    </>
  )

  return (
    <>
      <StyledList
        dataSource={datasource}
        rowKey={rowKey}
        pagination={false}
        $hasError={showError}
        loading={loading}
        footer={
          FooterComponent
            ? (currentPageData) => (
                <FooterComponent currentPageData={currentPageData} />
              )
            : undefined
        }
        locale={locale}
        renderItem={renderItemWrapper}
        grid={grid}
      />
      {showError && (
        <InputError>
          {' '}
          <Translate id={meta.error} />
        </InputError>
      )}
      {pagination.render && (
        <PaginationComponent
          page={pagination.page}
          pageCount={pagination.pageCount}
          pageSize={pagination.pageSize}
          total={pagination.total}
          onChangePagination={pagination.onChangePagination}
        />
      )}
    </>
  )
}

BaseList.propTypes = {
  datasource: PropTypes.array.isRequired,
  pagination: PropTypes.object,
  PaginationComponent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element
  ]),
  FooterComponent: PropTypes.node,
  loading: PropTypes.bool,
  meta: PropTypes.object,
  rowKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  emptyComponent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element
  ]),
  renderItem: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element
  ]).isRequired,
  grid: PropTypes.object,
  onClickRow: PropTypes.func
}

BaseList.defaultProps = {
  pagination: {
    render: true,
    pageIndex: 0,
    pageCount: 0,
    pageSize: 30,
    total: 0,
    onChangePagination: () => null
  },
  PaginationComponent: TableBasePagination,
  FooterComponent: undefined,
  loading: false,
  meta: {},
  rowKey: 'key',
  emptyComponent: undefined,
  grid: {},
  onClickRow: undefined
}

export default BaseList
