import React, {useEffect, useState} from 'react'

import {Col, Row} from 'antd'
import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'

import {
  ContentContainer,
  Margin,
  PageTitle,
  StyledTabPane,
  StyledTabs,
  StyledTabTitle,
  StyledTabTitleContainer
} from '../../../shared/styles/BasicStyles'
import DepartmentTab from './tabs/DepartmentTab'
import ProfileTab from './tabs/ProfileTab'
import UsersTab from './tabs/UsersTab'

const Sections = ['departments', 'users', 'profiles']

const SkyproConfigurationPage = ({router}) => {
  const [section, setSection] = useState('departments')

  useEffect(() => {
    function initialize() {
      const hash = router.location.hash.replace('#', '')
      if (Sections.find((s) => s === hash)) {
        setSection(hash)
      }
    }
    initialize()
  }, [])

  const onChangeTab = (active) => {
    setSection(active)
    router.history.replace(`/skypro#${active}`)
  }

  return (
    <>
      <Margin size={62} />
      <Row>
        <Col xs={24}>
          <PageTitle>
            <Translate id='SKYPRO_CONFIG' />
          </PageTitle>
        </Col>
      </Row>
      <ContentContainer>
        <StyledTabs destroyInactiveTabPane activeKey={section} onChange={onChangeTab}>
          <StyledTabPane
            tab={
              <StyledTabTitleContainer>
                <StyledTabTitle>
                  <Translate id='DEPARTMENTS' />
                </StyledTabTitle>
              </StyledTabTitleContainer>
            }
            key='departments'
          >
            <DepartmentTab router={router} />
          </StyledTabPane>
          <StyledTabPane
            tab={
              <StyledTabTitleContainer>
                <StyledTabTitle>
                  <Translate id='USERS' />
                </StyledTabTitle>
              </StyledTabTitleContainer>
            }
            key='users'
          >
            <UsersTab router={router} />
          </StyledTabPane>
          <StyledTabPane
            tab={
              <StyledTabTitleContainer>
                <StyledTabTitle>
                  <Translate id='PROFILES' />
                </StyledTabTitle>
              </StyledTabTitleContainer>
            }
            key='profiles'
          >
            <ProfileTab router={router} />
          </StyledTabPane>
        </StyledTabs>
      </ContentContainer>
    </>
  )
}

SkyproConfigurationPage.propTypes = {
  router: PropTypes.object.isRequired
}

export default SkyproConfigurationPage
