import React from 'react'

import { Translate } from 'react-localize-redux'
import styled from 'styled-components'

import BaseTable from '../../../../shared/components/table/BaseTable'
import ActiveTranslation from '../../../../shared/logic/translations/ActiveTranslation'
import { ProductColor } from '../../../orders/manage_b2b_b2b2c/components/Styles'

const Container = styled.div`
    width: 100%;
    max-width: 400px;
    padding-bottom: 30px;
`

const Flex = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    & > div {
        margin-left: 10px;
    }
`

const ColorsAndCodes = ({ fields }) => {
  const relations = fields.getAll() || []
  return (
    <Container>
      <BaseTable
        rowKey='rawMaterialColorId'
        datasource={relations}
        pagination={{ render: false }}
        columns={[
          {
            title: <Translate id='COLOR' />,
            render: (data) => (
              <Flex>
                <ActiveTranslation value={data?.color?.colorTranslation} tag='name' />
                <ProductColor
                  $file={
                    data?.color?.file
                      ? URL.createObjectURL(data?.color?.file)
                      : undefined
                  }
                  $color={data?.color?.html}
                  $border={data?.color?.borderColor}
                />
              </Flex>
            )
          },
          {
            title: <Translate id='BARCODE' />,
            render: (data) => data.barcode || '-'
          }
        ]}
      />
    </Container>
  )
}

export default ColorsAndCodes
