import React, {useState, useEffect} from 'react'

import {Translate} from 'react-localize-redux'

import Back from '../../../../assets/icons/back.svg'
import Next from '../../../../assets/icons/next.svg'
import {GetContactListTrue} from '../../../../infra/requests/ContactRequests'
import SearchInput from '../../../../shared/components/inputs/SearchInput'
import BaseLoading from '../../../../shared/components/loading/BaseLoading'
import {
  FooterBack,
  FooterNext
} from '../../../../shared/components/table/TableStyles'
import {ContactsPagination} from '../../MessagingStyles'
import {
  Title,
  ProcessContainer,
  ProcessSubContainer,
  ContentWithoutMargin
} from '../ProcessStyles'
import DraggableContact from './DraggableContact'

let timeout

const CalculateTotalPages = (page, pageSize, total) => {
  if (!total) return 1
  if (total <= pageSize) return 1
  return Math.ceil(total / pageSize)
}

const CalculateCurrentItems = (page, pageSize, total) => {
  if (!total) return 0
  if (total <= pageSize) return total
  if (total <= pageSize * page) return total
  return Math.ceil(pageSize * page)
}

const DraggableContactList = () => {
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [contacts, setContacts] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize] = useState(20)
  const [total, setTotal] = useState(0)

  const lastPage = CalculateTotalPages(page, pageSize, total)
  const current = CalculateCurrentItems(page, pageSize, total)

  useEffect(() => {
    async function getContacts() {
      setLoading(true)
      const {data} = await GetContactListTrue(
        page,
        pageSize,
        `Search=${search}`
      )
      setContacts(data?.items || [])
      setTotal(data?.totalItems || 0)
      setLoading(false)
    }
    getContacts()
  }, [])

  const updateList = async (string) => {
    setLoading(true)
    const {data} = await GetContactListTrue(
      page,
      pageSize,
      `Search=${string}`
    )
    setContacts(data?.items || [])
    setTotal(data?.totalItems || 0)
    setLoading(false)
  }

  const handleSearch = () => {
    setPage(1)
    updateList(search)
  }

  const handleInput = async (value) => {
    setSearch(value)
    setPage(1)
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(() => {
      updateList(value)
    }, 1000)
  }

  return (
    <>
      <ProcessContainer>
        <Title $top={5} $bottom={11}>
          <Translate id='CONTACTS' />
        </Title>
        <ContentWithoutMargin $left='0' $right='0'>
          <div style={{width: 'calc(100% + 2px)', marginBottom: 10}}>
            <SearchInput
              input={{
                value: search,
                onChange: handleInput,
                onSearch: handleSearch
              }}
            />
          </div>
        </ContentWithoutMargin>

        {loading ? (
          <BaseLoading margin='100' />
        ) : (
          <ProcessSubContainer $top='0' $bottom='0'>
            <ContentWithoutMargin>
              {contacts.map((contact, index) => (
                <DraggableContact
                  contact={contact}
                  key={index}
                  index={index}
                />
              ))}
            </ContentWithoutMargin>
          </ProcessSubContainer>
        )}
      </ProcessContainer>
      <ContactsPagination>
        <FooterBack
          disabled={page < 2}
          style={{marginRight: 0}}
          onClick={() => {
            if (page > 1) {
              setPage(page - 1)
              updateList(search)
            }
          }}
        >
          <img src={Back} alt={<Translate id='FOOTER_BACK' />} />
        </FooterBack>
        <div>
          {page} <Translate id='OF' /> {lastPage} ({current}{' '}
          <Translate id='OF' /> {total})
        </div>
        <FooterNext
          disabled={page >= lastPage}
          onClick={() => {
            if (page < lastPage) {
              setPage(page + 1)
              updateList(search)
            }
          }}
        >
          <img src={Next} alt={<Translate id='FOOTER_NEXT' />} />
        </FooterNext>
      </ContactsPagination>
    </>
  )
}

export default DraggableContactList
