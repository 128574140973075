import React from 'react'

import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'

import { StatusText, StatusSpan, StatusImg } from './StatusStyles'
import ActiveIcon from '../../../assets/icons/status_active.svg'
import InactiveIcon from '../../../assets/icons/status_inactive.svg'

const StatusColumn = ({ value, onText, offText }) => {
  let displayText
  let icon
  let isUndefined = false

  if (value === true) {
    displayText = onText
    icon = ActiveIcon
  } else if (value === false) {
    displayText = offText
    icon = InactiveIcon
  } else if (value === undefined) {
    displayText = '-'
    icon = null // No icon for undefined case
    isUndefined = true
  }

  return (
    <StatusText active={value} isUndefined={isUndefined}>
      <StatusSpan>{displayText}</StatusSpan>
      {icon && (
        <StatusImg
          src={icon}
          alt={<Translate id='STATE' />}
        />
      )}
    </StatusText>
  )
}

StatusColumn.propTypes = {
  value: PropTypes.any,
  onText: PropTypes.object,
  offText: PropTypes.object
}

StatusColumn.defaultProps = {
  value: undefined,
  onText: <Translate id='ACTIVE' />,
  offText: <Translate id='INACTIVE' />
}

export default StatusColumn
