import React from 'react'

import {Popover} from 'antd'
import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'
import Info from '../../../assets/icons/info.svg'
import {
  Flex,
  LineContent,
  CircleNote,
  NoteIcon,
  TextPopover
} from '../LogisticStyles'

const WarehousePopup = ({item}) => {
  const content = (
    <Flex>
      <TextPopover $bold>
        <Translate id='CODE' />:
      </TextPopover>
      <TextPopover>{item.code || <Translate id='NA' />}</TextPopover>
    </Flex>
  )
  return (
    <Popover placement='top' trigger='hover' content={content}>
      <LineContent>
        {item.name || <Translate id='NA' />}
        <CircleNote>
          <NoteIcon src={Info} alt='Info' />
        </CircleNote>
      </LineContent>
    </Popover>
  )
}

WarehousePopup.propTypes = {
  item: PropTypes.object.isRequired
}

export default WarehousePopup
