import React, { useState } from 'react'

import { DeleteOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import PropTypes from 'prop-types'
import { withLocalize, Translate } from 'react-localize-redux'
import { Field } from 'redux-form'

import BaseButton from '../../../../shared/components/buttons/BaseButton'
import SelectInput from '../../../../shared/components/inputs/SelectInput'
import TextAreaInput from '../../../../shared/components/inputs/TextAreaInput'
import TextInput from '../../../../shared/components/inputs/TextInput'
import GetObjectByID from '../../../../shared/logic/arrays/GetObjectByID'
import { ErrorColor } from '../../../../shared/styles/_colors'
import { Margin, TitleH4 } from '../../../../shared/styles/BasicStyles'

const ProductDescriptions = ({ fields, languages, translate }) => {
  const [language, setLanguage] = useState(undefined)

  const list = fields.getAll()

  const filterLanguages = () =>
    languages.filter((lang) => !list.find((l) => l.languageId === lang.languageId))

  const enableLanguage = () => {
    fields.push({
      name: '',
      description: '',
      languageId: parseInt(language, 10),
      showField: true
    })
    setLanguage(undefined)
  }

  return (
    <>
      <Row gutter={[20, 20]} align='bottom'>
        <Col xs={14} lg={5}>
          <SelectInput
            label={<Translate id='ADD_LANGUAGE' />}
            input={{
              value: language,
              onChange: setLanguage
            }}
            placeholder={<Translate id='SELECT' />}
            dataKey='languageId'
            dataLabel='name'
            data={filterLanguages()}
          />
        </Col>
        <Col xs={4} lg={3}>
          <BaseButton
            type='secondary'
            disabled={!language}
            onClick={enableLanguage}
          >
            <Translate id='ADD' />
          </BaseButton>
        </Col>
      </Row>
      <Margin size={20} />
      {list.map((currentField, index) => (
        <Row gutter={[20, 20]} key={index}>
          <Col xs={24}>
            <TitleH4 $op>
              {
                GetObjectByID(
                  languages,
                  'languageId',
                  currentField?.languageId
                )?.name
              }
              <DeleteOutlined
                style={{
                  marginLeft: '10px',
                  color: ErrorColor,
                  cursor: 'pointer',
                  zIndex: 2
                }}
                onClick={() => fields.remove(index)}
              />
            </TitleH4>
          </Col>
          <Col xs={12}>
            <Field
              name={`${fields.name}[${index}].name`}
              label={<Translate id='NAME' />}
              placeholder={translate('PRODUCT_NAME')}
              component={TextInput}
            />
          </Col>
          <Col xs={12}>
            <Field
              name={`${fields.name}[${index}].description`}
              label={<Translate id='DESCRIPTION' />}
              component={TextAreaInput}
              placeholder={translate('PRODUCT_DESCRIPTION')}
              rows={5}
            />
          </Col>
        </Row>
      ))}
      <Margin size={20} />
    </>
  )
}

ProductDescriptions.propTypes = {
  fields: PropTypes.object.isRequired,
  languages: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired
}

export default withLocalize(ProductDescriptions)
