import client from '../config/AxiosConfig'

export const GetDepartments = async () =>
  client.get('/SkyproConfig/ListSections')

export const GetUsers = async (page, pageSize, search) =>
  client.get(`/SkyproConfig/Users?ItemsPerPage=${pageSize}&Page=${page}&Search=${search}`)

export const GetProfiles = async (page, pageSize, search) =>
  client.get(
    `/SkyproConfig/Profiles?ItemsPerPage=${pageSize}&Page=${page}&Search=${search}`
  )

/** *************************** DEPARTMENTS ********************************* */

export const GetDepartment = async (id) =>
  client.get(`/SkyproConfig/${id}/SectionViewDetail`)

export const GetUsersInSection = async (id) =>
  client.get(`/SkyproConfig/${id}/ListUsersInSection`)

export const EditDepartment = async (id, data) =>
  client.put(`/SkyproConfig/${id}/PutSectionViewDetail`, data)
