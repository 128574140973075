import { withLocalize } from 'react-localize-redux'

const ActiveTranslation = ({ value, tag = 'name', activeLanguage }) => {
  if (
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'boolean'
  ) {
    return value
  }

  if (!value || !Array.isArray(value) || value.length === 0) return ''

  const found = value.find(
    (elem) =>
      elem.languageID === activeLanguage.code ||
      elem.languageId === activeLanguage.code
  )
  if (found) return found[tag] || ''
  const en = value.find(
    (elem) => elem.languageID === 2 || elem.languageId === 2
  )
  if (en) return en[tag] || ''
  return value[0][tag] || ''
}

export default withLocalize(ActiveTranslation)
