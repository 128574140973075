import client from '../config/AxiosConfig'

export const GetCockpitInformation = async () =>
  client.get('Cockpit/GetAllowedEndpoints')

export const GetCockpitEndpoint = async (endpoint, filters, page, pageSize) => {
  let url = endpoint
  filters.forEach((filter) => {
    if (filter.type === 'daterange') {
      url = url.replace(
        '{startdate}',
        filter.value[0].format('DD-MM-YYYY')
      )
      url = url.replace('{enddate}', filter.value[1].format('DD-MM-YYYY'))
    } else {
      url = url.replace(`{${filter.match}}`, filter.value)
    }
  })
  return client.get(`${url}?Page=${page}&ItemsPerPage=${pageSize}`)
}
