import React, { useState, useEffect } from 'react'

import { Col, Row } from 'antd'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'

import BaseButton from "../../../../shared/components/buttons/BaseButton";
import CheckboxInput from "../../../../shared/components/inputs/CheckboxInput";
import SelectInput from "../../../../shared/components/inputs/SelectInput";
import ActiveTranslation from "../../../../shared/logic/translations/ActiveTranslation";
import {
  BodyH3,
  Margin,
  StyledFlex,
  StyledHR,
  TitleH3,
} from "../../../../shared/styles/BasicStyles";
import {
  ProductColor,
  SingleColorContainer
} from '../../../orders/manage_b2b_b2b2c/components/Styles'
import ProductCard from '../../components/ProductCard'
import {
  RelatedProductBox,
  RelatedProductOptions
} from '../../ProductsStyles'
import SimpleCheckbox from "../../../../shared/components/inputs/SimpleCheckbox";

const Classifications = [
  { id: 'core', name: 'Core' },
  { id: 'nonCore', name: 'Non Core' },
  { id: 'assortment', name: 'Assortment' }
]

const RelatedTypes = [
  { id: 'core', name: 'Core' },
  { id: 'nonCore', name: 'Non Core' },
  { id: 'assortment', name: 'Assortment' }
]

const RelatedCategories = [
  { id: 'best', name: 'Best selling' },
  { id: 'price', name: 'Price' }
]

const OnlineStore = ({ colors, fields, showRelated, outlet, setoutlet }) => {
  const [relatedCategory, setRelatedCategory] = useState('')
  const [relatedType, setRelatedType] = useState('')
  const [selectedColors, setSelectedColors] = useState(undefined)
  const [selectedClassification, setSelectedClassification] = useState({})
  const products = fields.getAll() || []

  const availableColors = [
    ...colors,
    { colorId: 'all', colorTranslation: 'Activate All' }
  ]

  const handleOutletChange = () => {
    setoutlet(prevOutlet => {
      const newOutlet = !prevOutlet;
      return newOutlet;
    });
  };

  const renderCheckboxLabel = (color) => {
    return (
      <StyledFlex $direction="row" $align="center" key={color.colorId}>
        <SingleColorContainer key={color.colorId} $marginLeft="9px">
          <ProductColor
            $file={color.file ? URL.createObjectURL(color.file) : undefined}
            $color={color.html}
          />
        </SingleColorContainer>
        <BodyH3 $left="10">
          <ActiveTranslation value={color.colorTranslation} tag="name" />
        </BodyH3>
      </StyledFlex>
    );
  };

  const renderRelatedProduct = (index) => {
    if (products[index]) {
      return (
        <RelatedProductBox>
          <RelatedProductOptions>
            <BaseButton
              auto
              size="small"
              type="secondary"
              onClick={() => showRelated(index)}
            >
              <Translate id="REPLACE" />
            </BaseButton>
            <BaseButton
              size="small"
              auto
              type="secondary"
              onClick={() => fields.remove(index)}
            >
              <Translate id="REMOVE" />
            </BaseButton>
          </RelatedProductOptions>
          <ProductCard product={products[index].productRelated} />
        </RelatedProductBox>
      );
    }

    return (
      <RelatedProductBox>
        <RelatedProductOptions>
          <BaseButton auto type="secondary" onClick={() => showRelated(index)}>
            <Translate id="ADD_PRODUCT" />
          </BaseButton>
        </RelatedProductOptions>
      </RelatedProductBox>
    );
  };

  return (
    <>
      <Row gutter={[16, 20]}>
        <Col xs={24} lg={24}>
          <TitleH3 $op>
            <Translate id="PRODUCT_ACTIVATION" />
          </TitleH3>
        </Col>
        <Col xs={12} lg={8}>
          <BodyH3 $op>
            <Translate id="BY_COLOR" />
          </BodyH3>
          {colors.map((color) => (
            <StyledFlex $direction="row" $align="center" key={color.colorId}>
              <CheckboxInput
                input={{
                  value: selectedColors === color.colorId,
                  onChange: () => setSelectedColors(color.colorId),
                }}
                label={renderCheckboxLabel(color)}
              />
            </StyledFlex>
          ))}
        </Col>
        <Col xs={12} lg={8}>
          <BodyH3 $op>
            <Translate id="BY_CLASSIFICATION" />
          </BodyH3>
          {Classifications.map((classification, index) => (
            <StyledFlex $direction="row" $align="center" key={index}>
              <CheckboxInput
                type="radio"
                input={{
                  value: selectedClassification === index,
                  onChange: () => setSelectedClassification(index),
                }}
              />
              <BodyH3 $left="10">{classification.name}</BodyH3>
            </StyledFlex>
          ))}
        </Col>
        <Col xs={12} lg={8}>
          <BodyH3 $op>
            <BodyH3>Outlet</BodyH3>
          </BodyH3>
          <StyledFlex $direction='row' $align='center' >

            <SimpleCheckbox
              input={{
                value: outlet,
                onChange: handleOutletChange
              }}
            />

            {/* <BodyH3 $left='10'>is on outlet</BodyH3> */}
          </StyledFlex>


        </Col>
      </Row>
      <StyledHR $space="10px" />
      <Row gutter={[20, 20]}>
        <Col xs={24}>
          <TitleH3 $op>
            <Translate id="RELATED_PRODUCTS" />
          </TitleH3>
        </Col>
        <Col xs={24}>
          <BodyH3 $op>
            <Translate id="SORT_BY" />
          </BodyH3>
        </Col>
        <Col xs={12} lg={5}>
          <SelectInput
            label={<Translate id="CATEGORY" />}
            input={{
              value: relatedCategory,
              onChange: (value) => setRelatedCategory(value),
            }}
            placeholder={<Translate id="SELECT" />}
            data={RelatedCategories}
          />
        </Col>
        <Col xs={12} lg={5}>
          <SelectInput
            label={<Translate id="PRODUCT_TYPE" />}
            input={{
              value: relatedType,
              onChange: (value) => setRelatedType(value),
            }}
            placeholder={<Translate id="SELECT" />}
            data={RelatedTypes}
          />
        </Col>
      </Row>
      <Margin size={20} />
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={12} md={6}>
          {renderRelatedProduct(0)}
        </Col>
        <Col xs={24} sm={12} md={6}>
          {renderRelatedProduct(1)}
        </Col>
        <Col xs={24} sm={12} md={6}>
          {renderRelatedProduct(2)}
        </Col>
        <Col xs={24} sm={12} md={6}>
          {renderRelatedProduct(3)}
        </Col>
      </Row>
      <Margin size={30} />
    </>
  );
};

OnlineStore.propTypes = {
  colors: PropTypes.array,
  fields: PropTypes.object.isRequired,
  showRelated: PropTypes.func.isRequired,
};

OnlineStore.defaultProps = {
  colors: [],
};

export default OnlineStore;
