import DashboardPage from '../../pages/dashboard/DashboardPage'
import ReportsPage from '../../pages/reports/ReportsPage'

export default [
  {
    path: '/',
    component: DashboardPage,
    isAuthenticated: true,
    shouldRenderHeader: true,
    shouldRenderFooter: true,
    role: 1
  },
  {
    path: '/reports',
    component: ReportsPage,
    isAuthenticated: true,
    shouldRenderHeader: true,
    shouldRenderFooter: true,
    role: 1
  }
]
