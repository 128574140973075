import client from '../config/AxiosConfig'

export const SyncMessages = async () =>
  client.get('/Messages/importMessages')

export const GetIncomingMessages = async (page, pageSize, filters) =>
  client.get(
    `/Messages/getListedMessages?ItemsPerPage=${pageSize}&Page=${page}${filters}`
  )

export const GetOngoingProcesses = async (page, pageSize, filters) =>
  client.get(
    `/Processes/getListedOngoingProcesses?ItemsPerPage=${pageSize}&Page=${page}${filters}`
  )

export const GetArchivedProcesses = async (page, pageSize, filters) =>
  client.get(
    `/Processes/getListedArchivedProcesses?ItemsPerPage=${pageSize}&Page=${page}${filters}`
  )

export const GetProcessDetail = async (id) =>
  client.get(`/Processes/ProcessViewDetail/${id}`)

export const EditProcessDetail = async (id, data) =>
  client.put(`/Processes/PutProcessViewDetail/${id}`, data)

export const ReplyProcess = async (id, data) =>
  client.post(`/Processes/Reply/${id}`, data)

export const GetMessageDetail = async (id) =>
  client.get(`/Messages/MessageViewDetail/${id}`)

export const ReplyMessage = async (id, data) =>
  client.post(`/Processes/ReplyAndCreateNewProcess/${id}`, data)

export const CreateProcess = async (data) =>
  client.post('/Processes/createNewProcess', data)

export const AssociateProcess = async (data) =>
  client.put('/Processes/PutInProcess', data)

export const DownloadAttach = async (id) =>
  client.get(`/Attachments/DownloadAttachment/${id}`)

export const getAnswerTemplates = async () =>
  client.get('/MessageTemplates/GetMessageTemplateTranslation')
