import React from 'react'

import {Row, Col} from 'antd'
import {Translate} from 'react-localize-redux'

import BaseModal from '../../../shared/components/modal/BaseModal'
import {Margin} from '../../../shared/styles/BasicStyles'

const ImportErrorsModal = ({modal, onClose}) => (
  <BaseModal
    okText={<Translate id='SAVE' />}
    onClose={onClose}
    onCancel={onClose}
    cancelText='Close'
    title={modal.title}
    open={modal.open}
  >
    <Row>
      <Col xs={24}>
        <b>{modal.message}</b>
        <Margin size={8} />
      </Col>
      <Col xs={24}>
        <ul style={{margin: 0, paddingLeft: '13px'}}>
          {modal.errors.map((err) => (
            <li>
              {err.message}
            </li>
          ))}
        </ul>
      </Col>
    </Row>
  </BaseModal>
)

export default ImportErrorsModal
