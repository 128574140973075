import React from 'react'

import moment from 'moment'
import {PropTypes} from 'prop-types'

import {InputBox, InputLabel, DateInputStyled} from './InputStyles'

const DateInput = ({
  input,
  label,
  meta,
  placeholder,
  format,
  disabledDate,
  disabled,
  hasPaddingBottom,
  small,
  afterChange,
  allowClear,
  showToday
}) => {
  const showError = meta.invalid && meta.submitFailed

  const handleChange = (value) => {
    input.onChange(value || undefined)
    if (typeof afterChange === 'function') {
      afterChange(value || undefined)
    }
  }

  return (
    <InputBox hasPaddingBottom={hasPaddingBottom}>
      {label && <InputLabel>{label}</InputLabel>}
      <DateInputStyled
        hasError={showError}
        $small={small}
        allowClear={allowClear}
        placeholder={placeholder}
        showToday={showToday}
        value={
          moment.isDate(input.value)
            ? input.value
            : input.value
              ? moment(input.value)
              : ''
        }
        format={format}
        onChange={handleChange}
        disabledDate={disabledDate}
        disabled={disabled}
      />
    </InputBox>
  )
}

DateInput.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  placeholder: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  format: PropTypes.string,
  disabledDate: PropTypes.func,
  hasPaddingBottom: PropTypes.bool,
  small: PropTypes.bool,
  afterChange: PropTypes.func,
  disabled: PropTypes.bool,
  allowClear: PropTypes.bool,
  showToday: PropTypes.bool
}

DateInput.defaultProps = {
  input: {value: undefined, onChange: () => null},
  label: undefined,
  placeholder: undefined,
  meta: {},
  format: 'DD/MM/YYYY',
  disabledDate: undefined,
  hasPaddingBottom: false,
  small: false,
  afterChange: undefined,
  disabled: false,
  allowClear: false,
  showToday: true
}

export default DateInput
