import React, { useState, useEffect } from 'react'

import { PropTypes } from 'prop-types'
import { Translate, withConnect, withLocalize } from 'react-localize-redux'
import styled from 'styled-components'

import { GetAllWarehouses, GetAllWarehousesDestiny } from '../../../infra/requests/LogisticsRequests'
import {
  InputBox,
  InputLabel,
  SelectArrow,
  SelectInputStyled,
  SelectLoading
} from '../../../shared/components/inputs/InputStyles'
import { PrimaryColor } from '../../../shared/styles/_colors'

const Line = styled.div``
const Label = styled.div`
  color: ${PrimaryColor};
  font-size: 16px;
  line-height: 22px;
`

const Info = styled.div`
  font-size: 12px;
  line-height: 14px;
`
const SelectInputInventoryCount = ({
  input,
  label,
  meta,
  placeholder,
  allowClear,
  disabled,
  afterChange,
  translate
}) => {
  const [list, setList] = useState()
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    async function init() {
      setLoading(true)
      const { data, success } = await GetAllWarehousesDestiny()
      if (success) {
        const refactor = []
        data.items.forEach((current) => {
          refactor.push({
            label: ConstructLabel(current),
            value: current.warehouseId,
            name: current.warehouseName
          })
        })
        setList(refactor || [])
        setLoading(false)
      }
    }

    init()
  }, [])

  const ConstructLabel = (current) => (
    <Line>
      <Label>{`${current?.code} - ${current?.name}`}</Label>
      {current?.productive ? (
        <Info>
          <Translate id='TYPE' />: <Translate id='PRODUCTIVE' />
        </Info>
      ) : (
        <Info>
          <Translate id='TYPE' />: <Translate id='NOT_PRODUCTIVE' />
        </Info>
      )}
      <Info>
        <Translate id='LOCATION' />:{current?.contactDetail?.region}
      </Info>
      <Info>
        <Translate id='CLIENT' />:{current?.b2bclient?.name}
      </Info>
    </Line>
  )

  const handleChange = (value) => {
    input.onChange(value)
    if (typeof afterChange === 'function') {
      setTimeout(() => afterChange(value || undefined), 100)
    }
  }

  const defineValue = (value) => {
    if (value) {
      const found = list.find((x) => x.value === value)
      return found?.warehouseName || undefined
    }
    return undefined
  }

  const hasError = meta.invalid && meta.submitFailed

  return (
    <InputBox>
      {<Translate id='WAREHOUSE_NAME' /> && (
        <InputLabel><Translate id='WAREHOUSE_NAME' /></InputLabel>
      )}
      <SelectInputStyled
        hasError={hasError}
        placeholder={<Translate id='SELECT' />}
        value={defineValue(input.value)}
        onChange={handleChange}
        allowClear={allowClear}
        options={list}
        // suffixIcon={loading ? <SelectLoading /> : <SelectArrow />}
        disabled={disabled}
        loading={loading}
      />
    </InputBox>
  )
}

SelectInputInventoryCount.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.element
  ]),
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.element
  ]),
  allowClear: PropTypes.bool,
  disabled: PropTypes.bool,
  afterChange: PropTypes.func,
  client: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

SelectInputInventoryCount.defaultProps = {
  input: { value: undefined, onChange: () => null },
  label: undefined,
  placeholder: undefined,
  meta: {},
  allowClear: false,
  disabled: false,
  afterChange: undefined,
  client: undefined
}

export default withLocalize(SelectInputInventoryCount)
