import React from 'react'

import {Popover} from 'antd'
import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'
import styled from 'styled-components'

import {ReactComponent as InfoIcon} from '../../../../../assets/icons/info.svg'
import {PrimaryButtonColor} from '../../../../../shared/styles/_colors'
import {TableText} from './CompositionStyles'

export const CalculateAverage = (family, value) => {
  let avr = 0
  if (family && family.list) {
    family.list.forEach((item) => {
      avr += item[value] || 0
    })
    return Math.round((avr / family.list.length) * 100) / 100
  }
  return avr
}

export const Warning = styled(InfoIcon)`
  position: absolute;
  top: 0;
  right: 2px;
  height: 10px;
  path {
    fill: ${PrimaryButtonColor};
  }
`

const AverageLead = ({family, value, tag}) => (
  <Popover
    placement='topLeft'
    trigger='hover'
    content={<Translate id='AVG_VALUE_BETWEEN_MATERIALS_FROM_FAMILY' />}
    mouseEnterDelay={1}
  >
    <TableText>
      {CalculateAverage(family, value)}
      {tag && <span> {tag}</span>}
      <Warning />
    </TableText>
  </Popover>
)

AverageLead.propTypes = {
  family: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  tag: PropTypes.string
}

AverageLead.defaultProps = {
  tag: undefined
}

export default AverageLead
