import React, {useState} from 'react'

import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {Field} from 'redux-form'

import CloseBorderedIcon from '../../../assets/icons/cancel_border.svg'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import NumberIncrementInput from '../../../shared/components/inputs/NumberIncrementInput'
import AddProductInput from '../../../shared/components/inputs/Products/AddProductInput'
import {
  TableRow,
  ListCol,
  TableTitle,
  TableInput,
  TextTooltip,
  TableText,
  ListCheck
} from '../../../shared/components/table/CustomTableStyles'
import ActiveTranslation from '../../../shared/logic/translations/ActiveTranslation'
import {PageSubtitle, Margin} from '../../../shared/styles/BasicStyles'

const ArrivalProducts = ({fields, client, owner, warehouse, translate}) => {
  const [selected, setSelected] = useState([])

  const products = fields.getAll() || []

  const addTableProducts = (list) => {
    list.forEach((product) => {
      const found = products.find(
        (prod) => prod.reference === product.reference
      )
      if (!found) {
        fields.push(product)
      }
    })
  }

  const handleRemove = () => {
    selected
      .sort((a, b) => b - a)
      .forEach((index) => {
        fields.remove(index)
      })
    setSelected([])
  }

  const handleCheck = (e, index) => {
    const copy = [...selected]
    if (e.target.checked) {
      copy.push(index)
      setSelected(copy)
    } else {
      setSelected(copy.filter((i) => i !== index))
    }
  }

  const checkDisable = (item) => {
    if (item.orderSupplierId) {
      if (item.client !== client) return true
      if (item.owner !== owner) return true
      if (item.location !== warehouse?.contactDetailId) return true
    }
    return false
  }

  return (
    <>
      <PageSubtitle><Translate id='PRODUCTS' /></PageSubtitle>
      <Margin size={20} />
      <BaseButton
        disabled={selected.length <= 0}
        type='secondary'
        onClick={handleRemove}
        imagePath={CloseBorderedIcon}
        auto
      >
        <Translate id='REMOVE' />
      </BaseButton>
      <Margin size={20} />
      <TableRow $header>
        <ListCol xs={1} />
        <ListCol xs={3}>
          <TableTitle text={<Translate id='REFERENCE' />} />
        </ListCol>
        <ListCol xs={2}>
          <TableTitle text={<Translate id='EAN' />} />
        </ListCol>
        <ListCol xs={2}>
          <TableTitle text={<Translate id='CLIENT_REF' />} />
        </ListCol>
        <ListCol xs={4}>
          <TableTitle text={<Translate id='DESCRIPTION' />} />
        </ListCol>
        <ListCol xs={2}>
          <TableTitle text={<Translate id='COLOR' />} />
        </ListCol>
        <ListCol xs={1}>
          <TableTitle text={<Translate id='SIZE' />} />
        </ListCol>
        <ListCol xs={1}>
          <TableTitle text={<Translate id='WIDTH' />} />
        </ListCol>
        <ListCol xs={1}>
          <TableTitle text={<Translate id='MEASURE_UNIT' />} abbr={<Translate id='UM' />} />
        </ListCol>
        <ListCol xs={1}>
          <TableTitle text={<Translate id='ORDER_QUANTITY' />} abbr={<Translate id='ORDER_QTY' />} />
        </ListCol>
        <ListCol xs={2}>
          <TableTitle text={<Translate id='RECEIVED_QUANTITY' />} abbr={<Translate id='REC_QTY' />} />
        </ListCol>
        <ListCol xs={2}>
          <TableTitle text={<Translate id='UNIT_COST' />} />
        </ListCol>
        <ListCol xs={2}>
          <TableTitle text={<Translate id='TOTAL' />} />
        </ListCol>
      </TableRow>

      <TableRow>
        <ListCol xs={1} />
        <ListCol xs={3}>
          <TableInput $first>
            <AddProductInput
              placeholder={translate('SEARCH')}
              onSelectValues={addTableProducts}
              field='ref'
              client={client}
              clientRequired
              owner={owner}
              ownerRequired
            />
          </TableInput>
        </ListCol>
        <ListCol xs={2} />
        <ListCol xs={2} />
        <ListCol xs={4}>
          <TableInput $first>
            <AddProductInput
              placeholder={translate('SEARCH')}
              dataLabel='productTranslation'
              onSelectValues={addTableProducts}
              field='name'
              client={client}
              clientRequired
              owner={owner}
              ownerRequired
            />
          </TableInput>
        </ListCol>
      </TableRow>

      {products.map((product, index) => {
        const disable = checkDisable(product)
        return (
          <TableRow key={index} $disable={disable}>
            <ListCol xs={1}>
              {!product.orderSupplierId && (
                <ListCheck
                  checked={selected.includes(index)}
                  onChange={(e) => handleCheck(e, index)}
                />
              )}
            </ListCol>
            <ListCol xs={3}>
              <TextTooltip text={product.reference || '-'} />
            </ListCol>
            <ListCol xs={2}>
              <TextTooltip text={product.barcode || '-'} />
            </ListCol>
            <ListCol xs={2}>
              <TextTooltip text={product.referenceClient || '-'} />
            </ListCol>
            <ListCol xs={4}>
              <TextTooltip
                text={
                  <ActiveTranslation
                    value={product.description}
                    tag='name'
                  />
                }
              />
            </ListCol>
            <ListCol xs={2}>
              <TextTooltip
                text={
                  <ActiveTranslation
                    value={product?.color?.colorTranslation || '-'}
                    tag='name'
                  />
                }
              />
            </ListCol>
            <ListCol xs={1}>
              <TextTooltip text={product?.size?.nameEu || '-'} />
            </ListCol>
            <ListCol xs={1}>
              <TextTooltip text={product?.productWidth?.code || '-'} />
            </ListCol>
            <ListCol xs={1}>
              <TextTooltip text={product?.measureUnit?.code || '-'} />
            </ListCol>
            <ListCol xs={1}>
              <TableText>{product?.orderQuantity || '-'}</TableText>
            </ListCol>
            <ListCol xs={2}>
              <TableInput>
                <Field
                  component={NumberIncrementInput}
                  name={`${fields.name}[${index}].quantity`}
                  disabled={disable}
                />
              </TableInput>
            </ListCol>
            <ListCol xs={2}>
              <TableInput>
                <Field
                  component={NumberIncrementInput}
                  name={`${fields.name}[${index}].totalValue`}
                  disabled={disable}
                />
                {' €'}
              </TableInput>
            </ListCol>
            <ListCol xs={2}>
              <TableText>
                {(
                  (product.quantity || 0) * (product.totalValue || 0)
                ).toFixed(2)}
                €
              </TableText>
            </ListCol>
          </TableRow>
        )
      })}
    </>
  )
}

ArrivalProducts.propTypes = {
  fields: PropTypes.object.isRequired,
  client: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  owner: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  warehouse: PropTypes.object
}

ArrivalProducts.defaultProps = {
  client: undefined,
  owner: undefined,
  warehouse: undefined
}

export default withLocalize(connect()(ArrivalProducts))
