import React, {useState} from 'react'

import {Col, Row} from 'antd'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {Field, change} from 'redux-form'

import BaseButton from '../../../../../../shared/components/buttons/BaseButton'
import SelectInput from '../../../../../../shared/components/inputs/SelectInput'
import TextAreaInput from '../../../../../../shared/components/inputs/TextAreaInput'
import TextInput from '../../../../../../shared/components/inputs/TextInput'
import GetObjectByID from '../../../../../../shared/logic/arrays/GetObjectByID'
import {Margin, TitleH4} from '../../../../../../shared/styles/BasicStyles'

const BannerTranslations = ({fields, languages, dispatch}) => {
  const [language, setLanguage] = useState(undefined)

  const list = fields.getAll()

  const filterLanguages = () =>
    languages.filter((lang) => {
      const found =
        list?.length > 0 &&
        list.find((l) => l.languageId === lang.languageId)
      if (!found) return true
      if (found.show) return false
      if (
        (!found.title || found.title === '') &&
        (!found.description || found.description === '') &&
        (!found.buttonText || found.buttonText === '') &&
        (!found.buttonText2 || found.buttonText2 === '')
      ) {
        return true
      }
      return false
    })

  const enableLanguage = () => {
    if (list?.length > 0) {
      const index = list.findIndex((elem) => elem.languageID === language)
      if (index > -1) {
        dispatch(
          change('banner_form', `${fields.name}[${index}].show`, true)
        )
      } else {
        fields.push({
          description: '',
          buttonText: '',
          buttonText2: '',
          languageId: parseInt(language, 10),
          show: true
        })
      }
    } else {
      fields.push({
        description: '',
        buttonText: '',
        buttonText2: '',
        languageId: parseInt(language, 10),
        show: true
      })
    }
    setLanguage(undefined)
  }
  return (
    <>
      <Row gutter={[20, 20]} align='bottom'>
        <Col xs={14} lg={5}>
          <SelectInput
            label={<Translate id='ADD_LANGUAGE' />}
            input={{
              value: language,
              onChange: setLanguage
            }}
            placeholder={<Translate id='SELECT' />}
            dataKey='languageId'
            dataLabel='name'
            data={filterLanguages()}
          />
        </Col>
        <Col xs={4} lg={3}>
          <BaseButton
            type='secondary'
            disabled={!language}
            onClick={enableLanguage}
          >
            <Translate id='ADD' />
          </BaseButton>
        </Col>
      </Row>
      <Margin size={20} />
      {list?.length > 0 &&
        list
          .filter(
            (f) =>
              (f.title && f.title !== '') ||
              (f.description && f.description !== '') ||
              (f.buttonText && f.buttonText !== '') ||
              (f.buttonText2 && f.buttonText2 !== '') ||
              f.show
          )
          .map((currentField, index) => (
            <>
              <Row gutter={[20, 20]} key={index}>
                <Col xs={24}>
                  <TitleH4 $op>
                    {
                      GetObjectByID(
                        languages,
                        'languageId',
                        currentField?.languageId
                      )?.name
                    }
                  </TitleH4>
                </Col>
                <Col xs={6}>
                  <Field
                    component={TextInput}
                    name={`${fields.name}[${index}].title`}
                    label={<Translate id='TITLE' />}
                  />
                </Col>
                <Col xs={6}>
                  <Field
                    component={TextAreaInput}
                    name={`${fields.name}[${index}].description`}
                    label={<Translate id='TEXT' />}
                    rows={3}
                  />
                </Col>

                <Col xs={6}>
                  <Field
                    component={TextInput}
                    name={`${fields.name}[${index}].buttonText`}
                    label={<Translate id='BUTTON_TEXT' />}
                  />
                </Col>
                <Col xs={6}>
                  <Field
                    component={TextInput}
                    name={`${fields.name}[${index}].buttonText2`}
                    label={<Translate id='BUTTON_2_TEXT' />}
                  />
                </Col>
              </Row>
              <Margin size={20} />
            </>
          ))}
      <Margin size={20} />
    </>
  )
}

BannerTranslations.propTypes = {
  fields: PropTypes.object.isRequired,
  languages: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired
}

export default withLocalize(BannerTranslations)
