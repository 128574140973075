export default [
  {
    name: 'COCKPIT',
    link: '/',
    permissions: 1,
    exact: true
  },
  {
    name: 'MESSAGING',
    link: '/messaging',
    permissions: 1,
    exact: false
  },
  {
    name: 'ORDERS',
    link: '/orders',
    permissions: 1,
    exact: false
  },
  {
    name: 'SUPPLIERS',
    link: '/suppliers',
    permissions: 1,
    exact: false
  },
  {
    name: 'PRODUCTS',
    link: '/products',
    permissions: 1,
    exact: false
  },
  {
    name: 'FITTING',
    link: '/fitting',
    permissions: 1,
    exact: false
  },
  {
    name: 'LOGISTICS',
    link: '/logistics',
    permissions: 1,
    exact: false
  },
  {
    name: 'CLIENT',
    link: '/clients',
    permissions: 1,
    exact: false
  },
  {
    name: 'REPORTS',
    link: '/reports',
    permissions: 1,
    exact: false
  }
]
