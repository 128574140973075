import React, {createRef, useState} from 'react'

import {Fade} from '@egjs/flicking-plugins'
import Flicking from '@egjs/react-flicking'
import {Row, Col} from 'antd'
import {uniqBy} from 'lodash'
import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'
import {Field} from 'redux-form'

import ArrowLeftIcon from '../../../../assets/icons/arrow_left.svg'
import ArrowRightIcon from '../../../../assets/icons/arrow_right.svg'
import DropDownIcon from '../../../../assets/icons/down.svg'
import EmptyImageIcon from '../../../../assets/placeholder.jpg'
import PropertyDescription from '../../../../shared/components/description/PropertyDescription'
import ProductUploadFileInput from '../../../../shared/components/inputs/Products/ProductUploadFileInput'
import GetImage from '../../../../shared/logic/arrays/ImageFunction'
import ActiveTranslation from '../../../../shared/logic/translations/ActiveTranslation'
import {BorderColor} from '../../../../shared/styles/_colors'
import {
  StyledFlex,
  TitleH1,
  TitleH5,
  BodyH5,
  Margin,
  TitleH2,
  TitleH3
} from '../../../../shared/styles/BasicStyles'
import {
  SingleColorContainer,
  ProductColor
} from '../../../orders/manage_b2b_b2b2c/components/Styles'
import {
  ProductDropDownIcon,
  FlickingArrow,
  CarrouselImageContainer,
  CarrouselImage,
  EmptyImageText,
  HeaderPrice
} from '../../ProductsStyles'

const ProductHeader = ({product, colors}) => {
  const [headerIsOpened, setHeaderIsOpened] = useState(true)

  const flicking = createRef()

  const onClickLeft = () => {
    flicking.current.prev()
  }

  const onClickRight = () => {
    flicking.current.next()
  }

  if (headerIsOpened) {
    return (
      <>
        <Row>
          <Col xs={24} lg={16} style={{zIndex: 1}}>
            <FlickingArrow
              $left
              src={ArrowLeftIcon}
              onClick={onClickLeft}
            />
            <Flicking
              ref={flicking}
              gap={10}
              circular
              deceleration={0.015}
              moveType='snap'
              plugins={[new Fade()]}
              collectStatistics={false}
            >
              {product?.productPropertiesSection?.productImage?.length >
              0 ? (
                  product.productPropertiesSection.productImage
                    .filter((x) => !x.layout)
                    .map((currentImage, index) => (
                      <CarrouselImageContainer key={index}>
                        <CarrouselImage src={GetImage(currentImage)} />
                      </CarrouselImageContainer>
                    ))
                ) : (
                  <CarrouselImageContainer>
                    <CarrouselImage src={EmptyImageIcon} />
                    <EmptyImageText>
                      <Translate id='REF' />: {product.referenceCode}
                    </EmptyImageText>
                  </CarrouselImageContainer>
                )}
            </Flicking>
            <FlickingArrow
              $right
              src={ArrowRightIcon}
              onClick={onClickRight}
            />
          </Col>
          <Col xs={24} lg={8}>
            <Row style={{padding: '0px 15px'}}>
              <ProductDropDownIcon
                src={DropDownIcon}
                $open={headerIsOpened}
                onClick={() => setHeaderIsOpened(!headerIsOpened)}
              />
              <Col xs={24}>
                <BodyH5 $opacity='50%'>
                  <Translate id='REF' />: {product?.referenceCode}
                </BodyH5>
              </Col>
              <Col xs={24}>
                <StyledFlex
                  $direction='row'
                  $justify='flex-start'
                  $align='flex-start'
                >
                  <TitleH2>
                    <ActiveTranslation
                      value={
                        product?.productPropertiesSection
                          ?.productTranslation
                      }
                      tag='name'
                    />
                  </TitleH2>
                </StyledFlex>
              </Col>
              <Col xs={12}>
                <StyledFlex
                  $align='flex-start'
                  $justify='center'
                  style={{
                    height: '100%',
                    borderTop: `1px solid ${BorderColor}`,
                    borderBottom: `1px solid ${BorderColor}`,
                    borderRight: `1px solid ${BorderColor}`
                  }}
                >
                  <TitleH5>
                    <Translate id='COLORS' />
                  </TitleH5>
                  <StyledFlex
                    $justify='flex-start'
                    $align='center'
                    $direction='row'
                  >
                    {colors
                      ? uniqBy(colors, 'colorId').map((color, index) => (
                        <SingleColorContainer
                          key={index}
                          $marginLeft={index === 0 ? '0px' : '9px'}
                        >
                          <ProductColor
                            $file={
                              color.file
                                ? URL.createObjectURL(color.file)
                                : undefined
                            }
                            $color={color?.html}
                          />
                        </SingleColorContainer>
                      ))
                      : null}
                  </StyledFlex>
                </StyledFlex>
              </Col>
              <Col xs={12}>
                <StyledFlex
                  $direction='row'
                  $justify='space-between'
                  style={{
                    borderTop: `1px solid ${BorderColor}`,
                    borderBottom: `1px solid ${BorderColor}`,
                    padding: '3px'
                  }}
                >
                  <StyledFlex
                    $direction='column'
                    $justify='flex-start'
                    $align='flex-start'
                  >
                    <div>PVP</div>
                    <div>PVP(s/IVA) </div>
                  </StyledFlex>
                  <StyledFlex
                    $direction='column'
                    $justify='flex-end'
                    $align='flex-end'
                  >
                    <HeaderPrice>{product.totalValue}€</HeaderPrice>
                    <HeaderPrice>{product.baseValue}€</HeaderPrice>
                  </StyledFlex>
                </StyledFlex>
              </Col>

              <Col xs={24}>
                <StyledFlex>
                  <BodyH5 $top='20'>
                    <ActiveTranslation
                      value={
                        product?.productPropertiesSection
                          ?.productTranslation
                      }
                      tag='description'
                    />
                  </BodyH5>
                </StyledFlex>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row
          justify='middle'
          align='middle'
          style={{
            borderTop: `1px solid ${BorderColor}`,
            margin: '15px 0px 0px 0px',
            padding: '10px 0px'
          }}
          gutter={[20, 0]}
        >
          <Col
            xs={12}
            lg={3}
            style={{borderRight: `1px solid ${BorderColor}`}}
          >
            <PropertyDescription
              label={<Translate id='TYPE' />}
              value={
                <ActiveTranslation
                  value={
                    product?.productPropertiesSection?.productType
                      ?.productTypeTranslation
                  }
                  tag='name'
                />
              }
            />
          </Col>
          <Col
            xs={12}
            lg={3}
            style={{borderRight: `1px solid ${BorderColor}`}}
          >
            <PropertyDescription
              label={<Translate id='COMPANY' />}
              value={product?.company || 'N/A'}
            />
          </Col>
          <Col
            xs={12}
            lg={4}
            style={{borderRight: `1px solid ${BorderColor}`}}
          >
            <PropertyDescription
              label={<Translate id='MAIN_CATEGORY' />}
              value={
                <ActiveTranslation
                  value={
                    product?.productPropertiesSection?.mainCategory
                      ?.categoryTranslation
                  }
                  tag='name'
                />
              }
            />
          </Col>
          <Col
            xs={12}
            lg={4}
            style={{borderRight: `1px solid ${BorderColor}`}}
          >
            <PropertyDescription
              label={<Translate id='CATEGORY' />}
              value={
                <ActiveTranslation
                  value={
                    product?.productPropertiesSection?.category
                      ?.categoryTranslation || 'N/A'
                  }
                  tag='name'
                />
              }
            />
          </Col>
          <Col
            xs={12}
            lg={4}
            style={{borderRight: `1px solid ${BorderColor}`}}
          >
            <PropertyDescription
              label={<Translate id='SUPPLIER' />}
              value={product?.productPropertiesSection?.supplier || 'N/A'}
            />
          </Col>
          <Col xs={12} lg={6}>
            <Field
              name='productAttachment'
              component={ProductUploadFileInput}
            />
          </Col>
        </Row>
      </>
    )
  }

  return (
    <>
      <Row
        justify='middle'
        align='middle'
        style={{
          padding: '0px 10px'
        }}
        gutter={[20, 0]}
      >
        <Col
          xs={12}
          lg={3}
          style={{borderRight: `1px solid ${BorderColor}`}}
        >
          <PropertyDescription
            label={<Translate id='TYPE' />}
            value={
              <ActiveTranslation
                value={
                  product?.productPropertiesSection?.productType
                    ?.productTypeTranslation
                }
                tag='name'
              />
            }
          />
        </Col>
        <Col
          xs={12}
          lg={3}
          style={{borderRight: `1px solid ${BorderColor}`}}
        >
          <PropertyDescription
            label={<Translate id='COMPANY' />}
            value={product?.company || 'N/A'}
          />
        </Col>
        <Col
          xs={12}
          lg={4}
          style={{borderRight: `1px solid ${BorderColor}`}}
        >
          <PropertyDescription
            label={<Translate id='MAIN_CATEGORY' />}
            value={
              <ActiveTranslation
                value={
                  product?.productPropertiesSection?.mainCategory
                    ?.categoryTranslation
                }
                tag='name'
              />
            }
          />
        </Col>
        <Col
          xs={12}
          lg={3}
          style={{borderRight: `1px solid ${BorderColor}`}}
        >
          <PropertyDescription
            label={<Translate id='CATEGORY' />}
            value={
              <ActiveTranslation
                value={
                  product?.productPropertiesSection?.category
                    ?.categoryTranslation
                }
                tag='name'
              />
            }
          />
        </Col>
        <Col
          xs={12}
          lg={3}
          style={{borderRight: `1px solid ${BorderColor}`}}
        >
          <PropertyDescription
            label={<Translate id='SUPPLIER' />}
            value={product?.productPropertiesSection?.supplier || 'N/A'}
          />
        </Col>
        <Col xs={24} lg={8}>
          <ProductDropDownIcon
            src={DropDownIcon}
            $open={headerIsOpened}
            onClick={() => setHeaderIsOpened(!headerIsOpened)}
          />
          <StyledFlex
            $direction='row'
            $justify='flex-start'
            $align='flex-start'
            $width='330px'
          >
            <TitleH3 $left='5'>
              <ActiveTranslation
                value={
                  product?.productPropertiesSection?.productTranslation
                }
                tag='name'
              />
            </TitleH3>
          </StyledFlex>
        </Col>
      </Row>
      <Margin size={15} />
    </>
  )
}

ProductHeader.propTypes = {
  product: PropTypes.object.isRequired
}

export default ProductHeader
