import React, { Component } from 'react'

import { DownloadOutlined, BackwardOutlined } from '@ant-design/icons'
import { Row, Col, Popover, Spin } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import styled from 'styled-components'

import EditDatePopup from './EditDatePopup'
import DropdownIcon from '../../../assets/icons/dropdown_blue.svg'
import Info from '../../../assets/icons/info.svg'
import { GetSupplierOrders, UpdateOrderSupplierDate, EditMovement, GetResendOrderSupplierSets, RevertOrderSupplierSetToProcessingPO } from '../../../infra/requests/SupplierOrdersRequests'
import AlertService from '../../../shared/components/alert/AlertService'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import DateInput from '../../../shared/components/inputs/DateInput'
import { TextInputStyled } from '../../../shared/components/inputs/InputStyles'
import SelectInput from '../../../shared/components/inputs/SelectInput'
import TextInput from '../../../shared/components/inputs/TextInput'
import BaseTable from '../../../shared/components/table/BaseTable'
import { ExpandableContent } from '../../../shared/components/table/TableStyles'
import DownloadAttachment from '../../../shared/logic/attachments/DownloadAttachment'
import formatDate from '../../../shared/logic/dates/FormatDate'
import RoundCurrency from '../../../shared/logic/numbers/RoundCurrency'
import { ConstructQuery } from '../../../shared/logic/queries/EndpointQueries'
import ActiveTranslation from '../../../shared/logic/translations/ActiveTranslation'
import {
  ErrorColor,
  PrimaryColor,
  SuccessColor,
  TableTextColor
} from '../../../shared/styles/_colors'
import {
  ToggleImageContainer,
  ToggleImage,
  TextPopover,
  CircleIcon,
  OverdueAlert,
  Flex,
  TextPopoverLink
} from '../../../shared/styles/BasicStyles'
import { SOTotalSection, FooterLine } from '../components/POStyles'

let timeout

const HoverFile = styled.div`
  cursor: pointer;
  margin-top: 5px;
  &:hover {
    text-decoration: underline;
  }
`
const getSupplierSetButtonName = (state) => {
  switch (state) {
    case 'New Arrival':
      return 'Start reception'
    case 'Waiting Confirmation':
      return 'Continue reception'
    default:
      return null
  }
}

const calculateOrderTotal = (SO) => {
  let total = 0
  if (SO && SO.orderSupplier) {
    SO.orderSupplier.forEach((order) => {
      if (order.currency.currencyId !== 1) {
        total = parseFloat(total) + parseFloat(order.quantityOrdered * order.priceNegotiated * order.inverseExchangeRate)
      } else {
        total = parseFloat(total) + parseFloat(order.quantityOrdered * order.priceNegotiated)
      }
    })
  }
  return RoundCurrency(total)
}

class SupplierOrderList extends Component {
  state = {
    loading: true,
    rows: [],
    page: 1,
    pageSize: 10,
    total: 0,
    filters: { search: '' },
    fetched: false,
    loadingRow: {}
  }

  emailInputRefs = new Map(); // This will store refs for each row

  componentDidMount() {
    this.updateTable()
  }

  revertSO = async (SO) => {
    AlertService.confirm(
      'Warning',
      `Do you really want to revert the Supplier Order to Processing PO - Confirmation?
      Take in consideration that the supplier already received by email this Order.`,
      async () => {
        const { success } = await RevertOrderSupplierSetToProcessingPO(SO.orderSupplierSetId)
        if (success) {
          this.updateTable()
        }
      }
    )
  }

  supplierSetBtnAction = async (movementId, state, stateId) => {
    this.setState({ loading: true })

    if (stateId === null) {
      switch (state) {
        case 'New Arrival':
        case 'Waiting Confirmation':
          stateId = 4
          break
        default:
          break
      }
    }

    await EditMovement(movementId, { MovementStatusId: stateId })

    this.updateTable()
  }

  MainOrderStatus = (value) => {
    const { orderStatus } = this.props

    let orderStatusIdReturn = 20
    if (value?.length > 0) {
      value.forEach((item) => {
        if (item.orderStatus.orderStatusId < orderStatusIdReturn) {
          orderStatusIdReturn = item.orderStatus.orderStatusId
        }
      })
      const status = orderStatus.find(
        (x) => x.orderStatusId === orderStatusIdReturn
      )?.orderStatusTranslation

      return status ? (
        <div
          style={{
            color:
              orderStatusIdReturn === 1
                ? PrimaryColor
                : orderStatusIdReturn === 5
                  ? SuccessColor
                  : orderStatusIdReturn === 6
                    ? ErrorColor
                    : TableTextColor
          }}
        >
          <ActiveTranslation value={status} tag='name' />
        </div>
      ) : (
        <Translate id='NA' />
      )
    }
    return <Translate id='NA' />
  }

  MainDaysOverdue = (value) => {
    const dateArray = []
    let dif = 0
    if (value?.length > 0) {
      value.forEach((item) => {
        if (
          item.orderStatus.orderStatusId === 1 ||
          item.orderStatus.orderStatusId === 2
        ) {
          if (item?.dateExpected) {
            const compare = moment().isAfter(item?.dateExpected)
            if (compare) dateArray.push(moment(item.dateExpected))
          }
        }
      })
      dif = moment().diff(moment.min(dateArray), 'days')
    }

    if (dif !== 0) {
      return (
        <OverdueAlert>{dif > 9 ? dif : `0${dif}`}</OverdueAlert>
      )
    }
  }

  DaysOverdue = (value) => {
    let dif = 0

    if (
      value.orderStatus.orderStatusId === 1 ||
      value.orderStatus.orderStatusId === 2
    ) {
      if (value?.dateExpected) {
        const compare = moment().isAfter(value?.dateExpected)

        if (compare) {
          dif = moment().diff(moment.min(value.dateExpected), 'days')
        }
      }
    }

    return dif
  }

  renderFiles = (attachments) => {
    if (Array.isArray(attachments) && attachments.length) {
      const content = attachments.map((attach) => (
        <HoverFile
          key={attach.attachmentId}
          onClick={() => DownloadAttachment(attach)}
        >
          {attach.name}
        </HoverFile>
      ))
      return (
        <Popover content={content} title={<Translate id='ATTACHMENTS' />} trigger='hover'>
          <DownloadOutlined style={{ cursor: 'pointer' }} />
        </Popover>
      )
    }
    return null
  }

  tableColumns = () => [
    {
      type: 'text',
      dataIndex: 'orderSupplierSetId',
      title: 'Set',
      render: (value) => `#${value}`
    },
    {
      type: 'text',
      dataIndex: 'supplier',
      title: <Translate id='SUPPLIER' />,
      render: (value) => value?.name
    },
    {
      type: 'text',
      dataIndex: 'dateCreated',
      title: <Translate id='ORDER_DATE' />,
      render: (value) => moment(value).format('DD/MM/YYYY')
    },
    {
      type: 'text',
      dataIndex: 'paymentCondition',
      title: <Translate id='PAYMENT_CONDITIONS' />,
      render: (value) => value?.name
    },
    {
      type: 'text',
      title: <Translate id='TOTAL_VALUE_EUR' />,
      render: (data) => `${calculateOrderTotal(data)}€`
    },
    {
      type: 'text',
      title: <Translate id='FILES' />,
      dataIndex: 'attachment',
      render: (value) => this.renderFiles(value)
    },
    {
      type: 'text',
      title: <Translate id='ORDER_STATUS' />,
      dataIndex: 'orderSupplier',
      render: (value) => this.MainOrderStatus(value)
    },
    {
      type: 'text',
      title: <Translate id='DAYS_OVERDUE' />,
      dataIndex: 'orderSupplier',
      render: (value) => this.MainDaysOverdue(value)
    },
    {
      type: 'text',
      title: <Translate id='MOVEMENTID' />,
      dataIndex: 'movementId'
    },
    {
      type: 'text',
      title: <Translate id='MOVEMENTSTATUS' />,
      dataIndex: 'movementStatus'
    }
  ]

  renderDescription = (data) => {
    if (data.serviceId) {
      const product = data?.product?.productTranslation
      const color = data?.color?.colorTranslation
      const size = data?.size?.nameEu
      const width = data?.productWidth?.productWidthTranslation

      return (
        <div>
          <ActiveTranslation
            value={data?.service?.serviceTranslation}
            tag='name'
          />{' '}
          (
          <span>
            <ActiveTranslation value={product} tag='name' />
          </span>
          ,{' '}
          <span>
            <ActiveTranslation value={color} tag='name' />
          </span>
          , <span>{size}</span>,{' '}
          <span>
            <ActiveTranslation value={width} tag='name' />
          </span>
          )
        </div>
      )
    }
    return (
      <ActiveTranslation
        value={data?.product?.productTranslation}
        tag='name'
      />
    )
  }

  handleDateChange = async (order, date) => {
    this.setState({ loading: true })
    await UpdateOrderSupplierDate(order, { dateExpected: date })
    this.updateTable()
  }

  popoverContent = (value) => [
    { translateId: 'CLIENT', displayValue: value.orderTabInfoResource.clientName || 'N/A' },
    { translateId: 'ORDER_DATE', displayValue: formatDate(value.orderTabInfoResource.orderDate) || 'N/A' },
    { translateId: 'EXPECTED_DELIVERY_DATE', displayValue: formatDate(value.orderTabInfoResource.expectedDeliveryDate) || 'N/A' },
    { translateId: 'ORDER_QUANTITY', displayValue: value.orderTabInfoResource.orderQty || 'N/A' },
    { translateId: 'PO', displayValue: value.orderPo.orderPoid || 'N/A' }
  ]

  ExpandableColumns = (SO) => {
    const { router } = this.props
    const columns = [
      {
        type: 'text',
        title: 'PO',
        render: (data) => `#${data?.orderPo?.orderPoid}`
      },
      {
        type: 'text',
        title: <Translate id='REF_SKYPRO' />,
        render: (value) => {
          const content = (
            <>
              <div style={{ display: 'flex' }}>
                <TextPopover $bold>
                  <Translate id='ORDER_NMR' />:
                </TextPopover>

                <TextPopoverLink onClick={() => router.history.push(`/orders/b2b/${value.orderTabInfoResource.orderB2bId}`)}>
                  {value.orderTabInfoResource.orderNumber || 'N/A'}
                </TextPopoverLink>

              </div>
              {
                this.popoverContent(value).map((item, index) => (
                  <div style={{ display: 'flex' }} key={index}>
                    <TextPopover $bold>
                      <Translate id={item.translateId} />:
                    </TextPopover>
                    <TextPopover>{item.displayValue}</TextPopover>
                  </div>

                ))
              }
            </>
          )
          return (
            <>
              <Popover
                placement='bottomRight'
                trigger={window.innerWidth <= 768 ? 'click' : 'hover'}
                content={content}

              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  {value.referenceSkypro || 'N/A'}
                  <CircleIcon>
                    <img src={Info} alt={<Translate id='INFO' />} />
                  </CircleIcon>
                </div>
              </Popover>
            </>
          )
        }
      },
      {
        type: 'text',
        title: <Translate id='DESCRIPTION' />,
        render: this.renderDescription
      },
      {
        type: 'text',
        dataIndex: 'quantityOrdered',
        title: <Translate id='ORDER_QTY' />
      },
      {
        type: 'text',
        dataIndex: 'quantityReceived',
        title: <Translate id='QTY_RECEIVED' />
      },
      {
        type: 'text',
        title: <Translate id='UNIT_COST_EUR' />,
        render: (data) => `${RoundCurrency(data.priceNegotiated * data.exchangeRateUsed)}€`
      },
      {
        type: 'text',
        title: <Translate id='PRICE_EUROS' />,
        render: (data) =>
          `${RoundCurrency(data.quantityOrdered * data.priceNegotiated * data.exchangeRateUsed)}€`
      },
      {
        type: 'text',
        dataIndex: 'deliveryContactDetail',
        title: <Translate id='LOCATION' />,
        render: (value) => value?.warehouseName || 'N/A'
      },
      {
        type: 'text',
        title: <Translate id='FILES' />,
        dataIndex: 'attachment',
        render: (value) => this.renderFiles(value)
      },
      {
        type: 'text',
        dataIndex: 'orderStatus',
        title: <Translate id='ORDER_STATUS' />,
        render: (value) => (
          <div
            style={{
              color:
                value?.orderStatusId === 1
                  ? PrimaryColor
                  : value?.orderStatusId === 5
                    ? SuccessColor
                    : value?.orderStatusId === 6
                      ? ErrorColor
                      : TableTextColor
            }}
          >
            <ActiveTranslation
              value={value?.orderStatusTranslation}
              tag='name'
            />
          </div>
        )
      },
      {
        type: 'text',
        title: <Translate id='DELIVERY' />,
        render: (value) => {
          const dif = this.DaysOverdue(value)

          const content = (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <TextPopover $bold>
                <Translate id='DAYS_OVERDUE' />:
              </TextPopover>
              <TextPopover>
                {dif !== 0 && (
                  <OverdueAlert>{dif > 9 ? dif : `0${dif}`}</OverdueAlert>
                )}
              </TextPopover>
            </div>
          )
          return (
            <>
              {dif !== 0 ? (
                <>
                  <Popover
                    placement='bottomRight'
                    trigger='hover'
                    content={content}
                  >
                    <div
                      style={{
                        color: ErrorColor,
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      {value.dateExpected ? (
                        moment(value.dateExpected).format('DD/MM/YYYY')
                      ) : (
                        <Translate id='NA' />
                      )}

                    </div>
                  </Popover>
                  <EditDatePopup
                    dateExpected={value.dateExpected}
                    handleDateChange={(date) => this.handleDateChange(value.orderSupplierId, date)}
                  />
                </>
              ) : value.dateExpected ? (
                <>
                  {moment(value.dateExpected).format('DD/MM/YYYY')}
                  {value?.orderStatus?.orderStatusId !== 5 && <EditDatePopup
                    dateExpected={value.dateExpected}
                    handleDateChange={(date) => this.handleDateChange(value.orderSupplierId, date)}
                  />}
                </>
              ) : (
                <>
                  <Translate id='NA' />
                  <EditDatePopup
                    dateExpected={value.dateExpected}
                    handleDateChange={(date) => this.handleDateChange(value.orderSupplierId, date)}
                  />
                </>
              )}
            </>
          )
        }
      }
    ]

    const currency = SO.orderSupplier.find((s) => s.currency.currencyId !== 1)

    if (currency) {
      columns.splice(6, 0, {
        type: 'text',
        title: <Translate id='UNIT_COST' />,
        render: (data) => data.currency?.currencyId !== 1
          ? `${RoundCurrency(data.priceNegotiated)} ${data.currency?.code}`
          : '-'
      })
      columns.splice(7, 0, {
        type: 'text',
        title: <Translate id='PRICE' />,
        render: (data) => data.currency?.currencyId !== 1
          ? `${RoundCurrency(data.quantityOrdered * data.priceNegotiated)} ${data.currency?.code}`
          : '-'
      })
    }

    return columns
  }

  updateTable = async () => {
    const { page, pageSize, filters } = this.state
    const { data } = await GetSupplierOrders(
      page,
      pageSize,
      ConstructQuery(filters)
    )

    this.setState({
      rows: data?.items,
      total: data?.totalItems,
      loading: false
    })
  }

  setFilters = (values, time) => {
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(() => {
      this.setState(() => ({
        filters: values,
        page: 1,
        loading: true
      }), this.updateTable)
    }, time)
  }

  onChangePagination = (page) => {
    this.setState({ page, loading: true }, this.updateTable)
  }

  ExpandRow = (record) => {
    if (record.movementStatus === 'Incomplete') {
      return (this.renderExpandRow2Buttons(record))
    }

    const btnName = getSupplierSetButtonName(record.movementStatus)
    return (this.renderExpandRow(record, btnName))
  }

  handleResend = async (orderSupplierSetId, emailValue) => {
    const isValidEmail = this.validateEmail(emailValue)

    // Definir a linha como carregando
    this.setState((prevState) => ({
      loadingRow: { ...prevState.loadingRow, [orderSupplierSetId]: true }
    }))

    if (!isValidEmail) {
      AlertService.error(
        'Invalid Email',
        'Please enter a valid email address.'
      )

      this.setState((prevState) => ({
        loadingRow: { ...prevState.loadingRow, [orderSupplierSetId]: false }
      }))
      return
    }

    const payload = {
      orderSupplierSetId,
      email: emailValue
    }

    const { success } = await GetResendOrderSupplierSets(payload)

    if (success) {
      AlertService.success(
        'Success',
        'This Purchase Order has been re-submitted to the supplier via email successfully.'
      )
    }

    // Remover a linha do estado de carregamento
    this.setState((prevState) => ({
      loadingRow: { ...prevState.loadingRow, [orderSupplierSetId]: false }
    }))
  };

  validateEmail = (email) => {
    // Basic email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  };

  getRowIndex = (record) => this.state.rows.findIndex((row) => row.orderSupplierSetId === record.orderSupplierSetId)

  renderExpandRow = (record, btnName, SO) => {
    const { dispatch } = this.props
    return (

      <ExpandableContent>
        <BaseTable
          rowKey='orderSupplierId'
          datasource={record.orderSupplier}
          columns={this.ExpandableColumns(record)}
          pagination={{ render: false }}
          scroll={{ x: 0 }}
        />
        <SOTotalSection>
          <Flex
            style={{
              justifyContent: record.orderSupplier[0].orderStatus.dataStep === 'Processing' ? 'space-between' : 'flex-end',
              width: '98%'
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'end', width: '660px' }}>

              <div style={{ maxWidth: '350px', minWidth: '300px', marginRight: 10 }}>
                <TextInputStyled
                  type='email'
                  ref={(input) => this.emailInputRefs.set(record.orderSupplierSetId, input)}
                  defaultValue={record.email}
                  style={{ height: '26px' }}
                />
              </div>

              <BaseButton
                size='small'
                style={{ width: 'auto', marginRight: '1%' }}
                disabled={this.state.fetched || this.state.loadingRow[record.orderSupplierSetId]} // Disable if fetching or specific row is loading
                onClick={async () => {
                  const emailValue = this.emailInputRefs.get(record.orderSupplierSetId)?.value
                  await this.handleResend(record.orderSupplierSetId, emailValue)
                }}
              >
                {this.state.loadingRow[record.orderSupplierSetId] ? <div style={{ marginTop: '3px' }}><Spin size='small' /></div> : 'Resend'}
              </BaseButton>

              {record.orderSupplier[0].orderStatus.dataStep === 'Processing' ? (
                <BaseButton
                  style={{ marginRight: '20px' }}
                  auto
                  size='small'
                  type='secondary'
                  loading={SO?.loading}
                  onClick={() => this.revertSO(record, this.getRowIndex(record), dispatch)}
                >
                  <BackwardOutlined /> Revert to Processing PO
                </BaseButton>
              ) : null}
            </div>
            {btnName != null ? (
              <BaseButton
                size='small'
                style={{ width: 'auto', marginRight: '1%' }}
                onClick={async () => { await this.supplierSetBtnAction(record.movementId, record.movementStatus, null) }}
              >
                {btnName}
              </BaseButton>
            ) : null}
          </Flex>

          <FooterLine bold style={{ width: '150px', display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
            <Translate id='TOTAL_VALUE' />:{' '}
            <span>{calculateOrderTotal(record)}€</span>
          </FooterLine>
        </SOTotalSection>
      </ExpandableContent>
    )
  }

  renderExpandRow2Buttons = (record) => (

    <ExpandableContent>
      <BaseTable
        rowKey='orderSupplierId'
        datasource={record.orderSupplier}
        columns={this.ExpandableColumns(record)}
        pagination={{ render: false }}
        scroll={{ x: 0 }}
      />
      <SOTotalSection>
        <BaseButton
          size='small'
          style={{ minWidth: '12%', maxWidth: '17%', marginRight: '1%', width: 'fit-content' }}
          onClick={async () => { await this.supplierSetBtnAction(record.movementId, null, 12) }}
        >
          <Translate id='CLOSE_RECEPTION' />
        </BaseButton>

        <BaseButton
          size='small'
          style={{ minWidth: '12%', maxWidth: '17%', marginRight: '1%', width: 'fit-content' }}
          onClick={async () => { await this.supplierSetBtnAction(record.movementId, null, 4) }}
        >
          <Translate id='CONTINUE_RECEPTION' />
        </BaseButton>
        <FooterLine bold>
          <Translate id='TOTAL_VALUE' />:{' '}
          <span>{calculateOrderTotal(record)}€</span>
        </FooterLine>
      </SOTotalSection>
    </ExpandableContent>
  )

  render() {
    const {
      loading,
      rows,
      page,
      pageSize,
      total,
      filters
    } = this.state
    const { suppliers, orderStatus } = this.props

    const orderStatusFiltered =
      orderStatus?.length > 0
        ? orderStatus.filter((x) => x.regularFlowOrder)
        : []

    return (
      <Row>
        <Col xs={24}>
          <Row gutter={[24, 24]} style={{ marginBottom: 24 }}>
            <Col xs={6}>
              <SelectInput
                input={{
                  value: filters.supplierId,
                  onChange: (value) => {
                    this.setFilters(
                      {
                        ...filters,
                        supplierId: value
                      },
                      0
                    )
                  }
                }}
                label={<Translate id='SUPPLIER' />}
                placeholder={<Translate id='SELECT_SUPPLIER' />}
                data={suppliers}
                dataKey='supplierId'
                dataLabel='name'
              />
            </Col>
            <Col xs={6}>
              <SelectInput
                input={{
                  value: filters.orderStatusId,
                  onChange: (value) => {
                    this.setFilters(
                      {
                        ...filters,
                        orderStatusId: value
                      },
                      0
                    )
                  }
                }}
                label={<Translate id='STATUS' />}
                placeholder={<Translate id='SELECT_STATUS' />}
                data={orderStatusFiltered}
                dataKey='orderStatusId'
                dataLabel='orderStatusTranslation'
              />
            </Col>
            <Col xs={6}>
              <DateInput
                allowClear
                meta={{}}
                input={{
                  value: filters.date,
                  onChange: (value) => {
                    this.setFilters(
                      {
                        ...filters,
                        date: value
                      },
                      0
                    )
                  }
                }}
                label={<Translate id='DATE' />}
              />
            </Col>
            <Col xs={6}>
              <TextInput
                allowClear
                meta={{}}
                input={{
                  value: filters.search,
                  onChange: (value) => {
                    this.setFilters(
                      {
                        ...filters,
                        search: value
                      },
                      0
                    )
                  }
                }}
                label={<Translate id='B2B_ORDER' />}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <BaseTable
            rowKey='orderSupplierSetId'
            datasource={rows}
            columns={this.tableColumns()}
            pagination={{
              render: true,
              page,
              pageSize,
              total,
              onChangePagination: this.onChangePagination
            }}
            loading={loading}
            expandable={{
              expandIcon: ({ expanded, onExpand, record }) => (
                <ToggleImageContainer
                  role='button'
                  tabIndex={0}
                  onClick={(e) => onExpand(record, e)}
                >
                  <ToggleImage
                    src={DropdownIcon}
                    $open={expanded}
                    $noMarginRight
                  />
                </ToggleImageContainer>
              ),
              expandedRowRender: this.ExpandRow
            }}
          />
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = (state) => ({
  suppliers: state.info.suppliers,
  orderStatus: state.info.orderStatus
})

SupplierOrderList.propTypes = {
  orderStatus: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  suppliers: PropTypes.array.isRequired,
  router: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(SupplierOrderList)
