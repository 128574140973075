import React, {useState, useEffect} from 'react'

import {SearchOutlined} from '@ant-design/icons'
import {PropTypes} from 'prop-types'
import {Translate} from 'react-localize-redux'
import styled from 'styled-components'

import {GetOrderLocations} from '../../../../infra/requests/OrdersB2BRequests'
import AlertService from '../../../../shared/components/alert/AlertService'
import {
  InputBox,
  InputLabel,
  SelectInputStyled,
  SelectArrow,
  SelectLoading,
  NotFoundContainer
} from '../../../../shared/components/inputs/InputStyles'
import {PrimaryColor} from '../../../../shared/styles/_colors'

const Line = styled.div``
const Value = styled.div`
  font-size: 14px;
  line-height: 18px;
`
const Label = styled.div`
  color: ${PrimaryColor};
  font-size: 12px;
  line-height: 14px;
`

const Info = styled.div`
  font-size: 12px;
  line-height: 14px;
`

const ConstructAddress = (contact) =>
  `${contact.addressLine1 || ''} ${contact.addressLine2 || ''} ${
    contact.addressLine3 || ''
  } ${contact.city || ''}`

const ConstructLabel = (current, contact) => (
  <Line>
    <Label>
      {current.warehouseId ? (
        current.clientStock ? (
          <Translate id='CLIENT_WAREHOUSE' />
        ) : (
          <Translate id='SKYPRO_WAREHOUSE' />
        )
      ) : (
        <Translate id='CLIENT_ADDRESS' />
      )}
    </Label>
    <Value>{current.name}</Value>
    <Info>{ConstructAddress(contact)}</Info>
  </Line>
)

const DeliveryLocationSelect = ({
  input,
  label,
  meta,
  placeholder,
  allowClear,
  disabled,
  afterChange,
  client
}) => {
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function updateList() {
      if (client) {
        setLoading(true)
        const {data, success} = await GetOrderLocations(client)
        if (success) {
          const refactor = []
          const replaced = []

          data.forEach((current) => {
            if (Array.isArray(current?.contact)) {
              current.contact.forEach((contact) => {
                const found = refactor.find((r) => r.value == contact.contactDetailId)
                if (found) {
                  replaced.push({
                    label: ConstructLabel(current, contact),
                    value: contact.contactDetailId,
                    name: current.warehouseId
                      ? current.name
                      : ConstructAddress(contact)
                  })
                } else {
                  refactor.push({
                    label: ConstructLabel(current, contact),
                    value: contact.contactDetailId,
                    name: current.warehouseId
                      ? current.name
                      : ConstructAddress(contact)
                  })
                }
              })
            }
          })

          if (replaced.length) {
            const message = (
              <div>
                <div>
                  The following Delivery locations are not shown because they have duplicated IDs:
                </div>
                {replaced.map((item, index) => <div style={{marginTop: '10px'}} key={index}>{item.name}</div>)}
              </div>
            )
            AlertService.error('Data error', message)
          }

          setList(refactor || [])
          if (input.value) {
            const exist = refactor.find((w) => w.value == input.value)
            input.onChange(input.value.toString())
            if (!exist) input.onChange('')
          }
        } else {
          input.onChange('')
          setList([])
        }
        setLoading(false)
      }
    }
    updateList()
  }, [client])

  const handleChange = (value) => {
    input.onChange(value)
    if (typeof afterChange === 'function') {
      setTimeout(() => afterChange(value || undefined), 100)
    }
  }

  const defineValue = (value) => {
    if (value) {
      const found = list.find((x) => x.value === value)
      return found?.name || undefined
    }
    return undefined
  }

  const hasError = meta.invalid && meta.submitFailed

  return (
    <InputBox>
      {label && <InputLabel>{label}</InputLabel>}
      <SelectInputStyled
        hasError={hasError}
        placeholder={placeholder}
        value={defineValue(input.value)}
        onChange={handleChange}
        allowClear={allowClear}
        suffixIcon={loading ? <SelectLoading /> : <SelectArrow />}
        disabled={disabled}
        options={list}
        loading={loading}
        notFoundContent={
          <NotFoundContainer>
            <SearchOutlined />
            <Translate id='SELECT_CLIENT_FIRST' />
          </NotFoundContainer>
        }
      />
    </InputBox>
  )
}

DeliveryLocationSelect.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.element
  ]),
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.element
  ]),
  allowClear: PropTypes.bool,
  disabled: PropTypes.bool,
  afterChange: PropTypes.func,
  client: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

DeliveryLocationSelect.defaultProps = {
  input: {value: undefined, onChange: () => null},
  label: undefined,
  placeholder: undefined,
  meta: {},
  allowClear: false,
  disabled: false,
  afterChange: undefined,
  client: undefined
}

export default DeliveryLocationSelect
