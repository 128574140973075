import React, {useEffect, useState} from 'react'

import {Col, Row} from 'antd'
import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'

import {
  ContentContainer,
  Margin,
  PageTitle,
  StyledTabPane,
  StyledTabs,
  StyledTabTitle,
  StyledTabTitleContainer
} from '../../../shared/styles/BasicStyles'
import ConfigurationTab from './tabs/ConfigurationTab'
import ReceiveServerTab from './tabs/ReceiveServerTab'
import SendServerTab from './tabs/SendServerTab'

const Sections = ['receive', 'send', 'config']

const MailConfigurationPage = ({router}) => {
  const [section, setSection] = useState('receive')

  useEffect(() => {
    function initialize() {
      const hash = router.location.hash.replace('#', '')
      if (Sections.find((s) => s === hash)) {
        setSection(hash)
      }
    }
    initialize()
  }, [])

  const onChangeTab = (active) => {
    setSection(active)
    router.history.replace(`/mailConfiguration#${active}`)
  }

  return (
    <>
      <Margin size={62} />
      <Row>
        <Col xs={24}>
          <PageTitle>
            <Translate id='EMAIL_CONFIG' />
          </PageTitle>
        </Col>
      </Row>
      <ContentContainer>
        <StyledTabs activeKey={section} onChange={onChangeTab}>
          <StyledTabPane
            tab={
              <StyledTabTitleContainer>
                <StyledTabTitle>
                  <Translate id='RECEIVE_SERVER' />
                </StyledTabTitle>
              </StyledTabTitleContainer>
            }
            key='receive'
          >
            <ReceiveServerTab router={router} />
          </StyledTabPane>
          <StyledTabPane
            tab={
              <StyledTabTitleContainer>
                <StyledTabTitle>
                  <Translate id='SEND_SERVER' />
                </StyledTabTitle>
              </StyledTabTitleContainer>
            }
            key='send'
          >
            <SendServerTab router={router} />
          </StyledTabPane>
          <StyledTabPane
            tab={
              <StyledTabTitleContainer>
                <StyledTabTitle>
                  <Translate id='CONFIG' />
                </StyledTabTitle>
              </StyledTabTitleContainer>
            }
            key='config'
          >
            <ConfigurationTab />
          </StyledTabPane>
        </StyledTabs>
      </ContentContainer>
    </>
  )
}

MailConfigurationPage.propTypes = {
  router: PropTypes.object.isRequired
}

export default MailConfigurationPage
