import React, {useState, useEffect} from 'react'

import {Col, Row} from 'antd'
import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'
import {connect} from 'react-redux'
import {useParams} from 'react-router-dom'
import {reduxForm, initialize} from 'redux-form'

import {
  GetContactDetail,
  SaveContactDetail,
  SaveNewContact
} from '../../../infra/requests/ContactRequests'
import BackButton from '../../../shared/components/buttons/BackButton'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import BaseLoading from '../../../shared/components/loading/BaseLoading'
import {
  ContentContainer,
  Margin,
  PageForm,
  StyledTabPane,
  StyledTabs,
  StyledTabTitle,
  StyledTabTitleContainer,
  PageTitle
} from '../../../shared/styles/BasicStyles'
import Activity from './components/Activity'
import Information from './components/Information'
import Messages from './components/Messages'
import Orders from './components/Orders'
import Vouchers from './components/Vouchers'
import FormValidator from '../../../infra/services/validations/FormValidator'

const validations = FormValidator.make({
  email: 'email|required',
  contactTypeId: 'required',
  name: 'required'
})

const ManageContactPage = ({router, dispatch, handleSubmit}) => {
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const {id} = useParams()

  useEffect(() => {
    const init = async () => {
      if (id && id !== 'new') {
        const {data} = await GetContactDetail(id)
        dispatch(initialize('manage_contact', data || {}))
      }
      setLoading(false)
    }
    init()
  }, [])

  const onSubmit = async (values) => {
    try {
      setSaving(true)
      if (id && id !== 'new') {
        const {success} = await SaveContactDetail(id, values)
        if (success) router.history.push('/messaging#contacts')
        else setSaving(false)
      } else {
        const {success} = await SaveNewContact(values)
        if (success) router.history.push('/messaging#contacts')
        else setSaving(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  if (loading) return <BaseLoading margin='100' />

  return (
    <ContentContainer>
      <PageForm $margin='0' onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs={24}>
            <BackButton
              label={<Translate id='BACK' />}
              onClick={() => router.history.push('/messaging#contacts')}
            />
          </Col>
        </Row>
        <Margin size={29} />
        <Row xs={24}>
          <Col xs={12}>
            <PageTitle>
              <Translate id='CONTACT' />
            </PageTitle>
          </Col>
          <Col xs={12} align='end'>
            <BaseButton auto htmlType='submit' loading={saving}>
              <Translate id='SAVE' />
            </BaseButton>
          </Col>
        </Row>
        <Margin size={37} />
        <StyledTabs>
          <StyledTabPane
            tab={
              <StyledTabTitleContainer>
                <StyledTabTitle smaller>
                  <Translate id='INFORMATION' />
                </StyledTabTitle>
              </StyledTabTitleContainer>
            }
            key={1}
          >
            <Information />
          </StyledTabPane>
          {id !== 'new' && (
            <>
              <StyledTabPane
                tab={
                  <StyledTabTitleContainer>
                    <StyledTabTitle smaller>
                      <Translate id='MESSAGES' />
                    </StyledTabTitle>
                  </StyledTabTitleContainer>
                }
                key={2}
              >
                <Messages router={router} id={id} />
              </StyledTabPane>
              <StyledTabPane
                tab={
                  <StyledTabTitleContainer>
                    <StyledTabTitle smaller>
                      <Translate id='ACTIVITY' />
                    </StyledTabTitle>
                  </StyledTabTitleContainer>
                }
                key={3}
              >
                <Activity router={router} id={id} />
              </StyledTabPane>
              <StyledTabPane
                tab={
                  <StyledTabTitleContainer>
                    <StyledTabTitle smaller>
                      <Translate id='ORDERS' />
                    </StyledTabTitle>
                  </StyledTabTitleContainer>
                }
                key={4}
              >
                <Orders router={router} id={id} />
              </StyledTabPane>
              <StyledTabPane
                tab={
                  <StyledTabTitleContainer>
                    <StyledTabTitle smaller>
                      <Translate id='VOUCHERS' />
                    </StyledTabTitle>
                  </StyledTabTitleContainer>
                }
                key={5}
              >
                <Vouchers />
              </StyledTabPane>
            </>
          )}
        </StyledTabs>
      </PageForm>
    </ContentContainer>
  )
}

ManageContactPage.propTypes = {
  router: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired
}

const FormComponent = reduxForm({
  form: 'manage_contact',
  validate: validations
})(ManageContactPage)

export default connect(() => ({}))(FormComponent)
