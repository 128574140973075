import React, { useState, useEffect } from 'react'

import { Col, Row } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Translate, withLocalize } from 'react-localize-redux'
import { useParams } from 'react-router-dom'
import { reduxForm, initialize } from 'redux-form'

import Credits from './Credits'
import Dotation from './Dotation'
import Information from './Information'
import {
  CreateEmployee,
  EditEmployeeAddresses,
  EditEmployeeInformation,
  EditEmployeePlan,
  GetEmployee,
  GetB2bclientWorkgroupsDrop,
  GetB2bclientAddressDrop
} from '../../../infra/requests/ClientRequests'
import FormValidator from '../../../infra/services/validations/FormValidator'
import AlertService from '../../../shared/components/alert/AlertService'
import BackButton from '../../../shared/components/buttons/BackButton'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import BaseLoading from '../../../shared/components/loading/BaseLoading'
import {
  ContentContainer,
  Margin,
  PageForm,
  StyledTabPane,
  StyledTabs,
  StyledTabTitle,
  StyledTabTitleContainer
} from '../../../shared/styles/BasicStyles'
import { PageTitle } from '../ClientStyles'

const validations = (values) => {
  const errors = {}
  errors.employeeInformationSection = FormValidator.make({
    firstName: 'required',
    lastName: 'required',
    email: 'required',
    birthDate: 'required',
    birthCountryId: 'required',
    positionId: 'required',
    mobile: 'required|isContact',
    admissionDate: 'required',
    number: 'required',
    effective: 'required',
    vatnumber: 'isVat',
    phone: 'isContact'
  })(values?.employeeInformationSection)

  if (values?.employeeInformationSection?.effective === 'false' || values?.employeeInformationSection?.effective === false) {
    errors.employeeInformationSection.contractTypeId = FormValidator.validateField('contractTypeId', values?.employeeInformationSection?.contractTypeId, 'required')
    errors.employeeInformationSection.contractEnd = FormValidator.validateField('contractEnd', values?.employeeInformationSection?.contractEnd, 'required')
  }

  if (!values.oldEmployee) {
    if (!values?.employeeInformationSection?.password ||
      values?.employeeInformationSection?.password === '' ||
      !values?.employeeInformationSection?.confirm_password ||
      values?.employeeInformationSection?.confirm_password === '' ||
      values?.employeeInformationSection?.password !== values.employeeInformationSection?.confirm_password) {
      errors.employeeInformationSection.password = { _error: 'REQUIRED' }
      errors.employeeInformationSection.confirm_password = { _error: 'REQUIRED' }
    }
  }

  return errors
}

const ManageEmployeePage = ({
  router,
  dispatch,
  handleSubmit,
  translate
}) => {
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [name, setName] = useState(<Translate id='NEW_EMPLOYEE' />)
  const [productsList, setProductsList] = useState([])
  const [workgroups, setWorkgroups] = useState([])
  const [stopovers, setStopOvers] = useState([])
  const { client, id } = useParams()

  useEffect(() => {
    async function init() {
      const clientWorkgroups = await GetB2bclientWorkgroupsDrop()

      if (clientWorkgroups.success) {
        const clients = clientWorkgroups?.data?.items || []
        const found = clients.find((c) => c.b2bclientId == client)
        if (found) setWorkgroups(found.position)
      }

      const clientAddress = await GetB2bclientAddressDrop(client)
      if (clientAddress.success) setStopOvers(clientAddress?.data || [])

      if (id) {
        // get employee
        const { data, success } = await GetEmployee(id)

        if (success) {
          const fullName = `${data?.employeeInformationSection?.firstName} ${data?.employeeInformationSection?.lastName}`
          setName(
            data?.employeeInformationSection?.lastName
              ? fullName
              : data?.employeeInformationSection?.firstName
          )
          setProductsList(data?.employeeDotationSection?.dotation)
          dispatch(
            initialize(
              'manage_employee',
              {
                ...data,
                oldEmployee: true,
                intervalState: [
                  moment(data.employeeInformationSection.statusStartDate),
                  moment(data.employeeInformationSection.statusEndDate)
                ]
              } || {}
            )
          )
        }
      }
      setLoading(false)
    }
    init()
  }, [client, id])

  const onSubmit = async (values) => {
    if (!values?.employeeInformationSection.stopoverId || values?.employeeInformationSection.stopoverId === '') {
      let exist
      if (values?.employeeInformationSection?.contactEmployee && values?.employeeInformationSection.contactEmployee[0]) {
        const addresses = values?.employeeInformationSection.contactEmployee[0].contactDetail
        if (Array.isArray(addresses) && addresses.length) exist = true
      }
      if (!exist) {
        return AlertService.error(
          translate('ERROR'), translate('SELECT_STOPOVER_OR_CREATE_ADDRESS')
        )
      }
    }

    setSaving(true)
    let saved = false
    let idResult = ''
    try {
      const informationData = {
        ...values.employeeInformationSection,
        statusStartDate: values?.intervalState ? values?.intervalState[0] : undefined,
        statusEndDate: values?.intervalState ? values?.intervalState[1] : undefined
      }

      delete informationData?.stopover
      delete informationData?.birthCountry
      delete informationData?.position
      delete informationData?.contractType
      delete informationData?.employeeCategory
      delete informationData?.employeeStatus
      delete informationData?.costCenter

      const addressData = {
        contactEmployee: values?.employeeInformationSection
          ?.contactEmployee && [
            {
              contactId:
                values?.employeeInformationSection?.contactEmployee[0]
                  ?.contactId,
              contactDetail:
                values?.employeeInformationSection?.contactEmployee[0]
                  ?.contactDetail
            }
          ]
      }

      const employeePlanData = {
        employeePlan: values?.employeeInformationSection?.employeePlan && [
          {
            ...values?.employeeInformationSection?.employeePlan,
            positionId:
              values.employeeInformationSection?.position?.positionId,
            contractTypeId:
              values.employeeInformationSection?.contractType
                ?.contractTypeId,
            total: 1,
            dateFrom:
              values?.employeeInformationSection?.employeePlan[0]
                ?.dateFrom,
            dateTo:
              values?.employeeInformationSection?.employeePlan[0]
                ?.dateFrom,
            contractEnd:
              values?.employeeInformationSection?.employeePlan[0]?.dateFrom
          }
        ]
      }

      if (id) {
        idResult = id
        const edit = await EditEmployeeInformation(
          id,
          informationData
        )
        saved = edit.success
      } else {
        const resultInfo = await CreateEmployee(client, informationData)

        if (resultInfo.success) {
          saved = resultInfo.success
          idResult = resultInfo.data.employeeId
        }
      }
      if (saved) {
        const resultAddress = await EditEmployeeAddresses(
          idResult,
          addressData
        )
        const resultEmployeePlan = await EditEmployeePlan(
          idResult,
          employeePlanData
        )
        if (resultAddress.success && resultEmployeePlan.success) {
          AlertService.success(
            translate('SUCCESS'),
            id
              ? translate('EMPLOYEE_EDITED_SUCCESSFULLY')
              : translate('EMPLOYEE_CREATED_SUCCESSFULLY')
          )
          return router.history.push(`/clients/${client}#employees`)
        }
      }
      setSaving(false)
    } catch (error) {
      console.log(error)
    }
  }

  if (loading) return <BaseLoading margin='100' />

  return (
    <ContentContainer>
      <PageForm $margin='0' onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs={24}>
            <BackButton
              label={<Translate id='BACK' />}
              onClick={() =>
                router.history.push(`/clients/${client}#employees`)
              }
            />
          </Col>
        </Row>
        <Margin size={29} />
        <Row>
          <Col xs={24}>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <PageTitle>{name}</PageTitle>
              <BaseButton auto htmlType='submit' loading={saving}>
                <Translate id='SAVE' />
              </BaseButton>
            </div>
          </Col>
        </Row>
        <Margin size={37} />
        <StyledTabs>
          <StyledTabPane
            tab={
              <StyledTabTitleContainer>
                <StyledTabTitle smaller>
                  <Translate id='INFORMATION' />
                </StyledTabTitle>
              </StyledTabTitleContainer>
            }
            key={1}
          >
            <Information id={id} workgroups={workgroups} stopovers={stopovers} />
          </StyledTabPane>
          <StyledTabPane
            tab={
              <StyledTabTitleContainer>
                <StyledTabTitle smaller>
                  <Translate id='DOTATION' />
                </StyledTabTitle>
              </StyledTabTitleContainer>
            }
            key={4}
            disabled={!id}
          >
            <Dotation router={router} />
          </StyledTabPane>
          <StyledTabPane
            tab={
              <StyledTabTitleContainer>
                <StyledTabTitle smaller>
                  <Translate id='CREDITS' />
                </StyledTabTitle>
              </StyledTabTitleContainer>
            }
            key={5}
            disabled={!id}
          >
            <Credits
              id={id}
              productsList={productsList}
              dispatch={dispatch}
            />
          </StyledTabPane>
        </StyledTabs>
      </PageForm>
    </ContentContainer>
  )
}

ManageEmployeePage.propTypes = {
  router: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func,
  dispatch: PropTypes.func
}

ManageEmployeePage.defaultProps = {
  handleSubmit: undefined,
  dispatch: undefined
}

export default withLocalize(
  reduxForm({
    form: 'manage_employee',
    validate: validations
  })(ManageEmployeePage)
)
