import React, { useMemo } from 'react'

import { ContainerOutlined } from '@ant-design/icons'
import { PropTypes } from 'prop-types'
import { Translate, withLocalize } from 'react-localize-redux'

import {
  InputBox,
  InputLabel,
  SelectInputStyled,
  SelectOption,
  SelectArrow,
  NotFoundContainer,
  SelectLoading
} from './InputStyles'
import ActiveTranslation from '../../logic/translations/ActiveTranslation'
import { PrimaryButtonColor } from '../../styles/_colors'
import { OverlineText } from '../../styles/_texts'
import { BodyH3 } from '../../styles/BasicStyles'

const SelectInput = ({
  input,
  label,
  meta,
  data,
  placeholder,
  dataKey,
  dataLabel,
  allowClear,
  hasPaddingBottom,
  mode,
  disabled,
  afterChange,
  small,
  adjustable,
  additionalText,
  translateTag,
  renderLabel,
  clearValue,
  showSearch,
  notFoundIcon,
  notFoundMessage,
  loading,
  activeLanguage
}) => {
  const handleChange = (value) => {
    input.onChange(value || undefined)
    if (typeof afterChange === 'function') {
      setTimeout(() => afterChange(value || undefined), 100)
    }
  }

  const handleSearch = (search, option) => {
    if (typeof option.children === 'string') {
      return option.children.toLowerCase().indexOf(search.toLowerCase()) >= 0
    }
    if (typeof option.children === 'object' && option.children.props) {
      const { value, tag } = option.children.props
      let found = false
      value.forEach((item) => {
        if (
          item[tag] &&
          item[tag].toLowerCase().indexOf(search.toLowerCase()) >= 0
        ) {
          found = true
        }
      })
      return found
    }
    return true
  }

  const hasError = meta.invalid && meta.submitFailed

  const defineValue = (value) => {
    if (clearValue) return placeholder
    if (typeof value === 'boolean') return `${value}`
    return (value || value === 0) && value !== ''
      ? value.toString()
      : undefined
  }

  const getSortableString = (current) => {
    if (!current) return ''

    if (renderLabel) {
      const rendered = renderLabel(current)
      if (typeof rendered === 'string') return rendered
      if (rendered?.props?.children) return rendered.props.children.toString()
      return ''
    }

    let result

    const fields = dataLabel.split('.')

    if (fields.length === 1) {
      result = current[dataLabel]
    } else {
      result = fields.reduce((acc, cur) => {
        if (!acc) return ''
        if (typeof acc === 'string') return acc
        return acc[cur]
      }, current)
    }

    if (Array.isArray(result)) {
      if (result.length === 0) return ''
      const translationItem = result.find((i) => (i.languageID || i.languageId) === activeLanguage.languageId)
      const itemLabel = translationItem?.name

      return itemLabel || ''
    }

    if (typeof result === 'object' && result !== null) {
      return result[translateTag] || ''
    }

    return (result || '').toString()
  }

  const handleLabel = (current) => {
    if (renderLabel) return renderLabel(current)

    if (!current) return undefined

    let result

    const fields = dataLabel.split('.')

    if (fields.length === 1) result = current[dataLabel]
    else {
      result = fields.reduce((acc, cur) => {
        if (!acc) return `${cur} ${(<Translate id='DO_NOT_EXIST' />)}`
        if (typeof acc === 'string') return acc
        return acc[cur]
      }, current)
    }

    if (Array.isArray(result)) {
      return <ActiveTranslation value={result} tag={translateTag} />
    }

    if (typeof result === 'object') {
      return <Translate id='CANNOT_RENDER_OBJECT' />
    }

    return result
  }

  const sortedData = useMemo(() => [...data].sort((a, b) => {
    const labelA = getSortableString(a).toLowerCase()
    const labelB = getSortableString(b).toLowerCase()
    return labelA.localeCompare(labelB)
  }), [data, dataLabel, renderLabel, translateTag])

  return (
    <InputBox hasPaddingBottom={hasPaddingBottom}>
      {label && <InputLabel>{label}</InputLabel>}
      <SelectInputStyled
        mode={mode}
        $small={small}
        $adjustable={adjustable}
        hasError={hasError}
        placeholder={placeholder}
        value={defineValue(input.value)}
        showSearch={showSearch}
        onChange={handleChange}
        allowClear={allowClear}
        suffixIcon={
          loading ? (
            <SelectLoading small={small} />
          ) : (
            <SelectArrow $small={small} />
          )
        }
        filterOption={handleSearch}
        autoComplete='off'
        disabled={disabled}
        loading={loading}
        notFoundContent={
          <NotFoundContainer>
            {notFoundIcon}
            {notFoundMessage}
          </NotFoundContainer>
        }
      >
        {sortedData.map((current) => (
          <SelectOption key={current[dataKey]} disabled={current.disabled}>
            {handleLabel(current)}
          </SelectOption>
        ))}
      </SelectInputStyled>
      {additionalText && (
        <BodyH3 $color={PrimaryButtonColor} $size={OverlineText}>
          {additionalText}
        </BodyH3>
      )}
    </InputBox>
  )
}

SelectInput.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.element
  ]),
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.element
  ]),
  data: PropTypes.array,
  dataKey: PropTypes.string,
  dataLabel: PropTypes.string,
  mode: PropTypes.string,
  allowClear: PropTypes.bool,
  hasPaddingBottom: PropTypes.bool,
  disabled: PropTypes.bool,
  afterChange: PropTypes.func,
  small: PropTypes.bool,
  additionalText: PropTypes.object,
  translateTag: PropTypes.string,
  renderLabel: PropTypes.func,
  clearValue: PropTypes.bool,
  showSearch: PropTypes.bool,
  notFoundIcon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.element
  ]),
  notFoundMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.element
  ]),
  loading: PropTypes.bool
}

SelectInput.defaultProps = {
  input: { value: undefined, onChange: () => null },
  label: undefined,
  placeholder: undefined,
  meta: {},
  data: [],
  dataKey: 'id',
  dataLabel: 'name',
  mode: undefined,
  allowClear: true,
  hasPaddingBottom: false,
  disabled: false,
  afterChange: undefined,
  small: false,
  additionalText: undefined,
  translateTag: 'name',
  renderLabel: undefined,
  clearValue: false,
  showSearch: true,
  notFoundIcon: <ContainerOutlined />,
  loading: false,
  notFoundMessage: <Translate id='NO_DATA' />
}

export default withLocalize(SelectInput)
