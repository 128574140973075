import React, { useState, useEffect } from "react";

import { Row, Col, Spin } from "antd";
import moment from "moment";
import PropTypes from "prop-types";
import { Translate, withLocalize } from "react-localize-redux";
import { connect } from "react-redux";
import { Field, FieldArray, change } from "redux-form";

import {
  GetPendingClientOrders,
  GetSellersList,
  ValidateDepartureStock,
} from "../../../../infra/requests/DepartureRequests";
import { GetAllWarehouses, GetAllWarehousesDestiny } from "../../../../infra/requests/LogisticsRequests";
import PropertyDescription from "../../../../shared/components/description/PropertyDescription";
import DateInput from "../../../../shared/components/inputs/DateInput";
import SelectInput from "../../../../shared/components/inputs/SelectInput";
import { Margin } from "../../../../shared/styles/BasicStyles";
import DepartureProducts from "./sections/DepartureProducts";
import BaseLoading from "../../../../shared/components/loading/BaseLoading";

const FirstStep = ({ dispatch, clients, formValues, disabled, translate }) => {
  const [warehouses, setWarehouses] = useState([]);
  const [orders, setOrders] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [fetchData, setFetchData] = useState(false);
  const [loadingAvailability, setLoadingAvailability] = useState(false);
  const [loadingWarehouses, setLoadingWarehouses] = useState(true);

  useEffect(() => {
    async function init() {
      setLoadingWarehouses(true); // Set loading to true before fetching warehouses
      const warehousesData = await GetAllWarehousesDestiny();
      if (warehousesData.success) {
        setWarehouses(warehousesData?.data?.items || []);
      }
      setLoadingWarehouses(false); // Set loading to false after fetching warehouses
      const usersData = await GetSellersList();
      if (usersData.success) {
        setSellers(usersData?.data || []);
      }
    }
    init();
  }, []);

  const addSeller = (id) => {
    dispatch(
      change(
        "departure_create_waybill_kits",
        "seller",
        sellers.find((s) => s.UserId == id)
      )
    );
  };

  const addWarehouse = async (warehouseId) => {
    setLoadingAvailability(true);
    dispatch(
      change(
        "departure_create_waybill_kits",
        "warehouse",
        warehouses.find((wh) => wh.warehouseId == warehouseId)
      )
    );

    if (formValues.products) {
      const { data } = await ValidateDepartureStock({
        warehouseId,
        skus: formValues.products.map((p) => ({
          productId: p.productId,
          productWidthId: p.productWidthId,
          sizeId: p.sizeId,
          colorId: p.colorId,
          units: p.departQty,
        })),
      });

      const products = [];
      formValues.products.forEach((product) => {
        const availability = data.find(
          (d) =>
            d.productId == product.productId &&
            d.productWidthId == product.productWidthId &&
            d.sizeId == product.sizeId &&
            d.colorId == product.colorId
        );
        products.push({
          ...product,
          availableStock: parseInt(availability?.available || 0, 10),
        });
      });
      dispatch(change("departure_create_waybill_kits", "products", products));
      setLoadingAvailability(false);
    }
  };

  const onSelectClient = async (clientId) => {
    dispatch(change("departure_create_waybill_kits", "orderB2bid", ""));
    dispatch(change("departure_create_waybill_kits", "products", []));
    dispatch(
      change(
        "departure_create_waybill_kits",
        "client",
        clients.find((c) => c.b2bclientId == clientId)
      )
    );
    setFetchData(true);
    const { data, success } = await GetPendingClientOrders(clientId);
    if (success) setOrders(data?.items || []);
    setFetchData(false);
  };

  const setOrderProducts = async (orderB2bid) => {
    const products = [];
    const order = orders.find((o) => o.orderB2bid == orderB2bid);
    if (order && order.orderB2bitem) {
      let available = [];
      if (formValues?.originId) {
        const { data } = await ValidateDepartureStock({
          warehouseId: formValues.originId,
          skus: order.orderB2bitem.map((product) => ({
            productId: product.productCombination.productId,
            productWidthId: product.productCombination.productWidthId,
            sizeId: product.productCombination.sizeId,
            colorId: product.productCombination.colorId,
            units: product.quantity,
          })),
        });
        available = Array.isArray(data) ? data : [];
      }

      order.orderB2bitem.forEach((product) => {
        const availability = available.find(
          (d) =>
            d.productId == product.productCombination.productId &&
            d.productWidthId == product.productCombination.productWidthId &&
            d.sizeId == product.productCombination.sizeId &&
            d.colorId == product.productCombination.colorId
        );

        products.push({
          orderB2bitemId: product.orderB2bitemId,
          productId: product.productCombination.productId,
          sizeId: product.productCombination.sizeId,
          colorId: product.productCombination.colorId,
          productWidthId: product.productCombination.productWidthId,
          departQty: product.quantity,
          reference: product.productCombination.reference,
          barcode: product.productCombination.barcode,
          referenceClient: product.productCombination.referenceClient,
          description: product.productCombination.product.productTranslation,
          color: product.productCombination.color,
          size: product.productCombination.size,
          productWidth: product.productCombination.productWidth,
          orderQuantity: product.quantity,
          availableStock: formValues?.originId
            ? parseInt(availability?.available || 0, 10)
            : undefined,
        });
      });

      dispatch(change("departure_create_waybill_kits", "order", order.number));
    }
    dispatch(change("departure_create_waybill_kits", "products", products));
  };

  const handleClientList = () => {
    if (formValues.movementTypeId == 7 || formValues.movementTypeId == 11) {
      return clients.filter((c) => c.b2bclientId != 1);
    }
    return clients;
  };

  if (disabled) {
    return (
      <>
        <Row gutter={[40, 20]}>
          <Col xs={6}>
            <PropertyDescription
              label="Client"
              value={formValues?.client?.name || "N/A"}
            />
          </Col>
          <Col xs={6}>
            <PropertyDescription
              label="Order"
              value={formValues?.order || "N/A"}
            />
          </Col>
          <Col xs={6}>
            <PropertyDescription
              label="Origin warehouse"
              value={formValues?.warehouse?.name || "N/A"}
            />
          </Col>
          <Col xs={6}>
            <PropertyDescription
              label="Seller"
              value={formValues?.seller?.Name || "N/A"}
            />
          </Col>
          <Col xs={6}>
            <PropertyDescription
              label="Departure Date"
              value={
                moment(formValues?.departureDate).format("DD-MM-YYYY") ||
                moment().format("DD-MM-YYYY")
              }
            />
          </Col>
        </Row>
        <Margin size="50" />
        <Row>
          <Col xs={24}>
            <FieldArray
              component={DepartureProducts}
              name="products"
              dispatch={dispatch}
              departureType={formValues?.movementTypeId}
              originWarehouse={formValues?.warehouse}
              client={formValues?.clientId}
              supplier={formValues?.supplierId}
              disabled={disabled}
            />
          </Col>
        </Row>
      </>
    );
  }

  return (
    <>
      <Row gutter={[40, 20]}>
        <Col xs={6}>
          <Field
            allowClear={false}
            component={SelectInput}
            name="clientId"
            label={<Translate id="CLIENT" />}
            placeholder={translate("SELECT_CLIENT")}
            data={handleClientList()}
            dataKey="b2bclientId"
            dataLabel="name"
            afterChange={onSelectClient}
            disabled={disabled}
          />
        </Col>
        <Col xs={6}>
          <Field
            allowClear={false}
            component={SelectInput}
            name="orderB2bid"
            label={<Translate id="ORDER" />}
            placeholder={translate("SELECT_ORDER")}
            data={orders}
            dataKey="orderB2bid"
            dataLabel="number"
            notFoundMessage={
              formValues.clientId
                ? translate("THIS_CLIENT_DOES_NOT_HAVE_PENDING_ORDERS")
                : translate("PLEASE_SELECT_CLIENT_FIRST")
            }
            loading={fetchData}
            afterChange={setOrderProducts}
            disabled={disabled}
          />
        </Col>
        <Col xs={6}>
          <Field
            allowClear={false}
            component={SelectInput}
            name="originId"
            label={<Translate id="ORIGIN_WAREHOUSE" />}
            placeholder={translate("ORIGIN_WAREHOUSE")}
            data={warehouses}
            dataKey="warehouseId"
            dataLabel="name"
            afterChange={addWarehouse}
            disabled={disabled}
          />
        </Col>
        <Col xs={6}>
          <Field
            allowClear={false}
            component={SelectInput}
            name="sellerId"
            label={<Translate id="SELLER" />}
            placeholder={translate("SET_A_SELLER")}
            data={sellers}
            dataKey="UserId"
            dataLabel="Name"
            afterChange={addSeller}
            disabled={disabled}
          />
        </Col>
        <Col xs={6}>
          <Field
            component={DateInput}
            name="departureDate"
            label={<Translate id="DEPARTURE_DATE" />}
            placeholder={translate("SELECT")}
            disabled={disabled}
          />
        </Col>
      </Row>
      <Margin size="50" />
      <Row>
        <Col xs={24}>
          {/* {loadingAvailability ? (
            <BaseLoading />
          ) : ( */}
          <FieldArray
            component={DepartureProducts}
            name="products"
            dispatch={dispatch}
            departureType={formValues?.movementTypeId}
            originWarehouse={formValues?.warehouse}
            client={formValues?.clientId}
            supplier={formValues?.supplierId}
            disabled={disabled}
            loading={loadingAvailability}
          />
          {/* )} */}
        </Col>
      </Row>
    </>
  );
};

FirstStep.propTypes = {
  dispatch: PropTypes.func.isRequired,
  clients: PropTypes.array.isRequired,
  formValues: PropTypes.object,
};

FirstStep.defaultProps = {
  formValues: {},
};

export default withLocalize(
  connect((state) => ({
    clients: state.info.clients,
    departureTypes: state.info.departureTypes,
  }))(FirstStep)
);
