import React, { useState } from 'react'

import { Popconfirm } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import { Translate } from 'react-localize-redux'
import { useParams } from 'react-router'

import AttatchIcon from '../../../../assets/icons/attach.svg'
import TrashIcon from '../../../../assets/icons/remove.svg'
import { AuthTokenKey } from '../../../../infra/config/LocalStorageKeys'
import {
  UploadProductFile,
  DeleteProductFile
} from '../../../../infra/requests/ProductsRequests'
import { StyledFlex } from '../../../styles/BasicStyles'
import BaseButton from '../../buttons/BaseButton'
import GetFileType from '../../files/GetFileTypeIcon'
import { DocumentInfo, DocumentName } from '../Documents/DocumentsStyles'
import {
  ProductUploadImage,
  ProductUploadFileInputContainer,
  ProductFileRemoveImage
} from './ProductsStyles'

const ProductUploadFileInput = ({ input, top }) => {
  const [loading, setLoading] = useState(false)
  const { id } = useParams()

  const handleFileDrop = async (accepted, rejected) => {
    if (accepted && accepted[0]) {
      setLoading(true)
      const payload = new FormData()
      payload.append('File', accepted[0])
      const { data, success } = await UploadProductFile(id, payload)
      if (success) input.onChange(data)
      setLoading(false)
    } else console.warn(rejected)
  }

  const handleDelete = async () => {
    input.onChange({})
    await DeleteProductFile(id)
  }

  const handleOpen = () => {
    const authToken = localStorage.getItem(AuthTokenKey)
    fetch(`${window.env.REACT_APP_API}/Products/getProductFile/${id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = input.value.name
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
      })
      .catch((e) => console.log(e))
  }

  if (input.value && Object.keys(input.value).length > 0) {
    return (
      <ProductUploadFileInputContainer>
        <StyledFlex $direction='row' $clickable onClick={handleOpen}>
          <ProductUploadImage src={GetFileType(input.value)} />
          <StyledFlex $clickable>
            <DocumentName>
              {input.value ? input.value.name : null}
            </DocumentName>
            <DocumentInfo>
              {input.value
                ? moment(input.value.dateCreated).format('DD/MM/YYYY')
                : null}
            </DocumentInfo>
          </StyledFlex>
        </StyledFlex>
        <Popconfirm
          placement='topRight'
          title={<Translate id='CONFIRM_DELETE_FILE' />}
          onConfirm={handleDelete}
        >
          <ProductFileRemoveImage $top={top} src={TrashIcon} />
        </Popconfirm>
      </ProductUploadFileInputContainer>
    )
  }
  return (
    <Dropzone onDrop={handleFileDrop} multiple={false} maxFile>
      {({ getRootProps, getInputProps }) => (
        <>
          <input {...getInputProps()} />
          <StyledFlex>
            <BaseButton
              {...getRootProps()}
              ref={undefined}
              type='secondary'
              loading={loading}
              imagePath={AttatchIcon}
              auto
            >
              <Translate id='ATTACH_FILE' />
            </BaseButton>
          </StyledFlex>
        </>
      )}
    </Dropzone>
  )
}

ProductUploadFileInput.propTypes = {
  input: PropTypes.object,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.object
  ]),
  top: PropTypes.number
}

ProductUploadFileInput.defaultProps = {
  input: { value: undefined, onChange: () => null },
  label: <Translate id='CHOOSE_FILE' />,
  top: undefined
}

export default ProductUploadFileInput
