import React from 'react'

import { Popover } from 'antd'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'

import Info from '../../../assets/icons/info.svg'
import {
  Flex,
  LineContent,
  CircleNote,
  NoteIcon,
  TextPopover
} from '../LogisticStyles'

const WarehousePopup = ({ name, code }) => {

  const content = (
    <Flex>
      <TextPopover $bold>
        <Translate id='CODE' />:
      </TextPopover>
      <TextPopover>
        {code || <Translate id='NA' />}
      </TextPopover>
    </Flex>
  )
  return (

    <Popover placement='top' trigger='hover' content={content}>
      <LineContent>
        {name || <Translate id='NA' />}
        <CircleNote>
          <NoteIcon src={Info} alt='info' />
        </CircleNote>
      </LineContent>
    </Popover>
  )
}

WarehousePopup.propTypes = {
  item: PropTypes.object.isRequired
}

export default WarehousePopup
