import React from 'react'

import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'
import ArrowLeft from '../../../assets/icons/arrow_left.svg'
import {BackButtonStyle} from './ButtonsStyles'

const BackButton = ({onClick, disabled, label}) => (
  <BackButtonStyle onClick={onClick} disabled={disabled}>
    <img
      src={ArrowLeft}
      style={{marginRight: '10px'}}
      alt={<Translate id='BACK_IMAGE' />}
    />
    {label}
  </BackButtonStyle>
)

BackButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.element
  ])
}

BackButton.defaultProps = {
  onClick: () => null,
  disabled: false,
  label: <Translate id='BACK' />
}

export default BackButton
