import React, {useState} from 'react'

import {DeleteOutlined} from '@ant-design/icons'
import {Col, Row} from 'antd'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {Field, change} from 'redux-form'

import BaseButton from '../../../../shared/components/buttons/BaseButton'
import SelectInput from '../../../../shared/components/inputs/SelectInput'
import TextInput from '../../../../shared/components/inputs/TextInput'
import GetObjectByID from '../../../../shared/logic/arrays/GetObjectByID'
import {ErrorColor} from '../../../../shared/styles/_colors'
import {Margin, TitleH4} from '../../../../shared/styles/BasicStyles'

const ServiceTranslations = ({fields, languages, dispatch, meta}) => {
  const [language, setLanguage] = useState(undefined)
  const list = fields.getAll()

  const filterLanguages = () =>
    languages.filter((lang) => {
      const found =
        list?.length > 0 &&
        list.find((l) => l.languageId === lang.languageId)
      if (!found) return true
      if (found.show) return false
      if (!found.name || found.name === '') {
        return true
      }
      return false
    })

  const enableLanguage = () => {
    if (list?.length > 0) {
      const index = list.findIndex((elem) => elem.languageID === language)
      if (index > -1) {
        dispatch(
          change('manage_service', `${fields.name}[${index}].show`, true)
        )
      } else {
        fields.push({
          name: '',
          languageId: parseInt(language, 10),
          show: true
        })
      }
    } else {
      fields.push({
        name: '',
        languageId: parseInt(language, 10),
        show: true
      })
    }
    setLanguage(undefined)
  }

  const filtered = filterLanguages()

  return (
    <>
      <Row gutter={[20, 20]} align='bottom'>
        <Col xs={14} lg={5}>
          <SelectInput
            label={<Translate id='ADD_LANGUAGE' />}
            input={{
              value: language,
              onChange: setLanguage
            }}
            meta={meta}
            placeholder={<Translate id='SELECT' />}
            dataKey='languageId'
            dataLabel='name'
            data={filtered}
            disabled={filtered.length < 1}
          />
        </Col>
        <Col xs={4} lg={3}>
          <BaseButton
            type='secondary'
            disabled={!language}
            onClick={enableLanguage}
          >
            <Translate id='ADD' />
          </BaseButton>
        </Col>
      </Row>
      <Margin size={20} />
      <Row gutter={[20, 20]}>
        {list?.length > 0 &&
          list
            .filter((f) => (f.name && f.name !== '') || f.show)
            .map((currentField, index) => (
              <Col xs={12} key={index}>
                <Row gutter={[20, 20]}>
                  <Col xs={24}>
                    <TitleH4 $op>
                      {
                        GetObjectByID(
                          languages,
                          'languageId',
                          currentField?.languageId
                        )?.name
                      }
                    </TitleH4>

                  </Col>
                  <Col xs={24}>
                    <Field
                      component={TextInput}
                      name={`${fields.name}[${index}].name`}
                      label={<Translate id='NAME' />}
                    />
                    <DeleteOutlined
                      style={{
                        position: 'absolute',
                        top: '0',
                        right: '15px',
                        color: ErrorColor,
                        cursor: 'pointer',
                        zIndex: 2
                      }}
                      onClick={() => fields.remove(index)}
                    />
                  </Col>
                </Row>
                <Margin size={20} />
              </Col>
            ))}
      </Row>
      <Margin size={20} />
    </>
  )
}

ServiceTranslations.propTypes = {
  fields: PropTypes.object.isRequired,
  languages: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired
}

export default withLocalize(ServiceTranslations)
