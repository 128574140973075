import React, { useState } from 'react'

import { Row, Col, Radio } from 'antd'
import { Translate, withLocalize } from 'react-localize-redux'
import styled from 'styled-components'

import AlertService from '../../../../../shared/components/alert/AlertService'
import BaseButton from '../../../../../shared/components/buttons/BaseButton'
import NumberIncrementInput from '../../../../../shared/components/inputs/NumberIncrementInput'
import {
  TableRow,
  ListCol,
  TableTitle,
  TableInput,
  TextTooltip,
  TableText,
  FooterRow
} from '../../../../../shared/components/table/CustomTableStyles'
import { MaxValueFromArray } from '../../../../../shared/logic/arrays/ArrayFunctions'
import ActiveTranslation from '../../../../../shared/logic/translations/ActiveTranslation'
import { TitleH3 } from '../../../../../shared/styles/BasicStyles'
import EmployeeKitsModal from './EmployeeKitsModal'
import IDLIST from './IdsList'

const PreviewMessage = styled.div`
  text-align: center;
  font-size: 24px;
  padding: 30px;
  opacity: 0.6;
  cursor: pointer;
`

const GenerateBoxesTable = ({ workgroups, products, setProducts, boxes, setBoxes, disabled, translate }) => {
  const [toggle, setToggle] = useState(products.length < 20)
  const [boxNumber, setBoxNumber] = useState(1)
  const [modal, openModal] = useState()
  const [isKit, setKit] = useState(false)

  const selectedItems = products.reduce(
    (a, b) => a + parseInt(b.box || 0, 10),
    0
  )

  const create = () => {
    if (!boxNumber || boxNumber === '') {
      return AlertService.error(
        translate('CANNOT_CREATE_BOX'), translate('SELECT_NUMBER_OF_BOXES_TO_ADD')
      )
    }

    const added = []
    const errors = []
    const prods = products.map((product) => {
      const prod = { ...product }
      if (prod.box > 0) {
        const quantity = prod.box * boxNumber
        if (quantity > prod.left) {
          errors.push(`${translate('THERE_ARE_NO_QUANTITY_LEFT_FOR')} ${prod.reference}`)
        }
        added.push({ ...prod, quantity: parseInt(prod.box, 10) })
        prod.left -= quantity
      }
      prod.box = 0
      return prod
    })
    if (errors.length) {
      return AlertService.showValidations(translate('CANNOT_CREATE_BOX'), errors)
    }
    if (!added.length) {
      return AlertService.error(
        translate('CANNOT_CREATE_BOX'),
        translate('PLEASE_SELECT_ITEM_QUANTITIES')
      )
    }
    setProducts(prods)
    let loop = parseInt(boxNumber, 10)
    const boxList = [...boxes]
    let last = boxList.length ? MaxValueFromArray(boxList, (b) => b.realIndex) : -1
    while (loop--) {
      last++
      boxList.push({
        realIndex: last,
        isKit,
        items: added
      })
    }
    setBoxes(boxList)
    return setBoxNumber(1)
  }

  const removeExceptions = () => {
    const stock = [...products]
    const boxList = []
    let realIndex = 0
    boxes.forEach((box) => {
      if (IDLIST.includes(box.employeeId)) {
        boxList.push({ ...box, realIndex })
        realIndex++
      } else {
        box.items.forEach((i) => {
          const found = stock.findIndex((f) => f.barcode == i.barcode)
          if (found > -1) {
            stock[found].left += i.departQty
          }
        })
      }
    })

    setProducts(stock)
    setBoxes(boxList)
  }

  const changeBoxQuantity = (value, index) => {
    const list = [...products]
    list[index].box = value
    setProducts(list)
  }

  return (
    <Row gutter={[16, 30]}>
      <Col xs={24}>
        <TitleH3 $op><Translate id='CREATE_KITS_AND_BOXES' /></TitleH3>
      </Col>
      <Col xs={24}>
        <BaseButton
          type='primary'
          auto
          onClick={() => openModal(true)}
        >
          Create employee kits
        </BaseButton>

        {/* <BaseButton
          type='error'
          auto
          onClick={removeExceptions}
          style={{marginLeft: '20px'}}
        >
          Delete extra workgroups
        </BaseButton> */}

        <BaseButton
          type='secondary'
          auto
          onClick={() => setToggle((v) => !v)}
          style={{ marginLeft: '20px' }}
        >
          {toggle ? 'Close product list' : 'Open product list'}
        </BaseButton>
      </Col>
      <Col xs={24}>
        <TableRow $header>
          <ListCol xs={3}>
            <TableTitle first text={<Translate id='REFERENCE' />} />
          </ListCol>
          <ListCol xs={3}>
            <TableTitle text={<Translate id='EAN_CODE' />} />
          </ListCol>
          <ListCol xs={2}>
            <TableTitle text={<Translate id='CLIENT_REF' />} />
          </ListCol>
          <ListCol xs={4}>
            <TableTitle text={<Translate id='DESCRIPTION' />} />
          </ListCol>
          <ListCol xs={2}>
            <TableTitle text={<Translate id='COLOR' />} />
          </ListCol>
          <ListCol xs={1}>
            <TableTitle text={<Translate id='SIZE' />} />
          </ListCol>
          <ListCol xs={1}>
            <TableTitle text={<Translate id='WIDTH' />} />
          </ListCol>
          <ListCol xs={2}>
            <TableTitle text={<Translate id='QUANTITY_TO_SEND' />} abbr={<Translate id='QUANTITY_TO_SEND' />} />
          </ListCol>
          <ListCol xs={2}>
            <TableTitle text={<Translate id='QUANTITY_LEFT' />} abbr={<Translate id='QUANTITY_LEFT' />} />
          </ListCol>
          <ListCol xs={2}>
            <TableTitle text={<Translate id='BOX_QUANTITY' />} abbr={<Translate id='BOX_QTY' />} />
          </ListCol>
        </TableRow>

        {toggle ? products.map((product, index) => (

          <TableRow
            key={index}
            $disable={!product.left || product?.left === 0}
            $error={product?.left < 0}
          >
            <ListCol xs={3}>
              <TextTooltip first text={product.reference || '-'} />
            </ListCol>
            <ListCol xs={3}>
              <TextTooltip text={product.barcode || '-'} />
            </ListCol>
            <ListCol xs={2}>
              <TextTooltip text={product.referenceClient || '-'} />
            </ListCol>
            <ListCol xs={4}>
              <TextTooltip
                text={
                  <ActiveTranslation
                    value={product.description}
                    tag='name'
                  />
                }
              />
            </ListCol>
            <ListCol xs={2}>
              <TextTooltip
                text={
                  <ActiveTranslation
                    value={product?.color?.colorTranslation || '-'}
                    tag='name'
                  />
                }
              />
            </ListCol>
            <ListCol xs={1}>
              <TextTooltip text={product?.size?.nameEu || '-'} />
            </ListCol>
            <ListCol xs={1}>
              <TextTooltip text={product?.productWidth?.code || '-'} />
            </ListCol>
            <ListCol xs={2}>
              <TableText>{product?.departQty || '-'}</TableText>
            </ListCol>
            <ListCol xs={2}>
              <TableText>{product?.left || '-'}</TableText>
            </ListCol>
            <ListCol xs={2}>
              <TableInput>
                <NumberIncrementInput
                  disabled={
                    !product.left || product?.left === 0 || disabled
                  }
                  input={{ value: product.box, onChange: (v) => changeBoxQuantity(v, index) }}
                  step='1'
                />
              </TableInput>
            </ListCol>
          </TableRow>
        )) : <PreviewMessage onClick={() => setToggle(true)}>Click to show all products</PreviewMessage>}
        <FooterRow gutter={[16]}>
          <ListCol offset={15} xs={2}><Translate id='ITEMS' /> : {selectedItems}</ListCol>
          <ListCol xs={3}>
            <Radio.Group
              disabled={selectedItems === 0}
              onChange={(e) => setKit(e.target.value)}
              value={isKit}
            >
              <Radio value={false}><Translate id='BOX' /> </Radio>
              <Radio value><Translate id='KIT' /> </Radio>
            </Radio.Group>
          </ListCol>
          <ListCol xs={2}>
            <NumberIncrementInput
              disabled
              width='100%'
              input={{
                value: boxNumber,
                onChange: setBoxNumber
              }}
              step='1'
            />
          </ListCol>
          <ListCol xs={2}>
            <BaseButton
              size='small'
              type='primary'
              disabled={selectedItems === 0 || disabled}
              onClick={() => create(true)}
            >
              <Translate id='CREATE' />
            </BaseButton>
          </ListCol>
        </FooterRow>
      </Col>

      <EmployeeKitsModal
        open={modal}
        onClose={() => openModal(false)}
        products={products}
        setProducts={setProducts}
        setBoxes={setBoxes}
        setToggle={setToggle}
        workgroups={workgroups}
      />
    </Row>
  )
}

export default withLocalize(GenerateBoxesTable)
