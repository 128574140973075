import React, { useState, useEffect } from 'react'

import PropTypes from 'prop-types'

import { GetReportTypes } from '../../infra/requests/ReportRequests'
import BaseLoading from '../../shared/components/loading/BaseLoading'
import {
  ContentContainer,
  StyledTabPane,
  StyledTabs,
  StyledTabTitle,
  StyledTabTitleContainer
} from '../../shared/styles/BasicStyles'
import ReportList from './ReportList'

const getModules = (list) => {
  const modules = []
  if (list && list.length) {
    list.forEach((item) => {
      if (!modules.includes(item.module.toLowerCase())) {
        modules.push(item.module.toLowerCase())
      }
    })
  }
  return modules
}

const ReportsPage = ({ router }) => {
  const [reports, setReports] = useState([])
  const [sections, setSections] = useState([])
  const [ready, setReady] = useState(false)
  const [currentSection, setCurrentSection] = useState()
  const iFrameDetection = ((window === window.parent || window.opener))

  useEffect(() => {
    const init = async () => {
      const { data, success } = await GetReportTypes()
      if (success) {
        setSections(getModules(data))
        setReports(data)
      }
      setReady(true)
    }
    init()
  }, [])

  function capitalizeFirstLetter(str) {
    // Split the string into words
    const words = str.split(' ')

    // Capitalize the first letter of each word
    const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1))

    // Join the words back into a string
    const capitalizedString = capitalizedWords.join(' ')

    return capitalizedString
  }

  const transformString = (string) => {
    // Split the string into words
    const words = string.split(' ')

    // Capitalize the first letter of each word except the first one
    const transformedWords = words.map((word, index) => {
      if (index === 0) {
        return word.toLowerCase()
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    })

    // Join the transformed words and return the result
    return transformedWords.join('')
  }

  const transformStringIntoTow = (inputString) => {
    // Split the string by capital letters
    const words = inputString.split(/(?=[A-Z])/).filter(Boolean) // Add .filter(Boolean) to remove empty elements

    // Capitalize the first letter of each word and join them with a space
    const transformedString = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ').replace(/\s{2,}/g, ' ')

    return transformedString
  }

  const capitalizeEachWord = (inputString) => {
    // Split the string into words
    const words = inputString.split(/\s+/)

    // Capitalize the first letter of each word
    const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1))

    // Join the words back into a single string
    const capitalizedString = capitalizedWords.join(' ')

    return capitalizedString
  }

  useEffect(() => {
    const handleUrl = () => {
      const hash = transformStringIntoTow(router.location.hash.replace('#', '')).toLowerCase()
      if (sections.find((s) => s === hash.toLowerCase())) {
        setCurrentSection(hash.toLowerCase())
      } else if (hash === 'internaloperations') {
        setCurrentSection('internal operations')
      } else setCurrentSection('sales')
    }
    handleUrl()
  }, [router, router.location.hash, sections])

  const onChangeTab = (active) => {
    setCurrentSection(active.toLowerCase())
    if (active.split(' ').length > 1) {
      router.history.replace(`/reports#${transformString(active)}`)
    } else {
      router.history.replace(`/reports#${active.toLowerCase()}`)
    }
  }

  if (!ready) return <BaseLoading margin='200' />

  return (
    <ContentContainer>
      <StyledTabs destroyInactiveTabPane activeKey={currentSection} onChange={onChangeTab} $hidden={!iFrameDetection}>
        {sections.map((section) => (
          <StyledTabPane
            tab={
              <StyledTabTitleContainer>
                <StyledTabTitle>{capitalizeFirstLetter(section)}</StyledTabTitle>
              </StyledTabTitleContainer>
            }
            key={section}
          >
            {/* Render the ReportList component for each moduleb(divide it in diferent sections), passing filtered reports */}
            <ReportList reports={reports.filter((r) => r.module === capitalizeEachWord(section))} reporti={sections} />
          </StyledTabPane>
        )
        )}
      </StyledTabs>
    </ContentContainer>
  )
}

ReportsPage.propTypes = {
  router: PropTypes.object.isRequired
}

export default ReportsPage
