import React from 'react'

import {PropTypes} from 'prop-types'
import {Translate} from 'react-localize-redux'

import {
  InlineTextInputStyled,
  InlineInputLabel,
  InlineInputContainer,
  InlineTextStyled,
  InputError
} from './InputStyles'

const InlineTextInput = ({
  input,
  label,
  meta,
  type,
  placeholder,
  afterChange,
  min,
  max,
  width,
  height,
  backgroundColor,
  borderColor,
  disabled,
  priority
}) => {
  const handleChange = (event) => {
    input.onChange(event.target.value)
    if (typeof afterChange === 'function') {
      afterChange(event.target.value)
    }
  }

  const hasError = meta.invalid && meta.submitFailed

  return (
    <InlineInputContainer $priority={priority}>
      {label && <InlineInputLabel>{label}</InlineInputLabel>}
      {disabled ? (
        <InlineTextStyled
          $width={width}
          $height={height}
          $backgroundColor={backgroundColor}
          $borderColor={borderColor}
          $hasError={hasError}
        >
          {input.value}
        </InlineTextStyled>
      ) : (
        <InlineTextInputStyled
          type={type}
          placeholder={placeholder}
          value={input.value}
          onChange={handleChange}
          min={min}
          max={max}
          $width={width}
          $height={height}
          $backgroundColor={backgroundColor}
          $borderColor={borderColor}
          $hasError={hasError}
        />
      )}
      {hasError && (
        <InputError style={{bottom: '-16px'}}>
          <Translate id={meta.error} />
        </InputError>
      )}
    </InlineInputContainer>
  )
}

InlineTextInput.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
    PropTypes.element
  ]),
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
    PropTypes.element
  ]),
  type: PropTypes.string,
  afterChange: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  width: PropTypes.string,
  height: PropTypes.string,
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
  priority: PropTypes.bool
}

InlineTextInput.defaultProps = {
  input: {value: undefined, onChange: () => null},
  label: undefined,
  placeholder: undefined,
  meta: {},
  type: 'text',
  afterChange: undefined,
  min: undefined,
  max: undefined,
  width: undefined,
  height: undefined,
  backgroundColor: undefined,
  borderColor: undefined,
  priority: false
}

export default InlineTextInput
