import React from 'react'

import {PropTypes} from 'prop-types'
import {Translate} from 'react-localize-redux'
import {SwitchStyled, InputLabel} from './InputStyles'

const SwitchInput = ({
  input,
  checkedText,
  uncheckedText,
  label,
  disabled,
  afterChange
}) => {
  const handleChange = (checked) => {
    input.onChange(checked)
    if (typeof afterChange === 'function') {
      afterChange(checked)
    }
  }

  return (
    <>
      {label && <InputLabel>{label}</InputLabel>}
      <SwitchStyled
        onChange={handleChange}
        checked={input.value}
        checkedChildren={checkedText}
        unCheckedChildren={uncheckedText}
        disabled={disabled}
      />
    </>
  )
}

SwitchInput.propTypes = {
  input: PropTypes.object,
  disabled: PropTypes.bool,
  checkedText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  uncheckedText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  afterChange: PropTypes.func
}

SwitchInput.defaultProps = {
  input: {value: undefined, onChange: () => null},
  checkedText: <Translate id='ON' />,
  uncheckedText: <Translate id='OFF' />,
  label: undefined,
  disabled: false,
  afterChange: undefined
}

export default SwitchInput
