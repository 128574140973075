import DuplicateProductPage from '../../pages/products/DuplicateProductsPage'
import ManageProductPage from '../../pages/products/manage_products/ManageProductPage'
import ManageRawMaterialPage from '../../pages/products/manage_rawMaterials/ManageRawMaterialPage'
import ManageServicePage from '../../pages/products/manage_services/ManageServicePage'
import ProductsPage from '../../pages/products/ProductsPage'

export default [
  {
    path: '/products',
    component: ProductsPage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  },
  {
    path: '/products/:id/duplicate',
    component: DuplicateProductPage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  },
  {
    path: '/products/:id',
    component: ManageProductPage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    withoutContainer: true,
    role: 1
  },
  {
    path: '/materials/:id',
    component: ManageRawMaterialPage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    withoutContainer: true,
    role: 1
  },
  {
    path: '/services/:id',
    component: ManageServicePage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    withoutContainer: true,
    role: 1
  },
  {
    path: '/services/new',
    component: ManageServicePage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    withoutContainer: true,
    role: 1
  }
]
