import React from 'react'

import {Row, Col} from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {Field, FieldArray} from 'redux-form'

import AddressList from '../../../../shared/components/address/AddressList'
import DateInput from '../../../../shared/components/inputs/DateInput'
import SelectInput from '../../../../shared/components/inputs/SelectInput'
import TextAreaInput from '../../../../shared/components/inputs/TextAreaInput'
import TextInput from '../../../../shared/components/inputs/TextInput'

const Information = ({countries, contactTypes, translate}) => (
  <Row gutter={[24, 40]}>
    <Col xs={6}>
      <Field
        component={SelectInput}
        name='contactTypeId'
        label={<Translate id='TYPE' />}
        placeholder={<Translate id='SELECT_TYPE_CONTACT' />}
        data={contactTypes}
        allowClear={false}
        dataKey='contactTypeId'
        dataLabel='contactTypeTranslation'
      />
    </Col>
    <Col xs={6}>
      <Field
        component={TextInput}
        name='name'
        label={<Translate id='NAME' />}
        placeholder={translate('NAME')}
      />
    </Col>
    <Col xs={6}>
      <Field
        component={TextInput}
        name='email'
        type='email'
        label={<Translate id='EMAIL' />}
        placeholder={translate('EMAIL')}
      />
    </Col>
    <Col xs={6}>
      <Field
        component={DateInput}
        name='dateBirth'
        disabledDate={(value) => value.isAfter(moment().subtract(18, 'years'))}
        label={<Translate id='BIRTHDAY' />}
      />
    </Col>

    <Col xs={24}>
      <Field
        component={TextAreaInput}
        name='notes'
        rows={5}
        label={<Translate id='NOTES' />}
        placeholder={translate('NOTES')}
      />
    </Col>
    <Col xs={24}>
      <FieldArray
        name='contactDetail'
        component={AddressList}
        countries={countries}
      />
    </Col>
  </Row>
)

Information.propTypes = {
  countries: PropTypes.array.isRequired,
  contactTypes: PropTypes.array.isRequired
}

export default withLocalize(
  connect((state) => ({
    contactTypes: state.info.contactTypes,
    countries: state.info.countries
  }))(Information)
)
