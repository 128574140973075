import React, { useEffect, useState } from 'react'

import { Row, Col } from 'antd'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { Field, FieldArray } from 'redux-form'

import { GetLanguages } from '../../../../infra/requests/BaseRequests'
import AddressList from '../../../../shared/components/address/AddressList'
import SelectInput from '../../../../shared/components/inputs/SelectInput'
import SwitchInput from '../../../../shared/components/inputs/SwitchInput'
import TextInput from '../../../../shared/components/inputs/TextInput'
import { Margin } from '../../../../shared/styles/BasicStyles'

const InformationComponent = ({ countries, currencies }) => {
  const [languages, setLanguages] = useState([])
  useEffect(() => {
    async function initlanguages() {
      const { data, success } = await GetLanguages()
      if (success) setLanguages(data?.items || [])
    }
    initlanguages()
  }, [])

  return (
    <>
      <Row>
        <Col xs={6}>
          <Field
            name='name'
            component={TextInput}
            label={<Translate id='NAME' />}
          />
        </Col>
        <Col xs={5} offset={1}>
          <Field
            name='contact.email'
            component={TextInput}
            label={<Translate id='EMAIL' />}
            type='email'
          />
        </Col>
        <Col xs={3} offset={1}>
          <Field
            name='mobile'
            component={TextInput}
            label={<Translate id='TELEPHONE' />}
          />
        </Col>
        <Col xs={3} offset={1}>
          <Field
            name='telephone'
            component={TextInput}
            label={<Translate id='LANDLINE' />}
          />
        </Col>
        <Col xs={2} offset={1}>
          <Field
            component={SwitchInput}
            name='active'
            label={<Translate id='ACTIVE' />}
            checkedText={<Translate id='YES' />}
            uncheckedText={<Translate id='NO' />}
          />
        </Col>

      </Row>
      <Margin size={10} />
      <Row>
        <Col xs={6}>
          <Field
            name='vatnumber'
            component={TextInput}
            label={<Translate id='VAT' />}
          />
        </Col>
        <Col xs={5} offset={1}>
          <Field
            component={SelectInput}
            name='languageId'
            label={<Translate id='LANGUAGE' />}
            placeholder={<Translate id='SELECT' />}
            data={languages}
            dataKey='languageId'
            dataLabel='name'
          />
        </Col>
        <Col xs={4} offset={1}>
          <Field
            allowClear={false}
            component={SelectInput}
            name='currencyId'
            label={<Translate id='CURRENCY' />}
            placeholder={<Translate id='SELECT' />}
            defaultValue={1}
            data={currencies}
            dataKey='currencyId'
            dataLabel='code'
          />
        </Col>
      </Row>

      <Margin size={47} />
      <FieldArray
        name='contact.contactDetail'
        component={AddressList}
        countries={countries}
      />
    </>
  )
}

InformationComponent.propTypes = {
  countries: PropTypes.array,
  currencies: PropTypes.array,
  initialize: PropTypes.func.isRequired
}

InformationComponent.defaultProps = {
  countries: [], currencies: [], currencyId: 1
}

export default InformationComponent
