import React from 'react'

import {PropTypes} from 'prop-types'
import NumberFormat from 'react-number-format'
import styled from 'styled-components'

const DynamicNumberStyled = styled(NumberFormat)`
  outline: none;
  border: none;
  background-color: transparent;
  overflow: hidden;
  max-width: 100%;
`

const DynamicNumberInput = ({value}) => (
  <DynamicNumberStyled
    value={parseFloat(value).toFixed(2)}
    decimalSeparator='.'
    thousandSeparator={' '}
    disabled
  />
)

DynamicNumberInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

DynamicNumberInput.defaultProps = {
  value: undefined
}
export default DynamicNumberInput
