import {NavLink} from 'react-router-dom'
import styled from 'styled-components'

import {
  NavBackgroundColor,
  PageBackground,
  PrimaryButtonColor,
  BorderColor,
  PrimaryColor,
  TableTextColor
} from '../../../styles/_colors'
import {
  NavbarSize,
  PageLeftRightMargin,
  HeaderBreakpoint
} from '../../../styles/_responsive'
import {CaptionText, SemiBoldFontWeight} from '../../../styles/_texts'

export const HeaderNavbar = styled.nav`
  height: ${({opened}) => (opened ? 'auto' : NavbarSize)};
  background-color: ${NavBackgroundColor};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  position: fixed;
  top: 0;
  z-index: 3000;
  border-bottom: 1px solid ${PageBackground};
  width: 100%;
  overflow: hidden;

  @media (max-width: ${HeaderBreakpoint}px) {
    padding-right: 0px;
  }
`

export const LogoContainer = styled.div`
  margin-left: ${PageLeftRightMargin};
  height: 100%;
  display: flex;
  align-items: center;
`

export const MenuItem = styled.li`
  font-family: 'Campton';
  color: inherit;
  text-decoration: none;
  font-weight: ${SemiBoldFontWeight};
  color: ${({$color}) => $color || TableTextColor};
  font-size: ${CaptionText};
  padding: 0 15px;
  border-right: 1px solid rgba(198, 200, 202, 0.5);
  display: block;
  opacity: ${({$op}) => $op || 0.5};

  @media (max-width: 1300px) {
    padding: 0 10px;
  }

  @media (max-width: ${HeaderBreakpoint}px) {
    border-right: none;
  }
`

export const MenuItemContainer = styled(NavLink)`
  height: calc(100% + 1px);
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 3px solid
    ${(props) => (props.active ? PrimaryColor : 'transparent')};
  margin-bottom: 3px;
  cursor: pointer;

  &:focus,
  &:hover {
    outline: none;
  }

  &:focus ${MenuItem}, &:hover ${MenuItem} {
    color: ${PrimaryButtonColor};
    opacity: 1;
    outline: none;
  }

  &:last-child ${MenuItem} {
    border-right: none;
  }

  &.active {
    border-bottom: 3px solid ${PrimaryColor};

    ${MenuItem} {
      opacity: 1;
      color: ${PrimaryButtonColor};
    }
  }

  @media (max-width: ${HeaderBreakpoint}px) {
    border-right: none;
  }
`

const ResponsiveHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  ${({collapsed}) => {
    if (collapsed) {
      return `
        width: 100%;
        display: inline-block;

        ${MenuItemContainer}{
          padding: 15px 0px;
          margin: 0px ${PageLeftRightMargin}; 
        }
      `
    }
    return ''
  }}
`
export const LeftMenu = styled(ResponsiveHeader)``
export const RightMenu = styled(ResponsiveHeader)`
  position: relative;
  height: 100%;
`

export const AccountImage = styled.img`
  border-radius: 15px;
  border: 1px solid ${BorderColor};
  margin-left: 30px;
`

export const AccountContainer = styled.div`
  height: ${NavbarSize};
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 3px solid
    ${(props) => (props.active ? PrimaryColor : 'transparent')};
  margin-bottom: 3px;
  cursor: pointer;

  &:focus,
  &:hover {
    outline: none;
  }

  &:focus ${MenuItem}, &:hover ${MenuItem} {
    color: ${PrimaryButtonColor};
    opacity: 1;
    outline: none;
  }

  &:last-child ${MenuItem} {
    border-right: none;
  }

  &.active {
    ${MenuItem} {
      color: ${PrimaryButtonColor};
    }
  }

  &:hover ${AccountImage} {
    border: 1px solid ${PrimaryColor};
  }

  ${({active}) => {
    if (active) {
      return `
        ${AccountImage}{
          border: 1px solid ${PrimaryColor};
        }
      `
    }
    return ''
  }}

  @media (max-width: ${HeaderBreakpoint}px) {
    margin: 0px 40px;
    padding: 15px 0px;
  }
`

export const AccountName = styled(MenuItem)`
  padding: 0px 10px 0 10px;
  text-transform: uppercase;
  color: ${({$color}) => $color || TableTextColor};
  max-width: 115px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-right: none;
`

export const AccountDropdown = styled.div`
  display: none;

  ${({profileOpened, collapsed}) => {
    if (profileOpened && collapsed) {
      return ` 
        display: block;
        position: absolute;
        bottom: 50px;
        left: 49%;
        transform: translateX(-50%);
        min-width: 280px;
        background-color: ${NavBackgroundColor};
        border: 1px solid ${PageBackground};
        border-bottom: none;
        border-radius: 15px;
      `
    }
    if (profileOpened) {
      return `
        display: block;
        position: fixed;
        min-width: 200px;
        top: 89px;
        right: 5px;
        border: 1px solid ${PageBackground};
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        border-top: none;
        background-color: ${NavBackgroundColor};
      `
    }

    return ''
  }}
`
export const DropdownItem = styled(MenuItem)`
  border-right: none;
  color: ${({$color}) => $color || TableTextColor};
  opacity: ${({$op}) => $op || 0.5};
  text-align: ${({$align}) => $align || 'center'};
`

export const DropdownItemContainer = styled.div`
  height: auto;
  padding: ${({$padding}) => ($padding ? '10px 0px' : '15px 0px')};
  cursor: pointer;
  background-color: ${({$color}) => $color || 'transparent'};
  border-bottom: 2px solid
    ${({$borderColor, active}) => active ? PrimaryColor : $borderColor || BorderColor};
  
  ${({active}) => active ? `
    {
      ${DropdownItem} {
       color: ${PrimaryColor};
        opacity: 1;
      }
    }` : ''}

  &:focus,
  &:hover {
    border-bottom: 2px solid ${PrimaryColor};
  }

  &:focus ${DropdownItem}, &:hover ${DropdownItem} {
    color: ${({$color}) => ($color ? 'white' : PrimaryButtonColor)};
    opacity: ${({$color}) => ($color ? 0.5 : 1)};
  }

  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`

export const NavbarCollapsed = styled.div`
  ${(props) => {
    if (props.collapsed) {
      return 'width: 100%;'
    }
    return ''
  }}
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: ${NavbarSize};
`

const HamburgerBar = styled.div`
  width: 30px;
  height: 4px;
  background-color: ${PrimaryColor};
  margin: 2px 0;
  transition: 0.4s;
  border-radius: 10px;
`

export const HamburgerBarOne = styled(HamburgerBar)``

export const HamburgerBarTwo = styled(HamburgerBar)``

export const HamburgerBarThree = styled(HamburgerBar)``

export const HamburgerContainer = styled.div`
  ${({collapsed}) => {
    if (collapsed) {
      return `
      display: flex; 
      flex-direction: column; 
      justify-content: center; 
      margin-right:${PageLeftRightMargin};`
    }
    return 'display: none;'
  }}

  ${({isNavToggled}) => {
    if (isNavToggled) {
      return `
        ${HamburgerBarOne}{
          transform: rotate(-45deg) translate(-4px, 5px);
        }
        ${HamburgerBarTwo}{
          opacity: 0;
        }
        ${HamburgerBarThree}{
          transform: rotate(45deg) translate(-6px, -7px);
        }
      `
    }
    return ''
  }}
`
export const SiteTitle = styled.div`
  color: ${TableTextColor};
  font-size: ${CaptionText};
  line-height: 16px;
  margin-left: 15px;
  margin-top: 16px;
`
