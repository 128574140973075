import moment from 'moment'

export const IsToday = (date) =>
  date.date() === moment.utc().date() &&
  date.month() === moment.utc().month() &&
  date.year() === moment.utc().year()

export const IsSameDay = (date1, date2) =>
  date1.date() === date2.date() &&
  date1.month() === date2.month() &&
  date1.year() === date2.year()
