import React, {useState, useEffect} from 'react'

import {Row, Col} from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {
  reduxForm,
  FieldArray,
  Field,
  getFormValues,
  change
} from 'redux-form'

import {GetCurrencies} from '../../../infra/requests/BaseRequests'
import {
  CreateOrderB2B,
  AppendOrderFiles
} from '../../../infra/requests/OrdersB2BRequests'
import FormValidator from '../../../infra/services/validations/FormValidator'
import AlertService from '../../../shared/components/alert/AlertService'
import BackButton from '../../../shared/components/buttons/BackButton'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import DateInput from '../../../shared/components/inputs/DateInput'
import DocumentsInput from '../../../shared/components/inputs/Documents/NewDocumentsInput'
import SelectInput from '../../../shared/components/inputs/SelectInput'
import {
  PageTitle,
  PageForm,
  Margin,
  PageSubtitle
} from '../../../shared/styles/BasicStyles'
import CurrencyProductsTable from './components/CurrencyProductsTable'
import DeliveryLocationSelect from './components/DeliveryLocationSelect'

const validations = FormValidator.make({
  b2bclientId: 'required',
  stockOwnerId: 'required',
  paymentConditionId: 'required',
  deliveryContactDetailId: 'required',
  shippingConditionId: 'required',
  currencyId: 'required',
  dateDelivery: 'required',
  orderB2bitem: 'required|array'
})

const NewOrder = ({
  router,
  translate,
  dispatch,
  handleSubmit,
  paymentConditions,
  shippingConditions,
  currencies,
  clients,
  formValues
}) => {
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(undefined)
  const [currenciesList, setList] = useState(currencies)

  useEffect(() => {
    const init = async () => {
      const {data, success} = await GetCurrencies()
      if (success) setList(data?.list || currencies)
    }
    init()
  }, [])

  const onSubmit = async (values) => {
    setLoading(true)
    const payload = {
      ...values,
      dateOrder: moment()
    }
    const {data, success} = await CreateOrderB2B(payload)
    if (values.attachments && values.attachments.length) {
      const types = []
      const formData = new FormData()
      values.attachments.forEach((attachment) => {
        formData.append('Attachments', attachment.file)
        types.push(attachment.type)
      })
      formData.append('AttachmentTypes', JSON.stringify(types))
      await AppendOrderFiles(data, formData)
    }
    setLoading(false)
    if (success) router.history.push('/orders#b2b')
  }

  const clearProductsTable = () => {
    dispatch(change('manage_new_order_form', 'orderB2bitem', []))
    setTimeout(() => setRefresh(Math.random()), 50)
  }

  const showAlertMessage = (values) => {
    if (moment(values?.dateDelivery).startOf('day').isBefore(moment().startOf('day'))) {
      return AlertService.confirm(
        translate('B2B_ORDER_IS_PREVIOUS_THAT_TODAY'),
        translate('CONTINUE_CONFIRM'),
        () => onSubmit(values)
      )
    }
    return onSubmit(values)
  }

  const addCurrency = (id) => {
    const curr = currenciesList.find((c) => c.currencyId == id)
    dispatch(change('manage_new_order_form', 'currency', curr))
  }

  return (
    <PageForm>
      <BackButton
        label={<Translate id='BACK_ORDERS' />}
        disabled={loading}
        onClick={() => router.history.push('/orders#b2b')}
      />

      <PageTitle $top={30} $bottom={30}>
        <Translate id='NEW_ORDER' />
      </PageTitle>

      <Row gutter={[12, 24]}>
        <Col xs={4}>
          <Field
            component={SelectInput}
            name='b2bclientId'
            label={<Translate id='CLIENT' />}
            placeholder={<Translate id='DEFINE_CLIENT' />}
            data={clients}
            dataLabel='name'
            dataKey='b2bclientId'
            afterChange={clearProductsTable}
          />
        </Col>
        <Col xs={4}>
          <Field
            component={SelectInput}
            name='stockOwnerId'
            label={<Translate id='STOCK_OWNER' />}
            placeholder={<Translate id='STOCK_OWNER' />}
            data={clients}
            dataLabel='name'
            dataKey='b2bclientId'
          />
        </Col>
        <Col xs={6}>
          <Field
            component={DeliveryLocationSelect}
            name='deliveryContactDetailId'
            label={<Translate id='DELIVERY_LOCATION' />}
            placeholder={<Translate id='DELIVERY_LOCATION' />}
            client={formValues?.b2bclientId}
          />
        </Col>
        <Col xs={4}>
          <Field
            component={DateInput}
            name='dateDelivery'
            label={<Translate id='DATE_DELIVERY' />}
            placeholder='Delivery Date'
          />
        </Col>
        <Col xs={6}>
          <FieldArray component={DocumentsInput} name='attachments' />
        </Col>
        <Col xs={6}>
          <Field
            component={SelectInput}
            name='shippingConditionId'
            label={<Translate id='SHIPPING_CONDITION' />}
            placeholder={<Translate id='SHIPPING_CONDITION' />}
            data={shippingConditions}
            dataLabel='value'
            dataKey='key'
          />
        </Col>
        <Col xs={6}>
          <Field
            component={SelectInput}
            name='paymentConditionId'
            label={<Translate id='PAYMENT_CONDITION' />}
            placeholder={<Translate id='PAYMENT_CONDITION' />}
            data={paymentConditions}
            dataLabel='name'
            dataKey='paymentConditionId'
          />
        </Col>
        <Col xs={4}>
          <Field
            component={SelectInput}
            name='currencyId'
            label={<Translate id='CURRENCY' />}
            placeholder={<Translate id='CURRENCY' />}
            data={currencies}
            dataLabel='code'
            dataKey='currencyId'
            afterChange={addCurrency}
          />
        </Col>
      </Row>

      <Margin size='30' />
      <PageSubtitle>
        <Translate id='PRODUCTS' />
      </PageSubtitle>
      <FieldArray
        component={CurrencyProductsTable}
        name='orderB2bitem'
        client={formValues?.b2bclientId}
        currency={formValues?.currency}
        refresh={refresh}
      />

      <Margin size={30} />
      <Row align='middle' justify='end'>
        <Col xs={24} flex={1} style={{textAlign: 'right'}}>
          <BaseButton
            type='secondary'
            onClick={() => router.history.push('/orders')}
            auto
            disabled={loading}
          >
            <Translate id='CANCEL_ORDER' />
          </BaseButton>
          <BaseButton
            style={{marginLeft: '20px'}}
            auto
            type='primary'
            onClick={handleSubmit(showAlertMessage)}
            loading={loading}
          >
            <Translate id='CREATE_ORDER' />
          </BaseButton>
        </Col>
      </Row>
    </PageForm>
  )
}

NewOrder.propTypes = {
  router: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  paymentConditions: PropTypes.array.isRequired,
  shippingConditions: PropTypes.array.isRequired,
  clients: PropTypes.array.isRequired,
  formValues: PropTypes.object
}

NewOrder.defaultProps = {
  formValues: {}
}

const NewOrderPage = reduxForm({
  form: 'manage_new_order_form',
  enableReinitialize: true,
  validate: validations
})(NewOrder)

const mapStateToProps = (state) => ({
  clients: state.info.clients,
  paymentConditions: state.info.paymentConditions,
  shippingConditions: state.info.shippingConditions,
  deliveryLocations: state.info.deliveryLocations,
  currencies: state.info.currencies,
  formValues: getFormValues('manage_new_order_form')(state)
})

export default withLocalize(connect(mapStateToProps)(NewOrderPage))
