import React, { useEffect, useState } from 'react'

import { Row, Col } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { useParams } from 'react-router-dom'

import TransferDetail from './TransferDetail'
import { GetWarehouseTransferDetail, GetShipmentDetail, ReceiveExpectedItems, GetTransferDetail } from '../../../../infra/requests/DepartureRequests'
import AlertService from '../../../../shared/components/alert/AlertService'
import BackButton from '../../../../shared/components/buttons/BackButton'
import BaseButton from '../../../../shared/components/buttons/BaseButton'
import PropertyDescription from '../../../../shared/components/description/PropertyDescription'
import BaseLoading from '../../../../shared/components/loading/BaseLoading'
import ActiveTranslation from '../../../../shared/logic/translations/ActiveTranslation'
import {
  Margin,
  PageForm,
  PageTitle,
  TitleH3
} from '../../../../shared/styles/BasicStyles'
import ShippingDetail from '../components/shipping/ShippingDetail'

const WAITING_CONFIRMATION_EWP = 'Waiting Confirmation EWP'
const WAITING_CONFIRMATION_PORTAL = 'Waiting Confirmation Portal'

const TransferDetailPage = ({ router }) => {
  const [loading, setLoading] = useState(true)
  const [details, setDetails] = useState()
  const [transferDetail, setTransferDetail] = useState()
  const [confirmationLoading, setConfirmationLoading] = useState()
  const [shipment, setShipment] = useState()
  const { id } = useParams()

  useEffect(() => {
    const init = async () => {
      const { data, success } = await GetWarehouseTransferDetail(id)
      if (!success) return router.history.push('/logistics#depart')
      setDetails(data)

      if (data?.shipmentId) {
        const { data: shipData, success: shipSuccess } = await GetShipmentDetail(data.shipmentId)
        if (shipSuccess) setShipment(shipData)
      }

      if (data.warehouseTransferIds) {
        const { data: transferDetailData, success: transferDetailSuccess } = await GetTransferDetail(data.warehouseTransferIds[0])
        if (transferDetailSuccess) setTransferDetail(transferDetailData)
      }
      setLoading(false)
    }
    init()
  }, [id])

  const status = details?.movementStatusTranslations[0].name
  const showConfirmBtn = status === WAITING_CONFIRMATION_EWP || status === WAITING_CONFIRMATION_PORTAL
  const confirmButtonText = () => (
    <Translate id={`CONFIRM_TRANSFER_${status === WAITING_CONFIRMATION_EWP ? 'EWP' : 'PORTAL'}`} />
  )

  const orderNumbers = transferDetail?.orderB2Bs?.map((order) => order.number)
  const orderNumbersDisplay = orderNumbers?.length === 0 ? '-' : orderNumbers?.join(', ')

  const handleConfirmTransferClick = async () => {
    setConfirmationLoading(true)
    try {
      const confirmationData = [
        {
          warehouseTransferId: details.warehouseTransferIds[0],
          ReceivePendingQuantities: true
        }
      ]

      const { success } = await ReceiveExpectedItems(confirmationData)

      if (success) {
        window.location.reload()
      }
      setConfirmationLoading(false)
    } catch (error) {
      AlertService.error('Error', 'An unexpected error occurred')
      setConfirmationLoading(false)
    }
  }

  if (loading) return <BaseLoading margin='200' />

  return (
    <PageForm>
      <Row gutter={[0, 50]}>
        <Col xs={24}>
          <BackButton
            label='Back to logistics'
            onClick={() => router.history.push('/logistics#depart')}
          />
        </Col>
        <Col xs={24}>
          <PageTitle>Departure Movement #{id}</PageTitle>
        </Col>
      </Row>
      <Margin size={50} />
      <Row gutter={[40, 20]}>
        <Col xs={12} sm={12} lg={6} xl={4}>
          <PropertyDescription
            label='Client'
            value={details?.b2bclientName || 'N/A'}
          />
        </Col>
        <Col xs={12} sm={12} lg={6} xl={4}>
          <PropertyDescription
            label='Order'
            value={orderNumbersDisplay || 'N/A'}
          />
        </Col>
        <Col xs={12} sm={12} lg={6} xl={4}>
          <PropertyDescription
            label='Current warehouse'
            value={details?.warehouseName || 'N/A'}
          />
        </Col>
        <Col xs={12} sm={12} lg={6} xl={4}>
          <PropertyDescription
            label='Departure Date'
            value={details?.date ? moment(details?.date).format('DD-MM-YYYY HH:mm:ss') : 'N/A'}
          />
        </Col>
        <Col xs={12} sm={12} lg={6} xl={4}>
          <PropertyDescription
            label='Movement Status'
            value={<ActiveTranslation value={details?.movementStatusTranslations} tag='name' />}
          />
        </Col>
        {showConfirmBtn &&
          <Col xs={12} sm={12} lg={6} xl={4}>
            <BaseButton
              onClick={handleConfirmTransferClick}
              loading={confirmationLoading}
              disabled={confirmationLoading}
              aria-label={confirmationLoading ? 'Confirming transfer' : `Confirm Transfer ${status === WAITING_CONFIRMATION_EWP ? 'by EWP' : 'by Portal'}`}
              auto
            >
              {confirmButtonText()}
            </BaseButton>
          </Col>
        }
        {shipment && (
          <Col xs={24}>
            <TitleH3>Shipment #{details?.shipmentId}</TitleH3>
            <ShippingDetail delivery={shipment} />
          </Col>
        )}
        {details?.warehouseTransferIds?.length > 0 && (
          <Col xs={24}>
            <Row>
              {details.warehouseTransferIds.map((transferId) => (
                <Col xs={24} key={transferId}>
                  <TransferDetail transferDetail={transferDetail} />
                </Col>
              ))}
            </Row>
          </Col>
        )}
      </Row>
    </PageForm>
  )
}

export default TransferDetailPage

TransferDetailPage.propTypes = {
  router: PropTypes.shape({
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }).isRequired
  }).isRequired
}
