import React from "react";

import { Collapse } from "antd";

import Report from "./Report";

const { Panel } = Collapse;

const ReportList = ({ reports }) => {

  return(
    <Collapse accordion>
    {reports.map((report, index, reports) => (
      <Panel header={report.reportName} key={report.storeProcedureName}>
        <Report report={report}  index={index} reporti={reports} />
      </Panel>
    ))}
    </Collapse>
    )
  }

export default ReportList;
