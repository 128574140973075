import React, {Component} from 'react'

import {Col, Row} from 'antd'
import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'

import IconAdd from '../../../../assets/icons/add_bordered.svg'
import {GetReceivingServersData} from '../../../../infra/requests/BaseRequests'
import BaseButton from '../../../../shared/components/buttons/BaseButton'
import StatusColumn from '../../../../shared/components/Status/StatusColumn'
import BaseTable from '../../../../shared/components/table/BaseTable'
import {Margin} from '../../../../shared/styles/BasicStyles'

class ReceiveServerTab extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rows: [],
      page: 1,
      pageSize: 20,
      total: 0
    }
  }

  getColumns = () => [
    {
      dataIndex: 'name',
      type: 'text',
      title: <Translate id='NAME' />
    },
    {
      dataIndex: 'hostname',
      type: 'text',
      title: <Translate id='HOST_NAME' />
    },
    {
      dataIndex: 'imapPort',
      type: 'text',
      title: <Translate id='PORT' />
    },
    {
      dataIndex: 'username',
      type: 'text',
      title: <Translate id='USERNAME' />
    },
    {
      dataIndex: 'password',
      type: 'text',
      title: <Translate id='PASSWORD' />
    },
    {
      dataIndex: 'active',
      type: 'text',
      title: <Translate id='STATE' />,
      render: (value) => <StatusColumn value={value} />
    }
  ]

  componentDidMount = async () => {
    try {
      this.updateTable()
    } catch (error) {
      console.warn(error)
    }
  }

  updateTable = async () => {
    try {
      const {data, total} = await GetReceivingServersData()
      this.setState({
        rows: data?.items,
        total
      })
    } catch (error) {
      console.log(error)
    }
  }

  openReceiveServer = (row) => {
    const {router} = this.props
    router.history.push(
      `/mailConfiguration/receiveServer/${row.mailServerId}`
    )
  }

  onChangePagination = (page) => this.setState({page}, this.updateTable)

  render() {
    const {rows, page, pageSize, total} = this.state

    const {router} = this.props
    return (
      <>
        <Row>
          <Col xs={6}>
            <BaseButton
              auto
              imagePath={IconAdd}
              onClick={() =>
                router.history.push('/mailConfiguration/receiveServer/new')
              }
            >
              <Translate id='ADD_RECEIVE_SERVER' />
            </BaseButton>
          </Col>
        </Row>
        <Margin size={12} />
        <Row>
          <Col xs={24}>
            <BaseTable
              datasource={rows}
              columns={this.getColumns()}
              pagination={{
                render: false,
                page,
                pageSize,
                total,
                onChangePagination: this.onChangePagination
              }}
              onClickRow={this.openReceiveServer}
              rowKey='mailServerId'
            />
          </Col>
        </Row>
      </>
    )
  }
}

ReceiveServerTab.propTypes = {
  router: PropTypes.object.isRequired
}

export default ReceiveServerTab
