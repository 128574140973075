import client from '../config/AxiosConfig'

export const GetSuppliers = async (page, pageSize, search) =>
  client.get(
    `/Suppliers/GetSuppliers?ItemsPerPage=${pageSize}&Page=${page}&Search=${search}`
  )

export const GetSupplierDetail = async (id) =>
  client.get(`/Suppliers/GetSupplier/${id}`)

export const EditSupplier = async (id, data) =>
  client.put(`/Suppliers/UpdateSupplier/${id}`, data)

export const DeleteSupplier = async (id) =>
  client.delete(`/Suppliers/DeleteSupplier/${id}`)

export const CreateSupplier = async (data) =>
  client.post('/Suppliers/CreateSupplier', data)

export const GetSuppliersList = async () =>
  client.get('/Suppliers/getSuppliersList')
