import React from 'react'

import { BackwardOutlined, ForwardOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import { Translate, withLocalize } from 'react-localize-redux'
import { connect, useDispatch } from 'react-redux'
import { Field, arrayRemove } from 'redux-form'

import { HeaderButtonContainer } from './ProcessingPO.styles'
import { RevertSupplierSetToConsolidation } from '../../../infra/requests/SupplierOrdersRequests'
import AlertService from '../../../shared/components/alert/AlertService'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import SelectInput from '../../../shared/components/inputs/SelectInput'
import SmallTextInput from '../../../shared/components/inputs/SmallTextInput'
import SwitchInput from '../../../shared/components/inputs/SwitchInput'
import { Flex } from '../../../shared/styles/BasicStyles'
import CollapsableHeaderTitle from '../components/CollapsableHeaderTitle'
import { SupplierToggle } from '../components/POStyles'

const CollapsableHeader = ({
  SO,
  index,
  onSave,
  paymentConditions,
  resetReview,
  evolveSO,
  saving,
  translate
}) => {
  const dispatch = useDispatch()

  const revertSO = (so) => {
    AlertService.confirm(
      translate('WARNING'),
      translate('REVERT_ORDER_STATUS'),
      async () => {
        const { success } = await RevertSupplierSetToConsolidation(so.orderSupplierSetId)
        if (success) {
          dispatch(arrayRemove('manage_confirmation', 'orders', index))
        }
      }
    )
  }

  return (

    <>
      <CollapsableHeaderTitle
        max={350}
        name='Supplier'
        description={SO?.supplier?.name}
      />
      <CollapsableHeaderTitle
        stopPropagation
        name='Payment Condition'
        description={
          <Field
            component={SelectInput}
            small
            allowClear={false}
            name={`orders[${index}].paymentCondition.paymentConditionId`}
            placeholder={<Translate id='SELECT_PAYMENT_CONDITION' />}
            data={paymentConditions}
            dataLabel='name'
            dataKey='paymentConditionId'
            afterChange={() => resetReview(index)}
          />
        }
      />
      <CollapsableHeaderTitle
        stopPropagation
        name='Supplier Email'
        description={
          <Field
            component={SmallTextInput}
            name={`orders[${index}].email`}
            placeholder='Email'
            afterChange={() => resetReview(index)}
          />
        }
      />

      <HeaderButtonContainer>
        <BaseButton
          style={{ marginRight: '20px' }}
          auto
          type='secondary'
          loading={SO.loading}
          onClick={(e) => {
            e.stopPropagation()
            revertSO(SO)
          }}
        >
          <Flex>
            <div>

              <BackwardOutlined />
            </div>
            <Translate id='REVERT' />
          </Flex>
        </BaseButton>
        <BaseButton
          style={{ marginRight: '20px' }}
          auto
          type='secondary'
          loading={SO.loading || saving}
          onClick={(e) => {
            e.stopPropagation()
            evolveSO(SO)
          }}
        >
          <Flex>
            <Translate id='EVOLVE' />
            <div>
              <ForwardOutlined style={{ marginRight: 0, marginLeft: 10 }} />
            </div>
          </Flex>
        </BaseButton>
        <SupplierToggle onClick={(e) => e.stopPropagation()}>
          <SwitchInput
            input={{
              value: SO.confirmationReviewed,
              onChange: onSave
            }}
            checkedText={<Translate id='REVIEWED' />}
            uncheckedText={<Translate id='UNREVIEWED' />}
          />
        </SupplierToggle>
      </HeaderButtonContainer>
    </>
  )
}

CollapsableHeader.propTypes = {
  SO: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  paymentConditions: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  resetReview: PropTypes.func.isRequired,
  evolveSO: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired
}

CollapsableHeader.defaultProps = {}

const mapStateToProps = (state) => ({
  paymentConditions: state.info.paymentConditions
})

export default withLocalize(connect(mapStateToProps)(CollapsableHeader))
