import React from 'react'

import {
  HighlightContainer,
  HighlightTitle,
  HighlightText
} from './HighlightStyles'

const Highlight = ({ title, text }) => (
  <HighlightContainer>
    <HighlightTitle>{title}</HighlightTitle>
    <HighlightText>{text}</HighlightText>
  </HighlightContainer>
)

export default Highlight
