import React from 'react'

import { Tooltip } from 'antd'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { Field } from 'redux-form'

import AverageLead, { CalculateAverage } from './AverageLead'
import CheckboxSizeInput from './CheckboxSizeInput'
import {
  CompositionCollapse,
  CompositionPanel,
  CollapseIcon
} from './CompositionStyles'
import SelectMultipleColors from './SelectMultipleColors'
import SelectWidths from './SelectWidths'
import ServiceSupplierSelect from './ServiceSupplierSelect'
import EmptyIcon from '../../../../../assets/icons/empty_table.svg'
import NumberIncrementInput from '../../../../../shared/components/inputs/NumberIncrementInput'
import TableTextInput from '../../../../../shared/components/inputs/TableTextInput'
import {
  TableRow,
  TableTitle,
  TableInput,
  TableText,
  ListRow,
  ListCol,
  ListCheck,
  TextTooltip,
  EmptyTable,
  EmptyTableMessage,
  EmptyTableIcon
} from '../../../../../shared/components/table/CustomTableStyles'
import ActiveTranslation from '../../../../../shared/logic/translations/ActiveTranslation'
import { ProductColor } from '../../../../orders/manage_b2b_b2b2c/components/Styles'

const CompareIndexes = (line, value) => {
  if (typeof line === 'string') {
    return line !== value && line.indexOf(`${value}_`) === -1
  }
  return line !== value
}

const ListHeader = (
  <ListRow>
    <ListCol xs={1} />
    <ListCol xs={3}>
      <TableText $align='left'>
        <Translate id='REFERENCE' />
      </TableText>
    </ListCol>
    <ListCol xs={3}>
      <TableText $align='left'>
        <Translate id='REF_SUPPLIER' />
      </TableText>
    </ListCol>
    <ListCol xs={4}>
      <TableText $align='left'>
        <Translate id='DESCRIPTION' />
      </TableText>
    </ListCol>
    <ListCol xs={3}>
      <TableText $align='left'>
        <Translate id='WIDTH' />
      </TableText>
    </ListCol>
    <ListCol xs={10}>
      <TableText $align='left'>
        <Translate id='SIZE' />
      </TableText>
    </ListCol>
  </ListRow>
)

const CompositionTable = ({
  form,
  materials,
  sizes,
  widths,
  colors,
  selected,
  setSelected,
  serviceSuppliers
}) => {
  const handleCheckAll = (e) => {
    if (e.target.checked) {
      const all = []
      materials.forEach((material) => {
        if (material.type === 'family') {
          material.list.forEach((item) =>
            all.push(`${material.lineId}_${item.lineId}`)
          )
        } else all.push(material.lineId)
      })
      setSelected(all)
    } else {
      setSelected([])
    }
  }

  const handleBaseCheck = (e, material, tag) => {
    const copy = [...selected]
    if (e.target.checked) {
      if (material.type === 'family') {
        material.list.forEach((item) =>
          copy.push(`${material.lineId}_${item.lineId}`)
        )
      } else copy.push(tag)
      setSelected(copy)
    } else {
      setSelected(copy.filter((line) => CompareIndexes(line, tag)))
    }
  }

  const checkAll = () => {
    let total = 0
    materials.forEach((material) => {
      if (material.type === 'family') {
        material.list.forEach(() => {
          total += 1
        })
      } else total += 1
    })
    return selected.length > 0 && selected.length < total
  }

  const checkLine = (material) => {
    if (material.type === 'family') {
      let total = 0
      let count = 0
      material.list.forEach((item) => {
        total += 1
        if (selected.includes(`${material.lineId}_${item.lineId}`)) {
          count += 1
        }
      })
      return {
        checked: count === total,
        indeterminate: count > 0 && count < total
      }
    }
    return {
      checked: selected.includes(material.lineId),
      indeterminate: false
    }
  }

  return (
    <>
      <TableRow $header>
        <ListCol xs={1}>
          <ListCheck
            disabled={materials.length === 0}
            checked={selected.length > 1}
            indeterminate={checkAll()}
            onChange={handleCheckAll}
          />
        </ListCol>
        <ListCol xs={1}>
          <TableTitle text={<Translate id='PRIORITY' />} />
        </ListCol>
        <ListCol xs={3}>
          <TableTitle text={<Translate id='REFERENCE' />} />
        </ListCol>
        <ListCol xs={3}>
          <TableTitle text={<Translate id='DESCRIPTION' />} />
        </ListCol>
        <ListCol xs={1}>
          <TableTitle text={<Translate id='LEAD_TIME' />} />
        </ListCol>
        <ListCol xs={2}>
          <TableTitle text={<Translate id='QTY' />} />
        </ListCol>
        <ListCol xs={1}>
          <TableTitle text={<Translate id='UM' />} />
        </ListCol>
        <ListCol xs={2}>
          <TableTitle text={<Translate id='COST' />} />
        </ListCol>
        <ListCol xs={1}>
          <TableTitle text={<Translate id='MOQ' />} />
        </ListCol>
        <ListCol xs={1}>
          <TableTitle text={<Translate id='MAQ' />} />
        </ListCol>
        <ListCol xs={2}>
          <TableTitle text={<Translate id='CONSUMPTION' />} />
        </ListCol>
        <ListCol xs={2}>
          <TableTitle text={<Translate id='SUPPLIER' />} />
        </ListCol>
        <ListCol xs={2}>
          <TableTitle text={<Translate id='REF_SUPPLIER' />} />
        </ListCol>
        <ListCol xs={2}>
          <TableTitle text={<Translate id='COLORS' />} />
        </ListCol>
      </TableRow>
      {materials.length === 0 && (
        <EmptyTable>
          <EmptyTableMessage>
            <Translate id='TABLE_IS_EMPTY' />
          </EmptyTableMessage>
          <EmptyTableIcon src={EmptyIcon} />
        </EmptyTable>
      )}
      {materials.map((material, fIndex) => (
        <TableRow key={material.lineId}>
          <ListCol xs={1}>
            <ListCheck
              {...checkLine(material)}
              onChange={(e) =>
                handleBaseCheck(e, material, material.lineId)
              }
            />
          </ListCol>
          <ListCol xs={1}>
            <TableInput>
              <Field
                component={NumberIncrementInput}
                name={`${form}[${fIndex}].priority`}
              />
            </TableInput>
          </ListCol>
          <ListCol xs={3}>
            <TableText>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex' }}>
                  {material.type === 'service'
                    ? material?.s?.service?.reference
                    : material.referenceCode}
                </div>
                <div style={{ display: 'flex' }}>
                  <Tooltip title={<ActiveTranslation value={material?.rawMaterialColor?.colorTranslation} tag='name' />}>
                    <ProductColor
                      $bigger
                      $file={
                        material?.rawMaterialColor?.file
                          ? URL.createObjectURL(material?.rawMaterialColor?.file)
                          : undefined
                      }
                      $color={material?.rawMaterialColor?.html}
                      $border={material?.rawMaterialColor?.borderColor}
                    />
                  </Tooltip>
                </div>
              </div>
            </TableText>
          </ListCol>
          <ListCol xs={3}>
            <TextTooltip
              text={
                <ActiveTranslation
                  value={
                    material.type === 'service'
                      ? material?.s?.service?.serviceTranslation
                      : material?.productTranslation
                  }
                  tag='name'
                />
              }
            />
          </ListCol>
          <ListCol xs={1}>
            {material.type === 'service' ? (
              <TableInput>
                <Field
                  component={NumberIncrementInput}
                  name={`${form}[${fIndex}].leadTime`}
                  step='0.00001'
                />
              </TableInput>
            ) : material.type === 'family' ? (
              <AverageLead family={material} value='leadTime' />
            ) : (
              <TableText>{material.leadTime || '-'}</TableText>
            )}
          </ListCol>
          <ListCol xs={2}>
            <TableInput>
              <Field
                component={NumberIncrementInput}
                name={`${form}[${fIndex}].${material.type === 'service' ? 'units' : 'quantity'
                  }`}
                step='0.00001'
                min='0.00001'
              />
            </TableInput>
          </ListCol>
          <ListCol xs={1}>
            <TextTooltip
              text={
                material.type === 'service'
                  ? 'unit'
                  : material?.measureUnit?.code || '-'
              }
            />
          </ListCol>
          <ListCol xs={2}>
            {material.type === 'service' ? (
              <TableInput>
                <Field
                  component={NumberIncrementInput}
                  name={`${form}[${fIndex}].cost`}
                  step='0.00001'
                  min='0.00001'
                />{' '}
                €
              </TableInput>
            ) : material.type === 'family' ? (
              <AverageLead family={material} value='tableValue' tag='€' />
            ) : (
              <TableText>{material.tableValue || 0} €</TableText>
            )}
          </ListCol>
          <ListCol xs={1}>
            {material.type === 'service' ? (
              <TableInput>
                <Field
                  component={NumberIncrementInput}
                  name={`${form}[${fIndex}].moq`}
                  step='0.00001'
                />
              </TableInput>
            ) : material.type === 'family' ? (
              <AverageLead family={material} value='moq' />
            ) : (
              <TableText>{material.moq || '-'}</TableText>
            )}
          </ListCol>
          <ListCol xs={1}>
            {material.type === 'service' ? (
              <TableInput>
                <Field
                  component={NumberIncrementInput}
                  name={`${form}[${fIndex}].maq`}
                  step='0.00001'
                />
              </TableInput>
            ) : material.type === 'family' ? (
              <AverageLead family={material} value='maq' />
            ) : (
              <TableText>{material.maq || '-'}</TableText>
            )}
          </ListCol>
          <ListCol xs={2}>
            {material.type === 'service' ? (
              <TableText>{((material.cost || 0) * (material.units || 0)).toFixed(5)}</TableText>
            ) : material.type === 'family' ? (
              <TableText>{(CalculateAverage(material, 'tableValue') * material.quantity).toFixed(5)}</TableText>
            ) : (
              <TableText>{(material.tableValue * material.quantity || 0).toFixed(5)}</TableText>
            )}
          </ListCol>
          <ListCol xs={2}>
            {material.type === 'service' ? (
              <TableInput $left>
                <Field
                  component={ServiceSupplierSelect}
                  name={`${form}[${fIndex}].supplierId`}
                  data={serviceSuppliers}
                  service={material.serviceId}
                />
              </TableInput>
            ) : (
              <TextTooltip text={material.defaultSupplier || '-'} />
            )}
          </ListCol>
          <ListCol xs={2}>
            {material.type === 'service' ? (
              <TableInput>
                <Field
                  component={TableTextInput}
                  name={`${form}[${fIndex}].referenceSupplier`}
                />
              </TableInput>
            ) : (
              <TextTooltip text={material.referenceSupplier || '-'} />
            )}
          </ListCol>
          <ListCol xs={2}>
            {material.type === 'service' ? (
              <TableText>-</TableText>
            ) : (
              <TableInput>
                <Field
                  component={SelectMultipleColors}
                  name={`${form}[${fIndex}].colors`}
                  colors={colors}
                />
              </TableInput>
            )}
          </ListCol>

          {material.type === 'family' && material.list.length && (
            <ListCol xs={24}>
              <CompositionCollapse
                destroyInactivePanel
                expandIcon={({ isActive }) => (
                  <CollapseIcon rotate={isActive ? 90 : 0} />
                )}
              >
                <CompositionPanel
                  header={`List (${material.list.length})`}
                  key={material.productId}
                >
                  {ListHeader}
                  {material.list.map((item, lIndex) => (
                    <ListRow key={item.lineId}>
                      <ListCol xs={1}>
                        <ListCheck
                          checked={selected.includes(
                            `${material.lineId}_${item.lineId}`
                          )}
                          onChange={(e) =>
                            handleBaseCheck(
                              e,
                              item,
                              `${material.lineId}_${item.lineId}`
                            )
                          }
                        />
                      </ListCol>
                      <ListCol xs={3}>
                        <TextTooltip
                          first
                          text={item.referenceCode || 'N/A'}
                        />
                      </ListCol>
                      <ListCol xs={3}>
                        <TextTooltip
                          first
                          text={item.referenceSupplier || 'N/A'}
                        />
                      </ListCol>

                      <ListCol xs={4}>
                        <TextTooltip
                          first
                          text={
                            <ActiveTranslation
                              value={item?.productTranslation}
                              tag='name'
                            />
                          }
                        />
                      </ListCol>
                      <ListCol xs={3}>
                        <Field
                          component={SelectWidths}
                          name={`${form}[${fIndex}].list[${lIndex}].widths`}
                          widths={widths}
                        />
                      </ListCol>
                      <ListCol xs={10}>
                        <Field
                          component={CheckboxSizeInput}
                          name={`${form}[${fIndex}].list[${lIndex}].sizes`}
                          sizes={sizes}
                        />
                      </ListCol>
                    </ListRow>
                  ))}
                </CompositionPanel>
              </CompositionCollapse>
            </ListCol>
          )}
        </TableRow>
      ))}
    </>
  )
}

CompositionTable.propTypes = {
  form: PropTypes.string.isRequired,
  materials: PropTypes.array.isRequired,
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired,
  sizes: PropTypes.array.isRequired,
  widths: PropTypes.array.isRequired,
  colors: PropTypes.array.isRequired,
  serviceSuppliers: PropTypes.array.isRequired
}

export default CompositionTable
