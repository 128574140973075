import React from 'react'

import { Row, Col } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Translate, withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import { Field, FieldArray } from 'redux-form'

import {
  GetShippingCondition,
  GetPaymentCondition
} from '../../../../infra/constants/GetConstantValue'
import PropertyDescription from '../../../../shared/components/description/PropertyDescription'
import DateInput from '../../../../shared/components/inputs/DateInput'
import NewDocumentsInput from '../../../../shared/components/inputs/Documents/NewDocumentsInput'
import SelectInput from '../../../../shared/components/inputs/SelectInput'
import TableOrderStatus from '../../../../shared/components/orderStatus/TableOrderStatus'
import { OverdueAlert } from '../../../../shared/styles/BasicStyles'
import DeliveryLocationSelect from './DeliveryLocationSelect'

const ConstructAddress = (contact) =>
  contact
    ? `${contact?.addressLine1 || ''} ${contact?.addressLine2 || ''} ${contact?.addressLine3 || ''
    } ${contact?.city || ''}`
    : 'N/A'

const EditableOrderHeader = ({
  order,
  editing,
  clients,
  shippingConditions,
  paymentConditions,
  deleteDocument,
  translate
}) => (
  <>
    <Row gutter={[40, 20]}>
      <Col xs={12} md={6}>
        {editing ? (
          <Field
            component={SelectInput}
            name='b2bclientId'
            label={<Translate id='CLIENT' />}
            placeholder={<Translate id='DEFINE_CLIENT' />}
            data={clients}
            dataLabel='name'
            dataKey='b2bclientId'
            disabled
          />
        ) : (
          <PropertyDescription
            label={<Translate id='CLIENT' />}
            value={order?.b2bclient?.name}
          />
        )}
      </Col>
      <Col xs={12} md={6}>
        {editing ? (
          <Field
            component={SelectInput}
            name='stockOwnerId'
            label={<Translate id='STOCK_OWNER' />}
            placeholder={<Translate id='STOCK_OWNER' />}
            data={clients}
            dataLabel='name'
            dataKey='b2bclientId'
          />
        ) : (
          <PropertyDescription
            label={<Translate id='STOCK_OWNER' />}
            value={order?.stockOwner?.name}
          />
        )}
      </Col>
      <Col xs={12} md={6}>
        <PropertyDescription
          label={<Translate id='STATUS' />}
          value={<TableOrderStatus id={order?.orderStatusId} />}
        />
      </Col>
      <Col xs={12} md={6}>
        <PropertyDescription
          label={<Translate id='ORDER_DATE' />}
          value={
            order?.dateOrder ? (
              moment(order.dateOrder).format('DD/MM/YYYY')
            ) : (
              <Translate id='NA' />
            )
          }
        />
      </Col>
      <Col xs={12} md={6}>
        {editing ? (
          <Field
            component={DateInput}
            name='dateDelivery'
            label={<Translate id='DATE_DELIVERY' />}
            placeholder={translate('DATE_DELIVERY')}
          />
        ) : (
          <PropertyDescription
            label={<Translate id='DELIVERY_DATE' />}
            value={
              order.dateDelivery
                ? moment(order.dateDelivery).format('DD/MM/YYYY')
                : ''
            }
          />
        )}
      </Col>
      <Col xs={12} md={6}>
        <PropertyDescription
          label={<Translate id='DAYS_OVERDUE' />}
          value={
            order?.daysOverdue > 0 ? (
              <OverdueAlert $width='22px'>
                {parseInt(order?.daysOverdue, 10)}
              </OverdueAlert>
            ) : (
              0
            )
          }
        />
      </Col>

      <Col xs={12} md={6}>
        <PropertyDescription
          label={<Translate id='CURRENCY' />}
          value={order?.currency?.code}
        />
      </Col>
      <Col xs={12} md={6}>
        {editing ? (
          <Field
            component={SelectInput}
            name='shippingConditionId'
            label={<Translate id='SHIPPING_CONDITION' />}
            dataLabel='value'
            dataKey='key'
            placeholder={<Translate id='SHIPPING_CONDITION' />}
            data={shippingConditions}
          />
        ) : (
          <PropertyDescription
            label={<Translate id='SHIPPING_CONDITION' />}
            value={
              GetShippingCondition(
                order.shippingConditionId,
                shippingConditions
              )?.value
            }
          />
        )}
      </Col>
      <Col xs={12} md={6}>
        {editing ? (
          <Field
            component={SelectInput}
            name='paymentConditionId'
            label={<Translate id='PAYMENT_CONDITION' />}
            placeholder={<Translate id='PAYMENT_CONDITION' />}
            data={paymentConditions}
            dataLabel='name'
            dataKey='paymentConditionId'
          />
        ) : (
          <PropertyDescription
            label={<Translate id='PAYMENT_CONDITION' />}
            value={
              GetPaymentCondition(
                order.paymentConditionId,
                paymentConditions
              )?.name
            }
          />
        )}
      </Col>

      <Col xs={12} md={6}>
        {editing ? (
          <Field
            component={DeliveryLocationSelect}
            name='deliveryContactDetailId'
            label={<Translate id='DELIVERY_LOCATION' />}
            placeholder={<Translate id='DELIVERY_LOCATION' />}
            client={order?.b2bclientId}
          />
        ) : (
          <PropertyDescription
            label={<Translate id='DELIVERY_LOCATION' />}
            value={ConstructAddress(order?.deliveryContactDetail)}
          />
        )}
      </Col>
      <Col xs={12} md={6}>
        <FieldArray
          component={NewDocumentsInput}
          name='attachment'
          disabled={!editing}
          deleteDocument={deleteDocument}
        />
      </Col>
    </Row>
  </>
)

EditableOrderHeader.propTypes = {
  order: PropTypes.object.isRequired,
  editing: PropTypes.bool.isRequired,
  paymentConditions: PropTypes.array.isRequired,
  shippingConditions: PropTypes.array.isRequired,
  clients: PropTypes.array.isRequired,
  deleteDocument: PropTypes.func.isRequired
}

export default withLocalize(
  connect((state) => ({
    orderStatus: state.info.orderStatus,
    paymentConditions: state.info.paymentConditions,
    shippingConditions: state.info.shippingConditions,
    clients: state.info.clients
  }))(EditableOrderHeader)
)
