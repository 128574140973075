import React, {useState, useEffect} from 'react'

import {Col, Row} from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import {withLocalize, Translate} from 'react-localize-redux'
import {connect} from 'react-redux'
import {useParams} from 'react-router-dom'
import {reduxForm, initialize, FieldArray} from 'redux-form'

import {
  AddTailors,
  CreateNewFittingCenter,
  DeleteTailors,
  EditFittingCenter,
  GetCenterDetail,
  GetCenterUsers,
  GetUsersAvailable
} from '../../../infra/requests/FittingRequests'
import AlertService from '../../../shared/components/alert/AlertService'
import BackButton from '../../../shared/components/buttons/BackButton'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import BaseLoading from '../../../shared/components/loading/BaseLoading'
import {
  ContentContainer,
  Margin,
  PageForm,
  PageTitle,
  StyledTabPane,
  StyledTabs,
  StyledTabTitle,
  StyledTabTitleContainer
} from '../../../shared/styles/BasicStyles'
import Information from './tabs/Information'
import Users from './tabs/Users'

const validations = (values) => {
  const error = {}
  error.info = {}
  error.info.contact = {}
  if (!values.info?.contact?.name) {
    error.info.contact.name = {_error: <Translate id='NAME_REQUIRED' />}
  }
  if (!values.info?.contact?.email) {
    error.info.contact.email = {_error: <Translate id='EMAIL_REQUIRED' />}
  }

  if (!values.info?.defaultStartSchedule) {
    error.info.defaultStartSchedule = {
      _error: <Translate id='START_DURATION_REQUIRED' />
    }
  }
  if (!values.info?.defaultEndSchedule) {
    error.info.defaultEndSchedule = {
      _error: <Translate id='END_SCHEDULE_REQUIRED' />
    }
  }
  if (!values.info?.defaultSlotDuration) {
    error.info.defaultSlotDuration = {
      _error: <Translate id='SLOT_DURATION_REQUIRED' />
    }
  }
  const formats = ['HH:mm:ss']
  if (
    !moment(values.info?.defaultStartSchedule, formats, true).isValid()
  ) {
    error.info.defaultStartSchedule = {
      _error: <Translate id='START_DURATION_INVALID' />
    }
  }
  if (!moment(values.info?.defaultEndSchedule, formats, true).isValid()) {
    error.info.defaultEndSchedule = {
      _error: <Translate id='END_SCHEDULE_INVALID' />
    }
  }

  if (!moment(values.info?.defaultSlotDuration, formats, true).isValid()) {
    error.info.defaultSlotDuration = {
      _error: <Translate id='SLOT_DURATION_INVALID' />
    }
  }
  return error
}

const ManageFittingCentersPage = ({
  router,
  dispatch,
  handleSubmit,
  translate
}) => {
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [availableUsers, setAvailableUsers] = useState([])
  const [name, setName] = useState(<Translate id='NEW_FITTING_CENTER' />)
  const {id} = useParams()

  useEffect(() => {
    async function getFittingCenter() {
      if (id && id !== 'new') {
        const {data, success} = await GetCenterDetail(id)
        const resultUsers = await GetCenterUsers(id)
        if (success) {
          setName(data?.contact?.name)
          dispatch(
            initialize('manage_fitting_centers', {
              info: data,
              users: resultUsers?.data?.items || []
            })
          )
        }
      }
      const usersAvailable = await GetUsersAvailable()
      setAvailableUsers(usersAvailable?.data?.items || [])
      setLoading(false)
    }
    getFittingCenter()
  }, [])

  const onSubmit = async (values) => {
    try {
      setSaving(true)
      if (id && id !== 'new') {
        const {success} = await EditFittingCenter(id, values?.info)
        if (success) {
          AlertService.success(
            translate('SUCCESS'),
            translate('FITTING_CENTER_EDITED_SUCCESSFULLY')
          )
          return router.history.push('/fitting#centers')
        }
      } else {
        const {data, success} = await CreateNewFittingCenter(values?.info)
        if (success) {
          const idResult = data?.fittingOperatorId
          const users = (values?.users || []).map((u) => u.userId)
          await AddTailors(idResult, users)
          AlertService.success(
            translate('SUCCESS'),
            translate('FITTING_CENTER_CREATED_SUCCESSFULLY')
          )
          return router.history.push('/fitting#centers')
        }
      }
      setSaving(false)
    } catch (error) {
      console.log(error)
    }
  }

  if (loading) return <BaseLoading size='200' />

  return (
    <ContentContainer>
      <PageForm $margin='0' onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs={24}>
            <BackButton
              label={<Translate id='BACK' />}
              onClick={() => router.history.push('/fitting#centers')}
            />
          </Col>
        </Row>
        <Margin size={29} />
        <Row xs={24}>
          <Col xs={12}>
            <PageTitle>{name}</PageTitle>
          </Col>
          <Col xs={12} align='end'>
            <BaseButton auto htmlType='submit' loading={saving}>
              <Translate id='SAVE' />
            </BaseButton>
          </Col>
        </Row>
        <Margin size={37} />
        <StyledTabs>
          <StyledTabPane
            tab={
              <StyledTabTitleContainer>
                <StyledTabTitle smaller>
                  <Translate id='INFORMATION' />
                </StyledTabTitle>
              </StyledTabTitleContainer>
            }
            key={1}
          >
            <Information />
          </StyledTabPane>
          <StyledTabPane
            tab={
              <StyledTabTitleContainer>
                <StyledTabTitle smaller>
                  <Translate id='USERS' />
                </StyledTabTitle>
              </StyledTabTitleContainer>
            }
            key={3}
          >
            <FieldArray
              name='users'
              component={Users}
              availableUsers={availableUsers}
              id={id}
              translate={translate}
            />
          </StyledTabPane>
        </StyledTabs>
      </PageForm>
    </ContentContainer>
  )
}

ManageFittingCentersPage.propTypes = {
  router: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func
}

ManageFittingCentersPage.defaultProps = {
  handleSubmit: undefined
}

const myComponent = reduxForm({
  form: 'manage_fitting_centers',
  validate: validations
})(ManageFittingCentersPage)

export default withLocalize(connect()(myComponent))
