import React, {useState, useEffect} from 'react'

import {Translate} from 'react-localize-redux'
import styled from 'styled-components'

import {getAnswerTemplates} from '../../../../infra/requests/MessagingRequests'
import SelectInput from '../../../../shared/components/inputs/SelectInput'
import {GetTranslationFromArray} from '../../../../shared/logic/translations/Translations'

const TemplateInputSection = styled.div`
display: inline-block;
width: 200px;
margin-right: 20px;
`

const AnswerTemplates = ({onUseTemplate}) => {
  const [templates, setTemplates] = useState([])
  const [used, setUsed] = useState()

  useEffect(() => {
    async function init() {
      const {data, success} = await getAnswerTemplates()
      if (success) setTemplates(data?.items || [])
    }
    init()
  }, [])

  const handleTemplate = (value) => {
    setUsed(value)
    const template = templates.find((t) => t.messageTemplateId == value)
    if (template) {
      const lang = GetTranslationFromArray(template?.messageTemplateTranslation, 2)
      if (lang) onUseTemplate(lang.text)
    }
  }

  return (
    <TemplateInputSection>
      <SelectInput
        small
        allowClear={false}
        input={{value: used, onChange: handleTemplate}}
        data={templates}
        placeholder={<Translate id='ADD_REPLY_TEMPLATE' />}
        dataLabel='messageTemplateTranslation'
        dataKey='messageTemplateId'
      />
    </TemplateInputSection>

  )
}

export default AnswerTemplates
