import React, { useEffect, useState } from 'react'

import { Row, Col } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Translate, withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import { useParams } from 'react-router'
import { reduxForm, Field, initialize } from 'redux-form'

import Trash from '../../../../../assets/icons/remove_white.svg'
import {
  CreateVoucher,
  DeleteVoucher,
  EditVoucher,
  GetVoucherInformation,
  GetVoucherLog
} from '../../../../../infra/requests/MarketingRequests'
import { GetRelatedProducts } from '../../../../../infra/requests/ProductsRequests'
import FormValidator from '../../../../../infra/services/validations/FormValidator'
import AlertService from '../../../../../shared/components/alert/AlertService'
import BackButton from '../../../../../shared/components/buttons/BackButton'
import BaseButton from '../../../../../shared/components/buttons/BaseButton'
import DeleteButton from '../../../../../shared/components/buttons/DeleteButton'
import DateRangeInput from '../../../../../shared/components/inputs/DateRangeInput'
import FloatInput from '../../../../../shared/components/inputs/FloatInput'
import { InputLabel } from '../../../../../shared/components/inputs/InputStyles'
import IntegerInput from '../../../../../shared/components/inputs/IntegerInput'
import SelectInput from '../../../../../shared/components/inputs/SelectInput'
import SwitchInput from '../../../../../shared/components/inputs/SwitchInput'
import TextInput from '../../../../../shared/components/inputs/TextInput'
import BaseLoading from '../../../../../shared/components/loading/BaseLoading'
import {
  Margin,
  PageForm,
  PageTitle,
  StyledTabPane,
  StyledTabs,
  StyledTabTitle,
  StyledTabTitleContainer
} from '../../../../../shared/styles/BasicStyles'
import {
  HeaderLogs,
  HeaderLogsText,
  BodyLogs,
  LogPoint,
  VoucherTypeSection
} from '../../MarketingStyles'

const validations = FormValidator.make({
  name: 'required',
  code: 'required'
})

const ValidateVoucherType = (values) => {
  let selected = false
  if (values.percentage && values.percentage !== '') {
    selected = true
    if (values.totalValue && values.totalValue !== '') return 'CHOOSE_ONE_TYPE_DISCOUNT'
    if ((values.buyX && values.buyX !== '') || (values.getY && values.getY !== '')) return 'CHOOSE_ONE_TYPE_DISCOUNT'
  }

  if (values.totalValue && values.totalValue !== '') {
    selected = true
    if (values.percentage && values.percentage !== '') return 'CHOOSE_ONE_TYPE_DISCOUNT'
    if ((values.buyX && values.buyX !== '') || (values.getY && values.getY !== '')) return 'CHOOSE_ONE_TYPE_DISCOUNT'
  }

  if ((values.buyX && values.buyX !== '') && (values.getY && values.getY !== '')) {
    selected = true
    if (values.percentage && values.percentage !== '') return 'CHOOSE_ONE_TYPE_DISCOUNT'
    if (values.totalValue && values.totalValue !== '') return 'CHOOSE_ONE_TYPE_DISCOUNT'
  }

  return selected ? false : 'CHOOSE_ONE_TYPE_DISCOUNT'
}

const ManageVoucher = ({ router, dispatch, handleSubmit, translate }) => {
  const [ready, setReady] = useState(false)
  const [loading, setLoading] = useState(false)
  const [logs, setLogs] = useState([])
  const [products, setProducts] = useState([])
  const { id } = useParams()

  useEffect(() => {
    async function fetchVoucher() {

      if (id !== 'new') {
        const { data, success } = await GetVoucherInformation(id)
        const resultLog = await GetVoucherLog(id)
        if (success) {
          dispatch(
            initialize('voucher_form', {
              ...data,
              range:
                data.dateFrom && data.dateTo
                  ? [moment(data.dateFrom), moment(data.dateTo)]
                  : []
            })
          )
          setLogs(resultLog?.data || [])
        }
      }

      const resultP = await GetRelatedProducts(1)
      setProducts(resultP?.data?.items)

      setReady(true)
    }
    fetchVoucher()
  }, [])

  const onSubmit = async (values) => {
    try {
      const error = ValidateVoucherType(values)
      if (error) return AlertService.error(translate('ERROR'), translate(error))

      setLoading(true)
      const object = {
        ...values,
        isDiscountVoucher: true,
        dateFrom: values?.range && values?.range[0],
        dateTo: values?.range && values?.range[1]
      }

      if (id !== 'new') {
        const { success } = await EditVoucher(id, object)

        if (success) {
          AlertService.success(
            translate('SUCCESS'),
            translate('VOUCHER_EDITED_SUCCESSFULLY')
          )
          router.history.push('/marketing#vouchers')
        }
      } else {
        const { success } = await CreateVoucher(object)

        if (success) {
          AlertService.success(
            translate('SUCCESS'),
            translate('VOUCHER_CREATED_SUCCESSFULLY')
          )
          router.history.push('/marketing#vouchers')
        }
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const deleteVoucher = async () => {
    try {
      const { success } = await DeleteVoucher(id)
      if (success) {
        AlertService.success(
          translate('SUCCESS'),
          translate('VOUCHER_DELETED_SUCCESSFULLY')
        )
        router.history.push('/marketing#vouchers')
      }
    } catch (error) {
      console.log(error)
    }
  }

  if (!ready) return <BaseLoading margin='200' />

  const renderInfo = () => (
    <>
      <Row gutter={[40, 20]}>
        <Col xs={6}>
          <Field
            component={TextInput}
            name='name'
            label={<Translate id='NAME' />}
          />
        </Col>
        <Col xs={6}>
          <Field
            component={TextInput}
            name='code'
            label={<Translate id='CODE' />}
          />
        </Col>
        <Col xs={6}>
          <Field
            component={TextInput}
            name='email'
            label={<Translate id='CLIENT_EMAIL' />}
          />
        </Col>
        <Col xs={6}>
          <Field
            component={SwitchInput}
            name='active'
            checkedText={<Translate id='YES' />}
            uncheckedText={<Translate id='NO' />}
            label={<Translate id='ACTIVE' />}
          />
        </Col>
        <Col xs={6}>
          <Field
            component={SelectInput}
            name='productId'
            label={<Translate id='PRODUCT' />}
            data={products}
            dataLabel='productTranslation'
            dataKey='productId'
          />
        </Col>
        <Col xs={6}>
          <Field
            component={TextInput}
            name='usageCounter'
            label={<Translate id='USAGE' />}
            disabled
          />
        </Col>
        <Col xs={6}>
          <Field
            component={TextInput}
            name='usageLimit'
            label={<Translate id='USAGE_LIMIT' />}
          />
        </Col>
        <Col xs={6}>
          <Field
            component={DateRangeInput}
            name='range'
            label={<Translate id='DATE' />}
          />
        </Col>
      </Row>
      <Margin size={41} />
      <Row gutter={[40, 20]}>
        <Col xs={12}>
          <VoucherTypeSection>
            <InputLabel>
              <Translate id='BUYX_GETY' />
            </InputLabel>
            <Row gutter={[40, 20]}>
              <Col xs={12}>
                <Field
                  component={IntegerInput}
                  name='buyX'
                  inputDescriptionText='X'
                />
              </Col>
              <Col xs={12}>
                <Field
                  component={IntegerInput}
                  name='getY'
                  inputDescriptionText='Y'
                />
              </Col>
            </Row>
          </VoucherTypeSection>
        </Col>
        <Col xs={12}>
          <Row>
            <Col xs={11}>
              <VoucherTypeSection>
                <Field
                  component={FloatInput}
                  name='percentage'
                  label={<Translate id='PERCENTAGE' />}
                />
              </VoucherTypeSection>
            </Col>
            <Col xs={11} offset={2}>
              <VoucherTypeSection>
                <Field
                  component={FloatInput}
                  name='totalValue'
                  label={<Translate id='VALUE' />}
                />
              </VoucherTypeSection>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )

  const renderLog = () => (
    <>
      <HeaderLogs>
        <HeaderLogsText border width='5%' />
        <HeaderLogsText border width='40%'>
          <Translate id='DATE' />
        </HeaderLogsText>
        <HeaderLogsText border width='40%'>
          <Translate id='ORDER' />
        </HeaderLogsText>
        <HeaderLogsText width='15%'>
          <Translate id='VALUE' />
        </HeaderLogsText>
      </HeaderLogs>
      {logs?.length > 0 &&
        logs.map((log) => (
          <BodyLogs>
            <HeaderLogsText border width='5%' center>
              <LogPoint />
            </HeaderLogsText>
            <HeaderLogsText border width='40%'>
              {moment(log.date).format('DD/MM/YYYY HH:mm:ss')}
            </HeaderLogsText>
            <HeaderLogsText border width='40%'>
              {log.order}
            </HeaderLogsText>
            <HeaderLogsText width='15%'>€{log.value}</HeaderLogsText>
          </BodyLogs>
        ))}
    </>
  )

  return (
    <>
      <PageForm onSubmit={handleSubmit(onSubmit)}>
        <Row align='bottom' gutter={[0, 50]}>
          <Col xs={24}>
            <BackButton
              label={<Translate id='BACK' />}
              onClick={() => router.history.push('/marketing#vouchers')}
            />
          </Col>
          <Col xs={12}>
            <PageTitle>
              <Translate id='VOUCHERS' />
            </PageTitle>
          </Col>
          <Col xs={12} align='end'>
            <BaseButton htmlType='submit' type='primary' auto loading={loading}>
              <Translate id='SAVE' />
            </BaseButton>
          </Col>
        </Row>
        <Margin size={38} />
        {id !== 'new' && (
          <>
            <Row>
              <Col xs={6}>
                <DeleteButton
                  imagePath={Trash}
                  type='error'
                  auto
                  onClick={() => deleteVoucher()}
                >
                  <Translate id='DELETE_VOUCHER' />
                </DeleteButton>
              </Col>
            </Row>
            <Margin size={38} />
          </>
        )}
        <Row>
          <Col xs={24}>
            <StyledTabs>
              <StyledTabPane
                tab={
                  <StyledTabTitleContainer>
                    <StyledTabTitle smaller>
                      <Translate id='INFORMATION' />
                    </StyledTabTitle>
                  </StyledTabTitleContainer>
                }
                key='info'
              >
                {renderInfo()}
              </StyledTabPane>
              <StyledTabPane
                tab={
                  <StyledTabTitleContainer>
                    <StyledTabTitle smaller>
                      <Translate id='LOG' />
                    </StyledTabTitle>
                  </StyledTabTitleContainer>
                }
                key='log'
              >
                {renderLog()}
              </StyledTabPane>
            </StyledTabs>
          </Col>
        </Row>
      </PageForm>
    </>
  )
}

ManageVoucher.propTypes = {
  router: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  handleSubmit: PropTypes.func
}

ManageVoucher.defaultProps = {
  dispatch: undefined,
  handleSubmit: undefined
}

const ManageVoucherPage = reduxForm({
  form: 'voucher_form',
  validate: validations
})(ManageVoucher)

export default withLocalize(
  connect((state) => ({
    clients: state.info.clients
  }))(ManageVoucherPage)
)
