import React, {useState} from 'react'

import {Row, Col} from 'antd'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {useParams} from 'react-router'
import {change, FieldArray, getFormValues, initialize} from 'redux-form'

import {GetOrderB2CDetails, GetOrderB2COrderItemsDetails, UpdateOrderPicking} from '../../../../infra/requests/OrdersB2CRequests'
import AlertService from '../../../../shared/components/alert/AlertService'
import BaseButton from '../../../../shared/components/buttons/BaseButton'
import NextButton from '../../../../shared/components/buttons/NextButton'
import EANTextInput from '../../../../shared/components/inputs/EANCodeScanner'
import BaseLoading from '../../../../shared/components/loading/BaseLoading'
import {ParseStringToInt} from '../../../../shared/logic/strings/StringsFunctions'
import {Margin} from '../../../../shared/styles/BasicStyles'
import EditOrderProducts from './EditOrderProducts'

const ProductsData = ({handleNext, currentFormValues, dispatch, translate}) => {
  const {id} = useParams()
  const [loading, setLoading] = useState(false)
  const [isInsertingEANCode, setIsInsertingEANCode] = useState(false)
  const [eanCode, setEanCode] = useState('')
  const eanCodeRef = React.createRef()
  const validateEanCode = (value) => {
    const selectedProductIndex = currentFormValues.orderItem?.findIndex((x) => x.productCombination.barcode == value)
    if (selectedProductIndex >= 0) {
      const newOrderItems = [...currentFormValues.orderItem]
      if ((newOrderItems[selectedProductIndex].sendQty || 0) < newOrderItems[selectedProductIndex].quantity - newOrderItems[selectedProductIndex].quantityConfirmed) {
        newOrderItems[selectedProductIndex].sendQty = (parseInt(newOrderItems[selectedProductIndex]?.sendQty, 10) + 1 || 1).toString()
        dispatch(change('manage-order-b2b2c-b2c', 'orderItem', newOrderItems))
      } else {
        AlertService.error(translate('INVALID_EAN_CODE_AMOUNT'), translate('INVALID_EAN_CODE_AMOUNT_DESCRIPTION'))
      }
    } else {
      AlertService.error(translate('INVALID_EAN_CODE'), translate('INVALID_EAN_CODE_DESCRIPTION'))
    }
    setEanCode('')
    eanCodeRef.current.blur()
  }

  const saveProducts = async () => {
    setLoading(true)
    try {
      let willUpdatePickingBody = false
      const updatePickingBody = currentFormValues.orderItem.map((x) => {
        if (
          ParseStringToInt(x.sendQty) != null &&
        ParseStringToInt(x.sendQty) > 0 &&
        x.quantityConfirmed != x.quantity
        ) {
          willUpdatePickingBody = true
        }

        return {
          productId: x.productCombination.productId,
          productWidthId: x.productCombination.productWidthId,
          colorId: x.productCombination.colorId,
          sizeId: x.productCombination.sizeId,
          quantityConfirmed: x.quantityConfirmed === x.quantity ? x.quantityConfirmed : ParseStringToInt(x.sendQty),
          stockLossTypeId: 1
        }
      })

      if (willUpdatePickingBody) {
        const result = await UpdateOrderPicking(
          currentFormValues.orderId,
          !currentFormValues.sendNotifications,
          updatePickingBody
        )
        if (result.success) {
        // update order and go to the next step
          const {data} = await GetOrderB2CDetails(id)
          const resultItems = await GetOrderB2COrderItemsDetails(id)

          dispatch(
            initialize('manage-order-b2b2c-b2c', {
              ...currentFormValues,
              orderStatus: data.orderStatus,
              orderStatusId: data.orderStatusId,
              orderItem: resultItems?.data?.items
            })
          )
          handleNext(3)
        }
      } else {
        const picked = currentFormValues.orderItem.find((p) => parseInt(p.quantityConfirmed, 10) > 0)
        if (picked) handleNext(3)
        else AlertService.error(translate('ERROR'), translate('SELECT_PICKING_PRODUCTS_MESSAGE'))
      }
    } catch (error) {
      console.warn(error)
    }
    setLoading(false)
  }

  if (loading) return <BaseLoading margin='100' />

  return (
    <>
      <Margin size={30} />
      <EANTextInput
        inputRef={eanCodeRef}
        input={{value: eanCode, onChange: (value) => setEanCode(value)}}
        afterChange={validateEanCode}
        onBlur={() => setIsInsertingEANCode(false)}
        onFocus={() => setIsInsertingEANCode(true)}
      />
      <Row justify='end'>
        <Col xs={10} lg={5}>
          <BaseButton
            loading={isInsertingEANCode}
            onClick={() => eanCodeRef?.current?.focus()}
            type='secondary'
          >
            <Translate id='READ_EAN' />
          </BaseButton>
        </Col>
      </Row>
      <Margin size={30} />
      <Row>
        <Col xs={24}>
          <FieldArray component={EditOrderProducts} name='orderItem' orderId={currentFormValues.orderId} />
        </Col>
      </Row>
      <Margin size={50} />
      <Row>
        <Col xs={24} style={{textAlign: 'right'}}>
          <NextButton
            label={<Translate id='NEXT' />}
            onClick={saveProducts}
          />
        </Col>
      </Row>
    </>
  )
}

export default withLocalize(connect((state) => ({
  currentFormValues: getFormValues('manage-order-b2b2c-b2c')(state)
}))(ProductsData))
