import React from "react";

import { TableOutlined } from "@ant-design/icons";
import moment from "moment";
import PropTypes from "prop-types";
import { Translate } from "react-localize-redux";

import SmallTable from "../../../shared/components/table/SmallTable";
import {
  CockpitTableContainer,
  EmptyDashboardMessage,
} from "../DashboardStyles";

const RenderColumns = (labels) =>
  labels.map((l) => {
    if (l.dataIndex === "eta") {
      return {
        ...l,
        render: (value) => {
          const dateWithoutTime = value.split(' ')[0]; // Extracting only the date part
          return dateWithoutTime;
        },
      };
    }
    return l;
  });

const TableComponent = ({
  info,
  page,
  pageSize,
  handlePageChange,
  loading,
}) => {
  if (
    info.options.empty ||
    !info.options.table ||
    info.options.table.length === 0
  ) {
    return (
      <EmptyDashboardMessage>
        <TableOutlined />
        <div>
          <Translate id="NO_AVAILABLE_INFO" />
        </div>
      </EmptyDashboardMessage>
    );
  }

  return (
    <CockpitTableContainer>
      <SmallTable
        datasource={info.items}
        columns={RenderColumns(info.options.table)}
        rowKey={info.options.table[0].dataIndex}
        loading={loading}
        pagination={{
          render: true,
          page,
          pageSize,
          total: info.totalItems,
          onChangePagination: handlePageChange,
        }}
      />
    </CockpitTableContainer>
  );
};

TableComponent.propTypes = {
  info: PropTypes.object.isRequired,
};

export default TableComponent;
