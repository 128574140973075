import React from 'react'

import {LoadingOutlined} from '@ant-design/icons'
import {Popconfirm, Spin} from 'antd'
import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'
import {ButtonText} from '../../styles/_texts'
import {
  StyledButton,
  StyledButtonContent,
  StyledButtonImg
} from './ButtonsStyles'

const DeleteButton = ({
  children,
  type,
  size,
  onClick,
  disabled,
  loading,
  htmlType,
  align,
  style,
  imagePath,
  auto
}) => {
  const onClickWrapper = (event) => {
    if (loading || disabled) {
      return null
    }
    return onClick(event)
  }

  const showLoadingIcon =
    loading &&
    (!Array.isArray(children) ||
      (Array.isArray(children) &&
        children.filter((p) => typeof p !== 'boolean').length <= 1))

  return (
    <>
      <Popconfirm
        placement='topRight'
        title={<Translate id='CONFIRM_REMOVE' />}
        onConfirm={onClickWrapper}
        okButtonProps={{loading}}
      >
        <StyledButton
          styleType={type}
          type={htmlType}
          // onClick={onClickWrapper}
          disabled={disabled}
          loading={loading.toString()}
          align={align}
          style={style}
          $auto={auto}
        >
          <StyledButtonContent size={size} disabled={disabled}>
            {showLoadingIcon ? (
              <Spin
                size='small'
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: size === 'small' ? 13 : 6,
                  fontSize: ButtonText
                }}
                indicator={<LoadingOutlined spin />}
              />
            ) : imagePath ? (
              <StyledButtonImg src={imagePath} />
            ) : null}
            {children}
          </StyledButtonContent>
        </StyledButton>
      </Popconfirm>
    </>
  )
}

DeleteButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.bool
      ])
    ),
    PropTypes.string
  ]).isRequired,
  type: PropTypes.oneOf(['primary', 'secondary', 'error']),
  htmlType: PropTypes.oneOf(['button', 'submit', 'reset']),
  size: PropTypes.oneOf(['small', 'default']),
  align: PropTypes.oneOf(['left', 'right']),
  onClick: PropTypes.func,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  loading: PropTypes.bool,
  auto: PropTypes.bool,
  style: PropTypes.object,
  imagePath: PropTypes.string
}

DeleteButton.defaultProps = {
  type: 'error',
  htmlType: 'button',
  size: 'default',
  onClick: () => null,
  disabled: false,
  loading: false,
  align: 'left',
  style: undefined,
  imagePath: undefined,
  auto: false
}

export default DeleteButton
