import styled from 'styled-components'

import {BorderColor} from '../../../styles/_colors'

export const DocumentsInputSection = styled.div`
  width: 100%;
  position: relative;
  padding: 10px 5px;
  border-top: 1px solid ${BorderColor};
  border-bottom: 1px solid ${BorderColor};
  margin-top: 20px;
`

export const ToggleIcon = styled.div``

export const ToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px 0px;
`

export const Collapsible = styled.div`
  transform: ${({open}) => (open ? 'scale(1,1)' : 'scale(1,0)')};
  max-height: ${({open}) => (open ? '1000px' : '0px')};
  transition: all 0.2s ease-in-out;
  transform-origin: 0 0;
`

export const DocumentCard = styled.div`
  display: inline-block;
  width: 100%;
  padding: 10px 15px;
  background: white;
  position: relative;
  border-radius: 4px;
  border: 1px solid ${BorderColor};
`

export const DocumentNameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`

export const DocumentName = styled.div`
  display: inline-block;
  width: 100%;
  font-weight: bold;
  padding-right: 20px;
  font-size: 14px;
`
export const DocumentInfo = styled.div`
  display: inline-block;
  width: 100%;
  font-size: 14px;
  line-height: 17px;
  font-weight: 100;
`
