import ManageContactPage from '../../pages/messaging/contacts/ManageContactPage'
import ManageMessagePage from '../../pages/messaging/incoming/manage/ManageMessagePage'
import MessagingPage from '../../pages/messaging/MessagingPage'
import ManageProcessMessagePage from '../../pages/messaging/process/manage/ManageProcessMessagePage'
import ProcessPage from '../../pages/messaging/process/ProcessPage'

export default [
  {
    path: '/messaging',
    component: MessagingPage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  },
  {
    // create new process
    path: '/messaging/process/new',
    component: ManageMessagePage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  },
  {
    // check process details
    path: '/messaging/process/:id',
    component: ProcessPage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  },
  {
    // reply to a message from a process
    path: '/messaging/process/:id/reply/:messageID',
    component: ManageProcessMessagePage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  },
  {
    // forward to a message from a process
    path: '/messaging/process/:id/forward/:messageID',
    component: ManageProcessMessagePage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  },
  {
    // send new a message from a process
    path: '/messaging/process/:id/new',
    component: ManageProcessMessagePage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  },
  {
    // reply to a message
    path: '/messaging/message/:id/reply',
    component: ManageMessagePage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  },
  {
    // forward to a message
    path: '/messaging/message/:id/forward',
    component: ManageMessagePage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  },
  {
    path: '/contacts/:id',
    component: ManageContactPage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  }
]
