import React, { useState } from 'react'

import { CalendarOutlined } from '@ant-design/icons'
import { Row, Col } from 'antd'
import moment from 'moment'
import { PropTypes } from 'prop-types'
import { Translate } from 'react-localize-redux'

import { SaveAvailability } from '../../../infra/requests/FittingRequests'
import DateRangeInput from '../../../shared/components/inputs/DateRangeInput'
import MultipleSelectInput from '../../../shared/components/inputs/MultipleSelectInput'
import BaseModal from '../../../shared/components/modal/BaseModal'

const DAYS = [
  { id: 1, name: 'Sunday' },
  { id: 2, name: 'Monday' },
  { id: 3, name: 'Tuesday' },
  { id: 4, name: 'Wednesday' },
  { id: 5, name: 'Thursday' },
  { id: 6, name: 'Friday' },
  { id: 7, name: 'Saturday' }
]

const IsAllSelected = (list) =>
  list && list.length === 1 && list[0] === 'all'

const AvailabilityModal = ({ setModal, open, slots, center, tailor }) => {
  const [loading, setLoading] = useState(false)
  const [range, setRange] = useState([])
  const [days, setDays] = useState([])
  const [hours, setHours] = useState([])

  const handleClose = (refresh) => {
    setRange([])
    setDays([])
    setHours([])
    setModal(refresh)
  }
  const saveAvailability = async () => {
    try {
      setLoading(true)
      const payload = {
        Start: moment.utc(range[0]).startOf('day').toISOString(),
        End: moment.utc(range[1]).endOf('day').toISOString(),
        DaysOfWeek: IsAllSelected(days) ? DAYS.map((d) => d.id) : days,
        Periods: IsAllSelected(hours)
          ? slots.map((s) => `${s.start} - ${s.end}`)
          : hours
      }
      const { success } = await SaveAvailability(center, tailor, payload)
      setLoading(false)
      if (success) handleClose(true)
    } catch (error) {
      setLoading(false)
      console.warn(error)
      handleClose(true)
    }
  }

  const handleDisabled = () => {
    if (!range || range.length === 0) return true
    if (!days || days.length === 0) return true
    if (!hours || hours.length === 0) return true
    return false
  }

  return (
    <BaseModal
      okText={<Translate id='SAVE' />}
      onOk={saveAvailability}
      okButtonProps={{ loading, disabled: handleDisabled() }}
      onClose={() => handleClose(false)}
      title={<Translate id='AVAILABILITY' />}
      open={open}
    >
      {open && (
        <Row gutter={[24, 24]}>
          <Col xs={8}>
            <DateRangeInput
              input={{ value: range, onChange: setRange }}
              label={<Translate id='CHOOSE_PERIOD' />}
            />
          </Col>
          <Col xs={8}>
            <MultipleSelectInput
              input={{ value: days, onChange: setDays }}
              label={<Translate id='CHOOSE_DAYS_OF_WEEK' />}
              placeholder={<Translate id='SELECT_DAYS' />}
              data={DAYS}
              dataKey='id'
              dataLabel='name'
              suffixIcon={<CalendarOutlined />}
            />
          </Col>
          <Col xs={8}>
            <MultipleSelectInput
              input={{ value: hours, onChange: setHours }}
              label={<Translate id='CHOOSE_SLOTS' />}
              placeholder={<Translate id='SELECT_SLOTS' />}
              suffixIcon={<CalendarOutlined />}
              data={slots.map((x) => ({
                ...x,
                name: `${x.start} - ${x.end}`,
                id: `${x.start} - ${x.end}`
              }))}
              dataKey='id'
              dataLabel='name'
            />
          </Col>
        </Row>
      )}
    </BaseModal>
  )
}

AvailabilityModal.propTypes = {
  center: PropTypes.string,
  tailor: PropTypes.string,
  setModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  slots: PropTypes.array.isRequired
}

AvailabilityModal.defaultProps = {
  center: undefined,
  tailor: undefined
}

export default AvailabilityModal
