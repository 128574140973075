import React, { useEffect, useState } from 'react'

import { Row, Col } from 'antd'
import PropTypes from 'prop-types'
import { withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import { reduxForm, getFormValues, initialize } from 'redux-form'
import styled from 'styled-components'

import { StartWayBillSKU, CheckWaybillSKU } from '../../../../infra/requests/DepartureRequests'
import AlertService from '../../../../shared/components/alert/AlertService'
import BackButton from '../../../../shared/components/buttons/BackButton'
import BaseButton from '../../../../shared/components/buttons/BaseButton'
import BaseLoading from '../../../../shared/components/loading/BaseLoading'
import {
  Margin,
  PageForm,
  PageTitle
} from '../../../../shared/styles/BasicStyles'
import {
  ValidateDepartureData,
  ValidateStock
} from '../ValidateDeparture'
import FirstStep from './FirstStep'

const THeader = styled.div`
  display: flex;
  margin-top: 15px;
  font-weight: bold;
`
const HCol = styled.div`
  display: inline-block;
  width: 150px;
`
const TR = styled.div`
  display: flex;
`
const TD = styled.div`
  display: inline-block;
  width: 150px;
`

const calculatedReserved = (movements) => {
  let total = 0
  if (movements && Array.isArray(movements)) {
    movements.forEach((m) => {
      total += m.quantity
    })
  }
  return total
}

const WarningContent = (data) => (
  <div>
    <div>{data?.message}</div>
    <THeader>
      <HCol>Product</HCol>
      <HCol>Available</HCol>
      <HCol>Reserved</HCol>
    </THeader>
    {(data?.items || []).map((item, ind) => (
      <TR key={ind}>
        <TD>{item.barcode} </TD>
        <TD>{item.unitsAvailable}</TD>
        <TD>{calculatedReserved(item.movements)}</TD>
      </TR>
    ))}
  </div>
)

const CreateWayBill = ({ router, formValues, dispatch, translate }) => {
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    const init = async () => {
      dispatch(initialize('departure_create_waybill_kits', { movementTypeId: 11 }))
      setLoading(false)
    }
    init()
  }, [])

  const firstStepCreation = async (payload) => {
    const { data, success } = await StartWayBillSKU(payload)
    if (success) {
      AlertService.success('Departure successfully created',
        `The departure was saved with the id: #${data}`)
      if (success) router.history.push(`/logistics/departures/waybill-kits/${data}`)
    }
    setSaving(false)
  }

  const goNext = async () => {
    setSaving(true)

    const { errors, products } = ValidateDepartureData(formValues)
    if (errors.length) {
      setSaving(false)
      return AlertService.showValidations(translate('MISSING_INFORMATION'), errors)
    }

    const possibleDeparture = await ValidateStock(
      formValues.originId,
      products
    )

    if (!possibleDeparture) {
      setSaving(false)
      return AlertService.showValidations(
        translate('INVALID_QUANTITIES'),
        translate('INVALID_DEPARTURE_QUANTITIES')
      )
    }

    const anySendQtyGreaterThanZero = formValues.products.some((prod) => prod.departQty > 0);
    if (!anySendQtyGreaterThanZero) {
      setSaving(false);
      return AlertService.error(translate('MISSING_INFORMATION'), 'At least one product must have a send quantity greater than 0');
    }

    const payload = {
      originWarehouseId: formValues.originId,
      orderB2bId: formValues.orderB2bid,
      sellerId: formValues.sellerId,
      date: formValues.departureDate,
      items: formValues.products.filter((p) => p.departQty > 0).map((prod) => ({
        barcode: prod.barcode,
        departQty: prod.departQty
      }))
    }
    const { data } = await CheckWaybillSKU(payload)
    if (data?.success) {
      return firstStepCreation(payload)
    }
    setSaving(false)
  }

  if (loading) return <BaseLoading margin='200' />

  return (
    <PageForm>
      <Row gutter={[0, 50]}>
        <Col xs={24}>
          <BackButton
            label='Back to logistics'
            onClick={() => router.history.push('/logistics#depart')}
          />
        </Col>
        <Col xs={12}>
          <PageTitle>Create Waybill Kits</PageTitle>
        </Col>
        <Col xs={12} style={{ textAlign: 'right' }}>
          {(!formValues.movementStatusId || formValues.movementStatusId < 2) && (
            <BaseButton
              type='primary'
              auto
              onClick={goNext}
              loading={saving}
            >
              {translate('SAVE')}
            </BaseButton>
          )}
        </Col>
      </Row>
      <Margin size={50} />
      <FirstStep
        formValues={formValues}
        disabled={formValues.movementStatusId}
      />
    </PageForm>
  )
}

CreateWayBill.propTypes = {
  router: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  formValues: PropTypes.object
}

CreateWayBill.defaultProps = {
  formValues: {}
}

const CreateWayBillPage = reduxForm({
  form: 'departure_create_waybill_kits'
})(CreateWayBill)

export default withLocalize(connect((state) => ({
  orderStatus: state.info.orderStatus,
  paymentMethods: state.info.paymentMethods,
  formValues: getFormValues('departure_create_waybill_kits')(state)
}))(CreateWayBillPage))
