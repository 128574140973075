import React from 'react'

import { FileExcelOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import PropTypes from 'prop-types'
import { Translate, withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import { useParams } from 'react-router'
import { getFormValues } from 'redux-form'

import { AuthTokenKey } from '../../../../../infra/config/LocalStorageKeys'
import AlertService from '../../../../../shared/components/alert/AlertService'
import BaseButton from '../../../../../shared/components/buttons/BaseButton'
import { PrimaryButtonColor } from '../../../../../shared/styles/_colors'
import {
  BodyH5,
  TitleH2,
  TitleH5,
  Margin
} from '../../../../../shared/styles/BasicStyles'
import {
  CompositionBox,
  CompositionTitle,
  CostFileButton
} from '../../../ProductsStyles'

const calculateAverage = (family, value) => {
  let avr = 0
  if (family && family.list) {
    family.list.forEach((item) => {
      avr += item[value] || 0
    })
    return Math.round((avr / family.list.length) * 100) / 100
  }
  return avr
}

const CalculateCost = (composition = []) => {
  let total = 0
  composition.forEach((item) => {
    if (item.type === 'family') {
      const avr = calculateAverage(item, 'tableValue')
      const price = avr * item.quantity
      total += price
    }
    if (item.type === 'material') {
      const price = (item.tableValue || 0) * (item.quantity || 0)
      total += price
    }
    if (item.type === 'service') {
      const price = (item.cost || 0) * (item.units || 0)
      total += price
    }
  })

  return total.toFixed(2)
}

const CalculateDeviation = (cost, value) => {
  if (!cost || parseFloat(cost) === 0 || !value || parseFloat(value) === 0) return 0
  return (value / cost).toFixed(2)
}

const CalculateMargin = (cost, value) => {
  if (!cost || parseFloat(cost) == 0 || !value || parseFloat(value) == 0) return 0
  return (((value - cost) / cost) * 100).toFixed(2)
}

const CompositionCard = ({ composition, form, translate }) => {
  const { id } = useParams()

  const cost = CalculateCost(composition)
  const deviation = CalculateDeviation(
    cost,
    form?.productPriceSection?.totalValue
  )
  const margin = CalculateMargin(
    cost,
    form?.productPriceSection?.totalValue
  )

  const handleDownload = () => {
    const authToken = localStorage.getItem(AuthTokenKey)
    fetch(
      `${window.env.REACT_APP_API}/Products/getProductCostFile/${id}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }
    )
      .then((resp) => {
        if (resp.status == 200) {
          resp.blob().then((blob) => {
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.style.display = 'none'
            a.href = url
            a.download = 'tts'
            document.body.appendChild(a)
            a.click()
            window.URL.revokeObjectURL(url)
          })
            .catch(() => AlertService.error(translate('ERROR'), translate('ERROR_DOWNLOAD_COST_FILE')))
        } else {
          return AlertService.error(translate('ERROR'), translate('ERROR_DOWNLOAD_COST_FILE'))
        }
      })
      .catch(() => AlertService.error(translate('ERROR'), translate('ERROR_DOWNLOAD_COST_FILE')))
  }

  return (
    <CompositionBox>
      <CompositionTitle>
        <Translate id='TOTAL_COST' />
      </CompositionTitle>
      <Margin size='10' />
      <Row gutter={[0, 10]}>
        <Col xs={12} lg={5} style={{ textAlign: 'center' }}>
          <TitleH5>
            <Translate id='TECHNICAL_FILE' />
          </TitleH5>
          <BodyH5>
            <Translate id='COST_UNIT' />
          </BodyH5>
          <TitleH2 $color={PrimaryButtonColor}>{cost}€</TitleH2>
        </Col>
        <Col xs={12} lg={5} style={{ textAlign: 'center' }}>
          <TitleH5>
            <Translate id='ESTIMATED_COST' />
          </TitleH5>
          <BodyH5>
            <Translate id='COST_UNIT' />
          </BodyH5>
          <TitleH2 $color={PrimaryButtonColor}>
            {form?.productPriceSection?.baseValue || 'N/A'}€
          </TitleH2>
        </Col>
        <Col xs={12} lg={5} style={{ textAlign: 'center' }}>
          <TitleH5>
            <Translate id='FINAL_SALE' />
          </TitleH5>
          <BodyH5>
            <Translate id='COST_UNIT' />
          </BodyH5>
          <TitleH2 $color={PrimaryButtonColor}>
            {form?.productPriceSection?.totalValue || 'N/A'}€
          </TitleH2>
        </Col>
        <Col xs={12} lg={5} style={{ textAlign: 'center' }}>
          <TitleH5>
            <Translate id='ESTIMATED_DEVIATION' />
          </TitleH5>
          <BodyH5 $color='transparent'>.</BodyH5>
          <TitleH2 $color={PrimaryButtonColor}>+{deviation}%</TitleH2>
        </Col>
        <Col xs={12} lg={4} style={{ textAlign: 'center' }}>
          <TitleH5>
            <Translate id='FINAL_MARGIN' />
          </TitleH5>
          <BodyH5 $color='transparent'>.</BodyH5>
          <TitleH2 $color={PrimaryButtonColor}>{margin}%</TitleH2>
        </Col>
      </Row>

      <CostFileButton>
        <BaseButton
          auto
          size='small'
          type='secondary'
          onClick={handleDownload}
          icon={<FileExcelOutlined />}
        >
          <Translate id='DOWNLOAD_COST_FILE' />
        </BaseButton>
      </CostFileButton>
    </CompositionBox>
  )
}

CompositionCard.propTypes = {
  form: PropTypes.object.isRequired,
  composition: PropTypes.array.isRequired
}

export default withLocalize(connect((state) => ({
  form: getFormValues('manage_product')(state)
}))(CompositionCard))
