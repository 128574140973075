import React from 'react'

import {Col, Row} from 'antd'
import {FieldArray} from 'redux-form'

import {Margin} from '../../../../shared/styles/BasicStyles'
import FeaturesList from './FeaturesList'
import TechnologiesList from './TechnologiesList'

const ProductDetails = () => (
  <>
    <Margin size={36} />
    <Row gutter={[20, 20]} align='top'>
      <Col xs={24} lg={15}>
        <FieldArray
          component={FeaturesList}
          name='productPropertiesSection.productCharacteristicValue'
        />
      </Col>
      <Col xs={18} lg={9}>
        <FieldArray
          component={TechnologiesList}
          name='productPropertiesSection.productTechnology'
        />
      </Col>
    </Row>
    <Margin size={20} />
  </>
)

export default ProductDetails
