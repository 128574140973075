import React, {useState, useEffect} from 'react'

import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'
import {connect} from 'react-redux'

import {GetAllWarehouseTypes} from '../../../infra/requests/LogisticsRequests'
import {TableBodyField} from '../../../shared/components/table/TableStyles'
import ActiveTranslation from '../../../shared/logic/translations/ActiveTranslation'

const GetStatusColor = (item) => {
  const {quarantine, productive, confection, defects, materials} =
    item.warehouse

  if (productive) return '#29ABE2'
  if (quarantine) return '#FCCD21'
  if (confection) return '#39B54A'
  if (defects) return '#8CD73F'
  if (materials) return '#8CD73F'

  return ''
}

const GetStatusLabel = (item, warehouseTypes) => {
  const {quarantine, productive, confection, defects, materials} =
    item.warehouse

  let warehouseType

  if (productive) {
    warehouseType = warehouseTypes.find(
      (newdata) => newdata.label === 'Productive'
    )
  }

  if (quarantine) {
    warehouseType = warehouseTypes.find(
      (newdata) => newdata.label === 'Quarantine'
    )
  }

  if (confection) {
    warehouseType = warehouseTypes.find(
      (newdata) => newdata.label === 'Confection'
    )
  }
  if (defects) {
    warehouseType = warehouseTypes.find(
      (newdata) => newdata.label === 'Defects'
    )
  }
  if (materials) {
    warehouseType = warehouseTypes.find(
      (newdata) => newdata.label === 'Materials'
    )
  }

  return warehouseType ? (
    <ActiveTranslation
      value={warehouseType.warehouseTypeTranslation}
      tag='name'
    />
  ) : (
    <Translate id='NA' />
  )
}

const OverviewProdStatus = ({item, warehouseTypes}) => (
  <TableBodyField color={GetStatusColor(item)}>
    {GetStatusLabel(item, warehouseTypes)}
  </TableBodyField>
)

OverviewProdStatus.propTypes = {
  item: PropTypes.object.isRequired
}

export default connect((state) => ({
  warehouseTypes: state.info.warehouseTypes
}))(OverviewProdStatus)
