import React from "react";

import { BarChartOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import moment from "moment";
import PropTypes from "prop-types";
import { Translate } from "react-localize-redux";
import {
  VictoryChart,
  VictoryTheme,
  VictoryBar,
  VictoryTooltip,
  VictoryLabel,
  VictoryAxis,
  VictoryStack,
  VictoryContainer,
} from "victory";

import { StyledFlex } from "../../../shared/styles/BasicStyles";
import {
  EmptyDashboardMessage,
  RelativeContainer,
  ChartLoading,
} from "../DashboardStyles";
import LegendItem from "./LegendItem";

const calculateTickLegendValue = (value) => {
  if (value > 1000001) {
    const label = parseInt(value, 10).toString();
    return `${label.slice(0, -6)}M`;
  }

  if (value > 1001) {
    const label = parseInt(value, 10).toString();
    return `${label.slice(0, -3)}K`;
  }

  if (value % 1 === 0) {
    return parseInt(value, 10);
  }
  return parseFloat(value).toFixed(2);
};

const StackBarComponent = ({ info, loading }) => {
  if (info.options.empty) {
    return (
      <EmptyDashboardMessage>
        <BarChartOutlined />
        <div>
          <Translate id="NO_AVAILABLE_INFO" />
        </div>
      </EmptyDashboardMessage>
    );
  }
  if (!info.options.x) {
    return (
      <EmptyDashboardMessage>
        <BarChartOutlined />
        <div>
          <Translate id="NO_XAXIS_DEFINITION" />
        </div>
      </EmptyDashboardMessage>
    );
  }
  if (!info.options.y || info.options.y.length === "") {
    return (
      <EmptyDashboardMessage>
        <BarChartOutlined />
        <div>
          <Translate id="NO_YAXIS_DEFINITION" />
        </div>
      </EmptyDashboardMessage>
    );
  }

  return (
    <RelativeContainer id="manelito">
      {loading && (
        <ChartLoading>
          <Spin />
        </ChartLoading>
      )}
      <StyledFlex $direction="row" $justify="space-between" $top="12">
        <StyledFlex $direction="row">
          {info.options.y.map((label, index) => (
            <LegendItem
              key={label.key}
              color={label.color}
              name={label.name}
              left={index > 0 ? "10" : "0"}
            />
          ))}
        </StyledFlex>
      </StyledFlex>
      <VictoryChart
        theme={VictoryTheme.material}
        width={550}
        domainPadding={{ x: 15 }}
        containerComponent={
          <VictoryContainer
            style={{
              pointerEvents: "none",
              userSelect: "auto",
              touchAction: "auto",
            }}
          />
        }
      >
        <VictoryAxis
          scale="time"
          standalone={false}
          tickValues={info.items}
          tickLabelComponent={
            <VictoryLabel
              text={({ index }) => {
                const value = info.items[index][info.options.x.key];
                if (moment(value, "MM/YYYY").isValid()) {
                  return moment(value, "MM/YYYY").format("MMM YYYY").split(" ");
                }
                return value;
              }}
              style={[
                { fill: "#231F20", fontSize: 16 },
                { fill: "#231F20", opacity: "50%", fontSize: 14 },
              ]}
            />
          }
        />
        <VictoryAxis
          dependentAxis
          standalone={false}
          tickFormat={calculateTickLegendValue}
          tickLabelComponent={<VictoryLabel style={{ fontSize: 14 }} />}
        />
        <VictoryStack offset={0}>
          {info.options.y.map((label) => (
            <VictoryBar
              key={label.key}
              style={{ data: { fill: label.color, width: 20 } }}
              x={info.options.x.key}
              y={label.key}
              barRatio={0.8}
              animate={{
                duration: 2000,
                onLoad: { duration: 1000 },
              }}
              labelComponent={<VictoryTooltip />}
              labels={({ datum }) =>
                `${datum[info.options.x.key]} - ${datum[label.key]}`
              }
              data={info.items}
            />
          ))}
        </VictoryStack>
      </VictoryChart>
    </RelativeContainer>
  );
};

StackBarComponent.propTypes = {
  info: PropTypes.object.isRequired,
};

export default StackBarComponent;
