import React, { useState, useEffect } from 'react'

import { Row, Col } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Translate, withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import { useParams, useLocation } from 'react-router-dom'
import { initialize, reduxForm, change, getFormValues } from 'redux-form'

import ForwardIcon from '../../../assets/icons/forward.svg'
import ReplyIcon from '../../../assets/icons/reply.svg'
import ArchiveIcon from '../../../assets/icons/unarchive.svg'
import { AuthTokenKey } from '../../../infra/config/LocalStorageKeys'
import {
  GetProcessDetail,
  EditProcessDetail
} from '../../../infra/requests/MessagingRequests'
import BackButton from '../../../shared/components/buttons/BackButton'
import GetFileType from '../../../shared/components/files/GetFileTypeIcon'
import BaseLoading from '../../../shared/components/loading/BaseLoading'
import {
  PageForm,
  CollapsableContainer,
  HeaderCollapsable,
  ContentCollapsable,
  StyledFlex,
  ClickableImage,
  Margin
} from '../../../shared/styles/BasicStyles'
import IncomingButton from '../incoming/IncomingButton'
import { AttachmentIcon, AttachmentName, FileBox } from '../MessagingStyles'
import {
  Title,
  SubTitle,
  ProcessContainer,
  ProcessSubContainer,
  ProcessTitle,
  ProcessDescription,
  MessageSubTitle,
  FooterProcess
} from './ProcessStyles'
import ProcessTab from './ProcessTab'
import AlertService from '../../../shared/components/alert/AlertService'

const ProcessPage = ({
  router,
  dispatch,
  handleSubmit,
  currentFormValues,
  translate
}) => {
  //const location = useLocation()
  const [processInfo, setProcess] = useState({})
  const [loading, setLoading] = useState(true)
  const [save, setSave] = useState(false)
  const [togglesEmail, setTogglesEmail] = useState({})
  const [archive, setArchive] = useState(false)
  const { id } = useParams()

  useEffect(() => {
    async function init() {
      const { data, success } = await GetProcessDetail(id)

      if (success) {
        dispatch(initialize('manage_process', data))
        setProcess(data)
        setLoading(false)
        setArchive(!!(data?.stateId === 3 || data?.stateId === 4))
      }
    }
    init()
  }, [])

  const onSubmit = async (values) => {
    try {
      setSave(true)
      const obj = {
        slaid: values?.slaid,
        processUser: values?.processUser,
        stateId: values?.stateId,
        details: values?.details
      }

      obj.processUser.map((item) => delete item.userResponsible)

      const { data, success } = await EditProcessDetail(id, obj)

      if (success) {
        dispatch(change('manage_process', 'processUser', data.processUser))
        AlertService.success(
          translate('SUCCESS'),
          translate('PROCESS_SAVED')
        )

        setSave(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const archiveProcess = async () => {
    try {
      const obj = {
        slaid: currentFormValues?.slaid,
        processUser: currentFormValues?.processUser,
        stateId: 3,
        details: currentFormValues?.details
      }

      obj.processUser.map((item) => delete item.userResponsible)

      const { success } = await EditProcessDetail(id, obj)

      if (success) {
        router.history.push('/messaging#ongoing')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const unarchiveProcess = async () => {
    try {
      const obj = {
        slaid: currentFormValues?.slaid,
        processUser: currentFormValues?.processUser,
        stateId: 1,
        details: currentFormValues?.details
      }

      obj.processUser.map((item) => delete item.userResponsible)

      const { success } = await EditProcessDetail(id, obj)

      if (success) {
        router.history.push('/messaging#archive')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const downloadFile = (attach) => {
    const authToken = localStorage.getItem(AuthTokenKey)
    fetch(
      `${window.env.REACT_APP_API}/Attachments/DownloadAttachment/${attach.attachmentId}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }
    )
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = attach.name
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
      })
      .catch((e) => console.log(e))
  }

  if (loading) return <BaseLoading margin={200} />

  return (
    <PageForm onSubmit={handleSubmit(onSubmit)}>
      <BackButton
        label={<Translate id='BACK' />}
        onClick={() =>
          archive
            ? router.history.push('/messaging#archive')
            : router?.history?.location?.state?.from === '/contact/:id'
              ? router.history.goBack()
              : router.history.push('/messaging#ongoing')
        }
      />
      <Margin size={30} />
      <Row>
        <Col xs={24} lg={5}>
          <ProcessTab process={processInfo} id={id} loading={save} />
        </Col>
        <Col xs={24} lg={19}>
          <ProcessContainer
            $noBorderLeft
            $noBorderTop
            $bottom='0'
            $top='0'
          >
            <ProcessSubContainer $noBorderBottom $bottom='10'>
              <Title $top='5'>
                <Translate id='SUBJECT' />:
              </Title>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }}
              >
                <SubTitle $top={5}>{processInfo.subject}</SubTitle>
                <MessageSubTitle>
                  <Translate id='TOTAL_MESSAGES' />:{' '}
                  {processInfo?.message.length}
                </MessageSubTitle>
              </div>
            </ProcessSubContainer>
            <ProcessSubContainer $overflow='auto' $noBorderBottom>
              {processInfo.message.map((message, index) => (
                <CollapsableContainer $hasBorderBottom key={index}>
                  <HeaderCollapsable
                    $max={97}
                    onClick={() =>
                      setTogglesEmail({
                        ...togglesEmail,
                        [index]: togglesEmail[index] !== true
                      })
                    }
                  >
                    <StyledFlex
                      $width='100%'
                      $direction='row'
                      $justify='space-between'
                    >
                      <ProcessTitle>
                        <Translate id='FROM' />: {message.from}
                      </ProcessTitle>
                      <StyledFlex $direction='row'>
                        <MessageSubTitle $noMarginTop>
                          {moment(message.date).format('DD/MM/YYYY HH:mm')}
                        </MessageSubTitle>
                        <ClickableImage
                          src={ReplyIcon}
                          onClick={(event) => {
                            event.stopPropagation()
                            router.history.push(
                              `/messaging/process/${processInfo.processId}/reply/${message.messageId}`
                            )
                          }}
                          $marginLeft='12'
                        />
                        <ClickableImage
                          src={ForwardIcon}
                          style={{ height: '15px' }}
                          onClick={(event) => {
                            event.stopPropagation()
                            router.history.push(
                              `/messaging/process/${processInfo.processId}/forward/${message.messageId}`
                            )
                          }}
                          $marginLeft='12'
                        />
                      </StyledFlex>
                    </StyledFlex>
                    {togglesEmail[index] !== true ? (
                      <ProcessDescription>
                        {message.subject}
                      </ProcessDescription>
                    ) : null}
                  </HeaderCollapsable>
                  <ContentCollapsable open={togglesEmail[index] || false}>
                    <StyledFlex
                      $width='100%'
                      $justify='flex-start'
                      $align='flex-start'
                    >
                      <MessageSubTitle>
                        <Translate id='DATE' />:{' '}
                        {`${moment(message.date).format(
                          'dddd, MMMM DD, YYYY'
                        )} 
                        at ${moment(message.date).format('h:mm:ss A')}`}
                      </MessageSubTitle>
                      <MessageSubTitle>
                        <Translate id='SUBJECT' />: {message.subject}
                      </MessageSubTitle>
                      <MessageSubTitle>
                        <Translate id='TO' />:{' '}
                        {message?.to_arr?.length > 0 &&
                          message.to_arr.map((to) => `${to}`)}
                      </MessageSubTitle>
                      {message?.cc_arr?.length > 0 && (
                        <MessageSubTitle>
                          <Translate id='CC' />:{' '}
                          {message.cc_arr.map((cc) => `${cc}`)}
                        </MessageSubTitle>
                      )}
                      <div
                        style={{ padding: '20px 0px' }}
                        dangerouslySetInnerHTML={{
                          __html: message.bodyHtml
                        }}
                      />
                      {message?.attachment?.length > 0 && (
                        <Row
                          xs={24}
                          style={{ width: '100%' }}
                          gutter={[0, 15]}
                        >
                          {message.attachment.map((attach) => (
                            <Col xs={6} key={index}>
                              <FileBox
                                style={{ cursor: 'pointer' }}
                                onClick={() => downloadFile(attach)}
                              >
                                <AttachmentIcon
                                  src={GetFileType(attach)}
                                />
                                <AttachmentName>
                                  {attach.name}
                                </AttachmentName>
                              </FileBox>
                            </Col>
                          ))}
                        </Row>
                      )}
                    </StyledFlex>
                  </ContentCollapsable>
                </CollapsableContainer>
              ))}
            </ProcessSubContainer>
          </ProcessContainer>
        </Col>
        <Col xs={24}>
          {archive ? (
            <FooterProcess>
              <IncomingButton
                text={<Translate id='UNARCHIVE' />}
                imgSource={ArchiveIcon}
                onClick={() => unarchiveProcess()}
              />
            </FooterProcess>
          ) : (
            <FooterProcess>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <IncomingButton
                  text={<Translate id='ARCHIVE' />}
                  imgSource={ArchiveIcon}
                  hasRightBorder
                  onClick={() => archiveProcess()}
                />
                <div />
              </div>
              {processInfo.message.length > 0 ? (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <IncomingButton
                    text={<Translate id='REPLY' />}
                    imgSource={ReplyIcon}
                    hasRightBorder
                    onClick={() => {
                      router.history.push(
                        `/messaging/process/${processInfo.processId}/reply/${processInfo.message[0].messageId}`
                      )
                    }}
                  />
                  <IncomingButton
                    text={<Translate id='FORWARD' />}
                    imgSource={ForwardIcon}
                    onClick={() => {
                      router.history.push(
                        `/messaging/process/${processInfo.processId}/forward/${processInfo.message[0].messageId}`
                      )
                    }}
                  />
                </div>
              ) : (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <IncomingButton
                    text={<Translate id='SEND_NEW_MESSAGE' />}
                    imgSource={ForwardIcon}
                    onClick={() => {
                      router.history.push(
                        `/messaging/process/${processInfo.processId}/new`
                      )
                    }}
                  />
                </div>
              )}
            </FooterProcess>
          )}
        </Col>
      </Row>
    </PageForm>
  )
}

ProcessPage.propTypes = {
  router: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
}

ProcessPage.defaultProps = {}

const myComponent = reduxForm({
  form: 'manage_process'
})(withLocalize(ProcessPage))

export default connect((state) => ({
  currentFormValues: getFormValues('manage_process')(state)
}))(myComponent)
