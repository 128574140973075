import React, { useState, useEffect } from 'react'

import { Col, Row } from 'antd'
import moment from 'moment'
import { Translate } from 'react-localize-redux'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { GetContactActivity } from '../../../../infra/requests/ContactRequests'
import TextInput from '../../../../shared/components/inputs/TextInput'
import BaseLoading from '../../../../shared/components/loading/BaseLoading'
import BaseTable from '../../../../shared/components/table/BaseTable'
import GetTranslationFromArray from '../../../../shared/logic/translations/Translations'
import { SubTitle } from '../../../../shared/styles/BasicStyles'

const Image = styled.img`
  width: 100px;
  background: #c0c0c0;
`

const ImagePlaceholder = styled.img`
  width: 100px;
  height: 100px;
  background: #c0c0c0;
`

const UserActivity = () => {
  const [loading, setLoading] = useState(true)
  const [activity, setActivity] = useState({ items: [] })
  const { id } = useParams()
  useEffect(() => {
    async function init() {
      if (id && id !== 'new') {
        const { data, success } = await GetContactActivity(id)
        if (success) setActivity(data)
      }
      setLoading(false)
    }
    init()
  }, [id])

  const columns = [
    {
      title: <Translate id='IMAGE' />,
      type: 'text',
      dataIndex: 'filename',
      render: (value) =>
        value ? (
          <Image
            src={`${window.env.REACT_APP_API_FILES}/products/${value}`}
          />
        ) : (
          <ImagePlaceholder />
        )
    },
    {
      title: <Translate id='NAME' />,
      type: 'text',
      dataIndex: 'productTranslation',
      render: (value) => GetTranslationFromArray(value, 2)?.name || ''
    },
    {
      title: <Translate id='QTY' />,
      type: 'text',
      dataIndex: 'quantity'
    },
    {
      title: <Translate id='PRICE' />,
      type: 'text',
      dataIndex: 'price',
      render: (value) => (value ? `${value.toFixed(2)}€` : '0€')
    }
  ]

  if (loading) return <BaseLoading margin='100' />

  return (
    <Row gutter={[24, 36]}>
      <Col xs={5}>
        <TextInput
          disabled
          label={<Translate id='LAST_VISIT' />}
          input={{
            value: activity?.lastVisit
              ? moment(activity?.lastVisit).format('YYYY/MM/DD HH:mm')
              : ''
          }}
        />
      </Col>
      <Col xs={5}>
        <TextInput
          disabled
          label={<Translate id='DATE_LAST_ORDER' />}
          input={{
            value: activity?.lastOrder
              ? moment(activity?.lastOrder).format('YYYY/MM/DD HH:mm')
              : ''
          }}
        />
      </Col>
      <Col xs={5}>
        <TextInput
          disabled
          label={<Translate id='LAST_ORDER_VALUE' />}
          input={{ value: activity?.lastOrderValue }}
        />
      </Col>
      <Col xs={24}>
        <SubTitle>
          <Translate id='CURRENTLY_SHOPPING_BAG' />
        </SubTitle>
      </Col>
      <Col xs={24}>
        <BaseTable
          rowKey='productId'
          datasource={activity.items}
          columns={columns}
          emptyMessage={<Translate id='THERE_ARE_NO_ITEMS_IN_BAG' />}
          pagination={{
            render: false
          }}
          loading={loading}
        />
      </Col>
    </Row>
  )
}

export default UserActivity
