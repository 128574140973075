import React, { useState } from 'react'

import { Spin } from 'antd'
import { withLocalize } from 'react-localize-redux'
import styled from 'styled-components'

import { ReactComponent as PdfIcon } from '../../../assets/icons/files/pdf.svg'
import { AuthTokenKey } from '../../../infra/config/LocalStorageKeys'
import AlertService from '../alert/AlertService'

const PackingButton = styled(PdfIcon)`
  cursor: pointer;
  height: 26px;
`

const PackingListButton = ({ type, data, translate }) => {
  const [loading, setLoading] = useState(false)

  const handleItems = () => {
    const items = []
    switch (type) {
      case 'items':
        return data || []
      case 'box':
        return data?.items || []
      case 'lot':
        (data?.boxes || []).forEach((box) => {
          (box?.items || []).forEach((i) => {
            items.push(i)
          })
        })
        return items
      case 'container':
        (data?.lots || []).forEach((lot) => {
          (lot?.boxes || []).forEach((box) => {
            (box?.items || []).forEach((i) => {
              items.push(i)
            })
          })
        })
        return items

      default:
        return data?.items || []
    }
  }

  const handleDownload = () => {
    setLoading(true)
    const items = handleItems()
    const payload = items.map((item) => ({
      ProductId: item.productId,
      SizeId: item.sizeId,
      ColorId: item.colorId,
      ProductWidthId: item.productWidthId,
      DepartQty: item.quantity,
      PVP: item.PVP
    }))
    const authToken = localStorage.getItem(AuthTokenKey)
    fetch(`${window.env.REACT_APP_API}/Logistics/CreateSimplePickingList`, {
      method: 'post',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then((resp) => {
        if (resp.status == 200) {
          resp.blob().then((blob) => {
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.style.display = 'none'
            a.href = url
            a.download = 'pickinglist.pdf'
            document.body.appendChild(a)
            a.click()
            window.URL.revokeObjectURL(url)
            setLoading(false)
          })
            .catch(() => AlertService.error(translate('ERROR'), translate('ERROR_DOWNLOAD_PACKING_LIST')))
        } else {
          return AlertService.error(translate('ERROR'), translate('ERROR_DOWNLOAD_PACKING_LIST'))
        }
      })
  }

  if (loading) return <Spin />
  return <PackingButton onClick={handleDownload} />
}

export default withLocalize(PackingListButton)
