import React, { useState, useEffect } from 'react'

import { Row, Col } from 'antd'
import PropTypes from 'prop-types'
import { Translate, withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'

import {
  GetProductSizes,
  GetProductColors
} from '../../../infra/requests/BaseRequests'
import { GetAllWarehouses, GetAllWarehousesDestiny } from '../../../infra/requests/LogisticsRequests'
import SearchInput from '../../../shared/components/inputs/SearchInput'
import SelectInput from '../../../shared/components/inputs/SelectInput'

const ProdStock = [
  { id: 1, name: [{ languageId: 2, name: 'Productive' }] },
  { id: 2, name: [{ languageId: 2, name: 'Quarantine' }] },
  { id: 3, name: [{ languageId: 2, name: 'Defects' }] },
  { id: 4, name: [{ languageId: 2, name: 'Confection' }] }
]

const OverviewFilters = ({ filters, setFilters, clients, translate }) => {
  const [warehouses, setWarehouses] = useState([])
  const [colors, setColors] = useState([])
  const [sizes, setSizes] = useState([])

  useEffect(() => {
    async function initwarehouses() {
      const { data, success } = await GetAllWarehousesDestiny()
      if (success) setWarehouses(data?.items || [])
    }

    async function initcolors() {
      const { data, success } = await GetProductColors()
      if (success) setColors(data?.items || [])
    }

    async function initsizes() {
      const { data, success } = await GetProductSizes()
      if (success) setSizes(data?.items || [])
    }

    initwarehouses()
    initcolors()
    initsizes()
  }, [])

  const onChangeField = (key, value, timeout = 0) => {
    const result = { ...filters }
    result[key] = value
    setFilters(result, timeout)
  }

  return (
    <Row gutter={[20, 20]}>
      <Col xs={6}>
        <SelectInput
          label={<Translate id='OWNER' />}
          input={{
            value: filters.owner,
            onChange: (value) => onChangeField('owner', value)
          }}
          placeholder={<Translate id='SELECT' />}
          data={clients}
          dataKey='b2bclientId'
          dataLabel='name'
        />
      </Col>
      <Col xs={6}>
        <SelectInput
          label={<Translate id='CLIENT' />}
          input={{
            value: filters.client,
            onChange: (value) => onChangeField('client', value)
          }}
          placeholder={<Translate id='SELECT' />}
          data={clients}
          dataKey='b2bclientId'
          dataLabel='name'
        />
      </Col>
      <Col xs={6}>
        <SelectInput
          label={<Translate id='WAREHOUSE' />}
          input={{
            value: filters.warehouse,
            onChange: (value) => onChangeField('warehouse', value)
          }}
          placeholder={<Translate id='SELECT' />}
          data={warehouses}
          dataKey='warehouseId'
          dataLabel='name'
        />
      </Col>
      <Col xs={6}>
        <SelectInput
          label={<Translate id='COLOR' />}
          input={{
            value: filters.color,
            onChange: (value) => onChangeField('color', value)
          }}
          placeholder={<Translate id='SELECT' />}
          data={colors}
          dataKey='colorId'
          dataLabel='colorTranslation'
        />
      </Col>
      <Col xs={6}>
        <SelectInput
          label={<Translate id='SIZE' />}
          input={{
            value: filters.size,
            onChange: (value) => onChangeField('size', value)
          }}
          placeholder={<Translate id='SELECT' />}
          data={sizes}
          dataKey='sizeId'
          dataLabel='number'
        />
      </Col>
      <Col xs={6}>
        <SelectInput
          label={<Translate id='STOCK' />}
          input={{
            value: filters.stock,
            onChange: (value) => onChangeField('stock', value)
          }}
          placeholder={<Translate id='SELECT' />}
          data={ProdStock}
        />
      </Col>
      <Col xs={12}>
        <Translate id='SEARCH' />
        <SearchInput
          auto
          placeholder={translate('SEARCH')}
          input={{
            value: filters.search,
            onChange: (value) => onChangeField('search', value, 1000),
            onSearch: () => onChangeField('', '')
          }}
        />
      </Col>
    </Row>
  )
}

OverviewFilters.propTypes = {
  translate: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  clients: PropTypes.array.isRequired
}

const mapStateToProps = (state) => ({
  productTypes: state.info.productTypes,
  suppliers: state.info.suppliers,
  productCategories: state.info.productCategories,
  productStyles: state.info.productStyles,
  clients: state.info.clients
})

export default withLocalize(connect(mapStateToProps)(OverviewFilters))
