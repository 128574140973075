import React, {Component} from 'react'

import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'
import {connect} from 'react-redux'
import {getFormValues} from 'redux-form'

import SaveIcon from '../../../assets/icons/save.svg'
import {GetServices} from '../../../infra/requests/ProductsRequests'
import BackButton from '../../../shared/components/buttons/BackButton'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import BaseTable from '../../../shared/components/table/BaseTable'
import ActiveTranslation from '../../../shared/logic/translations/ActiveTranslation'
import {
  Margin,
  StyledHR,
  PageContent,
  TitleH2
} from '../../../shared/styles/BasicStyles'
import {ServiceCheckbox, ServiceAddedLabel} from '../ProductsStyles'

class AddServicesPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      rows: [],
      page: 1,
      pageSize: 25,
      total: 0,
      selected: [],
      added: this.getAddedServices()
    }
  }

  componentDidMount = async () => {
    try {
      this.updateTable()
    } catch (error) {
      console.warn(error)
    }
  }

  getAddedServices = () => {
    const {currentProductForm} = this.props
    const list = []
    if (currentProductForm?.composition) {
      currentProductForm.composition.forEach((material) => {
        if (material.type === 'service') {
          list.push(material.serviceId)
        }
      })
    }
    return list
  }

  updateTable = async () => {
    const {page, pageSize} = this.state
    this.setState({loading: true})
    const {data} = await GetServices(page, pageSize, '')
    this.setState({
      rows: data?.items,
      total: data?.totalItems,
      loading: false
    })
  }

  onChangePagination = (page) => this.setState({page}, this.updateTable)

  handleToggleSelection = (item) => {
    const {selected, added} = this.state

    if (added.includes(item.serviceId)) return

    const found = selected.findIndex((x) => x.serviceId === item.serviceId)
    if (found > -1) {
      selected.splice(found, 1)
      this.setState({selected})
    } else {
      selected.push(item)
      this.setState({selected})
    }
  }

  getColumns = () => {
    const {added, selected} = this.state
    return [
      {
        title: <Translate id='NAME' />,
        type: 'text',
        dataIndex: 'serviceTranslation',
        render: (value) => <ActiveTranslation value={value} tag='name' />
      },
      {
        title: <Translate id='CODE' />,
        type: 'text',
        dataIndex: 'code'
      },
      {
        title: <Translate id='REFERENCE' />,
        type: 'text',
        dataIndex: 'reference'
      },
      {
        title: <Translate id='STATUS' />,
        type: 'text',
        render: (data) =>
          added.includes(data.serviceId) ? (
            <ServiceAddedLabel>
              <Translate id='ALREADY_ADDED' />
            </ServiceAddedLabel>
          ) : (
            <ServiceCheckbox
              checked={
                !!selected.find((s) => s.serviceId === data.serviceId)
              }
            />
          )
      }
    ]
  }

  render() {
    const {loading, rows, page, pageSize, total, selected} = this.state
    const {onClose, addServices} = this.props
    return (
      <PageContent $noMargin $noMinHeight>
        <Margin size={20} />
        <Row>
          <Col xs={24}>
            <BackButton
              label={<Translate id='BACK' />}
              onClick={onClose}
            />
          </Col>
        </Row>
        <Margin size={24} />
        <Row justify='end'>
          <Col xs={12} lg={18}>
            <TitleH2>
              <Translate id='ADD_SERVICES' />
            </TitleH2>
          </Col>
          <Col xs={12} lg={6}>
            <BaseButton
              onClick={() => addServices(selected)}
              type='secondary'
              disabled={selected.length === 0}
              imagePath={SaveIcon}
            >
              <Translate id='ADD_TO_COMPOSITION' />
            </BaseButton>
          </Col>
        </Row>
        <StyledHR $space='20' />
        <Row>
          <Col xs={24}>
            <BaseTable
              rowKey='serviceId'
              datasource={rows}
              columns={this.getColumns()}
              pagination={{
                render: true,
                page,
                pageSize,
                total,
                onChangePagination: this.onChangePagination
              }}
              loading={loading}
              onClickRow={this.handleToggleSelection}
            />
          </Col>
        </Row>
      </PageContent>
    )
  }
}

AddServicesPage.propTypes = {
  onClose: PropTypes.func.isRequired,
  addServices: PropTypes.func.isRequired,
  currentProductForm: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  currentProductForm: getFormValues('manage_product')(state)
})

export default connect(mapStateToProps)(AddServicesPage)
