import React from 'react'

import {Col, Row} from 'antd'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {reduxForm, Field, reset} from 'redux-form'

import {ChangePassword} from '../../../infra/requests/AuthRequests'
import AlertService from '../../../shared/components/alert/AlertService'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import TextInput from '../../../shared/components/inputs/TextInput'
import {
  Margin,
  PageForm,
  PageTitle,
  TitleH3
} from '../../../shared/styles/BasicStyles'

const ProfilePage = ({dispatch, handleSubmit, translate}) => {
  const onSubmit = async (values) => {
    try {
      const result = await ChangePassword(values)
      try {
        if (result.success) {
          AlertService.success(translate('SUCCESS'), result?.data)
          dispatch(reset('profile_form'))
        }
      } catch (error) {
        AlertService.error(translate('ERROR'), result?.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <PageForm onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col xs={24}>
          <PageTitle>
            <Translate id='PROFILE' />
          </PageTitle>
        </Col>
      </Row>
      <Margin size={52} />
      <Row>
        <Col xs={24}>
          <TitleH3 $op>
            <Translate id='CHANGE_PASS' />
          </TitleH3>
        </Col>
      </Row>
      <Margin size={26} />
      <Row gutter={[40, 20]}>
        <Col xs={6}>
          <Field
            component={TextInput}
            name='Password'
            type='password'
            label={<Translate id='PASSWORD' />}
          />
        </Col>
      </Row>
      <Margin size={42} />
      <Row gutter={[40, 20]}>
        <Col xs={6}>
          <Field
            component={TextInput}
            name='NewPassword'
            type='password'
            label={<Translate id='NEW_PASS' />}
          />
        </Col>
        <Col xs={5}>
          <Field
            component={TextInput}
            name='ConfirmNewPassword'
            type='password'
            label={<Translate id='CONFIRM_NEW_PASS' />}
          />
        </Col>
      </Row>
      <Margin size={72} />
      <Row>
        <Col xs={24}>
          <BaseButton type='primary' auto htmlType='submit'>
            <Translate id='SAVE' />
          </BaseButton>
        </Col>
      </Row>
    </PageForm>
  )
}

ProfilePage.propTypes = {
  dispatch: PropTypes.func,
  handleSubmit: PropTypes.func
}

ProfilePage.defaultProps = {
  dispatch: undefined,
  handleSubmit: undefined
}

const myComponent = reduxForm({
  form: 'profile_form'
  // validate: validations
})(ProfilePage)

export default withLocalize(connect((state) => ({}))(myComponent))
