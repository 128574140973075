import React from 'react'

import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Translate} from 'react-localize-redux'
import CloseBorderedImage from '../../../../assets/icons/cancel_border.svg'
import SelectInput from '../../../../shared/components/inputs/SelectInput'
import {BookFontWeight} from '../../../../shared/styles/_texts'
import {StyledFlex, TitleH6} from '../../../../shared/styles/BasicStyles'
import {
  ProductDetailsFeature,
  ProductDetailsFeatureImage
} from '../../../products/ProductsStyles'

const RelatedUsers = ({fields, users, processUser, reply}) => {
  const emails = reply ? processUser : fields.getAll() || []

  const filterUsers = () =>
    users?.length > 0
      ? users.filter(
          (elem) =>
            !emails.find((c) => c?.userResponsibleId === elem.userId)
        )
      : []

  const renderLabel = (item) => {
    if (item?.aspnetMembership?.email) {
      return `${item.fullName} <${item.aspnetMembership.email}> `
    }
    return item.fullName
  }

  const addEmail = async (value) => {
    const object = users.find((x) => x.userId === value)

    if (object) {
      fields.unshift({
        userResponsibleId: object.userId,
        userResponsible: object
      })
    }
  }

  return (
    <div
      style={{
        padding: 29,
        paddingTop: 0,
        marginLeft: 5,
        marginRight: 5,
        maxHeight: 300,
        overflow: 'auto'
      }}
    >
      <div style={{width: '100%', position: 'fixed'}}>
        {!reply && (
          <SelectInput
            placeholder={<Translate id='SELECT' />}
            data={filterUsers()}
            dataKey='userId'
            renderLabel={renderLabel}
            afterChange={(value) => addEmail(value)}
            clearValue
          />
        )}
      </div>
      <div style={{width: '100%', marginTop: 50}}>
        {emails?.length > 0 &&
          emails.map((item, index) => (
            <div
              style={{
                marginBottom: 5
              }}
              key={item?.userId}
            >
              <ProductDetailsFeature style={{width: '100%'}}>
                <StyledFlex $align='flex-start' $width='90%'>
                  <TitleH6
                    $left='6'
                    $right='20'
                    style={{wordBreak: 'break-all'}}
                  >
                    {item?.userResponsible?.fullName}
                  </TitleH6>
                  {item?.userResponsible?.aspnetMembership?.email && (
                    <TitleH6
                      $left='6'
                      $right='20'
                      style={{
                        wordBreak: 'break-all',
                        fontWeight: BookFontWeight
                      }}
                    >
                      {item?.userResponsible?.aspnetMembership?.email}
                    </TitleH6>
                  )}
                </StyledFlex>
                {!reply && (
                  <div
                    style={{
                      width: '10%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <ProductDetailsFeatureImage
                      onClick={() => fields.remove(index)}
                      src={CloseBorderedImage}
                    />
                  </div>
                )}
              </ProductDetailsFeature>
            </div>
          ))}
      </div>
    </div>
  )
}

RelatedUsers.propTypes = {
  fields: PropTypes.object.isRequired,
  processUser: PropTypes.array,
  reply: PropTypes.bool
}

RelatedUsers.defaultProps = {
  processUser: [],
  reply: false
}

export default connect((state) => ({
  users: state.info.users
}))(RelatedUsers)
