import React, { useState, useEffect } from 'react'

import { Tooltip } from 'antd'
import { PropTypes } from 'prop-types'
import { Translate, withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import { Field, reduxForm, initialize } from 'redux-form'

import { ArrayPushIfNotExist } from '../../../logic/arrays/ArrayFunctions'
import ActiveTranslation from '../../../logic/translations/ActiveTranslation'
import { PrimaryButtonColor, TableTextColor } from '../../../styles/_colors'
import {
  PageForm,
  TitleH3,
  Margin,
  AvailableTooltip
} from '../../../styles/BasicStyles'
import BaseModal from '../../modal/BaseModal'
import ModalTable from '../../table/ModalTable'
import IntegerInput from '../IntegerInput'

const calculateSizesAndColors = (product) => {
  const colors = []
  const sizes = []
  if (product && product.productCombination) {
    product.productCombination.forEach((combination) => {
      const color = {
        id: combination.colorId,
        name: combination?.color?.colorTranslation || combination.colorId
      }
      ArrayPushIfNotExist(
        colors,
        color,
        (c) => c.id === combination.colorId
      )

      // Get other combinations fot that size and filter by unique widths
      const extraCombinations = product.productCombination
        .filter((c) => c.sizeId === combination.sizeId)
      const uniqueWidths = extraCombinations.filter((val, index, self) => self.findIndex((f) => f.productWidthId === val.productWidthId) == index)

      const size = {
        id: combination.sizeId,
        name: combination?.size?.nameEu || combination.sizeId,
        order: combination?.size?.order || combination.sizeId,
        widths: uniqueWidths.map((c) => ({
          id: c.productWidthId,
          code: c?.productWidth?.code,
          name: (
            <ActiveTranslation
              value={c?.productWidth?.productWidthTranslation}
              tag='name'
            />
          )
        }))
      }

      ArrayPushIfNotExist(sizes, size, (c) => c.id === combination.sizeId)
    })
  }
  return { colors, sizes: sizes.sort((a, b) => a.order - b.order) }
}

const constructColumns = (colors, translate) => {
  const cols = []
  cols.push({ dataIndex: 'size', title: 'Size' })
  cols.push({ dataIndex: 'width', title: 'Width' })
  colors.forEach((color) => {
    cols.push({
      dataIndex: color.id,
      title: (
        <div style={{ position: 'relative' }}>
          <Tooltip
            title={translate('QTY_FOR_PRODUCT')}
            color={TableTextColor}
          >
            <AvailableTooltip />
          </Tooltip>

          <div style={{ marginRight: 20 }}>
            <ActiveTranslation value={color.name} tag='name' />
            {` ${translate('QTY')}`}
          </div>
        </div>
      ),
      render: (value) => (
        <Field
          component={IntegerInput}
          name={`choices[${value}_${color.id}]`}
          min={0}
        />
      )
    })
  })
  return cols
}

const ProductMatrix = ({
  open,
  onClose,
  onSave,
  product,
  handleSubmit,
  translate,
  dispatch
}) => {
  const [name, setName] = useState('')
  const [columns, setColumns] = useState([])
  const [datasource, setData] = useState([])

  const constructData = (colors, sizes) => {
    const data = []
    sizes.forEach((size) => {
      size.widths.forEach((width) => {
        const row = {
          size: size.name,
          width: width.name,
          key: `${size.id}_${width.id}`
        }
        colors.forEach((color) => {
          row[color.id] = `${size.id}_${width.id}`
        })
        data.push(row)
      })
    })
    return data
  }

  useEffect(() => {
    async function constructMatrix() {
      if (product) {
        const { colors, sizes } = calculateSizesAndColors(product)
        const productName = (
          <ActiveTranslation
            value={product?.productTranslation}
            tag='name'
          />
        )
        setName(productName)
        setColumns(constructColumns(colors, translate))
        setData(constructData(colors, sizes))
        dispatch(initialize('product_matrix', { choices: {} }))
      }
    }
    constructMatrix()
  }, [product, dispatch])

  const onOkWrapper = (values) => {
    const result = []
    Object.keys(values.choices).forEach((key) => {
      if (
        key &&
        values.choices[key] &&
        parseInt(values.choices[key], 10) > 0
      ) {
        const comb = key.split('_')
        const sizeId = parseInt(comb[0], 10)
        const widthId = parseInt(comb[1], 10)
        const colorId = parseInt(comb[2], 10)
        const found = product.productCombination.find(
          (prod) =>
            prod.sizeId === sizeId &&
            prod.colorId === colorId &&
            widthId === prod.productWidthId
        )
        if (found) {
          result.push({
            ...product,
            productId: product.productId,
            productTypeId: product.productTypeId,
            productWidthId: found.productWidthId,
            productWidth: found.productWidth,
            sizeId: found.sizeId,
            colorId: found.colorId,
            reference: found.reference,
            barcode: found.barcode,
            referenceClient: product.referenceClient,
            description: product.productTranslation,
            color: found.color,
            size: found.size,
            quantity: values.choices[key],
            measureUnit: product?.measureUnit?.code,
            ownerId: product.ownerId,
            priceImpact: found.priceImpact,
            totalValue:
              parseFloat(product.productTypeId === 4 ? product.costValue : product.totalValue) +
              parseFloat(found.priceImpact)
          })
        }
      }
    })
    onSave(result)
    dispatch(initialize('product_matrix', { choices: {} }))
  }

  return (
    <BaseModal
      open={open}
      onClose={onClose}
      title={<Translate id='ADD_PRODUCT' />}
      onOk={handleSubmit(onOkWrapper)}
      okText={<Translate id='ADD_PRODUCT' />}
    >
      {open && product && (
        <PageForm $margin='0' onSubmit={handleSubmit(onOkWrapper)}>
          <TitleH3 $color={PrimaryButtonColor}>{name}</TitleH3>
          <Margin size='20' />
          <ModalTable
            rowKey='key'
            datasource={datasource}
            scroll={{}}
            columns={columns}
            pagination={{ render: false }}
          />
        </PageForm>
      )}
    </BaseModal>
  )
}

const ProductMatrixForm = reduxForm({
  form: 'product_matrix',
  enableReinitialize: true
})(ProductMatrix)

ProductMatrix.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  product: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  translate: PropTypes.func
}

ProductMatrix.defaultProps = {
  product: {},
  translate: undefined
}

export default withLocalize(
  connect(() => ({
    initialValues: { choices: {} }
  }))(ProductMatrixForm)
)
