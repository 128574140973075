import React, { Component } from 'react'

import { Row, Col } from 'antd'
import PropTypes from 'prop-types'
import { Translate, withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'

import Export from '../../../assets/icons/export.svg'
import { AuthTokenKey } from '../../../infra/config/LocalStorageKeys'
import { ExportWarehousesList, GetWarehousesList } from '../../../infra/requests/LogisticsRequests'
import AlertService from '../../../shared/components/alert/AlertService'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import CheckboxInput from '../../../shared/components/inputs/CheckboxInput'
import SearchInput from '../../../shared/components/inputs/SearchInput'
import SelectInput from '../../../shared/components/inputs/SelectInput'
import StatusColumn from '../../../shared/components/Status/StatusColumn'
import BaseTable from '../../../shared/components/table/BaseTable'
import { ConstructQuery } from '../../../shared/logic/queries/EndpointQueries'
import { Margin } from '../../../shared/styles/BasicStyles'
import { ConfigurationExportBtnContainer } from '../LogisticStyles'

class ConfigurationPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      isExporting: false,
      rows: [],
      page: 1,
      pageSize: 20,
      total: 0,
      owner: undefined,
      productive: false, // New state
      transit: false, // New state
      confection: false, // New state
      search: ''
    }
  }

  getColumns = () => [
    {
      title: <Translate id='WAREHOUSE' />,
      type: 'text',
      dataIndex: 'name'
    },
    {
      title: <Translate id='CODE' />,
      type: 'text',
      dataIndex: 'code',
      render: (value) => value || <Translate id='NA' />
    },
    {
      title: <Translate id='EXTERNAL_CODE' />,
      type: 'text',
      dataIndex: 'externalCode',
      render: (value) => value || <Translate id='NA' />
    },
    {
      title: <Translate id='OWNER' />,
      type: 'text',
      dataIndex: 'b2bclient',
      render: (value) => value?.name || <Translate id='NA' />
    },
    {
      title: <Translate id='PRIORITY' />,
      type: 'text',
      dataIndex: 'priority'
    },
    {
      title: <Translate id='STATUS' />,
      type: 'text',
      dataIndex: 'active',
      render: (value) => <StatusColumn value={value} />
    }
  ];

  componentDidMount = async () => {
    try {
      this.updateTable()
    } catch (error) {
      console.warn(error)
    }
  };

  updateTable = async () => {
    const { page, pageSize, owner, productive, transit, confection, search } = this.state
    this.setState({ loading: true })

    const query = ConstructQuery({ owner, productive, transit, confection, search })

    const { data } = await GetWarehousesList(page, pageSize, query)

    this.setState({
      rows: data?.items || [],
      total: data?.totalItems || 0,
      loading: false
    })
  };

  handleExport = async () => {
    const { translate } = this.props
    this.setState({ isExporting: true })
    const authToken = localStorage.getItem(AuthTokenKey)
    fetch(
      `${window.env.REACT_APP_API}/Logistics/ListWarehouses?exportExcel=true`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }
    )
      .then((resp) => {
        if (resp.status === 200) {
          resp.blob().then((blob) => {
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.style.display = 'none'
            a.href = url
            a.download = 'warehouses_list.xlsx'
            document.body.appendChild(a)
            a.click()
            window.URL.revokeObjectURL(url)
            this.setState({ isExporting: false })
          })
            .catch(() => {
              AlertService.error(translate('ERROR'), translate('ERROR_DOWNLOAD_FILE'))
              this.setState({ isExporting: false })
            })
        } else {
          this.setState({ isExporting: false })
          return AlertService.error(translate('ERROR'), translate('ERROR_DOWNLOAD_FILE'))
        }
      })
      .catch(() => {
        this.setState({ isExporting: false })
        AlertService.error(translate('ERROR'), translate('ERROR_DOWNLOAD_FILE'))
      })
  };

  onChangePagination = (page) => this.setState({ page }, this.updateTable);

  render() {
    const { loading, rows, page, pageSize, total, owner, productive, transit, confection, isExporting } = this.state
    const { router, clients } = this.props

    return (
      <>
        <Row style={{ display: 'flex', justifyContent: 'start' }} gutter={24}>
          <Col xs={12} md={10}>
            <SelectInput
              label={<Translate id='OWNER' />}
              input={{
                value: owner,
                onChange: (value) => {
                  this.onChangePagination(1)
                  this.setState({ owner: value }, this.updateTable)
                }
              }}
              placeholder={<Translate id='SELECT_OWNER' />}
              data={clients}
              dataKey='b2bclientId'
              dataLabel='name'
            />
          </Col>

          <Col style={{ display: 'flex', alignItems: 'end' }} xs={12} md={10}>
            <SearchInput
              auto
              input={{
                value: this.state.search,
                onChange: (value) => {
                  this.setState({ search: value }, () => {
                    this.onChangePagination(1)
                    this.updateTable()
                  })
                }
              }}
            />
          </Col>
        </Row>
        <Margin size={24} />
        <Row style={{ display: 'flex', justifyContent: 'start' }} gutter={24}>
          <Col xs={8} md={4}>
            <BaseButton onClick={() => router.history.push('/logistics/warehouses/new')}>
              <Translate id='ADD_WAREHOUSE' />
            </BaseButton>
          </Col>
          <Col xs={8} md={4}>
            <BaseButton onClick={() => router.history.push('/logistics/rates')}>
              <Translate id='SHIPPING_RATES' />
            </BaseButton>
          </Col>
          <Col xs={8} md={4}>
            <BaseButton onClick={() => router.history.push('/logistics/carriers')}>
              <Translate id='CARRIERS' />
            </BaseButton>
          </Col>

          <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} xs={24} md={6}>
            <CheckboxInput
              input={{
                value: productive,
                onChange: (checked) => {
                  this.setState({ productive: checked }, this.updateTable)
                  this.onChangePagination(1)
                }
              }}
              label='Productive'
            />
            <CheckboxInput
              input={{
                value: transit,
                onChange: (checked) => {
                  this.setState({ transit: checked }, this.updateTable)
                  this.onChangePagination(1)
                }
              }}
              label='Transit'
            />
            <CheckboxInput
              input={{
                value: confection,
                onChange: (checked) => {
                  this.setState({ confection: checked }, this.updateTable)
                  this.onChangePagination(1)
                }
              }}
              label='Confection'
            />
          </Col>

          <Col xs={8} md={6}>
            <ConfigurationExportBtnContainer>
              <BaseButton
                auto
                loading={isExporting}
                imagePath={Export}
                type='secondary'
                onClick={this.handleExport}
              >
                <Translate id='EXPORT_XLS' />
              </BaseButton>
            </ConfigurationExportBtnContainer>
          </Col>
        </Row>
        <Margin size={15} />
        <Row>
          <Col xs={24}>
            <BaseTable
              rowKey='warehouseId'
              datasource={rows}
              columns={this.getColumns()}
              pagination={{
                render: true,
                page,
                pageSize,
                total,
                onChangePagination: this.onChangePagination
              }}
              onClickRow={(row) => {
                router.history.push(`/logistics/warehouses/${row.warehouseId}`)
              }}
              loading={loading}
            />
          </Col>
        </Row>
      </>
    )
  }
}

ConfigurationPage.propTypes = {
  router: PropTypes.object.isRequired,
  clients: PropTypes.array.isRequired,
  translate: PropTypes.func.isRequired
}

export default withLocalize(
  connect((state) => ({
    clients: state.info.clients
  }))(ConfigurationPage)
)
