import styled from 'styled-components'

import {SuccessColor, TableTextColor} from '../../styles/_colors'
import {StyledButton} from '../buttons/ButtonsStyles'

export const AddressContainer = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: 30px;
`

export const AddressName = styled.div`
  font-size: 16px;
  line-height: 19px;
  font-weight: bold;
  margin-bottom: 10px;
`

export const AddressDescription = styled.div`
  font-size: 14px;
  line-height: 17px;
`

export const ButtonSection = styled.div`
  margin-top: 10px;
  ${StyledButton} {
    margin-right: 10px;
  }
`

export const StopOverSelect = styled.div`
  position: absolute;
  top: 12px;
  right: 24px;
  padding: 2px 3px;
  cursor: pointer;
  border-radius: 4px;
  color: ${({selected}) => selected ? SuccessColor : TableTextColor};
  font-weight: ${({selected}) => selected ? 'bold' : 'regular'};
  opacity: ${({selected}) => selected ? 1 : 0.5};
`
