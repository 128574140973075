import React, {useState} from 'react'

import {Col, Row} from 'antd'
import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'
import {connect} from 'react-redux'

import CloseBorderedImage from '../../../../assets/icons/cancel_border.svg'
import BaseButton from '../../../../shared/components/buttons/BaseButton'
import SelectInput from '../../../../shared/components/inputs/SelectInput'
import ActiveTranslation from '../../../../shared/logic/translations/ActiveTranslation'
import {
  BodyH5,
  StyledFlex,
  TitleH6
} from '../../../../shared/styles/BasicStyles'
import {
  ProductDetailsFeature,
  ProductDetailsFeatureImage
} from '../../ProductsStyles'

const FeaturesList = ({productCharacteristics, fields}) => {
  const [featureType, setFeatureType] = useState(undefined)
  const [featureValue, setFeatureValue] = useState(undefined)

  const characteristics = fields.getAll() || []

  const filterFeatures = () =>
    productCharacteristics.filter(
      (elem) =>
        !characteristics.find(
          (c) =>
            c?.characteristicValue?.characteristicId ==
            elem.characteristicId
        )
    )

  const onChangeFeature = (value) => {
    const found = productCharacteristics.find(
      (c) => c.characteristicId == value
    )
    setFeatureType(found)
    setFeatureValue(undefined)
  }

  const addCurrentFeature = () => {
    const value = featureType.values.find(
      (v) => v.characteristicValueId == featureValue
    )
    const object = {
      characteristicValue: {
        characteristic: featureType,
        characteristicId: featureType.characteristicId,
        characteristicValueId: featureValue,
        characteristicValueTranslation:
          value?.characteristicValueTranslation
      },
      characteristicValueId: featureValue
    }
    fields.push(object)
    setFeatureType(undefined)
    setFeatureValue(undefined)
  }

  return (
    <Row gutter={[20, 20]} align='bottom'>
      <Col xs={12} lg={8}>
        <SelectInput
          label={<Translate id='CHARACTERISTIC' />}
          input={{
            value: featureType?.characteristicId,
            onChange: onChangeFeature
          }}
          placeholder={<Translate id='SELECT' />}
          dataKey='characteristicId'
          dataLabel='characteristicTranslation'
          data={filterFeatures()}
        />
      </Col>
      <Col xs={12} lg={8}>
        <SelectInput
          label={<Translate id='VALUE' />}
          input={{
            value: featureValue,
            onChange: setFeatureValue
          }}
          placeholder={<Translate id='SELECT' />}
          dataKey='characteristicValueId'
          dataLabel='characteristicValueTranslation'
          disabled={!featureType}
          data={featureType ? featureType.values : []}
        />
      </Col>
      <Col xs={8} lg={4}>
        <BaseButton
          type='secondary'
          onClick={addCurrentFeature}
          disabled={!featureType || !featureValue}
        >
          <Translate id='ADD' />
        </BaseButton>
      </Col>
      {characteristics.map((feature, index) => (
        <Col xs={24} key={index}>
          <ProductDetailsFeature>
            <StyledFlex $direction='row'>
              <TitleH6 $right='6'>
                <ActiveTranslation
                  value={
                    feature?.characteristicValue?.characteristic
                      ?.characteristicTranslation
                  }
                  tag='name'
                />
              </TitleH6>
              <BodyH5 $right='20'>
                <ActiveTranslation
                  value={
                    feature?.characteristicValue
                      ?.characteristicValueTranslation
                  }
                  tag='name'
                />
              </BodyH5>
            </StyledFlex>
            <ProductDetailsFeatureImage
              onClick={() => fields.remove(index)}
              src={CloseBorderedImage}
            />
          </ProductDetailsFeature>
        </Col>
      ))}
    </Row>
  )
}

FeaturesList.propTypes = {
  fields: PropTypes.object.isRequired,
  productCharacteristics: PropTypes.array.isRequired
}

export default connect((state) => ({
  productCharacteristics: state.info.productCharacteristics
}))(FeaturesList)
