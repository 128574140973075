import React from 'react'

import {
  ExportOutlined,
  FileTextOutlined,
  HomeOutlined,
  ImportOutlined,
  MenuOutlined,
  RetweetOutlined
} from '@ant-design/icons'
import {Popover} from 'antd'
import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'

import {StyledHR} from '../../../shared/styles/BasicStyles'
import {
  MenuOption,
  OverviewMenuButton,
  TextPopover
} from '../LogisticStyles'

const MenuPopup = ({item, history, onChangeTab}) => {
  const options = (
    <>
      <MenuOption onClick={() => onChangeTab('main')}>
        <FileTextOutlined />
        <TextPopover>
          <Translate id='MOVEMENT_HISTORY' />
        </TextPopover>
      </MenuOption>
      <StyledHR $space='7' />
      <MenuOption onClick={() => history.push(`/logistics/warehouses/${item.warehouseId}`)}>
        <HomeOutlined />
        <TextPopover>
          <Translate id='WAREHOUSE_DETAIL' />
        </TextPopover>
      </MenuOption>
      <StyledHR $space='7' />
      <MenuOption onClick={() => history.push('/logistics/arrivals/new')}>
        <ImportOutlined />
        <TextPopover>
          <Translate id='START_RECEPTION' />
        </TextPopover>
      </MenuOption>
      <StyledHR $space='7' />
      <MenuOption onClick={() => history.push('/logistics/departures/new')}>
        <ExportOutlined />
        <TextPopover>
          <Translate id='START_EXPEDITION' />
        </TextPopover>
      </MenuOption>
      <StyledHR $space='7' />
      <MenuOption onClick={() => history.push('/logistics/maintenance/inventory')}>
        <RetweetOutlined />
        <TextPopover>
          <Translate id='ADJUSTMENT' />
        </TextPopover>
      </MenuOption>
    </>
  )

  return (
    <Popover
      placement='leftTop'
      trigger='click'
      content={options}
    >
      <OverviewMenuButton>
        <MenuOutlined />
      </OverviewMenuButton>
    </Popover>
  )
}

MenuPopup.propTypes = {
  item: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default MenuPopup
