import React from 'react'

import {PropTypes} from 'prop-types'

import {
  CheckBoxInputBox,
  CheckboxInputStyled
} from './InputStyles'

const CheckboxInput = ({
  input,
  label,
  meta,
  indeterminate,
  afterChange,
  type,
  disabled
}) => {
  const handleChange = (event) => {
    input.onChange(!input.value, event)
    if (typeof afterChange === 'function') {
      afterChange(!input.value)
    }
  }

  const hasError = meta.invalid && meta.submitFailed
  return (
    <CheckBoxInputBox>
      <CheckboxInputStyled
        hasError={hasError}
        onChange={handleChange}
        type={type}
        value={input.value}
        checked={input.value}
        indeterminate={indeterminate}
        disabled={disabled}
      >
        {label}
      </CheckboxInputStyled>
    </CheckBoxInputBox>
  )
}

CheckboxInput.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  afterChange: PropTypes.func,
  indeterminate: PropTypes.bool,
  type: PropTypes.string,
  disabled: PropTypes.bool
}

CheckboxInput.defaultProps = {
  input: {value: undefined, onChange: () => null},
  label: undefined,
  meta: {},
  afterChange: undefined,
  indeterminate: false,
  type: 'checkbox',
  disabled: false
}

export default CheckboxInput
