export default (boxes) => {
  const errors = []
  boxes.forEach((box) => {
    if (box.ean128 && box.ean128 !== '' && box.ean128.length < 12) errors.push('BOX_MISSING_EAN')
    if (!box.weight || box.weight === '' || parseFloat(box.weight) < 0.001) errors.push('BOX_MISSING_WEIGHT')
    if (!box.dimDepth || box.dimDepth === '' || parseFloat(box.dimDepth) < 0.001) errors.push('BOX_MISSING_DEPTH')
    if (!box.dimHeight || box.dimHeight === '' || parseFloat(box.dimHeight) < 0.001) errors.push('BOX_MISSING_HEIGHT')
    if (!box.dimWidth || box.dimWidth === '' || parseFloat(box.dimWidth) < 0.001) errors.push('BOX_MISSING_WIDTH')
  })
  return errors
}
