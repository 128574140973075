import styled from 'styled-components'

import {
  BorderColor,
  TableHeaderBackground,
  TableHeaderColor
} from '../../../shared/styles/_colors'
import {
  LabelText,
  MediumFontWeight,
  OverlineText
} from '../../../shared/styles/_texts'

export const BannerImageContainer = styled.div`
  display: flex;
`

export const BannerImage = styled.img`
  max-height: 104px;
`

export const DateText = styled.div`
  color: #1c1d21;
  font-weight: ${MediumFontWeight};
  font-size: ${OverlineText};
  line-height: 16px;
  margin-left: 14px;
  opacity: 50%;
`

export const HeaderLogs = styled.div`
  padding: 8px 0px;
  color: ${TableHeaderColor};
  font-size: ${LabelText};
  text-decoration: none;
  border: 1px solid ${BorderColor};
  border-radius: 7px;
  background-color: ${TableHeaderBackground};
  width: 100%;
  display: flex;
`
export const HeaderLogsText = styled.div`
  width: ${(p) => p.width || '100%'};
  border-right: ${(p) => (p.border ? `1px solid ${BorderColor}` : 'none')};
  margin-right: 10px;
  display: flex;
  align-items: ${(p) => (p.center ? 'center' : 'flex-start')};
  justify-content: ${(p) => (p.center ? 'center' : 'flex-start')};
`
export const BodyLogs = styled.div`
  padding: 8px 0px;
  font-size: ${LabelText};
  text-decoration: none;
  width: 100%;
  display: flex;
`

export const LogPoint = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${TableHeaderColor};
`

export const VoucherTypeSection = styled.div`
  background-color: #F1F1EF;
  border: 0.3px solid #C6C8CA;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 24px;
`
