import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import {
  TableRow,
  ListCol,
  TableTitle,
  TextTooltip
} from '../../../../shared/components/table/CustomTableStyles'
import ActiveTranslation from '../../../../shared/logic/translations/ActiveTranslation'

const DropdownList = styled.div`
  display: inline-block;
  transform: ${({$open}) => ($open ? 'scale(1,1)' : 'scale(1,0)')};
  height: ${({$open}) => ($open ? 'auto' : '0')};
  transform-origin: top center;
  margin-top: 5px;
  margin-bottom: -9px;
  margin-left: -1px;
  margin-right: -1px;
  width: calc(100% + 2px);
`

const ItemsInBox = ({box, items}) => {
  const products = box?.items || []
  return (
    <DropdownList xs={24} $open={box.open}>
      <TableRow $header>
        <ListCol xs={4}>
          <TableTitle first subtitle text='Reference' />
        </ListCol>
        <ListCol xs={3}>
          <TableTitle subtitle text='EAN Code' />
        </ListCol>
        <ListCol xs={5}>
          <TableTitle subtitle text='Description' />
        </ListCol>
        <ListCol xs={2}>
          <TableTitle subtitle text='Color' />
        </ListCol>
        <ListCol xs={2}>
          <TableTitle subtitle text='Size' />
        </ListCol>
        <ListCol xs={2}>
          <TableTitle subtitle text='Width' />
        </ListCol>
        <ListCol xs={2}>
          <TableTitle subtitle text='Order Quantity' abbr='Order Qty' />
        </ListCol>
        <ListCol xs={2}>
          <TableTitle subtitle text='Box quantity' abbr='Box Qty' />
        </ListCol>
      </TableRow>

      {products.map((product, index) => {
        const productDetails = items.find((x) => x.orderItemId == product.orderItemId)
        return (
          <TableRow key={index}>
            <ListCol xs={4}>
              <TextTooltip
                first
                text={productDetails?.productCombination?.reference || '-'}
              />
            </ListCol>
            <ListCol xs={3}>
              <TextTooltip
                text={productDetails?.productCombination?.barcode || '-'}
              />
            </ListCol>
            <ListCol xs={5}>
              <TextTooltip
                text={
                  <ActiveTranslation
                    value={
                      productDetails?.productCombination?.product
                        ?.productTranslation
                    }
                    tag='name'
                  />
                }
              />
            </ListCol>
            <ListCol xs={2}>
              <TextTooltip
                text={
                  <ActiveTranslation
                    value={
                      productDetails?.productCombination?.color?.colorTranslation ||
                    '-'
                    }
                    tag='name'
                  />
                }
              />
            </ListCol>
            <ListCol xs={2}>
              <TextTooltip
                text={productDetails?.productCombination?.size?.nameEu || '-'}
              />
            </ListCol>
            <ListCol xs={2}>
              <TextTooltip
                text={productDetails?.productCombination?.productWidth?.code || '-'}
              />
            </ListCol>
            <ListCol xs={2}>
              <TextTooltip align='center' text={productDetails?.quantity || '-'} />
            </ListCol>
            <ListCol xs={2}>
              <TextTooltip align='center' text={product?.itemQuantity || '-'} />
            </ListCol>
          </TableRow>
        )
      })}
    </DropdownList>
  )
}

ItemsInBox.propTypes = {
  box: PropTypes.object.isRequired
}

export default ItemsInBox
