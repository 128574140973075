import { InfoCircleOutlined } from '@ant-design/icons'
import { Tabs } from 'antd'
import styled, { createGlobalStyle } from 'styled-components'

import {
  PageBackground,
  PrimaryButtonColor,
  TableTextColor,
  TableHeaderColor,
  BorderColor,
  ErrorColor,
  PrimaryColor
} from './_colors'
import {
  FooterSize,
  NavbarSize,
  PageLeftRightMargin,
  device
} from './_responsive'
import {
  BoldFontWeight,
  OverlineText,
  TabsText,
  LabelText,
  BodyText,
  BookFontWeight,
  MediumFontWeight,
  SemiBoldFontWeight,
  H1Text,
  Subtitle1Text,
  H2Text,
  Subtitle2Text,
  FooterText,
  InformationTileDataText
} from './_texts'
import { StyledButton } from '../components/buttons/ButtonsStyles'

export const BasicStyles = createGlobalStyle`
body {
  font-family: 'Assistant', 'Campton' !important;
  margin: 0;
  padding: 0;
}
html {
  scroll-behavior: smooth;
}

&&& {
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    color: #fff;
    background: ${PrimaryButtonColor};
  }
  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid ${PrimaryButtonColor};
  }

  .ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
    color: white;
    background: ${PrimaryButtonColor};
    border-color: ${PrimaryButtonColor};
    border-radius: 45px;
    padding: 0 10px;
  }

  .ant-popover{
    max-width:500px;
    overflow: hidden;
  }

  .ant-message-notice-content{
    min-width: 60vw;
    text-align: left;
    padding: 16px 40px;
    border-radius: 4px;
    background: #FBFBFB;
    max-height: 80vh;
    overflow-y: auto;

    span[role=img]{
      display: none;
    }
  }

  .ant-message{
    z-index: 9999
  }

  .ant-modal-mask {
  z-index: 4000;
  }

  .ant-modal-wrap {
  z-index: 4001;
  }

  .ant-select-dropdown, .ant-picker-dropdown{
    z-index: 4005;
  }

  .ant-picker-now-btn{
    color: ${PrimaryColor};
  }

  .ant-tooltip{
    z-index: 99999;
  }
} 

`

/* basic app texts */
const setStyleManagement = ({
  $top,
  $bottom,
  $left,
  $right,
  $color,
  $opacity
}) => {
  let applyValues = ''
  if ($top) {
    applyValues = applyValues.concat(`margin-top: ${$top}px;`)
  }
  if ($bottom) {
    applyValues = applyValues.concat(`margin-bottom: ${$bottom}px;`)
  }
  if ($left) {
    applyValues = applyValues.concat(`margin-left: ${$left}px;`)
  }
  if ($right) {
    applyValues = applyValues.concat(`margin-right: ${$right}px;`)
  }
  if ($color) {
    applyValues = applyValues.concat(`color: ${$color};`)
  }
  if ($opacity) {
    applyValues = applyValues.concat(`opacity: ${$opacity};`)
  }
  return applyValues
}

export const TitleH1 = styled.h1`
  font-family: "Campton";
  color: ${TableTextColor};
  font-size: ${H1Text};
  font-weight: ${BoldFontWeight};
  margin: 0px;
  ${(props) => setStyleManagement(props)};
`
export const TitleH2 = styled.h2`
  font-family: "Campton";
  color: ${TableTextColor};
  font-size: ${H2Text};
  font-weight: ${BoldFontWeight};
  margin: 0px;
  opacity: ${({ $op }) => ($op ? 0.5 : 1)};
  ${(props) => setStyleManagement(props)};
`
// collapsable titles headers, ref header
export const TitleH3 = styled.h3`
  font-family: "Campton";
  color: ${TableTextColor};
  font-size: ${TabsText};
  font-weight: ${BoldFontWeight};
  opacity: ${({ $op }) => ($op ? 0.5 : 1)};
  margin: 0px;
  ${(props) => setStyleManagement(props)};
`
export const TitleH4 = styled.h4`
  font-family: "Campton";
  color: ${TableTextColor};
  font-size: ${Subtitle1Text};
  font-weight: ${({ $light }) => ($light ? MediumFontWeight : BoldFontWeight)};
  margin: 0px;
  opacity: ${({ $op }) => ($op ? 0.5 : 1)};
  ${(props) => setStyleManagement(props)};
`
// labels
export const TitleH5 = styled.h5`
  font-family: "Campton";
  color: ${TableTextColor};
  font-size: ${LabelText};
  font-weight: ${SemiBoldFontWeight};
  margin: 0px;
  opacity: ${({ $op }) => ($op ? 0.5 : 1)};
  ${(props) => setStyleManagement(props)};
`

export const TitleH6 = styled.h6`
  font-family: "Campton";
  color: ${TableTextColor};
  font-size: ${Subtitle2Text};
  font-weight: ${SemiBoldFontWeight};
  margin: 0px;
  ${(props) => setStyleManagement(props)};
`
export const TitleH7 = styled.h5`
  font-family: "Campton";
  color: ${TableTextColor};
  font-size: ${LabelText};
  font-weight: ${BoldFontWeight};
  margin: 0px;
  opacity: ${({ $op }) => ($op ? 0.5 : 1)};
  ${(props) => setStyleManagement(props)};
`
export const TitleH8 = styled.h5`
  font-family: "Campton";
  color: ${TableHeaderColor};
  font-size: ${InformationTileDataText};
  font-weight: ${BoldFontWeight};
  margin: 0px;
  opacity: ${({ $op }) => ($op ? 0.5 : 1)};
  ${(props) => setStyleManagement(props)};
  width: 100%;
  display: inline-block;
`
export const TitleH9 = styled.h5`
  font-family: "Campton";
  color: ${TableHeaderColor};
  font-size: ${Subtitle2Text};
  font-weight: ${MediumFontWeight};
  margin: 0px;
  width: 100%;
  display: inline-block;
  opacity: ${({ $op }) => ($op ? 0.5 : 1)};
  ${(props) => setStyleManagement(props)};
`

// labels body,
export const BodyH3 = styled.div`
  color: ${({ $color }) => $color || TableTextColor};
  font-size: ${({ $size }) => $size || LabelText};
  font-weight: ${BookFontWeight};
  opacity: ${({ $op }) => ($op ? 0.5 : 1)};
  margin: 0px;
  ${(props) => setStyleManagement(props)};
`

export const BodyH5 = styled.div`
  color: ${({ $op }) => $op || TableTextColor};
  font-size: ${({ $size }) => $size || BodyText};
  font-weight: ${BookFontWeight};
  margin: 0px;
  ${(props) => setStyleManagement(props)};
`

/* end of basic texts */

export const StyledHR = styled.hr`
  border-bottom: 1px solid ${BorderColor};
  border-top: none;
  margin: ${({ $space }) => ($space ? `${$space}px 0px` : '0px')};
  ${(props) => setStyleManagement(props)};
`

export const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${PageBackground};
  display: inline-block;
`

export const ContentContainer = styled.div`
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 30px 0px 75px 0px;
`

export const PageLoadingContainer = styled(PageContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  min-height: 100vh;
`
export const PageContent = styled.div`
  width: 100%;
  max-width: 1600px;
  min-height: ${({ $noMinHeight }) =>
    $noMinHeight ? 'auto' : `calc(100vh - ${NavbarSize} - ${FooterSize})`};
  margin: ${({ $noMargin }) =>
    $noMargin ? '0 auto 0 auto' : `${NavbarSize} auto 0 auto`};
  padding: 0px 20px;
  background-color: ${({ $backgroundColor }) =>
    $backgroundColor || 'transparent'};
  @media ${device.desktopL} {
    max-width: 1440px;
  }
  @media ${device.desktop} {
    max-width: 1240px;
  }
`

export const FullContainer = styled.div`
  margin: ${NavbarSize} auto 0 auto;
  position: relative;
  width: 100%;
  min-height: ${({ $noMinHeight }) =>
    $noMinHeight ? 'auto' : `calc(100vh - ${NavbarSize} - ${FooterSize})`};
`

// default tabs
const { TabPane } = Tabs

export const StyledTabTitle = styled.div`
  font-weight: ${BoldFontWeight};
  font-size: ${({ smaller }) => (smaller ? LabelText : TabsText)};
  opacity: 0.5;
`
export const StyledTabs = styled(Tabs)`
  color: ${TableTextColor};
  margin-top: ${({ $top }) => $top || 0}px;
  
  .ant-tabs-nav{
    display: ${({ $hidden }) => $hidden ? 'none' : 'flex'};;
  }

  .ant-tabs-ink-bar {
    background-color: ${PrimaryButtonColor};
    height: 4px !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${PrimaryButtonColor};

    ${StyledTabTitle} {
      opacity: 1;
    }
  }

  .ant-tabs-tab {
    padding: 12px 24px;
    margin: 0 12px 0 0;

    &:hover {
      color: ${PrimaryButtonColor};

      ${StyledTabTitle} {
        opacity: 1;
      }
    }
  }
`
export const StyledTabTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const Flex = styled.div`
  display: flex;
`

export const StyledTabBadge = styled.div`
  color: #ffffff;
  font-weight: ${BoldFontWeight};
  font-size: ${OverlineText};
  background-color: ${PrimaryButtonColor};
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  margin-left: 10px;
  line-height: 11px;
`

export const StyledTabPane = styled(TabPane)`
  width: 100%;
  display: inline-block;
  margin: 30px 0;
`

export const PageTitle = styled.h2`
  display: block;
  font-size: 27px;
  line-height: 34px;
  font-weight: bold;
  color: ${PrimaryButtonColor};
  margin-top: ${({ $top }) => $top || 0}px;
  margin-bottom: ${({ $bottom }) => $bottom || 0}px;
`

export const PageSubtitle = styled.h6`
  font-size: 18px;
  line-height: 21px;
  font-weight: regular;
  color: ${TableTextColor};
  margin-top: ${({ top }) => top || 0}px;
  margin-bottom: ${({ bottom }) => bottom || 0}px;
`
export const SubTitle = styled.div`
  font-size: ${TabsText};
  font-weight: ${BoldFontWeight};
  color: ${TableTextColor};
  opacity: 50%;
`

export const PageForm = styled.form`
  margin: ${({ $margin }) => $margin || '30px 0'};
  width: 100%;
  display: inline-block;
`

export const Margin = styled.div`
  height: ${({ size }) => size || 0}px;
`
export const CombinedInputs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`
export const EmptySpace = styled.div`
  width: 100%;
  display: inline-block;
  color: transparent;

  ::after {
    content: ".";
  }
`
export const SpaceBetween = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ $direction }) => $direction || 'row'};
  justify-content: space-between;
  margin-top: ${({ $top }) => $top || 0}px;
  flex-wrap: wrap;
`

export const HeaderCollapsable = styled.div`
  padding: 12px 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${({ $direction }) => $direction || 'space-between'};
  align-items: center;
  cursor: pointer;
  max-height: ${({ $max }) => $max || 50}px;
`

export const CollapsableSubGroup = styled.div`
  padding: ${({ $content }) => ($content ? '0px 0px 0px 30px' : '0px 30px')};
  border-bottom: none;
`

export const CollapsableContainer = styled.div`
  border-bottom: ${({ $hasBorderBottom }) =>
    $hasBorderBottom ? `1px solid ${BorderColor}` : 'none'};
  &:last-child {
    border-bottom: none;
  }
`

export const ContentCollapsable = styled.div`
  transform: ${({ open }) => (open ? 'scale(1,1)' : 'scale(1,0)')};
  max-height: ${({ open }) => (open ? '100%' : '0px')};
  transition: all 0.05s ease-in-out;
  transform-origin: 0 0;
`

export const ToggleImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:active {
    outline: none;
  }

  &:focus {
    outline: none;
  }
`

export const ToggleImage = styled.img`
  display: inline-block;
  margin-right: 15px;
  ${({ $open }) =>
    $open ? 'transform: rotate(0deg);' : 'transform: rotate(-90deg);'}
`

export const ToggleDown = styled.img`
  margin-right: ${({ $noMarginRight }) => ($noMarginRight ? '0px' : '40px')};
  ${({ $open }) =>
    $open ? 'transform: rotate(0deg);' : 'transform: rotate(-180deg);'}
`

export const StyledFlex = styled.div`
  display: flex;
  flex-direction: ${({ $direction }) => $direction || 'column'};
  justify-content: ${({ $justify }) => $justify || 'flex-start'};
  align-items: ${({ $align }) => $align || 'center'};
  margin-top: ${({ $top }) => $top || 0}px;
  margin-left: ${({ $left }) => $left || 0}px;
  margin-right: ${({ $right }) => $right || 0}px;
  margin-bottom: ${({ $bottom }) => $bottom || 0}px;
  width: ${({ $width }) => $width || 'auto'};
  height: ${({ $height }) => $height || 'auto'};
  cursor: ${({ $clickable }) => ($clickable ? 'pointer' : 'default')};
`
export const PopOverTitle = styled.div`
  font-size: ${LabelText};
  font-weight: ${BookFontWeight};
  color: ${TableHeaderColor};
`

export const PopOverDescription = styled.div`
  display: inline-block;
  width: 100%;
  font-size: ${BodyText};
  font-weight: ${MediumFontWeight};
`

export const ClickableImage = styled.img`
  cursor: pointer;
  margin-right: ${({ $marginRight }) => $marginRight || '0'}px;
  margin-left: ${({ $marginLeft }) => $marginLeft || '0'}px;
`

export const PropertyDescriptionContainer = styled.div``

export const CircleIcon = styled.div`
  display: flex;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: ${PrimaryButtonColor};
  justify-content: center;
  align-items: center;
`

export const SquareIcon = styled.div`
  display: flex;
  height: 36px;
  width: 46px;
  cursor: pointer;
  background-color: ${PrimaryButtonColor};
  justify-content: center;
  align-items: center;
  margin: -7px;
  margin-left: 23px;
  border-radius: 4px;
  border: 1px solid ${BorderColor};
`
export const TextPopover = styled.div`
  font-size: ${FooterText};
  font-weight: ${({ $bold }) => ($bold ? BoldFontWeight : BookFontWeight)};
  line-height: 17px;
  padding: 2px;
`
export const TextPopoverLink = styled.div`
  cursor: pointer;
  font-size: ${FooterText};
  font-weight: ${({ $bold }) => ($bold ? BoldFontWeight : BookFontWeight)};
  line-height: 17px;
  padding: 2px;
`

export const OverdueAlert = styled.div`
  color: white;
  background: ${ErrorColor};
  border-radius: 50%;
  height: 22px;
  padding: 0 4px;
  overflow: hidden;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 12px;
  width: ${({ $width }) => $width || 'auto'};
`
export const ExportButtons = styled.div`
  text-align: right;

  ${StyledButton} {
    margin-left: 15px;
  }
`

export const PreserveStateComponent = styled.div`
  display: ${({ open }) => (open ? 'inline-block' : 'none')};
  width: 100%;
`

export const AvailableTooltip = styled(InfoCircleOutlined)`
  position: absolute;
  top: 0;
  right: ${({ $right }) => $right || 0};
  color: ${({ $error }) => ($error ? ErrorColor : TableTextColor)};
  cursor: pointer;
`
