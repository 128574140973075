import ArrivalDetailPage from '../../pages/logistics/Arrivals/ArrivalDetailPage'
import CreateArrivalPage from '../../pages/logistics/Arrivals/CreateArrivalPage'
import CarriersPage from '../../pages/logistics/Configuration/CarriersPage'
import ManageCarrierPage from '../../pages/logistics/Configuration/ManageCarrierPage'
import ManageWarehouse from '../../pages/logistics/Configuration/ManageWarehouse'
import ShippingRatesPage from '../../pages/logistics/Configuration/ShippingRatesPage'
import LogisticsPage from '../../pages/logistics/LogisticsPage'
import InventoryPage from '../../pages/logistics/Maintenance/InventoryPage'
import StockPage from '../../pages/logistics/Maintenance/StockPage'
import StockTransferPage from '../../pages/logistics/Maintenance/StockTransferPage'

export default [
  {
    path: '/logistics',
    component: LogisticsPage,
    isAuthenticated: true,
    shouldRenderHeader: true,
    shouldRenderFooter: true,
    role: 1
  },
  {
    path: '/logistics/arrivals/new',
    component: CreateArrivalPage,
    isAuthenticated: true,
    shouldRenderHeader: true,
    shouldRenderFooter: true,
    role: 1
  },
  {
    path: '/logistics/arrivals/:id',
    component: ArrivalDetailPage,
    isAuthenticated: true,
    shouldRenderHeader: true,
    shouldRenderFooter: true,
    role: 1
  },
  {
    path: '/logistics/maintenance/inventory',
    component: InventoryPage,
    isAuthenticated: true,
    shouldRenderHeader: true,
    shouldRenderFooter: true,
    role: 1
  },
  {
    path: '/logistics/maintenance/stockTransfer',
    component: StockTransferPage,
    isAuthenticated: true,
    shouldRenderHeader: true,
    shouldRenderFooter: true,
    role: 1
  },
  {
    path: '/logistics/maintenance/stock',
    component: StockPage,
    isAuthenticated: true,
    shouldRenderHeader: true,
    shouldRenderFooter: true,
    role: 1
  },
  {
    path: '/logistics/carriers',
    component: CarriersPage,
    isAuthenticated: true,
    shouldRenderHeader: true,
    shouldRenderFooter: true,
    role: 1
  },
  {
    path: '/logistics/carriers/:id',
    component: ManageCarrierPage,
    isAuthenticated: true,
    shouldRenderHeader: true,
    shouldRenderFooter: true,
    role: 1
  },
  {
    path: '/logistics/rates',
    component: ShippingRatesPage,
    isAuthenticated: true,
    shouldRenderHeader: true,
    shouldRenderFooter: true,
    role: 1
  },
  {
    path: '/logistics/warehouses/:id',
    component: ManageWarehouse,
    isAuthenticated: true,
    shouldRenderHeader: true,
    shouldRenderFooter: true,
    role: 1
  }
]
