import React, {Component} from 'react'

import {Col, Row} from 'antd'
import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'

import {GetSendServer} from '../../../../infra/requests/BaseRequests'
import StatusColumn from '../../../../shared/components/Status/StatusColumn'
import BaseTable from '../../../../shared/components/table/BaseTable'

class SendServerTab extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rows: []
    }
  }

  getColumns = () => [
    {
      dataIndex: 'name',
      type: 'text',
      title: <Translate id='NAME' />
    },
    {
      dataIndex: 'hostname',
      type: 'text',
      title: <Translate id='HOST_NAME' />
    },
    {
      dataIndex: 'imapPort',
      type: 'text',
      title: <Translate id='PORT' />
    },
    {
      dataIndex: 'username',
      type: 'text',
      title: <Translate id='USERNAME' />
    },
    {
      dataIndex: 'password',
      type: 'text',
      title: <Translate id='PASSWORD' />
    },
    {
      dataIndex: 'active',
      type: 'text',
      title: <Translate id='STATE' />,
      render: (value) => <StatusColumn value={value} />
    }
  ]

  componentDidMount = async () => {
    try {
      this.updateTable()
    } catch (error) {
      console.warn(error)
    }
  }

  updateTable = async () => {
    try {
      const {data, success} = await GetSendServer()
      if (success) {
        this.setState({
          rows: [data]
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  openSendServer = (row) => {
    const {router} = this.props
    router.history.push(
      `/mailConfiguration/sendServer/${row.mailServerId}`
    )
  }

  // onChangePagination = (page) => this.setState({page}, this.updateTable)

  render() {
    // const {rows, page, pageSize, total} = this.state
    const {rows} = this.state
    return (
      <>
        {/* <Row xs={24}>
          <Col xs={12}>
            <BaseButton type='secondary' imagePath={Trash} auto>
              Delete
            </BaseButton>
          </Col>
          <Col xs={12} align='end'>
            <BaseButton auto imagePath={IconAdd}>
              <Translate id='ADD_SEND_SERVER' />
            </BaseButton>
          </Col>
        </Row>
        <Margin size={12} /> */}
        <Row>
          <Col xs={24}>
            <BaseTable
              datasource={rows}
              columns={this.getColumns()}
              onClickRow={this.openSendServer}
              pagination={{
                render: false // ,
                //   // page,
                //   // pageSize,
                //   // total,
                //   // onChangePagination: this.onChangePagination
              }}
              rowKey='mailServerId'
              // onClickRow = // nova página para editar valores
            />
          </Col>
        </Row>
      </>
    )
  }
}

SendServerTab.propTypes = {
  router: PropTypes.object.isRequired
}

export default SendServerTab
