// Importing necessary React components and libraries
import React, { useEffect } from "react";
import { Row, Col } from "antd";
import PropTypes from "prop-types";
import { Translate } from "react-localize-redux";

// Importing a custom SelectInput component and ArrayPushIfNotExist function
import SelectInput from "../../../shared/components/inputs/SelectInput";
import { ArrayPushIfNotExist } from "../../../shared/logic/arrays/ArrayFunctions";
import { Margin } from "../../../shared/styles/BasicStyles";

// Defining the OpenPOFilters functional component
const OpenPOFilters = ({ filters, setFilters, POList }) => {
  // Initializing three arrays to store unique values for filtering options
  const orderList = [];
  const productList = [];
  const ClientList = [];

  // Iterating over each Purchase Order (po) in the POList array
  POList.forEach((po) => {
    // Utilizing a custom function to add unique order numbers to the orderList array
    ArrayPushIfNotExist(
      orderList,
      { id: po.orderB2bid, name: po.orderB2b.number },
      (elem) => elem.name === po.orderB2b.number
    );

    // Utilizing a custom function to add unique product names to the productList array
    ArrayPushIfNotExist(
      productList,
      { id: po.productId, name: po?.product?.productTranslation },
      (elem) => elem.id === po.productId
    );

    // Utilizing a custom function to add unique client names to the ClientList array
    ArrayPushIfNotExist(
      ClientList,
      {
        id: po.orderB2b.b2bclient.b2bclientId,
        name: po.orderB2b.b2bclient.name,
      },
      (elem) => elem.name === po.orderB2b.b2bclient.name
    );
  });

  // Handling changes in the selected values (order, product, client)
  const handleChange = (key, value) => {
    setFilters((prevFilters) => {
      // Create a new object with the current filters
      const change = { ...prevFilters };
      // Update the specified key
      change[key] = value;

      // Return the updated filters to be applied to the state
      return change;
    });
  };

  // Returning JSX representing the component's UI
  return (
    <Row gutter={24}>
      {/* First Column: Order Number Selector */}
      <Col xs={24} md={8}>
        <SelectInput
          input={{
            value: filters.order,
            onChange: (value) => handleChange("orderB2bid", value),
          }}
          label={<Translate id="ORDER_NUMBER" />}
          placeholder={<Translate id="SELECT" />}
          data={orderList}
          dataKey="id"
          dataLabel="name"
        />
      </Col>

      {/* Second Column: Product Selector */}
      <Col xs={24} md={8}>
        <SelectInput
          input={{
            value: filters.product,
            onChange: (value) => handleChange("product", value),
          }}
          label={<Translate id="PRODUCT" />}
          placeholder={<Translate id="SELECT" />}
          data={productList}
          dataKey="id"
          dataLabel="name"
        />
      </Col>
      {/* Third Column: Client Order Selector */}
      <Col xs={24} md={8}>
        <SelectInput
          input={{
            value: filters.client,
            onChange: (value) => handleChange("client", value),
          }}
          label={<Translate id="CLIENT_ORDER" />}
          placeholder={<Translate id="SELECT" />}
          data={ClientList}
          dataKey="id"
          dataLabel="name"
        />
      </Col>
    </Row>
  );
};

// Type-checking for the props of the OpenPOFilters component
OpenPOFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  POList: PropTypes.array.isRequired,
};

// Exporting the OpenPOFilters component
export default OpenPOFilters;
