import React from 'react'

import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'

import ActiveTranslation from '../../../shared/logic/translations/ActiveTranslation'
import CollapsableHeaderTitle from '../components/CollapsableHeaderTitle'

const CollapsableHeader = ({PO}) => (
  <>
    <CollapsableHeaderTitle
      name={<Translate id='REF_SKYPRO' />}
      description={PO?.product?.referenceCode || <Translate id='NA' />}
    />
    <CollapsableHeaderTitle
      name={<Translate id='DESCRIPTION' />}
      description={
        <ActiveTranslation
          value={PO?.product?.productTranslation}
          tag='name'
        />
      }
    />
    <CollapsableHeaderTitle
      name={<Translate id='TYPE' />}
      description={
        <ActiveTranslation
          value={PO?.product?.productType?.productTypeTranslation}
          tag='name'
        />
      }
    />
    <CollapsableHeaderTitle
      name={<Translate id='CLIENT' />}
      description={PO?.orderB2b?.b2bclient?.name}
    />
    <CollapsableHeaderTitle
      name={<Translate id='MOQ' />}
      description={PO.product?.moq || <Translate id='NA' />}
    />
    <CollapsableHeaderTitle
      name={<Translate id='MAQ' />}
      description={PO.product?.maq || <Translate id='NA' />}
    />
    <CollapsableHeaderTitle
      name={<Translate id='STOCK_TOTAL' />}
      description={PO?.product?.stock || 0}
    />
    <CollapsableHeaderTitle
      name={<Translate id='TOTAL_ORDER_QTY' />}
      description={PO?.totalQuantity || 0}
    />
    <CollapsableHeaderTitle
      name={<Translate id='TOTAL_PRICE' />}
      description={`${PO?.totalValue ? PO.totalValue.toFixed(2) : 0} €`}
      last
    />
  </>
)

CollapsableHeader.propTypes = {
  PO: PropTypes.object.isRequired
}

CollapsableHeader.defaultProps = {}

export default CollapsableHeader
