import styled from 'styled-components'

import {
  BorderColor,
  FooterTextColor,
  PageBackground,
  PrimaryButtonColor,
  ErrorColor
} from '../../../styles/_colors'
import {SemiBoldFontWeight} from '../../../styles/_texts'

export const DocumentsPopupContainer = styled.div`
  width: 100%;
  position: relative;
`

export const DocumentsInputSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  padding: 7px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid ${BorderColor};
  border-radius: 4px;
  margin-top: 20px;
  cursor: pointer;
`

export const ToggleIcon = styled.div`
  transform: ${({$open}) => ($open ? 'rotate(180deg)' : 'rotate(0deg)')};
`

export const ToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px 0px;
`

export const Collapsible = styled.div`
  border: 1px solid ${BorderColor};
  border-radius: 4px;
  transform: ${({open}) => (open ? 'scale(1,1)' : 'scale(1,0)')};
  max-height: 400px;
  transition: all 0.2s ease-in-out;
  transform-origin: 0 0;
  position: absolute;
  z-index: 2;
  background-color: ${PageBackground};
  right: 0;
  top: calc(100% + 3px);
  width: 400px;
  padding: 20px;
  overflow-y: auto;
`

export const DocumentCard = styled.div`
  display: flex;
  flex-direction: row;
  width: 360px;
  padding: 10px 15px;
  background: white;
  position: relative;
  border-radius: 4px;
  border: 1px solid ${BorderColor};
  cursor: ${({$pointer}) => ($pointer ? 'pointer' : 'default')};
`

export const DocumentNameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const DocumentName = styled.div`
  font-weight: ${SemiBoldFontWeight};
  padding-right: 20px;
  font-size: 12px;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 18px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
`
export const DocumentInfo = styled.div`
  display: inline-block;
  width: 100%;
  font-size: 12px;
  line-height: 17px;
  font-weight: 100;
`
export const NumberDocs = styled.div`
  border-radius: 50%;
  background-color: ${PrimaryButtonColor};
  display: flex;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
`

export const NumberDocsText = styled.div`
  font-size: 11px;
  line-height: 11px;
  font-weight: ${SemiBoldFontWeight};
  color: ${FooterTextColor};
`
export const UploadImage = styled.img`
  height: 35px;
  width: 35px;
  margin-right: 12px;
`

export const DocumentTitle = styled.div`
  display: inline-block;
  width: 100%;
  font-size: 14px;
  line-height: 17px;
  font-weight: 100;
  padding-left: 10px;
`

export const LineSeparator = styled.div`
  border-bottom: 1px solid ${BorderColor};
  width: calc(100% + 40px);
  margin: 10px -20px;
`

export const DocumentTypeLabel = styled.div`
  font-weight: bold;
  margin-right: 20px;
  display: inline-block;
`

export const EmptyMessage = styled.div`
  font-size: 16px;
  text-align: center;
`

export const RemoveButton = styled.div`
  cursor: pointer;
  color: ${ErrorColor};
`
