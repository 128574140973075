import React, {useState} from 'react'

import {Col, Row} from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import Trash from '../../../assets/icons/remove.svg'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import DateRangeInput, {FutureRanges} from '../../../shared/components/inputs/DateRangeInput'
import SelectInput from '../../../shared/components/inputs/SelectInput'
import TextInput from '../../../shared/components/inputs/TextInput'
import BaseTable from '../../../shared/components/table/BaseTable'
import ActiveTranslation from '../../../shared/logic/translations/ActiveTranslation'
import {Margin} from '../../../shared/styles/BasicStyles'
import {SubTitle} from '../ClientStyles'

const Columns = [
  {
    dataIndex: 'modulationType',
    type: 'text',
    title: <Translate id='MODULATION' />,
    render: (value) => <ActiveTranslation value={value?.modulationTypeTranslation} tag='name' />
  },
  {
    dataIndex: 'rate',
    type: 'text',
    title: <Translate id='RATE_A' />
  },
  {
    dataIndex: 'weight',
    type: 'text',
    title: <Translate id='WEIGHT' />
  },
  {
    dataIndex: 'rateB',
    type: 'text',
    title: <Translate id='RATE_B' />
  },
  {
    dataIndex: 'dateFrom',
    type: 'text',
    title: <Translate id='FROM' />,
    render: (value) => <div>{moment(value).format('DD-MM-YYYY')}</div>
  },
  {
    dataIndex: 'dateTo',
    type: 'text',
    title: <Translate id='TO' />,
    render: (value) => <div>{moment(value).format('DD-MM-YYYY')}</div>
  }
]

const ModulationProgrammed = ({fields, modulationTypes, translate}) => {
  const [selected, setSelected] = useState([])
  const [type, setType] = useState('')
  const [rate, setRate] = useState('')
  const [weight, setWeight] = useState('')
  const [date, setDate] = useState('')

  const rows = fields.getAll() || []

  const addModulation = () => {
    const modulationType = modulationTypes.find(
      (x) => x.modulationTypeId == type
    )
    const object = {
      positionModulationId: rows?.length,
      modulationType,
      modulationTypeId: type,
      rate,
      rateB: 0,
      dateFrom: date[0],
      dateTo: date[1],
      weight
    }
    fields.push(object)
    setType('')
    setRate('')
    setWeight('')
    setDate('')
  }

  const removeModulation = () => {
    const indexes = selected.map((element) =>
      rows.findIndex((x) => x.positionModulationId === element.key)
    )
    indexes
      .sort((a, b) => (a > b ? -1 : 1))
      .forEach((i) => fields.remove(i))
    setSelected([])
  }

  return (
    <>
      <Row xs={24}>
        <Col xs={24}>
          <SubTitle>
            <Translate id='MODULATION_PROGRAMED' />
          </SubTitle>
        </Col>
      </Row>
      <Margin size={36} />
      <Row xs={24} align='bottom'>
        <Col xs={6}>
          <SelectInput
            input={{value: type, onChange: setType}}
            label={<Translate id='MODULATION_TYPE' />}
            data={modulationTypes}
            placeholder={<Translate id='CHOOSE' />}
            dataLabel='modulationTypeTranslation'
            dataKey='modulationTypeId'
          />
        </Col>
        <Col xs={4}>
          <div style={{marginLeft: 20}}>
            <TextInput
              input={{value: rate, onChange: setRate}}
              label={<Translate id='RATE_A' />}
              placeholder={translate('RATE_A')}
            />
          </div>
        </Col>
        <Col xs={4}>
          <div style={{marginLeft: 20}}>
            <TextInput
              input={{value: weight, onChange: setWeight}}
              label={<Translate id='WEIGHT' />}
              placeholder={translate('WEIGHT')}
            />
          </div>
        </Col>
        <Col xs={6}>
          <div style={{marginLeft: 20}}>
            <DateRangeInput
              input={{value: date, onChange: setDate}}
              label={<Translate id='DATE' />}
              ranges={FutureRanges}
              disabledDate={(value) => moment().startOf('day').isAfter(value.endOf('day'))}
            />
          </div>
        </Col>
        <Col xs={4}>
          <div style={{marginLeft: 20}}>
            <BaseButton
              auto
              type='secondary'
              onClick={addModulation}
              disabled={
                type === '' || weight === '' || rate === '' || date === ''
              }
            >
              <Translate id='ADD' />
            </BaseButton>
          </div>
        </Col>
      </Row>
      <Margin size={40} />
      <Row>
        <Col xs={24}>
          <BaseButton
            auto
            type='secondary'
            imagePath={Trash}
            onClick={removeModulation}
            disabled={selected?.length <= 0}
          >
            <Translate id='DELETE' />
          </BaseButton>
        </Col>
      </Row>
      <Margin size={14} />
      <Row>
        <Col xs={24}>
          <BaseTable
            datasource={rows}
            columns={Columns}
            pagination={{
              render: false
            }}
            rowSelection={{
              selected,
              onRowSelection: setSelected
            }}
            rowKey='positionModulationId'
          />
        </Col>
      </Row>
    </>
  )
}

ModulationProgrammed.propTypes = {
  fields: PropTypes.object.isRequired
}

export default withLocalize(
  connect((state) => ({
    modulationTypes: state.info.modulationTypes
  }))(ModulationProgrammed)
)
