import React, { useEffect, useState } from 'react'

import { Row, Col } from 'antd'
import PropTypes from 'prop-types'
import { Translate, withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import {
  reduxForm,
  getFormValues,
  FieldArray,
  change
} from 'redux-form'

import {
  GetAllReturnReasons,
  GetAllWarehouses,
  GetAllWarehousesDestiny,
  GetAllWarehouseTypes,
  GetMaintenanceList,

  GetWarehousesForStockMovements,
  PostStockTransfer
} from '../../../infra/requests/LogisticsRequests'
import AlertService from '../../../shared/components/alert/AlertService'
import BackButton from '../../../shared/components/buttons/BackButton'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import SelectInput from '../../../shared/components/inputs/SelectInput'
import { ConstructQuery } from '../../../shared/logic/queries/EndpointQueries'
import {
  Margin,
  PageForm,
  PageTitle,
  TitleH3
} from '../../../shared/styles/BasicStyles'
import StockTransferSelect from './StockTransferSelect'
import StockTransferTable from './StockTransferTable'

const StockTransfer = ({
  handleSubmit,
  dispatch,
  translate,
  router,
  formValues
}) => {
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [filters, setFilters] = useState({})
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [warehouses, setWarehouses] = useState([])
  const [warehousesDestiny, setWarehousesDestiny] = useState([])
  const [warehouseType, setWarehouseType] = useState()
  const [returnreason, setReturnReason] = useState()
  const [warehousesto, setWarehousesTo] = useState([])
  const [originalwarehouseId, setOriginalWarehouseId] = useState()
  const [destinationwarehouseId, setDestinationWarehouseId] = useState()
  const [returnreasonId, setReturnReasonId] = useState()
  const pageSize = 12

  useEffect(() => {
    async function initwarehousetype() {
      const { data, success } = await GetAllWarehouseTypes()
      if (success) setWarehouseType(data || [])
    }
    async function initreturnreason() {
      const { data, success } = await GetAllReturnReasons()
      if (success) setReturnReason(data?.items || [])
    }
    async function initwarehousesDestiny() {
      const { data, success } = await GetAllWarehousesDestiny()
      if (success) setWarehousesDestiny(data?.items || [])
    }
    initwarehousesDestiny()
    initwarehousetype()
    initreturnreason()
  }, [])

  const updateTable = async (p, f, state = true) => {
    setLoading(true)
    const data2 = {
      Page: p,
      itemsPerPage: pageSize,
      ...f,
      isStockTransfer: true
    }
    const { data } = await GetMaintenanceList(data2, state)
    dispatch(change('stock_transfer_form', 'items', data.items || []))
    setLoading(false)
    setTotal(data.totalItems || 0)
  }

  const onChangePagination = (newPage) => {
    setPage(newPage)
    updateTable(newPage, filters)
  }

  const onChangeSelect = async (value) => {
    const { data, success } = await GetWarehousesForStockMovements(value)
    if (success) {
      setWarehousesTo(data)
    }
    const newFilters = {
      ...filters,
      warehouse: value
    }
    setOriginalWarehouseId(value)
    setFilters(newFilters)
    setPage(1)
    updateTable(1, newFilters)
  }

  const onChangeSelectDestiny = (value) => {
    setDestinationWarehouseId(value)
  }

  const onChangeSelectRR = (value) => {
    setReturnReasonId(value)
  }

  const onChangeTypeField = async (key, value) => {
    const result = { ...filters }
    result[key] = value
    setFilters(result)
    const { data, success } = await GetAllWarehouses()
    let newdata = [{}]
    if (value === 'Productive') {
      if (success) {
        newdata = data.items.filter((wh) => wh.productive)
      }
    }
    if (value === 'Confection') {
      if (success) {
        newdata = data.items.filter((wh) => wh.confection)
      }
    }
    if (value === 'Materials') {
      if (success) {
        newdata = data.items.filter((wh) => wh.materials)
      }
    }
    if (value === 'Quarantine') {
      if (success) {
        newdata = data.items.filter((wh) => wh.quarantine)
      }
    }
    if (value === 'Defects') {
      if (success) {
        newdata = data.items.filter((wh) => wh.defects)
      }
    }
    setWarehouses(newdata)
  }

  const onSubmit = async (values) => {
    try {
      setSaving(true)
      if (values.items && values.items.length) {
        const payload = values.items
          .filter((item) => item.stockToMove)
          .map((item) => ({
            ProductId: item.productId,
            ColorId: item.colorId,
            SizeId: item.size.sizeId,
            ProductWidthId: item.productWidthId,
            OriginWarehouseId: originalwarehouseId,
            DestinationWarehouseId: destinationwarehouseId,
            Reason: '',
            ReasonId: returnreasonId,
            B2bclientId: item.stockOwnerId,
            Qty: item.stockToMove
          }))

        const { success } = await PostStockTransfer(payload)
        if (success) {
          AlertService.success(
            translate('MOVEMENT_STOCK_SAVED'),
            translate('CHANGES_SAVED_SUCCESSFULLY')
          )
        }
      }
      updateTable(1, filters)
      setSaving(false)
    } catch (error) {
      console.warn(error)
    }
  }

  return (
    <PageForm>
      <Row gutter={[0, 50]}>
        <Col xs={24}>
          <BackButton
            label={<Translate id='BACK' />}
            onClick={() => router.history.push('/logistics#main')}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <PageTitle>
            <Translate id='STOCK_TRANSFERS_REPAIR' />
          </PageTitle>
        </Col>

        <Col xs={12} style={{ textAlign: 'right' }}>
          <BaseButton
            type='primary'
            auto
            htmlType='button'
            disabled={!formValues?.items?.length}
            onClick={handleSubmit(onSubmit)}
            loading={saving}
          >
            <Translate id='SAVE' />
          </BaseButton>
        </Col>
      </Row>
      <Margin size={47} />
      <Row gutter={[40, 20]}>
        <Col xs={6}>
          <SelectInput
            label={<Translate id='TYPE' />}
            input={{
              value: filters?.label,
              onChange: (value) => onChangeTypeField('label', value)
            }}
            placeholder={<Translate id='SELECT' />}
            data={warehouseType}
            dataKey='label'
            dataLabel='warehouseTypeTranslation'
          />
        </Col>
        <Col xs={6}>
          <StockTransferSelect
            input={{
              value: filters.originalwarehouseId,
              onChange: onChangeSelect
            }}
            warehouses={warehouses}
            disabled={!filters.label}
          />
        </Col>
      </Row>
      <Margin size={27} />
      <Row>
        <Col xs={24}>
          <TitleH3 $op>
            <Translate id='PRODUCTS' />
          </TitleH3>
        </Col>
      </Row>
      <Margin size={27} />
      <Row>
        <Col xs={5}>
          <BaseButton auto type='secondary'>
            <Translate id='WRITTEN_OFF_STOCK' />
          </BaseButton>
        </Col>
      </Row>
      <Margin size={29} />
      <Row gutter={[40, 20]}>
        <Col xs={6}>
          <StockTransferSelect
            input={{
              value: filters?.destinationwarehouseId,
              onChange: onChangeSelectDestiny
            }}
            warehouses={warehousesDestiny}
            disabled={!filters.originalwarehouseId || !filters.label}
          />
        </Col>
        <Col xs={6}>
          <SelectInput
            label={<Translate id='RETURN_REASON' />}
            input={{
              value: filters?.returnreason,
              onChange: onChangeSelectRR
            }}
            placeholder={<Translate id='SELECT' />}
            data={returnreason}
            dataKey='returnReasonId'
            dataLabel='returnReasonTranslation'
          />
        </Col>
      </Row>
      <Margin size={33} />
      <FieldArray
        component={StockTransferTable}
        name='items'
        page={page}
        pageSize={pageSize}
        total={total}
        onChangePagination={onChangePagination}
        loading={loading}
      />
    </PageForm>
  )
}

StockTransfer.propTypes = {
  router: PropTypes.object.isRequired,
  formValues: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
}

StockTransfer.defaultProps = {
  formValues: {}
}

const StockTransferPage = reduxForm({
  form: 'stock_transfer_form'
})(StockTransfer)

export default withLocalize(connect((state) => ({
  formValues: getFormValues('stock_transfer_form')(state)
}))(StockTransferPage))
