import React, {useState} from 'react'

import {CheckOutlined} from '@ant-design/icons'
import {Dropdown, Menu} from 'antd'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import ActiveTranslation from '../../../../../shared/logic/translations/ActiveTranslation'
import {
  PrimaryButtonColor,
  BorderColor
} from '../../../../../shared/styles/_colors'
import {Translate} from 'react-localize-redux'

const SelectBox = styled.div`
  display: inline-block;
  width: 100%;
  background: white;
  border: 1px solid ${BorderColor};
  text-align: left;
  font-size: 14px;
  line-height: 26px;
  border-radius: 4px;
  cursor: pointer;
  height: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 26px;
  height: 26px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  padding: 0 5px;
  word-break: break-all;

  &:hover,
  &:focus,
  &:visited,
  &:active {
    border: 1px solid ${PrimaryButtonColor};
    outline: none;
  }
`

const ColorsMenu = styled.div`
  background: white;
  min-width: 120px;
  max-width: 200px;
`

const MenuItem = styled.button`
  display: inline-block;
  width: 100%;
  padding: 5px 12px;
  padding-right: 35px;
  background: ${({$selected}) => ($selected ? '#e6f7ff' : 'white')};
  font-weight: ${({$selected}) => ($selected ? 'bold' : 'normal')};
  cursor: pointer;
  position: relative;
  font-size: 14px;
  border: none;
  outline: none;
  text-align: left;

  &:hover {
    background: #e6f7ff;
    font-weight: bold;
  }

  &:disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
    background: white;
    font-weight: normal;
  }
`

const CheckIcon = styled(CheckOutlined)`
  position: absolute;
  top: 9px;
  right: 12px;
  font-size: 14px;
  color: ${PrimaryButtonColor};
`

const SelectMultipleColors = ({input, colors}) => {
  const [all, setAll] = useState(input.value.length === colors.length)
  const selected = Array.isArray(input.value) ? input.value : []

  const handleSelect = (e, colorId, trigger) => {
    e.stopPropagation()
    if (trigger) {
      input.onChange(selected.filter((s) => s !== colorId))
    } else {
      const list = [...selected, colorId]
      input.onChange(list)
      if (list.length === colors.length) setAll(true)
    }
  }

  const onSelectAll = (e) => {
    e.stopPropagation()
    if (all) {
      setAll(false)
      input.onChange([])
    } else {
      setAll(true)
      input.onChange(colors.map(({colorId}) => colorId))
    }
  }

  const Options = (
    <ColorsMenu>
      <MenuItem onClick={onSelectAll}>
        <Translate id='ALL' />
        {all && <CheckIcon />}
      </MenuItem>
      {colors.map((color) => {
        const trigger = selected.includes(color.colorId)
        return (
          <MenuItem
            key={color.colorId}
            $selected={trigger}
            disabled={all}
            onClick={(e) => handleSelect(e, color.colorId, trigger)}
          >
            <ActiveTranslation value={color.colorTranslation} tag='name' />
            {trigger && !all && <CheckIcon />}
          </MenuItem>
        )
      })}
    </ColorsMenu>
  )

  const renderLabel = () => {
    if (selected.length === 0) return ''
    if (all) return <Translate id='ALL' />
    return selected.map((colorId, index) => {
      const found = colors.find((c) => c.colorId === colorId)
      const name = (
        <ActiveTranslation value={found?.colorTranslation} tag='name' />
      )
      return index < selected.length - 1 ? (
        <span key={colorId}>{name}, </span>
      ) : (
        <span key={colorId}>{name}</span>
      )
    })
  }

  return (
    <Dropdown overlay={Options} placement='bottomLeft' trigger={['click']}>
      <SelectBox>{renderLabel()}</SelectBox>
    </Dropdown>
  )
}

SelectMultipleColors.propTypes = {
  input: PropTypes.object.isRequired,
  colors: PropTypes.array.isRequired
}

export default SelectMultipleColors
