import styled from 'styled-components'

import {
  PrimaryButtonColor,
  SecondaryButtonColor,
  PrimaryHoverButtonColor,
  DisableButtonColor,
  SecondaryHoverButtonColor,
  TableTextColor,
  BorderColor,
  ErrorColor,
  ErrorHoverColor
} from '../../styles/_colors'
import { BookFontWeight, BodyText, ButtonText } from '../../styles/_texts'

export const StyledButtonImg = styled.img`
  font-size: 16px;
  max-width: 16px;
  max-height: 16px;
  margin-right: 10px;

  @media (max-width: 480px) {
    max-width: 14px;
  max-height: 14px;
  }
`

const renderButtonTypes = ({ styleType, disabled, loading }) => {
  if (disabled || loading === 'true') {
    return `
      color: ${TableTextColor};
      background-color: ${DisableButtonColor}; 
      border: 1px solid ${BorderColor};
      opacity: 50%;
  `
  }
  if (styleType === 'primary') {
    return `
      color: #FFFFFF;
      background-color: ${PrimaryButtonColor}; 
      cursor: pointer;
      border: 1px solid ${PrimaryButtonColor};
    
  
      &:hover{
        background-color: ${PrimaryHoverButtonColor}; 
      }
    `
  }
  if (styleType === 'secondary') {
    return `
      color: ${TableTextColor};
      background-color: ${SecondaryButtonColor}; 
      cursor: pointer;
      border: 1px solid ${SecondaryButtonColor};

      &:hover{
        background-color: ${SecondaryHoverButtonColor}; 
      }
    `
  }
  if (styleType === 'error') {
    return `
      color: #FFFFFF;
      background-color: ${ErrorColor}; 
      cursor: pointer;
      border: 1px solid ${ErrorColor};

      &:hover{
        background-color: ${ErrorHoverColor}; 
      }
    `
  }
  return undefined
}
export const StyledButton = styled.button`
  width: ${({ $auto }) => ($auto ? 'auto' : '100%')};
  border-radius: 20px;
  border: none;
  display: inline-block;
  ${renderButtonTypes}

  &:focus {
    outline: none;
  }

  .anticon:not(.anticon-loading) {
    margin-right: 10px;
  }
`

export const StyledBordelessButton = styled.button`
  border-radius: 20px;
  border: none;
  display: inline-block;
  width: ${({ $auto }) => ($auto ? 'auto' : '100%')};
  cursor: pointer;
  background-color: transparent;

  &:focus {
    outline: none;
  }
`

export const StyledBordelessButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: ${BookFontWeight};
  max-height: 16px;
  margin: 6px 0px;
`

const renderSizesContent = ({ size }) => {
  if (size === 'small') {
    return `
      max-height: 20px; 
      margin: 0px 23px;
      font-size: ${BodyText};
    `
  }
  return `
    max-height: 16px; 
    margin: 8px 20px;
    font-size: ${ButtonText};
  `
}

export const StyledButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: ${BookFontWeight};
  ${renderSizesContent}

  @media (max-width: 768px) {
    font-size: 14px; 
  }

  @media (max-width: 480px) {
    font-size: 13px; 
  }
  @media (max-width: 404px) {
    font-size: 11px; 
  }
`

export const BackButtonStyle = styled.a`
  background-color: transparent;
  cursor: pointer;
  display: inline-block;
  padding: 5px 0;
  color: ${TableTextColor};
  opacity: 0.3;

  div {
    float: left;
  }

  svg {
    margin-top: 5px;
    margin-right: 10px;
  }

  &:hover,
  &:active {
    color: ${TableTextColor};
    opacity: 0.1;
  }
`
export const NextButtonStyle = styled.a`
  background-color: transparent;
  cursor: pointer;
  display: inline-block;
  padding: 5px 0;
  color: ${TableTextColor};
  opacity: 0.3;

  div {
    float: left;
  }

  svg {
    margin-top: 5px;
    margin-right: 10px;
  }

  &:hover,
  &:active {
    color: ${TableTextColor};
    opacity: 0.1;
  }
`
