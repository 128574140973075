import React, { Component } from 'react';
import { Row, Col } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';

import { GetMaintenanceList } from '../../../infra/requests/LogisticsRequests';
import BaseButton from '../../../shared/components/buttons/BaseButton';
import ProductLink from '../../../shared/components/links/ProductLink';
import BaseTable from '../../../shared/components/table/BaseTable';
import { ConstructQuery } from '../../../shared/logic/queries/EndpointQueries';
import ActiveTranslation from '../../../shared/logic/translations/ActiveTranslation';
import {
  Margin,
  PageTitle,
  StyledHR
} from '../../../shared/styles/BasicStyles';
import MaintenanceFilters from './MaintenanceFilters';
import MovementPopup from './MovementPopup';
import SkuPopup from './SkuPopup';
import ValuePopup from './ValuePopup';
import WarehousePopup from './WarehousePopup';

let timeout;

class MaintenanceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      rows: [],
      page: 1,
      pageSize: 20,
      total: 0,
      filters: {},
      tableKey: 0 // Unique key to force re-render
    };
  }

  componentDidMount = async () => {
    try {
      this.updateTable();
    } catch (error) {
      console.warn(error);
    }
  }

  updateTable = async () => {
    const { page, pageSize, filters } = this.state;
    this.setState({ loading: true });

    const data = {
      page,
      itemsPerPage: pageSize,
      ...filters // Spread the filters object to add all filter parameters dynamically
    };

    const response = await GetMaintenanceList(data);
    const { items, totalItems } = response.data;

    this.setState({
      rows: items || [],
      total: totalItems || 0,
      loading: false,
      tableKey: this.state.tableKey + 1 // Update the key to force re-render
    });
  }

  onChangePagination = (page) => this.setState({ page }, this.updateTable);

  setFilters = (values, time) => {
    this.setState({ filters: values, page: 1 }, () => {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(this.updateTable, time);
    });
  }

  getColumns = () => [
    {
      title: <Translate id='MOVEMENT_ID' />,
      type: 'text',
      dataIndex: 'movementId',
      render: (value) => value || <Translate id='NA' />
    },
    {
      title: <Translate id='MOVEMENT_TYPE' />,
      type: 'text',
      dataIndex: 'movementTypeName',
      render: (value) => (
        <ActiveTranslation
          value={value}
          tag='name'
        />
      )
    },
    {
      title: "Origin Warehouse",
      type: 'text',
      dataIndex: 'originWarehouseName',
      render: (value, record) => (
        <WarehousePopup
          name={record.originWarehouseName}
          code={record.originWarehouseCode}
        />
      )
    },
    {
      title: "Destiny Warehouse",
      type: 'text',
      dataIndex: 'destinyWarehouseName',
      render: (value, record) => (
        <WarehousePopup
          name={record.destinyWarehouseName}
          code={record.destinyWarehouseCode}
        />
      )
    },
    {
      title: <Translate id='REFERENCE' />,
      type: 'text',
      dataIndex: '',
      render: (value) => <SkuPopup item={value} />
    },
    {
      title: <Translate id='DESCRIPTION' />,
      type: 'text',
      render: (value) => <ProductLink product={value} router={this.props.router} />
    },
    {
      title: <Translate id='MOVEMENT_QTY' />,
      type: 'text',
      dataIndex: '',
      render: (value) => <MovementPopup item={value} />
    },
    {
      title: <Translate id='TOTAL_VALUE' />,
      type: 'text',
      dataIndex: '',
      render: (value) => <ValuePopup item={value} />
    },
    {
      title: <Translate id='DATE' />,
      type: 'text',
      dataIndex: 'date',
      render: (value) => moment(value).format('DD/MM/YYYY')
    }
  ]

  render() {
    const { loading, rows, page, pageSize, total, filters, tableKey } = this.state;
    const { router } = this.props;

    return (
      <>
        <Row>
          <Col xs={24}>
            <PageTitle>
              <Translate id='MOVEMENT_HISTORY' />
            </PageTitle>
          </Col>
        </Row>
        <Margin size={20} />
        <Row>
          <Col xs={5}>
            <BaseButton
              auto
              type='primary'
              onClick={() =>
                router.history.push('/logistics/maintenance/inventory')
              }
            >
              <Translate id='INVENTORY_COUNT' />
            </BaseButton>
          </Col>
          <Col xs={6}>
            <BaseButton
              auto
              type='primary'
              onClick={() =>
                router.history.push('/logistics/maintenance/stockTransfer')
              }
            >
              <Translate id='STOCK_TRANSFERS_REPAIR' />
            </BaseButton>
          </Col>
        </Row>
        <StyledHR $space='20' />

        <MaintenanceFilters
          filters={filters}
          setFilters={this.setFilters}
        />
        <Margin size={15} />
        <Row>
          <Col xs={24}>
            <BaseTable
              key={tableKey} // Use the key to force re-render
              rowKey='movementId'
              datasource={rows}
              columns={this.getColumns()}
              pagination={{
                render: true,
                page,
                pageSize,
                total,
                onChangePagination: this.onChangePagination
              }}
              loading={loading}
            />
          </Col>
        </Row>
      </>
    );
  }
}

MaintenanceList.propTypes = {
  router: PropTypes.object.isRequired
};

export default MaintenanceList;
