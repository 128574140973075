import React from 'react'

import PropTypes from 'prop-types'

import {
  CollapsableContainer,
  CollapsableName,
  CollapsableDescription
} from './POStyles'

const CollapsableHeaderTitle = ({
  name,
  description,
  last,
  left,
  max,
  stopPropagation
}) => (
  <CollapsableContainer
    $max={max}
    $left={left}
    $last={last}
    onClick={(e) => (stopPropagation ? e.stopPropagation() : null)}
  >
    <CollapsableName>{name}</CollapsableName>
    <CollapsableDescription>{description}</CollapsableDescription>
  </CollapsableContainer>
)

CollapsableHeaderTitle.propTypes = {
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.element
  ]),
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
    PropTypes.node
  ]),
  last: PropTypes.bool,
  left: PropTypes.bool,
  max: PropTypes.number,
  stopPropagation: PropTypes.bool
}

CollapsableHeaderTitle.defaultProps = {
  name: 'Title',
  description: 'null',
  last: false,
  left: false,
  max: 200,
  stopPropagation: false
}

export default CollapsableHeaderTitle
