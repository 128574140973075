import React from 'react'

import {Progress} from 'antd'
import PropTypes from 'prop-types'

import {PrimaryColor, ProgressBarColor} from '../../styles/_colors'

export const ProgressBar = ({
  value,
  showInfo,
  status,
  strokeLinecap,
  success,
  trailColor,
  strokeColor,
  size
}) => (
  <Progress
    showInfo={showInfo}
    size={size}
    percent={value}
    status={status}
    strokeLinecap={strokeLinecap}
    success={success}
    strokeColor={strokeColor}
    trailColor={trailColor}
    type='line'
  />
)

ProgressBar.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  showInfo: PropTypes.bool,
  success: PropTypes.object,
  status: PropTypes.oneOf(['normal', 'success', 'exception', 'active']),
  strokeLinecap: PropTypes.oneOf(['round', 'square']),
  strokeColor: PropTypes.string,
  trailColor: PropTypes.string,
  size: PropTypes.oneOf(['normal', 'small', 'large'])
}

ProgressBar.defaultProps = {
  showInfo: false,
  success: {},
  status: 'normal',
  size: 'normal',
  strokeLinecap: 'round',
  strokeColor: PrimaryColor,
  trailColor: ProgressBarColor
}

export default ProgressBar
