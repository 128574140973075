import React, {useState, useEffect} from 'react'

import {Row, Col} from 'antd'
import moment from 'moment'
import {reduxForm, Field, initialize} from 'redux-form'
import styled from 'styled-components'
import {
  VictoryChart,
  VictoryTheme,
  VictoryBar,
  VictoryGroup,
  VictoryLabel,
  VictoryAxis,
  VictoryLine
} from 'victory'

import {
  GetForecastChart,
  GetFakeProductCombination,
  GetFakeProducts
} from '../../../infra/requests/LogisticsRequests'
import DateInput from '../../../shared/components/inputs/DateInput'
import SelectInput from '../../../shared/components/inputs/SelectInput'

const ChartComponent = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`

const ChartLabel = styled.div`
  position: absolute;
  top: 55px;
  right: 35px;
  padding: 10px;
  background: white;
`

const LabelLine = styled.div`
  display: block;
  position: relative;
  padding-left: 28px;

  ::after {
    position: absolute;
    content: '';
    top: 3px;
    left: 3px;
    width: 16px;
    height: 16px;
    background: ${({$color}) => $color};
  }
`

const ForecastPage = (dispatch, handleSubmit) => {
  const [refs, setRefs] = useState([])
  const [skus, setSKUs] = useState([])
  const [forecastData, setForecastData] = useState([])
  const [thisdates, setThisDates] = useState([])
  const [maxValue, setMaxValue] = useState(0)
  useEffect(() => {
    async function fetchRef() {
      const {data} = await GetFakeProducts()
      setRefs(data?.items)
    }

    fetchRef()
  }, [])

  const onchangeReference = async (value) => {
    const {data} = await GetFakeProductCombination(value)
    setSKUs(data?.items)
  }

  const onchangeSKU = async (value) => {
    const {data} = await GetForecastChart(value)
    setForecastData(data?.items)
    setThisDates(data?.items.map((x) => x.date))
  }

  return (
    <Row gutter={[24, 24]}>
      <Col xs={6}>
        <SelectInput
          allowClear={false}
          label='Product'
          input={{
            value: undefined,
            onChange: (value) => onchangeReference(value)
          }}
          placeholder='Select'
          data={refs}
          dataKey='fakeproductId'
          dataLabel='fakereferenceCode'
        />
      </Col>
      <Col xs={6}>
        <SelectInput
          allowClear={false}
          label='SKU'
          input={{
            value: undefined,
            onChange: (value) => onchangeSKU(value)
          }}
          placeholder='Select'
          data={skus}
          dataKey='fakeproductCombinationId'
          dataLabel='fakereference'
        />
      </Col>
      <Col xs={6}>
        <DateInput
          label='From'
          allowClear={false}
          input={{
            value: '01/01/2021',
            onChange: (value) => console.log(value)
          }}
        />
      </Col>
      <Col xs={6}>
        <DateInput
          label='To'
          allowClear={false}
          input={{
            value: '12/31/2021',
            onChange: (value) => console.log(value)
          }}
        />
      </Col>
      <Col xs={24}>
        <ChartComponent>
          <VictoryChart theme={VictoryTheme.material} width={600}>
            <VictoryAxis
              scale='time'
              label='Time'
              style={{axisLabel: {fontSize: 12, padding: '35'}}}
              standalone={false}
              tickValues={forecastData}
              tickFormat={(x) =>
                moment(x, 'DD/MM/YYYY').format('MMM YYYY')
              }
              tickLabelComponent={
                <VictoryLabel
                  text={({index}) =>
                    index % 2
                      ? moment(thisdates[index], 'YYYY-MM-DD')
                        .format('MMM YYYY')
                        .split(' ')
                      : ''
                  }
                  style={[
                    {fill: '#231F20', fontSize: 9},
                    {fill: '#231F20', opacity: '50%', fontSize: 9}
                  ]}
                />
              }
            />
            <VictoryAxis
              dependentAxis
              standalone={false}
              style={{axisLabel: {fontSize: 12, padding: '35'}}}
              tickLabelComponent={<VictoryLabel style={{fontSize: 9}} />}
              label='Units'
              domain={{y: [0, 300]}}
            />
            <VictoryGroup offset={1}>
              <VictoryLine
                interpolation='cardinal'
                style={{data: {stroke: '#0065B5', strokeWidth: 1}}}
                x='date'
                y='cost'
                data={forecastData}
              />
              <VictoryLine
                style={{
                  data: {
                    stroke: '#000',
                    strokeWidth: 1,
                    strokeDasharray: [2, 1]
                  }
                }}
                x='date'
                y='safety'
                data={forecastData}
              />
              <VictoryLine
                style={{
                  data: {
                    stroke: '#39B54A',
                    strokeWidth: 1,
                    strokeDasharray: [2, 1]
                  }
                }}
                x='date'
                y='security'
                data={forecastData}
              />
              <VictoryLine
                interpolation='stepBefore'
                style={{
                  data: {
                    stroke: 'orange',
                    strokeWidth: 1,
                    strokeDasharray: [4, 2]
                  }
                }}
                x='date'
                y='injection'
                data={forecastData}
              />
              <VictoryLine
                style={{
                  data: {
                    stroke: 'purple',
                    strokeWidth: 1
                  }
                }}
                x='date'
                y='second'
                data={forecastData}
              />
            </VictoryGroup>
            <VictoryGroup offset={2}>
              <VictoryBar
                style={{data: {width: 1, fill: '#c43a31'}}}
                y='vert1'
                data={forecastData}
              />
            </VictoryGroup>
          </VictoryChart>
          <ChartLabel>
            <LabelLine $color='#0065B5'>Current Stock</LabelLine>
            <LabelLine $color='#39B54A'>Security Stock</LabelLine>
            <LabelLine $color='#000'>Min Stock</LabelLine>
            <LabelLine $color='#c43a31'>Order Points</LabelLine>
            <LabelLine $color='orange'>Stock After Order</LabelLine>
            <LabelLine $color='purple'>Consumption</LabelLine>
          </ChartLabel>
        </ChartComponent>
      </Col>
    </Row>
  )
}
ForecastPage.propTypes = {}

export default ForecastPage
