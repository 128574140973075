import React, {useState} from 'react'

import {Col, Row} from 'antd'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Field} from 'redux-form'
import {Translate} from 'react-localize-redux'
import Add from '../../assets/icons/add_bordered_black.svg'
import BaseButton from '../../shared/components/buttons/BaseButton'
import CheckboxInput from '../../shared/components/inputs/CheckboxInput'
import BaseTable from '../../shared/components/table/BaseTable'
import {Margin} from '../../shared/styles/BasicStyles'

const PermissionsTab = ({fields, dispatch}) => {
  const [selected, setSelected] = useState(false)

  const permissions = fields.getAll() || []

  const getColumnsPermissions = () => [
    {
      dataIndex: 'module',
      type: 'text',
      title: <Translate id='MODULE' />
    },
    {
      dataIndex: 'seeAll',
      title: <Translate id='SEE_ALL' />,
      render: (text, row, index) => (
        <Field
          component={CheckboxInput}
          name={`permissions[${index}].seeAll`}
        />
      )
    },
    {
      dataIndex: 'seeSelf',
      title: <Translate id='SEE_SELF' />,
      render: (text, row, index) => (
        <Field
          component={CheckboxInput}
          name={`permissions[${index}].seeAll`}
        />
      )
    },
    {
      dataIndex: 'editAll',
      title: <Translate id='EDIT_ALL' />,
      render: (text, row, index) => (
        <Field
          component={CheckboxInput}
          name={`permissions[${index}].editAll`}
        />
      )
    },
    {
      dataIndex: 'editSelf',
      title: <Translate id='EDIT_SELF' />,
      render: (text, row, index) => (
        <Field
          component={CheckboxInput}
          name={`permissions[${index}].editSelf`}
        />
      )
    },
    {
      dataIndex: 'createAll',
      title: <Translate id='CREATE_ALL' />,
      render: (text, row, index) => (
        <Field
          component={CheckboxInput}
          name={`permissions[${index}].createAll`}
        />
      )
    },
    {
      dataIndex: 'createSelf',
      title: <Translate id='CREATE_SELF' />,
      render: (text, row, index) => (
        <Field
          component={CheckboxInput}
          name={`permissions[${index}].createSelf`}
        />
      )
    }
  ]

  const onRowSelection = (data) => setSelected(data)

  return (
    <>
      <Row>
        <Col xs={24}>
          <BaseButton imagePath={Add} auto type='secondary'>
            <Translate id='ADD_NEW_MODULE' />
          </BaseButton>
        </Col>
      </Row>
      <Margin size={17} />
      <Row>
        <Col xs={24}>
          <BaseTable
            rowKey='id'
            datasource={permissions}
            columns={getColumnsPermissions()}
            rowSelection={{
              selected,
              onRowSelection
            }}
            pagination={{render: false}}
          />
        </Col>
      </Row>
    </>
  )
}

PermissionsTab.propTypes = {
  dispatch: PropTypes.func.isRequired
}

export default connect()(PermissionsTab)
