export const TransformOrderShippingAddress = (formValues) => ({
  name: formValues?.deliveryName,
  addressLine1: formValues?.deliveryAddressLine1,
  addressLine2: formValues?.deliveryAddressLine2,
  addressLine3: formValues?.deliveryAddressLine3,
  city: formValues?.deliveryCity,
  countryId: formValues?.deliveryCountryId,
  region: formValues?.deliveryRegion,
  zipCode: formValues?.deliveryZipCode,
  streetNumber: formValues?.deliveryStreetNumber
})

export const TransformOrderBillingAddress = (formValues) => ({
  name: formValues?.billingName || 'Billing info',
  addressLine1: formValues?.billingAddressLine1,
  addressLine2: formValues?.billingAddressLine2,
  addressLine3: formValues?.billingAddressLine3,
  city: formValues?.billingCity,
  countryId: formValues?.billingCountryId,
  region: formValues?.billingRegion,
  zipCode: formValues?.billingZipCode,
  streetNumber: formValues?.billingStreetNumber
})

export const TransformAddressToOrder = (addressData) => ({
  billingContactDetailId: addressData?.contactDetailId || null,
  billingName: addressData?.name || null,
  billingAddressLine1: addressData?.addressLine1,
  billingAddressLine2: addressData?.addressLine2,
  billingAddressLine3: addressData?.addressLine3,
  billingStreetNumber: addressData?.streetNumber,
  billingZipCode: addressData?.zipCode,
  billingCity: addressData?.city,
  billingRegionId: addressData?.regionId,
  billingRegion: addressData?.region,
  billingCountryId: addressData?.countryId,
  billingVat: addressData?.vatnumber,
  billingDataSet: addressData != undefined,
  deliveryContactDetailId: addressData?.contactDetailId,
  deliveryName: addressData?.name,
  deliveryAddressLine1: addressData?.addressLine1,
  deliveryAddressLine2: addressData?.addressLine2,
  deliveryAddressLine3: addressData?.addressLine3,
  deliveryStreetNumber: addressData?.streetNumber,
  deliveryZipCode: addressData?.zipCode,
  deliveryCity: addressData?.city,
  deliveryRegionId: addressData?.regionId,
  deliveryRegion: addressData?.region,
  deliveryCountryId: addressData?.countryId,
  deliveryTelephone: addressData?.telephone,
  deliveryMobile: addressData?.mobile
})
