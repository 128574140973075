import React, { Component } from 'react'

import { Popover, Row, Col } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Translate, withLocalize } from 'react-localize-redux'

import DropdownIcon from '../../../assets/icons/dropdown_blue.svg'
import AssociateProcessIcon from '../../../assets/icons/unarchive.svg'
import { GetOngoingProcesses } from '../../../infra/requests/MessagingRequests'
import DateRangeInput from '../../../shared/components/inputs/DateRangeInput'
import PriorityInput from '../../../shared/components/inputs/PriorityInput'
import SearchInput from '../../../shared/components/inputs/SearchInput'
import BaseTable from '../../../shared/components/table/BaseTable'
import ConstructQuery from '../../../shared/logic/queries/EndpointQueries'
import {
  StyledFlex,
  PopOverTitle,
  PopOverDescription,
  ToggleImageContainer,
  ToggleImage,
  Margin
} from '../../../shared/styles/BasicStyles'
import IncomingButton from '../incoming/IncomingButton'
import { BottomIncoming } from '../MessagingStyles'

let timeout

class Ongoing extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      rows: [],
      page: 1,
      pageSize: 20,
      total: 0,
      filters: {}
    }
    this.columns = [
      {
        dataIndex: 'number',
        title: <Translate id='PROCESS_ID' />
      },
      {
        dataIndex: 'destination',
        type: 'text',
        title: <Translate id='TO' />,
        render: (value, row) => {
          const content = (
            <StyledFlex $align='flex-start' style={{ padding: '10px 20px' }}>
              <PopOverTitle>
                <Translate id='FROM' />:
              </PopOverTitle>
              <PopOverDescription>{row.origin}</PopOverDescription>
              <PopOverTitle>
                <Translate id='TO' />:
              </PopOverTitle>
              <PopOverDescription>{row.destination}</PopOverDescription>
            </StyledFlex>
          )

          return (
            <Popover placement='topLeft' content={content} trigger='hover'>
              {value}
            </Popover>
          )
        }
      },
      {
        dataIndex: 'b2bclient',
        title: <Translate id='CLIENT' />,
        render: (value) => value?.name || 'N/A'
      },
      {
        dataIndex: 'subject',
        type: 'text',
        title: <Translate id='SUBJECT' />
      },
      {
        dataIndex: 'section',
        title: <Translate id='DEPARTMENT' />,
        render: (value) => value?.name || ''
      },
      {
        dataIndex: 'order',
        type: 'ordered',
        title: <Translate id='ORDER_NMR' />,
        render: (value) => (value && value[0] ? value[0].orderId : 'N/A')
      },
      {
        dataIndex: 'dateUpdated',
        title: <Translate id='DATE' />,
        render: (value) => moment(value).format('DD/MM/YYYY')
      },
      {
        dataIndex: 'sla',
        title: <Translate id='PRIORITY' />,
        render: (value) => (
          <PriorityInput input={{ value: value?.order || 3 }} disabled />
        )
      }
    ]
  }

  componentDidMount() {
    const { onRef } = this.props

    this.updateTable()
    onRef(this)
  }

  componentWillUnmount() {
    const { onRef } = this.props
    onRef(undefined)
  }

  setFilters = (values, time) => {
    this.onChangePagination(1)
    this.setState({ filters: values }, () => {
      if (timeout) clearTimeout(timeout)
      timeout = setTimeout(this.updateTable, time)
    })
  }

  updateTable = async () => {
    const { filters, page, pageSize } = this.state
    this.setState({ loading: true })
    const { data } = await GetOngoingProcesses(
      page,
      pageSize,
      ConstructQuery(filters)
    )
    this.setState({
      rows: data?.items,
      total: data?.totalItems,
      loading: false
    })
  }

  onChangePagination = (page) => this.setState({ page }, this.updateTable)

  render() {
    const {
      rows,
      search,
      page,
      pageSize,
      total,
      dateRange,
      loading,
      filters
    } = this.state
    const { router, translate } = this.props

    return (
      <>
        <Row gutter={[24, 16]} justify='end'>
          <Col xs={24} md={24} lg={6}>
            <SearchInput
              input={{
                value: search,
                onChange: (value) =>
                  this.setFilters({ ...filters, search: value }, 1000),
                onSearch: () => this.setFilters({ ...filters, '': '' }, 0)
              }}
              placeholder={translate('SEARCH')}
            />
          </Col>
          <Col xs={24} md={24} lg={6}>
            <DateRangeInput
              input={{
                value: dateRange,
                onChange: (date) => {
                  this.setFilters(
                    {
                      ...filters,
                      StartDate: date ? date[0].startOf('day') : undefined,
                      EndDate: date ? date[1].endOf('day') : undefined
                    },
                    0
                  )
                }
              }}
            />
          </Col>
        </Row>
        <Margin size='16' />
        <Row gutter={[24, 16]}>
          <Col xs={24}>
            <BaseTable
              datasource={rows}
              onClickRow={(record) =>
                router.history.push(
                  `/messaging/process/${record.processId}`
                )
              }
              loading={loading}
              columns={this.columns}
              pagination={{
                render: true,
                page,
                pageSize,
                total,
                onChangePagination: this.onChangePagination
              }}
              highlightRow={(record) => record.seen}
              isWhiteTable
              rowKey='processId'
              expandable={{
                expandIcon: ({ expanded, onExpand, record }) => (
                  <ToggleImageContainer
                    role='button'
                    tabIndex={0}
                    onClick={(e) => onExpand(record, e)}
                  >
                    <ToggleImage
                      src={DropdownIcon}
                      $open={expanded}
                      $noMarginRight
                    />
                  </ToggleImageContainer>
                ),
                expandedRowRender: (record) => (
                  <>
                    <div
                      style={{ padding: '10px 20px' }}
                      dangerouslySetInnerHTML={{
                        __html:
                          record?.lastMessage?.bodyHtml ||
                          translate('NO_MESSAGE_SENT')
                      }}
                    />
                    <BottomIncoming>
                      <StyledFlex $direction='row'>
                        <IncomingButton
                          text={<Translate id='GO_PROCESS' />}
                          imgSource={AssociateProcessIcon}
                          onClick={() =>
                            router.history.push(
                              `/messaging/process/${record.processId}`
                            )
                          }
                        />
                      </StyledFlex>
                    </BottomIncoming>
                  </>
                )
              }}
            />
          </Col>
        </Row>
      </>
    )
  }
}

Ongoing.propTypes = {
  router: PropTypes.object.isRequired
}

export default withLocalize(Ongoing)
