import client from '../config/AxiosConfig'

export const GetInfoOrdersSmart = async () =>
  client.get('/OrderSmart/GetInfoOrdersSmart')

export const GetSmartOrders = async (page, pageSize, filters) =>
  client.get(
    `/OrderSmart/GetOrdersSmart?Page=${page}&ItemsPerPage=${pageSize}${filters}`
  )

export const GetSmartOrderDetails = async (id) =>
  client.get(`/OrderSmart/GetOrderSmart/${id}`)

export const SaveSmartOrder = async (id, data) =>
  client.put(`/OrderSmart/Update/${id}`, data)

export const ConvertSmartOrder = async (id) =>
  client.post(`/OrderSmart/ConvertToB2B/${id}`)
