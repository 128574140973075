import { Col, Row } from 'antd'
import styled from 'styled-components'

import { BookFontWeight, LabelText } from '../../shared/styles/_texts'

export const HighlightGroup = styled.div`
  width: 100%;
  display: inline-block;
  margin-top: 40px;
`

export const AdvancedSearchDescription = styled.div`
  text-align: center;
  color: #8d99ae;
  font-weight: ${BookFontWeight};
  font-size: ${LabelText};
  margin-bottom: 7px;
`
export const StyledRow = styled(Row)`
  margin-top: 20px;
  justify-content: space-between;
`

export const StyledOrderNotesCol = styled(Col)`
  align-content: end;
`
