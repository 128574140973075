import React from 'react'

import {Row, Col} from 'antd'
import {Translate} from 'react-localize-redux'
import {Field} from 'redux-form'
import styled from 'styled-components'

import NumberIncrementInput from '../../../../shared/components/inputs/NumberIncrementInput'
import {
  BorderColor,
  PrimaryButtonColor
} from '../../../../shared/styles/_colors'

const RegionContainer = styled.div`
  display: inline-block;
  width: 100%;
`

const RegionTitle = styled.div`
  background: white;
  border: 1px solid ${BorderColor};
  border-radius: 4px;
  text-align: center;
  color: ${PrimaryButtonColor};
  font-size: 16px;
  line-height: 23px;
  padding: 8px 0;
`

const TableSubtitle = styled.div`
  text-align: center;
  border-right: 1px solid
    ${({$border}) => ($border ? BorderColor : 'transparent')};
  margin: 4px 0;
  width: 100%;
  height: 22px;
`

const Line = styled(Row)`
  border: 1px solid ${BorderColor};
  border-radius: 4px;
  margin-top: 3px;
`

const CurrencyTitle = styled.div`
  text-align: center;
  line-height: 26px;
  border-right: 1px solid ${BorderColor};
  margin: 4px 0;
`

const RegionName = (item) => {
  if (item && item.worldRegionId) {
    if (item.worldRegionId === 1) return <Translate id='EUROPE' />
    if (item.worldRegionId === 2) return <Translate id='REGION_2' />
    if (item.worldRegionId === 3) return <Translate id='REST_OF_THE_WORLD' />
  }
  return <Translate id='UNDEFINED_REGION' />
}

const ShippingRatesTable = ({fields}) => {
  const items = fields.getAll() || []
  return (
    <Row gutter={[16, 24]}>
      {items.map((item, index) => (
        <Col key={index} xs={8}>
          <RegionContainer>
            <RegionTitle>{RegionName(item)}</RegionTitle>
            <Line>
              <Col xs={4}>
                <TableSubtitle $border />
              </Col>
              <Col xs={10}>
                <TableSubtitle $border><Translate id='BELOW_THRESHOLD' /></TableSubtitle>
              </Col>
              <Col xs={10}>
                <TableSubtitle><Translate id='ABOVE_THRESHOLD' /></TableSubtitle>
              </Col>
            </Line>
            <Line align='center' justify='center'>
              <Col xs={4}>
                <CurrencyTitle><Translate id='EUR' /></CurrencyTitle>
              </Col>
              <Col xs={10}>
                <TableSubtitle $border>
                  <Field
                    component={NumberIncrementInput}
                    width='80%'
                    name={`${fields.name}[${index}].costBelowThreshold`}
                  />
                </TableSubtitle>
              </Col>
              <Col xs={10}>
                <TableSubtitle>
                  <Field
                    component={NumberIncrementInput}
                    width='80%'
                    name={`${fields.name}[${index}].costAboveThreshold`}
                  />
                </TableSubtitle>
              </Col>
            </Line>
            {item.carrierRateCurrency &&
              item.carrierRateCurrency.map((line, lIndex) => (
                <Line align='center' justify='center' key={lIndex}>
                  <Col xs={4}>
                    <CurrencyTitle>{line?.currency?.code}</CurrencyTitle>
                  </Col>
                  <Col xs={10}>
                    <TableSubtitle $border>
                      <Field
                        component={NumberIncrementInput}
                        width='80%'
                        name={`${fields.name}[${index}].carrierRateCurrency[${lIndex}].costBelowThreshold`}
                      />
                    </TableSubtitle>
                  </Col>
                  <Col xs={10}>
                    <TableSubtitle>
                      <Field
                        component={NumberIncrementInput}
                        width='80%'
                        name={`${fields.name}[${index}].carrierRateCurrency[${lIndex}].costAboveThreshold`}
                      />
                    </TableSubtitle>
                  </Col>
                </Line>
              ))}
          </RegionContainer>
        </Col>
      ))}
    </Row>
  )
}

export default ShippingRatesTable
