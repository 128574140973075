import React from 'react'

import {Col, Row} from 'antd'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {Field} from 'redux-form'

import Delete from '../../../../../../assets/icons/cancel_border.svg'
import TextInput from '../../../../../../shared/components/inputs/TextInput'
import {Margin} from '../../../../../../shared/styles/BasicStyles'

const CharacteristicsValue = ({fields, languages}) => {
  const values = fields.getAll() || []

  const deleteValue = async (valueId, index) => {
    const value = values.find((x) => x.characteristicValueId === valueId)

    const newValue = {...value, deleted: true}
    fields.remove(index)
    fields.push(newValue)
  }

  return (
    <>
      {values
        .map(
          (item, index) =>
            !item.deleted && (
              <div
                style={{
                  backgroundColor: '#F1F1EF',
                  border: '0.3px solid #C6C8CA',
                  borderRadius: '4px',
                  marginBottom: 20,
                  padding: '20px 15px'
                }}
                key={item?.characteristicValueId || Math.random()}
              >
                <Row>
                  <Col xs={4}>
                    <Field
                      component={TextInput}
                      name={`${fields.name}[${index}].characteristicValueId`}
                      label={<Translate id='ID' />}
                      disabled
                    />
                  </Col>
                  <Col xs={20} style={{textAlign: 'right'}}>
                    <div
                      style={{cursor: 'pointer', marginRight: 5}}
                      onClick={() =>
                        deleteValue(item?.characteristicValueId, index)
                      }
                    >
                      <img src={Delete} alt={<Translate id='DELETE' />} />
                    </div>
                  </Col>
                </Row>
                <Margin size={13} />
                <Row gutter={[40, 20]}>
                  {languages.map((l, lIndex) => {
                    const ind = (
                      item.characteristicValueTranslation || []
                    ).findIndex((x) => x.languageId === l.languageId)
                    return (
                      <Col xs={8} key={lIndex}>
                        <Field
                          component={TextInput}
                          name={`${fields.name}[${index}].characteristicValueTranslation[${ind}].name`}
                          label={<Translate id={l.abrev.toUpperCase()} />}
                        />
                      </Col>
                    )
                  })}
                </Row>
              </div>
            )
        )}
    </>
  )
}

CharacteristicsValue.propTypes = {
  fields: PropTypes.object.isRequired,
  languages: PropTypes.array.isRequired
}

export default withLocalize(CharacteristicsValue)
