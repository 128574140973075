import MailConfigurationPage from '../../pages/menuSettings/mailConfiguration/MailConfigurationPage'
import ManageDepartmentPageMailConfig from '../../pages/menuSettings/mailConfiguration/tabs/manage/ManageDepartmentPage'
import ManageReceiveServerPage from '../../pages/menuSettings/mailConfiguration/tabs/manage/ManageReceiveServerPage'
import ManageSendServerPage from '../../pages/menuSettings/mailConfiguration/tabs/manage/ManageSendServerPage'
import MarketingPage from '../../pages/menuSettings/marketing/MarketingPage'
import ManageBannerPage from '../../pages/menuSettings/marketing/tabs/manage/ManageBannerPage'
import ManageExchangePage from '../../pages/menuSettings/marketing/tabs/manage/ManageExchangePage'
import ManageProductCharacteristicsPage from '../../pages/menuSettings/marketing/tabs/manage/ManageProductCharacteristicsPage'
import ManageVoucherPage from '../../pages/menuSettings/marketing/tabs/manage/ManageVoucherPage'
import ManageWorkgroupPage from '../../pages/menuSettings/marketing/tabs/manage/ManageWorkgroupPage'
import ProfilePage from '../../pages/menuSettings/profile/ProfilePage'
import SkyproConfigurationPage from '../../pages/menuSettings/skypro/SkyproConfigurationPage'
import ManageDepartmentPage from '../../pages/menuSettings/skypro/tabs/ManageDepartmentPage'
import ManageProfilePage from '../../pages/menuSettings/skypro/tabs/ManageProfilePage'
import ManageUserPage from '../../pages/users/ManageUserPage'

export default [
  {
    path: '/profile',
    component: ProfilePage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  },
  {
    path: '/skypro',
    component: SkyproConfigurationPage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  },
  {
    path: '/skypro/department/:id',
    component: ManageDepartmentPage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  },
  {
    path: '/skypro/department/new',
    component: ManageDepartmentPage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  },
  {
    path: '/skypro/profile/:id',
    component: ManageProfilePage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  },
  {
    path: '/skypro/profile/new',
    component: ManageProfilePage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  },
  {
    path: '/skypro/user/:id',
    component: ManageUserPage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  },
  {
    path: '/marketing',
    component: MarketingPage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  },
  {
    path: '/marketing/product/:id',
    component: ManageProductCharacteristicsPage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  },
  {
    path: '/marketing/product/new',
    component: ManageProductCharacteristicsPage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  },
  {
    path: '/marketing/image/workgroup/:id',
    component: ManageWorkgroupPage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  },
  {
    path: '/marketing/banner/:id',
    component: ManageBannerPage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  },
  {
    path: '/marketing/banner/new',
    component: ManageBannerPage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  },
  {
    path: '/marketing/vouchers/voucher/:id',
    component: ManageVoucherPage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  },
  {
    path: '/marketing/vouchers/voucher/new',
    component: ManageVoucherPage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  },
  {
    path: '/marketing/vouchers/exchange/:id',
    component: ManageExchangePage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  },
  {
    path: '/marketing/vouchers/exchange/new',
    component: ManageExchangePage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  },
  {
    path: '/mailConfiguration',
    component: MailConfigurationPage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  },
  {
    path: '/mailConfiguration/sendServer/:id',
    component: ManageSendServerPage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  },
  {
    path: '/mailConfiguration/department/:id',
    component: ManageDepartmentPageMailConfig,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  },
  {
    path: '/mailConfiguration/receiveServer/:id',
    component: ManageReceiveServerPage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  },
  {
    path: '/mailConfiguration/receiveServer/new',
    component: ManageReceiveServerPage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  },
  {
    path: '/mailConfiguration/user/:id',
    component: ManageUserPage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  },
  {
    path: '/mailConfiguration/department/new',
    component: ManageDepartmentPage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  },
  {
    path: '/mailConfiguration/user/new',
    component: ManageUserPage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  }
]
