import { AuthTokenKey } from '../../../infra/config/LocalStorageKeys'

const DownloadAttachment = (doc) => {
  const authToken = localStorage.getItem(AuthTokenKey)
  fetch(
    `${window.env.REACT_APP_API}/Attachments/DownloadAttachment/${doc.attachmentId}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    }
  )
    .then((resp) => resp.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = doc.name
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
    })
    .catch((e) => console.log(e))
}

export default DownloadAttachment
