import React, { useState } from "react";

import { Row, Col, Tooltip, Spin } from "antd";
import PropTypes from "prop-types";
import { withLocalize } from "react-localize-redux";
import { Field } from "redux-form";
import styled from "styled-components";

import CloseBorderedIcon from "../../../../../assets/icons/cancel_border.svg";
import { ValidateDepartureStock } from "../../../../../infra/requests/DepartureRequests";
import BaseButton from "../../../../../shared/components/buttons/BaseButton";
import NumberIncrementInput from "../../../../../shared/components/inputs/NumberIncrementInput";
import AddProductInput from "../../../../../shared/components/inputs/Products/AddProductInput";
import {
  TableRow,
  ListCol,
  TableTitle,
  TableInput,
  TextTooltip,
  TableText,
  ListCheck,
} from "../../../../../shared/components/table/CustomTableStyles";
import ActiveTranslation from "../../../../../shared/logic/translations/ActiveTranslation";
import { ErrorColor } from "../../../../../shared/styles/_colors";
import {
  PageSubtitle,
  Margin,
  AvailableTooltip,
} from "../../../../../shared/styles/BasicStyles";
import BaseLoading from "../../../../../shared/components/loading/BaseLoading";

const AvailableContainer = styled.div`
  position: relative;
  width: 100%;
`;

const DepartureProducts = ({
  fields,
  originWarehouse,
  disabled,
  translate,
  loading,
}) => {
  const [selected, setSelected] = useState([]);
  const products = fields.getAll() || [];

  const handleDisabled = (product) => {
    if (product.availableStock <= 0) {
      product.departQty = 0
      return true
    }
  };

  const addTableProducts = async (list) => {
    if (originWarehouse && originWarehouse.warehouseId) {
      const { data } = await ValidateDepartureStock({
        warehouseId: originWarehouse.warehouseId,
        skus: list.map((p) => ({
          productId: p.productId,
          productWidthId: p.productWidthId,
          sizeId: p.sizeId,
          colorId: p.colorId,
          units: p.quantity,
        })),
      });
      list.forEach((product) => {
        const found = products.find(
          (prod) => prod.reference === product.reference
        );
        const availability = data.find(
          (d) =>
            d.productId == product.productId &&
            d.productWidthId == product.productWidthId &&
            d.sizeId == product.sizeId &&
            d.colorId == product.colorId
        );
        if (!found) {
          fields.push({
            ...product,
            departQty: product.quantity,
            availableStock: parseInt(availability?.available || 0, 10),
          });
        }
      });
    } else {
      list.forEach((product) => {
        const found = products.find(
          (prod) => prod.reference === product.reference
        );
        if (!found) {
          fields.push({
            ...product,
            departQty: product.quantity,
          });
        }
      });
    }
  };

  const handleRemove = () => {
    selected
      .sort((a, b) => b - a)
      .forEach((index) => {
        fields.remove(index);
      });
    setSelected([]);
  };

  const handleCheck = (e, index) => {
    const copy = [...selected];
    if (e.target.checked) {
      copy.push(index);
      setSelected(copy);
    } else {
      setSelected(copy.filter((i) => i !== index));
    }
  };

  const showAvailableError = (product) => {
    // Check if availableStock property of the product is an integer
    return Number.isInteger(product.availableStock)
      // If availableStock is an integer, compare departQty with availableStock
      ? parseInt(product.departQty, 10) > parseInt(product.availableStock, 10)
      // If availableStock is not an integer, return false
      : false;
  };

  const showAvailableError2 = (product) => {
    // Check if availableStock property of the product is an integer
    return Number.isInteger(product.orderQuantity)
      // If availableStock is an integer, compare departQty with availableStock
      ? parseInt(product.orderQuantity || 0, 10) <
      parseInt(product.departQty || 0, 10)
      // If availableStock is not an integer, return false
      : false;
  };

  const renderAvailableInput = (product) => (
    <AvailableContainer>
      {product?.availableStock}
      {showAvailableError(product) && (
        <Tooltip title={translate("NO_AVAILABLE_STOCK")} color={ErrorColor}>
          <AvailableTooltip $error />
        </Tooltip>
      )}
    </AvailableContainer>
  );

  const renderAvailableError = (product) => {

    const orderQuantity = parseInt(product.orderQuantity || 0, 10);
    const departQty = parseInt(product.departQty || 0, 10);

    return (
      <AvailableContainer>
        {product?.orderQuantity}
        {orderQuantity < departQty && showAvailableError2(product) && (
          <Tooltip title={"Send quantity cant be bigger than the order quantity"} color={ErrorColor}>
            <AvailableTooltip $error />
          </Tooltip>
        )}
      </AvailableContainer>
    );
  };


  return (
    <>
      <PageSubtitle>Products</PageSubtitle>
      <Margin size={20} />
      <Row>
        <Col xs={24}>
          <BaseButton
            disabled={selected.length <= 0 || disabled}
            type="secondary"
            onClick={handleRemove}
            imagePath={CloseBorderedIcon}
            auto
          >
            Remove
          </BaseButton>
        </Col>
      </Row>
      <Margin size={20} />
      <TableRow $header>
        <ListCol xs={1} />
        <ListCol xs={3}>
          <TableTitle text="Reference" />
        </ListCol>
        <ListCol xs={2}>
          <TableTitle text="EAN Code" />
        </ListCol>
        <ListCol xs={2}>
          <TableTitle text="Client Ref" />
        </ListCol>
        <ListCol xs={5}>
          <TableTitle text="Description" />
        </ListCol>
        <ListCol xs={2}>
          <TableTitle text="Color" />
        </ListCol>
        <ListCol xs={2}>
          <TableTitle text="Size" />
        </ListCol>
        <ListCol xs={1}>
          <TableTitle text="Width" />
        </ListCol>
        <ListCol xs={2}>
          <TableTitle text="Available Quantity" abbr="Available" />
        </ListCol>
        <ListCol xs={2}>
          <TableTitle text="Order Quantity" abbr="Order Qty" />
        </ListCol>
        <ListCol xs={2}>
          <TableTitle text="Send Quantity" abbr="Send Qty" />
        </ListCol>
      </TableRow>

      <TableRow>
        <ListCol xs={1} />
        <ListCol xs={3}>
          <TableInput $first>
            <AddProductInput
              placeholder="Search"
              onSelectValues={addTableProducts}
              warehouseId={originWarehouse?.warehouseId}
              field="ref"
              disabled={disabled}
            />
          </TableInput>
        </ListCol>
        <ListCol xs={2} />
        <ListCol xs={2} />
        <ListCol xs={3}>
          <TableInput $first>
            <AddProductInput
              placeholder="Search"
              dataLabel="productTranslation"
              onSelectValues={addTableProducts}
              field="name"
              disabled={disabled}
            />
          </TableInput>
        </ListCol>
      </TableRow>

      {products.map((product, index) => (
        <TableRow key={index}>
          <ListCol xs={1}>
            {!product.orderB2bitemId && !product.orderSupplierId && (
              <ListCheck
                disabled={disabled}
                checked={selected.includes(index)}
                onChange={(e) => handleCheck(e, index)}
              />
            )}
          </ListCol>
          <ListCol xs={3}>
            <TextTooltip text={product.reference || "-"} />
          </ListCol>
          <ListCol xs={2}>
            <TextTooltip text={product.barcode || "-"} />
          </ListCol>
          <ListCol xs={2}>
            <TextTooltip text={product.referenceClient || "-"} />
          </ListCol>
          <ListCol xs={5}>
            <TextTooltip
              text={
                <ActiveTranslation value={product.description} tag="name" />
              }
            />
          </ListCol>
          <ListCol xs={2}>
            <TextTooltip
              text={
                <ActiveTranslation
                  value={product?.color?.colorTranslation || "-"}
                  tag="name"
                />
              }
            />
          </ListCol>
          <ListCol xs={2}>
            <TextTooltip text={product?.size?.nameEu || "-"} />
          </ListCol>
          <ListCol xs={1}>
            <TextTooltip text={product?.productWidth?.code || "-"} />
          </ListCol>
          <ListCol xs={2}>
            {loading ? (
              <TableText>
                <Spin />
              </TableText>
            ) : (
              <TableText>
                {Number.isInteger(product?.availableStock)
                  ? renderAvailableInput(product)
                  : "-"}
              </TableText>
            )}
          </ListCol>
          <ListCol xs={2}>
            <TableText>
              {product?.orderQuantity
                ? renderAvailableError(product)
                : "-"}</TableText>
          </ListCol>
          <ListCol xs={2}>
            <TableInput>
              <Field
                disabled={handleDisabled(product)}
                component={NumberIncrementInput}
                name={`${fields.name}[${index}].departQty`}
                step="1"
                showError={showAvailableError(product)}
              />
            </TableInput>
          </ListCol>
        </TableRow>
      ))}
    </>
  );
};

DepartureProducts.propTypes = {
  fields: PropTypes.object.isRequired,
};

export default withLocalize(DepartureProducts);
