export const GetTranslationFromArray = (value, LanguageID) => {
  if (!value || !Array.isArray(value) || value.length === 0) return ''
  const found = value.find(
    (elem) =>
      elem.languageID === LanguageID || elem.languageId === LanguageID
  )
  if (found) return found
  const en = value.find(
    (elem) => elem.languageID === 2 || elem.languageId === 2
  )
  if (en) return en
  return value[0]
}

export const languages = []

export default GetTranslationFromArray
