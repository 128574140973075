import React, {useEffect, useState} from 'react'

import {Col, Row} from 'antd'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {reduxForm, Field, initialize} from 'redux-form'

import {
  ChangeLostBagReminder,
  GetLostBagReminder
} from '../../../../infra/requests/MarketingRequests'
import AlertService from '../../../../shared/components/alert/AlertService'
import BaseButton from '../../../../shared/components/buttons/BaseButton'
import IntegerInput from '../../../../shared/components/inputs/IntegerInput'
import {PageForm} from '../../../../shared/styles/BasicStyles'

const MarketingAutomationTab = ({dispatch, handleSubmit, translate}) => {
  const [hasFetchedMarketingAutomation, setHasFetchedMarketingAutomation] =
    useState(false)

  useEffect(() => {
    async function fetchMarketingAutomation() {
      try {
        const {data, success} = await GetLostBagReminder()
        if (success) {
          dispatch(initialize('marketing_automation_form', {value: data}))
        }
        setHasFetchedMarketingAutomation(true)
      } catch (error) {
        console.log(error)
      }
    }
    if (!hasFetchedMarketingAutomation) {
      fetchMarketingAutomation()
    }
  }, [])

  const onSubmit = async (values) => {
    try {
      const {success} = await ChangeLostBagReminder(values?.value)

      if (success) {
        AlertService.success(
          translate('SUCCESS'),
          translate('LOST_BAG_REMINDER_EDITED_SUCCESSFULLY')
        )
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <PageForm onSubmit={handleSubmit(onSubmit)}>
        <Row align='bottom'>
          <Col xs={6}>
            <Field
              name='value'
              component={IntegerInput}
              label={<Translate id='LOST_BAG' />}
              inputDescriptionText={<Translate id='HOURS' />}
            />
          </Col>
          <Col xs={6} offset={1}>
            <BaseButton auto type='primary' htmlType='submit'>
              <Translate id='SAVE' />
            </BaseButton>
          </Col>
        </Row>
      </PageForm>
    </>
  )
}

MarketingAutomationTab.propTypes = {
  dispatch: PropTypes.func,
  handleSubmit: PropTypes.func
}

MarketingAutomationTab.defaultProps = {
  dispatch: undefined,
  handleSubmit: undefined
}

const MarketingAutomationTabPage = reduxForm({
  form: 'marketing_automation_form'
  // validate: validations
})(MarketingAutomationTab)

export default withLocalize(connect()(MarketingAutomationTabPage))
