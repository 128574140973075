import React from 'react'

import {Col, Row} from 'antd'
import {Translate} from 'react-localize-redux'
import {Field} from 'redux-form'

import FloatInput from '../../../../shared/components/inputs/FloatInput'
import {Margin} from '../../../../shared/styles/BasicStyles'

const PackageDetail = () => (
  <>
    <Row gutter={[20, 20]}>
      <Col xs={6}>
        <Field
          component={FloatInput}
          name='productPropertiesSection.packageWidth'
          label={<Translate id='WIDTH(CM)' />}
        />
      </Col>
      <Col xs={6}>
        <Field
          component={FloatInput}
          name='productPropertiesSection.packageHeight'
          label={<Translate id='HEIGHT(CM)' />}
        />
      </Col>
    </Row>
    <Margin size={20} />
    <Row gutter={[20, 20]}>
      <Col xs={6}>
        <Field
          component={FloatInput}
          name='productPropertiesSection.packageDepth'
          label={<Translate id='DEPTH(CM)' />}
        />
      </Col>
      <Col xs={6}>
        <Field
          component={FloatInput}
          name='productPropertiesSection.packageWeight'
          label={<Translate id='WEIGHT(CM)' />}
        />
      </Col>
    </Row>
    <Margin size={20} />
  </>
)

export default PackageDetail
