import React, {useEffect, useState} from 'react'

import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {useParams} from 'react-router'
import {
  reduxForm,
  Field,
  initialize,
  change,
  getFormValues,
  FieldArray
} from 'redux-form'

import Add from '../../../../../assets/icons/add_bordered.svg'
import Trash from '../../../../../assets/icons/remove_white.svg'
import {
  AddValueProductCharacteristics,
  CreateProductCharacteristics,
  DeleteValueProductCharacteristics,
  EditProductCharacteristics,
  EditValueProductCharacteristics,
  GetProductCharacteristics,
  DeleteProductCharacteristic
} from '../../../../../infra/requests/MarketingRequests'
import AlertService from '../../../../../shared/components/alert/AlertService'
import BackButton from '../../../../../shared/components/buttons/BackButton'
import BaseButton from '../../../../../shared/components/buttons/BaseButton'
import DeleteButton from '../../../../../shared/components/buttons/DeleteButton'
import SwitchInput from '../../../../../shared/components/inputs/SwitchInput'
import TextInput from '../../../../../shared/components/inputs/TextInput'
import BaseLoading from '../../../../../shared/components/loading/BaseLoading'
import BaseModal from '../../../../../shared/components/modal/BaseModal'
import AsyncForEach from '../../../../../shared/logic/arrays/AsyncForEach'
import {
  Margin,
  PageForm,
  PageTitle,
  TitleH3
} from '../../../../../shared/styles/BasicStyles'
import CharacteristicsValue from './components/CharacteristicsValue'

const ManageProductCharacteristics = ({
  router,
  dispatch,
  handleSubmit,
  currentFormValues,
  languages,
  translate
}) => {
  const [ready, setReady] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [value, setValue] = useState(
    languages.map((l) => ({languageId: l.languageId, name: ''}))
  )
  const {id} = useParams()

  useEffect(() => {
    async function fetchProduct() {
      if (id !== 'new') {
        try {
          const {data, success} = await GetProductCharacteristics(id)

          if (success) {
            dispatch(
              initialize('product_characteristics_form', {
                ...data,
                characteristicTranslation: languages.map((l) => {
                  const found = data.characteristicTranslation.findIndex(
                    (x) => x.languageId === l.languageId
                  )
                  return {
                    languageId: l.languageId,
                    name:
                      found > -1
                        ? data.characteristicTranslation[found].name
                        : ''
                  }
                }),
                characteristicValue: data.characteristicValue.map(
                  (item) => ({
                    ...item,
                    characteristicValueTranslation: languages.map((l) => {
                      const found =
                        item.characteristicValueTranslation.findIndex(
                          (x) => x.languageId === l.languageId
                        )
                      return {
                        languageId: l.languageId,
                        name:
                          found > -1
                            ? item.characteristicValueTranslation[found]
                              .name
                            : ''
                      }
                    })
                  })
                )
              })
            )
          }
        } catch (error) {
          console.log(error)
        }
      } else {
        dispatch(
          initialize(
            'product_characteristics_form',
            {
              characteristicTranslation: languages.map((l) => ({
                languageId: l.languageId,
                name: ''
              }))
            } || {}
          )
        )
      }
      setReady(true)
    }
    fetchProduct()
  }, [])

  const validateTranslation = (field, name) => {
    let error
    if (!field || !Array.isArray(field) || field.length === 0) error = `Please define the ${name} of the characteristic`
    languages.forEach((lang, ind) => {
      if (!field[ind] || !field[ind].name || field[ind].name === '') error = `Please define the ${name} for all languages`
    })
    return error
  }

  const validateObject = (values) => {
    const errors = []

    const name = validateTranslation(values.characteristicTranslation, 'name')
    if (name) errors.push(name)

    if (!values.characteristicValue || !Array.isArray(values.characteristicValue) || values?.characteristicValue.length === 0) {
      errors.push('Please define at least one value for the characteristic')
    } else {
      values.characteristicValue.forEach((val, ind) => {
        const err = validateTranslation(val.characteristicValueTranslation, `${ind + 1}º value`)
        if (err) errors.push(err)
      })
    }
    return errors
  }

  const onSubmit = async (values) => {
    let idResult = ''
    try {
      const errors = validateObject(values)
      if (errors.length) {
        return AlertService.error(
          translate('MISSING_INFORMATION'),
          <div>
            {errors.map((err, index) => (
              <div key={index}>{err}</div>
            ))}
          </div>
        )
      }

      if (id !== 'new') {
        const resultInfo = await EditProductCharacteristics(id, values)
        if (resultInfo.success) {
          idResult = resultInfo.data.characteristicId
        }
      } else {
        const resultInfo = await CreateProductCharacteristics(values)
        if (resultInfo.success) {
          idResult = resultInfo.data.characteristicId
        }
      }

      if (values?.characteristicValue) {
        await AsyncForEach(values?.characteristicValue, async (object) => {
          if (object?.characteristicValueId) {
            if (object?.deleted) {
              await DeleteValueProductCharacteristics(
                idResult,
                object?.characteristicValueId
              )
            } else {
              await EditValueProductCharacteristics(
                idResult,
                object?.characteristicValueId,
                object
              )
            }
          } else {
            await AddValueProductCharacteristics(idResult, object)
          }
        })
      }

      AlertService.success(
        translate('SUCCESS'),
        id !== 'new'
          ? translate('PRODUCT_CHARACTERISTICS_EDITED_SUCCESSFULLY')
          : translate('PRODUCT_CHARACTERISTICS_CREATED_SUCCESSFULLY')
      )
      router.history.push('/marketing#product')
    } catch (error) {
      console.log(error)
    }
  }

  const addNewValue = () => {
    const objectValue = {
      characteristicValueTranslation: value
    }

    const err = validateTranslation(value, 'new value')
    if (err) {
      return AlertService.error(
        translate('MISSING_INFORMATION'),
        err
      )
    }

    const resultValue =
      currentFormValues?.characteristicValue?.length > 0
        ? [...currentFormValues.characteristicValue, objectValue]
        : [objectValue]

    dispatch(
      change(
        'product_characteristics_form',
        'characteristicValue',
        resultValue
      )
    )

    setOpenModal(false)
    setValue(languages.map((l) => ({languageId: l.languageId, name: ''})))
  }

  const handleValue = (index, string) => {
    const newArray = [...value]

    newArray[index].name = string
    setValue(newArray)
  }

  const deleteCharacteristic = async () => {
    try {
      const {success} = await DeleteProductCharacteristic(id)

      if (success) {
        AlertService.success(
          translate('SUCCESS'),
          translate('PRODUCT_CHARACTERISTICS_DELETED_SUCCESSFULLY')
        )
        router.history.push('/marketing#product')
      }
    } catch (error) {
      console.log(error)
    }
  }

  if (!ready) return <BaseLoading margin={200} />

  return (
    <>
      <PageForm onSubmit={handleSubmit(onSubmit)}>
        <Row align='bottom' gutter={[0, 50]}>
          <Col xs={24}>
            <BackButton
              label={<Translate id='BACK' />}
              onClick={() => router.history.push('/marketing#product')}
            />
          </Col>
          <Col xs={12}>
            <PageTitle>
              <Translate id='PRODUCT_CHARACTERISTICS' />
            </PageTitle>
          </Col>

          <Col xs={12} align='end'>
            <BaseButton htmlType='submit' type='primary' auto>
              <Translate id='SAVE' />
            </BaseButton>
          </Col>
        </Row>
        <Margin size={40} />
        <Row>
          <Col xs={5}>
            <BaseButton
              onClick={() => setOpenModal(true)}
              imagePath={Add}
              type='primary'
              auto
            >
              <Translate id='NEW_VALUE' />
            </BaseButton>
          </Col>
          {id !== 'new' && (
            <Col xs={6}>
              <DeleteButton
                imagePath={Trash}
                type='error'
                auto
                onClick={() => deleteCharacteristic()}
              >
                <Translate id='DELETE_CHARACTERISTICS' />
              </DeleteButton>
            </Col>
          )}
        </Row>
        <Margin size={38} />
        <Row gutter={[40, 20]}>
          <Col xs={5}>
            <Field
              component={TextInput}
              name='characteristicId'
              label={<Translate id='ID' />}
              disabled
            />
          </Col>
          <Col xs={5} offset={3}>
            <Field
              component={SwitchInput}
              name='filterable'
              checkedText={<Translate id='TRUE' />}
              uncheckedText={<Translate id='FALSE' />}
              label={<Translate id='FILTERABLE' />}
            />
          </Col>
        </Row>
        <Margin size={13} />
        <Row gutter={[40, 20]}>
          {languages.map((l, index) => {
            const ind = (
              currentFormValues?.characteristicTranslation || []
            ).findIndex((x) => x.languageId === l.languageId)

            return (
              <Col xs={8} key={index}>
                <Field
                  component={TextInput}
                  name={`characteristicTranslation[${ind}].name`}
                  label={<Translate id={l.abrev.toUpperCase()} />}
                />
              </Col>
            )
          })}
        </Row>
        <Margin size={32} />
        <TitleH3 $op>
          <Translate id='VALUE' />
        </TitleH3>
        <Margin size={13} />
        <FieldArray
          component={CharacteristicsValue}
          name='characteristicValue'
        />
        <BaseModal
          okText='Save'
          onOk={() => addNewValue()}
          onClose={() => setOpenModal(false)}
          title={<Translate id='ADD_VALUE' />}
          open={openModal}
        >
          <Row gutter={[40, 20]}>
            {languages.map((l, index) => (
              <Col xs={8} key={l.abrev}>
                <TextInput
                  label={<Translate id={l.abrev.toUpperCase()} />}
                  input={{
                    value: value[index].name,
                    onChange: (string) => handleValue(index, string)
                  }}
                />
              </Col>
            ))}
          </Row>
        </BaseModal>
      </PageForm>
    </>
  )
}

ManageProductCharacteristics.propTypes = {
  router: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  handleSubmit: PropTypes.func,
  languages: PropTypes.array.isRequired
}

ManageProductCharacteristics.defaultProps = {
  dispatch: undefined,
  handleSubmit: undefined
}

const ManageProductCharacteristicsPage = reduxForm({
  form: 'product_characteristics_form'
})(ManageProductCharacteristics)

export default withLocalize(
  connect((state) => ({
    clients: state.info.clients,
    currentFormValues: getFormValues('product_characteristics_form')(state)
  }))(ManageProductCharacteristicsPage)
)
