import {Row, Checkbox} from 'antd'
import styled from 'styled-components'

import {StyledButton} from '../../shared/components/buttons/ButtonsStyles'
import {
  BorderColor,
  PrimaryButtonColor,
  SecondaryButtonColor
} from '../../shared/styles/_colors'
import {TitleH3} from '../../shared/styles/BasicStyles'

export const ProductLocalImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${BorderColor};
  border-radius: 4px;
  height: 170px;
  overflow: hidden;
  position: relative;
  cursor: grab;

  &:hover {
    box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.5);
  }
  &:active {
    cursor: grabbing;
  }
`

export const TopButtons = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  padding: 5px;
  justify-content: space-between;
  display: flex;
`

export const HoverImageButton = styled.div`
  position: absolute;
  top: ${({$top}) => $top || 'auto'};
  right: ${({$right}) => $right || 'auto'};
  left: ${({$left}) => $left || 'auto'};
  cursor: pointer;
  font-size: 12px;
  padding: 2px 5px;
  border: 1px solid ${({$color}) => $color};
  color: ${({$color}) => $color};
  border-radius: 50%;
`

export const ProductLocalImage = styled.img`
  width: 100%;
`

export const CompositionBox = styled.div`
  border: 1px solid ${BorderColor};
  width: 100%;
  position: relative;
`

export const CompositionTitle = styled.div`
  border-bottom: 1px solid ${BorderColor};
  padding-left: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
`

export const RemovableItemContainer = styled.div`
  height: 30px;
  border: 1px solid ${BorderColor};
  padding: 1px 10px;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const SKUImageContainer = styled.div`
  height: 100px;
  border: 1px solid ${BorderColor};
  margin: 20px 0px;
  padding: 1px;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ChangeProductImage = styled.div`
  padding: 10px 10px;

  &:hover {
    cursor: pointer;
    border: 0.5px solid ${BorderColor};
  }
`

export const SKUImage = styled.img``

export const ProductDropDownIcon = styled.img`
  position: absolute;
  background-color: ${SecondaryButtonColor};
  top: 0;
  right: 20px;
  padding: 7px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 9;
  ${({$open}) =>
    $open ? 'transform: rotate(0deg);' : 'transform: rotate(-180deg);'}
`

export const ProductDocumentCard = styled.div`
  display: inline-block;
  width: 100%;
  padding: 5px;
  background-color: white;
  position: relative;
  border-radius: 4px;
  border: 1px solid ${BorderColor};
`

export const FlickingArrow = styled.img`
  position: absolute;
  left: ${({$left}) => ($left ? '20px' : 'auto')};
  right: ${({$right}) => ($right ? '0px' : 'auto')};
  top: 50%;
  transform: translate(-50%, -50%);
  height: 20px;
  width: 30px;
  z-index: 2001;
  cursor: pointer;
`

export const CarrouselImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 300px;
`

export const CarrouselImage = styled.img`
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
`

export const EmptyImageText = styled(TitleH3)`
  opacity: 0.5;
  margin-top: 44px;
  max-height: 33px;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const ProductDetailsFeature = styled.div`
  display: inline-flex;
  justify-content: space-between;
  padding: 3px 5px;
  border-radius: 15px;
  border: 1px solid ${BorderColor};
`

export const ProductDetailsFeatureImage = styled.img`
  cursor: pointer;
`

export const RowProduct = styled(Row)`
  border-top: 1px solid ${BorderColor};
  border-left: 6px solid
    ${({$base}) => ($base ? PrimaryButtonColor : 'transparent')};
`

export const CollapsableMenuSection = styled.div`
  border-bottom: ${({hideBorder}) =>
    hideBorder ? 'none' : `1px solid ${BorderColor}`};
  height: ${({open}) => (open ? 'auto' : '50px')};
`
export const SaveButton = styled.div`
  position: fixed;
  width: auto;
  bottom: 30px;
  right: 30px;
`

export const MeasureButtons = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: 20px;

  ${StyledButton} {
    margin-right: 10px;
  }
`

export const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0.2;
  background: black;
  display: ${({$show}) => ($show ? 'block' : 'none')};
`

export const RelatedProductBox = styled.div`
  display: inline-block;
  width: 100%;
`

export const RelatedProductOptions = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin-top: 20px;
`

export const CostFileButton = styled.div`
  position: absolute;
  right: 3px;
  top: 9px;
`

export const ServiceCheckbox = styled(Checkbox)`
  &&& {
    height: auto;
    border-right: none;
    width: auto;
    display: flex;
    text-align: left;
    padding: 0;
  }
`

export const ServiceAddedLabel = styled.div`
  color: ${PrimaryButtonColor};
`

export const HeaderPrice = styled.div`
  font-weight: bold;
  font-size: 18px;
`
