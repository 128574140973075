import React from 'react'

import {PropTypes} from 'prop-types'

import {PriorityInputStyled} from './InputStyles'

const GetPriorityColor = (priority) => {
  switch (parseInt(priority, 10)) {
    case 1:
      return '#E9ADA7'
    case 2:
      return '#FFFBF0'
    case 3:
      return '#E4F2EF'
    default:
      return '#E4F2EF'
  }
}

const GetPriorityBorder = (priority) => {
  switch (parseInt(priority, 10)) {
    case 1:
      return '#F5333F'
    case 2:
      return '#FFF2C9'
    case 3:
      return '#BDE0CC'
    default:
      return '#BDE0CC'
  }
}

const PriorityInput = ({
  input,
  min,
  max,
  step,
  disabled,
  afterChange,
  width,
  height
}) => {
  const handleChange = (event) => {
    const {value} = event.target
    if (!value || value === '') {
      input.onChange('')
    } else if (value === 0) {
      input.onChange(0)
    } else if (value > max) {
      input.onChange(max)
    } else if (value < min) {
      input.onChange(min)
    } else input.onChange(value)
    if (typeof afterChange === 'function') {
      afterChange(event.target.value)
    }
  }

  return (
    <PriorityInputStyled
      onChange={handleChange}
      value={input.value}
      $width={width}
      $height={height}
      $backgroundColor={GetPriorityColor(input.value)}
      $border={GetPriorityBorder(input.value)}
      min={min}
      max={max}
      step={step}
      disabled={disabled}
      type='number'
    />
  )
}

PriorityInput.propTypes = {
  input: PropTypes.object,
  afterChange: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  disabled: PropTypes.bool
}

PriorityInput.defaultProps = {
  input: {value: undefined, onChange: () => null},
  afterChange: undefined,
  min: 1,
  max: 3,
  step: 1,
  width: 32,
  height: 20,
  disabled: false
}

export default PriorityInput
