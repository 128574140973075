import React from 'react'

import { Translate } from 'react-localize-redux'

import MailIcon from '../../../../assets/icons/mail.svg'
import PhoneIcon from '../../../../assets/icons/phone.svg'
import FooterCopyright from './FooterCopyright'
import {
  FooterContainer,
  FooterDescriptionImage,
  BottomContainer,
  BottomLeft,
  BottomRight,
  FooterTitle,
  FooterDescription
} from './FooterStyles'

const NavigationFooter = () => {
  const iFrameDetection = ((window === window.parent || window.opener))

  return (
    iFrameDetection &&
    <FooterContainer>
      <FooterCopyright />
      <BottomContainer>
        <BottomLeft>
          <div>
            <FooterTitle>
              <Translate id='ABOUT_SKYPRO' />
            </FooterTitle>
            <FooterDescription>
              <Translate id='SKYPRO_HEALTH_TECHNOLOGY' />
            </FooterDescription>
          </div>
          <div>
            <FooterTitle>
              <Translate id='CUSTOMER_SUPPORT' />
            </FooterTitle>
            <FooterDescription>
              <Translate id='PRIVACY_POLICY' />
            </FooterDescription>
            <FooterDescription>
              <Translate id='DELIVERY_RETURNS' />
            </FooterDescription>
            <FooterDescription>
              <Translate id='PAYMENT_SECURITY' />
            </FooterDescription>
            <FooterDescription>
              <Translate id='FAQ' />
            </FooterDescription>
          </div>
        </BottomLeft>
        <BottomRight>
          <div>
            <FooterTitle>
              <Translate id='CONTACT_US' />
            </FooterTitle>
            <FooterDescription>
              <FooterDescriptionImage src={MailIcon} />
              <Translate id='SUPPORT_MAIL' />
            </FooterDescription>
            <FooterDescription>
              <FooterDescriptionImage src={PhoneIcon} />
              <Translate id='SUPPORT_PHONE_NUMBER' />
            </FooterDescription>
          </div>
        </BottomRight>
      </BottomContainer>
    </FooterContainer>
  )
}

export default NavigationFooter
