import React from 'react'

import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'

import {
  ProductsFooter,
  ProductsFooterContainer,
  ProductsFooterTitle,
  ProductsFooterValue
} from '../../../../shared/components/inputs/Products/ProductsStyles'
import RoundCurrency from '../../../../shared/logic/numbers/RoundCurrency'

const TotalFooter = ({products, currency}) => {
  const calculateTotal = () => {
    let total = 0
    let totalCur = 0
    products.forEach((product) => {
      total += parseFloat(product?.prices.sub)
      totalCur += parseFloat(product?.prices.currSub)
    })
    return {total, totalCur}
  }

  const calc = calculateTotal()

  return (
    <ProductsFooter>
      <ProductsFooterContainer>
        <ProductsFooterTitle $total>
          <Translate id='TOTAL' />:
        </ProductsFooterTitle>
      </ProductsFooterContainer>
      <ProductsFooterContainer>
        <ProductsFooterValue $total>
          {RoundCurrency(calc.total)}€
        </ProductsFooterValue>
        {currency && currency.currencyId != 1 && (
          <ProductsFooterValue $total>
            {RoundCurrency(calc.totalCur)} {currency.code}
          </ProductsFooterValue>
        )}
      </ProductsFooterContainer>
    </ProductsFooter>
  )
}

TotalFooter.propTypes = {
  products: PropTypes.array.isRequired
}

export default TotalFooter
