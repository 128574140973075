import React, { useState, useEffect } from 'react'

import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'

import AccountIcon from '../../../../assets/icons/profile.svg'
import Logo from '../../../../assets/skypro_logo_dark.svg'
import { LogoutAuth } from '../../../../infra/requests/AuthRequests'
import { userDelete } from '../../../../redux/data/user/UserActions'
import {
  NavBackgroundColor,
  PrimaryButtonColor
} from '../../../styles/_colors'
import { HeaderBreakpoint } from '../../../styles/_responsive'
import OutsideAlerter from '../../OutsideAlerter/OutsideAlerter'
import {
  HeaderNavbar,
  LogoContainer,
  LeftMenu,
  MenuItem,
  RightMenu,
  MenuItemContainer,
  AccountContainer,
  AccountImage,
  AccountName,
  HamburgerContainer,
  NavbarCollapsed,
  HamburgerBarOne,
  HamburgerBarTwo,
  HamburgerBarThree,
  AccountDropdown,
  DropdownItem,
  DropdownItemContainer,
  SiteTitle
} from './HeaderStyles'
import NavbarMenus from './NavbarMenus'

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height
  }
}

const NavigationHeader = ({ router, user, dispatch }) => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )
  const [isNavToggled, toggleNavbar] = useState(false)
  const [isProfileToggled, toggleProfile] = useState(false)
  const iFrameDetection = ((window === window.parent || window.opener))

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const Logout = async () => {
    try {
      await LogoutAuth()
      localStorage.clear()
      dispatch(userDelete())
      router.history.push('/login')
    } catch (e) {
      console.error(e)
    }
  }

  const isNavbarCollapsed = windowDimensions.width < HeaderBreakpoint

  return (
    iFrameDetection &&
    <HeaderNavbar opened={isNavbarCollapsed && isNavToggled}>
      <NavbarCollapsed collapsed={isNavbarCollapsed}>
        <LogoContainer>
          <img src={Logo} alt={<Translate id='NAV_LOGO' />} />
        </LogoContainer>
        <HamburgerContainer
          collapsed={isNavbarCollapsed}
          onClick={() => toggleNavbar(!isNavToggled)}
          isNavToggled={isNavToggled}
        >
          <HamburgerBarOne />
          <HamburgerBarTwo />
          <HamburgerBarThree />
        </HamburgerContainer>
      </NavbarCollapsed>

      <LeftMenu collapsed={isNavbarCollapsed}>
        {NavbarMenus.map((menu, index) => (
          <MenuItemContainer
            key={index}
            to={menu.link}
            exact={menu.exact}
            activeClassName='active'
          >
            <MenuItem>{menu.name}</MenuItem>
          </MenuItemContainer>
        ))}
      </LeftMenu>
      <RightMenu collapsed={isNavbarCollapsed}>
        <OutsideAlerter callback={() => toggleProfile(false)}>
          <AccountContainer
            active={isProfileToggled}
            onClick={() => toggleProfile(!isProfileToggled)}
            tabIndex='-1'
          >
            <AccountImage src={AccountIcon} />
            <AccountName>{user.fullName}</AccountName>
          </AccountContainer>

          <AccountDropdown
            profileOpened={isProfileToggled}
            collapsed={isNavbarCollapsed}
          >
            <DropdownItemContainer
              onClick={() => router.history.push('/profile')}
              active={router.location.pathname.indexOf('/profile') === 0}
            >
              <DropdownItem>
                <Translate id='MY_PROFILE' />
              </DropdownItem>
            </DropdownItemContainer>
            <DropdownItemContainer
              onClick={() => router.history.push('/skypro')}
              active={router.location.pathname.indexOf('/skypro') === 0}
            >
              <DropdownItem>
                <Translate id='SKYPRO' />
              </DropdownItem>
            </DropdownItemContainer>
            <SiteTitle>
              <Translate id='SITE' />
            </SiteTitle>
            <DropdownItemContainer
              $padding
              $borderColor='transparent'
              onClick={() => router.history.push('/marketing')}
              active={router.location.pathname.indexOf('/marketing') === 0}
            >
              <DropdownItem $align='left'>
                <Translate id='MARKETING' />
              </DropdownItem>
            </DropdownItemContainer>
            <DropdownItemContainer
              $padding
              $borderColor='transparent'
              onClick={() => router.history.push('/mailConfiguration')}
              active={router.location.pathname.indexOf('/mailConfiguration') === 0}
            >
              <DropdownItem $align='left'>
                <Translate id='EMAIL_CONFIGURATION' />
              </DropdownItem>
            </DropdownItemContainer>
            <DropdownItemContainer
              $borderColor='transparent'
              $color={PrimaryButtonColor}
              onClick={() => Logout()}
            >
              <DropdownItem $op={1} $color={NavBackgroundColor}>
                <Translate id='LOGOUT_CAPS' />
              </DropdownItem>
            </DropdownItemContainer>
          </AccountDropdown>
        </OutsideAlerter>
      </RightMenu>
    </HeaderNavbar>
  )
}

NavigationHeader.propTypes = {
  user: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
}

export default NavigationHeader
