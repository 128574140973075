import React from 'react'

import { Col, Row } from 'antd'
import PropTypes from 'prop-types'
import { Translate, withLocalize } from 'react-localize-redux'

import { DeliveryContainer, HeaderTitle, HeaderValue, TotalQuantityContainer } from './WHTransferStyles'
import PropertyDescription from '../../../../shared/components/description/PropertyDescription'
import BaseTable from '../../../../shared/components/table/BaseTable'
import ActiveTranslation from '../../../../shared/logic/translations/ActiveTranslation'
import { TitleH5 } from '../../../../shared/styles/BasicStyles'

const productColumns = [
  {
    dataIndex: 'referenceCode',
    title: <Translate id='REFERENCE' />
  },
  {
    dataIndex: 'barcode',
    title: <Translate id='EAN_CODE' />
  },
  {
    dataIndex: 'productNameTranslations',
    title: <Translate id='DESCRIPTION' />,
    render: (value) => <ActiveTranslation value={value} tag='name' />
  },
  {
    dataIndex: 'colorTranslations',
    title: <Translate id='COLOR' />,
    render: (value) => <ActiveTranslation value={value} tag='name' />
  },
  {
    dataIndex: 'sizeName',
    title: <Translate id='SIZE' />
  },
  {
    dataIndex: 'productWidthTranslations',
    title: <Translate id='WIDTH' />,
    render: (value) => <ActiveTranslation value={value} tag='name' />
  },
  {
    dataIndex: 'quantity',
    title: <Translate id='REQUESTED_QUANTITY' />,
    align: 'right'
  }
]

const TransferDetail = ({ transferDetail }) => (
  <DeliveryContainer>
    <Row gutter={[40, 20]}>
      <Col xs={12} sm={12} lg={6} xl={4}>
        <PropertyDescription
          label='Transfer ID'
          value={`#${transferDetail.warehouseTransferId}`}
        />
      </Col>
      <Col xs={12} sm={12} lg={6} xl={4}>
        <PropertyDescription
          label='Origin Warewhouse'
          value={transferDetail?.warehouseOriginName || 'N/A'}
        />
      </Col>
      <Col xs={12} sm={12} lg={6} xl={4}>
        <PropertyDescription
          label='Destiny Warewhouse'
          value={transferDetail?.warehouseDestinyName || 'N/A'}
        />
      </Col>
      <Col xs={12} sm={12} lg={6} xl={4}>
        <TitleH5>Transfer Status</TitleH5>
        <HeaderValue>
          <ActiveTranslation value={transferDetail?.warehouseTransferStatusTranslations} tag='name' />
        </HeaderValue>
      </Col>
    </Row>
    <div>

      <div>
        <BaseTable
          rowKey='barcode'
          columns={productColumns}
          datasource={transferDetail?.warehouseTransferItems || []}
          pagination={{ render: false }}
        />
        <TotalQuantityContainer>
          <HeaderTitle>
            Total Quantity:
          </HeaderTitle>
          <HeaderValue>
            {transferDetail?.totalQuantity || 0}
          </HeaderValue>
        </TotalQuantityContainer>
      </div>

    </div>
  </DeliveryContainer>
)

TransferDetail.propTypes = {
  transferDetail: PropTypes.object.isRequired
}

export default withLocalize(TransferDetail)
