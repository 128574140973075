import React from 'react'

import {PropTypes} from 'prop-types'

import {SelectTitleStyled, SelectOption, SelectArrow} from '../TableStyles'

const TableTitleSelect = ({
  input,
  data,
  placeholder,
  dataKey,
  dataLabel,
  allowClear,
  noBorder
}) => {
  const handleChange = (value) => {
    input.onChange(value || undefined)
  }

  return (
    <SelectTitleStyled
      placeholder={placeholder}
      value={input.value !== '' ? input.value : undefined}
      onChange={handleChange}
      allowClear={allowClear}
      $noBorder={noBorder}
      suffixIcon={<SelectArrow />}
    >
      {data.map((current) => (
        <SelectOption key={current[dataKey]} disabled={current.disabled}>
          {current[dataLabel]}
        </SelectOption>
      ))}
    </SelectTitleStyled>
  )
}

TableTitleSelect.propTypes = {
  input: PropTypes.object,
  placeholder: PropTypes.string,
  data: PropTypes.array,
  dataKey: PropTypes.string,
  dataLabel: PropTypes.string,
  allowClear: PropTypes.bool,
  noBorder: PropTypes.bool
}

TableTitleSelect.defaultProps = {
  input: {value: undefined, onChange: () => null},
  placeholder: '',
  data: [],
  dataKey: 'value',
  dataLabel: 'text',
  allowClear: true,
  noBorder: false
}

export default TableTitleSelect
