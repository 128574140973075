import React, {useState} from 'react'

import {CheckCircleOutlined, CloseCircleOutlined} from '@ant-design/icons'
import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'
import {connect} from 'react-redux'
import {initialize} from 'redux-form'

import AddWhite from '../../../assets/icons/add_white.svg'
import {Margin, SubTitle} from '../../styles/BasicStyles'
import BaseButton from '../buttons/BaseButton'
import Address from './AddressComponent'
import AddressModal from './AddressModal'
import {StopOverSelect} from './AddressStyles'

const AddressList = ({fields, countries, dispatch, selectPrimary}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const addressList = fields.getAll() || []

  const onSaveAddress = (values) => {
    if (values.openIndex >= 0) {
      fields.remove(values.openIndex)
      setTimeout(() => fields.insert(values.openIndex, values), 50)
    } else {
      fields.push(values)
    }
    setIsModalOpen(false)
    dispatch(initialize('manage_new_address', {}))
  }

  const onAddressDelete = (index) => {
    fields.remove(index)
  }

  const openEditAddress = (address, index) => {
    dispatch(
      initialize('manage_new_address', {...address, openIndex: index})
    )
    setIsModalOpen(true)
  }

  const closeModal = () => {
    dispatch(initialize('manage_new_address', {}))
    setIsModalOpen(false)
  }

  const showPrimary = (address, index) => {
    if (typeof selectPrimary === 'function') {
      return (
        <StopOverSelect
          onClick={() => selectPrimary(index)}
          selected={address.preferred}
        >
          {address.preferred ? <CheckCircleOutlined /> : <CloseCircleOutlined />} <Translate id='PREFERRED' />
        </StopOverSelect>
      )
    }
    return null
  }

  return (
    <Row gutter={[24, 24]}>
      <Col xs={24}>
        <SubTitle>
          <Translate id='ADDRESS' />
        </SubTitle>
      </Col>
      <Col xs={24}>
        <Margin size={18} />
        <BaseButton
          auto
          imagePath={AddWhite}
          onClick={() => setIsModalOpen(true)}
        >
          <Translate id='ADD_ADDRESS' />
        </BaseButton>
      </Col>
      {addressList.map((address, index) => (
        <Col xs={6} key={index}>
          <Address
            address={address}
            index={index}
            countries={countries}
            onDelete={onAddressDelete}
            openAddress={openEditAddress}
          />
          {showPrimary(address, index)}
        </Col>
      ))}

      <AddressModal
        open={isModalOpen}
        onClose={closeModal}
        onSave={onSaveAddress}
        countries={countries}
      />
    </Row>
  )
}

AddressList.propTypes = {
  fields: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  countries: PropTypes.array.isRequired,
  selectPrimary: PropTypes.func
}

AddressList.defaultProps = {
  selectPrimary: undefined
}

export default connect()(AddressList)
