import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Translate } from 'react-localize-redux';
import EditIcon from '../../../assets/icons/eye.svg';
import { LineContent, EditButton } from '../../../pages/logistics/LogisticStyles';
import ActiveTranslation from '../../logic/translations/ActiveTranslation';
import isInIframe from '../../../shared/utils/isInIframe';

const ProductLink = ({ product }) => {
  const history = useHistory(); // Pega o objeto history do useHistory hook

  const inIframe = isInIframe();

  const handleProductClick = () => {
    if (product.productId) {
      // Constrói a URL do produto
      const productDetailsUrl = `/products/${product.productId}`;

      // Abre a URL em uma nova aba
      window.open(productDetailsUrl, inIframe ? '_self' : '_blank', 'noopener,noreferrer');

      // Opcional: Se você também quiser mudar a URL na aba original
      // history.push(productDetailsUrl);
    }
  };

  return (
    <LineContent>
      <ActiveTranslation
        value={product?.productTranslation || product.description}
        tag='name'
      />
      <EditButton
        src={EditIcon}
        alt={<Translate id='EDIT' />}
        onClick={handleProductClick} // Chama a função handleProductClick no clique do botão
      />
    </LineContent>
  );
};

ProductLink.propTypes = {
  product: PropTypes.object.isRequired // Assegura que a prop product é obrigatória e é um objeto
};

export default ProductLink;
