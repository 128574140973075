import {
  GetDeliveryLocations,
  GetAllServices,
  GetSuppliersList,
  GetPositions,
  GetReceivingServers,
  GetUsers,
  GetProcesses,
  GetInfo,
  GetSuppliersRawMaterial
} from '../../infra/requests/BaseRequests'
import {GetAllWarehouseTypes} from '../../infra/requests/LogisticsRequests'
import {updateInfo} from '../data/info/InfoActions'

export default function start() {
  return async (dispatch) => {
    try {
      const {data, success} = await GetInfo()

      if (success) {
        const attachmentTypesData = data?.attachmentTypes?.items
        const returnReasonsData = data?.returnReasons?.items
        const arrivalTypesData = data?.arrivalTypes?.items
        const departureTypesData = data?.departureTypes?.items
        const currenciesData = data?.currencies?.items
        const orderStatusData = data?.orderStatus?.items
        const paymentConditionsData = data?.paymentConditions?.items
        const shippingConditionsData = data?.shippingConditions
        const contractTypesData = data?.contractTypes?.items
        const bannersTypeData = data?.bannersType?.items
        const modulationTypesData = data?.modulationTypes?.items
        const employeeCategoryData = data?.employeeCategories?.items
        const employeeStatusData = data?.employeeStatus?.items
        const employeeCostCenterData = data?.employeeCostCenter?.items
        const employeePositionData = data?.employeePositions?.items
        const paymentMethodsData = data?.paymentMethods
        const countriesData = data?.countries
        const rawMaterialFamilyData = data?.rawMaterialFamilies?.items
        const productTypesData = data?.productTypes?.items
        const productStylesData = data?.productStyles?.items
        const productTechnologiesData = data?.productTechnologies.items
        const productColorsData = data?.productColors?.items
        const productSizesData = data?.productSizes?.items
        const productWidthsData = data?.productWidths?.items
        const productTaxRatesData = data?.productTaxRates?.items
        const productCategoriesData = data?.productCategories?.items
        const productMeasuresData = data?.productMeasures?.items
        const productMeasuresUnitData = data?.productMeasuresUnit?.items
        const contactTypesData = data?.contactTypes?.items
        const clientsData = data?.clients?.items
        const productCharacteristics = []
        const productCharacteristicsData =
            data?.productCharacteristics?.items
        const productCharacteristicValuesData =
            data?.productCharacteristicValues?.items
        if (
          productCharacteristicsData &&
            productCharacteristicValuesData
        ) {
          productCharacteristicsData.forEach((char) => {
            productCharacteristics.push({
              ...char,
              values: productCharacteristicValuesData.filter(
                (sub) => sub.characteristicId === char.characteristicId
              )
            })
          })
        }

        const deliveryLocationsData = await GetDeliveryLocations()
        const usersData = await GetUsers()
        const processesData = await GetProcesses()
        const receivingServersData = await GetReceivingServers()
        const servicesData = await GetAllServices()
        const suppliersData = await GetSuppliersList()
        const suppliersRawMaterialData = await GetSuppliersRawMaterial()
        const positionsData = await GetPositions()
        const warehouseTypeData = await GetAllWarehouseTypes()

        dispatch(
          updateInfo({
            ready: true,
            warehouseTypes: warehouseTypeData?.data || [],
            currencies: currenciesData || [],
            orderStatus: orderStatusData || [],
            paymentConditions: paymentConditionsData,
            deliveryLocations: deliveryLocationsData.data,
            shippingConditions: shippingConditionsData,
            clients: clientsData || [],
            paymentMethods: paymentMethodsData,
            countries: countriesData,
            services: servicesData?.data?.items || [],
            bannersType: bannersTypeData || [],
            receivingServers: receivingServersData.data?.items || [],
            productTypes: productTypesData || [],
            productCategories: productCategoriesData || [],
            productStyles: productStylesData || [],
            productCharacteristics,
            productTechnologies: productTechnologiesData || [],
            productMeasures: productMeasuresData || [],
            productColors: productColorsData,
            productSizes: productSizesData,
            productWidths: productWidthsData || [],
            productTaxRates: productTaxRatesData || [],
            productMeasuresUnit: productMeasuresUnitData || [],
            suppliers: suppliersData.data?.items || [],
            positions: positionsData.data,
            contractTypes: contractTypesData || [],
            modulationTypes: modulationTypesData || [],
            employeeCategory: employeeCategoryData || [],
            employeeStatus: employeeStatusData || [],
            employeeCostCenter: employeeCostCenterData || [],
            employeePosition: employeePositionData || [],
            contactTypes: contactTypesData || [],
            users: usersData?.data?.items || [],
            processes: processesData?.data?.items || [],
            rawMaterialFamily: rawMaterialFamilyData || [],
            suppliersRawMaterial:
                suppliersRawMaterialData?.data?.items || [],
            arrivalTypes: arrivalTypesData || [],
            departureTypes: departureTypesData || [],
            attachmentTypes: attachmentTypesData || [],
            returnReasons: returnReasonsData || []
          })
        )
      }
    } catch (error) {
      console.log(error)
    }
  }
}
