import styled from 'styled-components'

import {BorderColor} from '../../shared/styles/_colors'
import {Campton} from '../../shared/styles/_texts'

export const StyledNotFoundContainer = styled.div`
  width: 100%;
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(p) => p.theme.primaryBackgroundColor};
`

export const StyledNotFoundGroup = styled.div`
  max-width: 800px;
  height: 500px;
  position: relative;
`

export const StyledTitle = styled.h2`
  display: inline-block;
  width: 100%;
  font-size: 50px;
  line-height: 1;
  margin: 0px;
  font: ${Campton};
  text-align: center;
  color: ${(p) => p.theme.primaryColor};
`

export const StyledDescription = styled.h5`
  display: block;
  width: 100%;
  font-size: 15px;
  text-align: center;
  font: Calibri;
`

export const StyledNotFoundLogo = styled.img`
  display: block;
  width: 500px;
  margin-left: auto;
  margin-right: auto;
`

export const StyledNotFound = styled.h1`
  display: inline-block;
  width: 100%;
  color: #fff;
  font-size: 70px;
  line-height: 1;
  text-align: center;
`
export const MarginBackNotFound = styled.div`
  display: block;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
`
