import React, { useState } from 'react'

import { ImportOutlined, DownloadOutlined, LoadingOutlined } from '@ant-design/icons'
import moment from 'moment'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import { Translate, withLocalize } from 'react-localize-redux'
import styled from 'styled-components'

import { AuthTokenKey } from '../../../infra/config/LocalStorageKeys'
import {
  ImportEmployees,
  ImportEmployeeCredits
} from '../../../infra/requests/ClientRequests'
import AlertService from '../../../shared/components/alert/AlertService'
import { BorderColor, PrimaryButtonColor, TableTextColor } from '../../../shared/styles/_colors'
import ImportErrorsModal from './ImportErrorsModal'

const ImportItem = styled.div`
    margin-bottom: 10px;
    cursor: pointer;
    color: ${TableTextColor};

    &:hover {
        color: ${PrimaryButtonColor};
    }
`

const DownloadItem = styled.div`
    cursor: pointer;
    color: ${TableTextColor};
    &:hover {
        color: ${PrimaryButtonColor};
    }
`

const ListSeparator = styled.div`
    width: 100%;
    height: 1px;
    background: ${BorderColor};
    margin-bottom: 10px;
`

const BaseModalStatus = { open: false, title: '', message: '', errors: [] }

const ImportsPopover = ({ translate, id }) => {
  const [saving, setSaving] = useState([false, false, false])
  const [modal, setModal] = useState(BaseModalStatus)

  const handleImportList = async (accepted) => {
    if (accepted && accepted[0]) {
      setSaving([true, false, false])
      const formData = new FormData()
      formData.append('File', accepted[0])
      const { success, data } = await ImportEmployees(id, formData)
      setSaving([false, false, false])
      if (success) {
        return AlertService.success(
          translate('IMPORT_SUCCESS'),
          translate('IMPORT_EMPLOYEE_LIST_SUCCESS')
        )
      }
      return setModal({ open: true, title: data?.exceptionDetail, message: data?.message, errors: data?.errorsList })
    }
  }

  const handleImportCredits = async (accepted) => {
    if (accepted && accepted[0]) {
      setSaving([false, true, false])
      const formData = new FormData()
      formData.append('File', accepted[0])
      const { success } = await ImportEmployeeCredits(id, formData)
      if (success) {
        return AlertService.success(
          translate('IMPORT_SUCCESS'),
          translate('IMPORT_EMPLOYEE_CREDITS_SUCCESS')
        )
      }
      setSaving([false, false, false])
    }
  }

  const handleDownloadTemplate = () => {
    setSaving([false, false, true])
    const authToken = localStorage.getItem(AuthTokenKey)
    fetch(
      `${window.env.REACT_APP_API}/SkyproConfig/ExportEmployees/${id}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }
    )
      .then((resp) => {
        if (resp.status == 200) {
          resp.blob().then((blob) => {
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.style.display = 'none'
            a.href = url
            a.download = `client_${id}_template_${moment().format('YYYY_MM_DD_HH_mm_ss')}.zip`
            document.body.appendChild(a)
            a.click()
            window.URL.revokeObjectURL(url)
            setSaving([false, false, false])
          }).catch(() => {
            AlertService.error(translate('ERROR'), translate('ERROR_DOWNLOAD_FILE'))
            setSaving([false, false, false])
          })
        } else {
          resp.json().then((json) => {
            setSaving([false, false, false])
            return AlertService.error(
              translate('ERROR'),
              json.Message || translate('ERROR_DOWNLOAD_FILE')
            )
          }).catch(() => {
            AlertService.error(translate('ERROR'), translate('ERROR_DOWNLOAD_FILE'))
            setSaving([false, false, false])
          })
        }
      })
      .catch(() => {
        setSaving([false, false, false])
        AlertService.error(translate('ERROR'), translate('ERROR_DOWNLOAD_FILE'))
      })
  }

  return (
    <>
      <Dropzone onDrop={handleImportList} multiple={false} maxFiles={1}>
        {({ getRootProps, getInputProps }) => (
          <ImportItem {...getRootProps()} disabled={saving[0]}>
            <input {...getInputProps()} />
            {saving[0] ? <LoadingOutlined /> : <ImportOutlined />} <Translate id='IMPORT_EMPLOYEE_LIST' />
          </ImportItem>
        )}
      </Dropzone>

      <Dropzone onDrop={handleImportCredits} multiple={false} maxFiles={1}>
        {({ getRootProps, getInputProps }) => (
          <ImportItem {...getRootProps()} disabled={saving[2]}>
            <input {...getInputProps()} />
            {saving[1] ? <LoadingOutlined /> : <ImportOutlined />} <Translate id='IMPORT_EMPLOYEE_CREDITS' />
          </ImportItem>

        )}
      </Dropzone>

      <ListSeparator />
      <DownloadItem onClick={handleDownloadTemplate}>
        {saving[2] ? <LoadingOutlined /> : <DownloadOutlined />} <Translate id='DOWNLOAD_EMPLOYEE_TEMPLATE' />
      </DownloadItem>

      <ImportErrorsModal modal={modal} onClose={() => setModal(BaseModalStatus)} />
    </>
  )
}

ImportsPopover.propTypes = {
  id: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired
}

export default withLocalize(ImportsPopover)
