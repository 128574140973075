import {Checkbox} from 'antd'
import styled from 'styled-components'

import {
  TableTextColor,
  DisableButtonColor,
  BorderColor,
  PrimaryColor
} from '../../../../shared/styles/_colors'
import {
  BookFontWeight,
  LabelText,
  SemiBoldFontWeight,
  BodyText,
  ButtonText,
  BoldFontWeight
} from '../../../../shared/styles/_texts'

export const EditOrderTitleContainer = styled.div`
  margin-right: ${({$last}) => ($last ? 0 : 30)}px;
  margin-top: ${({$margin}) => ($margin ? 30 : 0)}px;
`

export const EditOrderName = styled.h5`
  color: ${TableTextColor};
  font-size: ${LabelText};
  font-weight: ${SemiBoldFontWeight};
  white-space: nowrap;
`
export const EditOrderDescription = styled.div`
  color: ${({$color}) => $color || TableTextColor};
  font-size: ${BodyText};
  font-weight: ${BookFontWeight};
`

export const EditOrderStatusContainer = styled.div`
  display: inline-block;
  color: ${({$color}) => $color || DisableButtonColor};
  background-color: ${({$background}) =>
    $background || DisableButtonColor};
  border: 1px solid ${({$background}) => $background || DisableButtonColor};
  border-radius: 20px;
  opacity: ${({$disable}) => ($disable ? 0.5 : 1)};
  margin-right: ${({$last}) => ($last ? 0 : 15)}px;
`

export const EditOrderStatusContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: ${BookFontWeight};
  max-height: 16px;
  margin: 6px 30px;
  font-size: ${ButtonText};
`

export const AddressContainer = styled.div``

export const EditOrderTitle = styled.div`
  color: ${TableTextColor};
  font-weight: ${BoldFontWeight};
  font-size: 21px;
  line-height: 24px;
  text-align: left;
`

export const AddressName = styled.div`
  color: ${TableTextColor};
  font-weight: ${SemiBoldFontWeight};
  font-size: 16px;
  line-height: 19.2px;
  text-align: left;
  margin-top: 17px;
`

export const AddressDescription = styled.div`
  color: ${TableTextColor};
  font-weight: ${BookFontWeight};
  font-size: 14px;
  line-height: 16.8px;
  text-align: left;
  margin-top: 9px;
`

export const ProductImage = styled.div`
  position: relative;
  padding: 5px;
  border: 2px solid
    ${({$selected}) => ($selected ? PrimaryColor : BorderColor)};
  width: 100%;
  padding-bottom: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-origin: center;
  background-size: contain;
  background-image: url('${({src}) => src}');
`

export const Title = styled.div`
  color: ${TableTextColor};
  font-weight: ${SemiBoldFontWeight};
  font-size: ${ButtonText};
  text-align: center;
`

export const ProductRefContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const ProductTitle = styled.div`
  color: ${TableTextColor};
  font-weight: ${SemiBoldFontWeight};
  font-size: ${BodyText};
`

export const ProductDescription = styled.div`
  color: ${({$color}) => $color || TableTextColor};
  font-weight: ${({$weight}) => $weight || BookFontWeight};
  font-size: ${BodyText};
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 22px;
  height: 22px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`

export const ColorsContainer = styled.div`
  color: ${TableTextColor};
  font-weight: ${SemiBoldFontWeight};
  width: 100%;
  overflow: hidden;
  font-size: ${BodyText};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0px;
  border-top: 1px solid ${BorderColor};
  border-bottom: 1px solid ${BorderColor};
`

export const SingleColorContainer = styled.div`
  display: inline-block;
  border: 1px solid ${BorderColor};
  padding: 1px;
  border-radius: 50%;
  margin-left: ${({$marginLeft}) => $marginLeft || '5px'};
  margin-right: ${({$marginRight}) => $marginRight || '0px'};
`

export const ProductColor = styled.div`
  ${({$file, $color}) => {
    if ($file) {
      return `
        background: url(${$file});
      `
    }

    if ($color) {
      return `
        background-color: ${$color};
      `
    }
  }};
    ${({$border}) => {
    if ($border) {
      return `
        border: 1px solid ${$border};
      `
    }
  }};
  border-radius: 50%;
  height: ${({$bigger}) => $bigger ? 15 : 10}px;
  width: ${({$bigger}) => $bigger ? 15 : 10}px;
`

export const ProductCardSection = styled.div`
  width: 100%;
  margin-top: 25px;
  opacity: ${({$disabled}) => ($disabled ? 0.5 : 1)};
  cursor: ${({$disabled}) => ($disabled ? 'not-allowed' : 'pointer')};
  position: relative;
`

export const ProductSelection = styled(Checkbox)`
  position: absolute;
  top: 5px;
  left: 5px;
`
