import React, {useState} from 'react'

import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'
import {FieldArray} from 'redux-form'

import Trash from '../../../../assets/icons/remove.svg'
import BaseButton from '../../../../shared/components/buttons/BaseButton'
import SelectInput from '../../../../shared/components/inputs/SelectInput'
import BaseTable from '../../../../shared/components/table/BaseTable'
import ActiveTranslation from '../../../../shared/logic/translations/ActiveTranslation'
import {Margin} from '../../../../shared/styles/BasicStyles'

const SupplierServicesComponent = ({fields, services}) => {
  const [selected, setSelected] = useState([])
  const [service, setService] = useState('')
  const rows = fields.getAll() || []

  const getColumns = () => [
    {
      title: <Translate id='NAME' />,
      type: 'text',
      dataIndex: 'service',
      render: (value) => (
        <ActiveTranslation value={value?.serviceTranslation} tag='name' />
      )
    }
  ]

  const addService = () => {
    const item = services.find((x) => x.serviceId == service)
    if (item) {
      const object = {
        active: true,
        service: item
      }
      fields.unshift(object)
      setService('')
    }
  }

  const deleteSelected = () => {
    const indexes = selected.map((element) =>
      rows.findIndex(
        (x) =>
          x?.service?.serviceId === element?.record?.service?.serviceId
      )
    )
    indexes
      .sort((a, b) => (a > b ? -1 : 1))
      .forEach((i) => fields.remove(i))
    setSelected([])
  }

  const filteredServices = () =>
    services.filter(
      (p) =>
        !rows.find(
          (t) => t.service.serviceId === p.serviceId && t.active === true
        )
    )

  return (
    <>
      <Row align='bottom'>
        <Col xs={6}>
          <SelectInput
            label={<Translate id='SERVICES' />}
            placeholder={<Translate id='SELECT_SERVICE' />}
            data={filteredServices()}
            dataLabel='serviceTranslation'
            dataKey='serviceId'
            input={{value: service, onChange: setService}}
          />
        </Col>
        <Col xs={2} offset={1}>
          <BaseButton
            disabled={!service}
            auto
            type='secondary'
            onClick={addService}
          >
            <Translate id='ADD' />
          </BaseButton>
        </Col>
      </Row>
      <Margin size={40} />
      <Row xs={24}>
        <Col xs={24}>
          <BaseButton
            disabled={selected?.length <= 0}
            onClick={deleteSelected}
            type='secondary'
            imagePath={Trash}
            auto
          >
            <Translate id='DELETE' />
          </BaseButton>
        </Col>
      </Row>
      <Margin size={16} />
      <Row>
        <Col xs={24}>
          <BaseTable
            rowKey={(id) => id.service.serviceId}
            datasource={rows.filter((x) => x.active === true)}
            columns={getColumns()}
            pagination={{render: false}}
            rowSelection={{
              selected,
              onRowSelection: setSelected
            }}
          />
        </Col>
      </Row>
    </>
  )
}

SupplierServicesComponent.propTypes = {
  services: PropTypes.array.isRequired
}

const SupplierServices = ({services}) => (
  <FieldArray
    component={SupplierServicesComponent}
    name='supplierService'
    services={services}
  />
)

SupplierServices.propTypes = {
  services: PropTypes.array.isRequired
}

export default SupplierServices
