import React, { useEffect, useState } from 'react'

import { Row, Col } from 'antd'
import PropTypes from 'prop-types'
import { Translate, withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import { useParams } from 'react-router'
import {
  reduxForm,
  initialize,
  getFormValues,
  FieldArray,
  change,
  arrayPush,
  arrayRemove,
  arrayInsert
} from 'redux-form'

import DropdownBlue from '../../../assets/icons/dropdown_blue.svg'
import DuplicateIcon from '../../../assets/icons/duplicate.svg'
import {
  EditProductPrices,
  EditProductProperties,
  SaveMeasures,
  EditProductComposition,
  EditProductOnlineStore,
  GetProduct,
  EditProductSKU,
} from '../../../infra/requests/ProductsRequests'
import { GetSuppliersList } from '../../../infra/requests/SupplierRequests'
import AlertService from '../../../shared/components/alert/AlertService'
import BackButton from '../../../shared/components/buttons/BackButton'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import BaseLoading from '../../../shared/components/loading/BaseLoading'
import { ArrayPushIfNotExist } from '../../../shared/logic/arrays/ArrayFunctions'
import {
  ConstructProperties,
  ConstructComposition
} from '../../../shared/logic/products/ProductConstructors'
import {
  PageForm,
  HeaderCollapsable,
  ToggleImage,
  ContentCollapsable,
  CollapsableSubGroup,
  TitleH4,
  StyledHR,
  PageContent,
  Margin
} from '../../../shared/styles/BasicStyles'
import { CollapsableMenuSection, SaveButton } from '../ProductsStyles'
import AddMaterialsPage from './AddMaterialsPage'
import AddServicesPage from './AddServicesPage'
import ProductHeader from './components/0_ProductHeader'
import ProductsMeasures from './components/10_Measures'
import PackageDetail from './components/11_PackageDetail'
import GeneralInformation from './components/1_GeneralInformation'
import ProductDetails from './components/2_ProductDetails'
import ProductImages from './components/3_ProductImages'
import SEOArrayData from './components/4_SEOData'
import ProductDescriptions from './components/5_ProductDescriptions'
import ProductComposition from './components/6_ProductComposition'
import ProductSKUs from './components/7_SKU'
import ProductPrices from './components/8_ProductPrices'
import OnlineStore from './components/9_OnlineStore'
import RelatedProductsPage from './RelatedProductsPage'

const getRefSubString = (value) => {
  if (!value || typeof value !== 'string') return ''
  return value.substring(0, 6)
}

const GetColors = (product) => {
  const result = []
  if (product?.productSKUSection?.productCombination) {
    product.productSKUSection.productCombination.forEach((combination) => {
      ArrayPushIfNotExist(
        result,
        combination.color,
        (elem) => elem.colorId === combination.color.colorId
      )
    })
  }
  return result
}

const GetSizes = (product) => {
  const result = []
  if (product?.productSKUSection?.productCombination) {
    product.productSKUSection.productCombination.forEach(
      ({ size, productWidthId }) => {
        const index = result.findIndex((s) => s.sizeId === size.sizeId)

        if (index > -1) {
          const foundWidth = result[index].widths.find(
            (w) => w === productWidthId
          )
          if (!foundWidth) result[index].widths.push(productWidthId)
        } else {
          const obj = { ...size, widths: [productWidthId] }
          result.push(obj)
        }
      }
    )
  }
  return result.sort((a, b) => a.order - b.order)
}

const GetAvailableWidths = (sizes) => {
  const result = []
  sizes.forEach((size) => {
    if (size && size.widths) {
      size.widths.forEach((width) => {
        ArrayPushIfNotExist(result, width)
      })
    }
  })
  return result
}

const GetComposition = (composition) => {
  const materials = composition?.rawMaterialList || []
  const services = composition?.productSupplier || []

  const result = []

  materials.forEach((item) => {
    if (item.rawMaterialFamilyId) {
      const found = result.findIndex(
        (r) => r.rawMaterialFamilyId === item.rawMaterialFamilyId
      )
      if (found > -1) {
        result[found].list.push({
          ...item,
          lineId: `m${item.productId}-${item.rawMaterialColorId}`,
          type: 'material'
        })
      } else {
        result.push({
          ...item,
          lineId: `f${item.rawMaterialFamilyId}`,
          type: 'family',
          productTranslation:
            item.rawMaterialFamily.rawMaterialFamilyTranslation,
          referenceCode: getRefSubString(item.referenceCode),
          referenceSupplier: '-',
          list: [{ ...item, lineId: `m${item.productId}-${item.rawMaterialColorId}`, type: 'material' }]
        })
      }
    } else {
      result.push({
        ...item,
        lineId: `m${item.productId}-${item.rawMaterialColorId}`,
        type: 'material'
      })
    }
  })

  services.forEach((service) => {
    result.push({
      ...service,
      lineId: `s${service.serviceId}`,
      type: 'service'
    })
  })

  return result.sort((a, b) => (a.priority > b.priority ? 1 : -1))
}

const ManageProductPage = ({
  router,
  handleSubmit,
  dispatch,
  currentFormValues,
  productTypes,
  productCategories,
  productStyles,
  productWidths,
  translate
}) => {
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [mainCategoryToggle, setMainCategoryToggle] = useState(undefined)
  const [subCategoryToggle, setSubCategoryToggle] = useState(undefined)
  const [colors, setColors] = useState(undefined)
  const [sizes, setSizes] = useState(undefined)
  const [widths, setWidths] = useState(undefined)
  const [related, setRelated] = useState({ show: false })
  const [showMaterials, setShowMaterials] = useState(false)
  const [showServices, setShowServices] = useState(false)
  const [serviceSuppliers, setSuppliers] = useState([])
  const [isoutlet, setIsOutlet] = useState()
  const { id } = useParams()

  useEffect(() => {
    async function fetchProduct() {
      const { data, success } = await GetProduct(id)
      if (success) {
        setIsOutlet(data.productPropertiesSection.isOutlet)
        if (data?.productPropertiesSection?.productTypeId == 4) {
          return router.history.push(`/materials/${data.productId}`)
        }
        dispatch(
          initialize('manage_product', {
            ...data,
            composition: GetComposition(data.productCompositionSection)
          })
        )
        setColors(GetColors(data))
        const sizeList = GetSizes(data)
        setSizes(sizeList)
        setWidths(GetAvailableWidths(sizeList))
        const result = await GetSuppliersList()
        setSuppliers(result?.data?.items || [])
        setLoading(false)
      }
    }
    fetchProduct()
  }, [])


  const onSubmit = async (values) => {
    try {
      setSaving(true)


      values.productOnlineStoreSection.isOutlet = isoutlet;

      await EditProductComposition(
        id,
        ConstructComposition(values, sizes, widths)
      )

      await EditProductProperties(id, ConstructProperties(values))
      await SaveMeasures(id, values?.productMeasureSection)
      await EditProductPrices(id, values?.productPriceSection)
      await EditProductOnlineStore(id, values?.productOnlineStoreSection)
      await EditProductSKU(id, values?.productSKUSection);

      setSaving(false)
      AlertService.success(
        translate('SUCCESS'),
        translate('PRODUCT_EDITED_SUCCESSFULY')
      )
    } catch (errors) {
      setSaving(false)
      AlertService.error(
        translate('ERRORS_COMPOSITION'),
        <div>
          {errors.map((error, index) => (
            <div key={index}>{translate(error.tag, { value: error.value })}</div>
          ))}
        </div>
      )
    }
  }

  const setRelatedProduct = (item) => {
    if (item) {
      const list =
        currentFormValues?.productOnlineStoreSection
          ?.productRelationProduct || []
      const found = list.find((l) => l.productRelatedId === item.productId)
      if (!found) {
        if (related.index >= 0) {
          if (related.index < list.length) {
            dispatch(
              arrayRemove(
                'manage_product',
                'productOnlineStoreSection.productRelationProduct',
                related.index
              )
            )
            dispatch(
              arrayInsert(
                'manage_product',
                'productOnlineStoreSection.productRelationProduct',
                related.index,
                {
                  productId: id,
                  productRelatedId: item.productId,
                  productRelated: item
                }
              )
            )
          } else {
            dispatch(
              arrayPush(
                'manage_product',
                'productOnlineStoreSection.productRelationProduct',
                {
                  productId: id,
                  productRelatedId: item.productId,
                  productRelated: item
                }
              )
            )
          }
        } else {
          dispatch(
            arrayPush(
              'manage_product',
              'productOnlineStoreSection.productRelationProduct',
              {
                productId: id,
                productRelatedId: item.productId,
                productRelated: item
              }
            )
          )
        }
      }
    }
    setRelated({ show: false })
  }

  const handleNewMaterials = (items) => {
    const composition = currentFormValues?.composition || []
    items.forEach((item) => {
      if (item.rawMaterialFamilyId) {
        const found = composition.findIndex(
          (x) => x.rawMaterialFamilyId === item.rawMaterialFamilyId
        )
        if (found > -1) {
          composition[found].list.push({
            ...item,
            lineId: `m${item.productId}-${item.rawMaterialColorId}`,
            type: 'material',
            widths,
            quantity: 0,
            consumption: 0
          })
        } else {
          const family = {
            ...item,
            lineId: `f${item.rawMaterialFamilyId}`,
            type: 'family',
            productTranslation:
              item.rawMaterialFamily.rawMaterialFamilyTranslation,
            referenceCode: getRefSubString(item.referenceCode),
            referenceSupplier: '-',
            list: [
              {
                ...item,
                type: 'material',
                lineId: `m${item.productId}-${item.rawMaterialColorId}`,
                widths
              }
            ],
            quantity: 0,
            priority: composition.length + 1,
            consumption: 0,
            colors: colors.map((c) => c.colorId)
          }
          composition.push(family)
        }
      } else {
        const found = composition.find((x) =>
          x.productId === item.productId && x.rawMaterialColorId === item.rawMaterialColorId)
        if (!found) {
          composition.push({
            ...item,
            lineId: `m${item.productId}-${item.rawMaterialColorId}`,
            type: 'material',
            quantity: 0,
            consumption: 0,
            priority: composition.length + 1,
            colors: colors.map((c) => c.colorId),
            widths
          })
        }
      }
    })
    dispatch(change('manage_product', 'composition', composition))
    setShowMaterials(false)
  }

  const handleNewServices = (items) => {
    const composition = currentFormValues?.composition || []
    items.forEach((service) => {
      composition.push({
        ...service,
        productId: id,
        productTypeId:
          currentFormValues?.productPropertiesSection?.productTypeId,
        lineId: `s${service.serviceId}`,
        type: 'service',
        s: { service },
        moq: 1,
        maq: 1,
        units: 1,
        leadTime: 0,
        priority: composition.length + 1
      })
    })
    dispatch(change('manage_product', 'composition', composition))
    setShowServices(false)
  }
  if (loading) return <BaseLoading margin={200} />

  if (related.show) {
    return <RelatedProductsPage original={id} setRelatedProduct={setRelatedProduct} />
  }

  if (showMaterials) {
    return (
      <AddMaterialsPage
        addMaterials={handleNewMaterials}
        onClose={() => setShowMaterials(false)}
      />
    )
  }

  if (showServices) {
    return (
      <AddServicesPage
        addServices={handleNewServices}
        onClose={() => setShowServices(false)}
      />
    )
  }

  return (
    <PageForm $margin='20px 0px 0px 0px' onSubmit={handleSubmit(onSubmit)}>
      <PageContent $noMargin $noMinHeight>
        <Row>
          <Col xs={24}>
            <BackButton
              label={<Translate id='BACK_PRODUCTS' />}
              onClick={() =>
                router.history.push(
                  `/products#${currentFormValues?.productPropertiesSection?.isB2c
                    ? 'b2c'
                    : 'b2b'
                  }`
                )
              }
            />
          </Col>
        </Row>
        <Margin size={24} />
        <Row justify='end'>
          <Col xs={16} lg={6}>
            <BaseButton
              onClick={() =>
                router.history.push(`/products/${id}/duplicate`)
              }
              type='secondary'
              imagePath={DuplicateIcon}
            >
              <Translate id='DUPLICATE_EXISTING' />
            </BaseButton>
          </Col>
        </Row>
        <Margin size={15} />
        <StyledHR $space={15} />
        <ProductHeader product={currentFormValues} colors={colors} />
      </PageContent>
      <PageContent $noMargin $noMinHeight>
        <Row>
          <Col xs={24}>
            <CollapsableMenuSection open={mainCategoryToggle === 0}>
              <HeaderCollapsable
                $direction='flex-start'
                onClick={() => {
                  setSubCategoryToggle(undefined)
                  setMainCategoryToggle(
                    mainCategoryToggle === 0 ? undefined : 0
                  )
                }}
              >
                <ToggleImage
                  src={DropdownBlue}
                  $open={mainCategoryToggle === 0}
                />
                <TitleH4 $light>
                  <Translate id='PROPERTIES' />
                </TitleH4>
              </HeaderCollapsable>
              <ContentCollapsable open={mainCategoryToggle === 0}>
                <CollapsableSubGroup>
                  <CollapsableMenuSection open={subCategoryToggle === 0}>
                    <HeaderCollapsable
                      $direction='flex-start'
                      onClick={() => {
                        setSubCategoryToggle(
                          subCategoryToggle === 0 ? undefined : 0
                        )
                      }}
                    >
                      <ToggleImage
                        src={DropdownBlue}
                        $open={subCategoryToggle === 0}
                      />
                      <TitleH4 $light>
                        <Translate id='GENERAL_INFO' />
                      </TitleH4>
                    </HeaderCollapsable>
                    <ContentCollapsable open={subCategoryToggle === 0}>
                      <CollapsableSubGroup $content>
                        {subCategoryToggle === 0 && (
                          <GeneralInformation
                            productCategories={productCategories}
                            dispatch={dispatch}
                            productTypes={productTypes}
                            productStyles={productStyles}
                            productPropertiesSection={
                              currentFormValues?.productPropertiesSection
                            }
                          />
                        )}
                      </CollapsableSubGroup>
                    </ContentCollapsable>
                  </CollapsableMenuSection>
                  <CollapsableMenuSection open={subCategoryToggle === 4}>
                    <HeaderCollapsable
                      $direction='flex-start'
                      onClick={() => {
                        setSubCategoryToggle(
                          subCategoryToggle === 4 ? undefined : 4
                        )
                      }}
                    >
                      <ToggleImage
                        src={DropdownBlue}
                        $open={subCategoryToggle === 4}
                      />
                      <TitleH4 $light>
                        <Translate id='NAME_DESCRIPTION' />
                      </TitleH4>
                    </HeaderCollapsable>
                    <ContentCollapsable open={subCategoryToggle === 4}>
                      <CollapsableSubGroup $content>
                        {subCategoryToggle === 4 && (
                          <FieldArray
                            dispatch={dispatch}
                            name='productPropertiesSection.productTranslation'
                            component={ProductDescriptions}
                          />
                        )}
                      </CollapsableSubGroup>
                    </ContentCollapsable>
                  </CollapsableMenuSection>
                  <CollapsableMenuSection open={subCategoryToggle === 1}>
                    <HeaderCollapsable
                      $direction='flex-start'
                      onClick={() => {
                        setSubCategoryToggle(
                          subCategoryToggle === 1 ? undefined : 1
                        )
                      }}
                    >
                      <ToggleImage
                        src={DropdownBlue}
                        $open={subCategoryToggle === 1}
                      />
                      <TitleH4 $light>
                        <Translate id='DETAILS' />
                      </TitleH4>
                    </HeaderCollapsable>
                    <ContentCollapsable open={subCategoryToggle === 1}>
                      <CollapsableSubGroup $content>
                        {subCategoryToggle === 1 && <ProductDetails />}
                      </CollapsableSubGroup>
                    </ContentCollapsable>
                  </CollapsableMenuSection>

                  <CollapsableMenuSection open={subCategoryToggle === 2}>
                    <HeaderCollapsable
                      $direction='flex-start'
                      onClick={() => {
                        setSubCategoryToggle(
                          subCategoryToggle === 2 ? undefined : 2
                        )
                      }}
                    >
                      <ToggleImage
                        src={DropdownBlue}
                        $open={subCategoryToggle === 2}
                      />
                      <TitleH4 $light>
                        <Translate id='IMAGES' />
                      </TitleH4>
                    </HeaderCollapsable>
                    <ContentCollapsable open={subCategoryToggle === 2}>
                      <CollapsableSubGroup $content>
                        {subCategoryToggle === 2 && (
                          <FieldArray
                            component={ProductImages}
                            name='productPropertiesSection.productImage'
                            colors={colors}
                            changeImages={(data) =>
                              dispatch(
                                change(
                                  'manage_product',
                                  'productPropertiesSection.productImage',
                                  data
                                )
                              )
                            }
                          />
                        )}
                      </CollapsableSubGroup>
                    </ContentCollapsable>
                  </CollapsableMenuSection>

                  <CollapsableMenuSection open={subCategoryToggle === 3}>
                    <HeaderCollapsable
                      $direction='flex-start'
                      onClick={() => {
                        setSubCategoryToggle(
                          subCategoryToggle === 3 ? undefined : 3
                        )
                      }}
                    >
                      <ToggleImage
                        src={DropdownBlue}
                        $open={subCategoryToggle === 3}
                      />
                      <TitleH4 $light>
                        <Translate id='SEO' />
                      </TitleH4>
                    </HeaderCollapsable>
                    <ContentCollapsable open={subCategoryToggle === 3}>
                      <CollapsableSubGroup $content>
                        {subCategoryToggle === 3 && (
                          <FieldArray
                            name='productPropertiesSection.productTranslation'
                            dispatch={dispatch}
                            component={SEOArrayData}
                          />
                        )}
                      </CollapsableSubGroup>
                    </ContentCollapsable>
                  </CollapsableMenuSection>
                  <CollapsableMenuSection
                    hideBorder
                    open={subCategoryToggle === 5}
                  >
                    <HeaderCollapsable
                      $direction='flex-start'
                      onClick={() => {
                        setSubCategoryToggle(
                          subCategoryToggle === 5 ? undefined : 5
                        )
                      }}
                    >
                      <ToggleImage
                        src={DropdownBlue}
                        $open={subCategoryToggle === 5}
                      />
                      <TitleH4 $light>
                        <Translate id='PACKAGE' />
                      </TitleH4>
                    </HeaderCollapsable>
                    <ContentCollapsable open={subCategoryToggle === 5}>
                      <CollapsableSubGroup $content>
                        {subCategoryToggle === 5 && <PackageDetail />}
                      </CollapsableSubGroup>
                    </ContentCollapsable>
                  </CollapsableMenuSection>
                </CollapsableSubGroup>
              </ContentCollapsable>
            </CollapsableMenuSection>

            <CollapsableMenuSection open={mainCategoryToggle === 1}>
              <HeaderCollapsable
                $direction='flex-start'
                onClick={() => {
                  setSubCategoryToggle(undefined)
                  setMainCategoryToggle(
                    mainCategoryToggle === 1 ? undefined : 1
                  )
                }}
              >
                <ToggleImage
                  src={DropdownBlue}
                  $open={mainCategoryToggle === 1}
                />
                <TitleH4 $light>
                  <Translate id='SKU' />
                </TitleH4>
              </HeaderCollapsable>
              <ContentCollapsable open={mainCategoryToggle === 1}>
                {mainCategoryToggle === 1 && (
                  <FieldArray
                    name='productSKUSection.productCombination'
                    component={ProductSKUs}
                    colors={colors}
                    sizes={sizes}
                    widths={productWidths}
                    dispatch={dispatch}
                  />
                )}
              </ContentCollapsable>
            </CollapsableMenuSection>

            <CollapsableMenuSection open={mainCategoryToggle === 2}>
              <HeaderCollapsable
                $direction='flex-start'
                onClick={() => {
                  setSubCategoryToggle(undefined)
                  setMainCategoryToggle(
                    mainCategoryToggle === 2 ? undefined : 2
                  )
                }}
              >
                <ToggleImage
                  src={DropdownBlue}
                  $open={mainCategoryToggle === 2}
                />
                <TitleH4 $light>
                  <Translate id='COMPOSITION' />
                </TitleH4>
              </HeaderCollapsable>
              <ContentCollapsable open={mainCategoryToggle === 2}>
                {mainCategoryToggle === 2 && (
                  <FieldArray
                    name='composition'
                    component={ProductComposition}
                    colors={colors}
                    sizes={sizes}
                    widths={widths}
                    showMaterials={setShowMaterials}
                    setShowServices={setShowServices}
                    dispatch={dispatch}
                    serviceSuppliers={serviceSuppliers}
                    reference={currentFormValues?.referenceCode}
                  />
                )}
              </ContentCollapsable>
            </CollapsableMenuSection>

            <CollapsableMenuSection open={mainCategoryToggle === 3}>
              <HeaderCollapsable
                $direction='flex-start'
                onClick={() => {
                  setSubCategoryToggle(undefined)
                  setMainCategoryToggle(
                    mainCategoryToggle === 3 ? undefined : 3
                  )
                }}
              >
                <ToggleImage
                  src={DropdownBlue}
                  $open={mainCategoryToggle === 3}
                />
                <TitleH4 $light>
                  <Translate id='MEASURE' />
                </TitleH4>
              </HeaderCollapsable>
              <ContentCollapsable open={mainCategoryToggle === 3}>
                {mainCategoryToggle === 3 && (
                  <FieldArray
                    name='productMeasureSection.productMeasure'
                    component={ProductsMeasures}
                    sizes={sizes}
                    productId={id}
                  />
                )}
              </ContentCollapsable>
            </CollapsableMenuSection>

            <CollapsableMenuSection open={mainCategoryToggle === 4}>
              <HeaderCollapsable
                $direction='flex-start'
                onClick={() => {
                  setSubCategoryToggle(undefined)
                  setMainCategoryToggle(
                    mainCategoryToggle === 4 ? undefined : 4
                  )
                }}
              >
                <ToggleImage
                  src={DropdownBlue}
                  $open={mainCategoryToggle === 4}
                />
                <TitleH4 $light>
                  <Translate id='PRICE' />
                </TitleH4>
              </HeaderCollapsable>
              <ContentCollapsable open={mainCategoryToggle === 4}>
                {mainCategoryToggle === 4 && (
                  <FieldArray
                    component={ProductPrices}
                    name='productPriceSection.productPrice'
                  />
                )}
              </ContentCollapsable>
            </CollapsableMenuSection>

            <CollapsableMenuSection open={mainCategoryToggle === 5}>
              <HeaderCollapsable
                $direction='flex-start'
                onClick={() => {
                  setSubCategoryToggle(undefined)
                  setMainCategoryToggle(
                    mainCategoryToggle === 5 ? undefined : 5
                  )
                }}
              >
                <ToggleImage
                  src={DropdownBlue}
                  $open={mainCategoryToggle === 5}
                />
                <TitleH4 $light>
                  <Translate id='ONLINE_STORE' />
                </TitleH4>
              </HeaderCollapsable>
              <ContentCollapsable open={mainCategoryToggle === 5}>
                {mainCategoryToggle === 5 && (
                  <FieldArray
                    component={OnlineStore}
                    name='productOnlineStoreSection.productRelationProduct'
                    router={router}
                    colors={colors}
                    outlet={isoutlet}
                    setoutlet={setIsOutlet}
                    showRelated={(index) =>
                      setRelated({ show: true, index })
                    }
                  />
                )}
              </ContentCollapsable>
            </CollapsableMenuSection>
          </Col>
        </Row>
        <Margin size={50} />
      </PageContent>
      <SaveButton>
        <BaseButton auto htmlType='submit' type='primary' loading={saving}>
          <Translate id='SAVE' />
        </BaseButton>
      </SaveButton>
    </PageForm>
  )
}

ManageProductPage.propTypes = {
  router: PropTypes.object.isRequired,
  currentFormValues: PropTypes.object,
  dispatch: PropTypes.func,
  handleSubmit: PropTypes.func,
  productTypes: PropTypes.array,
  productCategories: PropTypes.array,
  productStyles: PropTypes.array,
  productWidths: PropTypes.array
}

ManageProductPage.defaultProps = {
  currentFormValues: {},
  dispatch: undefined,
  handleSubmit: undefined,
  productTypes: [],
  productCategories: [],
  productStyles: [],
  productWidths: []
}

const myComponent = reduxForm({
  form: 'manage_product'
})(withLocalize(ManageProductPage))

export default connect((state) => ({
  currentFormValues: getFormValues('manage_product')(state),
  productTypes: state.info.productTypes,
  productCategories: state.info.productCategories,
  productStyles: state.info.productStyles,
  productWidths: state.info.productWidths
}))(myComponent)
