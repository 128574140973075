import React, { useState, useEffect } from 'react'

import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'

import {
  StyledTabs,
  StyledTabPane,
  StyledTabTitleContainer,
  StyledTabTitle,
  ContentContainer
} from '../../shared/styles/BasicStyles'
import OrdersB2B from './b2b/OrdersB2B'
import OrdersB2B2C from './b2b2c/OrdersB2B2C'
import OrdersB2C from './b2c/OrdersB2C'
import SmartOrders from './smart_orders/SmartOrders'

const Sections = ['b2b', 'b2c', 'b2b2c', 'smart']

const OrdersPage = ({ router }) => {
  const [section, setSection] = useState()
  const iFrameDetection = ((window === window.parent || window.opener))

  useEffect(() => {
    function initialize() {
      const hash = router.location.hash.replace('#', '')
      if (Sections.find((s) => s === hash)) {
        setSection(hash)
      } else setSection('b2b')
    }
    initialize()
  }, [router])

  const onChangeTab = (active) => {
    setSection(active)
    router.history.replace(`/orders#${active}`)
  }

  return (
    <>
      <ContentContainer>
        <StyledTabs hideAdd="false" destroyInactiveTabPane activeKey={section} onChange={onChangeTab} $hidden={!iFrameDetection} >
          <StyledTabPane

            tab={
              <StyledTabTitleContainer>
                <StyledTabTitle>
                  <Translate id='B2B' />
                </StyledTabTitle>
              </StyledTabTitleContainer>
            }
            key='b2b'
          >
            {section === 'b2b' && <OrdersB2B router={router} />}
          </StyledTabPane>
          <StyledTabPane
            tab={
              <StyledTabTitleContainer>
                <StyledTabTitle>
                  <Translate id='B2C' />
                </StyledTabTitle>
              </StyledTabTitleContainer>
            }
            key='b2c'
          >
            {section === 'b2c' && <OrdersB2C router={router} />}
          </StyledTabPane>
          <StyledTabPane
            tab={
              <StyledTabTitleContainer>
                <StyledTabTitle>
                  <Translate id='B2B2C' />
                </StyledTabTitle>
              </StyledTabTitleContainer>
            }
            key='b2b2c'
          >
            {section === 'b2b2c' && <OrdersB2B2C router={router} />}
          </StyledTabPane>
          <StyledTabPane
            tab={
              <StyledTabTitleContainer>
                <StyledTabTitle>
                  <Translate id='SMART_ORDERS' />
                </StyledTabTitle>
                {/* <StyledTabBadge>XXX</StyledTabBadge> */}
              </StyledTabTitleContainer>
            }
            key='smart'
          >
            {section === 'smart' && <SmartOrders router={router} />}
          </StyledTabPane>
        </StyledTabs>
      </ContentContainer>
    </>
  )
}

OrdersPage.propTypes = {
  router: PropTypes.object.isRequired
}

export default OrdersPage
