import React, { useState } from 'react'

import { MailOutlined } from '@ant-design/icons'
import { Row } from 'antd'
import PropTypes from 'prop-types'
import { Translate, withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import { Field, reduxForm, getFormValues } from 'redux-form'

import {
  AuthForm,
  AuthHeaderBackground,
  AuthLogo,
  AuthHeaderTitle,
  AuthHeaderDescription,
  AuthBottom,
  AuthTitle,
  AuthBody,
  Box
} from './AuthStyles'
import AuthImageTextInput from './components/AuthImageTextInput'
import LogoImage from '../../assets/client_logo.png'
import { RecoverPassword } from '../../infra/requests/AuthRequests'
import FormValidator from '../../infra/services/validations/FormValidator'
import AlertService from '../../shared/components/alert/AlertService'
import BaseButton from '../../shared/components/buttons/BaseButton'
import FooterCopyright from '../../shared/components/layout/footer/FooterCopyright'
import { StyledFlex, Margin } from '../../shared/styles/BasicStyles'

const formRules = FormValidator.make({
  email: 'required|email'
})

const Recover = ({ router, handleSubmit, translate }) => {
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = async ({ email }) => {
    setIsLoading(true)
    const payload = { email }

    try {
      const { success } = await RecoverPassword(payload)
      if (success) {
        setIsLoading(false)
        AlertService.success(
          'Success',
          'Recover password email has been sent.'
        )
        router.history.push('/login')
      } else {
        setIsLoading(false)
      }
    } catch (e) {
      console.error(e)
      setIsLoading(false)
    }
  }

  return (
    <AuthForm onSubmit={handleSubmit(onSubmit)}>
      <AuthLogo src={LogoImage} />
      <AuthHeaderBackground>
        <StyledFlex $justify='center' align='center'>
          <AuthHeaderTitle>
            <Translate id='WELCOME_PORTAL' />
          </AuthHeaderTitle>
          <AuthHeaderDescription>
            <Translate id='WELCOME_PORTAL_INTRO' />
          </AuthHeaderDescription>
        </StyledFlex>
      </AuthHeaderBackground>
      <AuthBody>
        <AuthTitle>
          <Translate id='RECOVER_PASSWORD' />
        </AuthTitle>
        <Field
          name='email'
          type='email'
          image={<MailOutlined />}
          component={AuthImageTextInput}
          placeholder={translate('E_MAIL')}
        />
      </AuthBody>
      <Row justify='center' align='middle'>
        <Box>
          <BaseButton htmlType='submit' type='primary' loading={isLoading}>
            <Translate id='RECOVER' />
          </BaseButton>
        </Box>
      </Row>
      <Margin size='15' />
      <Row justify='center' align='middle'>
        <Box>
          <BaseButton
            type='secondary'
            onClick={() => router.history.push('/login')}
            disabled={isLoading}
          >
            <Translate id='BACK_TO_LOGIN' />
          </BaseButton>
        </Box>
      </Row>
      <AuthBottom>
        <FooterCopyright white />
      </AuthBottom>
    </AuthForm>
  )
}

Recover.propTypes = {
  router: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  translate: PropTypes.func
}

Recover.defaultProps = {
  translate: undefined
}

const RecoverPage = reduxForm({
  form: 'recover',
  validate: formRules,
  enableReinitialize: true
})(Recover)

export default withLocalize(
  connect((state) => ({
    currentFormValues: getFormValues('recover')(state),
    initialValues: { email: state.email.email }
  }))(RecoverPage)
)
