import React from 'react'

import {Col, Row} from 'antd'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {Field} from 'redux-form'

import IntegerInput from '../../../shared/components/inputs/IntegerInput'
import ProductLink from '../../../shared/components/links/ProductLink'
import BaseTable from '../../../shared/components/table/BaseTable'
import ActiveTranslation from '../../../shared/logic/translations/ActiveTranslation'
import {Margin} from '../../../shared/styles/BasicStyles'

const Columns = [
  {
    title: <Translate id='REF_SKYPRO' />,
    type: 'text',
    dataIndex: 'refSkypro',
    render: (value) => value || <Translate id='NA' />
  },
  {
    title: <Translate id='EAN' />,
    type: 'text',
    dataIndex: 'eaN13',
    render: (value) => value || <Translate id='NA' />
  },
  {
    title: <Translate id='DESCRIPTION' />,
    type: 'text',
    render: (value) => <ProductLink product={value} />
  },
  {
    title: 'Inventory',
    type: 'text',
    dataIndex: 'inventory'
  },
  {
    title: 'UM',
    type: 'text',
    dataIndex: 'mu',
    render: (value) => <ActiveTranslation value={value?.code} tag='name' />
  },
  {
    title: 'Unit Price',
    type: 'text',
    dataIndex: 'unitPrice'
  },
  {
    title: 'Counted Stock',
    type: 'text',
    render: (value, row, index) => (
      <Field
        name={`items[${index}].countedStock`}
        component={IntegerInput}
        min={0}
        placeholder='-'
      />
    )
  },
  {
    title: 'Differences',
    type: 'text',
    render: (value) =>
      value.countedStock
        ? (value.countedStock || 0) - (value.inventory || 0)
        : 0
  }
]

const InventoryCountTable = ({
  fields,
  page,
  total,
  pageSize,
  onChangePagination,
  loading
}) => {
  const inventoryitems = fields.getAll() || []
  return (
    <>
      <Margin size={20} />
      <Row>
        <Col xs={24}>
          <BaseTable
            rowKey={(row) =>
              `${row.productId}_${row.refSkypro}_${row.eaN13}`
            }
            datasource={inventoryitems}
            columns={Columns}
            loading={loading}
            pagination={{
              render: true,
              page,
              pageSize,
              total,
              onChangePagination
            }}
          />
        </Col>
      </Row>
    </>
  )
}

InventoryCountTable.propTypes = {
  onChangePagination: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  page: PropTypes.number,
  total: PropTypes.number,
  pageSize: PropTypes.number,
  loading: PropTypes.bool
}

InventoryCountTable.defaultProps = {
  page: 1,
  total: 0,
  pageSize: 12,
  loading: false
}

export default withLocalize(connect()(InventoryCountTable))
