import styled from 'styled-components'

import {
  SecondaryButtonColor,
  FooterBorderColor,
  FooterBackgroundColor,
  FooterTextColor
} from '../../../styles/_colors'
import {PageLeftRightMargin, FooterSize} from '../../../styles/_responsive'
import {
  Subtitle2Text,
  BookFontWeight,
  SemiBoldFontWeight,
  LightFontWeight,
  FooterText
} from '../../../styles/_texts'

export const FooterContainer = styled.footer`
  width: 100%;
  height: ${FooterSize};
  z-index: 100;

  @media (max-width: 630px) {
    height: 100%;
  }
`

export const CopyrightSection = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  height: 80px;
  padding: 0px ${PageLeftRightMargin};
  border-top: 1px solid ${SecondaryButtonColor};
  background-color: ${({$white}) => ($white ? '#FFF' : 'transparent')};
`

export const CopyrightImage = styled.img`
  height: 30px;
`
export const CopyrightText = styled.div`
  font-size: ${Subtitle2Text};
  font-weight: ${BookFontWeight};
  text-align: right;
`
export const BottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 4px solid ${FooterBorderColor};
  background-color: ${FooterBackgroundColor};
  padding: 25px ${PageLeftRightMargin} 40px ${PageLeftRightMargin};
  flex-wrap: wrap;
`

export const BottomLeft = styled.div`
  display: flex;
  flex-direction: row;

  div {
    &:first-child {
      margin-right: 35px;
    }
  }
`
export const BottomRight = styled.div``

export const FooterTitle = styled.div`
  font-weight: ${SemiBoldFontWeight};
  font-size: ${Subtitle2Text};
  line-height: 30px;
  color: ${FooterTextColor};
`
export const FooterDescription = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: ${LightFontWeight};
  font-size: ${FooterText};
  line-height: 25px;
  color: ${FooterTextColor};
  cursor: pointer;
`
export const FooterDescriptionImage = styled.img`
  margin-right: 10px;
`
