import React from 'react'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import {
  PropertyDescriptionContainer,
  TitleH5,
  BodyH5
} from '../../styles/BasicStyles'
import isInIframe from '../../../shared/utils/isInIframe';

const BodyText = styled(BodyH5)``

const PropertyDescription = ({ label, value, link }) => {

  const inIframe = isInIframe();
  return (
    <PropertyDescriptionContainer>
      <TitleH5>{label}</TitleH5>
      {link ? (
        <a target={inIframe ? '_self' : '_blank'} href={`/orders/b2b/${link}`}>{value}</a> // Render as a link if `link` is provided
      ) : (
        <BodyText>{value}</BodyText> // Otherwise, render as text
      )}
    </PropertyDescriptionContainer>

  )
}

PropertyDescription.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number
  ]),
  value: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number
  ])
}

PropertyDescription.defaultProps = {
  label: undefined,
  value: undefined
}

export default PropertyDescription
