import React, { useState, useEffect } from 'react'
import { Translate, withConnect, withLocalize } from 'react-localize-redux'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'
import {
  InputBox,
  InputLabel,
  SelectArrow,
  SelectInputStyled,
  SelectLoading
} from '../../../shared/components/inputs/InputStyles'
import { PrimaryColor } from '../../../shared/styles/_colors'
import { GetAllWarehouses } from '../../../infra/requests/LogisticsRequests'

const Line = styled.div``
const Label = styled.div`
  color: ${PrimaryColor};
  font-size: 16px;
  line-height: 22px;
`

const Info = styled.div`
  font-size: 12px;
  line-height: 14px;
`
const StockTransferSelect = ({
  input,
  meta,
  allowClear,
  disabled,
  afterChange,
  warehouses
}) => {
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [filters, setFilters] = useState({})

  useEffect(() => {
    async function init() {
      const refactor = []
      warehouses.forEach((current) => {
        refactor.push({
          label: ConstructLabel(current),
          value: current.warehouseId,
          code: current.code,
          name: current.name,
          b2bclient: current.b2bclientId
        })
      })
      setList(refactor || [])
    }
    init()
  }, [warehouses])

  const ConstructLabel = (current) => (
    <Line>
      <Label>{`${current?.code} - ${current?.name}`}</Label>
      <Info>
        <Translate id='LOCATION' />:{current?.contactDetail?.region}
      </Info>
      <Info>
        <Translate id='CLIENT' />:{current?.b2bclient?.name}
      </Info>
    </Line>
  )

  const handleChange = (value) => {
    input.onChange(value)
    if (typeof afterChange === 'function') {
      setTimeout(() => afterChange(value || undefined), 100)
    }
  }

  const defineValue = (value) => {
    if (value) {
      const found = list.find((x) => x.value === value)
      return found?.warehouseName || undefined
    }
    return undefined
  }

  const hasError = meta.invalid && meta.submitFailed

  return (
    <InputBox>
      {<Translate id='WAREHOUSE_NAME' /> && (
        <InputLabel>{<Translate id='WAREHOUSE_NAME' />}</InputLabel>
      )}
      <SelectInputStyled
        hasError={hasError}
        placeholder={<Translate id='SELECT' />}
        value={defineValue(input.value)}
        onChange={handleChange}
        allowClear={allowClear}
        options={list}
        disabled={disabled}
        loading={loading}
      />
    </InputBox>
  )
}
StockTransferSelect.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.element
  ]),
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.element
  ]),
  allowClear: PropTypes.bool,
  disabled: PropTypes.bool,
  afterChange: PropTypes.func
}

StockTransferSelect.defaultProps = {
  input: { value: undefined, onChange: () => null },
  label: undefined,
  placeholder: undefined,
  meta: {},
  allowClear: false,
  disabled: false,
  afterChange: undefined
}

export default withLocalize(StockTransferSelect)
