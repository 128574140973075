import styled from 'styled-components'

import {
  BorderColor,
  NavBackgroundColor,
  PageBackground,
  PrimaryButtonColor,
  PrimaryColor,
  SecondaryButtonColor,
  Sections,
  StatusActive,
  TableHeaderColor,
  TableTextColor
} from '../../shared/styles/_colors'
import {
  BodyText,
  BoldFontWeight,
  BookFontWeight,
  H1Text,
  LabelText,
  MediumFontWeight,
  OverlineText,
  Subtitle2Text,
  TabsText
} from '../../shared/styles/_texts'

export const SettingsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 25px;
`

export const SettingsBox = styled.div`
  background-color: ${NavBackgroundColor};
  width: 33%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  margin-right: ${({marginR}) => marginR || 0}px;
  cursor: pointer;

  &:focus,
  &:hover {
    border: 2px solid ${PrimaryColor};
    border-radius: 4px;
  }
`
export const BoxHeader = styled.div`
  background-color: ${PageBackground};
  width: 100%;
  padding: 9px;
  font-size: ${OverlineText};
  line-height: 16px;
  font-weight: ${BoldFontWeight};
`

export const BoxContent = styled.div`
  width: 100%;
  padding: 18px;
  display: flex;
  flex-direction: row;
`
export const BoxImage = styled.img`
  width: 60px;
  height: 60px;
  margin-right: 17px;
`

export const BoxText = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const PageTitle = styled.div`
  font-size: ${H1Text};
  line-height: 41px;
  font-weight: ${BoldFontWeight};
  color: ${PrimaryButtonColor};
`

export const BoxTitle = styled.div`
  font-size: 24px;
  line-height: 27px;
  font-weight: ${BoldFontWeight};
  color: ${PrimaryButtonColor};
`

export const BoxDescription = styled.div`
  font-size: ${BodyText};
  line-height: 20px;
  font-weight: ${BookFontWeight};
`
export const StatusText = styled.div`
  font-size: ${BodyText};
  line-height: 20px;
  font-weight: ${BookFontWeight};
  color: ${({color}) => color || StatusActive};
`
export const SectionsText = styled.div`
  font-size: ${BodyText};
  line-height: 20px;
  font-weight: ${BookFontWeight};
  color: ${Sections};
`

export const PasswordText = styled.div`
  font-size: ${BodyText};
  line-height: 20px;
  font-weight: ${BookFontWeight};
  color: ${({$color}) => $color || StatusActive};
  cursor: pointer;
`
export const BackgroundImg = styled.img`
  width: 98px;
  height: 65px;
`
export const TitleGeral = styled.div`
  color: ${TableTextColor};
  font-size: ${TabsText};
  font-weight: ${BoldFontWeight};
  opacity: 50%;
  margin-left: 20px;
`
export const ProfilesBox = styled.div`
  background-color: ${NavBackgroundColor};
  width: 33%;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  margin-right: ${({marginR}) => marginR || 0}px;
`
export const ProfilesFooter = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${PageBackground};
  padding: 10px 30px 10px 30px;
  justify-content: space-between;
`
export const TableChildrenContainer = styled.div`
  margin-left: 170px;
  margin-bottom: 19px;
  display: flex;
`

export const ChildrenText = styled.div`
  border-left: 1px solid #c6c8ca;
  padding-left: 9px;
  width: ${({width}) => width || 0}px;
`
export const CompanyContainer = styled.div`
  display: flex;
  width: 100%;
  padding-right: 10px;
`

export const CompanySubContainer = styled.div`
  width: ${({width}) => width || '100%'};
`
export const CompanyButton = styled.div`
  padding-bottom: 20px;
  border-bottom: 0.3px solid ${BorderColor};
`
export const CompanyTable = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${SecondaryButtonColor};
  align-items: center;
  padding: 14px 25px 14px 25px;
  justify-content: space-between;
  cursor: pointer;
  border: 0.3px solid ${BorderColor};
`

export const CompanySubTable = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${NavBackgroundColor};
  align-items: center;
  padding: 14px 25px 14px 25px;
  justify-content: space-between;
  cursor: pointer;
`

export const MainTitle = styled.div`
  color: ${TableHeaderColor};
  font-size: ${LabelText};
  font-weight: ${MediumFontWeight};
  margin-left: ${({spaceL}) => spaceL || 0}px;
`
export const SecondaryTitle = styled.div`
  color: ${TableTextColor};
  font-size: ${LabelText};
  font-weight: ${BookFontWeight};
  margin-left: ${({spaceL}) => spaceL || 0}px;
  opacity: 50%;
`
export const SubTitle = styled.div`
  font-size: ${TabsText};
  font-weight: ${BoldFontWeight};
  color: ${TableTextColor};
  opacity: 50%;
`
