import React, {useState} from 'react'

import {CloseOutlined, CopyOutlined} from '@ant-design/icons'
import {Checkbox, Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {useParams, withRouter} from 'react-router'
import styled from 'styled-components'

import {DuplicateProductInformation} from '../../../infra/requests/ProductsRequests'
import AlertService from '../../../shared/components/alert/AlertService'
import BaseButton from '../../../shared/components/buttons/BaseButton'

export const PopupContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: white;
  transform: ${({open}) => (open ? 'scale(1,1)' : 'scale(1,0)')};
  transition: all 0.2s ease;
  height: 365px;
  transform-origin: bottom;
  cursor: default;
`

const Container = styled.div`
  display: inline-block;
  width: 100%;
  padding: 15px;
`

const CloseSection = styled.div`
  float: right;
  padding: 2px 3px;
  cursor: pointer;
  font-size: 18px;
  margin-top: -8px;
  margin-right: -8px;
  cursor: pointer;
`

const Title = styled.div`
  color: #231f20;
  font-weight: 600;
  font-size: 16px;
  margin: 10px 0;
`

const DuplicateButton = styled.div`
  margin-top: 30px;
  text-align: right;
`

const DuplicateProductCard = ({selected, onClose, history, translate}) => {
  const [loading, setLoading] = useState(false)
  const [areas, setAreas] = useState([])
  const {id} = useParams()

  const handleDuplication = async () => {
    setLoading(true)
    const {success, data} = await DuplicateProductInformation(id, {
      productId: selected, // selected, 4423
      sections: areas
    })
    if (success) {
      history.push(`/products/${id}`)
      AlertService.success(
        translate('SUCCESS'),
        translate(data || 'PROPERTIES_DUPLICATED_SUCCESSFULLY')
      )
    } else setLoading(false)
  }

  return (
    <Container onClick={(e) => e.stopPropagation()}>
      <CloseSection onClick={onClose}>
        <CloseOutlined />
      </CloseSection>
      <Title>Areas:</Title>
      <Checkbox.Group onChange={setAreas}>
        <Row gutter={[8, 8]}>
          <Col xs={24}>
            <Checkbox value='properties'>
              <Translate id='PROPERTIES' />
            </Checkbox>
          </Col>
          <Col xs={24}>
            <Checkbox value='composition'>
              <Translate id='COMPOSITION' />
            </Checkbox>
          </Col>
          <Col xs={24}>
            <Checkbox value='measure'>
              <Translate id='MEASURES' />
            </Checkbox>
          </Col>
          <Col xs={24}>
            <Checkbox value='prices'>
              <Translate id='PRICES' />
            </Checkbox>
          </Col>
          <Col xs={24}>
            <Checkbox value='onlineStore'>
              <Translate id='ONLINE_STORE' />
            </Checkbox>
          </Col>
        </Row>
      </Checkbox.Group>

      <DuplicateButton>
        <BaseButton
          onClick={handleDuplication}
          disabled={areas.length === 0}
          auto
          size='small'
          align='right'
          loading={loading}
          icon={<CopyOutlined />}
        >
          <Translate id='DUPLICATE' />
        </BaseButton>
      </DuplicateButton>
    </Container>
  )
}

DuplicateProductCard.propTypes = {
  selected: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}

export default withLocalize(withRouter(DuplicateProductCard))
