import React, { useState } from 'react'

import { Row, Col } from 'antd'
import PropTypes from 'prop-types'
import { withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import { reduxForm, getFormValues } from 'redux-form'

import DepartureData from './1_DepartureData'
import {
  ValidateDepartureData,
  ValidateStock
} from './ValidateTransfer'
import {
  CreateWarehouseTransfer
} from '../../../../infra/requests/DepartureRequests'
import FormValidator from '../../../../infra/services/validations/FormValidator'
import AlertService from '../../../../shared/components/alert/AlertService'
import BackButton from '../../../../shared/components/buttons/BackButton'
import BaseButton from '../../../../shared/components/buttons/BaseButton'
import {
  Margin,
  PageForm,
  PageTitle
} from '../../../../shared/styles/BasicStyles'

const formRules = FormValidator.make({
  originId: 'required',
  destinyId: 'required',
  departureDate: 'required'
  // carrierId: 'required',
  // incotermsId: 'required',
  // shippingDate: 'required',
  // contents: 'required'
})

const CreateWHTransferPage = ({ router, formValues, handleSubmit, translate }) => {
  const [loading, setLoading] = useState(false)

  const validateAndConvert = (value) => {
    if (typeof value === 'string') {
      const convertedValue = Number(value)

      if (!Number.isNaN(convertedValue)) {
        return convertedValue
      }
      return null
    } if (typeof value === 'number') {
      return value
    }
    return null
  }
  const onSubmit = async (values) => {
    const { errors, products } = ValidateDepartureData(values)
    if (errors.length) {
      return AlertService.showValidations(translate('MISSING_INFORMATION'), errors)
    }
    setLoading(true)
    const possibleDeparture = await ValidateStock(
      values.originId,
      products
    )

    if (!possibleDeparture) {
      return AlertService.showValidations(
        translate('INVALID_QUANTITIES'),
        translate('INVALID_DEPARTURE_QUANTITIES')
      )
    }

    const payload = {
      Date: values.departureDate,
      OriginWarehouseId: values.originId,
      DestinationWarehouseId: values.destinyId,
      ValidationRequired: values.requiresValidation,
      CarrierId: values.carrierId,
      IncotermsID: values.incotermsId,
      ShippingDate: values.shippingDate,
      Contents: values.contents,
      OrderB2BIds: values.ordersb2b,
      InputItemsList: products.map((p) => ({
        productId: p.productId,
        departQty: validateAndConvert(p.orderQuantity || p.quantity),
        departReserveQty: p.departReserved,
        sizeId: p.sizeId || p.productCombination[0].sizeId,
        colorId: p.color?.colorId || p.productCombination[0].color.colorId,
        productWidthId: p.productWidth?.productWidthId || p.productCombination[0].productWidth?.productWidthId
      }))
    }


    const { success } = await CreateWarehouseTransfer(payload)

    if (success) return router.history.push('/logistics#depart')

    setLoading(false)
  }

  return (
    <PageForm>
      <Row gutter={[0, 50]}>
        <Col xs={24}>
          <BackButton
            label='Back to logistics'
            onClick={() => router.history.push('/logistics#depart')}
          />
        </Col>
        <Col xs={12}>
          <PageTitle>Create WH Transfer</PageTitle>
        </Col>
        <Col xs={12} style={{ textAlign: 'right' }}>
          <BaseButton
            type='primary'
            auto
            loading={loading}
            onClick={handleSubmit(onSubmit)}
          >
            {translate('CREATE')}
          </BaseButton>
        </Col>
      </Row>
      <Margin size={40} />
      <DepartureData formValues={formValues} />
    </PageForm>
  )
}

CreateWHTransferPage.propTypes = {
  router: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  formValues: PropTypes.object,
  translate: PropTypes.func
}

CreateWHTransferPage.defaultProps = {
  formValues: {},
  translate: undefined
}

const Page = reduxForm({
  form: 'departure_wh_form',
  validate: formRules
})(CreateWHTransferPage)

export default withLocalize(connect((state) => ({
  orderStatus: state.info.orderStatus,
  paymentMethods: state.info.paymentMethods,
  formValues: getFormValues('departure_wh_form')(state),
  initialValues: {
    requiresValidation: false // Default value for requiresValidation
  }
}))(Page))
