import { Modal as AntdModal } from 'antd'
import styled from 'styled-components'

export const Modal = styled(AntdModal)`

  &&& {
    .ant-modal-content {
      border-radius: 25px;
      padding: 10px 30px;
      overflow-x: hidden;
      overflow-y: hidden;
      height: auto;
    }

    @media screen and (max-height: 845px) {
      .ant-modal-content {
        height: auto; /* Adjust the height accordingly for smaller screens */
      }
    }
    
    .ant-modal-body {
      min-height: ${({ $hasMinHeight }) => ($hasMinHeight ? '200px' : '0')};
      padding:0;
    }

    .ant-modal-header {
      border-bottom: 1px solid #979797;
    }

    .ant-modal-close {
      right: 40px;
      top: 30px;

      &:hover {
        opacity: 0.6;
      }
      .ant-modal-close-x {
        width: 32px;
        height: 32px;
        font-size: 16px;
        line-height: 32px;
        color: #231f20;
      }
    }

    .ant-modal-footer {
      padding: 0;
      text-align: right;
      border-top: none;
    }
  }
`
