export default (values) => {
  const errors = []

  if (!values?.rawMaterialPropertiesSection?.measureUnitId || values?.rawMaterialPropertiesSection?.measureUnitId === '') {
    errors.push({tag: 'RAW_MATERIAL_SELECT_MEASURE_UNIT'})
  }

  if (Array.isArray(values?.rawMaterialPropertiesSection?.productSupplier) &&
        values?.rawMaterialPropertiesSection?.productSupplier.length > 0) {
    values.rawMaterialPropertiesSection.productSupplier.forEach((supp) => {
      if (supp.leadTime === undefined || supp.leadTime === '') {
        errors.push({tag: 'RAW_MATERIAL_SELECT_SUPPLIER_LEAD', value: supp?.s?.supplier?.name})
      }
      if (supp.moq === undefined || supp.moq === '') {
        errors.push({tag: 'RAW_MATERIAL_SELECT_SUPPLIER_MOQ', value: supp?.s?.supplier?.name})
      }
      if (supp.maq === undefined || supp.maq === '') {
        errors.push({tag: 'RAW_MATERIAL_SELECT_SUPPLIER_MAQ', value: supp?.s?.supplier?.name})
      }
      if (supp.units === undefined || supp.units === '') {
        errors.push({tag: 'RAW_MATERIAL_SELECT_SUPPLIER_UNIT', value: supp?.s?.supplier?.name})
      }
      if (supp.cost === undefined || supp.cost === '') {
        errors.push({tag: 'RAW_MATERIAL_SELECT_SUPPLIER_COST', value: supp?.s?.supplier?.name})
      }
      if (supp.priority === undefined || supp.priority === '') {
        errors.push({tag: 'RAW_MATERIAL_SELECT_SUPPLIER_PRIORITY', value: supp?.s?.supplier?.name})
      }
    })
  }
  return errors
}
