import React, {useState} from 'react'

import {DropboxOutlined} from '@ant-design/icons'
import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Field, change, getFormValues} from 'redux-form'
import styled from 'styled-components'

import CloseBorderedIcon from '../../../../assets/icons/cancel_border.svg'
import {ReactComponent as DropdownIcon} from '../../../../assets/icons/dropdown_blue.svg'
import {UpdatePackages} from '../../../../infra/requests/OrdersB2CRequests'
import BaseButton from '../../../../shared/components/buttons/BaseButton'
import PackingListButton from '../../../../shared/components/buttons/PackingListButton'
import NumberIncrementInput from '../../../../shared/components/inputs/NumberIncrementInput'
import {
  TableRow,
  ListCol,
  TableTitle,
  TableInput,
  ListCheck,
  TableText,
  FooterRow
} from '../../../../shared/components/table/CustomTableStyles'
import {TitleH3} from '../../../../shared/styles/BasicStyles'
import ItemsInBox from './ItemsInBox'

const EmptyTable = styled.div` 
  display: flex;
  width: 100%;
  margin-top: 3px;
  background: white;
  padding: 50px 30px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 0.6;
  font-size: 18px;
  border-radius: 4px;

  .anticon {
    font-size: 40px;
    margin-bottom: 10px;
  }
`

const ToggleDropdown = styled(DropdownIcon)`
  display: inline-block;
  transform: ${({open}) => (open ? 'rotate(-180deg)' : 'rotate(0)')};
  transition: all 0.2s ease;
  cursor: pointer;
`

const CalculateBoxQuantity = (box) => {
  let total = 0
  if (box && box.items) {
    box.items.forEach((item) => {
      total += item.itemQuantity
    })
  }
  return total
}

const HandleConstructBox = (box, products) => {
  const result = []
  box.items.forEach((item) => {
    const product = products.find((x) => x.orderItemId == item.orderItemId)
    result.push({
      productId: product?.productCombination?.productId,
      sizeId: product?.productCombination?.sizeId,
      colorId: product?.productCombination?.colorId,
      productWidthId: product?.productCombination?.productWidthId,
      quantity: product?.quantity,
      PVP: product?.unitTotalValueFinal
    })
  })
  return {items: result}
}

const OrderBoxes = ({fields, dispatch, disabled, formValues}) => {
  const [selected, setSelected] = useState([])
  const boxes = fields.getAll() || []

  const handleCheck = (e, index) => {
    const copy = [...selected]
    if (e.target.checked) {
      copy.push(index)
      setSelected(copy)
    } else {
      setSelected(copy.filter((i) => i !== index))
    }
  }

  const handleCheckAll = (e) => {
    if (e.target.checked) {
      const indexes = []
      boxes.forEach((b, i) => {
        if (!b.sent) indexes.push(i)
      })
      setSelected(indexes)
    } else {
      setSelected([])
    }
  }

  const toggleBox = (index) => {
    dispatch(
      change('manage-order-b2b2c-b2c', `orderBox[${index}].open`, !boxes[index].open)
    )
  }

  const handleRemoveBoxes = async () => {
    const products = JSON.parse(
      JSON.stringify(formValues?.orderItem || [])
    )
    selected
      .sort((a, b) => b - a)
      .forEach((index) => {
        boxes[index].items.forEach((item) => {
          const ind = products.findIndex(
            (x) => x.orderItemId === item.orderItemId
          )
          if (ind > -1) {
            if (products[ind].left) {
              products[ind].left += item.itemQuantity
            } else {
              products[ind].left = item.itemQuantity
            }
          }
        })
        boxes.splice(index, 1)
      })

    const {success, data} = await UpdatePackages(formValues.orderId, boxes.filter((b) => !b.sent))
    if (success) {
      if (Array.isArray(data) && data.length > 0) {
        dispatch(
          change(
            'manage-order-b2b2c-b2c',
            'orderBox',
            data
          )
        )
      }
    }

    dispatch(change('manage-order-b2b2c-b2c', 'orderBox', boxes))
    dispatch(change('manage-order-b2b2c-b2c', 'orderItem', products))
    setSelected([])
  }

  if (!boxes.length) return null

  return (
    <Row gutter={[16, 40]}>
      <Col xs={12}>
        <TitleH3 $op>Kits and Boxes</TitleH3>
      </Col>
      <Col xs={24}>
        <TableRow $header>
          <ListCol xs={1}>
            <ListCheck
              disabled={!boxes.length || disabled}
              checked={selected.length > 0}
              onChange={handleCheckAll}
              indeterminate={
                selected.length > 0 && selected.length < boxes.length
              }
            />
          </ListCol>
          <ListCol xs={2}>
            <TableTitle text='Number' />
          </ListCol>
          <ListCol xs={2}>
            <TableTitle text='Type' />
          </ListCol>
          <ListCol xs={3}>
            <TableTitle text='EAN 128' />
          </ListCol>
          <ListCol xs={1}>
            <TableTitle text='Items' />
          </ListCol>
          <ListCol xs={3}>
            <TableTitle text='Weight (kg)' />
          </ListCol>
          <ListCol xs={3}>
            <TableTitle text='Length (cm)' />
          </ListCol>
          <ListCol xs={3}>
            <TableTitle text='Height (cm)' />
          </ListCol>
          <ListCol xs={3}>
            <TableTitle text='Depth (cm)' />
          </ListCol>
          <ListCol xs={2}>
            <TableTitle text='Packing list' />
          </ListCol>
          <ListCol xs={1} />
        </TableRow>
        {boxes.length === 0 && (
          <EmptyTable>
            <DropboxOutlined />
            Select products to create boxes
          </EmptyTable>
        )}
        {boxes.map((box, index) => (
          <TableRow key={index}>
            <ListCol xs={1}>
              <ListCheck
                checked={selected.includes(index)}
                onChange={(e) => handleCheck(e, index)}
                disabled={disabled || box.sent}
              />
            </ListCol>
            <ListCol xs={2}>
              <TableInput>
                <Field
                  component={NumberIncrementInput}
                  name={`orderBox[${index}].orderPackageId`}
                  width='80%'
                  disabled
                />
              </TableInput>
            </ListCol>
            <ListCol xs={2}>
              <TableText $align='left'>
                {box.isKit ? 'Kit' : 'Box'}
              </TableText>
            </ListCol>
            <ListCol xs={3}>
              <TableInput>
                <Field
                  component={NumberIncrementInput}
                  name={`orderBox[${index}].ean128`}
                  width='100%'
                  disabled={disabled || box.sent}
                />
              </TableInput>
            </ListCol>
            <ListCol xs={1}>
              <TableText>{CalculateBoxQuantity(box)}</TableText>
            </ListCol>
            <ListCol xs={3}>
              <TableInput>
                <Field
                  component={NumberIncrementInput}
                  name={`orderBox[${index}].weight`}
                  width='80%'
                  disabled={disabled || box.sent}
                />
              </TableInput>
            </ListCol>
            <ListCol xs={3}>
              <TableInput>
                <Field
                  component={NumberIncrementInput}
                  name={`orderBox[${index}].dimWidth`}
                  width='80%'
                  disabled={disabled || box.sent}
                />
              </TableInput>
            </ListCol>
            <ListCol xs={3}>
              <TableInput>
                <Field
                  component={NumberIncrementInput}
                  name={`orderBox[${index}].dimHeight`}
                  width='80%'
                  disabled={disabled || box.sent}
                />
              </TableInput>
            </ListCol>
            <ListCol xs={3}>
              <TableInput>
                <Field
                  component={NumberIncrementInput}
                  name={`orderBox[${index}].dimDepth`}
                  width='80%'
                  disabled={disabled || box.sent}
                />
              </TableInput>
            </ListCol>
            <ListCol xs={2}>
              <TableText>
                <PackingListButton type='box' data={HandleConstructBox(box, formValues.orderItem)} />
              </TableText>
            </ListCol>
            <ListCol xs={1}>
              <TableText $first>
                <ToggleDropdown
                  open={box.open}
                  onClick={() => toggleBox(index)}
                />
              </TableText>
            </ListCol>
            <ItemsInBox box={box} items={formValues.orderItem} />
          </TableRow>
        ))}
        <FooterRow gutter={[16, 16]}>
          <ListCol xs={3}>
            <BaseButton
              disabled={selected.length <= 0 || disabled}
              type='secondary'
              size='small'
              onClick={handleRemoveBoxes}
              imagePath={CloseBorderedIcon}
            >
              Remove
            </BaseButton>
          </ListCol>
        </FooterRow>
      </Col>
    </Row>
  )
}

OrderBoxes.propTypes = {
  dispatch: PropTypes.func.isRequired,
  formValues: PropTypes.object
}

OrderBoxes.defaultProps = {
  formValues: {}
}

export default connect((state) => ({
  formValues: getFormValues('manage-order-b2b2c-b2c')(state)
}))(OrderBoxes)
