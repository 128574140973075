import React from 'react'

import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'
import {reduxForm, Field} from 'redux-form'

import FormValidator from '../../../infra/services/validations/FormValidator'
import {PageForm} from '../../styles/BasicStyles'
import SelectInput from '../inputs/SelectInput'
import TextInput from '../inputs/TextInput'
import BaseModal from '../modal/BaseModal'

const validations = FormValidator.make({
  name: 'required',
  addressLine1: 'required',
  zipCode: 'required',
  city: 'required',
  region: 'required',
  countryId: 'required'
})

const AddressModal = ({
  handleSubmit,
  reset,
  open,
  onClose,
  onSave,
  countries
}) => {
  const handleSave = (values) => {
    onSave(values)
    reset()
  }

  const handleClose = () => {
    onClose()
    reset()
  }

  return (
    <BaseModal
      okText={<Translate id='SAVE' />}
      onOk={handleSubmit(handleSave)}
      onClose={handleClose}
      title={<Translate id='ADDRESS' />}
      open={open}
    >
      {open && (
        <PageForm onSubmit={handleSubmit(handleSave)}>
          <Row gutter={[20, 20]}>
            <Col xs={24}>
              <Field
                name='name'
                component={TextInput}
                label={<Translate id='LABEL' />}
              />
            </Col>
            <Col xs={24} lg={10}>
              <Field
                name='addressLine1'
                component={TextInput}
                label={<Translate id='ADDRESS1' />}
              />
            </Col>
            <Col xs={24} lg={7}>
              <Field
                name='addressLine2'
                component={TextInput}
                label={<Translate id='LINE2' />}
              />
            </Col>
            <Col xs={24} lg={7}>
              <Field
                name='addressLine3'
                component={TextInput}
                label={<Translate id='LINE3' />}
              />
            </Col>
            <Col xs={24} lg={5}>
              <Field
                name='streetNumber'
                component={TextInput}
                label={<Translate id='STREET_NUMBER' />}
              />
            </Col>
            <Col xs={24} lg={5}>
              <Field
                name='zipCode'
                component={TextInput}
                label={<Translate id='POSTAL_CODE' />}
              />
            </Col>
            <Col xs={24} lg={7}>
              <Field
                name='city'
                component={TextInput}
                label={<Translate id='CITY' />}
              />
            </Col>
            <Col xs={24} lg={7}>
              <Field
                name='region'
                component={TextInput}
                label={<Translate id='REGION' />}
              />
            </Col>
            <Col xs={24} lg={8}>
              <Field
                name='countryId'
                component={SelectInput}
                label={<Translate id='COUNTRY' />}
                placeholder={<Translate id='COUNTRY' />}
                data={countries}
                dataLabel='name'
                dataKey='countryID'
              />
            </Col>
          </Row>
        </PageForm>
      )}
    </BaseModal>
  )
}

AddressModal.propTypes = {
  countries: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired
}

AddressModal.defaultProps = {}

const AddressForm = reduxForm({
  form: 'manage_new_address',
  validate: validations
})(AddressModal)

export default AddressForm
