import React, { useState } from 'react'

import { Row, Col } from 'antd'
import PropTypes from 'prop-types'
import { Translate, withLocalize } from 'react-localize-redux'
import { FieldArray } from 'redux-form'

import Trash from '../../../assets/icons/remove.svg'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import SelectInput from '../../../shared/components/inputs/SelectInput'
import StatusColumn from '../../../shared/components/Status/StatusColumn'
import BaseTable from '../../../shared/components/table/BaseTable'
import ActiveTranslation from '../../../shared/logic/translations/ActiveTranslation'
import { Margin } from '../../../shared/styles/BasicStyles'
import ProductLink from '../../../shared/components/links/ProductLink'
import AlertService from '../../../shared/components/alert/AlertService'

const Columns = [
  {
    dataIndex: 'productId',
    type: 'text',
    title: <Translate id='ID' />
  },

  {
    title: <Translate id='NAME' />,
    dataIndex: 'product',
    type: 'text',
    render: (value) => <ProductLink product={value} />
  },
  {
    dataIndex: 'product',
    type: 'text',
    title: <Translate id='REFERENCE' />,
    render: (value) => <div>{value?.referenceCode}</div>
  },
  {
    dataIndex: 'product',
    type: 'text',
    title: <Translate id='CATEGORY' />,
    render: (value) => (
      value.mainCategory && value.mainCategory.categoryTranslation ? (
        <ActiveTranslation
          value={value.mainCategory.categoryTranslation}
          tag='name'
        />
      ) : (
        ''
      )
    )
  },
  {
    dataIndex: 'active',
    type: 'text',
    title: <Translate id='STATE' />,
    render: (value) => <StatusColumn value={value} />
  }
]

const RelatedProductsComponent = ({
  fields,
  products,
  router,
  translate
}) => {
  const [selected, setSelected] = useState([])
  const [product, setProduct] = useState('')

  const rows = fields.getAll() || []
  const filteredProducts = () =>
    products.filter((p) => !rows.find((t) => t.productId == p.productId))

  const addProduct = () => {
    const item = products.find((x) => x.productId == product)

    const object = {
      productId: product,
      product: {
        mainCategory: {
          categoryTranslation: item?.mainCategory?.categoryTranslation
        },
        referenceCode: item?.referenceCode,
        productTranslation: item?.productTranslation,
        productId: product
      },
      active: true
    }
    fields.push(object)
    setProduct('')
    AlertService.success(
      translate('SUCCESS'),
      translate('RELATED_ADDED_SUCCESSFULLY_SAVE_TO_COMPLETE')
    )
  }

  const removeProduct = () => {
    const indexes = selected.map((element) =>
      rows.findIndex((x) => x.productId == element.key)
    )
    indexes
      .sort((a, b) => (a > b ? -1 : 1))
      .forEach((i) => fields.remove(i))
    setSelected([])
  }

  return (
    <>
      <Row gutter={[24]} align='bottom'>
        <Col xs={8}>
          <SelectInput
            input={{ value: product, onChange: setProduct }}
            label={<Translate id='PRODUCTS' />}
            data={filteredProducts()}
            placeholder={<Translate id='CHOOSE' />}
            dataLabel='productTranslation'
            dataKey='productId'
          />
        </Col>
        <Col xs={4}>
          <BaseButton
            auto
            type='secondary'
            onClick={addProduct}
            disabled={product === ''}
          >
            <Translate id='ADD' />
          </BaseButton>
        </Col>
      </Row>
      <Margin size={38} />
      <Row>
        <Col xs={24}>
          <BaseButton
            auto
            type='secondary'
            imagePath={Trash}
            onClick={removeProduct}
            disabled={selected?.length <= 0}
          >
            <Translate id='DELETE' />
          </BaseButton>
        </Col>
      </Row>
      <Margin size={14} />
      <Row>
        <Col xs={24}>
          <BaseTable
            datasource={rows}
            columns={Columns}
            pagination={{
              render: false
            }}
            rowSelection={{
              selected,
              onRowSelection: setSelected
            }}
            rowKey='productId'
          // onClickRow={(row) =>
          //   router.history.push(`/products/${row.productId}`)
          // }
          />
        </Col>
      </Row>
    </>
  )
}

RelatedProductsComponent.propTypes = {
  router: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired
}

const relatedProducts = ({ products, router }) => (
  <FieldArray
    component={withLocalize(RelatedProductsComponent)}
    name='positionRelatedProductsSection.positionProductRelation'
    products={products}
    router={router}
  />
)

relatedProducts.propTypes = {
  products: PropTypes.array.isRequired
}

export default relatedProducts
