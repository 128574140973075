// page colors
export const PageBackground = '#F9F9F9'

// primary project colors
export const PrimaryColor = '#0065B5'
export const PrimaryButtonColor = '#0267B2'
export const SecondaryButtonColor = '#EBECEC'
export const PrimaryHoverButtonColor = '#025CA0'
export const SecondaryHoverButtonColor = '#BBBFC3'
export const DisableButtonColor = '#F1EFEF'

// tables colors
export const TableHeaderColor = '#2965A8'
export const TableTextColor = '#231F20'
export const TableHeaderBackground = '#FBFBFB'
export const TableFooterTextColor = '#7D858C'
export const StatusActive = '#1A9926'
export const StatusInactive = '#ED1C00'
export const Sections = '#29ABE2'

// header navbar and footer colors
export const NavBackgroundColor = '#FFFFFF'
export const NavTextColor = '#97A2B5'
export const NavHover = '#fcfcfc'
export const FooterBorderColor = '#00A0E3'
export const FooterBackgroundColor = '#A1C4EB'
export const FooterTextColor = '#FFFFFF'

// custom colors
export const UnselectedColor = '#BBBFC3'
export const BorderColor = '#C6C8CA'
export const SelectColor = '#8D99AE'
export const DocumentTextColor = '#1C1D21'

// action colors
export const SuccessColor = '#39B54A'
export const ErrorColor = '#ED1C00'
export const ErrorHoverColor = '#E37162'
export const WarningColor = '#fccd21'
export const OpaqueError = 'rgba(237, 28, 0, 0.3)'

// custom
export const PaymentAccepted = '#8CD73F'
export const NotSeenColor = '#DBE4EE'

export const ProgressBarColor = '#F2F2F2'

export const BarGraphicColors = ['#0063B8', '#89B7E8', '#C6C8CA']

export const PieGraphColors = [
  '#007DBD',
  '#004B84',
  '#CC2127',
  '#DB5228',
  '#F06C20',
  '#EE8A2C',
  '#EBA938',
  '#EAC744',
  '#231F20',
  '#323E48',
  '#EBC833',
  '#B1D1EA',
  '#DD511B',
  '#F26C01'
]
