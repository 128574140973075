import React from 'react'

import {Spin} from 'antd'
import {PropTypes} from 'prop-types'
import Dropzone from 'react-dropzone'
import {withLocalize} from 'react-localize-redux'

import AddIconBordered from '../../../assets/icons/add_bordered_black.svg'
import AlertService from '../alert/AlertService'
import {
  ProductUploadImageContainer,
  ProductUploadImage
} from './InputStyles'

const ProductUploadImageInput = ({input, meta, loading, translate}) => {
  const handleFileDrop = (accepted) => {
    if (accepted && accepted[0]) {
      input.onChange(accepted[0])
    } else AlertService.error(translate('ERROR'), translate('INVALID_IMAGE_FORMAT'))
  }

  const hasError = meta.invalid && meta.submitFailed

  return (
    <Dropzone onDrop={handleFileDrop} multiple={false} maxFiles={1} accept='image/*'>
      {({getRootProps, getInputProps}) => (
        <ProductUploadImageContainer
          {...getRootProps()}
          hasError={hasError}
        >
          <input {...getInputProps()} />
          {loading ? (
            <Spin />
          ) : (
            <ProductUploadImage src={AddIconBordered} />
          )}
        </ProductUploadImageContainer>
      )}
    </Dropzone>
  )
}

ProductUploadImageInput.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object
}

ProductUploadImageInput.defaultProps = {
  input: {value: undefined, onChange: () => null},
  meta: {}
}

export default withLocalize(ProductUploadImageInput)
