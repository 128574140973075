import React from 'react'

import {FieldArray} from 'redux-form'

import {Margin} from '../../../shared/styles/BasicStyles'
import ContractPlanning from './ContractPlanning'
import ModulationConfigured from './ModulationConfigured'
import ModulationProgrammed from './ModulationProgrammed'

const Modulations = () => (
  <>
    <FieldArray
      component={ContractPlanning}
      name='positionModulationsSection.employeePlan'
    />
    <Margin size={59} />
    <FieldArray
      component={ModulationConfigured}
      name='positionModulationsSection.positionModulationGeneric'
    />
    <Margin size={59} />
    <FieldArray
      component={ModulationProgrammed}
      name='positionModulationsSection.positionModulation'
    />
  </>
)

Modulations.propTypes = {}

export default Modulations
