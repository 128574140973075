import React from 'react'

import { PropTypes } from 'prop-types'

import {
  InputBox,
  InputLabel,
  TextInputStyled
} from './InputStyles'

const TextInput = ({
  input,
  label,
  meta,
  type,
  placeholder,
  afterChange,
  min,
  max,
  disabled,
  addonAfter
}) => {
  const handleChange = (event) => {
    input.onChange(event.target.value)
    if (typeof afterChange === 'function') {
      afterChange(event.target.value)
    }
  }

  const hasError = meta.invalid && meta.submitFailed

  return (
    <InputBox>
      {label && <InputLabel>{label}</InputLabel>}
      <TextInputStyled
        hasError={hasError}
        type={type}
        placeholder={placeholder}
        value={input.value}
        onChange={handleChange}
        min={min}
        max={max}
        disabled={disabled}
        addonAfter={addonAfter}
      />
      {/* {hasError && (
        <InputError>
          <Translate id={meta.error} />
        </InputError>
      )} */}
    </InputBox>
  )
}

TextInput.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.element
  ]),
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.element
  ]),
  type: PropTypes.string,
  afterChange: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  disabled: PropTypes.bool
}

TextInput.defaultProps = {
  input: { value: undefined, onChange: () => null },
  label: undefined,
  placeholder: undefined,
  meta: {},
  type: 'text',
  afterChange: undefined,
  min: undefined,
  max: undefined,
  disabled: false
}

export default TextInput
