export default function (id, data) {
  if (id && data && data.length > 0) {
    const value = data.find((currentData) => currentData.id === id)
    if (value) return value.name
  }
  return 'Invalid id'
}

export const GetShippingCondition = (type, shippingConditions) => {
  if (!shippingConditions || shippingConditions.length <= 0) return null
  const value = shippingConditions.find((elem) => elem.key === type)
  return value
}

export const GetPaymentMethod = (type, paymentMethods) => {
  if (!paymentMethods || paymentMethods.length <= 0) return null
  const value = paymentMethods.find(
    (elem) => elem.paymentMethodId === type
  )
  return value
}

export const GetPaymentCondition = (type, paymentConditions) => {
  if (!paymentConditions || paymentConditions.length <= 0) return null
  const value = paymentConditions.find(
    (elem) => elem.paymentConditionId === type
  )
  return value
}

export const GetStatusType = (type, orderStatus) => {
  if (!orderStatus || orderStatus.length <= 0) return null
  const value = orderStatus.find((elem) => elem.orderStatusId === type)
  return value
}

export const GetServiceType = (type, services) => {
  if (!services || services.length <= 0) return null
  const value = services.find((elem) => elem.ServiceID === type)
  return value
}
