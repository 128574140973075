import React, {Component} from 'react'

import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'

import AddWhite from '../../../assets/icons/add_bordered.svg'
import {GetSuppliers} from '../../../infra/requests/SupplierRequests'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import SearchInput from '../../../shared/components/inputs/SearchInput'
import StatusColumn from '../../../shared/components/Status/StatusColumn'
import BaseTable from '../../../shared/components/table/BaseTable'
import {Margin} from '../../../shared/styles/BasicStyles'

let timeout

class SuppliersList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      rows: [],
      page: 1,
      pageSize: 20,
      total: 0,
      search: ''
    }
  }

  getColumns = () => [
    {
      title: <Translate id='NAME' />,
      type: 'text',
      dataIndex: 'name'
    },
    {
      title: <Translate id='CODE' />,
      type: 'text',
      dataIndex: 'externalCode',
      render: (value) => value || <Translate id='NA' />
    },
    {
      dataIndex: 'active',
      type: 'text',
      title: <Translate id='STATE' />,
      render: (value) => <StatusColumn value={value} />
    }
  ]

  componentDidMount = async () => {
    try {
      this.updateTable()
    } catch (error) {
      console.warn(error)
    }
  }

  updateTable = async () => {
    const {page, pageSize, search} = this.state
    this.setState({loading: true})
    const {data} = await GetSuppliers(page, pageSize, search)
    this.setState({
      rows: data?.items,
      total: data?.totalItems,
      loading: false
    })
  }

  onChangePagination = (page) => this.setState({page}, this.updateTable)

  setFilter = (search) => {
    this.setState({search, page: 1}, () => {
      if (timeout) clearTimeout(timeout)
      timeout = setTimeout(this.updateTable, 1000)
    })
  }

  render() {
    const {loading, rows, page, pageSize, total, search} = this.state
    const {router, translate} = this.props

    return (
      <>
        <Row gutter={[24, 24]}>
          <Col xs={6}>
            <BaseButton
              auto
              imagePath={AddWhite}
              onClick={() => router.history.push('/suppliers/new')}
            >
              <Translate id='ADD_NEW' />
            </BaseButton>
          </Col>
          <Col xs={6} offset={12}>
            <SearchInput
              auto
              input={{
                value: search,
                onChange: this.setFilter,
                onSearch: this.updateTable
              }}
              placeholder={translate('SEARCH')}
            />
          </Col>
        </Row>
        <Margin size={30} />
        <Row>
          <Col xs={24}>
            <BaseTable
              rowKey='supplierId'
              datasource={rows}
              columns={this.getColumns()}
              pagination={{
                render: true,
                page,
                pageSize,
                total,
                onChangePagination: this.onChangePagination
              }}
              onClickRow={(row) => {
                router.history.push(`/suppliers/${row.supplierId}`)
              }}
              loading={loading}
            />
          </Col>
        </Row>
      </>
    )
  }
}

SuppliersList.propTypes = {
  router: PropTypes.object.isRequired
}

export default withLocalize(SuppliersList)
