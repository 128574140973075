import React, { useEffect, useState } from 'react'

import { Row, Col } from 'antd'
import PropTypes from 'prop-types'
import { Translate, withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  reduxForm,
  initialize,
  Field,
  getFormValues,
  change
} from 'redux-form'

import { GetSuppliersList } from '../../../infra/requests/BaseRequests'
import {
  GetWarehouseDetail,
  CreateNewWarehouse,
  SaveWarehouse,
  GetListEwp
} from '../../../infra/requests/LogisticsRequests'
import { GetCarriers } from '../../../infra/requests/OrdersB2CRequests'
import FormValidator from '../../../infra/services/validations/FormValidator'
import BackButton from '../../../shared/components/buttons/BackButton'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import SelectInput from '../../../shared/components/inputs/SelectInput'
import SwitchInput from '../../../shared/components/inputs/SwitchInput'
import TextInput from '../../../shared/components/inputs/TextInput'
import BaseLoading from '../../../shared/components/loading/BaseLoading'
import {
  Margin,
  PageForm,
  PageTitle
} from '../../../shared/styles/BasicStyles'

const validations = FormValidator.make({
  name: 'required',
  email: 'email|required',
  addressLine1: 'required',
  zipCode: 'required',
  code: 'required',
  externalCode: 'required',
  b2bclientId: 'required',
  countryId: 'required',
  city: 'required'
})

const ManageWarehouse = ({
  router,
  dispatch,
  handleSubmit,
  formValues,
  countries,
  clients,
  translate
}) => {
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [suppliers, setSuppliers] = useState(false)
  const [carriers, setCarriers] = useState(false)
  const [ewp, setEwp] = useState([])
  const { id } = useParams()

  useEffect(() => {
    async function init() {
      if (id && id !== 'new') {
        const { data } = await GetWarehouseDetail(id)

        dispatch(
          initialize('warehouse_form', {
            ...data,
            b2bclientId: data?.b2bclient?.b2bclientId,
            addressLine1: data?.contact?.contactDetail[0]?.addressLine1,
            addressLine2: data?.contact?.contactDetail[0]?.addressLine2,
            addressLine3: data?.contact?.contactDetail[0]?.addressLine3,
            streetNumber: data?.contact?.contactDetail[0]?.streetNumber,
            zipCode: data?.contact?.contactDetail[0]?.zipCode,
            city: data?.contact?.contactDetail[0]?.city,
            region: data?.contact?.contactDetail[0]?.region,
            countryId: data?.contact?.contactDetail[0]?.countryId,
            email: data?.contact?.contactDetail[0]?.email,
            telephone: data?.contact?.contactDetail[0]?.telephone,
            mobile: data?.contact?.contactDetail[0]?.mobile,
            ewpId: data?.ewp?.ewpid
          })
        )
      } else dispatch(initialize('warehouse_form', {}))

      const { data } = await GetListEwp()
      setEwp(data)

      const supplierData = await GetSuppliersList()
      setSuppliers(supplierData?.data?.items || [])
      const carriersData = await GetCarriers()
      setCarriers(carriersData?.data || [])
      setLoading(false)
    }
    init()
  }, [])

  const onSubmit = async (values) => {
    setSaving(true)
    if (id !== 'new') {
      const { success } = await SaveWarehouse(id, values)
      setSaving(false)
      if (success) return router.history.push('/logistics#config')
    } else {
      const { success } = await CreateNewWarehouse(values)
      setSaving(false)
      if (success) return router.history.push('/logistics#config')
    }
    return setSaving(false)
  }

  if (loading) return <BaseLoading margin='200' />

  return (
    <PageForm onSubmit={handleSubmit(onSubmit)} noValidate>
      <Row align='bottom' gutter={[0, 50]}>
        <Col xs={24}>
          <BackButton
            label={<Translate id='BACK' />}
            onClick={() => router.history.push('/logistics#config')}
          />
        </Col>
        <Col xs={12}>
          <PageTitle>
            {id !== 'new' ? formValues.name : <Translate id='NEW_WAREHOUSE' />}
          </PageTitle>
        </Col>

        <Col xs={12} align='end'>
          <BaseButton
            htmlType='submit'
            type='primary'
            auto
            loading={saving}
            disabled={saving}
          >
            <Translate id='SAVE' />
          </BaseButton>
        </Col>
      </Row>
      <Margin size={40} />
      <Row gutter={[24, 24]}>
        <Col xs={6}>
          <Field
            label={<Translate id='WAREHOUSE_NAME' />}
            placeholder={translate('WAREHOUSE_NAME')}
            name='name'
            component={TextInput}
          />
        </Col>
        <Col xs={6}>
          <Field
            name='b2bclientId'
            component={SelectInput}
            label={<Translate id='OWNER' />}
            placeholder={translate('OWNER')}
            dataLabel='name'
            dataKey='b2bclientId'
            data={clients}
          />
        </Col>
        <Col xs={4}>
          <Field
            label={<Translate id='CODE' />}
            placeholder={translate('INSERT_WAREHOUSE_CODE')}
            name='code'
            component={TextInput}
          />
        </Col>
        <Col xs={4}>
          <Field
            label={<Translate id='EXTERNAL_CODE' />}
            placeholder={translate('INSERT_WAREHOUSE_EXTERNAL_CODE')}
            name='externalCode'
            component={TextInput}
          />
        </Col>
        <Col xs={4}>
          <Field
            label={<Translate id='PRIORITY' />}
            placeholder={translate('INSERT_WAREHOUSE_PRIORITY')}
            name='priority'
            component={TextInput}
          />
        </Col>
        <Col xs={6}>
          <Field
            label={<Translate id='EMAIL' />}
            placeholder={translate('INSERT_EMAIL')}
            name='email'
            type='email'
            component={TextInput}
          />
        </Col>
        <Col xs={6}>
          <Field
            label={<Translate id='TELEPHONE' />}
            placeholder={translate('INSERT_TELEPHONE_NUMBER')}
            name='telephone'
            component={TextInput}
          />
        </Col>
        <Col xs={6}>
          <Field
            label={<Translate id='MOBILE' />}
            placeholder={translate('INSERT_MOBILE_NUMBER')}
            name='mobile'
            component={TextInput}
          />
        </Col>
        <Col xs={3} offset={1}>
          <Field
            component={SwitchInput}
            name='active'
            checkedText={<Translate id='YES' />}
            uncheckedText={<Translate id='NO' />}
            label={<Translate id='ACTIVE' />}
          />
        </Col>
        <Col xs={8}>
          <Field
            label={<Translate id='ADDRESS1' />}
            placeholder={translate('INSERT_ADDRESS')}
            name='addressLine1'
            component={TextInput}
          />
        </Col>
        <Col xs={8}>
          <Field
            label={<Translate id='LINE2' />}
            placeholder={translate('INSERT_ADDRESS')}
            name='addressLine2'
            component={TextInput}
          />
        </Col>
        <Col xs={8}>
          <Field
            label={<Translate id='LINE3' />}
            placeholder={translate('INSERT_ADDRESS')}
            name='addressLine3'
            component={TextInput}
          />
        </Col>
        <Col xs={4}>
          <Field
            label='Street Number'
            placeholder={translate('INSERT_STREET_NUMBER')}
            name='streetNumber'
            component={TextInput}
          />
        </Col>
        <Col xs={4}>
          <Field
            label='Zip Code'
            placeholder={translate('INSERT_ZIP_CODE')}
            name='zipCode'
            component={TextInput}
          />
        </Col>
        <Col xs={6}>
          <Field
            label={<Translate id='CITY' />}
            placeholder={translate('INSERT_CITY')}
            name='city'
            component={TextInput}
          />
        </Col>
        <Col xs={6}>
          <Field
            label={<Translate id='REGION' />}
            placeholder={translate('INSERT_REGION')}
            name='region'
            component={TextInput}
          />
        </Col>
        <Col xs={4}>
          <Field
            name='countryId'
            component={SelectInput}
            label={<Translate id='COUNTRY' />}
            placeholder={<Translate id='COUNTRY' />}
            data={countries}
            dataLabel='name'
            dataKey='countryID'
          />
        </Col>
        <Col xs={8}>
          <Field
            name='supplierId'
            component={SelectInput}
            label={<Translate id='SUPPLIER' />}
            placeholder={<Translate id='RELATED_TO_SUPPLIER' />}
            data={suppliers}
            dataLabel='name'
            dataKey='supplierId'
          />
        </Col>
        <Col xs={8}>
          <Field
            name='carrierId'
            component={SelectInput}
            label={<Translate id='CARRIER' />}
            placeholder={<Translate id='RELATED_TO_CARRIER' />}
            data={carriers}
            dataLabel='name'
            dataKey='carrierID'
          />
        </Col>
        <Col xs={8}>
          <Field
            name='ewpId'
            component={SelectInput}
            label='EWP'
            placeholder='Select EWP'
            data={ewp}
            dataLabel='name'
            dataKey='ewpid'
          />
        </Col>
      </Row>
      <Margin size='24' />
      <Row gutter={[24, 24]}>
        <Col xs={4}>
          <Field
            component={SwitchInput}
            name='productive'
            checkedText={<Translate id='YES' />}
            uncheckedText={<Translate id='NO' />}
            label={<Translate id='PRODUCTIVE' />}
            disabled={
              formValues.defects ||
              formValues.quarantine ||
              formValues.materials ||
              formValues.confection
            }
          />
        </Col>
        <Col xs={4}>
          <Field
            component={SwitchInput}
            name='quarantine'
            checkedText={<Translate id='YES' />}
            uncheckedText={<Translate id='NO' />}
            label={<Translate id='QUARANTINE' />}
            disabled={
              formValues.productive ||
              formValues.defects ||
              formValues.transit ||
              formValues.confection
            }
          />
        </Col>
        <Col xs={4}>
          <Field
            component={SwitchInput}
            name='defects'
            checkedText={<Translate id='YES' />}
            uncheckedText={<Translate id='NO' />}
            label={<Translate id='DEFECTS' />}
            disabled={
              formValues.productive ||
              formValues.quarantine ||
              formValues.transit ||
              formValues.confection
            }
          />
        </Col>
        <Col xs={4}>
          <Field
            component={SwitchInput}
            name='transit'
            checkedText={<Translate id='YES' />}
            uncheckedText={<Translate id='NO' />}
            label={<Translate id='TRANSIT' />}
            disabled={
              formValues.confection ||
              formValues.quarantine ||
              formValues.defects
            }
          />
        </Col>
        <Col xs={4}>
          <Field
            component={SwitchInput}
            name='materials'
            checkedText={<Translate id='YES' />}
            uncheckedText={<Translate id='NO' />}
            label={<Translate id='MATERIALS' />}
            disabled={formValues.productive}
            afterChange={(value) => {
              if (!value && formValues.confection) {
                dispatch(change('warehouse_form', 'confection', false))
              }
            }}
          />
        </Col>
        <Col xs={4}>
          <Field
            component={SwitchInput}
            name='confection'
            checkedText={<Translate id='YES' />}
            uncheckedText={<Translate id='NO' />}
            label={<Translate id='CONFECTION' />}
            disabled={
              !formValues.materials ||
              formValues.quarantine ||
              formValues.transit ||
              formValues.defects
            }
          />
        </Col>
      </Row>
      <Margin size='24' />
      <Row gutter={[24, 24]}>
        <Col xs={4}>
          <Field
            component={SwitchInput}
            name='clientStock'
            checkedText={<Translate id='YES' />}
            uncheckedText={<Translate id='NO' />}
            label={<Translate id='CLIENT_STOCK' />}
          />
        </Col>
        <Col xs={4}>
          <Field
            component={SwitchInput}
            name='returnExchange'
            checkedText={<Translate id='YES' />}
            uncheckedText={<Translate id='NO' />}
            label={<Translate id='RETURNS' />}
          />
        </Col>
      </Row>
    </PageForm>
  )
}

ManageWarehouse.propTypes = {
  router: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  formValues: PropTypes.object,
  countries: PropTypes.array.isRequired
}

ManageWarehouse.defaultProps = {
  formValues: {}
}

const ManageWarehousePage = withLocalize(reduxForm({
  form: 'warehouse_form',
  validate: validations
})(ManageWarehouse))

export default connect((state) => ({
  formValues: getFormValues('warehouse_form')(state),
  countries: state.info.countries,
  clients: state.info.clients
}))(ManageWarehousePage)
