import React from 'react'

import { Translate } from 'react-localize-redux'
import { useSelector } from 'react-redux'

import {
  CopyrightSection,
  CopyrightImage,
  CopyrightText
} from './FooterStyles'
import MySkyproLogo from '../../../../assets/myskypro_logo.png'
import version from '../../../../infra/constants/version'

const FooterCopyright = (white) => {
  const backendVersion = useSelector((state) => state.info.backendVersion)
  const currentYear = new Date().getFullYear()
  return (
    <CopyrightSection $white={white}>
      <CopyrightImage src={MySkyproLogo} />
      <CopyrightText>
        <Translate id='COPYRIGHT' data={{ year: currentYear }} />
        <div>
          {`${backendVersion} | ${version}`}
        </div>
      </CopyrightText>
    </CopyrightSection>
  )
}

export default FooterCopyright
