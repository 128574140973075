import {IsNullOrEmpty} from '../../../../shared/logic/strings/StringsFunctions'

export default (requestBody) => {
  const errors = []
  if (Array.isArray(requestBody.items) && requestBody.items.length > 0) {
    requestBody.items.forEach((item) => {
      if (!item.itemId) errors.push({field: 'RETURN_PRODUCT', message: 'REQUIRED'})
      if (!item.quantity || item.quantity <= 0) errors.push({field: 'QUANTITY', message: 'REQUIRED'})
      if (!item.motiveId) errors.push({field: 'SELECT_MOTIVE', message: 'REQUIRED'})
      if (typeof item.isReturn !== 'boolean') errors.push({field: 'RETURN_TYPE', message: 'REQUIRED'})
    })
  } else {
    errors.push({field: 'ITEMS', message: 'REQUIRED'})
  }

  if (IsNullOrEmpty(requestBody.pickingDate)) {
    errors.push({field: 'DATE', message: 'REQUIRED'})
  }

  return errors
}
