import React, { useState } from 'react'

import { Col, Row } from 'antd'
import { Translate } from 'react-localize-redux'
import { Field } from 'redux-form'

import Trash from '../../../../assets/icons/remove.svg'
import BaseButton from '../../../../shared/components/buttons/BaseButton'
import FloatInput from '../../../../shared/components/inputs/FloatInput'
import IntegerInput from '../../../../shared/components/inputs/IntegerInput'
import SelectInput from '../../../../shared/components/inputs/SelectInput'
import TextInput from '../../../../shared/components/inputs/TextInput'
import BaseTable from '../../../../shared/components/table/BaseTable'
import { Margin } from '../../../../shared/styles/BasicStyles'

const Suppliers = ({ fields, suppliers }) => {
  const [supplier, setSupplier] = useState('')
  const [selected, setSelected] = useState([])

  const suppliersInfo = fields.getAll() || []
  const filteredSuppliers = () =>
    suppliers.filter(
      (p) =>
        !suppliersInfo.find(
          (t) => t.supplier && t.supplier.supplierId === p.supplierId
        )
    );

  const addSupplier = () => {
    const found = suppliers.find(
      (x) => x.supplierId === parseInt(supplier, 10)
    )
    const object = {
      supplierId: supplier,
      supplier: {
        supplierId: supplier,
        name: found.name
      }

    }
    fields.unshift(object)
    setSupplier('')
  }

  const removeSupplier = () => {
    const indexes = selected.map((element) =>
      suppliersInfo.findIndex((x) => x.supplierId === element.key)
    )
    indexes
      .sort((a, b) => (a > b ? -1 : 1))
      .forEach((i) => fields.remove(i))
    setSelected([])
  }

  const columns = () => [
    {
      type: 'text',
      dataIndex: 'name',
      title: <Translate id='NAME' />,
      render: (text, row) => (
        <>{row.supplier.name}</>
      )
    },
    {
      type: 'text',
      dataIndex: 'referenceSupplier',
      title: <Translate id='REFERENCE_SUPPLIER' />,
      render: (text, row, index) => (
        <Field
          component={TextInput}
          name={`rawMaterialPropertiesSection.productSupplier[${index}].referenceSupplier`}
        />
      )
    },
    {
      type: 'text',
      dataIndex: 'leadTime',
      title: <Translate id='LEAD_TIME' />,
      render: (text, row, index) => (
        <Field
          component={IntegerInput}
          name={`rawMaterialPropertiesSection.productSupplier[${index}].leadTime`}
        />
      )
    },
    {
      type: 'text',
      dataIndex: 'moq',
      title: <Translate id='MOQ' />,
      render: (text, row, index) => (
        <Field
          component={FloatInput}
          name={`rawMaterialPropertiesSection.productSupplier[${index}].moq`}
        />
      )
    },
    {
      type: 'text',
      dataIndex: 'maq',
      title: <Translate id='MAQ' />,
      render: (text, row, index) => (
        <Field
          component={FloatInput}
          name={`rawMaterialPropertiesSection.productSupplier[${index}].maq`}
        />
      )
    },
    {
      type: 'text',
      dataIndex: 'units',
      title: <Translate id='UNITS' />,
      render: (text, row, index) => (
        <Field
          component={FloatInput}
          name={`rawMaterialPropertiesSection.productSupplier[${index}].units`}
        />
      )
    },
    {
      type: 'text',
      dataIndex: 'cost',
      title: <Translate id='COST' />,
      render: (text, row, index) => (
        <Field
          component={FloatInput}
          name={`rawMaterialPropertiesSection.productSupplier[${index}].cost`}
        />
      )
    },
    {
      type: 'text',
      dataIndex: 'priority',
      title: <Translate id='PRIORITY' />,
      render: (text, row, index) => (
        <Field
          component={IntegerInput}
          name={`rawMaterialPropertiesSection.productSupplier[${index}].priority`}
        />
      )
    }
  ]

  return (
    <>
      <Row gutter={[20, 20]} align='bottom'>
        <Col xs={12} lg={8}>
          <SelectInput
            label={<Translate id='SUPPLIER' />}
            placeholder={<Translate id='SELECT' />}
            dataKey='supplierId'
            dataLabel='name'
            data={filteredSuppliers()}
            input={{
              value: supplier,
              onChange: setSupplier
            }}
          />
        </Col>
        <Col xs={8} lg={4}>
          <BaseButton
            type='secondary'
            onClick={addSupplier}
            disabled={!supplier}
          >
            <Translate id='ADD' />
          </BaseButton>
        </Col>
      </Row>
      <Margin size={20} />
      <Row>
        <Col xs={24}>
          <BaseButton
            auto
            type='secondary'
            imagePath={Trash}
            onClick={removeSupplier}
            disabled={selected?.length <= 0}
          >
            <Translate id='DELETE' />
          </BaseButton>
        </Col>
      </Row>
      <Margin size={14} />
      <Row>
        <BaseTable
          rowKey='supplierId'
          columns={columns()}
          datasource={suppliersInfo}
          pagination={{ render: false }}
          rowSelection={{
            selected,
            onRowSelection: setSelected
          }}
        />
      </Row>
      <Margin size={20} />
    </>
  )
}

Suppliers.propTypes = {}

Suppliers.defaultProps = {}

export default Suppliers
