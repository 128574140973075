import React from 'react'
import {Translate} from 'react-localize-redux'
import PropTypes from 'prop-types'

import ArrowRight from '../../../assets/icons/arrow_right.svg'
import {NextButtonStyle} from './ButtonsStyles'

const NextButton = ({onClick, disabled, label}) => (
  <NextButtonStyle onClick={onClick} disabled={disabled}>
    <div style={{marginRight: 10}}>{label}</div>
    <img src={ArrowRight} alt={<Translate id='NEXT_IMAGE' />} />
  </NextButtonStyle>
)

NextButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool
}

NextButton.defaultProps = {
  onClick: () => null,
  disabled: false,
  label: <Translate id='NEXT' />
}

export default NextButton
