const validateFamily = (family, errors) => {
  if (!family.priority || family.priority === '') {
    errors.push({tag: 'FAMILY_HAS_NO_PRIORITY', value: family.referenceCode})
  }
  if (
    !family.quantity ||
    family.quantity === '' ||
    family.quantity === 0
  ) {
    errors.push({tag: 'FAMILY_HAS_NO_QUANTITY_DEFINED', value: family.referenceCode})
  }
  if (
    !family.colors ||
    !Array.isArray(family.colors) ||
    family.colors.length === 0
  ) {
    errors.push({tag: 'FAMILY_HAS_NO_COLORS_DEFINED', value: family.referenceCode})
  }
  if (
    !family.list ||
    !Array.isArray(family.list) ||
    family.list.length === 0
  ) {
    errors.push({tag: 'HAS_ERROR_DELETE_FROM_COMPOSITION', value: family.referenceCode})
  }
  family.list.forEach((material) => {
    if (
      !material.widths ||
      !Array.isArray(material.widths) ||
      material.widths.length === 0
    ) {
      errors.push({tag: 'MATERIAL_HAS_NO_WIDTHS_DEFINED', value: family.referenceCode})
    }
    if (
      !material.sizes ||
      !Array.isArray(material.sizes) ||
      material.sizes.length === 0
    ) {
      errors.push({tag: 'MATERIAL_HAS_NO_SIZE_DEFINED', value: family.referenceCode})
    }
  })
}

const validateMaterial = (material, errors) => {
  if (!material.priority || material.priority === '') {
    errors.push({tag: 'MATERIAL_HAS_NO_PRIORITY', value: material.referenceCode})
  }
  if (
    !material.quantity ||
    material.quantity === '' ||
    material.quantity === 0
  ) {
    errors.push({tag: 'MATERIAL_HAS_NO_QTY_DEFINED', value: material.referenceCode})
  }
  if (
    !material.colors ||
    !Array.isArray(material.colors) ||
    material.colors.length === 0
  ) {
    errors.push({tag: 'MATERIAL_HAS_NO_COLORS_DEFINED', value: material.referenceCode})
  }
}

const validateService = (service, errors) => {
  if (!service.priority || service.priority === '') {
    errors.push({tag: 'SERVICE_NO_PRIORITY', value: service?.s?.service?.reference})
  }
  if (!service.units || service.units === '' || service.units === 0) {
    errors.push({tag: 'SERVICE_QTY_DEFINED', value: service?.s?.service?.reference})
  }
  if (!service.cost || service.cost === '' || service.cost === 0) {
    errors.push({tag: 'SERVICE_COST_DEFINED', value: service?.s?.service?.reference})
  }
  if (
    !service.supplierId ||
    service.supplierId === '' ||
    service.supplierId === 0
  ) {
    errors.push({tag: 'SERVICE_NO_SUPPLIER_DEFINED', value: service?.s?.service?.reference})
  }
}

export const ConstructProperties = (values) => ({
  ...values.productPropertiesSection,
  productTechnology: values.productPropertiesSection.productTechnology.map(
    (elem) => ({technologyId: elem.technologyId})
  ),
  productCharacteristicValue:
    values.productPropertiesSection.productCharacteristicValue.map(
      (elem) => ({CharacteristicValueId: elem.characteristicValueId})
    )
})

export const ConstructComposition = (values, sizes, widths) => {
  const errors = []
  const materials = []
  const services = []
  const sizeIds = sizes.map((s) => s.sizeId)

  if (values && values.composition) {
    values.composition.forEach((material) => {
      if (material.type === 'family') {
        validateFamily(material, errors)
        material.list.forEach((item) => {
          materials.push({
            ...item,
            colors: material.colors,
            priority: material.priority,
            quantity: material.quantity,
            consumption: material.consumption
          })
        })
      }
      if (material.type === 'material') {
        validateMaterial(material, errors)
        materials.push({...material, sizes: sizeIds, widths})
      }
      if (material.type === 'service') {
        validateService(material, errors)
        services.push(material)
      }
    })
  }

  if (errors.length) {
    throw errors
  }

  return {rawMaterialList: materials, productSupplier: services}
}
