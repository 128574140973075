import React, { Component } from 'react'

import { Row, Col } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'

import {
  GetInfoOrdersSmart,
  GetSmartOrders
} from '../../../infra/requests/SmartOrdersRequests'
import Highlight from '../../../shared/components/highlight/Highlight'
import DateRangeInput from '../../../shared/components/inputs/DateRangeInput'
import SearchInput from '../../../shared/components/inputs/SearchInput'
import BaseTable from '../../../shared/components/table/BaseTable'
import ConstructQuery from '../../../shared/logic/queries/EndpointQueries'
import { Margin } from '../../../shared/styles/BasicStyles'

let timeout

class SmartOrders extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      rows: [],
      page: 1,
      pageSize: 20,
      total: 0,
      filters: { search: '' },
      infoOrders: {}
    }
  }

  getColumns = () => [
    {
      dataIndex: 'b2bclient',
      title: <Translate id='CLIENT' />,
      render: (client) => client?.name,
      type: 'text'
    },
    {
      dataIndex: 'orderSmartId',
      type: 'text',
      title: <Translate id='ORDER_NMR' />,
      width: 70
    },
    {
      dataIndex: 'dateOrder',
      title: <Translate id='ORDER_DATE' />,
      render: (date) => moment(date).format('DD/MM/YYYY'),
      type: 'text',
      width: 100
    },
    {
      dataIndex: 'totalUnits',
      type: 'text',
      title: <Translate id='QTY' />
    },
    {
      dataIndex: 'totalValue',
      type: 'text',
      title: <Translate id='VALUE' />
    },
    {
      dataIndex: 'currency',
      type: 'text',
      title: <Translate id='CURRENCY' />,
      render: (value) => value?.code,
      width: 100
    },
    {
      dataIndex: 'dateDelivery',
      title: <Translate id='DATE_DELIVERY' />,
      render: (date) => moment(date).format('DD/MM/YYYY'),
      type: 'text',
      width: 100
    }
  ]

  componentDidMount = async () => {
    try {
      const { data } = await GetInfoOrdersSmart()
      this.setState({ infoOrders: data }, this.updateTable)
    } catch (error) {
      console.warn(error)
    }
  }

  updateTable = async () => {
    const { page, pageSize, filters } = this.state
    this.setState({ loading: true })
    const { data } = await GetSmartOrders(
      page,
      pageSize,
      ConstructQuery(filters)
    )
    this.setState({
      rows: data?.items,
      total: data?.totalItems,
      loading: false
    })
  }

  onChangePagination = (page) => this.setState({ page }, this.updateTable)

  setFilters = (values, time) => {
    this.setState({ filters: values }, () => {
      if (timeout) clearTimeout(timeout)
      timeout = setTimeout(this.updateTable, time)
    })
  }

  render() {
    const {
      loading,
      rows,
      page,
      pageSize,
      total,
      dateRange,
      filters,
      infoOrders
    } = this.state
    const { router } = this.props
    return (
      <>
        <Row gutter={[24, 16]}>
          <Col xs={24} md={8}>
            <Highlight
              title={<Translate id='HIGH_VALUE_ORDERS' />}
              text={infoOrders?.hightValueOrders}
            />
          </Col>
          <Col xs={24} md={8}>
            <Highlight
              title={<Translate id='ORDERS_THIS_MONTH' />}
              text={infoOrders?.ordersThisMonth}
            />
          </Col>
          <Col xs={24} md={8}>
            <Highlight
              title={<Translate id='DELIVERIES_THIS_MONTH' />}
              text={infoOrders?.deliveriesThisMonth}
            />
          </Col>
        </Row>
        <Margin size={30} />
        <Row gutter={[24, 16]}>
          <Col xs={24} md={12} lg={{ span: 6, offset: 12 }}>
            <SearchInput
              input={{
                value: filters.search,
                onChange: (value) =>
                  this.setFilters({ ...filters, search: value }, 1000),
                onSearch: () => this.setFilters({ ...filters, '': '' }, 0)
              }}
            />
          </Col>
          <Col xs={24} md={12} lg={6}>
            <DateRangeInput
              input={{
                value: dateRange,
                onChange: (date) => {
                  this.setFilters(
                    {
                      ...filters,
                      dateFrom: date ? date[0].startOf('day') : undefined,
                      dateTo: date ? date[1].endOf('day') : undefined
                    },
                    0
                  )
                }
              }}
            />
          </Col>
        </Row>
        <Margin size={30} />
        <Row>
          <Col xs={24}>
            <BaseTable
              rowKey='orderSmartId'
              datasource={rows}
              columns={this.getColumns()}
              pagination={{
                render: true,
                page,
                pageSize,
                total,
                onChangePagination: this.onChangePagination
              }}
              onClickRow={(row) => {
                router.history.push(
                  `/orders/smartorder/${row.orderSmartId}`
                )
              }}
              loading={loading}
            />
          </Col>
        </Row>
      </>
    )
  }
}

SmartOrders.propTypes = {
  router: PropTypes.object.isRequired
}

export default SmartOrders
