/* eslint-disable camelcase */
import ManageOrderB2BPage from '../../pages/orders/b2b/ManageOrderB2BPage'
import NewOrderPage from '../../pages/orders/b2b/NewOrderPage'
import CreateOrder_B2B2C_B2C from '../../pages/orders/manage_b2b_b2b2c/CreateOrder_B2B2C_B2C'
import ExchangeAndReturns_B2B2C_B2C from '../../pages/orders/manage_b2b_b2b2c/ExchangeAndReturns_B2B2C_B2C'
// import ManageOrderB2B2CPage from '../../pages/orders/b2b2c/ManageOrderB2B2CPage'
import EditOrderB2C from '../../pages/orders/manage_b2b_b2b2c/ManageOrders_B2B2C_B2C'
import OrdersPage from '../../pages/orders/OrdersPage'
import ManageSmartOrderPage from '../../pages/orders/smart_orders/ManageSmartOrderPage'

export default [
  {
    path: '/orders',
    component: OrdersPage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  },
  {
    path: '/orders/b2b/new',
    component: NewOrderPage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  },
  {
    path: '/orders/b2b/:id',
    component: ManageOrderB2BPage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  },
  {
    path: '/orders/b2c/new',
    component: CreateOrder_B2B2C_B2C,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  },
  {
    path: '/orders/b2c/:id',
    component: EditOrderB2C,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  },
  {
    path: '/orders/b2b2c/new',
    component: CreateOrder_B2B2C_B2C,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  },
  {
    path: '/orders/b2b2c/:id',
    component: EditOrderB2C,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  },
  {
    path: '/orders/smartorder/:id',
    component: ManageSmartOrderPage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  },
  {
    path: '/returns/b2b2c/:id',
    component: ExchangeAndReturns_B2B2C_B2C,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  },
  {
    path: '/returns/b2c/:id',
    component: ExchangeAndReturns_B2B2C_B2C,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  }
]
