import styled from 'styled-components'

import {BorderColor} from '../../shared/styles/_colors'
import {BookFontWeight, BodyText} from '../../shared/styles/_texts'

export const MessagingContainer = styled.div`
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 20px 0px 50px 0px;
`

export const BottomIncoming = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0px 0px 0px;
  margin-right: 20px;
  margin-left: 20px;
  border-top: 1px solid ${BorderColor};
`

export const IncomingButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-right: ${({$hasRightBorder}) =>
    $hasRightBorder ? `1px solid ${BorderColor}` : 'none'};
  padding: 0px 16px;
  white-space: nowrap;
  cursor: pointer;
`

export const IncomingButtonImg = styled.img`
  margin-right: 10px;
`

export const IncomingButtonText = styled.h5`
  padding: 0;
  margin: 0;
  font-weight: ${BookFontWeight};
  font-size: ${BodyText};
`

export const FilesMessagingContainer = styled.div`
  height: 80px;
  width: 100%;
  position: absolute;
  top: -81px;
  left: 0;
  display: flex;
  padding: 5px;
  overflow-x: auto;
`
export const FileBox = styled.div`
  display: flex;
  margin-right: 15px;
  border: 1px solid ${BorderColor};
  align-items: center;
  padding: 5px;
  height: auto;
  border-radius: 7px;
  height: 55px;
`

export const DeleteAttachmentIcon = styled.img`
  cursor: pointer;
  margin-left: 10px;
  align-self: flex-start;
`

export const AttachmentIcon = styled.img`
  height: 35px;
  width: 35px;
  margin-right: 12px;
`

export const AttachmentName = styled.div`
  font-size: 14px;
  line-height: 17px;
  font-weight: 100;
  max-width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 34px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
  min-width: 100px;
`

export const ContactsPagination = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  justify-content: space-between;
  display: flex;
  padding: 10px;
`
