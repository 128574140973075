import styled from 'styled-components'

import {
  PrimaryButtonColor,
  TableTextColor,
  StatusActive,
  PrimaryColor
} from '../../shared/styles/_colors'
import {
  BoldFontWeight,
  H1Text,
  TabsText,
  BodyText,
  BookFontWeight
} from '../../shared/styles/_texts'

export const PageTitle = styled.div`
  font-size: ${H1Text};
  line-height: 41px;
  font-weight: ${BoldFontWeight};
  color: ${PrimaryButtonColor};
`

export const SubTitle = styled.div`
  font-size: ${TabsText};
  font-weight: ${BoldFontWeight};
  color: ${TableTextColor};
  opacity: 50%;
`
export const StatusText = styled.div`
  font-size: ${BodyText};
  line-height: 20px;
  font-weight: ${BookFontWeight};
  color: ${({color}) => color || StatusActive};
`

export const RecoverSection = styled.div`
  font-size: ${BodyText};
  line-height: 20px;
  font-weight: ${BookFontWeight};
  color: ${PrimaryColor};
  cursor: pointer;
`

export const RecoverImage = styled.img`
  margin-left: 7px;
  width: 19px;
  height: 17px;
`
