import React, { useState } from 'react'

import { PlusOutlined, CloseOutlined } from '@ant-design/icons'
import { Row, Col, Popover } from 'antd'
import moment from 'moment'
import { PropTypes } from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from 'react-router'

import {
  BaseCalendarContainer,
  LoadingOverlay,
  WeekDayLabel,
  HeaderCell,
  EmptyHeader,
  TimeSlot,
  CalendarSlot,
  CenteredCol,
  CurrentButton,
  SlotHover,
  DisableLayer,
  PopoverLine
} from './BaseCalendarStyles'
import CalendarSelector from './CalendarSelector'
import ScheduleFittingModal from '../../../pages/fitting/tabs/ScheduleFittingModal'
import { IsToday } from '../../logic/dates/MomentFunctions'
import BaseLoading from '../loading/BaseLoading'

const CalculateUnix = (current, start) => {
  const splitHour = start.split(':')
  return moment
    .utc(current)
    .set('hours', splitHour[0])
    .set('minutes', splitHour[1])
    .startOf('minutes')
    .toISOString()
    .replace('.000Z', '')
}

const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
]

const BaseCalendar = ({
  date,
  setDate,
  slots,
  loading,
  schedule,
  saveSlot,
  disabled,
  onScheduleFittingSubmit,
  employees
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedSlot, setSelectedSlot] = useState()

  const handleAddEmployee = (slot, current, unix) => {
    setSelectedSlot({ slot, date: `${current.format('DD/MM/YYYY')} ${slot.start}`, unix })
    setIsModalOpen(true)
  }
  return (
    <BaseCalendarContainer>
      {loading && (
        <LoadingOverlay>
          <BaseLoading />
        </LoadingOverlay>
      )}
      {disabled && <DisableLayer />}
      <Row gutter={[24, 24]}>
        <CenteredCol offset={3} xs={18}>
          <CalendarSelector date={date} setDate={setDate} />
        </CenteredCol>
        <Col xs={3}>
          <CurrentButton onClick={() => setDate(moment.utc())}>
            <Translate id='TODAY' />
          </CurrentButton>
        </Col>
      </Row>
      <Row>
        <Col xs={3}>
          <EmptyHeader />
          {slots.map((slot) => (
            <TimeSlot key={slot.start}>
              {slot.start} - {slot.end}
            </TimeSlot>
          ))}
        </Col>
        {days.map((day, index) => {
          const current = moment.utc(date).startOf('week').add(index, 'days')
          return (
            <Col xs={3} key={index}>
              <WeekDayLabel>{day}</WeekDayLabel>
              <HeaderCell $first={index === 0} $today={IsToday(current)}>
                {current.format('DD')}
              </HeaderCell>
              {slots.map((slot, slotIndex) => {
                const unix = CalculateUnix(current, slot.start)
                const available = schedule.find((s) => s.slot === unix)
                const passed = moment
                  .utc(unix)
                  .startOf('day')
                  .isBefore(moment.utc().startOf('day'))

                const content = (
                  <>
                    <PopoverLine $title>
                      <b>
                        <Translate id='NAME_1' />{' '}
                      </b>
                      {available?.employeeName}
                    </PopoverLine>
                    <PopoverLine>
                      <b>
                        <Translate id='CONTACT_1' />{' '}
                      </b>
                      {available?.employeePhone}
                    </PopoverLine>
                  </>
                )
                return (
                  <CalendarSlot
                    key={`${index}_${slotIndex}`}
                    $first={index === 0}
                    $available={available}
                  >
                    {!available?.employeeId && !passed && (
                      available ? (
                        <SlotHover>
                          <div style={{ display: 'flex', gap: '1rem' }}>
                            <CloseOutlined onClick={() => saveSlot(available, unix)} />
                            <PlusOutlined onClick={() => handleAddEmployee(slot, current, unix)} />
                          </div>
                        </SlotHover>
                      )
                        : (
                          <SlotHover onClick={() => {
                            saveSlot(available, unix)
                          }}
                          >
                            <PlusOutlined />
                          </SlotHover>
                        )
                    )}
                    {available?.employeeId && (
                      <Popover content={content} placement='bottom'>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', color: 'red', fontSize: 20 }}>
                          <CloseOutlined
                            onClick={() => { saveSlot(available, unix) }}
                          />
                        </div>
                      </Popover>

                    )}
                  </CalendarSlot>
                )
              })}
            </Col>
          )
        })}
      </Row>
      <ScheduleFittingModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        date={selectedSlot?.date}
        slot={selectedSlot?.unix}
        employees={employees}
        onScheduleFittingSubmit={onScheduleFittingSubmit}
      />
    </BaseCalendarContainer>
  )
}

BaseCalendar.propTypes = {
  date: PropTypes.object.isRequired,
  setDate: PropTypes.func.isRequired,
  saveSlot: PropTypes.func.isRequired,
  slots: PropTypes.array,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  schedule: PropTypes.array,
  onScheduleFittingSubmit: PropTypes.func,
  employees: PropTypes.array
}

BaseCalendar.defaultProps = {
  slots: [],
  loading: false,
  disabled: false,
  schedule: [],
  onScheduleFittingSubmit: undefined,
  employees: []
}

export default withRouter(BaseCalendar)
