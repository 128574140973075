import styled from 'styled-components'

export const HeaderButtonContainer = styled.div`
  display: flex;
  margin-left: auto;
`

export const UpdateAllLinesSuppliersContainer = styled.div`
  float: right;
  text-align: left;
  display: flex;
  align-items: center;
`

export const ReviewAllLinesSuppliersContainer = styled.div`
float: right;
text-align: left;
`
