import React, {useState} from 'react'

import {Row, Col, Checkbox} from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {FieldArray, change, getFormValues} from 'redux-form'

import Trash from '../../../assets/icons/remove.svg'
import {
  CreateEmployeeExtraCredits,
  DeleteEmployeeExtraCredits,
  GetEmployee
} from '../../../infra/requests/ClientRequests'
import AlertService from '../../../shared/components/alert/AlertService'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import DateInput from '../../../shared/components/inputs/DateInput'
import SelectInput from '../../../shared/components/inputs/SelectInput'
import ProductLink from '../../../shared/components/links/ProductLink'
import BaseTable from '../../../shared/components/table/BaseTable'
import AsyncForEach from '../../../shared/logic/arrays/AsyncForEach'
import {Margin} from '../../../shared/styles/BasicStyles'

const Columns = [
  {
    dataIndex: '',
    type: 'text',
    title: <Translate id='PRODUCT' />,
    render: (value) => <ProductLink product={value} />
  },
  {
    dataIndex: 'expirationDate',
    type: 'text',
    title: <Translate id='EXPIRATION' />,
    render: (value) => (
      <div>{value ? moment(value).format('DD-MM-YYYY') : '-'}</div>
    )
  },
  {
    dataIndex: 'attributionDate',
    type: 'text',
    title: <Translate id='ATTRIBUTION' />,
    render: (value) => (
      <div>{value ? moment(value).format('DD-MM-YYYY') : '-'}</div>
    )
  },
  {
    dataIndex: 'consumptionDate',
    type: 'text',
    title: <Translate id='CONSUMPTION' />,
    render: (value) => (
      <div>{value ? moment(value).format('DD-MM-YYYY') : '-'}</div>
    )
  }
]

const JoinListCredits = (fields, form) => {
  const extra = fields.getAll() || []
  const base = form?.employeeCreditsUsageSection?.credit || []
  const list = [...extra, ...base]
  list.sort((a, b) => moment(a.consumptionDate).isBefore(b.consumptionDate) ? 1 : -1)
  return list
}

const CreditsTable = ({id, fields, dispatch, productsList, translate, currentFormValues}) => {
  const [selected, setSelected] = useState([])
  const [product, setProduct] = useState('')
  const [expiration, setExpiration] = useState('')
  const [rows, setRows] = useState(JoinListCredits(fields, currentFormValues))

  const updateTable = async (currentId) => {
    const {data} = await GetEmployee(currentId)
    setRows(data?.employeeCreditsExtraSection?.credit)
  }

  const getSelected = () => {
    if (selected?.length > 0) {
      const array = []

      selected.forEach((item) => {
        const index = rows.findIndex(
          (x) => x.extraCreditId === item.key && !x.consumptionDate
        )
        if (index <= -1) {
          array.push(item.key)
        }
      })

      if (array?.length > 0) {
        return true
      }
      return false
    }
    return true
  }

  const addCredit = async () => {
    try {
      const data = {
        productId: product,
        isExtra: true,
        dateExpiration: expiration
      }

      if (!product || product === '') {
        AlertService.error(translate('ERROR'), translate('PRODUCT_IS_MISSING'))
      } else if (!expiration || expiration === '') {
        AlertService.error(translate('ERROR'), translate('EXPIRATION_DATE_IS_MISSING'))
      } else {
        const result = await CreateEmployeeExtraCredits(id, data)

        if (result.success) {
          dispatch(
            change(
              'manage_employee',
              'employeeCreditsExtraSection.credit',
              result.data.employeeCreditsExtraSection.credit
            )
          )

          updateTable(id)
          setProduct('')
          setExpiration('')
          AlertService.success(
            translate('SUCCESS'),
            translate('EXTRA_CREDIT_ADDED_SUCCESSFULLY')
          )
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const removeCredits = async () => {
    if (selected?.length > 0) {
      const indexes = selected.map((item) =>
        rows.findIndex(
          (x) => x.extraCreditId === item.key && !x.consumptionDate
        )
      )

      await AsyncForEach(
        indexes.sort((a, b) => (a > b ? -1 : 1)),
        async (index) => {
          if (index > -1) {
            const extraCredit = rows[index]?.extraCreditId
            const result = await DeleteEmployeeExtraCredits(
              id,
              extraCredit
            )
            if (result.success) {
              await fields.remove(index)
            }
          }
        }
      )
      updateTable(id)
      setSelected([])
    }
  }

  const handleRowSelect = (record) => {
    const found = selected.find((s) => s.key === record.extraCreditId)
    if (found) {
      setSelected(selected.filter((s) => s.key !== record.extraCreditId))
    } else {
      const newSelected = [...selected, {key: record.extraCreditId, record}]
      setSelected(newSelected)
    }
  }

  return (
    <>
      <Row gutter={[24, 40]} align='bottom'>
        <Col xs={6}>
          <SelectInput
            input={{value: product, onChange: setProduct}}
            placeholder={<Translate id='SELECT_PRODUCT' />}
            label={<Translate id='PRODUCT' />}
            data={productsList}
            dataKey='productId'
            dataLabel='productTranslation'
          />
        </Col>
        <Col xs={6}>
          <DateInput
            input={{value: expiration, onChange: setExpiration}}
            label={<Translate id='EXPIRATION_DATE' />}
            disabledDate={(value) => moment().startOf('day').isAfter(value.endOf('day'))}
          />
        </Col>
        <Col xs={4}>
          <BaseButton
            block
            type='secondary'
            disabled={!expiration || expiration === '' || !product || product === ''}
            onClick={addCredit}
          >
            <Translate id='ADD' />
          </BaseButton>
        </Col>
      </Row>
      <Margin size={38} />
      <Row>
        <Col xs={24}>
          <BaseButton
            auto
            type='secondary'
            imagePath={Trash}
            onClick={removeCredits}
            disabled={getSelected()}
          >
            <Translate id='DELETE' />
          </BaseButton>
        </Col>
      </Row>
      <Margin size={14} />
      <Row>
        <Col xs={24}>
          <BaseTable
            rowKey={(data) => data.extraCreditId || `${data.productId}_${data.consumptionDate}`}
            datasource={rows}
            columns={Columns}
            pagination={{render: false}}
            rowSelection={{
              selected,
              hideSelectAll: true,
              onRowSelection: setSelected,
              renderCell: (prop, record) => (
                <Checkbox
                  disabled={!record.extraCreditId}
                  checked={!!selected.find((s) => s.key === record.extraCreditId)}
                  onChange={() => handleRowSelect(record)}
                />
              )
            }}
          />
        </Col>
      </Row>
    </>
  )
}

CreditsTable.propTypes = {
  id: PropTypes.string.isRequired,
  productsList: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired
}

const Credits = ({id, productsList, dispatch, currentFormValues}) => (
  <FieldArray
    name='employeeCreditsExtraSection.credit'
    component={withLocalize(CreditsTable)}
    productsList={productsList}
    id={id}
    dispatch={dispatch}
    currentFormValues={currentFormValues}
  />
)

Credits.propTypes = {
  id: PropTypes.string,
  productsList: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired
}

Credits.defaultProps = {
  id: ''
}

export default withLocalize(
  connect((state) => ({
    currentFormValues: getFormValues('manage_employee')(state)
  }))(Credits))
