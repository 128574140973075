import React, {useEffect, useState} from 'react'

import {Row, Col} from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {useParams} from 'react-router-dom'
import {reduxForm, Field, initialize} from 'redux-form'

import Trash from '../../../../../assets/icons/remove_white.svg'
import {
  CreateVoucher,
  DeleteVoucher,
  EditVoucher,
  GetVoucherInformation,
  GetVoucherLog
} from '../../../../../infra/requests/MarketingRequests'
import AlertService from '../../../../../shared/components/alert/AlertService'
import BackButton from '../../../../../shared/components/buttons/BackButton'
import BaseButton from '../../../../../shared/components/buttons/BaseButton'
import DeleteButton from '../../../../../shared/components/buttons/DeleteButton'
import SwitchInput from '../../../../../shared/components/inputs/SwitchInput'
import TextInput from '../../../../../shared/components/inputs/TextInput'
import {
  Margin,
  PageForm,
  PageTitle,
  StyledTabPane,
  StyledTabs,
  StyledTabTitle,
  StyledTabTitleContainer
} from '../../../../../shared/styles/BasicStyles'
import {
  HeaderLogs,
  HeaderLogsText,
  BodyLogs,
  LogPoint
} from '../../MarketingStyles'

const Sections = ['info', 'log']

const ManageExchange = ({router, dispatch, handleSubmit, translate}) => {
  const [section, setSection] = useState('info')
  const [hasFetchedExchange, setHasFetchedExchange] = useState(false)
  const [logs, setLogs] = useState([])
  const {id} = useParams()

  useEffect(() => {
    async function fetchVoucher() {
      if (id !== 'new') {
        try {
          const {data, success} = await GetVoucherInformation(id)
          const resultLog = await GetVoucherLog(id)

          if (success && resultLog?.success) {
            dispatch(
              initialize('exchange_form', {
                ...data,
                range:
                  data.dateFrom && data.dateTo
                    ? [moment(data.dateFrom), moment(data.dateTo)]
                    : []
              })
            )
            setLogs(resultLog?.data)
          }
        } catch (error) {
          console.log(error)
        }
      }
      setHasFetchedExchange(true)
    }
    if (!hasFetchedExchange) {
      fetchVoucher()
    }
    function init() {
      const hash = router.location.hash.replace('#', '')
      if (Sections.find((s) => s === hash)) {
        setSection(hash)
      }
    }
    init()
  }, [])

  const onChangeTab = (active) => {
    setSection(active)
    router.history.replace(`/marketing/vouchers/exchange/${id}#${active}`)
  }

  const onSubmit = async (values) => {
    try {
      const object = {
        ...values,
        isDiscountVoucher: false
      }

      if (id !== 'new') {
        const {success} = await EditVoucher(id, object)

        if (success) {
          AlertService.success(
            translate('SUCCESS'),
            translate('EXCHANGE_VOUCHER_EDITED_SUCCESSFULLY')
          )
          router.history.push('/marketing#vouchers')
        }
      } else {
        const {success} = await CreateVoucher(object)

        if (success) {
          AlertService.success(
            translate('SUCCESS'),
            translate('EXCHANGE_VOUCHER_CREATED_SUCCESSFULLY')
          )
          router.history.push('/marketing#vouchers')
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const deleteVoucher = async () => {
    try {
      const {success} = await DeleteVoucher(id)
      if (success) {
        AlertService.success(
          translate('SUCCESS'),
          translate('EXCHANGE_VOUCHER_DELETED_SUCCESSFULLY')
        )
        router.history.push('/marketing#vouchers')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const renderInfo = () => (
    <>
      <Row gutter={[40, 20]}>
        <Col xs={6}>
          <Field
            component={TextInput}
            name='name'
            label={<Translate id='NAME' />}
          />
        </Col>
        <Col xs={6}>
          <Field
            component={TextInput}
            name='code'
            label={<Translate id='CODE' />}
          />
        </Col>
        <Col xs={6}>
          <Field
            component={TextInput}
            name='email'
            label={<Translate id='CLIENT_EMAIL' />}
          />
        </Col>
        <Col xs={6}>
          <Field
            component={SwitchInput}
            name='active'
            checkedText={<Translate id='YES' />}
            uncheckedText={<Translate id='NO' />}
            label={<Translate id='ACTIVE' />}
          />
        </Col>
        <Col xs={6}>
          <Field
            component={TextInput}
            name='totalValue'
            label={<Translate id='VALUE' />}
          />
        </Col>
        <Col xs={6}>
          <Field
            component={TextInput}
            name='totalValueRemaining'
            label={<Translate id='VALUE_REMAINING' />}
            disabled
          />
        </Col>
        <Col xs={6}>
          <Field
            component={TextInput}
            name='usageCounter'
            label={<Translate id='USAGE' />}
            disabled
          />
        </Col>
      </Row>
    </>
  )

  const renderLog = () => (
    <>
      <HeaderLogs>
        <HeaderLogsText border width='5%' />
        <HeaderLogsText border width='40%'>
          <Translate id='DATE' />
        </HeaderLogsText>
        <HeaderLogsText border width='40%'>
          <Translate id='ORDER' />
        </HeaderLogsText>
        <HeaderLogsText width='15%'>
          <Translate id='VALUE' />
        </HeaderLogsText>
      </HeaderLogs>
      {logs?.length > 0 &&
        logs.map((log) => (
          <BodyLogs>
            <HeaderLogsText border width='5%' center>
              <LogPoint />
            </HeaderLogsText>
            <HeaderLogsText border width='40%'>
              {moment(log.date).format('DD/MM/YYYY HH:mm:ss')}
            </HeaderLogsText>
            <HeaderLogsText border width='40%'>
              {log.order}
            </HeaderLogsText>
            <HeaderLogsText width='15%'>€{log.value}</HeaderLogsText>
          </BodyLogs>
        ))}
    </>
  )

  return (
    <>
      <PageForm onSubmit={handleSubmit(onSubmit)}>
        <Row align='bottom' gutter={[0, 50]}>
          <Col xs={24}>
            <BackButton
              label={<Translate id='BACK' />}
              onClick={() => router.history.push('/marketing#vouchers')}
            />
          </Col>
          <Col xs={12}>
            <PageTitle>
              <Translate id='EXCHANGE_VOUCHER' />
            </PageTitle>
          </Col>
          <Col xs={12} align='end'>
            <BaseButton htmlType='submit' type='primary' auto>
              <Translate id='SAVE' />
            </BaseButton>
          </Col>
        </Row>
        <Margin size={38} />
        {id !== 'new' && (
          <>
            <Row>
              <Col xs={6}>
                <DeleteButton
                  imagePath={Trash}
                  type='error'
                  auto
                  onClick={() => deleteVoucher()}
                >
                  <Translate id='DELETE_VOUCHER' />
                </DeleteButton>
              </Col>
            </Row>
            <Margin size={38} />
          </>
        )}
        <Row>
          <Col xs={24}>
            <StyledTabs activeKey={section} onChange={onChangeTab}>
              <StyledTabPane
                tab={
                  <StyledTabTitleContainer>
                    <StyledTabTitle smaller>
                      <Translate id='INFORMATION' />
                    </StyledTabTitle>
                  </StyledTabTitleContainer>
                }
                key='info'
              >
                {renderInfo()}
              </StyledTabPane>
              <StyledTabPane
                tab={
                  <StyledTabTitleContainer>
                    <StyledTabTitle smaller>
                      <Translate id='LOG' />
                    </StyledTabTitle>
                  </StyledTabTitleContainer>
                }
                key='log'
              >
                {renderLog()}
              </StyledTabPane>
            </StyledTabs>
          </Col>
        </Row>
      </PageForm>
    </>
  )
}

ManageExchange.propTypes = {
  router: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  handleSubmit: PropTypes.func
}

ManageExchange.defaultProps = {
  dispatch: undefined,
  handleSubmit: undefined
}

const ManageExchangePage = reduxForm({
  form: 'exchange_form'
  // validate: validations
})(ManageExchange)

export default withLocalize(
  connect((state) => ({
    clients: state.info.clients
  }))(ManageExchangePage)
)
