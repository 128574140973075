import React, {useEffect, useState} from 'react'

import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'
import {connect} from 'react-redux'
import {useParams} from 'react-router-dom'
import {getFormValues, reduxForm, initialize} from 'redux-form'

import {GetB2BClient} from '../../infra/requests/ClientRequests'
import BackButton from '../../shared/components/buttons/BackButton'
import BaseLoading from '../../shared/components/loading/BaseLoading'
import {
  Margin,
  PageForm,
  PageTitle,
  StyledTabPane,
  StyledTabs,
  StyledTabTitle,
  StyledTabTitleContainer
} from '../../shared/styles/BasicStyles'
import EmployeesTab from './employees/EmployeesTab'
import UsersTab from './users/UsersTab'
import WorkgroupsTab from './workgroups/WorkgroupsTab'

const Sections = ['workgroups', 'employees', 'users']

const ManageClient = ({router, handleSubmit, dispatch}) => {
  const [section, setSection] = useState()
  const [loading, setLoading] = useState(true)
  const [client, setClient] = useState(false)
  const {id} = useParams()

  useEffect(() => {
    async function GetName() {
      const hash = router.location.hash.replace('#', '')
      if (Sections.find((s) => s === hash)) {
        setSection(hash)
      } else setSection('workgroups')

      const {data, success} = await GetB2BClient(id)
      if (success) setClient(data)
      setLoading(false)
    }
    GetName()
  }, [])

  const onSubmit = async (values) => {
    console.log(values)
  }

  const onChangeTab = (active) => {
    setSection(active)
    router.history.replace(`/clients/${id}#${active}`)
  }

  if (loading) return <BaseLoading />

  return (
    <PageForm onSubmit={handleSubmit(onSubmit)}>
      <Row gutter={[0, 20]}>
        <Col xs={24}>
          <BackButton
            label={<Translate id='BACK' />}
            onClick={() => router.history.push('/clients')}
          />
        </Col>
        <Col xs={24}>
          <PageTitle>{client.name}</PageTitle>
        </Col>
        <Margin size={30} />
        <Col xs={24}>
          <StyledTabs destroyInactiveTabPane activeKey={section} onChange={onChangeTab}>
            <StyledTabPane
              tab={
                <StyledTabTitleContainer>
                  <StyledTabTitle>
                    <Translate id='WORKGROUPS' />
                  </StyledTabTitle>
                </StyledTabTitleContainer>
              }
              key='workgroups'
            >
              <WorkgroupsTab router={router} id={id} />
            </StyledTabPane>
            <StyledTabPane
              tab={
                <StyledTabTitleContainer>
                  <StyledTabTitle>
                    <Translate id='EMPLOYEES' />
                  </StyledTabTitle>
                </StyledTabTitleContainer>
              }
              key='employees'
            >
              <EmployeesTab router={router} id={id} />
            </StyledTabPane>
            <StyledTabPane
              tab={
                <StyledTabTitleContainer>
                  <StyledTabTitle>
                    <Translate id='USERS' />
                  </StyledTabTitle>
                </StyledTabTitleContainer>
              }
              key='users'
            >
              <UsersTab router={router} id={id} />
            </StyledTabPane>
          </StyledTabs>
        </Col>
      </Row>
    </PageForm>
  )
}

ManageClient.propTypes = {
  router: PropTypes.object.isRequired
}

const ManageClientPage = reduxForm({
  form: 'client_form'
  // validate: validations
})(ManageClient)

export default connect((state) => ({
  currentFormValues: getFormValues('client_form')(state)
}))(ManageClientPage)
