import React, {Component} from 'react'

import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'

import Add from '../../../assets/icons/add_bordered.svg'
import {GetB2BClientUsers} from '../../../infra/requests/ClientRequests'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import SearchInput from '../../../shared/components/inputs/SearchInput'
import StatusColumn from '../../../shared/components/Status/StatusColumn'
import BaseTable from '../../../shared/components/table/BaseTable'
import {Sections} from '../../../shared/styles/_colors'
import {Margin, BodyH5} from '../../../shared/styles/BasicStyles'

let timeout

class UsersTab extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      rows: [],
      page: 1,
      pageSize: 24,
      total: 0,
      search: ''
    }
  }

  getColumns = () => [
    {
      title: <Translate id='NAME' />,
      type: 'text',
      dataIndex: 'fullName'
    },
    {
      title: <Translate id='DEPARTMENT' />,
      type: 'text',
      dataIndex: 'employee',
      render: (value) => (
        <BodyH5 $color={Sections}>
          {value[0] ? value[0]?.position?.name : '-'}
        </BodyH5>
      )
    },
    {
      title: <Translate id='WORKGROUP' />,
      type: 'text',
      dataIndex: 'employee',
      render: (value) => (
        <div>{value[0] ? value[0]?.position?.name : '-'}</div>
      )
    },
    {
      dataIndex: 'aspnetMembership',
      type: 'text',
      title: <Translate id='APPROVED' />,
      render: (value) => (
        <StatusColumn
          value={value.isApproved}
          onText={<Translate id='YES' />}
          offText={<Translate id='NO' />}
        />
      )
    },
    {
      dataIndex: 'aspnetMembership',
      type: 'text',
      title: <Translate id='STATUS' />,
      render: (value) => <StatusColumn value={!value.isLockedOut} />
    }
  ]

  componentDidMount = async () => {
    try {
      this.updateTable()
    } catch (error) {
      console.warn(error)
    }
  }

  updateTable = async () => {
    const {page, pageSize, search} = this.state
    const {id} = this.props
    try {
      this.setState({loading: true})

      const {data, success} = await GetB2BClientUsers(
        id,
        page,
        pageSize,
        search
      )

      if (success) {
        this.setState({
          rows: data?.items,
          loading: false,
          total: data?.totalItems
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  onChangePagination = (page) => this.setState({page}, this.updateTable)

  openEmployee = (row) => {
    const {router, id} = this.props
    router.history.push(`/clients/${id}/user/${row.userId}`)
  }

  setFilter = (search) => {
    this.setState({search, page: 1}, () => {
      if (timeout) clearTimeout(timeout)
      timeout = setTimeout(this.updateTable, 1000)
    })
  }

  render() {
    const {loading, rows, page, pageSize, total, search} = this.state
    const {router, id, translate} = this.props

    return (
      <>
        <Row gutter={[24, 24]}>
          <Col xs={6}>
            <BaseButton
              auto
              imagePath={Add}
              type='primary'
              onClick={() =>
                router.history.push(`/clients/${id}/user/new`)
              }
            >
              <Translate id='ADD_NEW' />
            </BaseButton>
          </Col>
          <Col xs={6} offset={12}>
            <SearchInput
              input={{
                value: search,
                onChange: this.setFilter,
                onSearch: this.updateTable
              }}
              placeholder={translate('SEARCH')}
            />
          </Col>
        </Row>
        <Margin size={15} />
        <Row>
          <Col xs={24}>
            <BaseTable
              rowKey='userId'
              datasource={rows}
              columns={this.getColumns()}
              pagination={{
                render: true,
                page,
                pageSize,
                total,
                onChangePagination: this.onChangePagination
              }}
              loading={loading}
              onClickRow={this.openEmployee}
            />
          </Col>
        </Row>
      </>
    )
  }
}

UsersTab.propTypes = {
  router: PropTypes.object.isRequired
}

export default withLocalize(UsersTab)
