import React, { Component } from 'react'

import { Row, Col, Checkbox } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'

import OrderSearch from './OrderSearch'
import AddWhite from '../../../assets/icons/add_white.svg'
import Export from '../../../assets/icons/export.svg'
import {
  CreatInternalPO,
  GetInfoOrdersB2B,
  GetOrdersB2B
} from '../../../infra/requests/OrdersB2BRequests'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import Highlight from '../../../shared/components/highlight/Highlight'
import TableOrderStatus from '../../../shared/components/orderStatus/TableOrderStatus'
import BaseTable from '../../../shared/components/table/BaseTable'
import { ConstructQuery, ConstructSort } from '../../../shared/logic/queries/EndpointQueries'
import { Margin, OverdueAlert } from '../../../shared/styles/BasicStyles'

let timeout

class OrdersB2B extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      loadingPO: false,
      rows: [],
      page: 1,
      pageSize: 20,
      total: 0,
      selectedOrders: [],
      infoOrders: {},
      sort: undefined
    }
  }

  getColumns = () => [
    {
      title: <Translate id='CLIENT' />,
      type: 'ordered',
      sorter: true,
      dataIndex: 'b2bclientName',
      render: (value, data) => data?.b2bclient?.name || 'N/A'
    },
    {
      dataIndex: 'number',
      title: <Translate id='ORDER_NMR' />,
      type: 'ordered',
      render: (value) => value || 'N/A',
      sorter: true
    },
    {
      dataIndex: 'dateOrder',
      title: <Translate id='ORDER_DATE' />,
      type: 'ordered',
      sorter: true,
      render: (orderDate) =>
        orderDate ? moment(orderDate).format('DD/MM/YYYY') : 'N/A'
    },
    {
      dataIndex: 'totalItems',
      type: 'text',
      title: <Translate id='QTY' />
    },
    {
      dataIndex: 'baseTotalValue',
      type: 'text',
      title: <Translate id='VALUE' />
    },
    {
      dataIndex: 'currency',
      type: 'text',
      title: <Translate id='CURRENCY' />,
      render: (value) => value?.code
    },
    {
      dataIndex: 'orderStatusId',
      type: 'ordered',
      sorter: true,
      title: <Translate id='ORDER_STATUS' />,
      render: (status) => <TableOrderStatus id={status} />
    },
    {
      dataIndex: 'dateDelivery',
      type: 'ordered',
      sorter: true,
      title: <Translate id='DATE_DELIVERY' />,
      render: (deliveryDate) =>
        deliveryDate ? (
          moment(deliveryDate).format('DD/MM/YYYY')
        ) : (
          <Translate id='NA' />
        )
    },
    {
      dataIndex: 'daysOverdue',
      type: 'text',
      title: <Translate id='DAYS_OVERDUE' />,
      render: (value) => {
        const roundedParsedValue = Math.floor(parseFloat(value))
        if (roundedParsedValue > 0) {
          return (

            <OverdueAlert $width='22px'>{roundedParsedValue}</OverdueAlert>
          )
        }
      }

    }

  ]

  componentDidMount = async () => {
    try {
      this.updateTable()
      const { data } = await GetInfoOrdersB2B()
      this.setState({ infoOrders: data })
    } catch (error) {
      console.warn(error)
    }
  }

  updateTable = async () => {
    const { page, pageSize, filters, sort } = this.state
    this.setState({ loading: true })

    const { data } = await GetOrdersB2B(
      page,
      pageSize,
      ConstructQuery(filters),
      ConstructSort(sort)
    )
    this.setState({
      rows: data?.items,
      total: data?.totalItems,
      loading: false
    })
  }

  handleRowSelect = (checked, record) => {
    const { selectedOrders } = this.state
    const found = selectedOrders.find((s) => s.key === record.orderB2bid)

    if (found) {
      this.setState({
        selectedOrders: selectedOrders.filter(
          (s) => s.key !== record.orderB2bid
        )
      })
    } else {
      selectedOrders.push({ key: record.orderB2bid, record })
      this.setState({ selectedOrders })
    }
  }

  onChangePagination = (page) => this.setState({ page }, this.updateTable)

  onChangeSort = (sort) => this.setState({ sort }, this.updateTable)

  createInternalPO = async () => {
    const { router } = this.props
    const { selectedOrders } = this.state
    this.setState({ loadingPO: true })
    const { success } = await CreatInternalPO(
      selectedOrders.map((selected) => selected.key)
    )
    if (success) router.history.push('/suppliers#open')
    else this.setState({ loadingPO: false })
  }

  setFilters = (values, time) => {
    this.setState({ filters: values, page: 1 }, () => {
      if (timeout) clearTimeout(timeout)
      timeout = setTimeout(this.updateTable, time)
    })
  }

  render() {
    const {
      loading,
      loadingPO,
      selectedOrders,
      rows,
      page,
      pageSize,
      total,
      infoOrders
    } = this.state
    const { router } = this.props

    return (
      <>
        <Row gutter={[24, 16]}>
          <Col>
            <BaseButton
              onClick={() => router.history.push('/orders/b2b/new')}
              imagePath={AddWhite}
            >
              <Translate id='NEW_ORDER' />
            </BaseButton>
          </Col>
        </Row>
        <Margin size={24} />
        <Row gutter={[24, 16]}>
          <Col xs={24} md={8}>
            <Highlight
              title={<Translate id='ACTIVE_ORDERS' />}
              text={infoOrders?.activeOrders}
            />
          </Col>
          <Col xs={24} md={8}>
            <Highlight
              title={<Translate id='CLOSED_ORDERS' />}
              text={infoOrders?.closedOrders}
            />
          </Col>
          <Col xs={24} md={8}>
            <Highlight
              title={<Translate id='NEW_ORDERS' />}
              text={infoOrders?.newOrders}
            />
          </Col>
        </Row>
        <Margin size={30} />
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 13, order: 3 }} md={{ span: 8, order: 1 }} lg={{ span: 6, order: 0 }}>
            <BaseButton
              loading={loadingPO}
              disabled={!selectedOrders.length}
              onClick={this.createInternalPO}
              type='secondary'
              imagePath={Export}
              auto
            >
              <Translate id='CREATE_INTERNAL_PO' />
            </BaseButton>
          </Col>
          <Col xs={0} lg={5} md={0} />
          <OrderSearch setFilters={this.setFilters} />
        </Row>
        <Margin size='30' />
        <Row>
          <Col xs={24}>
            <BaseTable
              rowKey='orderB2bid'
              highlightRow={(record) => record?.orderStatusId === 0}
              datasource={rows}
              columns={this.getColumns()}
              sortCallback={this.onChangeSort}
              pagination={{
                render: true,
                page,
                pageSize,
                total,
                onChangePagination: this.onChangePagination
              }}
              rowSelection={{
                selected: selectedOrders,
                hideSelectAll: true,
                renderCell: (prop, record) => (
                  <Checkbox
                    disabled={record.orderStatusId !== 0}
                    checked={
                      !!selectedOrders.find(
                        (s) => s.key === record.orderB2bid
                      )
                    }
                    onChange={(e) => this.handleRowSelect(e, record)}
                  />
                )
              }}
              onClickRow={(row) => {
                router.history.push(`/orders/b2b/${row.orderB2bid}`)
              }}
              loading={loading}
            />
          </Col>
        </Row>
      </>
    )
  }
}

OrdersB2B.propTypes = {
  router: PropTypes.object.isRequired
}

export default OrdersB2B
