import FormValidator from '../../../infra/services/validations/FormValidator'

export const PopupValidation = (values) => {
  const errors = []
  let empty = true
  if (!values.items || values.items.length === 0) {
    errors.push('The arrival products list is empty')
  } else {
    values.items.forEach((product) => {
      if (product.quantity && parseInt(product.quantity, 10) > 0) empty = false
      if (product.quantity && parseInt(product.quantity, 10) > 0 && !product.totalValue) {
        errors.push(`Define unit cost for product ${product.reference}`)
      }
    })
    if (empty) errors.push('Add a quantity for at least one product from the arrival list')
    // if (!selectedProducts) errors.push('Add a quantity for at least one product from the arrival list')
  }

  // if (values.movementTypeId === '1') {
  //   if (!values.attachments || values.attachments.length === 0) {
  //     errors.push('Please attach the invoice to the supplier reception')
  //   } else {
  //     let invoice = false
  //     values.attachments.forEach((attach) => {
  //       if (attach.type == '1') invoice = true
  //     })
  //     if (!invoice) {
  //       errors.push('Please attach the invoice to the supplier reception')
  //     }
  //   }
  // }

  return errors
}

const ArrivalFormValidation = (values) => {
  const errors = FormValidator.make({
    movementTypeId: 'required',
    date: 'required',
    supplierId: 'required',
    ownerId: 'required',
    clientId: 'required',
    warehouseId: 'required'
  })(values)

  if (values.movementTypeId === '1') {
    errors.supplierOrderId = FormValidator.validateField(
      'supplierOrderId',
      values.supplierOrderId,
      'required'
    )
  }

  if (values.movementTypeId === '3') {
    errors.returnReasonId = FormValidator.validateField(
      'returnReasonId',
      values.returnReasonId,
      'required'
    )
  }

  return errors
}

export default ArrivalFormValidation

export const ValidateConfirmationArrival = (values) => {
  const errors = []
  let empty = true
  values.productCombinationWarehouseMovement.forEach((product) => {
    if (values?.movementTypeID !== 4 && !(product.unitPriceReceived > 0)) {
      errors.push(`Define unit price received for product ${product.productReference}`)
    }
    if (product.recepQty > 0) empty = false
  })
  if (empty) errors.push('Define quantity received for at least one of the products in the list')

  if (values?.movementTypeID !== 4) {
    if ((!values.attachments || values.attachments.length === 0)) {
      errors.push('Please attach the invoice to the supplier reception')
    } else {
      let invoice = false
      values.attachments.forEach((attach) => {
        if (attach.type == '1') invoice = true
      })
      if (!invoice) {
        errors.push('Please attach the invoice to the supplier reception')
      }
    }
  }

  return errors
}
