import React, {Component} from 'react'

import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'

import Add from '../../../../assets/icons/add_bordered.svg'
import {GetProfiles} from '../../../../infra/requests/SkyproConfigsRequests'
import BaseButton from '../../../../shared/components/buttons/BaseButton'
import SearchInput from '../../../../shared/components/inputs/SearchInput'
import StatusColumn from '../../../../shared/components/Status/StatusColumn'
import BaseTable from '../../../../shared/components/table/BaseTable'
import {Margin} from '../../../../shared/styles/BasicStyles'

let timeout

class ProfileTab extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      rows: [],
      page: 1,
      pageSize: 30,
      total: 0,
      search: ''
    }
  }

  getColumns = () => [
    {
      dataIndex: 'name',
      type: 'text',
      title: <Translate id='PROFILE' />
    },
    {
      dataIndex: 'numberOfUsers',
      type: 'text',
      title: <Translate id='NUMBER_USERS' />
    },
    {
      dataIndex: 'numberOfActiveUsers',
      type: 'text',
      title: <Translate id='NUMBER_ACTIVE_USERS' />
    },
    {
      dataIndex: 'active',
      type: 'text',
      title: <Translate id='STATUS' />,
      render: (value) => <StatusColumn value={value} />
    }
  ]

  componentDidMount = async () => {
    try {
      this.updateTable()
    } catch (error) {
      console.warn(error)
    }
  }

  updateTable = async () => {
    const {page, pageSize, search} = this.state
    try {
      this.setState({loading: true})

      const {data, success} = await GetProfiles(page, pageSize, search)

      if (success) {
        this.setState({
          rows: data?.items,
          loading: false,
          total: data?.totalItems
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  onChangePagination = (page) => this.setState({page}, this.updateTable)

  setFilter = (search) => {
    this.setState({search, page: 1}, () => {
      if (timeout) clearTimeout(timeout)
      timeout = setTimeout(this.updateTable, 1000)
    })
  }

  render() {
    const {loading, rows, page, pageSize, total, search} = this.state
    const {router} = this.props

    return (
      <>
        <Row gutter={[24, 24]}>
          <Col xs={6}>
            <BaseButton
              auto
              imagePath={Add}
              type='primary'
              onClick={() => router.history.push('/skypro/profile/new')}
            >
              <Translate id='ADD_NEW' />
            </BaseButton>
          </Col>
          <Col xs={6} offset={12}>
            <SearchInput
              input={{
                value: search,
                onChange: this.setFilter,
                onSearch: this.updateTable
              }}
            />
          </Col>
        </Row>
        <Margin size={17} />
        <Row>
          <Col xs={24}>
            <BaseTable
              rowKey='profileId'
              datasource={rows}
              columns={this.getColumns()}
              pagination={{
                render: true,
                page,
                pageSize,
                total,
                onChangePagination: this.onChangePagination
              }}
              onClickRow={(row) => {
                router.history.push(`/skypro/profile/${row.profileId}`)
              }}
              loading={loading}
            />
          </Col>
        </Row>
      </>
    )
  }
}

ProfileTab.propTypes = {
  router: PropTypes.object.isRequired
}

export default ProfileTab
