import React, { useState, useEffect } from "react";

import { FileExcelOutlined } from "@ant-design/icons";
import { Row, Col } from "antd";
import moment from "moment/moment";
import { withLocalize } from "react-localize-redux";
import { useSelector } from "react-redux";

import { AuthTokenKey } from "../../infra/config/LocalStorageKeys";
import AlertService from "../../shared/components/alert/AlertService";
import BaseButton from "../../shared/components/buttons/BaseButton";
import DateInput from "../../shared/components/inputs/DateInput";
import FloatInput from "../../shared/components/inputs/FloatInput";
import SelectInput from "../../shared/components/inputs/SelectInput";
import SwitchInput from "../../shared/components/inputs/SwitchInput";
import TextInput from "../../shared/components/inputs/TextInput";
import { first } from "lodash";

const DefineSelectLabels = (param) => {
  const options = [];
  if (param && param.parameterValue) {
    const list = param.parameterValue.split(";");
    list.forEach((item) => {
      const result = item.split(",");
      options.push({
        id: result[0],
        name: result[1],
      });
    });
  }
  return options;
};

const ReportList = ({ report, translate, index, reporti }) => {
  const [exporting, setExporting] = useState(false);
  const [values, setValues] = useState({});
  const languages = useSelector((state) => state.localize.languages);

  // from the api comes "languageId" but the front was searching for "langid" , so i transformed the object 
  const transformedData = languages.map(({ languageId, name }) => ({
    id: languageId,
    name,
  }));

  const handleListInput = () => {
    if (report.module === "Sales" && index > 1) {
      // Verification for Sales Module parameters
      return report.parameters.filter((item) => item.parameterType !== 4);
    }
    else if (report.module === "Logistics") {
      // Verification for Logistics Module parameters
      // return report.parameters.filter((item) => item.parameterType !== 3);
      return report.parameters;
    }
    else if (report.module === "Marketing") {
      // Verification for Marketing Module parameters
      // return report.parameters.filter((item) => item.parameterType !== 2);
      return report.parameters;
    }
    else if (report.module === "Orders") {
      // Verification for Orders Module parameters
      // return report.parameters.filter((item) => item.parameterType !== 1);
      return report.parameters;
    }
    else if (report.module === "Employees") {
      // Verification for Employees Module parameters
      // return report.parameters.filter((item) => item.parameterType !== 4);
      return report.parameters;
    }
    else {
      // Default behavior, return all parameters for unknown modules
      return report.parameters;
    }
  }


  const initializeDefaultValues = () => {
    report.parameters.forEach((param) => {
      if (param.parameterType === 4) {
        setFilterValue(param.parameterName, 2);
      }
    });
  };

  useEffect(() => {
    initializeDefaultValues();
  }, []);

  const setFilterValue = (name, value) => {
    const result = { ...values };
    result[name] = value;
    setValues(result);
  };

  // Function to provide default value for SelectInput
  const getDefaultSelectValue = () => {
    // Check if there are available translations
    if (languages.length > 0) {
      // Find the default language based on the abbreviation
      const defaultLanguage = languages.find(
        (language) => language.abrev === "en" // Update 'en' to the abbreviation of your default language
      );

      // Return the name of the default language if found, otherwise return undefined
      return defaultLanguage ? defaultLanguage.name : undefined;
    }

    // Return undefined if there are no available translations
    return undefined;
  };

  const renderFieldType = (param) => {
    switch (param.parameterType) {
      case 0:
        return (
          <SelectInput
            label={param.parameterLabel}
            placeholder="Select from list"
            input={{
              value: values[param.parameterName],
              onChange: (v) => setFilterValue(param.parameterName, v),
            }}
            data={DefineSelectLabels(param)}
          />
        );
      case 1:
        return (
          <TextInput
            label={param.parameterLabel}
            placeholder="Text value"
            input={{
              value: values[param.parameterName],
              onChange: (v) => setFilterValue(param.parameterName, v),
            }}
          />
        );
      case 2:
        return (
          <FloatInput
            label={param.parameterLabel}
            placeholder="Decimal value"
            input={{
              value: values[param.parameterName],
              onChange: (v) => setFilterValue(param.parameterName, v),
            }}
          />
        );
      case 3:
        return (
          <DateInput
            label={param.parameterLabel}
            input={{
              value: values[param.parameterName],
              onChange: (v) => setFilterValue(param.parameterName, v),
            }}
          />
        );
      case 4:
        return (
          <SelectInput
            label={param.parameterLabel}
            placeholder={getDefaultSelectValue()}
            input={{
              value:
                values[param.parameterName] !== undefined
                  ? values[param.parameterName]
                  : 2, // Set initial value here
              onChange: (v) => {
                setFilterValue(param.parameterName, v);
              },
            }}
            data={transformedData}
          />
        );
      case 5:
        return (
          <SwitchInput
            label={param.parameterLabel}
            checkedText="Yes"
            uncheckedText="No"
            input={{
              value: values[param.parameterName],
              onChange: (v) => setFilterValue(param.parameterName, v),
            }}
          />
        );

      default:
        return null;
    }
  };

  const setParamValue = (param) => {
    switch (param.parameterType) {
      case 0:
      case 4:
        return values[param.parameterName]
          ? parseInt(values[param.parameterName], 10)
          : undefined;
      case 2:
        return values[param.parameterName]
          ? parseFloat(values[param.parameterName])
          : undefined;
      case 3:
        return values[param.parameterName]
          ? moment(values[param.parameterName])
            .startOf("day")
            .format("YYYY-MM-DD HH:mm:ss")
          : undefined;
      default:
        return values[param.parameterName];
    }
  };

  const handleDownload = () => {
    setExporting(true);
    const payload = {
      storeProcedureName: report.storeProcedureName,
      reportParameters: report.parameters.map((param) => ({
        ...param,
        parameterValue: setParamValue(param),
      })),
    };

    const authToken = localStorage.getItem(AuthTokenKey);
    fetch(`${window.env.REACT_APP_API}/ReportGeneric/ExecuteReport`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((resp) => {
        if (resp.status == 200) {
          resp
            .blob()
            .then((blob) => {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement("a");
              a.style.display = "none";
              a.href = url;
              a.download = `${report.storeProcedureName}.csv`;
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url);
              setExporting(false);
            })
            .catch(() => {
              AlertService.error(
                translate("ERROR"),
                translate("ERROR_DOWNLOAD_FILE")
              );
              setExporting(false);
            });
        } else {
          resp
            .json()
            .then((json) => {
              setExporting(false);
              return AlertService.error(
                translate("ERROR"),
                json.Message || translate("ERROR_DOWNLOAD_FILE")
              );
            })
            .catch(() => {
              AlertService.error(
                translate("ERROR"),
                translate("ERROR_DOWNLOAD_FILE")
              );
              setExporting(false);
            });
        }
      })
      .catch(() => {
        setExporting(false);
        AlertService.error(
          translate("ERROR"),
          translate("ERROR_DOWNLOAD_FILE")
        );
      });
  };

  return (
    <Row gutter={[24, 24]}>
      {handleListInput().map((param, columnIndex) => {
        return (
          <Col key={columnIndex} xs={6}>
            {renderFieldType(param)}
          </Col>
        );
      })}
      <Col xs={24} style={{ textAlign: "right" }}>
        <BaseButton
          auto
          loading={exporting}
          icon={<FileExcelOutlined />}
          onClick={handleDownload}
        >
          Download report
        </BaseButton>
      </Col>
    </Row>
  );
};

export default withLocalize(ReportList);
