import React, { useState } from 'react'

import { DeleteOutlined } from '@ant-design/icons'
import { Popconfirm } from 'antd'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { useParams } from 'react-router'
import styled from 'styled-components'

import EmptyImageIcon from '../../../../assets/placeholder.jpg'
import {
  SaveMeasureImage,
  DeleteProductImage
} from '../../../../infra/requests/ProductsRequests'
import FileInput from '../../../../shared/components/inputs/FileInput'
import { BorderColor, ErrorColor } from '../../../../shared/styles/_colors'
import { Margin } from '../../../../shared/styles/BasicStyles'

const ImageOverlay = styled.div`
  width: 100%;
  position: relative;
`

const Image = styled.img`
  width: 100%;
  background: #f0f0f0;
  min-height: 100px;
`

const DeleteImage = styled(DeleteOutlined)`
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 26px;
  color: ${ErrorColor};
  cursor: pointer;
`

const EmptySection = styled.div`
  width: 100%;
  background: #f0f0f0;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('${EmptyImageIcon}');
  background-size: cover;
  background-position: center;
  border: 1px solid ${BorderColor};
  border-radius: 4px;
`

const MeasureImage = ({ input }) => {
  const [loading, setLoading] = useState(false)
  const { id } = useParams()

  const layout =
    input.value && Array.isArray(input.value)
      ? input.value.find((i) => i.layout)
      : undefined

  const setLayoutImage = async (value) => {
    setLoading(true)
    const payload = new FormData()
    payload.append('File', value)
    const { data, success } = await SaveMeasureImage(id, payload)
    if (success) {
      input.onChange(data?.productPropertiesSection?.productImage)
    }
    setLoading(false)
  }

  const handleDelete = async () => {
    if (layout) {
      const { data, success } = await DeleteProductImage(id, layout.imageId)
      if (success) {
        input.onChange(data?.productPropertiesSection?.productImage)
      }
    }
  }

  const renderImage = () => {
    if (layout) {
      return (
        <ImageOverlay>
          <Image
            src={`${window.env.REACT_APP_API_FILES}/products/${layout?.image?.filename}`}
          />

          <Popconfirm
            placement='topRight'
            title={<Translate id='LAYOUT_IMAGE_MESSAGE' />}
            onConfirm={handleDelete}
          >
            <DeleteImage />
          </Popconfirm>
        </ImageOverlay>
      )
    }

    return <EmptySection />
  }

  return (
    <>
      {renderImage()}
      <Margin size={24} />
      <FileInput
        label={<Translate id='MEASURE_IMAGE' />}
        input={{
          value: layout?.image,
          onChange: setLayoutImage
        }}
        placeholder={<Translate id='SELECT' />}
        loading={loading}
        accept='image/*'
      />
    </>
  )
}

MeasureImage.propTypes = {
  input: PropTypes.object
}

MeasureImage.defaultProps = {
  input: {}
}

export default MeasureImage
