import client from '../config/AxiosConfig'

export const GetOpenPO = async () => client.get('OrderPO/GetOrdersPOOpen')

export const CreateMapNeeds = async (id, data) =>
  client.post(`OrderPO/CreateOrderNeeds/${id}`, data)

export const GetNeedsMap = async () =>
  client.get('OrderPO/GetOrdersPONeeds')

export const SaveNeedsMap = async (id, data) =>
  client.put(`OrderPO/UpdateOrderNeeds/${id}`, data)

export const ConvertToSupplierOrders = async (data) =>
  client.post('OrderSupplier/CreateOrdersSupplier', data)

export const GetConsolidation = async () =>
  client.get('OrderSupplier/GetOrderSupplierSetsConsolidation')

export const SaveConsolidation = async (id, data) =>
  client.put(`OrderSupplier/SaveConsolidationOrderSupplierSet/${id}`, data)

export const SendToConfirmation = async (data) =>
  client.put('OrderSupplier/ConsolidateOrderSupplierSets', data)

export const GetConfirmation = async () =>
  client.get('OrderSupplier/GetOrderSupplierSetsConfirmation')

export const SaveConfirmation = async (id, data, ignore = false) =>
  client.put(`OrderSupplier/SaveConfirmationOrderSupplierSet/${id}?IgnoreInvalidEmail=${ignore}`, data)

export const SendSupplierOrders = async (data) =>
  client.put('OrderSupplier/SendOrderSupplierSets', data)
