import React from 'react'

import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {Field, FieldArray} from 'redux-form'

import AddressList from '../../../../shared/components/address/AddressList'
import DescriptiveTextInput from '../../../../shared/components/inputs/DescriptiveTextInput'
import TextInput from '../../../../shared/components/inputs/TextInput'
import {Margin} from '../../../../shared/styles/BasicStyles'

const Information = ({countries, translate}) => (
  <>
    <Row gutter={[24, 50]}>
      <Col xs={8}>
        <Field
          component={TextInput}
          name='info.contact.name'
          label={<Translate id='NAME' />}
          placeholder={translate('NAME')}
        />
      </Col>
      <Col xs={6}>
        <Field
          component={TextInput}
          name='info.contact.email'
          label={<Translate id='EMAIL' />}
          placeholder={translate('EMAIL')}
          type='email'
        />
      </Col>
    </Row>
    <Margin size={20} />
    <Row gutter={[24, 40]}>
      <Col xs={6}>
        <Field
          component={DescriptiveTextInput}
          name='info.defaultStartSchedule'
          label={<Translate id='OPENING_HOUR' />}
          placeholder={translate('OPENING_HOUR')}
          descriptiveText='00:00:00'
        />
      </Col>
      <Col xs={6}>
        <Field
          component={DescriptiveTextInput}
          name='info.defaultEndSchedule'
          label={<Translate id='CLOSING_HOUR' />}
          placeholder={translate('CLOSING_HOUR')}
          descriptiveText='00:00:00'
        />
      </Col>
      <Col xs={6}>
        <Field
          component={DescriptiveTextInput}
          name='info.defaultSlotDuration'
          label={<Translate id='SLOT_DURATION' />}
          placeholder={translate('SLOT_DURATION')}
          descriptiveText='00:00:00'
        />
      </Col>
    </Row>
    <Margin size={20} />
    <Row gutter={[24, 40]}>
      <Col xs={24}>
        <FieldArray
          name='info.contact.contactDetail'
          component={AddressList}
          countries={countries}
          client
        />
      </Col>
    </Row>
  </>
)

Information.propTypes = {
  countries: PropTypes.array.isRequired,
  translate: PropTypes.func.isRequired
}

export default withLocalize(
  connect((state) => ({
    countries: state.info.countries
  }))(Information)
)
