import React from "react";
import { BarChartOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import moment from "moment";
import PropTypes from "prop-types";
import { Translate } from "react-localize-redux";
import {
  VictoryChart,
  VictoryTheme,
  VictoryBar,
  VictoryTooltip,
  VictoryGroup,
  VictoryLabel,
  VictoryAxis,
  VictoryContainer,
} from "victory";

import { StyledFlex } from "../../../shared/styles/BasicStyles";
import {
  EmptyDashboardMessage,
  StyledVictoryChartWrapper,
  ChartLoading,
} from "../DashboardStyles";
import LegendItem from "./LegendItem";

// Function to format Y-axis values
const calculateTickLegendValue = (value) => {
  if (value > 1000001) {
    return `${(value / 1_000_000).toFixed(0)}M`;
  } else if (value > 1001) {
    return `${(value / 1_000).toFixed(0)}K`;
  } else if (Number.isInteger(value)) {
    return value;
  } else {
    return value.toFixed(0);
  }
};

// Function to determine if text should be rotated
const shouldRotateText = (key, items) =>
  items.some((x) => x[key].length > 12) && items.length > 2;

// Function to set tick labels style based on rotation condition
const setTickLabelsText = (key, items) => {
  if (shouldRotateText(key, items)) {
    return { tickLabels: { angle: 20, textAnchor: "start" } };
  }
  return { tickLabels: { fill: "#231F20", fontSize: 14 } };
};

const GroupBarComponent = ({ info, loading }) => {
  if (info.options.empty) {
    return (
      <EmptyDashboardMessage>
        <BarChartOutlined />
        <div>
          <Translate id="NO_AVAILABLE_INFO" />
        </div>
      </EmptyDashboardMessage>
    );
  }
  if (!info.options.x) {
    return (
      <EmptyDashboardMessage>
        <BarChartOutlined />
        <div>
          <Translate id="NO_XAXIS_DEFINITION" />
        </div>
      </EmptyDashboardMessage>
    );
  }
  if (!info.options.y || info.options.y.length === 0) {
    return (
      <EmptyDashboardMessage>
        <BarChartOutlined />
        <div>
          <Translate id="NO_YAXIS_DEFINITION" />
        </div>
      </EmptyDashboardMessage>
    );
  }

  // Helper function to check if all y-values are zero
  const areAllYValuesZero = (items, yOptions) =>
    items.every((item) => yOptions.every((yOption) => item[yOption.key] === 0));

  // Check if all y-values are zero
  if (areAllYValuesZero(info.items, info.options.y)) {
    return (
      <EmptyDashboardMessage>
        <BarChartOutlined />
        <div>
          <Translate id="NO_DATA_AVAILABLE" />
        </div>
      </EmptyDashboardMessage>
    );
  }

  return (
    <>
      <StyledFlex $direction="row" $justify="space-between" $top="12">
        <StyledFlex $direction="row">
          {info.options.y.map((label, index) => (
            <LegendItem
              key={label.key}
              color={label.color}
              name={label.name}
              left={index > 0 ? "10" : "0"}
            />
          ))}
        </StyledFlex>
      </StyledFlex>
      <StyledVictoryChartWrapper>
        {loading && (
          <ChartLoading>
            <Spin />
          </ChartLoading>
        )}

        <VictoryChart
          fixLabelOverlap={true}
          theme={VictoryTheme.material}
          width={550}
          domainPadding={0}
          minDomain={{ x: 0 }}
          maxDomain={{ x: 13 }}
          containerComponent={
            <VictoryContainer
              style={{
                pointerEvents: "none",
                userSelect: "auto",
                touchAction: "auto",
              }}
            />
          }
        >
          <VictoryAxis
            scale="time"
            offsetY={50}
            style={{
              axis: { stroke: "transparent" },
              ticks: { stroke: "transparent" },
              grid: { stroke: "transparent" },
              ...setTickLabelsText(info.options.x.key, info.items) // Integrate tick label rotation conditionally
            }}
            tickValues={info.items.map((item) => item[info.options.x.key])} // Map tick values to X-axis keys

            tickLabelComponent={
              <VictoryLabel
                {...(shouldRotateText(info.options.x.key, info.items) && {
                  textAnchor: "start",
                  dy: -6,
                  dx: -6,
                })}
                text={({ index }) => {
                  const value = info.items[index][info.options.x.key];
                  if (info.options.x.key === "x_ReferenceCode" || info.options.x.key === "x_Reference") {
                    return value;
                  } else if (info.options.x.key === "x_ProductName") {
                    return value;
                  } else if (info.options.x.key === "x_Name") {
                    return value;
                  } else if (moment(value, "MM/YYYY").isValid()) {
                    return moment(value, "MM/YYYY").format("MMM YYYY").split(" ");
                  }
                  // style doesn't affect the text max width, manually add the "..." on a string
                  return value.length > 10 ? `${value.slice(0, 10)}...` : value;
                }}
                style={[
                  { fill: "#231F20", fontSize: 14 },
                  {
                    fill: "#231F20",
                    opacity: "50%",
                    fontSize: 14,
                  },
                ]}
              />
            }
          />
          <VictoryAxis
            scale="time"
            tickValues={info.items.map((item) => item[info.options.x.key])} // Use the key from info for tick values
            style={setTickLabelsText(info.options.x.key, info.items)} // Reuse the rotation and styling logic
            tickLabelComponent={
              <VictoryLabel
                {...(shouldRotateText(info.options.x.key, info.items) && {
                  textAnchor: "start",
                  dy: -6,
                  dx: -6,
                })}
                text={({ index }) => {
                  const value = info.items[index][info.options.x.key];
                  if (["x_ReferenceCode", "x_Reference", "x_ProductName", "x_Name"].includes(info.options.x.key)) {
                    return "";
                  } else if (moment(value, "MM/YYYY").isValid()) {
                    return "";
                  }
                  return "";
                }}
              />
            }
          />
          <VictoryAxis
            dependentAxis
            tickFormat={calculateTickLegendValue}
            tickLabelComponent={
              <VictoryLabel
                dx={6}
                dy={({ text }) => (text < 0 ? -12 : 0)} // Adjust label position for negative values
                style={{ fontSize: 14 }}
              />
            }
          />
          <VictoryGroup offset={-15}>
            {info.options.y.map((label) => (
              <VictoryBar
                key={label.key}
                style={{ data: { fill: label.color, width: 15 } }}
                cornerRadius={{ topLeft: 1, topRight: 1 }}
                x={info.options.x.key}
                y={label.key}
                barRatio={0.8}
                animate={{
                  duration: 2000,
                  onLoad: { duration: 1000 },
                }}
                labelComponent={<VictoryTooltip />}
                labels={({ datum }) =>
                  `${datum[info.options.x.key]} - ${datum[label.key]}`
                }
                data={info.items}
              />
            ))}
          </VictoryGroup>
        </VictoryChart>
      </StyledVictoryChartWrapper>
    </>
  );
};

GroupBarComponent.propTypes = {
  info: PropTypes.object.isRequired,
};

export default GroupBarComponent;
