import React from 'react'

import {Popover} from 'antd'
import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'
import Info from '../../../assets/icons/info.svg'
import {
  Flex,
  LineContent,
  CircleNote,
  NoteIcon,
  TextPopover
} from '../LogisticStyles'

const PricePopup = ({item}) => {
  const content = (
    <Flex>
      <TextPopover $bold>
        <Translate id='SALES_PRICE' />
      </TextPopover>
      <TextPopover>
        {item.productCombination?.product?.totalValue || 0}€
      </TextPopover>
    </Flex>
  )
  return (
    <Popover placement='top' trigger='hover' content={content}>
      <LineContent>
        {item.productCombination?.product?.costValue || 0}€
        <CircleNote>
          <NoteIcon src={Info} alt={<Translate id='INFO' />} />
        </CircleNote>
      </LineContent>
    </Popover>
  )
}

PricePopup.propTypes = {
  item: PropTypes.object.isRequired
}

export default PricePopup
