import styled from 'styled-components'

import {
  toolboxFixedSize,
  buttonsFixedSize,
  draftFixedSize
} from '../../../shared/components/inputs/InputStyles'
import {
  BorderColor,
  NavBackgroundColor,
  TableHeaderBackground,
  TableTextColor
} from '../../../shared/styles/_colors'
import {
  BookFontWeight,
  BoldFontWeight,
  SemiBoldFontWeight,
  LabelText,
  BodyText
} from '../../../shared/styles/_texts'

export const Title = styled.div`
  display: block;
  font-size: 18px;
  font-weight: ${BoldFontWeight};
  line-height: 24px;
  margin-top: ${({$top}) => $top || 0}px;
  margin-bottom: ${({$bottom}) => $bottom || 0}px;
  text-align: ${({$centered}) => ($centered ? 'center' : 'left')};
`
export const SubTitle = styled.div`
  display: block;
  font-size: 16px;
  font-weight: ${BoldFontWeight};
  margin-top: ${({$top}) => $top || 0}px;
  margin-bottom: ${({$bottom}) => $bottom || 0}px;
`

export const SemiBoldTitles = styled.label`
  font-size: 13px;
  font-weight: ${SemiBoldFontWeight};
  line-height: 15.6px;
`

export const MessageSubTitle = styled.div`
  font-size: 14px;
  font-weight: ${BookFontWeight};
  line-height: 15.6px;
  color: ${TableTextColor};
  opacity: 0.5;
  margin-top: ${({$noMarginTop}) => ($noMarginTop ? '0px' : '5px')};
`

export const ProcessContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-left: ${({$noBorderLeft}) =>
    $noBorderLeft ? 'none' : `1px solid ${BorderColor}`};
  border-right: ${({$noBorderRight}) =>
    $noBorderRight ? 'none' : `1px solid ${BorderColor}`};
  border-top: ${({$noBorderTop}) =>
    $noBorderTop ? 'none' : `1px solid ${BorderColor}`};
  border-bottom: ${({$noBorderBottom}) =>
    $noBorderBottom ? 'none' : `1px solid ${BorderColor}`};
  padding-left: ${({$left}) => $left || 20}px;
  padding-right: ${({$right}) => $right || 20}px;
  padding-top: ${({$top}) => $top || 15}px;
  padding-bottom: ${({$bottom}) => $bottom || 15}px;
  background-color: ${TableHeaderBackground};
  height: ${draftFixedSize + 235}px;
`

export const ProcessSubContainer = styled.div`
  border-top: ${({$noBorderTop}) =>
    $noBorderTop ? 'none' : `1px solid ${BorderColor}`};
  border-bottom: ${({$noBorderBottom}) =>
    $noBorderBottom ? 'none' : `1px solid ${BorderColor}`};
  padding-left: ${({$pLeft}) => $pLeft || 35}px;
  padding-right: ${({$pRight}) => $pRight || 35}px;
  padding-top: ${({$top}) => $top || 12}px;
  padding-bottom: ${({$bottom}) => $bottom || 12}px;
  margin-left: ${({$left}) => $left || -20}px;
  margin-right: ${({$right}) => $right || -20}px;
  background-color: ${TableHeaderBackground};
  overflow: ${({$overflow}) => $overflow || 'inherit'};
`

export const BottomSubContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: calc(100% - 2px);
  border-top: ${({$noBorderTop}) =>
    $noBorderTop ? 'none' : `1px solid ${BorderColor}`};
  border-bottom: ${({$noBorderBottom}) =>
    $noBorderBottom ? 'none' : `1px solid ${BorderColor}`};
  padding-left: ${({$left}) => $left || 35}px;
  padding-right: ${({$right}) => $right || 35}px;
  padding-top: ${({$top}) => $top || 12}px;
  padding-bottom: ${({$bottom}) => $bottom || 12}px;
  margin-left: ${({$left}) => $left || -20}px;
  margin-right: ${({$right}) => $right || -20}px;
  background-color: ${TableHeaderBackground};
`

export const ContentWithoutMargin = styled.div`
  margin-left: ${({$left}) => $left || -35}px;
  margin-right: ${({$right}) => $right || -35}px;
`

export const ProcessNumber = styled.label`
  margin-left: 10px;
  font-weight: ${BookFontWeight};
  line-height: 16.8px;
`

export const ProcessTitle = styled.div`
  color: ${TableTextColor};
  font-weight: ${SemiBoldFontWeight};
  font-size: ${LabelText};
  text-align: left;
  margin-top: ${({$top}) => $top || 0}px;
`
export const ProcessDescription = styled.div`
  color: ${TableTextColor};
  font-weight: ${BookFontWeight};
  font-size: ${BodyText};
  text-align: left;
  margin-bottom: ${({$bottom}) => $bottom || 0}px;
  margin-left: ${({$left}) => $left || 0}px;
`
export const ContactContainer = styled.div`
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-top: 1px solid ${BorderColor};
  opacity: ${({$isDragging}) => ($isDragging ? 0.2 : 1)};
  &:first-child {
    border-top: none;
  }
  cursor: grab;
`

export const ContactDragImageContainer = styled.div`
  margin: 5px;
  border-right: 1px solid ${BorderColor};
`

export const ContactDragImage = styled.img`
  margin-right: 5px;
  padding: 3px;
`

export const ContactName = styled.div`
  color: ${TableTextColor};
  font-weight: ${BookFontWeight};
  font-size: ${BodyText};
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 22px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  padding-right: 5px;
`

export const BottomButtonsContainer = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid ${BorderColor};
  height: ${toolboxFixedSize}px;
`

export const AttatchIconImg = styled.img`
  height: ${buttonsFixedSize - 2}px;
  border: 1px solid transparent;
  border-radius: 2px;
  background-color: transparent;
  padding: 3px;
  margin-right: 20px;
  cursor: pointer;

  &:active {
    border: 1px solid #dedede;
    background-color: #dedede;
    box-shadow: none;
    outline: none;
  }

  &:focus {
    border: 1px solid #dedede;
    box-shadow: none;
    outline: none;
  }

  &:hover {
    border: 1px solid #dedede;
    box-shadow: none;
    outline: none;
  }
`

export const FooterProcess = styled.div`
  background-color: ${NavBackgroundColor};
  width: 100%;
  border: 1px solid ${BorderColor};
  border-top: none;
  padding: 13px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
`

export const ProcessTabArrow = styled.img`
  transform: ${({$active}) =>
    $active ? 'rotate(180deg)' : 'rotate(0deg)'};
  transition: all 0.2s ease;
`

export const PriorityLabel = styled.div`
  font-size: 16px;
  color: rgb(35, 31, 32);
  font-weight: 600;
`

export const MessageContactsSection = styled.div`
  display: block;
  width: 100%;
  background: ${TableHeaderBackground};
  padding: 2px 20px;
  height: 151px;
  overflow-y: auto;
  border-top: 1px solid ${BorderColor};
  border-left: ${({$bigger}) =>
    $bigger ? `1px solid ${BorderColor}` : 'none'};
`

export const MessageSubjectSection = styled.div`
  display: block;
  width: 100%;
  background: ${TableHeaderBackground};
  padding: 0px 20px;
  border-top: 1px solid ${BorderColor};
  border-bottom: 1px solid ${BorderColor};
  border-left: ${({$bigger}) =>
    $bigger ? `1px solid ${BorderColor}` : 'none'};
`

export const MessageContainer = styled.div`
  background: white;
  display: block;
  width: 100%;
  border-bottom: 1px solid ${BorderColor};
  border-left: ${({$bigger}) =>
    $bigger ? `1px solid ${BorderColor}` : 'none'};
`
