import React, { Component } from 'react'

import { Row, Col } from 'antd'
import PropTypes from 'prop-types'
import { Translate, withLocalize } from 'react-localize-redux'

import Export from '../../../assets/icons/export.svg'
import { AuthTokenKey } from '../../../infra/config/LocalStorageKeys'
import { GetOverview } from '../../../infra/requests/LogisticsRequests'
import AlertService from '../../../shared/components/alert/AlertService'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import ProductLink from '../../../shared/components/links/ProductLink'
import BaseTable from '../../../shared/components/table/BaseTable'
import { ConstructQuery } from '../../../shared/logic/queries/EndpointQueries'
import ActiveTranslation from '../../../shared/logic/translations/ActiveTranslation'
import { Margin, StyledHR } from '../../../shared/styles/BasicStyles'
import MenuPopup from './MenuPopup'
import OverviewFilters from './OverviewFilters'
import OverviewProdStatus from './OverviewProdStatus'
import PricePopup from './PricePopup'
import ReferencePopup from './ReferencePopup'
import WarehousePopup from './WarehousePopup'

let timeout

const DefineKey = (row) => `${row.productId}_${row.productWidthId}_${row.sizeId}_${row.colorId}_${row.warehouseId}`

class OverviewList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      exporting: false,
      rows: [],
      page: 1,
      pageSize: 20,
      total: 0,
      filters: {}
    }
  }

  componentDidMount = async () => {
    try {
      this.updateTable()
    } catch (error) {
      console.warn(error)
    }
  }

  updateTable = async () => {
    const { page, pageSize, filters } = this.state
    this.setState({ loading: true })
    const { data } = await GetOverview(
      page,
      pageSize,
      ConstructQuery(filters)
    )
    this.setState({
      rows: data?.items || [],
      total: data?.totalItems || 0,
      loading: false
    })
  }

  onChangePagination = (page) => this.setState({ page }, this.updateTable)

  setFilters = (values, time) => {
    this.setState({ filters: values, page: 1 }, () => {
      if (timeout) clearTimeout(timeout)
      timeout = setTimeout(this.updateTable, time)
    })
  }


  getColumns = () => {
    const { router: { history }, onChangeTab } = this.props
    return [
      {
        title: <Translate id='CLIENT' />,
        type: 'text',
        dataIndex: 'b2bclient',
        render: (value) => value?.name || <Translate id='NA' />
      },
      {
        title: <Translate id='WAREHOUSE' />,
        type: 'text',
        dataIndex: 'warehouse',
        render: (value) => (
          <>
            <WarehousePopup item={value} />
          </>
        )
      },
      {
        title: <Translate id='R_SKYPRO' />,
        type: 'text',
        dataIndex: 'productCombination',
        render: (value) => <ReferencePopup item={value} />
      },
      {
        title: <Translate id='DESCRIPTION' />,
        type: 'text',
        render: (value) => (
          <ProductLink product={value?.productCombination?.product} />
        )
      },
      {
        title: <Translate id='COLOR' />,
        type: 'text',
        render: (value) => (
          <ActiveTranslation
            value={value?.productCombination?.color?.colorTranslation}
            tag='name'
          />
        )
      },
      {
        title: <Translate id='SIZE' />,
        type: 'text',
        render: (value) =>
          value?.productCombination?.size?.nameEu || <Translate id='NA' />
      },
      {
        title: <Translate id='STOCK' />,
        type: 'text',
        dataIndex: 'units'
      },
      {
        title: <Translate id='UM' />,
        type: 'text',
        render: (value) =>
          value?.productCombination?.product?.measureUnit?.code || (
            <Translate id='NA' />
          )
      },
      {
        title: <Translate id='PROD' />,
        type: 'text',
        render: (value) => <OverviewProdStatus item={value} />
      },
      {
        title: <Translate id='RESERVED' />,
        type: 'text',
        dataIndex: 'unitsReserved'
      },
      {
        title: <Translate id='UNIT_COST' />,
        type: 'text',
        render: (value) => (
          <>
            <PricePopup item={value} />
            <MenuPopup item={value} history={history} onChangeTab={onChangeTab} />
          </>
        )
      }
    ]
  }

  handleExport = async () => {
    const { translate } = this.props
    const { page, pageSize, filters } = this.state
    this.setState({ exporting: true })
    const authToken = localStorage.getItem(AuthTokenKey)
    fetch(
      `${window.env.REACT_APP_API}/Logistics/ExportOverviewXls?ItemsPerPage=${pageSize}&Page=${page}${ConstructQuery(filters)}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }
    )
      .then((resp) => {
        if (resp.status == 200) {
          resp.blob().then((blob) => {
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.style.display = 'none'
            a.href = url
            a.download = 'tts'
            document.body.appendChild(a)
            a.click()
            window.URL.revokeObjectURL(url)
            this.setState({ exporting: false })
          })
            .catch(() => {
              AlertService.error(translate('ERROR'), translate('ERROR_DOWNLOAD_FILE'))
              this.setState({ exporting: false })
            })
        } else {
          this.setState({ exporting: false })
          return AlertService.error(translate('ERROR'), translate('ERROR_DOWNLOAD_FILE'))
        }
      })
      .catch(() => {
        this.setState({ exporting: false })
        AlertService.error(translate('ERROR'), translate('ERROR_DOWNLOAD_FILE'))
      })
  }

  render() {
    const { loading, exporting, rows, page, pageSize, total, filters } = this.state

    return (
      <>
        <OverviewFilters filters={filters} setFilters={this.setFilters} />
        <StyledHR $space='20' />
        <Row>
          <Col xs={4}>
            <BaseButton
              auto
              loading={exporting}
              imagePath={Export}
              type='secondary'
              onClick={this.handleExport}
            >
              <Translate id='EXPORT_XLS' />
            </BaseButton>
          </Col>
          <Col xs={14} />
          <Col xs={6} />
        </Row>
        <Margin size={15} />
        <Row>
          <Col xs={24}>
            <BaseTable
              rowKey={DefineKey}
              datasource={rows}
              columns={this.getColumns()}
              pagination={{
                render: true,
                page,
                pageSize,
                total,
                onChangePagination: this.onChangePagination
              }}
              loading={loading}
            />
          </Col>
        </Row>
      </>
    )
  }
}

OverviewList.propTypes = {
  router: PropTypes.object.isRequired
}

export default withLocalize(OverviewList)
