import React, {useEffect, useState} from 'react'

import {Col, Row} from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {useLocation} from 'react-router'
import {change, Field, FieldArray, getFormValues, initialize, reduxForm} from 'redux-form'

import {GetNonEmployeesDrop} from '../../../infra/requests/BaseRequests'
import {GetEmployeesByB2bclient} from '../../../infra/requests/DepartureRequests'
import {CreateNewOrder, GetUserContactDetails} from '../../../infra/requests/OrdersB2CRequests'
import FormValidator from '../../../infra/services/validations/FormValidator'
import AddressComponent from '../../../shared/components/address/AddressComponent'
import AddressModal from '../../../shared/components/address/AddressModal'
import BackButton from '../../../shared/components/buttons/BackButton'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import CheckboxInput from '../../../shared/components/inputs/CheckboxInput'
import DateInput from '../../../shared/components/inputs/DateInput'
import NewDocumentsInput from '../../../shared/components/inputs/Documents/NewDocumentsInput'
import ProductsTable from '../../../shared/components/inputs/Products/ProductsTable'
import SelectInput from '../../../shared/components/inputs/SelectInput'
import SwitchInput from '../../../shared/components/inputs/SwitchInput'
import BaseLoading from '../../../shared/components/loading/BaseLoading'
import {Margin, PageForm, PageSubtitle, PageTitle} from '../../../shared/styles/BasicStyles'
import EditOrderHeaderTitle from './components/EditOrderHeaderTitle'
import {EditOrderTitle} from './components/Styles'
import {TransformAddressToOrder, TransformOrderBillingAddress, TransformOrderShippingAddress} from './helpers/TransformContactsAddresses'

// eslint-disable-next-line camelcase
const CreateOrder_B2B2C_B2C = (
  {
    router,
    formValues,
    handleSubmit,
    clients,
    translate,
    countries,
    paymentMethods,
    dispatch
  }) => {
  const [loading, setLoading] = useState(true)
  const [openModal, setModal] = useState(false)
  const [refresh, setRefresh] = useState(undefined)
  const [employees, setEmployees] = useState([])
  const [users, setUsers] = useState([])
  const location = useLocation()

  const shippingAddress = TransformOrderShippingAddress(formValues)
  const billingAddress = TransformOrderBillingAddress(formValues)
  const isOrderB2B2C = location.pathname.indexOf('b2b2c') > -1

  useEffect(() => {
    async function getUsers() {
      const {success, data} = await GetNonEmployeesDrop()
      if (success) {
        setUsers(data.items)
      }
    }

    dispatch(
      initialize(
        'create-order-b2c-b2b2c',
        {
          ...TransformAddressToOrder(),
          dateDelivery: moment(),
          date: moment(),
          sendNotifications: true
        },
        true,
        {
          keepDirty: true,
          keepSubmitSucceeded: false,
          updateUnregisteredFields: true,
          keepValues: true
        }
      )
    )
    if (!isOrderB2B2C) {
      getUsers()
    }
    setLoading(false)
  }, [])

  useEffect(() => {
    async function updateEmployees() {
      const {success, data} = await GetEmployeesByB2bclient(formValues.b2bclientId)
      if (success) {
        setEmployees(data.items)
      }
    }

    if (formValues.b2bclientId != undefined) {
      updateEmployees()
    }
  }, [formValues.b2bclientId])

  if (loading) {
    return (
      <BaseLoading />
    )
  }

  const leaveOrder = () => {
    if (isOrderB2B2C) {
      return router.history.push('/orders#b2b2c')
    }
    return router.history.push('/orders#b2c')
  }

  const openEditAddress = (address, index) => {
    dispatch(
      initialize('manage_new_address', {...address, openIndex: index})
    )
    setModal(true)
  }

  const closeModal = () => {
    setModal(false)
    dispatch(initialize('manage_new_address', {}))
  }

  const onSubmit = async (values) => {
    setLoading(true)
    const {success} = await CreateNewOrder(values.sendNotifications, values)
    if (success) {
      leaveOrder()
    }
    setLoading(false)
  }

  const clearProductsTable = () => {
    dispatch(change('create-order-b2c-b2b2c', 'orderItem', []))
    dispatch(change('create-order-b2c-b2b2c', 'employeeId', undefined))
    setTimeout(() => setRefresh(Math.random()), 50)
  }

  const updateOrderAddress = (addressData) => {
    dispatch(
      initialize(
        'create-order-b2c-b2b2c',
        TransformAddressToOrder(addressData),
        true,
        {
          keepDirty: true,
          keepSubmitSucceeded: false,
          updateUnregisteredFields: true,
          keepValues: false
        }
      )
    )
  }

  const onChangeEmployee = async (employeeId) => {
    if (employeeId) {
      const employee = employees.find((x) => x.employeeId == employeeId)
      const {success, data} = await GetUserContactDetails(employee.userId)
      if (success && data.length > 0) {
        dispatch(change('create-order-b2c-b2b2c', 'userId', employee.userId))
        updateOrderAddress(data[0])
      } else {
        updateOrderAddress()
      }
    } else {
      updateOrderAddress()
    }
  }

  const handleToggleAddress = async (value) => {
    if (value) {
      dispatch(
        initialize(
          'create-order-b2c-b2b2c',
          {
            ...formValues,
            billingDataSet: true,
            billingName: formValues?.deliveryName,
            billingAddressLine1: formValues?.deliveryAddressLine1,
            billingAddressLine2: formValues?.deliveryAddressLine2,
            billingAddressLine3: formValues?.deliveryAddressLine3,
            billingCity: formValues?.deliveryCity,
            billingCountryId: formValues?.deliveryCountryId,
            billingRegion: formValues?.deliveryRegion,
            billingZipCode: formValues?.deliveryZipCode,
            billingStreetNumber: formValues?.deliveryStreetNumber
          },
          true,
          {
            keepDirty: true,
            keepSubmitSucceeded: false,
            updateUnregisteredFields: true,
            keepValues: false
          }
        )
      )
    } else {
      dispatch(
        initialize(
          'create-order-b2c-b2b2c',
          {
            ...formValues,
            billingDataSet: false,
            billingName: 'Billing info',
            billingAddressLine1: '',
            billingAddressLine2: '',
            billingAddressLine3: '',
            billingCity: '',
            billingCountryId: '',
            billingRegion: '',
            billingZipCode: '',
            billingStreetNumber: ''
          },
          true,
          {
            keepDirty: true,
            keepSubmitSucceeded: false,
            updateUnregisteredFields: true,
            keepValues: false
          }
        )
      )
    }
  }

  const onSaveAddress = async (values) => {
    if (formValues.billingDataSet) {
      dispatch(
        initialize('create-order-b2c-b2b2c', {
          ...formValues,
          billingName: values.name,
          billingAddressLine1: values.addressLine1,
          billingAddressLine2: values.addressLine2,
          billingAddressLine3: values.addressLine3,
          billingCity: values.city,
          billingCountryId: values.countryId,
          billingRegion: values.region,
          billingZipCode: values.zipCode,
          billingStreetNumber: values.streetNumber,
          deliveryName: values.name,
          deliveryAddressLine1: values.addressLine1,
          deliveryAddressLine2: values.addressLine2,
          deliveryAddressLine3: values.addressLine3,
          deliveryCity: values.city,
          deliveryCountryId: values.countryId,
          deliveryRegion: values.region,
          deliveryZipCode: values.zipCode,
          deliveryStreetNumber: values.streetNumber
        })
      )
    } else if (values.openIndex === 0) {
      dispatch(
        initialize('create-order-b2c-b2b2c', {
          ...formValues,
          deliveryName: values.name,
          deliveryAddressLine1: values.addressLine1,
          deliveryAddressLine2: values.addressLine2,
          deliveryAddressLine3: values.addressLine3,
          deliveryCity: values.city,
          deliveryCountryId: values.countryId,
          deliveryRegion: values.region,
          deliveryZipCode: values.zipCode,
          deliveryStreetNumber: values.streetNumber
        })
      )
    } else {
      dispatch(
        initialize('create-order-b2c-b2b2c', {
          ...formValues,
          billingName: values.name,
          billingAddressLine1: values.addressLine1,
          billingAddressLine2: values.addressLine2,
          billingAddressLine3: values.addressLine3,
          billingCity: values.city,
          billingCountryId: values.countryId,
          billingRegion: values.region,
          billingZipCode: values.zipCode,
          billingStreetNumber: values.streetNumber
        })
      )
    }
    setModal(false)
    dispatch(initialize('manage_new_address', {}))
  }

  return (
    <PageForm>
      <Row gutter={[0, 50]}>
        <Col xs={24}>
          <BackButton
            label={<Translate id='BACK_ORDERS' />}
            onClick={leaveOrder}
          />
        </Col>
        <Col xs={24}>
          <PageTitle>
            <Translate id='ORDER_PROCESS' />
          </PageTitle>
        </Col>
      </Row>
      <Margin size={30} />
      <Row gutter={[20, 20]}>
        {
          isOrderB2B2C && (
            <>
              <Col xs={4}>
                <Field
                  component={SelectInput}
                  name='b2bclientId'
                  label={<Translate id='CLIENT' />}
                  placeholder={<Translate id='DEFINE_CLIENT' />}
                  data={clients}
                  hasPaddingBottom
                  dataLabel='name'
                  dataKey='b2bclientId'
                  afterChange={clearProductsTable}
                />
              </Col>

              <Col xs={6}>
                <Field
                  component={SelectInput}
                  name='employeeId'
                  disabled={employees.length <= 0}
                  label={<Translate id='EMPLOYEE' />}
                  placeholder={<Translate id='DEFINE_EMPLOYEE' />}
                  data={employees}
                  hasPaddingBottom
                  renderLabel={(value) => `${value.firstName} ${value.lastName}`}
                  dataKey='employeeId'
                  afterChange={async (value) => onChangeEmployee(value)}
                />
              </Col>
            </>
          )
        }
        {
          !isOrderB2B2C && (
            <>
              <Col xs={4}>
                <Field
                  component={SelectInput}
                  name='userId'
                  label={<Translate id='CLIENT' />}
                  placeholder={<Translate id='DEFINE_CLIENT' />}
                  data={users}
                  hasPaddingBottom
                  renderLabel={(value) => `${value.firstName} ${value.lastName}`}
                  dataKey='userId'
                />
              </Col>
            </>
          )
        }
        <Col xs={4}>
          <Field
            component={DateInput}
            name='dateDelivery'
            label={<Translate id='DATE_DELIVERY' />}
            placeholder='Delivery Date'
            hasPaddingBottom
          />
        </Col>
        <Col xs={12} lg={6}>
          <Field
            component={SelectInput}
            data={paymentMethods.filter(
              (x) => x.paymentMethodID === 2 || x.paymentMethodID === 4
            )}
            dataLabel='name'
            dataKey='paymentMethodID'
            name='paymentMethod'
            label={<Translate id='PAYMENT_METHOD' />}
            placeholder={<Translate id='DEFINE_PAYMENT_METHOD' />}
          />
        </Col>
        <Col xs={12} lg={3}>
          <EditOrderHeaderTitle
            label={<Translate id='SEND_NOTIFICATIONS' />}
            value={
              <Field
                component={SwitchInput}
                name='sendNotifications'
                checkedText={<Translate id='ON' />}
                uncheckedText={<Translate id='OFF' />}
              />
            }
          />
        </Col>
      </Row>
      <Margin size={30} />
      <Row>
        <Col xs={12} lg={6}>
          <EditOrderTitle>
            <Translate id='SHIPPING_ADDRESS' />
          </EditOrderTitle>
          <AddressComponent
            countries={countries}
            address={shippingAddress}
            openAddress={() => openEditAddress(shippingAddress, 0)}
          />
        </Col>
        <Col xs={12} lg={6}>
          <EditOrderTitle>
            <Translate id='BILLING_ADDRESS' />
          </EditOrderTitle>
          <AddressComponent
            countries={countries}
            address={billingAddress}
            openAddress={formValues.billingDataSet ?
              undefined :
              () => openEditAddress(billingAddress, 1)}
          />
          <Margin size={20} />
          <Field
            name='billingDataSet'
            component={CheckboxInput}
            label={<Translate id='SAME_SHIPPING_ADDRESS' />}
            afterChange={handleToggleAddress}
          />
        </Col>
      </Row>
      <Margin size={10} />
      <Row align='end'>
        <Col xs={6}>
          <FieldArray component={NewDocumentsInput} name='attachment' />
        </Col>
      </Row>
      <Margin size={30} />
      <PageSubtitle>
        <Translate id='PRODUCTS' />
      </PageSubtitle>
      <FieldArray
        component={ProductsTable}
        name='orderItem'
        client={isOrderB2B2C ? formValues?.b2bclientId : clients.find((x) => x.name == 'Skypro')?.b2bclientId}
        refresh={refresh}
      />
      <Margin size={30} />
      <Row align='middle' justify='end'>
        <Col xs={24} flex={1} style={{textAlign: 'right'}}>
          <BaseButton
            type='secondary'
            onClick={() => router.history.push('/orders')}
            auto
            disabled={loading}
          >
            <Translate id='CANCEL_ORDER' />
          </BaseButton>
          <BaseButton
            style={{marginLeft: '20px'}}
            auto
            type='primary'
            onClick={handleSubmit(onSubmit)}
            loading={loading}
          >
            <Translate id='CREATE_ORDER' />
          </BaseButton>
        </Col>
      </Row>
      <AddressModal
        open={openModal}
        onClose={closeModal}
        onSave={onSaveAddress}
        countries={countries}
      />
    </PageForm>
  )
}

CreateOrder_B2B2C_B2C.propTypes = {
  router: PropTypes.object.isRequired,
  formValues: PropTypes.object,
  orderStatus: PropTypes.array,
  paymentMethods: PropTypes.array,
  dispatch: PropTypes.func
}

CreateOrder_B2B2C_B2C.defaultProps = {
  formValues: {},
  orderStatus: [],
  paymentMethods: [],
  dispatch: undefined
}

const validations = FormValidator.make({
  client: 'required',
  orderItem: 'required|array'
})

const myComponent = reduxForm({
  form: 'create-order-b2c-b2b2c',
  validate: validations
})(CreateOrder_B2B2C_B2C)

export default withLocalize(connect((state) => ({
  countries: state.info.countries,
  clients: state.info.clients,
  paymentMethods: state.info.paymentMethods,
  formValues: getFormValues('create-order-b2c-b2b2c')(state)
}))(myComponent))
