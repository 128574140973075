import React, {useEffect, useState} from 'react'

import {Col, Row} from 'antd'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import {
  GetGeneralServer,
  GetReceivingServers,
  PutGeneralServer
} from '../../../../infra/requests/BaseRequests'
import AlertService from '../../../../shared/components/alert/AlertService'
import BaseButton from '../../../../shared/components/buttons/BaseButton'
import SelectInput from '../../../../shared/components/inputs/SelectInput'
import BaseLoading from '../../../../shared/components/loading/BaseLoading'
import {Margin} from '../../../../shared/styles/BasicStyles'

const ConfigurationTab = ({translate}) => {
  const [ready, setReady] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedMail, SetSelectedMail] = useState()
  const [listMail, SetListMails] = useState([])
  useEffect(() => {
    async function init() {
      const {data, success} = await GetReceivingServers()
      if (success) {
        const result = await GetGeneralServer()
        SetSelectedMail(result?.data?.mailServerId)
        SetListMails(data?.items)
        setReady(true)
      }
    }
    init()
  }, [])

  const onSubmit = async () => {
    try {
      setLoading(true)
      const {success} = await PutGeneralServer(selectedMail)
      if (success) {
        AlertService.success(
          translate('SUCCESS'),
          translate('GENERAL_EMAIL_EDITED_SUCCESSFULLY')
        )
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  if (!ready) return <BaseLoading margin='100' />

  return (
    <>
      <Row>
        <Col xs={24} align='end'>
          <BaseButton auto onClick={onSubmit} loading={loading}>
            <Translate id='SAVE' />
          </BaseButton>
        </Col>
      </Row>
      <Margin size={12} />
      <Row gutter={24}>
        <Col xs={6}>
          <SelectInput
            label={<Translate id='GENERAL_MAILBOX' />}
            placeholder={<Translate id='GENERAL_MAILBOX' />}
            data={listMail}
            dataKey='mailServerId'
            dataLabel='name'
            allowClear={false}
            input={{value: selectedMail, onChange: SetSelectedMail}}
          />
        </Col>
      </Row>
    </>
  )
}

export default withLocalize(ConfigurationTab)
