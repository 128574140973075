import React, { useEffect, useState } from 'react'

import { useOktaAuth } from '@okta/okta-react'
import { Spin } from 'antd'
import { connect } from 'react-redux'
import styled from 'styled-components'

import OKTAIcon from '../../assets/icons/okta.svg'
import { AuthTokenKey } from '../../infra/config/LocalStorageKeys'
import { LoginAuth } from '../../infra/requests/AuthRequests'
import { GetAuthUser } from '../../infra/requests/BaseRequests'
import { updatePermissions } from '../../redux/data/permissions/PermissionsActions'
import { userSave } from '../../redux/data/user/UserActions'
import GetBasicInformation from '../../redux/thunks/GetBasicInformation'
import BaseButton from '../../shared/components/buttons/BaseButton'

const Section = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
        height: 200px;
    }
`

const Message = styled.div`
  font-size: 20px;
  font-weight: bold;
`
const ButtonSection = styled.div`
  text-align: center;
  margin-top: 30px;
`

const OktaRedirect = ({ router, dispatch }) => {
  const [error, setError] = useState(false)
  const { oktaAuth } = useOktaAuth()

  useEffect(() => {
    const request = async () => {
      oktaAuth.token.parseFromUrl().then(async (res) => {
        if (res.tokens) {
          const result = await LoginAuth({
            authValidation: 2,
            tokenSSO: res?.tokens?.accessToken?.accessToken
          })
          if (result.success) {
            localStorage.setItem(AuthTokenKey, result.data)
            const userData = await GetAuthUser()
            if (userData.success) {
              dispatch(userSave({
                fullName: userData?.data?.fullName,
                profileName: userData?.data?.profileName,
                profileId: userData?.data?.profileId,
                userId: userData?.data?.userId,
                ewpId: userData?.data?.ewpId
              }))
              dispatch(updatePermissions(userData?.data?.permissions))
            }
            dispatch(GetBasicInformation())
            return router.history.push('/')
          }
          return setError(true)
        }
      }).catch((err) => {
        console.log(err)
        setError(true)
      })
    }
    request()
  }, [])

  return (
    <Section>
      <img src={OKTAIcon} alt='OKTA' />
      {error ? (
        <>
          <Message>Unable to finish the login process</Message>
          <Message>Please try again later</Message>
          <ButtonSection>
            <BaseButton
              auto
              type='primary'
              onClick={() => router.history.push('/login')}
            >
              Back to login
            </BaseButton>
          </ButtonSection>
        </>
      ) : <Spin size='large' style={{ marginTop: '100px' }} />}
    </Section>
  )
}

export default connect()(OktaRedirect)
