import CheckIfItemsAreMissing from './CheckIfItemsAreMissing'
import CheckIfPackageIsMissing from './CheckIfPackageIsMissing'
import ValidateAddresses from './ValidateAddresses'

export const ValidateItemsAndBoxes = (order) => {
  if (Array.isArray(order.orderItem) && order.orderItem.length > 0) {
    const itemsMissing = CheckIfItemsAreMissing(order)
    if (itemsMissing) {
      return 2
    }
    if (Array.isArray(order.orderBox) && order.orderBox.length > 0) {
      const packageIsMissing = CheckIfPackageIsMissing(order)
      if (packageIsMissing) {
        return 3
      }
    } else {
      return 3
    }
  } else {
    return 3
  }

  return -1
}

export default (order) => {
  const invalidItemsAndBoxes = ValidateItemsAndBoxes(order)
  switch (order?.orderStatusId) {
    // processing
    case 1:
      // check if order is
      if (order.paid) {
        // check if addresses are valid
        const addressesErrors = ValidateAddresses(order)
        if (addressesErrors.length > 0) {
          return 1
        }

        return 2
      }
      return 0

    case 3:
      if (invalidItemsAndBoxes >= 0) {
        return invalidItemsAndBoxes
      }

      return 4

    case 4:
      if (invalidItemsAndBoxes >= 0) {
        return invalidItemsAndBoxes
      }

      return 5
    default:
      if (order?.orderStatusId >= 4) {
        return 5
      }
      return 0
  }
}
