import React from 'react'

import Checkbox from 'react-simple-checkbox'
import styled from 'styled-components'

import {BorderColor} from '../../styles/_colors'

const CheckBoxSection = styled.div`
  display: inline-block;
  height: 24px;
`

const CheckboxInput = styled(Checkbox)`
  top: 0px;
  cursor: ${({disabled}) => disabled ? 'not-allowed' : 'pointer'};
  
  rect {
    stroke: ${BorderColor};
    transition-delay: 50ms !important;
    fill: ${({checked, disabled}) => disabled ? '#f5f5f5' : checked ? '#1890ff' : '#FFFFFF'};
  }

  &.unchecked .square {
    fill-opacity: 1;
  }

  &:hover{
    rect {
      stroke: ${({disabled}) => disabled ? BorderColor : '#1890ff'};
    }
  }
`

const SimpleCheckbox = ({input, disabled}) => {
  const handleChange = (value) => {
    if (!disabled) {
      input.onChange(value)
    }
  }
  return (
    <CheckBoxSection>
      <CheckboxInput
        disabled={disabled}
        color={{
          borderColor: '#1890ff',
          uncheckedBorderColor: BorderColor,
          tickColor: disabled ? (input.value ? '#b8b8b8' : '#f5f5f5') : '#FFFFFF'
        }}
        tickSize={3}
        size={2}
        borderThickness={1}
        checked={input.value}
        onChange={handleChange}
        backAnimationDuration={0}
        tickAnimationDuration={50}
      />
    </CheckBoxSection>
  )
}

export default SimpleCheckbox
