import React from 'react'

import {Popover} from 'antd'
import {Translate} from 'react-localize-redux'
import styled from 'styled-components'

import InfoIcon from '../../../../../assets/icons/info.svg'
import SelectInput from '../../../../../shared/components/inputs/SelectInput'
import {PrimaryButtonColor} from '../../../../../shared/styles/_colors'

const SelectContainer = styled.div`
  display: inline-block;
  width: 100%;
  position: relative;
`
const WarningPopup = styled.div`
  position: absolute;
  top: 1px;
  right: 1px;
  width: 22px;
  height: 22px;
  background: ${PrimaryButtonColor};
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ExclamationIcon = styled.img`
  display: inline-block;
  height: 11px;
`

const ServiceSupplierSelect = ({input, meta, data, service}) => {
  const showWarning = () => {
    if (input.value) {
      const found = data.find((s) => s.supplierId === input.value)
      if (found && found.supplierService) {
        const exist = found.supplierService.find(
          (s) => s.serviceId === service
        )
        return !exist
      }
    }
    return false
  }

  const showData = () => {
    const filtered = data.filter((s) => !!s.supplierService.find((ser) => ser.serviceId == service))
    if (filtered.length) return filtered
    return data
  }

  return (
    <SelectContainer>
      <SelectInput
        small
        input={input}
        meta={meta}
        data={showData()}
        dataKey='supplierId'
        dataLabel='name'
        allowClear={false}
      />
      {showWarning() && (
        <Popover
          placement='topRight'
          trigger='hover'
          content={
            <div>
              <div>
                <Translate id='SUPPLIER_SERVICE_NOT_REGISTERED' />
              </div>
              <div>
                <Translate id='SAVE_CREATE_CONECTION_SUPPLIER_SERVICE' />
              </div>
            </div>
          }
        >
          <WarningPopup>
            <ExclamationIcon src={InfoIcon} />
          </WarningPopup>
        </Popover>
      )}
    </SelectContainer>
  )
}

export default ServiceSupplierSelect
