import React, {useState} from 'react'

import {Col, Row} from 'antd'
import PropTypes from 'prop-types'
import {DndProvider} from 'react-dnd'
import {HTML5Backend} from 'react-dnd-html5-backend'
import {Translate} from 'react-localize-redux'
import {useParams} from 'react-router'

import {
  SaveProductImage,
  DeleteProductImage,
  ChangeCoverImage,
  ChangeFittingImage,
  ManageImageColors
} from '../../../../infra/requests/ProductsRequests'
import ProductUploadImageInput from '../../../../shared/components/inputs/ProductUploadImageInput'
import {ArrayPushIfNotExist} from '../../../../shared/logic/arrays/ArrayFunctions'
import {
  BodyH3,
  BodyH5,
  Margin,
  TitleH3
} from '../../../../shared/styles/BasicStyles'
import ColorImagesSection from './ImageCard/ColorImagesSection'
import ProductImageCard from './ImageCard/ProductImageCard'

const ProductImages = ({fields, colors, changeImages}) => {
  const [loading, setLoading] = useState(false)
  const {id} = useParams()
  const list = fields.getAll() || []
  const images = list.filter((img) => !img.layout)

  const addNewImage = async (file, color) => {
    setLoading(true)
    const payload = new FormData()
    payload.append('File', file)
    const {data, success} = await SaveProductImage(id, payload)
    if (success) {
      if (color) {
        const imagesList =
          data?.productPropertiesSection?.productImage || []
        if (imagesList.length) {
          const newImg = imagesList[imagesList.length - 1]
          const result = await ManageImageColors(id, newImg.imageId, [
            color
          ])
          if (result.success) {
            changeImages(
              result?.data?.productPropertiesSection?.productImage
            )
          }
        }
      } else {
        changeImages(data?.productPropertiesSection?.productImage)
      }
    }
    setLoading(false)
  }

  const deleteImage = async (image) => {
    const {data, success} = await DeleteProductImage(id, image)
    if (success) {
      changeImages(data?.productPropertiesSection?.productImage)
    }
    return true
  }

  const changeCover = async (image) => {
    const {data, success} = await ChangeCoverImage(id, image)
    if (success) {
      changeImages(data?.productPropertiesSection?.productImage)
    }
    return true
  }

  const changeFitting = async (image) => {
    const {data, success} = await ChangeFittingImage(id, image)
    if (success) {
      changeImages(data?.productPropertiesSection?.productImage)
    }
    return true
  }

  const handleColorRemove = async (imageId, colorId) => {
    const img = images.find((i) => i.imageId === imageId)
    const existing = (img?.productImageColor || []).map((c) => c.colorId)
    const result = await ManageImageColors(
      id,
      imageId,
      existing.filter((c) => c !== colorId)
    )
    if (result.success) {
      changeImages(result?.data?.productPropertiesSection?.productImage)
    }
  }

  const onImageDrop = async (image, color) => {
    const img = images.find((i) => i.imageId === image.imageId)
    const existing = (img?.productImageColor || []).map((c) => c.colorId)
    ArrayPushIfNotExist(existing, color.colorId)
    const result = await ManageImageColors(id, image.imageId, existing)
    if (result.success) {
      changeImages(result?.data?.productPropertiesSection?.productImage)
    }
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Row>
        <Col xs={24}>
          <TitleH3 $opacity='0.5'>
            <Translate id='PRODUCT_IMAGES' />
          </TitleH3>
        </Col>
        <Col xs={24}>
          <BodyH3 $opacity='0.5'>
            <Translate id='PROD_IMG_DESC1' />
          </BodyH3>
        </Col>
        <Col xs={24}>
          <BodyH5 $opacity='0.5'>
            <Translate id='PROD_IMG_DESC2' />
          </BodyH5>
        </Col>
      </Row>
      <Margin size={20} />
      <Row gutter={[20, 20]}>
        {images.length < 8 && (
          <Col xs={8} lg={4}>
            <ProductUploadImageInput
              loading={loading}
              input={{value: undefined, onChange: addNewImage}}
            />
          </Col>
        )}
        {images
          .filter((img) => img.productImageColor.length === 0)
          .map((img) => (
            <Col xs={8} lg={4} key={img.imageId}>
              <ProductImageCard
                deleteImage={deleteImage}
                changeCover={changeCover}
                changeFitting={changeFitting}
                currentImage={img}
                dropCard={() => null}
              />
            </Col>
          ))}
      </Row>
      <Margin size={20} />
      {colors.map((currentColor) => (
        <ColorImagesSection
          key={currentColor.colorId}
          color={currentColor}
          images={images}
          loading={loading}
          addNewImage={addNewImage}
          deleteImage={deleteImage}
          changeCover={changeCover}
          changeFitting={changeFitting}
          handleColorRemove={handleColorRemove}
          onImageDrop={onImageDrop}
        />
      ))}
      <Margin size={20} />
    </DndProvider>
  )
}

ProductImages.propTypes = {
  fields: PropTypes.object.isRequired,
  changeImages: PropTypes.func.isRequired,
  colors: PropTypes.array
}

ProductImages.defaultProps = {
  colors: []
}

export default ProductImages
