import LoginPage from '../../pages/auth/LoginPage'
import OktaRedirect from '../../pages/auth/OktaRedirect'
import RecoverPage from '../../pages/auth/RecoverPage'

export default [
  {
    path: '/login',
    component: LoginPage,
    isAuthenticated: false
  },
  {
    path: '/recover',
    component: RecoverPage,
    isAuthenticated: false
  },
  {
    path: '/authorization-code/callback',
    component: OktaRedirect,
    isAuthenticated: false
  }
  //   {
  //     path: '/recover-account',
  //     component: RecoverAccountPage,
  //     isAuthenticated: false
  //   },
  //   {
  //     path: '/recover-password',
  //     component: RecoverPasswordPage,
  //     isAuthenticated: false
  //   },
  //   {
  //     path: '/register',
  //     component: RegisterPage,
  //     isAuthenticated: false
  //   }
]
