import React from 'react'

import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'
import {connect} from 'react-redux'
import {reduxForm, getFormValues, Field} from 'redux-form'

import BackButton from '../../../shared/components/buttons/BackButton'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import DateInput from '../../../shared/components/inputs/DateInput'
import SelectInput from '../../../shared/components/inputs/SelectInput'
import BaseTable from '../../../shared/components/table/BaseTable'
import {
  Margin,
  PageForm,
  PageTitle,
  TitleH3
} from '../../../shared/styles/BasicStyles'

const Types = [
  {id: 'type1', name: 'Type 1 name'},
  {id: 'type2', name: 'Type 2 name'},
  {id: 'type3', name: 'Type 3 name'},
  {id: 'type4', name: 'Type 4 name'},
  {id: 'type5', name: 'Type 5 name'},
  {id: 'type6', name: 'Type 6 name'},
  {id: 'type7', name: 'Type 7 name'},
  {id: 'type8', name: 'Type 8 name'}
]

const StockPage = ({router}) => {
  const getColumns = () => [
    {
      title: <Translate id='BOX_KIT_QTY' />,
      type: 'text'
    },
    {
      title: <Translate id='QTY_LEFT' />,
      type: 'text'
    },
    {
      title: <Translate id='UM' />,
      type: 'text'
    },
    {
      title: <Translate id='RECEP_QTY' />,
      type: 'text'
    },
    {
      title: <Translate id='ORDER_QTY' />,
      type: 'text'
    },
    {
      title: <Translate id='SIZE' />,
      type: 'text'
    },
    {
      title: <Translate id='COLOR' />,
      type: 'text'
    },
    {
      title: <Translate id='DESCRIPTION' />,
      type: 'text'
    },
    {
      title: <Translate id='CLIENT_REF' />,
      type: 'text'
    },
    {
      title: <Translate id='REF_SKYPRO' />,
      type: 'text'
    },
    {
      title: <Translate id='REF_SUPPLIER' />,
      type: 'text'
    },
    {
      title: <Translate id='EAN13_CODE' />,
      type: 'text'
    }
  ]

  return (
    <>
      <PageForm>
        <Row gutter={[0, 50]}>
          <Col xs={24}>
            <BackButton
              label={<Translate id='BACK' />}
              onClick={() => router.history.push('/logistics#main')}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <PageTitle><Translate id='KIT_TO_STOCK' /></PageTitle>
          </Col>
          <Col xs={12} style={{textAlign: 'right'}}>
            <BaseButton type='primary' auto>
              <Translate id='SAVE' />
            </BaseButton>
          </Col>
        </Row>
        <Margin size={47} />
        <Row gutter={[40, 20]}>
          <Col xs={24} md={12} lg={4}>
            <Field
              component={SelectInput}
              name='warehouse'
              label={<Translate id='SELECT_WAREHOUSE' />}
              placeholder={<Translate id='SELECT' />}
              data={Types}
            />
          </Col>
          <Col xs={24} md={12} lg={4}>
            <Field
              component={SelectInput}
              name='customer'
              label={<Translate id='CUSTOMER' />}
              placeholder={<Translate id='SELECT' />}
              data={Types}
            />
          </Col>
          <Col xs={24} md={12} lg={4}>
            <Field
              component={DateInput}
              name='date'
              label={<Translate id='DATE' />}
              placeholder={<Translate id='ACTION_DATE' />}
            />
          </Col>
          <Col xs={24} md={12} lg={4}>
            <Field
              component={SelectInput}
              name='type'
              label={<Translate id='COUNTING_TYPE' />}
              placeholder={<Translate id='SELECT' />}
              data={Types}
            />
          </Col>
          <Col xs={24} md={12} lg={4}>
            <Field
              component={SelectInput}
              name='owner'
              label={<Translate id='COUNTING_OWNER' />}
              placeholder={<Translate id='SELECT' />}
              data={Types}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <TitleH3 $op><Translate id='SAVE' /></TitleH3>
          </Col>
        </Row>
        <Margin size={27} />
        <Row>
          <Col xs={4}>
            <BaseButton auto type='primary'>
              <Translate id='CREATE_BOX' />
            </BaseButton>
          </Col>
          <Col xs={4}>
            <BaseButton auto type='primary'>
              <Translate id='CREATE_KIT' />
            </BaseButton>
          </Col>
        </Row>
        <Margin size={17} />
        <Row>
          <Col xs={24}>
            <BaseTable
              rowKey='id'
              columns={getColumns()}
              pagination={{
                render: false
              }}
            />
          </Col>
        </Row>
        <Margin size={48} />
        <Row>
          <Col xs={24}>
            <TitleH3 $op><Translate id='KITS_AND_BOXES' /></TitleH3>
          </Col>
        </Row>
      </PageForm>
    </>
  )
}

StockPage.propTypes = {
  router: PropTypes.object.isRequired
}

StockPage.defaultProps = {

}

const myComponent = reduxForm({
  form: 'stock_form'
  // validate: validations
})(StockPage)

export default connect((state) => ({
  orderStatus: state.info.orderStatus,
  paymentMethods: state.info.paymentMethods,
  currentFormValues: getFormValues('stock_form')(state)
}))(myComponent)
