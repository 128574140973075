import React, { Component } from 'react'

import { Row, Col } from 'antd'
import PropTypes from 'prop-types'
import { Translate, withLocalize } from 'react-localize-redux'

import { GetB2BClients } from '../../infra/requests/ClientRequests'
import SearchInput from '../../shared/components/inputs/SearchInput'
import StatusColumn from '../../shared/components/Status/StatusColumn'
import BaseTable from '../../shared/components/table/BaseTable'
import { ContentContainer, Margin } from '../../shared/styles/BasicStyles'

class ClientsPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      rows: [],
      page: 1,
      pageSize: 12,
      total: 0,
      search: ''
    }
  }

  getColumns = () => [
    {
      title: <Translate id='CLIENT' />,
      type: 'text',
      dataIndex: 'name'
    },
    {
      title: <Translate id='CLIENT_STOCK' />,
      type: 'text',
      dataIndex: 'clientStock'
    },
    {
      title: <Translate id='CLIENT_STOCK_VALUE' />,
      type: 'text',
      dataIndex: 'clientStockValue',
      render: (value) => `${parseFloat(value).toFixed(2)}€`
    },
    {
      title: <Translate id='EMPLOYEES_NUMBER' />,
      type: 'text',
      dataIndex: 'employeeNumber'
    },
    {
      title: <Translate id='OPEN_ORDERS_VALUE' />,
      type: 'text',
      dataIndex: 'valueOfOpenOrders',
      render: (value) => `${parseFloat(value).toFixed(2)}€`
    },
    {
      title: <Translate id='MISSING_VALUES' />,
      type: 'text',
      dataIndex: 'missingValues',
      render: (value) => (value ? `${parseFloat(value).toFixed(2)}€` : '')
    },
    {
      title: <Translate id='STATUS' />,
      type: 'text',
      dataIndex: 'active',
      render: (value) => <StatusColumn value={value} />
    }
  ]

  componentDidMount = async () => {
    try {
      this.updateTable()
    } catch (error) {
      console.warn(error)
    }
  }

  updateTable = async () => {
    try {
      const { page, pageSize, search } = this.state

      this.setState({ loading: true })
      const { data } = await GetB2BClients(page, pageSize, search)

      this.setState({
        rows: data?.items,
        total: data?.totalItems,
        loading: false
      })
    } catch (error) {
      console.log(error)
    }
  }

  onChangePagination = (page) => this.setState({ page }, this.updateTable)

  render() {
    const { loading, rows, search, page, pageSize, total } = this.state
    const { router, translate } = this.props

    return (
      <ContentContainer>
        <Row>
          <Col xs={16} />
          <Col xs={8}>
            <SearchInput
              input={{
                value: search,
                onChange: (value) => { this.onChangePagination(1); this.setState({ search: value }) },
                onSearch: this.updateTable
              }}
              placeholder={translate('SEARCH')}
            />
          </Col>
        </Row>
        <Margin size={15} />
        <Row>
          <Col xs={24}>
            <BaseTable
              rowKey='b2bclientId'
              datasource={rows}
              columns={this.getColumns()}
              pagination={{
                render: true,
                page,
                pageSize,
                total,
                onChangePagination: this.onChangePagination
              }}
              onClickRow={(row) => {
                router.history.push(`/clients/${row.b2bclientId}`)
              }}
              loading={loading}
            />
          </Col>
        </Row>
      </ContentContainer>
    )
  }
}

ClientsPage.propTypes = {
  router: PropTypes.object.isRequired
}

export default withLocalize(ClientsPage)
