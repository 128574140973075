import React from 'react'

import moment from 'moment'
import {PropTypes} from 'prop-types'
import {Translate} from 'react-localize-redux'

import {
  InputBox,
  InputLabel,
  InputError,
  DateRangeInputStyled
} from './InputStyles'

const DateRangeInput = ({
  input,
  label,
  meta,
  placeholder,
  format,
  disabledDate,
  ranges,
  disabled,
  initialDateFormat,
  allowClear
}) => {
  const showError = meta.invalid && meta.submitFailed

  const getInitialValue = (values) => {
    if (initialDateFormat && values && values.length) {
      return values.map((currentDate) =>
        moment.isMoment(currentDate)
          ? currentDate
          : moment(currentDate, initialDateFormat)
      )
    }
    return values
  }

  return (
    <InputBox>
      {label && <InputLabel>{label}</InputLabel>}
      <DateRangeInputStyled
        allowClear={allowClear}
        showError={showError}
        placeholder={placeholder}
        value={disabled ? undefined : getInitialValue(input.value)}
        format={format}
        onChange={input.onChange}
        disabledDate={disabledDate}
        separator={<Translate id='TO' />}
        ranges={ranges}
        disabled={disabled}
      />
      {showError && (
        <InputError>
          {' '}
          <Translate id={meta.error} />
        </InputError>
      )}
    </InputBox>
  )
}

DateRangeInput.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string
  ]),
  placeholder: PropTypes.array,
  ranges: PropTypes.object,
  format: PropTypes.string,
  initialDateFormat: PropTypes.string,
  disabledDate: PropTypes.func,
  disabled: PropTypes.bool,
  allowClear: PropTypes.bool
}

DateRangeInput.defaultProps = {
  input: {value: undefined, onChange: () => null},
  label: undefined,
  placeholder: ['start', 'end'],
  meta: {},
  allowClear: true,
  format: 'DD/MM/YYYY',
  disabledDate: undefined,
  ranges: {
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [
      moment().subtract(1, 'month').startOf('month'),
      moment().subtract(1, 'month').endOf('month')
    ],
    'This Week': [moment().startOf('week'), moment().endOf('week')],
    'Last Week': [
      moment().subtract(1, 'week').startOf('week'),
      moment().subtract(1, 'week').endOf('week')
    ]
  },
  disabled: false,
  initialDateFormat: undefined
}

export default DateRangeInput

export const FutureRanges = {
  'This Month': [moment().startOf('month'), moment().endOf('month')],
  'Next Month': [
    moment().add(1, 'month').startOf('month'),
    moment().add(1, 'month').endOf('month')
  ],
  'This Year': [moment().startOf('year'), moment().endOf('year')],
  'Next Year': [
    moment().add(1, 'year').startOf('year'),
    moment().add(1, 'year').endOf('year')
  ]
}
