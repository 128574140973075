import React, {Component} from 'react'

import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import CancelBordered from '../../../assets/icons/cancel_border.svg'
import {GetRawMaterials} from '../../../infra/requests/ProductsRequests'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import BordelessButton from '../../../shared/components/buttons/BordelessButton'
import SearchInput from '../../../shared/components/inputs/SearchInput'
import SelectInput from '../../../shared/components/inputs/SelectInput'
import BaseList from '../../../shared/components/table/BaseList'
import GetCoverImage from '../../../shared/logic/arrays/ImageCoverFunction'
import {ConstructQuery} from '../../../shared/logic/queries/EndpointQueries'
import ActiveTranslation from '../../../shared/logic/translations/ActiveTranslation'
import {TableHeaderColor} from '../../../shared/styles/_colors'
import {SemiBoldFontWeight} from '../../../shared/styles/_texts'
import {Margin, StyledFlex, StyledHR} from '../../../shared/styles/BasicStyles'
import {
  ProductImage,
  ProductRefContainer,
  ProductTitle,
  ProductDescription,
  ColorsContainer,
  SingleColorContainer,
  ProductColor
} from '../../orders/manage_b2b_b2b2c/components/Styles'

let timeout

class RawMaterialsTabs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      rows: [],
      page: 1,
      pageSize: 24,
      total: 0,
      filters: {search: ''}
    }
  }

  componentDidMount = async () => {
    try {
      this.updateTable()
    } catch (error) {
      console.warn(error)
    }
  }

  updateTable = async () => {
    const {page, pageSize, filters} = this.state
    this.setState({loading: true})
    const {data} = await GetRawMaterials(
      page,
      pageSize,
      ConstructQuery(filters)
    )
    this.setState({
      rows: data?.items,
      total: data?.totalItems,
      loading: false
    })
  }

  onChangePagination = (page) => this.setState({page}, this.updateTable)

  clearSearch = async () => {
    this.setState({filters: {search: ''}}, this.updateTable)
  }

  renderItem = (product) => (
    <>
      <Margin size={25} />
      <ProductRefContainer>
        <ProductTitle>
          <Translate id='REF' />
          :&ensp;
        </ProductTitle>
        <ProductDescription
          $weight={SemiBoldFontWeight}
          $color={TableHeaderColor}
        >
          {product?.referenceCode}
        </ProductDescription>
      </ProductRefContainer>
      <ProductDescription>
        <ActiveTranslation
          value={product?.productTranslation}
          tag='name'
        />
      </ProductDescription>
      <Margin size={10} />
      <ProductImage src={GetCoverImage(product?.productImage)} />
      <Margin size={10} />
      <ProductTitle>
        <Translate id='FAMILY' />
      </ProductTitle>
      <ProductDescription>
        {product?.rawMaterialFamily?.rawMaterialFamilyTranslation ?
          <ActiveTranslation value={product?.rawMaterialFamily?.rawMaterialFamilyTranslation} /> :
          <Translate id='NA' />}
      </ProductDescription>
      <Margin size={10} />
      <ProductTitle>
        <Translate id='SUPPLIER' />
      </ProductTitle>
      <ProductDescription>
        {product?.productSupplier[0]?.s?.supplier?.name || (
          <Translate id='NA' />
        )}
      </ProductDescription>
      <Margin size={10} />
      <ProductTitle>
        <Translate id='REF_SUPPLIER' />
      </ProductTitle>
      <ProductDescription>
        {product?.productSupplier[0]?.referenceSupplier || (
          <Translate id='NA' />
        )}
      </ProductDescription>
      <ColorsContainer>
        <Translate id='COLORS' />
        <StyledFlex $justify='center' $align='center' $direction='row'>
          {(product?.productCombination || []).map(
            (combination, index) => (
              <SingleColorContainer
                key={index}
                $marginLeft={
                  index < product.productCombination.length - 1
                    ? '9px'
                    : false
                }
              >
                <ProductColor
                  $file={
                    combination?.color?.file
                      ? URL.createObjectURL(combination?.color?.file)
                      : undefined
                  }
                  $color={combination?.color?.html}
                />
              </SingleColorContainer>
            )
          )}
        </StyledFlex>
      </ColorsContainer>
    </>
  )

  setFilters = (values, time) => {
    this.setState({filters: values, page: 1}, () => {
      if (timeout) clearTimeout(timeout)
      timeout = setTimeout(this.updateTable, time)
    })
  }

  render() {
    const {loading, rows, page, pageSize, total, filters} = this.state
    const {router, rawMaterialFamily, suppliersRawMaterial, productColors, translate} =
      this.props
    return (
      <>
        {/* <Row xs={24}>
          <Col xs={24} style={{textAlign: 'right'}}>
            <BaseButton
              type='secondary'
              onClick={() =>
                console.warn(<Translate id='EXPORT_TO_EXCEL' />)
              }
              auto
            >
              <Translate id='EXPORT_VIEW_TO_EXCEL' />
            </BaseButton>
          </Col>
        </Row>
        <Margin size={29} />
        <StyledHR $space='20' /> */}
        <Row align='bottom' gutter={[20, 20]}>
          <Col xs={4}>
            <SelectInput
              label={<Translate id='FAMILY' />}
              input={{
                value: filters.rawMaterialFamilyId,
                onChange: (value) => this.setFilters({...filters, rawMaterialFamilyId: value}, 0)
              }}
              dataKey='rawMaterialFamilyId'
              dataLabel='rawMaterialFamilyTranslation'
              placeholder={<Translate id='TYPE' />}
              data={rawMaterialFamily}
            />
          </Col>
          <Col xs={4}>
            <SelectInput
              label={<Translate id='COLOR' />}
              input={{
                value: filters.rawMaterialColorId,
                onChange: (value) => this.setFilters({...filters, rawMaterialColorId: value}, 0)
              }}
              placeholder={<Translate id='SELECT' />}
              data={productColors}
              dataKey='colorId'
              dataLabel='colorTranslation'
            />
          </Col>
          <Col xs={4}>
            <SelectInput
              label={<Translate id='SUPPLIER' />}
              input={{
                value: filters.supplierId,
                onChange: (value) => this.setFilters({...filters, supplierId: value}, 0)
              }}
              dataKey='supplierId'
              dataLabel='name'
              placeholder='Supplier'
              data={suppliersRawMaterial}
            />
          </Col>
          <Col xs={8} style={{textAlign: 'right'}}>
            <SearchInput
              auto
              input={{
                value: filters.search,
                onChange: (value) => this.setFilters({...filters, search: value}, 1000),
                onSearch: this.updateTable
              }}
              placeholder={translate('SEARCH')}
            />
          </Col>

          <Col xs={4} style={{textAlign: 'right'}}>
            <BordelessButton
              auto
              onClick={() => this.clearSearch()}
              imagePath={CancelBordered}
            >
              <Translate id='CLEAR_SEARCH' />
            </BordelessButton>
          </Col>
        </Row>
        <StyledHR $space='20' />
        <Row>
          <Col xs={24}>
            <BaseList
              grid={{
                gutter: 20,
                xs: 1,
                sm: 2,
                md: 4,
                lg: 4,
                xl: 6,
                xxl: 6
              }}
              datasource={rows}
              renderItem={this.renderItem}
              pagination={{
                render: true,
                page,
                pageSize,
                total,
                onChangePagination: this.onChangePagination
              }}
              onClickRow={(item) =>
                router.history.push(`/materials/${item.productId}`)
              }
              loading={loading}
            />
          </Col>
        </Row>
      </>
    )
  }
}

RawMaterialsTabs.propTypes = {
  router: PropTypes.object.isRequired,
  rawMaterialFamily: PropTypes.array.isRequired,
  suppliersRawMaterial: PropTypes.array.isRequired
}

const mapStateToProps = (state) => ({
  rawMaterialFamily: state.info.rawMaterialFamily,
  suppliersRawMaterial: state.info.suppliersRawMaterial,
  productColors: state.info.productColors
})

export default withLocalize(connect(mapStateToProps)(RawMaterialsTabs))
