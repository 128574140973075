import React, { useState, useEffect } from 'react'

import { Row, Col, Select } from 'antd'
import PropTypes from 'prop-types'
import { Translate, withLocalize } from 'react-localize-redux'
import { useLocation } from 'react-router-dom'
import SelectInput from '../../../shared/components/inputs/SelectInput'

import BaseButton from '../../../shared/components/buttons/BaseButton'
import DateInput from '../../../shared/components/inputs/DateInput'
import SearchInput from '../../../shared/components/inputs/SearchInput'
import TextInput from '../../../shared/components/inputs/TextInput'
import BaseModal from '../../../shared/components/modal/BaseModal'
import { Margin } from '../../../shared/styles/BasicStyles'
import { AdvancedSearchDescription } from '../OrdersStyles'
import { GetOrderStatus } from '../../../infra/requests/BaseRequests'
import { GetB2BClientsDrop } from '../../../infra/requests/BaseRequests'
import AlertService from '../../../shared/components/alert/AlertService'



const EmptyState = {
  search: '',
  dateFrom: '',
  dateTo: '',
  qtyFrom: '',
  qtyTo: '',
  totalFrom: '',
  totalTo: '',
  orderStatusId: '',
}

const OrderSearch = ({ setFilters, translate }) => {
  const [openModal, setModal] = useState(false)
  const [values, setValues] = useState(EmptyState)
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const urlParam = queryParams.get('search')
  const [statusItems, setStatusItems] = useState([])
  const [clients, setClients] = useState([])

  const formatCustomCurrency = (value) => {

    const commaPosition = value.indexOf(",")
    const dotPosition = value.indexOf(".")

    // Remove non-digit characters except periods, commas, and semicolons
    let cleanedValue = value.replace(/[^\d,.;]/g, '');

    // Remove semicolons
    cleanedValue = cleanedValue.replace(/;/g, '');

    // Scenario: 120,020.23000
    if (cleanedValue.includes(',') && cleanedValue.includes('.') && commaPosition < dotPosition) {

      cleanedValue = cleanedValue.replace(/,/g, '');
    }
    // Scenario: 120.020,23000
    else if (cleanedValue.includes('.') && cleanedValue.includes(',') && commaPosition > dotPosition) {

      cleanedValue = cleanedValue.replace(/\./g, '').replace(/,/g, '.');


    }
    // Scenario: 12,3333
    else if (cleanedValue.includes(',')) {
      cleanedValue = cleanedValue.replace(/,/g, '.');
    }

    return cleanedValue;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [orderStatus, clientsB2B] = await Promise.all([GetOrderStatus(), GetB2BClientsDrop()])
        setStatusItems(orderStatus.data.items)
        setClients(clientsB2B.data.items)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    };

    fetchData();
  }, []);


  const onSubmitSearch = (key, value, timeout = 0) => {
    const result = { ...values };
    result[key] = value;
    setValues(result);
    setFilters(result, timeout);
  }

  const onChangeField = (key, value) => {
    const result = { ...values };
    result[key] = value;
    setValues(result);
  }


  const handleFilter = (inputs) => {

    const validatedInputs = {
      ...inputs, // Include the already formatted totals
      qtyFrom: inputs.qtyFrom,
      qtyTo: inputs.qtyTo,
    };

    if (validatedInputs.qtyFrom !== '' && validatedInputs.qtyTo !== '') {
      const quantityFrom = parseFloat(validatedInputs.qtyFrom);
      const quantityTo = parseFloat(validatedInputs.qtyTo);

      if (quantityFrom > quantityTo) {
        AlertService.error(
          "Invalid Quantity",
          'Minimum quantity cannot be greater than the maximum quantity.'
        );
        // Reset qtyFrom and qtyTo in validatedInputs
        validatedInputs.qtyFrom = '';
        validatedInputs.qtyTo = '';
        return; // Exit early to prevent filtering
      }
    }

    // Format and validate totalFrom and totalTo
    const formattedInputs = {
      ...validatedInputs,
      totalFrom: formatCustomCurrency(inputs.totalFrom),
      totalTo: formatCustomCurrency(inputs.totalTo),
    };

    // Validate totalFrom and totalTo
    if (formattedInputs.totalFrom !== '' && formattedInputs.totalTo !== '') {
      const totalFrom = parseFloat(formattedInputs.totalFrom);
      const totalTo = parseFloat(formattedInputs.totalTo);

      if (totalFrom > totalTo) {
        AlertService.error(
          "Invalid Order values",
          'Minimum order value cannot be greater than the maximum order value.'
        );
        // Reset totalFrom and totalTo in formattedInputs
        formattedInputs.totalFrom = '';
        formattedInputs.totalTo = '';
        return; // Exit early to prevent filtering
      }
    }

    // Update filters with validated and formatted inputs
    setFilters(formattedInputs);
    setModal(false);
  };

  useEffect(() => {
    if (urlParam) {
      onSubmitSearch('search', urlParam, 1000)
    }
  }, [urlParam])

  return (
    <>
      <Col xs={24} md={10} lg={{ span: 8, order: 1 }} style={{ textAlign: 'right' }}>
        <SearchInput
          input={{
            value: values.search,
            onChange: (value) => onSubmitSearch('search', value, 1000),
            onSearch: () => onSubmitSearch('', '')
          }}
          placeholder={translate('SEARCH')}
        />
      </Col>
      <Col xs={11} md={6} lg={{ span: 5, order: 2 }} style={{ textAlign: 'right' }}>
        <BaseButton
          onClick={() => setModal(true)}
          type='secondary'
          align='right'
          auto
        >
          <Translate id='ADVANCED_SEARCH' />
        </BaseButton>
      </Col>
      <BaseModal
        okText={<Translate id='SEARCH' />}
        onOk={() => handleFilter(values)}
        cancelText={<Translate id='CLEAR_SEARCH' />}
        onCancel={() => {
          setValues(EmptyState)
          handleFilter(EmptyState)
        }}
        onClose={() => setModal(false)}
        title={<Translate id='ADVANCED_SEARCH' />}
        open={openModal}
      >
        <Row align='bottom' justify='center' gutter={[20, 10]}>
          <Col xs={24} md={11}>
            <DateInput
              input={{
                value: values.dateFrom,
                onChange: (value) => onChangeField('dateFrom', value)
              }}
              label={<Translate id='PERIOD' />}
            />
          </Col>
          <Col xs={24} md={2}>
            <AdvancedSearchDescription>
              <Translate id='TO' />
            </AdvancedSearchDescription>
          </Col>
          <Col xs={24} md={11}>
            <DateInput
              input={{
                value: values.dateTo,
                onChange: (value) => onChangeField('dateTo', value)
              }}
              label=''
            />
          </Col>
        </Row>
        <Margin size={20} />
        <Row align='bottom' justify='center' gutter={[20, 10]}>
          <Col xs={24} md={11}>
            <TextInput
              input={{
                value: values.qtyFrom,
                onChange: (value) => onChangeField('qtyFrom', value)
              }}
              label={<Translate id='QUANTITY' />}
              placeholder={translate('SET_MINIMUM_LIMIT_QTY')}
            />
          </Col>
          <Col xs={24} md={2}>
            <AdvancedSearchDescription>
              <Translate id='TO' />
            </AdvancedSearchDescription>
          </Col>
          <Col xs={24} md={11}>
            <TextInput
              input={{
                value: values.qtyTo,
                onChange: (value) => onChangeField('qtyTo', value)
              }}
              label=''
              placeholder={translate('SET_MAXIMUM_LIMIT_QTY')}
            />
          </Col>
        </Row>
        <Margin size={20} />
        <Row align='bottom' justify='center' gutter={[20, 10]}>
          <Col xs={24} md={11}>
            <TextInput
              input={{
                value: values.totalFrom,
                onChange: (value) => onChangeField('totalFrom', value)
              }}
              label={<Translate id='TOTAL_VALUE' />}
              placeholder={translate('SET_MINIMUM_ORDER_TOTAL_VALUE')}
            />
          </Col>
          <Col xs={24} md={2}>
            <AdvancedSearchDescription>
              <Translate id='TO' />
            </AdvancedSearchDescription>
          </Col>
          <Col xs={24} md={11}>
            <TextInput
              input={{
                value: values.totalTo,
                onChange: (value) => onChangeField('totalTo', value)
              }}
              label=''
              placeholder={translate('SET_MAXIMUM_ORDER_TOTAL_VALUE')}
            />
          </Col>
        </Row>
        <Margin size={20} />
        <Row align='bottom' justify='center' gutter={[20, 10]}>
          <Col xs={24} md={11}>
            <SelectInput
              input={{ onChange: (value) => onChangeField('B2BClientID', value) }}
              label={<Translate id='COMPANY' />}
              data={clients}
              placeholder={<Translate id='CHOOSE_COMPANY' />}
              dataLabel='name'
              dataKey='b2bclientId'
            />
          </Col>
          <Col xs={2} md={2} lg={2} />
          <Col xs={24} md={11}>
            <SelectInput
              input={{ onChange: (value) => onChangeField('orderStatusId', value) }}
              label={<Translate id='STATUS' />}
              data={statusItems}
              placeholder={<Translate id='CHOOSE_STATUS' />}
              dataLabel='dataStep'
              dataKey='orderStatusId'
            />
          </Col>
          <Col xs={24} md={2}>

          </Col>
        </Row>
      </BaseModal>
    </>
  )
}

OrderSearch.propTypes = {
  setFilters: PropTypes.func.isRequired
}
export default withLocalize(OrderSearch)
