export default (order) => {
  let isMissing = false
  order.orderItem.forEach(((orderItem) => {
    let quantityForItem = 0
    order.orderBox.forEach((box) => {
      box.items.forEach((boxItem) => {
        if (boxItem.orderItemId === orderItem.orderItemId) {
          quantityForItem += boxItem.itemQuantity
        }
      })
    })
    if (quantityForItem < orderItem.quantityConfirmed) {
      isMissing = true
    }
  }))
  return isMissing
}
