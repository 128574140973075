import styled from 'styled-components'

import {
  BorderColor,
  TableTextColor,
  PrimaryButtonColor
} from '../../styles/_colors'
import {
  ButtonText,
  SemiBoldFontWeight,
  H2Text,
  BoldFontWeight
} from '../../styles/_texts'

export const HighlightContainer = styled.div`
  border: 1px solid ${BorderColor};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 60px;
  border-radius: 15px;
`

export const HighlightTitle = styled.h5`
  color: ${TableTextColor};
  font-size: 15px;
  font-weight: ${SemiBoldFontWeight};
  margin: 0;

  
  @media (max-width: 768px) {
    font-size: 13px; 
  }

  @media (max-width: 480px) {
    font-size: 12px; 
  }
`

export const HighlightText = styled.h1`
  color: ${PrimaryButtonColor};
  font-size: 25px;
  font-weight: ${BoldFontWeight};
  margin: 0 0 0 20px;

  @media (max-width: 768px) {
    font-size: 22px; 
  }
`
