import React, {useState} from 'react'

import {Col, Row} from 'antd'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {Field, change} from 'redux-form'

import SelectInput from '../../shared/components/inputs/SelectInput'
import SwitchInput from '../../shared/components/inputs/SwitchInput'
import TextInput from '../../shared/components/inputs/TextInput'
import {Margin} from '../../shared/styles/BasicStyles'

const Information = ({profiles, translate, id, dispatch}) => {
  const [showPassword, setShowPassword] = useState(!id || id === 'new')

  const handleResetPassword = (value) => {
    setShowPassword(value)
    if (!value) {
      dispatch(change('user_form', 'password', ''))
      dispatch(change('user_form', 'confirm_password', ''))
    }
  }

  return (
    <>
      <Row gutter={[40, 20]}>
        <Col xs={6}>
          <Field
            component={TextInput}
            name='fullName'
            label={<Translate id='NAME' />}
            placeholder={translate('NAME')}
          />
        </Col>
        <Col xs={6}>
          <Field
            component={TextInput}
            name='userName'
            label={<Translate id='USERNAME' />}
            placeholder={translate('USERNAME')}
            disabled={id && id !== 'new'}
          />
        </Col>
        <Col xs={6}>
          <Field
            component={SelectInput}
            name='profile.profileId'
            label={<Translate id='PROFILE' />}
            placeholder={<Translate id='SELECT' />}
            data={profiles}
            allowClear={false}
            dataKey='profileId'
            dataLabel='name'
          />
        </Col>
        <Col xs={3}>
          <Field
            component={SwitchInput}
            label={<Translate id='APPROVED' />}
            name='aspnetMembership.isApproved'
            checkedText={<Translate id='YES' />}
            uncheckedText={<Translate id='NO' />}
          />
        </Col>
        <Col xs={3}>
          <Field
            component={SwitchInput}
            label={<Translate id='BLOCKED' />}
            name='aspnetMembership.isLockedOut'
            checkedText={<Translate id='YES' />}
            uncheckedText={<Translate id='NO' />}
          />
        </Col>
      </Row>
      <Margin size={20} />
      <Row gutter={[40, 20]}>
        {id && id !== 'new' && (
          <Col xs={4}>
            <SwitchInput
              input={{value: showPassword, onChange: handleResetPassword}}
              checkedText={<Translate id='YES' />}
              uncheckedText={<Translate id='NO' />}
              label={<Translate id='NEW_PASSWORD' />}
            />
          </Col>
        )}
        {showPassword && (
          <Col xs={6}>
            <Field
              component={TextInput}
              type='password'
              name='password'
              label={<Translate id='PASSWORD' />}
              placeholder={translate('PASSWORD')}
            />
          </Col>
        )}
        {showPassword && (
          <Col xs={6}>
            <Field
              component={TextInput}
              type='password'
              name='confirm_password'
              label={<Translate id='CONFIRM_PASSWORD' />}
              placeholder={translate('CONFIRM_PASSWORD')}
            />
          </Col>
        )}
      </Row>
    </>
  )
}

Information.propTypes = {
  profiles: PropTypes.array.isRequired
}

Information.defaultProps = {}

export default withLocalize(connect()(Information))
