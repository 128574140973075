import styled from 'styled-components'

import { BorderColor, PrimaryColor, TableTextColor } from '../../../../shared/styles/_colors'
import { BodyText, BookFontWeight, LabelText, SemiBoldFontWeight } from '../../../../shared/styles/_texts'

export const DeliveryContainer = styled.div`
  padding: 10px 0px;
  border-bottom: 1px solid ${BorderColor};
  margin-bottom: -1px;
`

export const HeaderTitle = styled.div`
  color: ${TableTextColor};
  font-size: ${LabelText};
  font-weight: ${SemiBoldFontWeight};
  line-height: 18px;
`
export const HeaderValue = styled.div`
  color: ${({ $blue }) => ($blue ? PrimaryColor : TableTextColor)};
  font-size: ${BodyText};
  font-weight: ${BookFontWeight};
  line-height: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
`

export const TotalQuantityContainer = styled.div`
width: 100%;
display: flex;
justify-content: flex-end;
align-items: center;
padding-right: 10px;
padding-top: 6px;
gap: 20px;
`
