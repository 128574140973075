import EmptyImageIcon from '../../../assets/placeholder.jpg'

const GetImage = (obj) => {
  if (obj && obj?.image?.url) {
    return obj.image.url
  }
  if (obj && !obj?.image?.url) {
    return `${window.env.REACT_APP_API_FILES}/products/${obj?.image?.filename}`
  }
  return EmptyImageIcon
}

export default GetImage
