import React from 'react'

import {PropTypes} from 'prop-types'

import {TableTextColor} from '../../styles/_colors'
import {BodyText} from '../../styles/_texts'
import {BodyH3} from '../../styles/BasicStyles'
import {
  InputBox,
  InputLabel,
  ProductPriceInputBox,
  ProductPriceInputStyled
} from './InputStyles'

const IntegerInput = ({
  input,
  label,
  meta,
  type,
  placeholder,
  afterChange,
  min,
  max,
  step,
  disabled,
  additionalText,
  inputDescriptionText
}) => {
  const handleChange = (event) => {
    input.onChange(event.target.value)
    if (typeof afterChange === 'function') {
      afterChange(event.target.value)
    }
  }

  const handleFormat = (event) => {
    if (event.target.value) {
      let value = event.target.value.replace('e', '')
      value = parseInt(value, 10)
      if (value < 0) value *= -1
      input.onChange(value)
      if (typeof afterChange === 'function') {
        afterChange(value)
      }
    }
  }

  const hasError = meta.invalid && meta.submitFailed

  return (
    <InputBox>
      {label && <InputLabel>{label}</InputLabel>}
      <ProductPriceInputBox disabled={disabled} $hasError={hasError}>
        <ProductPriceInputStyled
          type={type}
          placeholder={placeholder}
          value={input.value}
          onChange={handleChange}
          min={min}
          max={max}
          step={step}
          disabled={disabled}
          onBlur={handleFormat}
          onKeyPress={(e) => e.key === 'e' && e.preventDefault()}
        />
        {inputDescriptionText && (
          <BodyH3 $color={disabled ? 'white' : TableTextColor}>
            {inputDescriptionText}
          </BodyH3>
        )}
      </ProductPriceInputBox>
      {additionalText && (
        <BodyH3 $size={BodyText}>{additionalText}</BodyH3>
      )}
    </InputBox>
  )
}

IntegerInput.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  placeholder: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  type: PropTypes.string,
  afterChange: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  disabled: PropTypes.bool,
  additionalText: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  inputDescriptionText: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ])
}

IntegerInput.defaultProps = {
  input: {value: undefined, onChange: () => null},
  label: undefined,
  placeholder: undefined,
  meta: {},
  type: 'number',
  afterChange: undefined,
  min: 0,
  max: undefined,
  step: 1,
  disabled: false,
  additionalText: undefined,
  inputDescriptionText: undefined
}

export default IntegerInput
