import AuthRoutes from './data/AuthRoutes'
import BaseRoutes from './data/BaseRoutes'
import ClientRoutes from './data/ClientRoutes'
import DeparturesRoutes from './data/DeparturesRoutes'
import FittingRoutes from './data/FittingRoutes'
import LogisticsRoutes from './data/LogisticsRoutes'
import MessagingRoutes from './data/MessagingRoutes'
import OrderRoutes from './data/OrderRoutes'
import ProductsRoutes from './data/ProductsRoutes'
import SettingsRoutes from './data/SettingsRoutes'
import SuppliersRoutes from './data/SuppliersRoutes'

export default [
  ...AuthRoutes,
  ...BaseRoutes,
  ...OrderRoutes,
  ...SuppliersRoutes,
  ...MessagingRoutes,
  ...ProductsRoutes,
  ...SettingsRoutes,
  ...LogisticsRoutes,
  ...DeparturesRoutes,
  ...FittingRoutes,
  ...ClientRoutes
]
