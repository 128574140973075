import React, {useState} from 'react'

import {Checkbox} from 'antd'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {Translate} from 'react-localize-redux'

const Container = styled.div`
  display: inline-block;
  width: 100%;
`

const AllCheckbox = styled(Checkbox)`
  margin-left: 8px;
`

const CheckboxSizeInput = ({input, sizes}) => {
  const [all, setAll] = useState(input.value.length === sizes.length)
  const selected = Array.isArray(input.value) ? input.value : []

  const handleAllChange = (e) => {
    if (e.target.checked) {
      setAll(true)
      input.onChange(sizes.map((s) => s.sizeId))
    } else {
      setAll(false)
      input.onChange([])
    }
  }

  const handleInputChange = (e, sizeId) => {
    const copy = [...selected]
    if (e.target.checked) {
      copy.push(sizeId)
      input.onChange(copy)
    } else {
      input.onChange(copy.filter((x) => x !== sizeId))
    }
  }

  return (
    <Container>
      <AllCheckbox checked={all} onChange={handleAllChange}>
        <Translate id='ALL' />
      </AllCheckbox>
      {sizes.map((size) => (
        <Checkbox
          key={size.sizeId}
          disabled={all}
          checked={!!selected.find((s) => s === size.sizeId)}
          onChange={(e) => handleInputChange(e, size.sizeId)}
        >
          {size.nameEu}
        </Checkbox>
      ))}
    </Container>
  )
}

CheckboxSizeInput.propTypes = {
  input: PropTypes.object.isRequired,
  sizes: PropTypes.array.isRequired
}

export default CheckboxSizeInput
