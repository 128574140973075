import ClientsPage from '../../pages/client/ClientsPage'
import ManageEmployeePage from '../../pages/client/employees/ManageEmployeePage'
import ManageClientPage from '../../pages/client/ManageClientPage'
import ManageWorkgroupPage from '../../pages/client/workgroups/ManageWorkgroupPage'
import ManageProfilePage from '../../pages/profiles/ManageProfilePage'
import ManageUserPage from '../../pages/users/ManageUserPage'

export default [
  {
    path: '/clients',
    component: ClientsPage,
    isAuthenticated: true,
    shouldRenderHeader: true,
    shouldRenderFooter: true,
    role: 1
  },
  {
    path: '/clients/:id',
    component: ManageClientPage,
    isAuthenticated: true,
    shouldRenderHeader: true,
    shouldRenderFooter: true,
    role: 1
  },
  {
    path: '/clients/:client/workgroup/new',
    component: ManageWorkgroupPage,
    isAuthenticated: true,
    shouldRenderHeader: true,
    shouldRenderFooter: true,
    role: 1
  },
  {
    path: '/clients/:client/workgroup/:id',
    component: ManageWorkgroupPage,
    isAuthenticated: true,
    shouldRenderHeader: true,
    shouldRenderFooter: true,
    role: 1
  },
  {
    path: '/clients/:client/employee/new',
    component: ManageEmployeePage,
    isAuthenticated: true,
    shouldRenderHeader: true,
    shouldRenderFooter: true,
    role: 1
  },
  {
    path: '/clients/:client/employee/:id',
    component: ManageEmployeePage,
    isAuthenticated: true,
    shouldRenderHeader: true,
    shouldRenderFooter: true,
    role: 1
  },
  {
    path: '/clients/:client/user/new',
    component: ManageUserPage,
    isAuthenticated: true,
    shouldRenderHeader: true,
    shouldRenderFooter: true,
    role: 1
  },
  {
    path: '/clients/:client/user/:id',
    component: ManageUserPage,
    isAuthenticated: true,
    shouldRenderHeader: true,
    shouldRenderFooter: true,
    role: 1
  },
  {
    path: '/clients/:client/profile/new',
    component: ManageProfilePage,
    isAuthenticated: true,
    shouldRenderHeader: true,
    shouldRenderFooter: true,
    role: 1
  },
  {
    path: '/clients/:client/profile/:id',
    component: ManageProfilePage,
    isAuthenticated: true,
    shouldRenderHeader: true,
    shouldRenderFooter: true,
    role: 1
  }
]
