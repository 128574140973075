import React from 'react'

import { PropTypes } from 'prop-types'

import {
  CustomDotContainer,
  StyledSteps,
  StyledStep
} from './BaseStepsStyles'

const BaseSteps = ({ steps, currentStep, changeStep, max, min }) => {
  const customDot = (dot, { status, index }) => (
    <CustomDotContainer
      type='button'
      $active={status === 'process' || index < currentStep}
      onClick={() => changeStep(index)}
      disabled={index > max || index < min}
    >
      {index + 1}
    </CustomDotContainer>
  )

  return (
    <StyledSteps current={currentStep} progressDot={customDot}>
      {steps.map((title, index) => (
        <StyledStep key={index} title={title} />
      ))}
    </StyledSteps>
  )
}

BaseSteps.propTypes = {
  steps: PropTypes.array,
  currentStep: PropTypes.number,
  max: PropTypes.number,
  min: PropTypes.number,
  changeStep: PropTypes.func
}

BaseSteps.defaultProps = {
  steps: [],
  currentStep: 0,
  changeStep: undefined,
  max: 0,
  min: 0
}

export default BaseSteps
