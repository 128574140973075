import React, {useState, useEffect} from 'react'

import {Col, Row} from 'antd'
import {Translate} from 'react-localize-redux'
import {connect} from 'react-redux'
import {change} from 'redux-form'

import SimpleCheckbox from '../inputs/SimpleCheckbox'
import BaseTable from '../table/BaseTable'
import PermissionFilters from './PermissionFilters'

const addIndexes = (list) => list.map((elem, originalIndex) => ({...elem, originalIndex}))

const PermissionsTable = ({fields, dispatch, disabled}) => {
  const [original, SetOriginal] = useState([])
  const [showList, setShowList] = useState([])

  useEffect(() => {
    async function init() {
      const list = addIndexes(fields.getAll() || [])
      SetOriginal(list)
      setShowList(list)
    }
    init()
  }, [])

  const handleChange = (data, index, position, value) => {
    const show = JSON.parse(JSON.stringify(showList))
    show[index].permissions[position] = value
    setShowList(show)
    const list = JSON.parse(JSON.stringify(original))
    list[data.originalIndex].permissions[position] = value
    SetOriginal(list)
    dispatch(change('profile_form', fields.name, list))
  }

  const columns = [
    {
      dataIndex: 'functionalityName',
      type: 'text',
      title: <Translate id='FUNCTIONALITY' />
    },
    {
      title: <Translate id='SEE_ALL' />,
      render: (data, row, index) => (
        <SimpleCheckbox
          disabled={disabled || data.permissions[0] === null}
          input={{
            value: !!data.permissions[0],
            onChange: (v) => handleChange(data, index, 0, v)}}
        />
      )
    },
    {
      title: <Translate id='SEE_SELF' />,
      render: (data, row, index) => (
        <SimpleCheckbox
          disabled={disabled || data.permissions[1] === null}
          input={{
            value: !!data.permissions[1],
            onChange: (v) => handleChange(data, index, 1, v)}}
        />
      )
    },
    {
      title: <Translate id='EDIT_ALL' />,
      render: (data, row, index) => (
        <SimpleCheckbox
          disabled={disabled || data.permissions[2] === null}
          input={{
            value: !!data.permissions[2],
            onChange: (v) => handleChange(data, index, 2, v)}}
        />
      )
    },
    {
      title: <Translate id='EDIT_SELF' />,
      render: (data, row, index) => (
        <SimpleCheckbox
          disabled={disabled || data.permissions[3] === null}
          input={{
            value: !!data.permissions[3],
            onChange: (v) => handleChange(data, index, 3, v)}}
        />
      )
    },
    {
      title: <Translate id='CREATE_ALL' />,
      render: (data, row, index) => (
        <SimpleCheckbox
          disabled={disabled || data.permissions[4] === null}
          input={{
            value: !!data.permissions[4],
            onChange: (v) => handleChange(data, index, 4, v)}}
        />
      )
    },
    {
      title: <Translate id='CREATE_SELF' />,
      render: (data, row, index) => (
        <SimpleCheckbox
          disabled={disabled || data.permissions[5] === null}
          input={{
            value: !!data.permissions[5],
            onChange: (v) => handleChange(data, index, 5, v)}}
        />
      )
    },
    {
      title: <Translate id='DELETE_ALL' />,
      render: (data, row, index) => (
        <SimpleCheckbox
          disabled={disabled || data.permissions[6] === null}
          input={{
            value: !!data.permissions[6],
            onChange: (v) => handleChange(data, index, 6, v)}}
        />
      )
    },
    {
      title: <Translate id='DELETE_SELF' />,
      render: (data, row, index) => (
        <SimpleCheckbox
          disabled={disabled || data.permissions[7] === null}
          input={{
            value: !!data.permissions[7],
            onChange: (v) => handleChange(data, index, 7, v)}}
        />
      )
    }
  ]

  const handleSearch = (filters) => {
    const filtered = original.filter((elem) => {
      let valid = true
      if (filters.search !== '') {
        valid = elem.functionalityName.toLowerCase().indexOf(filters.search.toLowerCase()) > -1
      }
      if (valid && filters.used) {
        valid = !!elem.permissions.find((v) => v == true)
      }
      if (valid && filters.see) {
        const [a, b] = elem.permissions
        valid = a || b
      }
      if (valid && filters.edit) {
        const [, , a, b] = elem.permissions
        valid = a || b
      }
      if (valid && filters.create) {
        const [, , , , a, b] = elem.permissions
        valid = a || b
      }
      if (valid && filters.delete) {
        const [, , , , , , a, b] = elem.permissions
        valid = a || b
      }
      return valid
    })
    setShowList(filtered)
  }

  return (
    <Row gutter={[24, 24]}>
      <PermissionFilters handleSearch={handleSearch} />
      <Col xs={24}>
        <BaseTable
          rowKey='functionalityId'
          datasource={showList}
          columns={columns}
          pagination={{
            render: false
          }}
        />
      </Col>
    </Row>
  )
}

export default connect()(PermissionsTable)
