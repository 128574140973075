import React from 'react'

import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'

import ProductTotalFooter from '../../../../shared/components/inputs/Products/ProductTotalFooter'
import BaseTable from '../../../../shared/components/table/BaseTable'
import RoundCurrency from '../../../../shared/logic/numbers/RoundCurrency'
import ActiveTranslation from '../../../../shared/logic/translations/ActiveTranslation'

const OrderProductsTable = ({fields, currency}) => {
  const products = fields.getAll() || []

  const defineValue = (product) => {
    if (product.productTypeId === 4) return product.costValue || 0
    if (product.unitPriceBase) return product.unitPriceBase
    return product.baseValue || 0
  }

  const renderColumns = () => {
    const columns = [
      {
        title: <Translate id='REFERENCE' />,
        dataIndex: 'reference',
        render: (value) => value || '-'
      },
      {
        title: <Translate id='EAN_CODE' />,
        dataIndex: 'barcode',
        render: (value) => value || '-'
      },
      {
        title: <Translate id='CLIENT_REF' />,
        dataIndex: 'referenceClient',
        render: (value) => value || '-'
      },
      {
        title: <Translate id='DESCRIPTION' />,
        dataIndex: 'description',
        render: (description) => (
          <ActiveTranslation value={description || '-'} tag='name' />
        )
      },
      {
        title: <Translate id='COLOR' />,
        dataIndex: 'color',
        render: (color) => (
          <ActiveTranslation
            value={color?.colorTranslation || '-'}
            tag='name'
          />
        )
      },
      {
        title: <Translate id='SIZE' />,
        dataIndex: 'size',
        render: (size) => size?.nameEu || '-'
      },
      {
        title: <Translate id='WIDTH' />,
        dataIndex: 'productWidth',
        render: (productWidth) => productWidth?.code || '-'
      },
      {
        title: <Translate id='QUANTITY' />,
        dataIndex: 'quantity'
      },
      {
        title: <Translate id='PRICE_EUROS' />,
        render: (data) => `${RoundCurrency(defineValue(data) * currency.exchangeRate)}€`
      },
      {
        title: <Translate id='SUB_TOTAL_EUROS' />,
        render: (data) => (
          <div>
            {RoundCurrency(data.quantity * defineValue(data) * currency.exchangeRate)}€
          </div>
        )
      }
    ]

    if (currency && currency.currencyId > 1) {
      columns.push({
        title: <Translate id='PRICE' />,
        dataIndex: 'unitPriceBase',
        render: (value) => `${RoundCurrency(value)} ${currency.code}`
      })
      columns.push({
        title: <Translate id='SUB_TOTAL' />,
        render: (data) => RoundCurrency((data.quantity || 0) * (data.unitPriceBase || 0))
      })
    }

    return columns
  }

  return (
    <>
      <BaseTable
        rowKey='reference'
        datasource={products}
        columns={renderColumns()}
        pagination={{render: false}}
      />
      <ProductTotalFooter products={products} currency={currency} />
    </>
  )
}

OrderProductsTable.propTypes = {
  fields: PropTypes.object.isRequired
}

export default OrderProductsTable
