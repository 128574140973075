import React, { useState, useEffect } from 'react'

import { convertToRaw, EditorState, ContentState } from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import PropTypes from 'prop-types'
import { Editor } from 'react-draft-wysiwyg'
import isInIframe from '../../../shared/utils/isInIframe';

import { DraftWrapper, InputLabel } from './InputStyles'

const emptyDraft = /^<p><\/p>/

const sanitizeHtml = require('sanitize-html')

const sanitizeOptions = {
  allowedTags: [
    'p',
    'h1',
    'h2',
    'h3',
    'strong',
    'em',
    'inst',
    'del',
    'ul',
    'li',
    'ol',
    'a',
    'hr'
  ],
  allowedAttributes: {
    p: ['style'],
    a: ['href', 'target', 'style']
  },
  allowedStyles: {
    '*': {
      'text-align': [/^left$/, /^right$/, /^center$/, /^justify$/],
      'font-size': [/^\d+(?:px|em|%)$/],
      'line-height': [/^\d+(?:px|em|%)$/],
      color: [/^#([0-9a-f]{3}|[0-9a-f]{6})$/i],
      opacity: [/^(0(\.\d+)?|1(\.0+)?)$/]
    }
  }
}

const urlValidations =
  /\b(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])(?![^<>]*>|[^"]*?<\/a)/gim

const RichTextInput = ({
  label,
  input,
  meta,
  placeholder,
  disabled,
  noLabel,
  fixedPosition
}) => {
  const [editor, setEditor] = useState('')
  const [focus, setFocus] = useState(false)
  const [hasChangedInput, setHasChangedInput] = useState(false)
  const inIframe = isInIframe();

  useEffect(() => {
    // component mount.
    if (input && input.value && !hasChangedInput) {
      const contentBlock = htmlToDraft(input.value)
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      )
      setEditor(EditorState.createWithContent(contentState))
      setHasChangedInput(true)
    }
  }, [input, fixedPosition, hasChangedInput])

  const handleBeforeOnChange = (editorState) => {
    if (input) {
      let rawHtml = draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      )
      const urlMatches = rawHtml.match(urlValidations)
      if (urlMatches && urlMatches.length > 0) {
        urlMatches.forEach((result) => {
          rawHtml = rawHtml.replace(
            result,
            `<a href="${result}" target=${inIframe ? '_self' : '_blank'}>${result}</a>`
          )
        })
      }
      const cleanHTML = sanitizeHtml(rawHtml, sanitizeOptions)
      input.onChange(emptyDraft.test(cleanHTML) ? '' : cleanHTML)
    }
    setEditor(editorState)
  }

  const { invalid, submitFailed } = meta
  const showError = invalid && submitFailed ? 1 : 0

  return (
    <DraftWrapper error={showError} focus={focus}>
      {!noLabel && <InputLabel>{label}</InputLabel>}
      <Editor
        readOnly={disabled}
        placeholder={placeholder}
        editorState={editor}
        onEditorStateChange={(es) => handleBeforeOnChange(es)}
        wrapperClassName='wrapper-class'
        editorClassName={
          !disabled ? 'editor-class' : 'editor-class disabled'
        }
        toolbarClassName='toolbar-class'
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        toolbar={{
          options: [
            'inline',
            'link',
            'blockType',
            'textAlign',
            'list',
            'history'
          ],
          inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough']
          },
          list: {
            options: ['unordered', 'ordered']
          },
          blockType: {
            options: ['Normal', 'H1', 'H2']
          },
          link: {
            inDropdown: false,
            showOpenOptionOnHover: false,
            defaultTargetOption: '_blank',
            options: ['link', 'unlink']
          }
        }}
      />
    </DraftWrapper>
  )
}

RichTextInput.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  noLabel: PropTypes.bool,
  fixedPosition: PropTypes.number
}

RichTextInput.defaultProps = {
  input: { value: undefined, onChange: () => null },
  label: undefined,
  placeholder: undefined,
  meta: {},
  disabled: false,
  noLabel: true,
  fixedPosition: 130
}

export default RichTextInput
