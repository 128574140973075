import React from 'react'

import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'

import EmptyTable from '../../../assets/icons/empty_table.svg'
import {EmptySpace} from '../../styles/BasicStyles'
import TableBasePagination from './components/TableBasePagination'
import {
  StyledSmallTable,
  EmptyTableIconContainer,
  EmptyTableIcon,
  EmptyTableText
} from './TableStyles'

const renderEmpty = (result) =>
  result !== null && result !== undefined && result !== '' ? (
    result
  ) : (
    <EmptySpace />
  )

const SmallTable = ({
  datasource,
  columns,
  meta,
  rowKey,
  loading,
  emptyMessage,
  scroll,
  pagination
}) => {
  const showError = meta.invalid && meta.submitFailed
  const columnsWrapper = (source) => {
    if (Array.isArray(source) && source.length > 0) {
      const newSource = source.map((current) => {
        const result = {
          ...current,
          showSorterTooltip: false,
          title: () => <div>{current.title}</div>,
          render: (text, row, renderIndex) => (
            <div>
              {current.render
                ? renderEmpty(current.render(text, row, renderIndex))
                : renderEmpty(text)}
            </div>
          )
        }

        return result
      })

      return newSource
    }
    return []
  }

  const locale = {
    emptyText: (
      <EmptyTableIconContainer>
        <EmptyTableText>
          {emptyMessage || <Translate id='TABLE_IS_EMPTY' />}
        </EmptyTableText>
        <EmptyTableIcon src={EmptyTable} />
      </EmptyTableIconContainer>
    )
  }

  return (
    <>
      <StyledSmallTable
        dataSource={datasource}
        columns={columnsWrapper(columns)}
        showSorterTooltip={false}
        rowKey={rowKey}
        pagination={false}
        $hasError={showError}
        loading={loading}
        scroll={scroll}
        locale={locale}
      />
      {pagination.render && (
        <TableBasePagination
          small
          page={pagination.page}
          pageSize={pagination.pageSize}
          total={pagination.total}
          onChangePagination={pagination.onChangePagination}
        />
      )}
    </>
  )
}

SmallTable.propTypes = {
  datasource: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  meta: PropTypes.object,
  rowKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  emptyMessage: PropTypes.string,
  scroll: PropTypes.object
}

SmallTable.defaultProps = {
  loading: false,
  meta: {},
  rowKey: 'key',
  emptyMessage: undefined,
  scroll: undefined
}

export default SmallTable
