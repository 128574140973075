import React, {useState} from 'react'

import {EditOutlined} from '@ant-design/icons'
import {Popover} from 'antd'
import moment from 'moment'
import styled from 'styled-components'

import BaseButton from '../../../shared/components/buttons/BaseButton'
import DateInput from '../../../shared/components/inputs/DateInput'
import {PrimaryColor} from '../../../shared/styles/_colors'

const PopupSection = styled.div`
    background: ${PrimaryColor};
    color: white;
    border-radius: 4px;
    border-radius: 4px;
    height: 22px;
    width: 22px;
    text-align: center;
    cursor: pointer;
`

const ActionSection = styled.div`
    margin-top: 10px;
    text-align: right;
`

const EditDatePopup = ({dateExpected, handleDateChange}) => {
  const [date, setDate] = useState(moment(dateExpected))

  const content = (
    <>
      <DateInput
        label='Change Date'
        input={{value: date, onChange: setDate}}

      />
      <ActionSection>
        <BaseButton
          onClick={() => handleDateChange(date)}
          auto
          size='small'
        >
          Save
        </BaseButton>
      </ActionSection>
    </>
  )

  return (
    <PopupSection>
      <Popover content={content} placement='topRight' trigger='click'>
        <EditOutlined />
      </Popover>
    </PopupSection>
  )
}

export default EditDatePopup
