import React, { useState, useEffect } from 'react'
import { Row, Col, Select } from 'antd'
import PropTypes from 'prop-types'
import { Translate, withLocalize } from 'react-localize-redux'
import { useLocation } from 'react-router-dom'
import SelectInput from '../../../shared/components/inputs/SelectInput'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import DateInput from '../../../shared/components/inputs/DateInput'
import BaseModal from '../../../shared/components/modal/BaseModal'
import { Margin } from '../../../shared/styles/BasicStyles'
import { GetOrderStatus, GetB2BClients, GetPaymentMethods } from '../../../infra/requests/BaseRequests'
import { NewOrderStatusList, BackOrderStatusList, HistoricalOrderStatusList } from '../helpers/OrderStatusFilters'
import { AdvancedSearchDescription } from '../OrdersStyles'

const EmptyState = {
    search: '',
    dateFrom: '',
    dateTo: '',
    qtyFrom: '',
    qtyTo: '',
    totalFrom: '',
    totalTo: '',
    orderStatusId: '',
    PaymentMethodID: '',
    ReturnType: '',
}

const AdvancedB2CSearch = ({ setFilters, translate, filters, count }) => {
    const [openModal, setModal] = useState(false)
    const [values, setValues] = useState(EmptyState)
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const urlParam = queryParams.get('search')
    const [statusItems, setStatusItems] = useState([])
    const [clients, setClients] = useState([])
    const [payments, setPayments] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [orderStatus, clientsB2B] = await Promise.all([GetOrderStatus(), GetB2BClients()])
                setStatusItems(orderStatus.data.items)
                setClients(clientsB2B.data.items)
                const statusInfo = await GetPaymentMethods()
                setPayments(statusInfo.data)
            } catch (error) {
                console.error('Error fetching data:', error)
            }
        }
        fetchData()
    }, [])

    const typeData = [
        { typeId: 0, typeName: "Return", typeFilter: "R" },
        { typeId: 1, typeName: "Exchange", typeFilter: "E" },
        { typeId: 2, typeName: "Regular", typeFilter: "N" },
    ]

    const typePaid = [
        { typeId: 0, typeName: "Paid", typeFilter: true },
        { typeId: 1, typeName: "Unpaid", typeFilter: false },
    ]

    const onSubmitSearch = (key, value, timeout = 0) => {
        const result = { ...values }
        result[key] = value
        setValues(result)
        setFilters(result, timeout)
    }

    const onChangeField = (key, value) => {
        const result = { ...values }
        result[key] = value
        setValues(result)
    }

    const handleFilter = (inputs) => {
        const formattedInputs = { ...inputs }
        setFilters(formattedInputs)
        setModal(false)
    }

    useEffect(() => {
        if (urlParam) {
            onSubmitSearch('search', urlParam, 1000)
        }
    }, [urlParam])

    const getOrderStatusList = () => {
        if (count === 0) {
            return NewOrderStatusList(statusItems)
        } else if (count === 1) {
            return BackOrderStatusList(statusItems)
        } else if (count === 2) {
            return HistoricalOrderStatusList(statusItems)
        } else {
            return []
        }
    }

    return (
        <>
            <BaseButton style={{ padding: 0 }} onClick={() => setModal(true)} type='secondary' align='right' auto>
                <Translate id='ADVANCED_SEARCH' />
            </BaseButton>
            <BaseModal
                okText={<Translate id='SEARCH' />}
                onOk={() => handleFilter(values)}
                cancelText={<Translate id='CLEAR_SEARCH' />}
                onCancel={() => {
                    setValues(EmptyState)
                    handleFilter(EmptyState)
                }}
                onClose={() => setModal(false)}
                title={<Translate id='ADVANCED_SEARCH' />}
                open={openModal}
            >
                <Margin size={10} />
                <Row align='bottom' justify='center' gutter={[20, 10]}>

                    <Col xs={24} md={24} lg={6}>
                        <SelectInput
                            input={{ onChange: (value) => onChangeField('orderStatusId', value) }}
                            label={<Translate id='STATUS' />}
                            data={getOrderStatusList(statusItems)}
                            placeholder={<Translate id='CHOOSE_STATUS' />}
                            dataLabel='dataStep'
                            dataKey='orderStatusId'
                        />
                    </Col>
                    <Col xs={24} md={24} lg={6}>
                        <SelectInput
                            input={{ onChange: (value) => onChangeField('ReturnType', value) }}
                            label={<Translate id='TYPE' />}
                            data={typeData}
                            placeholder={<Translate id='CHOOSE_TYPE' />}
                            dataLabel='typeName'
                            dataKey='typeFilter'
                        />
                    </Col>
                    <Col xs={24} md={24} lg={6}>
                        <SelectInput
                            input={{ onChange: (value) => onChangeField('Paid', value) }}
                            label={<Translate id='PAYMENT' />}
                            data={typePaid}
                            placeholder={<Translate id='CHOOSE_PAYMENT' />}
                            dataLabel='typeName'
                            dataKey='typeFilter'
                        />
                    </Col>
                    <Col xs={24} md={24} lg={6}>
                        <SelectInput
                            input={{ onChange: (value) => onChangeField('PaymentMethodID', value) }}
                            label={<Translate id='PAYMENT_METHOD' />}
                            data={payments}
                            placeholder={<Translate id='CHOOSE_PAYMENT' />}
                            dataLabel='Name'
                            dataKey='PaymentMethodID'
                        />
                    </Col>

                </Row>
                <Margin size={20} />
                <Row align='bottom' justify='center' gutter={[20, 10]}>
                    <Col xs={24} md={11}>
                        <DateInput
                            input={{
                                value: values.dateFrom,
                                onChange: (value) => onChangeField('dateFrom', value)
                            }}
                            label={<Translate id='PERIOD' />}
                        />
                    </Col>
                    <Col xs={24} md={2}>
                        <AdvancedSearchDescription>
                            <Translate id='TO' />
                        </AdvancedSearchDescription>
                    </Col>
                    <Col xs={24} md={11}>
                        <DateInput
                            input={{
                                value: values.dateTo,
                                onChange: (value) => onChangeField('dateTo', value)
                            }}
                            label=''
                        />
                    </Col>
                </Row>
                <Margin size={20} />
            </BaseModal>
        </>
    )
}

AdvancedB2CSearch.propTypes = {
    setFilters: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
    count: PropTypes.number.isRequired,
}

export default withLocalize(AdvancedB2CSearch)
