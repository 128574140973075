import React from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import ActiveTranslation from '../../logic/translations/ActiveTranslation'
import { TableBodyField } from '../table/TableStyles'

const GetStatusColor = (id) => {
  switch (id) {
    case 0:
      return '#29ABE2'

    case 1:
      return '#FCCD21'

    case 2:
    case 6:
    case 10:
      return '#ED1C00'

    case 3:
    case 8:
      return '#8CD73F'

    case 4:
    case 7:
      return '#2243E0'

    case 5:
    case 9:
    case 11:
      return '#39B54A'

    default:
      return ''
  }
}

const TableOrderStatus = ({ id, orderStatus, paid, type }) => {


  const showPayment = () => {
    if (type === 'b2c' && id < 3 && orderStatus !== undefined) {
      return paid ? ' (Paid)' : ' (Unpaid)';
    }
    return null;
  };


  const status = orderStatus.find((elem) => elem.orderStatusId === id)

  return (
    <TableBodyField color={GetStatusColor(id)}>
      <ActiveTranslation
        value={
          status?.orderStatusTranslation || id?.orderStatusTranslation
        }
        tag='name'
      />
      {showPayment()}
    </TableBodyField>
  )
}

TableOrderStatus.propTypes = {
  id: PropTypes.number.isRequired,
  orderStatus: PropTypes.array.isRequired
}

const mapStateToProps = (state) => ({
  orderStatus: state.info.orderStatus
})

export default connect(mapStateToProps)(TableOrderStatus)
