import React, {useEffect, useState} from 'react'

import {Col, Row} from 'antd'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {useParams} from 'react-router'
import {reduxForm, initialize, Field, FieldArray} from 'redux-form'

import {
  GetProfile,
  CreateProfile,
  UpdateProfile,
  GetProfilePermissions,
  SaveProfilePermissions,
  GetProfileUsers
} from '../../../../infra/requests/ClientRequests'
import AlertService from '../../../../shared/components/alert/AlertService'
import BackButton from '../../../../shared/components/buttons/BackButton'
import BaseButton from '../../../../shared/components/buttons/BaseButton'
import SwitchInput from '../../../../shared/components/inputs/SwitchInput'
import TextInput from '../../../../shared/components/inputs/TextInput'
import BaseLoading from '../../../../shared/components/loading/BaseLoading'
import PermissionsTable from '../../../../shared/components/permissions/PermissionsTable'
import StatusColumn from '../../../../shared/components/Status/StatusColumn'
import BaseTable from '../../../../shared/components/table/BaseTable'
import {
  ContentContainer,
  Margin,
  PageForm,
  PageTitle,
  StyledTabPane,
  StyledTabs,
  StyledTabTitle,
  StyledTabTitleContainer
} from '../../../../shared/styles/BasicStyles'

const Tabs = ['users', 'permissions']

const ManageProfile = ({router, dispatch, handleSubmit, translate}) => {
  const [section, setSection] = useState('permissions')
  const [ready, setReady] = useState(false)
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [name, setName] = useState(<Translate id='PROFILE' />)
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [users, setUsers] = useState([])
  const {id} = useParams()
  const pageSize = 20

  useEffect(() => {
    async function init() {
      if (id !== 'new') {
        const {data, success} = await GetProfile(id)
        if (success) {
          setName(data.name)
          const perms = await GetProfilePermissions(id)
          dispatch(initialize('profile_form', {
            ...data,
            permissions: perms?.data || []
          }))
        }

        const userResult = await GetProfileUsers(
          id,
          page,
          pageSize
        )
        setTotal(userResult?.data?.totalItems || 0)
        setUsers(userResult?.data?.items)

        const hash = router.location.hash.replace('#', '')
        if (Tabs.find((s) => s === hash)) {
          setSection(hash)
        }
      } else {
        const perms = await GetProfilePermissions(0)
        dispatch(initialize('profile_form', {permissions: perms?.data || []}))
      }

      setReady(true)
    }
    init()
  }, [])

  const onChangeTab = (active) => {
    setSection(active)
    router.history.replace(`/skypro/profile/${id}#${active}`)
  }

  const onSubmit = async (values) => {
    setSaving(true)
    if (id !== 'new') {
      const {success} = await UpdateProfile(id, {
        name: values.name,
        active: values.active
      })
      if (success) {
        const result = await SaveProfilePermissions(id, values.permissions)
        if (result.success) {
          AlertService.success(translate('PROFILE_EDITED'), translate('PROFILE_EDITED_MESSAGE'))
          return router.history.push('/skypro#profiles')
        }
      }
    } else {
      const {success, data} = await CreateProfile({
        name: values.name,
        active: values.active
      })
      if (success) {
        const result = await SaveProfilePermissions(data.profileId, values.permissions)
        if (result.success) {
          AlertService.success(translate('PROFILE_CREATED'), translate('PROFILE_CREATED_MESSAGE'))
          return router.history.push('/skypro#profiles')
        }
      }
    }

    setSaving(false)
  }

  const handlePageChange = async (p) => {
    setLoading(true)
    setPage(p)
    const {data} = await GetProfileUsers(id, p, pageSize)
    setUsers(data?.items)
    setTotal(data?.totalItems || 0)
    setLoading(false)
  }

  const getColumnsUsers = () => [
    {
      dataIndex: 'fullName',
      type: 'text',
      title: <Translate id='NAME' />
    },
    {
      dataIndex: 'userName',
      type: 'text',
      title: <Translate id='USERNAME' />
    },
    {
      dataIndex: 'aspnetMembership',
      type: 'text',
      title: <Translate id='EMAIL' />,
      render: (value) => <div>{value?.email || 'N/A'}</div>
    },
    {
      dataIndex: 'aspnetMembership',
      type: 'text',
      title: <Translate id='APPROVED' />,
      render: (value) => (
        <StatusColumn
          value={value.isApproved}
          onText={<Translate id='YES' />}
          offText={<Translate id='NO' />}
        />
      )
    },
    {
      dataIndex: 'aspnetMembership',
      type: 'text',
      title: <Translate id='STATUS' />,
      render: (value) => <StatusColumn value={!value.isLockedOut} />
    }
  ]

  if (!ready) return <BaseLoading margin={200} />

  return (
    <>
      <PageForm onSubmit={handleSubmit(onSubmit)}>
        <Row align='bottom' gutter={[24, 24]}>
          <Col xs={24}>
            <BackButton
              label={<Translate id='BACK' />}
              onClick={() => router.history.push('/skypro#profiles')}
            />
          </Col>
          <Col xs={12}>
            <PageTitle>{name}</PageTitle>
          </Col>
        </Row>
        <Margin size='50' />
        <Row gutter={[24, 24]}>
          <Col xs={6}>
            <Field
              component={TextInput}
              name='name'
              label='Profile name'
              placeholder='Profile name'
            />
          </Col>
          <Col xs={6} offset={1}>
            <Field
              component={SwitchInput}
              name='active'
              checkedText={<Translate id='YES' />}
              uncheckedText={<Translate id='NO' />}
              label={<Translate id='ACTIVE' />}
            />
          </Col>
          <Col xs={3} offset={8}>
            <BaseButton type='primary' htmlType='submit' loading={saving}>
              <Translate id='SAVE' />
            </BaseButton>
          </Col>
        </Row>
        <ContentContainer>
          <StyledTabs activeKey={section} onChange={onChangeTab}>
            <StyledTabPane
              tab={
                <StyledTabTitleContainer>
                  <StyledTabTitle>
                    <Translate id='PERMISSIONS' />
                  </StyledTabTitle>
                </StyledTabTitleContainer>
              }
              key='permissions'
            >
              <FieldArray name='permissions' component={PermissionsTable} />
            </StyledTabPane>
            <StyledTabPane
              tab={
                <StyledTabTitleContainer>
                  <StyledTabTitle>
                    <Translate id='USERS' />
                  </StyledTabTitle>
                </StyledTabTitleContainer>
              }
              key='users'
            >
              <Row>
                <Col xs={24}>
                  <BaseTable
                    rowKey='userId'
                    datasource={users}
                    columns={getColumnsUsers()}
                    pagination={{
                      render: true,
                      page,
                      pageSize,
                      total,
                      onChangePagination: handlePageChange
                    }}
                    loading={loading}
                  />
                </Col>
              </Row>
            </StyledTabPane>
          </StyledTabs>
        </ContentContainer>
      </PageForm>
    </>
  )
}

ManageProfile.propTypes = {
  router: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
}

const ManageProfilePage = reduxForm({
  form: 'profile_form'
  // validate: validations
})(ManageProfile)

export default withLocalize(ManageProfilePage)
