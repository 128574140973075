import React, {useState} from 'react'

import {VerticalAlignBottomOutlined, VerticalAlignTopOutlined} from '@ant-design/icons'
import {Col, Row} from 'antd'
import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'
import {connect} from 'react-redux'
import {Field, change} from 'redux-form'

import BaseButton from '../../../../shared/components/buttons/BaseButton'
import NumberIncrementInput from '../../../../shared/components/inputs/NumberIncrementInput'
import SelectInput from '../../../../shared/components/inputs/SelectInput'
import SimpleCheckbox from '../../../../shared/components/inputs/SimpleCheckbox'
import BaseTable from '../../../../shared/components/table/BaseTable'
import {Margin} from '../../../../shared/styles/BasicStyles'
import {MeasureButtons} from '../../ProductsStyles'
import MeasureImage from './MeasureImage'

const PositionFromAlphabet = (letter) => {
  const code = letter.charCodeAt(0)
  return code - 97
}

const PositionInAlphabet = (index) => {
  const code = index + 97
  return String.fromCharCode(code)
}

const TransformMeasures = (object) => {
  const result = []
  if (object) {
    Object.keys(object).forEach((key) => {
      if (key.indexOf('MeasureId') > -1 && key.indexOf('product') === -1) {
        const letter = key.replace('MeasureId', '')
        const index = PositionFromAlphabet(letter)
        if (result[index]) {
          result[index].measure = object[key]
        } else {
          result[index] = {
            letter,
            measure: object[key]
          }
        }
      }
      if (key.indexOf('Tolerance') > -1 && key.indexOf('product') === -1) {
        const letter = key.replace('Tolerance', '')
        const index = PositionFromAlphabet(letter)
        if (result[index]) {
          result[index].tolerance = object[key]
        } else {
          result[index] = {
            letter,
            tolerance: object[key]
          }
        }
      }
      if (key.indexOf('Value') > -1 && key.indexOf('product') === -1) {
        const letter = key.replace('Value', '')
        const index = PositionFromAlphabet(letter)
        if (result[index]) {
          result[index].value = object[key]
        } else {
          result[index] = {
            letter,
            value: object[key]
          }
        }
      }
    })
  }
  return result
}

const ProductsMeasures = ({
  fields,
  sizes,
  productWidths,
  meta,
  dispatch,
  productMeasures,
  productId
}) => {
  const list = fields.getAll() || []
  const [index, setIndex] = useState(0)
  const columns = [
    {
      type: 'text',
      dataIndex: 'letter',
      title: <Translate id='LETTER' />,
      render: (value) => value.toUpperCase()
    },
    {
      type: 'text',
      title: <Translate id='MEASURE' />,
      render: (data) => (
        <Field
          name={`${fields.name}[${index}].${data.letter}MeasureId`}
          small
          component={SelectInput}
          data={productMeasures}
          allowClear={false}
          dataKey='measureId'
          dataLabel='measureTranslation'
          placeholder={<Translate id='SELECT_MEASURE' />}
        />
      )
    },
    {
      type: 'text',
      title: <Translate id='VALUE' />,
      render: (data) => (
        <Field
          name={`${fields.name}[${index}].${data.letter}Value`}
          component={NumberIncrementInput}
          min='0'
          step='0.00001'
        />
      )
    },
    {
      title: <Translate id='ADJUSTABLE' />,
      render: (data) => (
        <Field
          name={`${fields.name}[${index}].${data.letter}Adjustable`}
          component={SimpleCheckbox}
        />
      )
    }
  ]

  const data = TransformMeasures(list[index])

  const changeSize = (sizeId) => {
    let id = list.findIndex((l) => l.sizeId == sizeId)
    if (id === -1) {
      const size = sizes.find((s) => s.sizeId == sizeId)
      if (size) {
        size.widths.forEach((w) => {
          fields.push({
            sizeId,
            productWidthId: w,
            productId
          })
        })
      }
      id = list.length
    }
    setIndex(id)
  }

  const changeWidth = (widthId) => {
    const id = list.findIndex(
      (l) => list[index]?.sizeId == l.sizeId && l.productWidthId == widthId
    )
    setIndex(id)
  }

  const addLineAction = () => {
    const current = {...list[index]}
    const letter = PositionInAlphabet(data.length)
    current[`${letter}MeasureId`] = undefined
    current[`${letter}Value`] = 0
    dispatch(change('manage_product', `${fields.name}[${index}]`, current))
  }

  const removeLineAction = () => {
    const current = {...list[index]}
    const letter = PositionInAlphabet(data.length - 1)
    delete current[`${letter}MeasureId`]
    delete current[`${letter}Value`]
    dispatch(change('manage_product', `${fields.name}[${index}]`, current))
  }

  const defineWidths = () => {
    if (list[index]) {
      const size = sizes.find((s) => s.sizeId == list[index].sizeId)
      if (size) {
        const available = size.widths
        return productWidths.filter((w) =>
          available.find((a) => a == w.productWidthId)
        )
      }
    }
    return []
  }

  return (
    <>
      <Row gutter={[16, 20]}>
        <Col xs={12} lg={5}>
          <SelectInput
            label={<Translate id='SIZE' />}
            placeholder={<Translate id='SELECT' />}
            data={sizes}
            allowClear={false}
            dataKey='sizeId'
            dataLabel='number'
            input={{
              value: list[index]?.sizeId,
              onChange: changeSize
            }}
          />
        </Col>
        <Col xs={12} lg={5}>
          <SelectInput
            label={<Translate id='WIDTH' />}
            placeholder={<Translate id='SELECT' />}
            data={defineWidths()}
            allowClear={false}
            dataKey='productWidthId'
            dataLabel='productWidthTranslation'
            input={{
              value: list[index]?.productWidthId,
              onChange: changeWidth
            }}
          />
        </Col>
      </Row>
      <Margin size={70} />
      <Row gutter={[16, 20]}>
        <Col xs={12}>
          <BaseTable
            rowKey='letter'
            meta={meta}
            datasource={data}
            columns={columns}
            pagination={{render: false}}
            emptyMessage={<Translate id='NO_MEASURES_MESSAGES' />}
          />
          <MeasureButtons>
            {data.length < 26 && (
              <BaseButton
                type='secondary'
                icon={<VerticalAlignBottomOutlined />}
                auto
                onClick={addLineAction}
              >
                <Translate id='ADD_LINE' />
              </BaseButton>
            )}
            {data.length > 0 && (
              <BaseButton
                type='secondary'
                icon={<VerticalAlignTopOutlined />}
                auto
                onClick={removeLineAction}
              >
                <Translate id='REMOVE_LINE' />
              </BaseButton>
            )}
          </MeasureButtons>
        </Col>
        <Col xs={12}>
          <Field
            name='productPropertiesSection.productImage'
            component={MeasureImage}
          />
        </Col>
      </Row>
      <Margin size={24} />
    </>
  )
}

ProductsMeasures.propTypes = {
  fields: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  meta: PropTypes.object,
  sizes: PropTypes.array,
  productWidths: PropTypes.array,
  dispatch: PropTypes.func.isRequired,
  productMeasures: PropTypes.array.isRequired
}

ProductsMeasures.defaultProps = {
  fields: [],
  sizes: [],
  productWidths: [],
  meta: {}
}

export default connect((state) => ({
  productMeasures: state.info.productMeasures,
  productWidths: state.info.productWidths
}))(ProductsMeasures)
