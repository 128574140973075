import React from 'react'

import {LoadingOutlined} from '@ant-design/icons'
import {Spin} from 'antd'
import PropTypes from 'prop-types'

import {ButtonText} from '../../styles/_texts'
import {
  StyledButtonImg,
  StyledBordelessButton,
  StyledBordelessButtonContent
} from './ButtonsStyles'

const BordelessButton = ({
  children,
  imagePath,
  size,
  onClick,
  disabled,
  loading,
  htmlType,
  style,
  auto
}) => {
  const onClickWrapper = (event) => {
    if (loading || disabled) {
      return null
    }
    return onClick(event)
  }

  return (
    <StyledBordelessButton
      type={htmlType}
      onClick={onClickWrapper}
      disabled={disabled}
      loading={loading.toString()}
      style={style}
      $auto={auto}
    >
      <StyledBordelessButtonContent size={size} disabled={disabled}>
        {loading ? (
          <Spin
            size='small'
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginRight: size === 'small' ? 13 : 6,
              fontSize: ButtonText
            }}
            indicator={<LoadingOutlined spin />}
          />
        ) : imagePath ? (
          <StyledButtonImg src={imagePath} />
        ) : null}
        {children}
      </StyledBordelessButtonContent>
    </StyledBordelessButton>
  )
}

BordelessButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.bool
      ])
    ),
    PropTypes.string
  ]).isRequired,
  htmlType: PropTypes.oneOf(['button', 'submit', 'reset']),
  size: PropTypes.oneOf(['small', 'default']),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  style: PropTypes.object,
  imagePath: PropTypes.string
}

BordelessButton.defaultProps = {
  htmlType: 'button',
  size: 'default',
  onClick: () => null,
  disabled: false,
  loading: false,
  style: undefined,
  imagePath: undefined
}

export default BordelessButton
