import React, {Component} from 'react'

import {Col, Row} from 'antd'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'

import Add from '../../../assets/icons/add_bordered.svg'
import {GetFittingCenters} from '../../../infra/requests/FittingRequests'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import SearchInput from '../../../shared/components/inputs/SearchInput'
import BaseTable from '../../../shared/components/table/BaseTable'
import {
  ContentContainer,
  Margin,
  PageTitle
} from '../../../shared/styles/BasicStyles'

let timeout

class FittingCentersTab extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      rows: [],
      search: ''
    }
  }

  getColumns = () => [
    {
      title: <Translate id='NAME' />,
      type: 'text',
      dataIndex: 'contact',
      render: (value) => <div>{value?.name}</div>
    }
  ]

  componentDidMount = async () => {
    try {
      this.updateTable()
    } catch (error) {
      console.warn(error)
    }
  }

  updateTable = async () => {
    try {
      const {search} = this.state

      this.setState({loading: true})
      const {data} = await GetFittingCenters(search)

      this.setState({
        rows: data,
        loading: false
      })
    } catch (error) {
      console.log(error)
    }
  }

  openFittingCenter = (row) => {
    const {router} = this.props
    router.history.push(`/fitting/centers/${row.fittingOperatorId}`)
  }

  setFilter = (search) => {
    this.setState({search}, () => {
      if (timeout) clearTimeout(timeout)
      timeout = setTimeout(this.updateTable, 1000)
    })
  }

  render() {
    const {search, rows, loading} = this.state
    const {router, translate} = this.props

    return (
      <ContentContainer>
        <Row gutter={[24, 40]}>
          <Col xs={24}>
            <PageTitle>
              <Translate id='FITTING_CENTERS' />
            </PageTitle>
          </Col>
          <Col xs={6}>
            <BaseButton
              auto
              imagePath={Add}
              type='primary'
              onClick={() => router.history.push('/fitting/centers/new')}
            >
              <Translate id='ADD_NEW' />
            </BaseButton>
          </Col>
          <Col xs={6} offset={12}>
            <SearchInput
              input={{
                value: search,
                onChange: this.setFilter,
                onSearch: this.updateTable
              }}
              placeholder={translate('SEARCH')}
            />
          </Col>
        </Row>
        <Margin size={15} />
        <Row>
          <Col xs={24}>
            <BaseTable
              rowKey='fittingOperatorId'
              datasource={rows}
              columns={this.getColumns()}
              pagination={{
                render: false
              }}
              loading={loading}
              onClickRow={this.openFittingCenter}
            />
          </Col>
        </Row>
      </ContentContainer>
    )
  }
}

FittingCentersTab.propTypes = {
  router: PropTypes.object.isRequired
}

export default withLocalize(FittingCentersTab)
