import React, { Component } from 'react'

import { Row, Col } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'

import AvailabilityModal from './AvailabilityModal'
import NextAppointmentsTab from './NextAppointmentsTab'
import { GetEmployeesByB2bclient } from '../../../infra/requests/DepartureRequests'
import {
  GetCentersDropDown,
  GetTailorCurrentSlots,
  SaveFittingSlot,
  DeleteFittingSlot
} from '../../../infra/requests/FittingRequests'
import AlertService from '../../../shared/components/alert/AlertService'
import BaseCalendar from '../../../shared/components/Calendar/BaseCalendar'
import SelectInput from '../../../shared/components/inputs/SelectInput'
import { Margin } from '../../../shared/styles/BasicStyles'
import {
  TabSubtitle,
  TabDelimiter,
  AvailabilityButton
} from '../FittingStyles'

const CalculateSlots = (
  start = '09:00',
  end = '19:00',
  step = '00:30:00'
) => {
  const result = []
  const startSplit = start.split(':')
  const endSplit = end.split(':')
  const stepSplit = step.split(':')
  const hour = moment
    .utc()
    .set('hours', startSplit[0])
    .set('minutes', startSplit[1])
  const endTime = moment
    .utc()
    .set('hours', endSplit[0])
    .set('minutes', endSplit[1])

  let iteration = 0
  while (hour.isBefore(endTime) && iteration < 50) {
    result.push({
      start: hour.format('HH:mm'),
      end: hour
        .add(stepSplit[0], 'hours')
        .add(stepSplit[1], 'minutes')
        .format('HH:mm')
    })
    iteration += 1
  }
  return result
}

class CalendarTab extends Component {
  state = {
    loading: false,
    openModal: false,
    centers: [],
    selected: { tailors: [] },
    tailor: undefined,
    date: moment.utc(),
    slots: CalculateSlots(),
    schedule: [],
    b2bClientEmployees: [],
    appointments: []
  }

  componentDidMount() {
    this.init()
  }

  setAppointments = (newAppointments) => {
    this.setState({
      appointments: newAppointments
    })
  }

  init = async () => {
    const { data } = await GetCentersDropDown()
    this.setState({ centers: data || [] })
  }

  handleSearch = async () => {
    const { selected, tailor, date } = this.state
    if (selected.fittingOperatorId && tailor && tailor !== '') {
      this.setState({ loading: true })
      const { data } = await GetTailorCurrentSlots(
        selected.fittingOperatorId,
        tailor,
        date
      )
      this.setState({
        loading: false,
        schedule: data?.slots || []
      })
    }
  }

  handleCenterChange = async (value) => {
    const { centers } = this.state
    const found = centers.find((c) => c.fittingOperatorId == value)

    const b2bClients = found?.fittingOperatorB2bclient

    if (b2bClients) {
      const promises = b2bClients.map((client) =>
        new Promise((resolve) => {
          GetEmployeesByB2bclient(client.b2bclient.b2bclientId)
            .then(({ success, data }) => {
              if (success && data?.items) {
                const employeesWithFullName = data.items.map((item) => ({
                  fullName: `${item.firstName} ${item.lastName}`,
                  ...item
                }))
                resolve(employeesWithFullName)
              } else {
                resolve([])
              }
            })
            .catch((err) => {
              console.error(`Error fetching employees for client ${client.b2bclient.b2bclientId}:`, err)
              resolve([])
            })
        })
      )

      Promise.all(promises)
        .then((results) => {
          const employees = results.flat()
          this.setState({
            b2bClientEmployees: employees
          })
        })
        .catch((err) => {
          console.error('Error in fetching employees:', err)
        })
    }
    let tailor = ''
    if (
      found?.fittingOperatorUser &&
      found.fittingOperatorUser.length > 0
    ) {
      tailor = found.fittingOperatorUser[0].userId
    }

    const slots = CalculateSlots(
      found?.defaultStartSchedule,
      found?.defaultEndSchedule,
      found?.defaultSlotDuration
    )

    this.setState(
      {
        schedule: [],
        slots,
        selected: {
          fittingOperatorId: value,
          center: found,
          tailors: found?.fittingOperatorUser || []
        },
        tailor
      },
      this.handleSearch
    )
  }

  handleChangeDate = (date) => {
    this.setState({ date, schedule: [] }, this.handleSearch)
  }

  handleSlotChange = async (available, slot) => {
    const { selected, tailor, appointments } = this.state

    const saveFittingSlotAndSearch = async () => {
      if (selected.fittingOperatorId && tailor) {
        await SaveFittingSlot(selected.fittingOperatorId, tailor, {
          slot,
          duration: selected?.center?.defaultSlotDuration
        })
        this.handleSearch()
      }
    }

    if (!available) {
      await saveFittingSlotAndSearch()
    } else if (!available.employeeId) {
      await DeleteFittingSlot(available.fittingScheduleId)
      this.handleSearch()
    } else if (available.employeeId) {
      const updatedAppointments = appointments.filter((a) => a.slot !== slot).sort((a, b) => moment(a.slot) - moment(b.slot))
      this.setState({ appointments: updatedAppointments })
      await saveFittingSlotAndSearch()
    }
  };

  canOpenModal = () => {
    const { selected, tailor } = this.state
    if (selected?.fittingOperatorId && tailor && tailor !== '') {
      this.setState({ openModal: true })
    }
  }

  openAppointment = (appointment) => {
    const { router } = this.props
    if (!appointment.concluded) {
      router.history.push(
        `/fitting/appointment/${appointment.fittingScheduleId}`
      )
    }
  }

  handleScheduleFittingSubmit = async (employeeInfo, slot) => {
    const { selected, tailor, schedule } = this.state
    const payload = {
      slot,
      duration: selected?.center?.defaultSlotDuration,
      ...employeeInfo
    }

    const { fullName, phone, mobile } = employeeInfo

    const resetSlotPayload = schedule.find((s) => (
      parseInt(s.employeeId, 10) === parseInt(employeeInfo.employeeId, 10) &&
      moment(s.slot).isSame(moment(slot), 'day')
    ))

    let success
    if (resetSlotPayload) {
      const { success: s } = await SaveFittingSlot(selected.fittingOperatorId, tailor, {
        slot: resetSlotPayload?.slot,
        duration: selected?.center?.defaultSlotDuration
      })

      success = s
    }

    if (success || !resetSlotPayload) {
      const { success: success2 } = await SaveFittingSlot(selected.fittingOperatorId, tailor, payload)

      if (success2) {
        this.setState((prevState) => {
          const updatedSchedule = prevState.schedule.map((s) => {
            if (s.slot === slot) {
              return { employeeName: fullName, employeePhone: phone || mobile, ...payload }
            } if (resetSlotPayload && s.slot === resetSlotPayload?.slot) {
              return {
                slot: resetSlotPayload.slot,
                duration: selected?.center?.defaultSlotDuration
              }
            }
            return s
          })

          let isEmptySlot = true
          const updatedAppointmentData = {
            employeeName: fullName,
            employeePhone: phone || mobile,
            ...payload
          }
          const updatedAppointments = prevState.appointments.reduce((acc, a) => {
            if (!(resetSlotPayload && a.slot === resetSlotPayload?.slot)) {
              acc.push(a)
            } else {
              isEmptySlot = false
              acc.push(updatedAppointmentData)
            }
            return acc
          }, [])

          const newAppointmensState = isEmptySlot ? [...updatedAppointments, updatedAppointmentData] : updatedAppointments
          return {
            schedule: updatedSchedule,
            appointments: newAppointmensState.sort((a, b) =>
              moment(a.slot) - moment(b.slot)
            )
          }
        })
        AlertService.success(
          'Success',
          'Schedule updated successfully'
        )
      }
    }
  }

  render() {
    const {
      centers,
      selected,
      tailor,
      date,
      slots,
      loading,
      schedule,
      openModal,
      b2bClientEmployees,
      appointments
    } = this.state
    const { router } = this.props

    return (
      <Row gutter={[24, 24]}>
        <Col xs={5}>
          <SelectInput
            input={{
              value: selected.fittingOperatorId,
              onChange: this.handleCenterChange
            }}
            placeholder={<Translate id='SELECT_FITTING_CENTER' />}
            label={<Translate id='FITTING_CENTER' />}
            data={centers}
            dataKey='fittingOperatorId'
            dataLabel='contact.name'
            allowClear={false}
          />
          <Margin size='20' />
          <SelectInput
            input={{
              value: tailor,
              onChange: (value) =>
                this.setState(
                  { tailor: value, schedule: [] },
                  this.handleSearch
                )
            }}
            placeholder={<Translate id='SELECT_TAILOR' />}
            label={<Translate id='TAILOR' />}
            data={selected.tailors}
            disabled={!selected.tailors.length}
            dataKey='userId'
            dataLabel='user.fullName'
            allowClear={false}
          />
          <Margin size='30' />
          <TabSubtitle>
            <Translate id='AVAILABILITY' />
            <AvailabilityButton
              disabled={
                !selected?.fittingOperatorId || !tailor || tailor === ''
              }
              onClick={this.canOpenModal}
            />
          </TabSubtitle>
          <TabDelimiter />
          <NextAppointmentsTab
            center={selected?.fittingOperatorId}
            tailor={tailor}
            history={router.history}
            appointments={appointments}
            setAppointments={this.setAppointments}
          />
        </Col>
        <Col xs={19}>
          <BaseCalendar
            loading={loading}
            date={date}
            schedule={schedule}
            setDate={this.handleChangeDate}
            slots={slots}
            saveSlot={this.handleSlotChange}
            disabled={
              !selected?.fittingOperatorId || !tailor || tailor === ''
            }
            onScheduleFittingSubmit={this.handleScheduleFittingSubmit}
            employees={b2bClientEmployees}
          />
        </Col>
        <AvailabilityModal
          slots={slots}
          open={openModal}
          center={selected?.fittingOperatorId}
          tailor={tailor}
          setModal={(refresh) =>
            this.setState({ openModal: false }, () => {
              if (refresh) this.handleSearch()
            })
          }
        />
      </Row>
    )
  }
}

CalendarTab.propTypes = {
  router: PropTypes.object.isRequired
}

export default CalendarTab
