import React, {useState} from 'react'

import {Col, Row} from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {useParams} from 'react-router'
import {Field, FormSection} from 'redux-form'
import styled from 'styled-components'

import CloseBorderedImage from '../../../../assets/icons/cancel_border.svg'
import AlertService from '../../../../shared/components/alert/AlertService'
import BaseButton from '../../../../shared/components/buttons/BaseButton'
import DateRangeInput, {FutureRanges} from '../../../../shared/components/inputs/DateRangeInput'
import ProductPriceInput from '../../../../shared/components/inputs/ProductPriceInput'
import SelectInput from '../../../../shared/components/inputs/SelectInput'
import TextInput from '../../../../shared/components/inputs/TextInput'
import GetObjectByID from '../../../../shared/logic/arrays/GetObjectByID'
import {BorderColor} from '../../../../shared/styles/_colors'
import {
  BodyH3,
  BodyH5,
  Margin,
  StyledFlex,
  StyledHR,
  TitleH3,
  TitleH6
} from '../../../../shared/styles/BasicStyles'
import {
  ProductDetailsFeature,
  ProductDetailsFeatureImage
} from '../../ProductsStyles'

const OrLabel = styled.div`
  position: absolute;
  top: 28px;
  right: -8px;
  font-size: 14px;
`

const ProductPrices = ({
  suggestedRetailPrice,
  currencies,
  productTaxRates,
  fields,
  translate
}) => {
  const [currencyId, setCurrencyId] = useState('')
  const [finalPrice, setFinalPrice] = useState('')
  const [rangeDate, setRangeDate] = useState('')
  const [baseValue, setBaseValue] = useState('')
  const [percentage, setPercentage] = useState('')
  const {id} = useParams()

  const list = fields.getAll() || []

  const addCurrency = () => {
    if (Number(finalPrice)) {
      fields.push({
        baseValue: finalPrice,
        currencyId,
        productId: id
      })
      setCurrencyId('')
      setFinalPrice('')
    } else {
      AlertService.error(
        translate('ERROR'),
        translate('FINAL_PRICE_NUMBER_IS_MANDATORY')
      )
    }
  }

  const removeCurrency = (elem) => {
    const index = list.findIndex((c) => c.currencyId === elem.currencyId)
    if (index > -1) fields.remove(index)
  }

  const addDiscount = async () => {
    const index = list.find(
      (c) =>
        c.dateFrom.toISOString().split('T')[0] === rangeDate[0].toISOString().split('T')[0] &&
        c.dateTo.toISOString().split('T')[0] === rangeDate[1].toISOString().split('T')[0] &&
        (c.percentage === percentage || c.baseValue === baseValue)
    )
  
    if (Number(percentage) || Number(baseValue)) {
      if (index === undefined) {
        await fields.push({
          dateFrom: rangeDate[0].toISOString().split('T')[0] + 'T00:00:00',
          dateTo: rangeDate[1].toISOString().split('T')[0] + 'T23:59:59',
          baseValue,
          percentage,
          productId: id
        })
  
        setBaseValue('')
        setPercentage('')
        setRangeDate('')
      } else {
        AlertService.error(
          translate('ERROR'),
          translate('VALUE_ALREADY_INSERTED')
        )
      }
    } else {
      AlertService.error(
        translate('ERROR'),
        translate('NUMBER_IS_MANDATORY')
      )
    }
  }

  const removeDiscount = (elem) => {
    const index = list.findIndex(
      (c) =>
        c.dateFrom === elem.dateFrom &&
        c.dateTo === elem.dateTo &&
        c.percentage === elem.percentage &&
        c.baseValue === elem.baseValue
    )
    if (index > -1) fields.remove(index)
  }
  const manageCurrency = () =>
    currencies?.length > 0
      ? currencies.filter(
        (curr) => !list.find((l) => parseInt(l?.currencyId, 10) === parseInt(curr.currencyId, 10))
      )
      : []

  return (
    <>
      <FormSection name='productPriceSection'>
        <Row gutter={[20, 20]} xs={24}>
          <Col xs={12}>
            <div style={{borderRight: `1px solid ${BorderColor}`}}>
              <Col xs={24} style={{marginBottom: '30px'}}>
                <TitleH3 $opacity='0.5'>
                  <Translate id='PRICE_CONFIG' />
                </TitleH3>
              </Col>
              <Margin size={20} />
              <Row gutter={[20, 20]}>
                <Col xs={11} style={{marginLeft: '16px'}}>
                  <Field
                    name='tableValue'
                    label={<Translate id='FINAL_COST' />}
                    component={ProductPriceInput}
                    inputDescriptionText='EUR'
                  />
                </Col>
                <Col xs={11}>
                  <Field
                    name='costValue'
                    label={<Translate id='AVERAGE_COST' />}
                    component={ProductPriceInput}
                    disabled
                    inputDescriptionText='EUR'
                  />
                </Col>
              </Row>
              <Margin size={20} />
              <Row gutter={[20, 20]}>
                <Col xs={11} style={{marginLeft: '16px'}}>
                  <Field
                    name='marginPercentage'
                    label={<Translate id='MARGIN' />}
                    component={ProductPriceInput}
                    inputDescriptionText='%'
                    additionalText={<Translate id='USING_PRICE_COST' />}
                    step={0.0000000000000000000000000000000000000000001}
                  />
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={12}>
            <Row gutter={[20, 20]}>
              <Col xs={24} style={{marginLeft: '16px', marginTop: '37px'}}>
                <BodyH3 $opacity='0.5'>
                  <Translate id='TAXES' />
                </BodyH3>
              </Col>
              <Col xs={11} style={{marginLeft: '16px'}}>
                <Field
                  name='taxRateId'
                  label={<Translate id='VAT_RATE' />}
                  component={SelectInput}
                  data={productTaxRates}
                  dataKey='taxRateId'
                  dataLabel='taxRateTranslation'
                />
              </Col>
              <Col xs={11}>
                <Field
                  name='additionalTaxes'
                  label={<Translate id='ADDITIONAL_TAXES' />}
                  component={ProductPriceInput}
                  inputDescriptionText='EUR'
                  additionalText={<Translate id='INSERT_ADT_TAXES' />}
                />
              </Col>
            </Row>
            <Row gutter={[20, 20]}>
              <Col xs={11} style={{marginLeft: '16px'}}>
                <Field
                  name='hscode'
                  label={<Translate id='HS_CODE' />}
                  component={TextInput}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </FormSection>
      <StyledHR $space='30' />
      <Row gutter={[20, 20]} style={{marginLeft: '16px'}}>
        <Col xs={14} lg={5}>
          <Field
            name='baseValue'
            label={<Translate id='NET_PRICE' />}
            disabled
            component={ProductPriceInput}
            inputDescriptionText='EUR'
            additionalText={<Translate id='USING_COST_MARGIN' />}
          />
        </Col>
        <Col xs={14} lg={5}>
          <Field
            name='totalValue'
            label={<Translate id='FINAL_PRICE' />}
            disabled
            component={ProductPriceInput}
            inputDescriptionText='EUR'
          />
        </Col>
        <Col xs={14} lg={5}>
          <Field
            name='marginPercentage'
            label={<Translate id='RETAIL_MARGIN' />}
            disabled
            component={ProductPriceInput}
            inputDescriptionText='%'
          />
        </Col>
      </Row>
      <StyledHR $space='30' />
      <Row gutter={[20, 20]} align='bottom' style={{marginLeft: '16px'}}>
        <Col xs={24}>
          <TitleH3 $opacity='0.5'>
            <Translate id='ADD_CURRENCY' />
          </TitleH3>
        </Col>
        <Col xs={14} lg={5}>
          <SelectInput
            label={<Translate id='CURRENCY' />}
            input={{
              value: currencyId,
              onChange: setCurrencyId
            }}
            placeholder={<Translate id='SELECT_CURRENCY' />}
            data={manageCurrency()}
            dataKey='currencyId'
            dataLabel='name'
          />
        </Col>
        <Col xs={14} lg={5}>
          <ProductPriceInput
            label={<Translate id='SUGGESTED_PRICE' />}
            input={{
              value: suggestedRetailPrice || '',
              onChange: () => null
            }}
            disabled
            inputDescriptionText={
              currencyId
                ? currencies.find((c) => c.currencyId === currencyId)?.code
                : ''
            }
          />
        </Col>
        <Col xs={14} lg={5}>
          <ProductPriceInput
            label={<Translate id='FINAL_PRICE' />}
            placeholder={translate('DEFINE_PRICE')}
            input={{
              value: finalPrice || suggestedRetailPrice || '',
              onChange: setFinalPrice
            }}
            inputDescriptionText={
              currencyId
                ? currencies.find((c) => c.currencyId === currencyId)?.code
                : ''
            }
          />
        </Col>
        <Col xs={4} lg={3}>
          <BaseButton
            type='secondary'
            disabled={
              !currencyId || (!finalPrice && !suggestedRetailPrice)
            }
            onClick={addCurrency}
          >
            <Translate id='ADD' />
          </BaseButton>
        </Col>
      </Row>
      <Margin size={20} />
      <Row gutter={[0, 20]} style={{marginLeft: '16px'}}>
        {list
          .filter((elem) => elem.currencyId)
          .map((currentField, index) => (
            <Col xs={24} key={index}>
              <ProductDetailsFeature>
                <StyledFlex $direction='row'>
                  <TitleH6 $left='3'>
                    {
                      GetObjectByID(
                        currencies,
                        'currencyId',
                        currentField.currencyId
                      )?.code
                    }
                  </TitleH6>
                  <BodyH5 $right='25' $left='20'>
                    <Translate id='FINAL_PRICE' />:{' '}
                    {currentField.finalRetailPrice
                      ? currentField.finalRetailPrice
                      : currentField?.baseValue}
                    {
                      GetObjectByID(
                        currencies,
                        'currencyId',
                        currentField.currencyId
                      )?.symbol
                    }
                  </BodyH5>
                </StyledFlex>
                <ProductDetailsFeatureImage
                  onClick={() => removeCurrency(currentField)}
                  src={CloseBorderedImage}
                />
              </ProductDetailsFeature>
            </Col>
          ))}
      </Row>
      <Margin size='30' />
      <StyledHR $space='30' />
      <Row gutter={[25, 20]} align='bottom' style={{marginLeft: '16px'}}>
        <Col xs={24}>
          <TitleH3 $opacity='0.5'>
            <Translate id='ADD_DISCOUNT' />
          </TitleH3>
        </Col>
        <Col xs={14} lg={5}>
          <ProductPriceInput
            label={<Translate id='FINAL_PRICE' />}
            input={{
              value: baseValue,
              onChange: setBaseValue
            }}
            inputDescriptionText='EUR'
            disabled={!!percentage}
          />
          <OrLabel>
            <Translate id='OR' />
          </OrLabel>
        </Col>
        <Col xs={14} lg={5}>
          <ProductPriceInput
            label={<Translate id='DISCOUNT' />}
            input={{
              value: percentage,
              onChange: setPercentage
            }}
            disabled={!!baseValue}
            inputDescriptionText='%'
          />
        </Col>
        <Col xs={14} lg={6}>
          <DateRangeInput
            label={<Translate id='DATE' />}
            ranges={FutureRanges}
            disabledDate={(value) => moment().startOf('day').isAfter(value.endOf('day'))}
            input={{
              value: rangeDate,
              onChange: (value) => setRangeDate(value)
            }}
          />
        </Col>
        <Col xs={4} lg={3}>
          <BaseButton
            type='secondary'
            disabled={(!baseValue && !percentage) || !rangeDate}
            onClick={addDiscount}
          >
            <Translate id='ADD' />
          </BaseButton>
        </Col>
      </Row>
      <Margin size={20} />
      <Row gutter={[0, 20]} style={{marginLeft: '16px'}}>
        {list
          .filter((elem) => !elem.currencyId)
          .map((currentField, index) => (
            <Col xs={24} key={index}>
              <ProductDetailsFeature>
                <StyledFlex $direction='row'>
                  <div>
                    {currentField.percentage ? (
                      <BodyH5 $right='25' $left='10'>
                        <Translate id='DISCOUNT' />:{' '}
                        {currentField.percentage} %
                      </BodyH5>
                    ) : (
                      <BodyH5 $right='25' $left='10'>
                        <Translate id='FINAL_PRICE' />:{' '}
                        {currentField?.baseValue} EUR
                      </BodyH5>
                    )}
                  </div>
                  <div>
                    <BodyH5 $right='25' $left='10'>
                      <Translate id='START_DATE' />:{' '}
                      {moment(currentField.dateFrom).format('DD-MM-YYYY')}
                    </BodyH5>
                    <BodyH5 $right='25' $left='10'>
                      <Translate id='END_DATE' />:{' '}
                      {moment(currentField.dateTo).format('DD-MM-YYYY')}
                    </BodyH5>
                  </div>
                </StyledFlex>
                <ProductDetailsFeatureImage
                  onClick={() => removeDiscount(currentField)}
                  src={CloseBorderedImage}
                />
              </ProductDetailsFeature>
            </Col>
          ))}
      </Row>
      <Margin size={20} />
    </>
  )
}

ProductPrices.propTypes = {
  suggestedRetailPrice: PropTypes.number,
  currencies: PropTypes.array,
  productTaxRates: PropTypes.array,
  fields: PropTypes.object.isRequired
}

ProductPrices.defaultProps = {
  suggestedRetailPrice: undefined,
  currencies: [],
  productTaxRates: []
}

export default withLocalize(
  connect((state) => ({
    productTaxRates: state.info.productTaxRates,
    currencies: state.info.currencies
  }))(ProductPrices)
)
