import React, {useState} from 'react'

import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'
import {connect} from 'react-redux'

import Trash from '../../../../assets/icons/remove.svg'
import {AddTailors, DeleteTailors} from '../../../../infra/requests/FittingRequests'
import AlertService from '../../../../shared/components/alert/AlertService'
import BaseButton from '../../../../shared/components/buttons/BaseButton'
import SelectInput from '../../../../shared/components/inputs/SelectInput'
import StatusColumn from '../../../../shared/components/Status/StatusColumn'
import BaseTable from '../../../../shared/components/table/BaseTable'
import AsyncForEach from '../../../../shared/logic/arrays/AsyncForEach'
import {Margin} from '../../../../shared/styles/BasicStyles'

const Columns = [
  {
    dataIndex: 'fullName',
    type: 'text',
    title: <Translate id='NAME' />
  },
  {
    dataIndex: 'userName',
    type: 'text',
    title: <Translate id='USERNAME' />
  },
  {
    dataIndex: 'aspnetMembership',
    type: 'text',
    title: <Translate id='EMAIL' />,
    render: (value) => <div>{value?.email}</div>
  },
  {
    dataIndex: 'aspnetMembership',
    type: 'text',
    title: <Translate id='STATUS' />,
    render: (value) => <StatusColumn value={!value.isLockedOut} />
  }
]

const Users = ({fields, availableUsers, id, translate}) => {
  const [selected, setSelected] = useState([])
  const [user, setUser] = useState(undefined)
  const [saving, setSaving] = useState(false)
  const [del, setDel] = useState(false)

  const users = fields.getAll() || []

  const filterUsers = () =>
    availableUsers?.length > 0
      ? availableUsers.filter(
        (elem) => !users.find((c) => c?.userId === elem.userId)
      )
      : []

  const addUser = async () => {
    const newUser = availableUsers.find((x) => x.userId === user)
    if (id && id !== 'new') {
      setSaving(true)
      const {success} = await AddTailors(id, [newUser?.userId])
      setSaving(false)
      if (success) {
        fields.push(newUser)
        setUser(undefined)
        return AlertService.success(
          translate('TAILOR_ADDED'),
          translate('TAILOR_ADDED_MESSAGE')
        )
      }
    } else {
      fields.push(newUser)
    }
    setUser(undefined)
  }

  const removeUser = async () => {
    const indexes = selected.map((element) =>
      users.findIndex((x) => x.userId == element.key)
    ).sort((a, b) => (a > b ? -1 : 1))

    if (id && id !== 'new') {
      setDel(true)
      await AsyncForEach(indexes, async (index) => {
        const {success} = await DeleteTailors(id, users[index].userId)
        if (success) fields.remove(index)
      })
      setSelected([])
      setDel(false)
      return AlertService.success(
        translate('TAILORS_REMOVED'),
        translate('TAILORS_REMOVED_MESSAGE')
      )
    }
    indexes.forEach((i) => fields.remove(i))
    setSelected([])
  }

  return (
    <>
      <Row gutter={[24]} align='bottom'>
        <Col xs={8}>
          <SelectInput
            input={{value: user, onChange: setUser}}
            label={<Translate id='USER' />}
            placeholder={<Translate id='CHOOSE_USER' />}
            data={filterUsers()}
            dataLabel='fullName'
            dataKey='userId'
          />
        </Col>
        <Col xs={6}>
          <div style={{marginLeft: 20}}>
            <BaseButton
              auto
              type='secondary'
              onClick={addUser}
              disabled={user === undefined}
              loading={saving}
            >
              <Translate id='ADD' />
            </BaseButton>
          </div>
        </Col>
      </Row>
      <Margin size={40} />
      <Row>
        <Col xs={6}>
          <BaseButton
            auto
            type='secondary'
            imagePath={Trash}
            onClick={removeUser}
            disabled={selected?.length <= 0}
            loading={del}
          >
            <Translate id='DELETE' />
          </BaseButton>
        </Col>
      </Row>
      <Margin size={26} />
      <Row gutter={[24]}>
        <Col xs={24}>
          <BaseTable
            datasource={users}
            columns={Columns}
            pagination={{render: false}}
            rowKey='userId'
            rowSelection={{
              selected,
              onRowSelection: setSelected
            }}
          />
        </Col>
      </Row>
    </>
  )
}

Users.propTypes = {
  availableUsers: PropTypes.array.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

Users.defaultProps = {
  id: undefined
}

export default connect()(Users)
