import React from 'react'

import {PropTypes} from 'prop-types'

import {SmallInputBox, TableTextStyled} from './InputStyles'

const TableTextInput = ({
  input,
  meta,
  placeholder,
  afterChange,
  disabled
}) => {
  const handleChange = (event) => {
    input.onChange(event.target.value)
    if (typeof afterChange === 'function') {
      afterChange(event.target.value)
    }
  }

  const hasError = meta.invalid && meta.submitFailed

  return (
    <SmallInputBox>
      <TableTextStyled
        hasError={hasError}
        type='text'
        placeholder={placeholder}
        value={input.value}
        onChange={handleChange}
        disabled={disabled}
      />
    </SmallInputBox>
  )
}

TableTextInput.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  placeholder: PropTypes.string,
  afterChange: PropTypes.func,
  disabled: PropTypes.bool
}

TableTextInput.defaultProps = {
  input: {value: undefined, onChange: () => null},
  placeholder: undefined,
  meta: {},
  afterChange: undefined,
  disabled: false
}

export default TableTextInput
