import React, { useState } from 'react'

import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'

import Back from '../../../../assets/icons/back.svg'
import Next from '../../../../assets/icons/next.svg'
import {
  TablePager,
  LeftPager,
  RightPager,
  FooterBack,
  FooterNext,
  PaginationInput
} from '../TableStyles'

const calculateTotalPages = (page, pageSize, total) => {
  if (!total) return 1
  if (total <= pageSize) return 1
  return Math.ceil(total / pageSize)
}

const calculateCurrentItems = (page, pageSize, total) => {
  if (!total) return 0
  if (total <= pageSize) return total
  if (total <= pageSize * page) return total
  return Math.ceil(pageSize * page)
}

const TableBasePagination = ({
  page,
  pageSize,
  total,
  onChangePagination,
  small
}) => {
  const [currentPage, setCurrentPage] = useState(page)
  const lastPage = calculateTotalPages(page, pageSize, total)
  const current = calculateCurrentItems(page, pageSize, total)

  const OnPressBack = () => {
    if (page > 1) {
      const p = page - 1
      setCurrentPage(p)
      onChangePagination(p)
    }
  }

  const OnPressForward = () => {
    if (page < lastPage) {
      const p = page + 1
      setCurrentPage(p)
      onChangePagination(p)
    }
  }

  const handlePageChange = (value) => {
    setCurrentPage(value)
    onChangePagination(value)
  }

  const getPages = () => {
    const options = []
    for (let i = 1; i <= lastPage; i += 1) {
      options.push({ value: i, label: i })
    }
    return options
  }

  return (
    <TablePager $small={small}>
      <LeftPager>
        <span>Page</span>
        <PaginationInput
          $small={small}
          value={page}
          onChange={handlePageChange}
          allowClear={false}
          showArrow={false}
          options={getPages()}
        />
        <span>
          of {lastPage} ({current} of {total})
        </span>
      </LeftPager>
      <RightPager>
        <FooterBack disabled={page < 2} onClick={OnPressBack}>
          <img src={Back} alt={<Translate id='FOOTER_BACK' />} />
        </FooterBack>
        <FooterNext disabled={page >= lastPage} onClick={OnPressForward}>
          <img src={Next} alt={<Translate id='FOOTER_NEXT' />} />
        </FooterNext>
      </RightPager>
    </TablePager>
  )
}

TableBasePagination.propTypes = {
  page: PropTypes.number,
  pageSize: PropTypes.number,
  total: PropTypes.number,
  onChangePagination: PropTypes.func.isRequired
}

TableBasePagination.defaultProps = {
  page: 1,
  pageSize: 0,
  total: 0
}

export default TableBasePagination
