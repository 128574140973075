import styled from 'styled-components'

import {
  BorderColor,
  PrimaryButtonColor,
  TableTextColor
} from '../../shared/styles/_colors'
import {
  BoldFontWeight,
  BookFontWeight,
  FooterText
} from '../../shared/styles/_texts'

export const Flex = styled.div`
  display: flex;
`

export const LineContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const CircleNote = styled.div`
  display: flex;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: ${PrimaryButtonColor};
  justify-content: center;
  align-items: center;
`

export const NoteIcon = styled.img`
  height: 11px;
`

export const TextPopover = styled.div`
  font-size: ${FooterText};
  font-weight: ${({ $bold }) => ($bold ? BoldFontWeight : BookFontWeight)};
  line-height: 17px;
  padding: 2px;
`

export const EditButton = styled.img`
  width: 16;
  height: 16;
  cursor: pointer;
`

export const OverviewMenuButton = styled.div`
  display: flex;
  height: 36px;
  width: 46px;
  cursor: pointer;
  background-color: ${PrimaryButtonColor};
  justify-content: center;
  align-items: center;
  margin: -7px;
  margin-left: 23px;
  border-radius: 4px;
  border: 1px solid ${BorderColor};
  color: white;
  font-size: 17px;
`

export const MenuOption = styled.div`
  display: flex;
  align-items: center;
  color: ${TableTextColor};
  cursor: pointer;

  .anticon {
    color: ${TableTextColor};
    margin-right: 10px;
  }
`

export const ConfigurationExportBtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`
