import React, { useState } from 'react'

import { BackwardOutlined, ForwardOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import { Translate, withLocalize } from 'react-localize-redux'
import { useDispatch } from 'react-redux'
import { initialize } from 'redux-form'

import { HeaderButtonContainer } from './ProcessingPO.styles'
import { GetConsolidation } from '../../../infra/requests/InternalPORequests'
import { RevertOrderSuplierCreation } from '../../../infra/requests/SupplierOrdersRequests'
import AlertService from '../../../shared/components/alert/AlertService'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import SwitchInput from '../../../shared/components/inputs/SwitchInput'
import { Flex } from '../../../shared/styles/BasicStyles'
import CollapsableHeaderTitle from '../components/CollapsableHeaderTitle'
import { SupplierToggle } from '../components/POStyles'

const CollapsableHeader = ({ SO, onSave, profit, evolveSO, saving, translate }) => {
  const [isLoading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const revertSO = async (so) => {
    setLoading(true)

    AlertService.confirm(
      translate('WARNING'),
      translate('REVERT_SO_CONFIRMATION'),
      async () => {
        const { success } = await RevertOrderSuplierCreation(so.orderSupplierSetId)
        if (success) {
          const { data, success: getConsolidationSuccess } = await GetConsolidation()
          if (getConsolidationSuccess) {
            dispatch(initialize('manage_consolidation', { orders: data.items || [] }))
          }
        }
      }
    )

    setLoading(false)
  }

  const collapsableHeaderTitleData = [
    { max: 300, name: <Translate id='SUPPLIER' />, description: SO?.supplier?.name },
    { name: <Translate id='PROFIT_VARIATION' />, description: `${profit.variation}%` },
    { name: <Translate id='PROFIT_AMOUNT' />, description: `${profit.amount}€` }
  ]

  return (
    <>
      {collapsableHeaderTitleData.map((item, index) => (
        <CollapsableHeaderTitle {...item} key={index} />
      ))}
      <HeaderButtonContainer>
        <BaseButton
          style={{ marginRight: '20px' }}
          auto
          type='secondary'
          loading={isLoading}
          onClick={() => revertSO(SO)}
        >
          <Flex>
            <div>
              <BackwardOutlined />
            </div>
            <Translate id='REVERT' />
          </Flex>
        </BaseButton>
        <BaseButton
          style={{ marginRight: '20px' }}
          auto
          type='secondary'
          loading={isLoading || saving}
          onClick={() => evolveSO(SO)}
        >
          <Flex>
            <Translate id='EVOLVE' />
            <div>
              <ForwardOutlined style={{ marginRight: 0, marginLeft: 10 }} />
            </div>
          </Flex>
        </BaseButton>

        <SupplierToggle onClick={(e) => e.stopPropagation()}>
          <SwitchInput
            input={{
              value: SO.consolidationReviewed,
              onChange: onSave
            }}
            checkedText={<Translate id='REVIEWED' />}
            uncheckedText={<Translate id='REVIEWED' />}
          />
        </SupplierToggle>
      </HeaderButtonContainer>
    </>
  )
}

CollapsableHeader.propTypes = {
  SO: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  profit: PropTypes.object.isRequired,
  evolveSO: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired
}

export default withLocalize(CollapsableHeader)
