import React, {useRef, useEffect} from 'react'

import PropTypes from 'prop-types'

function useOutsideAlerter(ref, callback) {
  /**
   * Alert if clicked on outside of element
   */
  function handleClickOutside(event) {
    if (
      ref.current &&
      !ref.current.contains(event.target) &&
      ((event.target.className &&
        typeof event.target.className.includes !== 'undefined' &&
        !event.target.className?.includes('langSelector')) ||
        !event.target.className)
    ) {
      callback()
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })
}

/**
 * Component that alerts if you click outside of it
 */
function OutsideAlerter(props) {
  const {callback, children} = props
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, callback)
  return <div ref={wrapperRef}>{children}</div>
}

OutsideAlerter.propTypes = {
  callback: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
    PropTypes.node,
    PropTypes.string,
    PropTypes.number
  ]).isRequired
}

export default OutsideAlerter
