import styled from 'styled-components'

import backgroundImage from '../../assets/login_bg.jpg'
import {
  PrimaryButtonColor,
  ErrorColor,
  BorderColor,
  SecondaryButtonColor
} from '../../shared/styles/_colors'
import {
  BookFontWeight,
  MediumFontWeight,
  BoldFontWeight
} from '../../shared/styles/_texts'

export const AuthForm = styled.form`
  position: relative;
  display: inline-block;
  min-height: 100vh;
  width: 100%;
  padding-bottom: 160px;
  text-align: center;
`

export const AuthBody = styled.div`
  display: inline-block;
  width: 75%;
  max-width: 350px;
  margin: 10px;
`

export const AuthHeaderBackground = styled.div`
  background-image: url(${backgroundImage});
  background-size: cover;
  padding: 0px;
  width: 100%;
  height: 360px;
  border-bottom-right-radius: 125px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 360px;
    background-image: linear-gradient(270deg, #004b84, #004b84);
    opacity: 0.86;
    border-bottom-right-radius: 125px;
  }
`

export const AuthLogo = styled.img`
  position: absolute;
  top: 20px;
  right: 40px;
  width: 130px;
  z-index: 1;
`
export const AuthBottom = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`

export const AuthTitle = styled.h4`
  font-weight: ${BoldFontWeight};
  font-style: normal;
  font-size: 20px;
  text-align: center;
  color: #0a1734;
  margin-top: 30px;
`

export const AuthHeaderTitle = styled.h1`
  font-weight: ${BookFontWeight};
  font-size: 45px;
  line-height: 40px;
  color: #fff;
  text-align: center;
  padding: 0px 40px;
  z-index: 1;
`

export const AuthHeaderDescription = styled.h5`
  font-weight: ${MediumFontWeight};
  font-size: 16px;
  color: #fff;
  text-align: center;
  padding: 0px 40px;
  z-index: 1;
`

export const AuthInputBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: ${({ disabled }) => (disabled ? SecondaryButtonColor : '#fff')};
  border: 1px solid ${({ $hasError }) => ($hasError ? ErrorColor : BorderColor)};
  border-radius: 4px;
  padding: 5px 10px;
  cursor: text;
  width: ${({ $width }) => $width || '100%'};
  height: ${({ $height }) => $height || ' 36px'};

  &:hover,
  &:focus,
  &:visited,
  &:active {
    border: 1px solid
      ${({ disabled }) => (disabled ? BorderColor : PrimaryButtonColor)};
    outline: none;
  }
`
export const TextInputStyled = styled.input`
  background: none;
  border: none;
  margin-left: 20px;
  width: 100%;
  font-size: 16px;

  &:hover,
  &:focus,
  &:visited,
  &:active {
    border: none;
    outline: none;
  }

  ::placeholder {
    opacity: 0.6;
  }
  :-ms-input-placeholder {
    opacity: 0.6;
  }
  ::-ms-input-placeholder {
    opacity: 0.6;
  }
`
export const RememberContainer = styled.div`
  float: left;
`

export const CaptchaWrapper = styled.div`
  .rnc {
    position: relative;
    display: flex;
    width: 100%;
    border: 1px solid ${({ hasError }) => (hasError ? ErrorColor : BorderColor)};
    border-radius: 4px;
    background: white;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    height: 36px;
    padding: 0 20px 0 5px;
    font-size: 16px;

    &:hover {
      border: 1px solid #0267b2;
    }
  }
  .rnc-column {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
  }
  .rnc-input {
    display: inline-block;
    width: 100%;
    border: none;
    background: transparent;
    outline: none;
    font-size: 16px;
  }
  button {
    background: transparent;
    display: inline-block;
    border: none;
    padding: 0;
    margin: 0;
    width: 20px;
    height: 16px;
  }
  svg {
    width: 12px;
  }
  canvas {
    height: 36px;
    margin-top: 8px;
    margin-right: 10px;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
`

export const Box = styled.div`
width: 200px ;
 margin-top: 3px ;
`
