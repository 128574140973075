import React from 'react'

import { Row, Col } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'

import PropertyDescription from '../../../../../shared/components/description/PropertyDescription'
import { Margin } from '../../../../../shared/styles/BasicStyles'

const DepartureHeader = ({ formValues }) => (

  <>
    <Row gutter={[40, 20]}>
      <Col xs={6}>
        <PropertyDescription
          label='Client'
          value={formValues?.client?.name || 'N/A'}
        />
      </Col>
      {/* onclick go to /orderB2bid */}
      <Col xs={6}>
        <PropertyDescription
          label='Order'
          link={formValues?.orderB2bid}
          value={formValues?.order || 'N/A'}
        />
      </Col>
      <Col xs={6}>
        <PropertyDescription
          label='Origin warehouse'
          value={formValues?.warehouse?.name || 'N/A'}
        />
      </Col>
      <Col xs={6}>
        <PropertyDescription
          label='Seller'
          value={formValues?.seller?.Name || 'N/A'}
        />
      </Col>
      <Col xs={6}>
        <PropertyDescription
          label='Departure Date'
          value={
            moment(formValues?.departureDate).format('DD-MM-YYYY') ||
            moment().format('DD-MM-YYYY')
          }
        />
      </Col>
    </Row>
    <Margin size={35} />
  </>
)

DepartureHeader.propTypes = {
  formValues: PropTypes.object
}

DepartureHeader.defaultProps = {
  formValues: {}
}

export default DepartureHeader
