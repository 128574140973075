import React from 'react';
import ActiveTranslation from '../../../shared/logic/translations/ActiveTranslation';

const StatusComponent = (info) => {
    const getStatusColor = (status) => {
        switch (status[1].name) {
            case 'New departure':
                return '#007bff'; // ou '#007bff' para um azul específico
            case 'Delivered':
                return '#28a745'; // ou '#28a745' para um verde específico
            case 'Cancelled':
            case 'Not valid':
                return '#dc3545'; // ou '#dc3545' para um vermelho específico
            default:
                return '#ffc107'; // ou '#ffc107' para um amarelo específico
        }
    };

    const statusColor = getStatusColor(info.info);

    return (
        <div style={{ color: statusColor }}>
            <ActiveTranslation value={info.info} tag='name' />
        </div>
    );
};

export default StatusComponent;
