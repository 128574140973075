import React from 'react'

import { Translate } from 'react-localize-redux'
import styled from 'styled-components'

import { ListEmployeeAddresses, ListMultipleEmployeeAddresses } from '../../../../../infra/requests/DepartureRequests'
import { GetAllWarehouses, GetAllWarehousesDestiny } from '../../../../../infra/requests/LogisticsRequests'
import { GetOrderLocations } from '../../../../../infra/requests/OrdersB2BRequests'
import { GetSupplierDetail } from '../../../../../infra/requests/SupplierRequests'
import { PrimaryColor } from '../../../../../shared/styles/_colors'

const Line = styled.div``
const Value = styled.div`
  font-size: 14px;
  line-height: 18px;
`
const Label = styled.div`
  color: ${PrimaryColor};
  font-size: 12px;
  line-height: 14px;
`

const Info = styled.div`
  font-size: 12px;
  line-height: 14px;
`

const ConstructAddress = (contact) =>
  `${contact.addressLine1 || ''} ${contact.addressLine2 || ''} ${contact.addressLine3 || ''
  } ${contact.city || ''}`

const ConstructLabel = (name, type, contact) => (
  <Line>
    <Label>{type}</Label>
    <Value>{name}</Value>
    <Info>{ConstructAddress(contact)}</Info>
  </Line>
)

export const TransformContact = (contact) => ({
  name: ConstructAddress(contact),
  value: contact.contactDetailId
})

const GetPossibleDeliveryLocations = async (
  departureType,
  client,
  supplier
) => {
  const result = []
  if (departureType === '4') {
    const { data, success } = await GetAllWarehousesDestiny()
    if (success && data?.items) {
      data.items.forEach((item) => {
        const type = item.clientStock
          ? <Translate id='CLIENT_WAREHOUSE' />
          : <Translate id='SKYPRO_WAREHOUSE' />
        result.push({
          value: item.warehouseId,
          label: ConstructLabel(item.name, type, {}),
          name: item.name,
          contact: item.contactDetail,
          b2bclientId: item.b2bclientId,
          clientStock: item.clientStock
        })
      })
    }
  } else if (departureType === '5' && supplier) {
    const { data, success } = await GetAllWarehouses()
    const supplierData = await GetSupplierDetail(supplier)
    if (success && data?.items) {
      data.items.forEach((item) => {
        if (item.supplierId == supplier) {
          result.push({
            value: item.warehouseId,
            label: ConstructLabel(
              item.name,
              <Translate id='SUPPLIER_WAREHOUSE' />,
              item.contactDetail
            ),
            name: item.name,
            contact: item.contactDetail,
            b2bclientId: item.b2bclientId
          })
        }
      })
    }

    if (supplierData.success && supplierData.data) {
      const { contact, name } = supplierData.data
      if (contact && Array.isArray(contact.contactDetail)) {
        contact.contactDetail.forEach((detail) => {
          const showName = detail.name || contact.name || name
          result.push({
            value: detail.contactDetailId,
            label: ConstructLabel(showName, <Translate id='SUPPLIER_ADDRESS' />, detail),
            name: ConstructAddress(detail),
            contact: detail
          })
        })
      }
    }
  } else if (client) {
    const { data, success } = await GetOrderLocations(client)
    if (success) {
      data.forEach((item) => {
        if (Array.isArray(item.contact)) {
          item.contact.forEach((contact) => {
            const type = item.warehouseId
              ? item.clientStock
                ? <Translate id='CLIENT_WAREHOUSE' />
                : <Translate id='SKYPRO_WAREHOUSE' />
              : <Translate id='CLIENT_ADDRESS' />
            result.push({
              contact,
              value: contact.contactDetailId,
              label: ConstructLabel(item.name, type, contact),
              name: item.warehouseId
                ? item.name
                : ConstructAddress(contact)
            })
          })
        }
      })
    }
  }
  return result
}

export const GetClientAddresses = async (client) => {
  const result = []
  const { data, success } = await GetOrderLocations(client)
  if (success) {
    data.forEach((item) => {
      if (Array.isArray(item.contact)) {
        item.contact.forEach((contact) => {
          const type = item.warehouseId
            ? item.clientStock
              ? <Translate id='CLIENT_WAREHOUSE' />
              : <Translate id='SKYPRO_WAREHOUSE' />
            : <Translate id='CLIENT_ADDRESS' />
          result.push({
            contact,
            value: contact.contactDetailId,
            label: ConstructLabel(item.name, type, contact),
            name: item.warehouseId
              ? item.name
              : ConstructAddress(contact)
          })
        })
      }
    })
  }
  return result
}

export const GetEmployeeAddresses = async (employee) => {
  const result = []
  const { data, success } = await ListEmployeeAddresses(employee)
  if (success) {
    data.forEach((item) => {
      result.push({
        value: item.contactDetailId,
        label: ConstructLabel(item.name, 'Employee Address', item),
        name: ConstructAddress(item),
        contact: item
      })
    })
  }
  return result
}

export const GetEmployeeAddressesFromArray = async (list) => {
  const result = []
  const { data, success } = await ListMultipleEmployeeAddresses(list)
  if (success) {
    data.forEach((emp) => {
      result.push({
        employeeId: emp.employeeId,
        addresses: emp.addresses.map((item) => ({
          value: item.contactDetailId,
          label: ConstructLabel(item.name, 'Employee Address', item),
          name: ConstructAddress(item),
          contact: item
        }))
      })
    })
  }
  return result
}

export default GetPossibleDeliveryLocations
