import React, {useState} from 'react'

import moment from 'moment'
import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'
import styled from 'styled-components'

import DropdownIcon from '../../../../../assets/icons/dropdown_blue.svg'
import BaseTable from '../../../../../shared/components/table/BaseTable'
import {
  BorderColor,
  TableTextColor,
  PrimaryColor
} from '../../../../../shared/styles/_colors'
import {
  BookFontWeight,
  LabelText,
  SemiBoldFontWeight,
  BodyText
} from '../../../../../shared/styles/_texts'

const DeliveryContainer = styled.div`
  padding: 10px 0px;
  border-top: 1px solid ${BorderColor};
  border-bottom: 1px solid ${BorderColor};
  margin-bottom: -1px;
`

const CollapseHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  cursor: pointer;
`
const ToggleIcon = styled.img`
  display: inline-block;
  margin-right: 15px;
  transform: ${({$open}) => ($open ? 'rotate(0deg)' : 'rotate(-90deg)')};
`
const CollapseBody = styled.div`
  transform: ${({open}) => (open ? 'scale(1,1)' : 'scale(1,0)')};
  max-height: ${({open}) => (open ? 'auto' : '0px')};
  transform-origin: top center;
  margin-top: ${({open}) => (open ? '10px' : '0px')};
`

const HeaderSection = styled.div`
  margin-left: 25px;
`

const HeaderTitle = styled.div`
  color: ${TableTextColor};
  font-size: ${LabelText};
  font-weight: ${SemiBoldFontWeight};
  line-height: 18px;
`
const HeaderValue = styled.div`
  color: ${({$blue}) => ($blue ? PrimaryColor : TableTextColor)};
  font-size: ${BodyText};
  font-weight: ${BookFontWeight};
  line-height: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
`

const ShippingDetail = ({delivery}) => {
  const [opened, setOpen] = useState(false)
  const columns = [
    {
      dataIndex: 'barcode',
      title: <Translate id='EAN' />
    },
    {
      dataIndex: 'number',
      title: <Translate id='NUMBER' />
    },
    {
      dataIndex: 'height',
      title: <Translate id='HEIGHT_' />
    },
    {
      dataIndex: 'depth',
      title: <Translate id='DEPTH_' />
    },
    {
      dataIndex: 'totalWeight',
      title: <Translate id='WEIGHT_' />
    },
    {
      dataIndex: 'totalVolume',
      title: <Translate id='LENGTH_' />
    },
    {
      dataIndex: 'quantity',
      title: <Translate id='QUANTITY' />
    }
  ]

  const toggleOpen = () => setOpen(!opened)

  return (
    <DeliveryContainer>
      <CollapseHeader onClick={toggleOpen}>
        <ToggleIcon src={DropdownIcon} $open={opened} />
        <HeaderSection>
          <HeaderTitle><Translate id='DESTINATION' /></HeaderTitle>
          <HeaderValue>
            {delivery?.destinyName || '-'}
          </HeaderValue>
        </HeaderSection>
        <HeaderSection>
          <HeaderTitle><Translate id='CARRIER' /></HeaderTitle>
          <HeaderValue>
            {delivery?.carrierName || '-'}
          </HeaderValue>
        </HeaderSection>
        <HeaderSection>
          <HeaderTitle><Translate id='SHIPPING_DATE' /></HeaderTitle>
          <HeaderValue>
            {moment(delivery.shipmentDeliveryDate).format('DD/MM/YYYY')}
          </HeaderValue>
        </HeaderSection>
        <HeaderSection>
          <HeaderTitle><Translate id='SHIPPING_VALUE' /></HeaderTitle>
          <HeaderValue>
            {delivery.shippingValue}€
          </HeaderValue>
        </HeaderSection>

      </CollapseHeader>
      <CollapseBody open={opened}>
        {opened && (
          <BaseTable
            rowKey='boxId'
            columns={columns}
            datasource={delivery.shipmentBoxes}
            pagination={{render: false}}
          />
        )}
      </CollapseBody>
    </DeliveryContainer>
  )
}

ShippingDetail.propTypes = {
  delivery: PropTypes.object
}

ShippingDetail.defaultProps = {
  delivery: {}
}

export default ShippingDetail
