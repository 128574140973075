import React from 'react'

import {MinusCircleOutlined, PlusCircleOutlined} from '@ant-design/icons'
import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'
import styled from 'styled-components'

import {
  TableRow,
  ListCol,
  TableTitle,
  TextTooltip
} from '../../../../../shared/components/table/CustomTableStyles'
import ActiveTranslation from '../../../../../shared/logic/translations/ActiveTranslation'

const DropdownList = styled.div`
  display: inline-block;
  transform: ${({$open}) => ($open ? 'scale(1,1)' : 'scale(1,0)')};
  height: ${({$open}) => ($open ? 'auto' : '0')};
  transform-origin: top center;
  margin-top: 5px;
  margin-bottom: -9px;
  margin-left: -1px;
  margin-right: -1px;
  width: calc(100% + 2px);
`

const Actions = styled.div`
    position: relative;
    padding: 0 3px;
    border-left: 1px solid #C6C8CA;
    color: #231F20;
    font-size: 14px;
    line-height: 22px;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const ButtonAction = styled.button`
  display: inline-block;
    font-size: 14px;
    cursor: pointer;
    display: inline-block;
    padding: 0;
    margin: 0;
    outline: 0;
    border: none;
    background: none;
    box-box-shadow: none;
    color: #231F20;

    &:disabled{
      opacity: 0.5;
      cursor: not-allowed;
    }
`

const QuantityValue = styled.div`
display: inline.block;
margin: 0 5px;
`

const ItemsInsideKits = ({box, reduceQuantity, increaseQuantity}) => {
  const products = box?.items || []
  if (!box.open) return null

  return (
    <DropdownList xs={24} $open={box.open}>
      <TableRow $header>
        <ListCol xs={4}>
          <TableTitle first subtitle text={<Translate id='REFERENCE' />} />
        </ListCol>
        <ListCol xs={3}>
          <TableTitle subtitle text={<Translate id='EAN_CODE' />} />
        </ListCol>
        <ListCol xs={2}>
          <TableTitle subtitle text={<Translate id='CLIENT_REF' />} />
        </ListCol>
        <ListCol xs={5}>
          <TableTitle subtitle text={<Translate id='DESCRIPTION' />} />
        </ListCol>
        <ListCol xs={2}>
          <TableTitle subtitle text={<Translate id='COLOR' />} />
        </ListCol>
        <ListCol xs={1}>
          <TableTitle subtitle text={<Translate id='SIZE' />} />
        </ListCol>
        <ListCol xs={1}>
          <TableTitle subtitle text={<Translate id='WIDTH' />} />
        </ListCol>
        <ListCol xs={2}>
          <TableTitle subtitle text={<Translate id='CREDITS' />} abbr={<Translate id='CREDITS' />} />
        </ListCol>
        <ListCol xs={2}>
          <TableTitle subtitle text={<Translate id='SEND_QUANTITY' />} abbr={<Translate id='SEND_QTY' />} />
        </ListCol>
        <ListCol xs={2}>
          <TableTitle subtitle text={<Translate id='BOX_QUANTITY' />} abbr={<Translate id='BOX_QTY' />} />
        </ListCol>
      </TableRow>

      {products.map((product, index) => (
        <TableRow key={index} $error={product.error}>
          <ListCol xs={4}>
            <TextTooltip first text={product.reference || '-'} />
          </ListCol>
          <ListCol xs={3}>
            <TextTooltip text={product.barcode || '-'} />
          </ListCol>
          <ListCol xs={2}>
            <TextTooltip text={product.referenceClient || '-'} />
          </ListCol>
          <ListCol xs={5}>
            <TextTooltip
              text={
                <ActiveTranslation
                  value={product.description}
                  tag='name'
                />
              }
            />
          </ListCol>
          <ListCol xs={2}>
            <TextTooltip
              text={
                <ActiveTranslation
                  value={product?.color?.colorTranslation || '-'}
                  tag='name'
                />
              }
            />
          </ListCol>
          <ListCol xs={1}>
            <TextTooltip text={product?.size?.nameEu || '-'} />
          </ListCol>
          <ListCol xs={1}>
            <TextTooltip text={product?.productWidth?.code || '-'} />
          </ListCol>
          <ListCol xs={2}>
            <TextTooltip
              align='center'
              text={product?.empNeededQty || '-'}
            />
          </ListCol>
          <ListCol xs={2}>
            <TextTooltip align='center' text={product?.departQty || '-'} />
          </ListCol>
          <ListCol xs={2}>
            {product?.departQty > 0 ? (
              <Actions>
                <ButtonAction
                  onClick={() => reduceQuantity(index, product)}
                  disabled={product?.quantity < 1}
                  type='button'
                ><MinusCircleOutlined />
                </ButtonAction>
                <QuantityValue>{product?.quantity || '0'}</QuantityValue>
                <ButtonAction
                  onClick={() => increaseQuantity(index, product)}
                  disabled={product?.quantity >= product?.empNeededQty}
                  type='button'
                ><PlusCircleOutlined />
                </ButtonAction>
              </Actions>
            )
              : <TextTooltip align='center' text={product?.quantity || '0'} />
            }
          </ListCol>
        </TableRow>
      ))}
    </DropdownList>
  )
}

ItemsInsideKits.propTypes = {
  box: PropTypes.object.isRequired
}

export default ItemsInsideKits
