import React, { useState, useEffect } from 'react'

import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'

import {
  ContentContainer,
  StyledTabPane,
  StyledTabs,
  StyledTabTitle,
  StyledTabTitleContainer
} from '../../shared/styles/BasicStyles'
import ArrivalsLits from './Arrivals/ArrivalsList'
import ConfigurationPage from './Configuration/ConfigurationPage'
import DeparturesList from './Departures/DeparturesList'
import ForecastPage from './Forecast/ForecastPage'
import InformationPage from './Information/InformationPage'
import MaintenanceList from './Maintenance/MaintenanceList'
import OverviewList from './Overview/OverviewList'


const Sections = [
  'info',
  'over',
  'arrivals',
  'depart',
  'pick',
  'main',
  'forecast',
  'config'
]

const LogisticsPage = ({ router }) => {
  const [section, setSection] = useState('')
  const iFrameDetection = ((window === window.parent || window.opener))

  useEffect(() => {
    function initialize() {
      const hash = router.location.hash.replace('#', '')
      if (Sections.find((s) => s === hash)) {
        setSection(hash)
      } else setSection('info')
    }
    initialize()
  }, [router])

  const onChangeTab = (active) => {
    setSection(active)
    router.history.replace(`/logistics#${active}`)
  }

  return (
    <>
      <ContentContainer>
        <StyledTabs destroyInactiveTabPane activeKey={section} onChange={onChangeTab} $hidden={!iFrameDetection}>
          <StyledTabPane
            tab={
              <StyledTabTitleContainer>
                <StyledTabTitle>
                  <Translate id='INFORMATION' />
                </StyledTabTitle>
              </StyledTabTitleContainer>
            }
            key='info'
          >
            <InformationPage />
          </StyledTabPane>
          <StyledTabPane
            tab={
              <StyledTabTitleContainer>
                <StyledTabTitle>
                  <Translate id='OVERVIEW' />
                </StyledTabTitle>
              </StyledTabTitleContainer>
            }
            key='over'
          >
            <OverviewList router={router} onChangeTab={onChangeTab} />
          </StyledTabPane>
          <StyledTabPane
            tab={
              <StyledTabTitleContainer>
                <StyledTabTitle>
                  <Translate id='ARRIVALS' />
                </StyledTabTitle>
              </StyledTabTitleContainer>
            }
            key='arrivals'
          >
            <ArrivalsLits router={router} />
          </StyledTabPane>
          <StyledTabPane
            tab={
              <StyledTabTitleContainer>
                <StyledTabTitle>
                  <Translate id='DEPARTURES' />
                </StyledTabTitle>
              </StyledTabTitleContainer>
            }
            key='depart'
          >
            <DeparturesList router={router} />
          </StyledTabPane>
          <StyledTabPane
            tab={
              <StyledTabTitleContainer>
                <StyledTabTitle>
                  <Translate id='MAINTENANCE' />
                </StyledTabTitle>
              </StyledTabTitleContainer>
            }
            key='main'
          >
            <MaintenanceList router={router} />
          </StyledTabPane>
          <StyledTabPane
            tab={
              <StyledTabTitleContainer>
                <StyledTabTitle>
                  <Translate id='FORECAST' />
                </StyledTabTitle>
              </StyledTabTitleContainer>
            }
            key='forecast'
          >
            <ForecastPage />
          </StyledTabPane>
          <StyledTabPane
            tab={
              <StyledTabTitleContainer>
                <StyledTabTitle>
                  <Translate id='CONFIGURATION' />
                </StyledTabTitle>
              </StyledTabTitleContainer>
            }
            key='config'
          >
            <ConfigurationPage router={router} />
          </StyledTabPane>
        </StyledTabs>
      </ContentContainer>
    </>
  )
}

LogisticsPage.propTypes = {
  router: PropTypes.object.isRequired
}

export default LogisticsPage
