import React, { useEffect, useState } from 'react'

import { Row, Col } from 'antd'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'

import AddWhite from '../../../assets/icons/add_white.svg'
import { GetInfoOrdersB2C } from '../../../infra/requests/OrdersB2CRequests'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import Highlight from '../../../shared/components/highlight/Highlight'
import {
  StyledTabs,
  StyledTabPane,
  StyledTabTitleContainer,
  StyledTabTitle,
  Margin
} from '../../../shared/styles/BasicStyles'
import HistoricalOrdersTab from './tabs/HistoricalOrdersTab'
import NewOrdersTab from './tabs/NewOrdersTab'
import OnBackOrdersTab from './tabs/OnBackOrdersTab'

const OrdersB2C = ({ router }) => {
  const [infoOrders, setInfoOrders] = useState()
  const [count, setcount] = useState(0)

  useEffect(() => {
    async function GetInfo() {
      const { data, success } = await GetInfoOrdersB2C()
      if (success) setInfoOrders(data)
    }
    GetInfo()
  }, [])
  return (
    <>
      {/* <Row gutter={[24, 16]}>
        <Col>
          <BaseButton
            onClick={() => router.history.push('/orders/b2c/new')}
            imagePath={AddWhite}
          >
            <Translate id='NEW_ORDER' />
          </BaseButton>
        </Col>
      </Row>
      <Margin size={24} /> */}
      <Row gutter={[24, 16]}>
        <Col xs={12} md={6}>
          <Highlight
            title='Nº Active Orders'
            text={infoOrders?.activeOrders}
          />
        </Col>
        <Col xs={12} md={6}>
          <Highlight
            title='Nº Back Orders'
            text={infoOrders?.backOrders}
          />
        </Col>
        <Col xs={12} md={6}>
          <Highlight
            title='Waiting Payment'
            text={infoOrders?.waitingPayment}
          />
        </Col>
        <Col xs={12} md={6}>
          <Highlight title='Returns' text={infoOrders?.returns} />
        </Col>
        <Col xs={12} md={6}>
          <Highlight
            title='Conversion Rate'
            text={infoOrders?.conversionRate}
          />
        </Col>
        <Col xs={12} md={6}>
          <Highlight
            title='Abandoned Cart'
            text={infoOrders?.abandonedCart}
          />
        </Col>
        <Col xs={12} md={6}>
          <Highlight title='Daily Sales' text={infoOrders?.dailySales} />
        </Col>
        <Col xs={12} md={6}>
          <Highlight
            title='Monthly Sales'
            text={infoOrders?.monthlySales}
          />
        </Col>
      </Row>
      <Margin size={30} />
      <StyledTabs destroyInactiveTabPane>
        <StyledTabPane
          tab={
            <StyledTabTitleContainer>
              <StyledTabTitle smaller>New Orders</StyledTabTitle>
            </StyledTabTitleContainer>
          }
          key={1}
        >
          <NewOrdersTab count={count} setcount={setcount} router={router} />
        </StyledTabPane>
        <StyledTabPane
          tab={
            <StyledTabTitleContainer>
              <StyledTabTitle smaller>On Back Orders</StyledTabTitle>
            </StyledTabTitleContainer>
          }
          key={2}
        >
          <OnBackOrdersTab count={count} setcount={setcount} router={router} />
        </StyledTabPane>
        <StyledTabPane
          tab={
            <StyledTabTitleContainer>
              <StyledTabTitle smaller>Historical Orders</StyledTabTitle>
            </StyledTabTitleContainer>
          }
          key={3}
        >
          <HistoricalOrdersTab count={count} setcount={setcount} router={router} />
        </StyledTabPane>
      </StyledTabs>
    </>
  )
}

OrdersB2C.propTypes = {
  router: PropTypes.object.isRequired
}

export default OrdersB2C
