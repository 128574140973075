import React from 'react'

import { LoadingOutlined } from '@ant-design/icons'
import { Select, DatePicker, AutoComplete, Switch, TimePicker, Input, Checkbox } from 'antd'
import styled from 'styled-components'

import {
  BorderColor,
  TableTextColor,
  SecondaryButtonColor,
  PrimaryButtonColor,
  ErrorColor,
  PrimaryColor,
  PageBackground
} from '../../styles/_colors'
import {
  BookFontWeight,
  BodyText,
  SemiBoldFontWeight
} from '../../styles/_texts'

const { Option } = Select

const { RangePicker } = DatePicker

export const InputBox = styled.div`
  display: flex;
  flex-direction: ${({ $row }) => ($row ? 'row' : 'column')};
  width: 100%;
  position: relative;
  padding-bottom: ${({ hasPaddingBottom }) =>
    hasPaddingBottom ? '20px' : '0px'};
`

export const InputLabel = styled.div`
  font-size: 16px;
  line-height: 20px;
  color: ${TableTextColor};
  margin-left: 10px;
  font-weight: ${SemiBoldFontWeight};
`

export const InlineInputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${({ $priority }) => ($priority ? 'row' : 'column')};
  justify-content: center;
  padding-bottom: ${({ hasPaddingBottom }) =>
    hasPaddingBottom ? '20px' : '0px'};
`

export const InlineInputLabel = styled.label`
  font-size: 16px;
  color: ${TableTextColor};
  margin-right: 10px;
  font-weight: ${SemiBoldFontWeight};
`

export const InputError = styled.div`
  position: absolute;
  bottom: 0;
  left: 2px;
  font-size: 11px;
  line-height: 16px;
  color: ${ErrorColor};
`

export const FileInputStyled = styled.div`
  background: white;
  border: 1px solid
    ${({ hasError }) => (hasError ? ErrorColor : BorderColor)};
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;

  opacity: ${({ $loading }) => ($loading ? '0.3' : '1')};

  &:hover,
  &:focus,
  &:visited,
  &:active {
    border: 1px solid ${PrimaryButtonColor};
    outline: none;
  }
`

export const FileButton = styled.div`
  background: ${SecondaryButtonColor};
  border: 1px solid ${SecondaryButtonColor};
  color: ${TableTextColor};
  display: inline-block;
  border-radius: 50px;
  padding: 0 15px;
`

export const FileName = styled.div`
  color: ${TableTextColor};
  font-size: 14px;
  line-height: 18px;
  margin-left: 8px;
  text-overflow: ellipsis;
  max-width: calc(100% - 140px);
  overflow: hidden;
  float: left;
  overflow: hidden;
  height: 18px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
`
export const TextInputStyled = styled.input`
  background: white;
  border: 1px solid
    ${({ hasError }) => (hasError ? ErrorColor : BorderColor)};
  border-radius: 4px;
  padding: 5px 10px;
  cursor: text;
  display: inline-block;
  width: 100%;
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || ' 36px'};
  &:hover,
  &:focus,
  &:visited,
  &:active {
    border: 1px solid ${PrimaryButtonColor};
    outline: none;
  }

  &:disabled {
    background: #f5f5f5;
    &:hover,
    &:focus,
    &:visited,
    &:active {
      border: 1px solid ${BorderColor};
      outline: none;
    }
  }

  ::placeholder {
    opacity: 0.4;
  }
  :-ms-input-placeholder {
    opacity: 0.4;
  }
  ::-ms-input-placeholder {
    opacity: 0.4;
  }
`

export const ColorsUploadContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  border: 1px solid
    ${({ hasError }) => (hasError ? ErrorColor : BorderColor)};
  border-radius: 4px;
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '36px'};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  &:hover,
  &:focus,
  &:visited,
  &:active {
    border: ${({ disabled }) =>
    disabled
      ? `1px solid ${BorderColor}`
      : `1px solid ${PrimaryButtonColor}`};
    outline: none;
  }
`

export const ColorsFileName = styled.div`
  color: ${TableTextColor};
  font-size: 14px;
  margin-left: 8px;
  text-overflow: ellipsis;
  max-width: calc(100% - 38px);
  overflow: hidden;
  height: 36px;
  line-height: 36px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`

export const ColorsFileIconContainer = styled.div`
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  margin: 1px 0px;
  height: 34px;
  width: 30px;
  background-color: ${BorderColor};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const InlineTextInputStyled = styled.input`
  background-color: ${({ $backgroundColor, $hasError }) =>
    $backgroundColor || ($hasError ? ErrorColor : BorderColor)};
  border: 1px solid
    ${({ $borderColor, $hasError }) =>
    $borderColor || ($hasError ? ErrorColor : BorderColor)};
  border-radius: 4px;
  padding: 5px 10px;
  cursor: text;
  width: ${({ $width }) => $width || '100%'};
  height: ${({ $height }) => $height || '36px'};
  &:hover,
  &:focus,
  &:visited,
  &:active {
    border: 1px solid ${PrimaryButtonColor};
    outline: none;
  }

  ::placeholder {
    opacity: 0.4;
  }
  :-ms-input-placeholder {
    opacity: 0.4;
  }
  ::-ms-input-placeholder {
    opacity: 0.4;
  }
`

export const InlineTextStyled = styled.div`
  background-color: ${({ $backgroundColor, $hasError }) =>
    $backgroundColor || ($hasError ? ErrorColor : BorderColor)};
  border: 1px solid
    ${({ $borderColor, $hasError }) =>
    $borderColor || ($hasError ? ErrorColor : BorderColor)};
  border-radius: 4px;
  padding: 5px 10px;
  width: ${({ $width }) => $width || '100%'};
  height: ${({ $height }) => $height || '36px'};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const TextAreaInputStyled = styled.textarea`
  background: ${({ $backgroundColor }) => ($backgroundColor)};
  border: 1px solid
    ${({ hasError }) => (hasError ? ErrorColor : BorderColor)};
  border-radius: 4px;
  padding: 5px 10px;
  cursor: text;
  display: inline-block;
  width: 100%;
  line-height: 15px;
  max-height: ${({ rows }) => (rows ? `calc(17px * ${rows})` : '100%')};

  &:hover,
  &:focus,
  &:visited,
  &:active {
    border: 1px solid ${PrimaryButtonColor};
    outline: none;
  }

  ::placeholder {
    opacity: 0.4;
  }
  :-ms-input-placeholder {
    opacity: 0.4;
  }
  ::-ms-input-placeholder {
    opacity: 0.4;
  }
`

export const SearchImage = styled.img`
  background-color: #fff;
  padding: 7px 10px;
  border-left: 1px solid ${BorderColor};
  cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
`

export const SearchInputContainer = styled.div`
  border: 1px solid ${BorderColor};
  border-bottom: ${({ $noBorderBottom }) =>
    $noBorderBottom
      ? '1px solid transparent'
      : `1px solid ${BorderColor}`};
  border-radius: ${({ $noBorderBottom }) =>
    $noBorderBottom ? '0px' : '4px'};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &:hover,
  &:focus,
  &:visited,
  &:active {
    border: 1px solid
      ${({ $disabled }) => ($disabled ? BorderColor : PrimaryButtonColor)};
    outline: none;
  }
`

export const SearchInputStyled = styled.input`
  padding: 5px 10px;
  display: inline-block;
  width: 100%;
  cursor: text;
  outline: none;
  border: none;
  border-radius: 4px;
  background-color: transparent;

  &:hover,
  &:focus,
  &:visited,
  &:active {
    outline: none;
    border: none;
  }

  ::placeholder {
    opacity: 0.4;
  }
  :-ms-input-placeholder {
    opacity: 0.4;
  }
  ::-ms-input-placeholder {
    opacity: 0.4;
  }
`

export const AutoCompleteInputStyled = styled(AutoComplete)`
  cursor: text;
  outline: none;
  border: none;
  border-radius: 4px;
  background-color: transparent;
  width: 100%;

  &&& {
    .ant-select-selector {
      width: 100%;
      border: none;
    }
  }

  &:hover,
  &:focus,
  &:visited,
  &:active {
    outline: none;
    border: none;
  }

  ::placeholder {
    opacity: 0.4;
  }
  :-ms-input-placeholder {
    opacity: 0.4;
  }
  ::-ms-input-placeholder {
    opacity: 0.4;
  }
`

export const SelectArrow = styled.div`
  position: absolute;
  top: ${({ $small }) => ($small ? -7 : -12)}px;
  right: -10px;
  background: #d8d8d8;
  height: ${({ $small }) => ($small ? 24 : 34)}px;
  width: ${({ $small }) => ($small ? 25 : 34)}px;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;

  ::after {
    content: '';
    position: absolute;
    right: ${({ $small }) => ($small ? 6 : 10)}px;
    top: ${({ $small }) => ($small ? 8 : 12)}px;
    width: 0;
    height: 0;
    border-left: ${({ $small }) => ($small ? 5 : 6)}px solid transparent;
    border-right: ${({ $small }) => ($small ? 5 : 6)}px solid transparent;
    border-top: ${({ $small }) => ($small ? 7 : 10)}px solid white;
    clear: both;
    transition: all 0.5s ease;
  }
`

export const SelectInputStyled = styled(({ hasError, ...rest }) => (
  <Select {...rest} />
))`
  width: 100%;
  height: ${({ $small }) => ($small ? 26 : 36)}px;
  overflow: hidden;

  max-width: ${({ $small, $adjustable }) => ($adjustable ? '100%' : $small ? '200px' : '100%')};

  &&& {
    &.ant-select-disabled.ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      color: rgba(0, 0, 0, 0.85);
      background: #f5f5f5;
      cursor: not-allowed;
    }

    .ant-select-selector {
      width: 100%;
      padding: ${({ $small }) => ($small ? '2px 8px' : '5px 11px')};
      height: ${({ $small }) => ($small ? 26 : 36)}px;
      border-radius: 4px;
      border: 1px solid
        ${({ hasError }) => (hasError ? ErrorColor : BorderColor)};
      outline: none;
      box-shadow: none;
      opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

      &:hover,
      &:focus,
      &:visited,
      &:active {
        border: 1px solid
          ${({ disabled }) => (disabled ? BorderColor : PrimaryButtonColor)};
        outline: none;
        box-shadow: none;
      }
    }

    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      line-height: ${({ $small }) => ($small ? 20 : 26)}px;
    }

    .ant-select-selection-search-input {
      height: ${({ $small }) => ($small ? 18 : 30)}px;
    }

    .ant-select-selection-search {
      left: ${({ $small }) => ($small ? 8 : 11)}px;
    }

    .ant-select-clear {
      background: #d8d8d8;
      right: ${({ $small }) => ($small ? 6 : 11)}px;
    }
  }
`

export const MultiInputStyled = styled(({ hasError, ...rest }) => (
  <Select {...rest} />
))`
  width: 100%;
  overflow: hidden;
  margin-bottom: 1px;
  height: 36px;

  &&& {
    .ant-select-selector {
      width: 100%;
      height: 36px;
      padding: 5px 11px;
      border-radius: 4px;
      border: 1px solid
        ${({ hasError, $isActive }) =>
    $isActive
      ? PrimaryButtonColor
      : hasError
        ? ErrorColor
        : BorderColor};
      outline: none;
      box-shadow: none;
      background-color: transparent;

      &:hover,
      &:focus,
      &:visited,
      &:active {
        border: 1px solid ${PrimaryButtonColor};
        outline: none;
        box-shadow: none;
      }
    }

    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      border-radius: 15px;
    }

    .ant-select-clear {
      background: #d8d8d8;
    }
  }
`

export const SelectOption = styled(Option)``

export const DateInputStyled = styled(DatePicker)`
  width: 100%;
  height: ${({ $small }) => ($small ? 24 : 36)}px;
  padding: 5px 11px;
  border-radius: 4px;
  border: 1px solid
    ${({ hasError }) => (hasError ? ErrorColor : BorderColor)};
  outline: none;
  box-shadow: none;

  &:hover,
  &:focus,
  &:visited,
  &:active {
    border: 1px solid ${PrimaryButtonColor};
    outline: none;
    box-shadow: none;
  }

  .ant-picker-suffix {
    height: ${({ $small }) => ($small ? 22 : 34)}px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #d8d8d8;
    margin-right: -11px;
    color: white;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
  }

  .ant-picker-clear {
    background: #d8d8d8;
    right: -1px;
  }
`

export const DateRangeInputStyled = styled(RangePicker)`
  width: 100%;
  height: 34px;
  padding: 5px 11px;
  border-radius: 4px;
  border: 1px solid
    ${({ hasError }) => (hasError ? ErrorColor : BorderColor)};
  outline: none;
  box-shadow: none;

  &:hover,
  &:focus,
  &:visited,
  &:active {
    border: 1px solid ${PrimaryButtonColor};
    outline: none;
    box-shadow: none;
  }

  .ant-picker-suffix {
    height: 32px;
    width: 88px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #d8d8d8;
    margin-right: -11px;
    color: white;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
  }

  .ant-picker-clear {
    background: #d8d8d8;
    right: 10px;
  }

  .ant-picker-active-bar {
    background: ${PrimaryButtonColor};
  }

  .ant-picker-input > input {
    text-align: center;
  }
`

export const CheckBoxInputBox = styled.div`
  display: inline-block;
`

export const CheckboxInputStyled = styled(Checkbox)``

export const CheckBoxInputLabel = styled.div`
  color: ${TableTextColor};
  font-weight: ${BookFontWeight};
  font-size: ${BodyText};
  margin-left: 5px;
  margin-top: 2px;
  cursor: pointer;
  display: inline-block;
`

export const draftFixedSize = 600
export const toolboxFixedSize = 70
export const buttonsFixedSize = 25
export const DraftWrapper = styled.div`
  height: ${draftFixedSize}px;

  .wrapper-class {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    width: 100%;
    font-size: 14px;
    border: 1px solid transparent;
    border-radius: 4px;
    transition: all 0.3s;

    &:focus,
    &:hover,
    &:active {
      border: 1px solid transparent;
      outline: none;
      box-shadow: none;
      border-right-width: 1px;
    }
  }

  .rdw-image-left {
    .rdw-image-imagewrapper {
      text-align: left;
    }
  }

  .rdw-image-center {
    .rdw-image-imagewrapper {
      text-align: center;
    }
  }

  .rdw-image-right {
    .rdw-image-imagewrapper {
      text-align: right;
    }
  }

  .rdw-image-alignment-options-popup {
    bottom: 10px;
    font-size: 20px;
    line-height: 20px;
  }

  .rdw-image-alignment-option {
    height: auto;
    width: auto;
  }

  .wrapper-class {
    position: relative;
    width: 100%;
    display: inline-block;
    padding-bottom: ${toolboxFixedSize}px;
    height: calc(${draftFixedSize}px - ${toolboxFixedSize}px);
  }

  .editor-class {
    width: 100%;
    padding: 0 15px;
    background-color: #fff;
    height: calc(${draftFixedSize}px - ${toolboxFixedSize}px - 81px);
    ${(p) =>
    p.error
      ? `border: 1px solid ${p.theme.inputErrorColor};`
      : 'border-color: inherit;'}

    &.disabled {
      background-color: #f5f5f5;
      color: rgba(0, 0, 0, 0.25);
      cursor: not-allowed;
      opacity: 1;
    }

    .DraftEditor-root {
      position: relative;
      display: inline-block;
      width: 100%;

      .DraftEditor-editorContainer {
        position: relative;
        display: inline-block;
        width: 100%;

        .public-DraftEditor-content {
          color: ${TableTextColor};
          position: relative;
          display: inline-block;
          width: 100%;

          .public-DraftStyleDefault-block {
            margin: 0;
          }
        }
      }
    }
  }

  .toolbar-class {
    position: absolute;
    bottom: 0;
    width: auto;
    border: none;
    background-color: transparent;
    border-radius: 0px;
    margin: 0px;
    padding: 0px;
    height: ${toolboxFixedSize}px;
    z-index: 5;
    max-width: 400px;

    .rdw-option-wrapper {
      margin: 0px 2px;
      height: ${buttonsFixedSize}px;
      border: 1px solid transparent;
      border-radius: 2px;
      background-color: transparent;

      &:active {
        border: 1px solid #dedede;
        background-color: #dedede;
        box-shadow: none;
      }

      &:focus {
        border: 1px solid #dedede;
        box-shadow: none;
      }

      &:hover {
        border: 1px solid #dedede;
        box-shadow: none;
      }
    }

    .rdw-option-active {
      border: 1px solid #dedede;
      background-color: #dedede;
      box-shadow: none;
    }

    .rdw-inline-wrapper {
      margin: 0px;
    }
    .rdw-link-wrapper {
      margin: 0px;
    }
    .rdw-block-wrapper {
      margin: 0px;

      .rdw-dropdown-wrapper {
        background-color: transparent;
        height: ${buttonsFixedSize}px;
      }
    }
    .rdw-text-align-wrapper {
      margin: 0px;
    }
    .rdw-list-wrapper {
      margin: 0px;
    }
    .rdw-history-wrapper {
      margin: 0px;
    }

    .rdw-dropdown-selectedtext {
      color: ${TableTextColor};
    }
  }

  img {
    max-width: 100%;
  }

  .rdw-link-modal {
    height: 230px;
  }
`

export const ProductUploadImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${BorderColor};
  border-radius: 4px;
  height: 170px;
  margin-top: 22px;
`

export const ProductUploadImage = styled.img`
  width: 58px;
  height: 58px;
  opacity: 0.3;
`

export const ProductPriceInputBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: ${({ disabled }) => (disabled ? BorderColor : 'white')};
  border: 1px solid
    ${({ $hasError }) => ($hasError ? ErrorColor : BorderColor)};
  border-radius: 4px;
  padding: 5px 10px;
  cursor: text;
  width: 100%;
  width: ${({ $width }) => $width || '100%'};
  height: ${({ $height }) => $height || ' 36px'};

  &:hover,
  &:focus,
  &:visited,
  &:active {
    border: 1px solid ${PrimaryButtonColor};
    outline: none;
  }
`
export const ProductPriceInputStyled = styled.input`
  background: none;
  border: none;
  width: 100%;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0;
  }

  &[type=number] {
    -moz-appearance: textfield;
  }

  &:hover,
  &:focus,
  &:visited,
  &:active {
    border: none;
    outline: none;
  }

  ::placeholder {
    opacity: 0.6;
  }
  :-ms-input-placeholder {
    opacity: 0.6;
  }
  ::-ms-input-placeholder {
    opacity: 0.6;
  }
`

export const SwitchStyled = styled(Switch)`
  &&& {
    &.ant-switch-checked {
      background-color: ${PrimaryButtonColor};
    }
  }
`

export const AutocompleteContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  text-align: left;
`

export const StyledAutocompleteSearch = styled(TextInputStyled)`
  display: inline-block;
  width: calc(100% - 25px);
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  padding: 3px 8px;
  padding-right: 3px;
  height: 24px;
  line-height: 18px;
`

export const AutoCompleteIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  float: left;
  border: 1px solid
    ${({ $active }) => ($active ? PrimaryButtonColor : BorderColor)};
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  border-left: 0;
  height: 24px;
  cursor: pointer;
  background: ${({ $active }) =>
    $active ? PrimaryButtonColor : PageBackground};
  color: ${({ $active }) => ($active ? 'white' : TableTextColor)};
`

export const BoxIcon = styled.img`
  display: inline-block;
  width: 14px;
`

export const AutoCompleteMenu = styled.div`
  display: inline-block;
  z-index: 9999;
  position: absolute;
  left: 0;
  top: calc(100% + 3px);
  background: white;
  border-radius: 4px;
  max-height: 300px;
  overflow-y: auto;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  min-height: ${({ opened }) => (opened ? '0' : '30')}px;
  transform: ${({ opened }) => (opened ? 'scale(1, 1)' : 'scale(1, 0)')};
  transition: transform 0.2 ease-ease-in-out;
`

export const AutoCompleteOption = styled.div`
  padding: 5px 10px;
  cursor: pointer;
  font-weight: normal;
  opacity: 0.9;
  &:hover {
    background: #e6f7ff;
    opacity: 1;
  }
`

export const AutoCompleteEmpty = styled.div`
  width: 250px;
  padding: 5px 10px;
  font-weight: normal;
  font-size: 14px;
  opacity: 0.5;
  text-align: center;
  
  .anticon {
    display: block;
    margin: 10px 0;
    font-size: 46px;
    opacity: 0.5;
  }
`

export const SmallInputBox = styled.div`
  display: inline-block;
  width: 100%;
`

export const SmallTextStyled = styled.input`
  background: white;
  border: 1px solid
    ${({ hasError, editable }) =>
    hasError ? ErrorColor : editable ? PrimaryButtonColor : BorderColor};

  padding: 3px 8px;
  cursor: text;
  display: inline-block;
  width: 100%;
  height: 24px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.65);
  &:hover,
  &:focus,
  &:visited,
  &:active {
    outline: none;
  }
  border-radius: 4px;
  /* border-top-right-radius: 0;
  border-bottom-right-radius: 0; */

  ::placeholder {
    opacity: 0.4;
  }
  :-ms-input-placeholder {
    opacity: 0.4;
  }
  ::-ms-input-placeholder {
    opacity: 0.4;
  }
`

export const InputAction = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  float: right;
  background: ${({ editable }) => (editable ? PrimaryColor : '#d8d8d8')};
  border: 1px solid
    ${({ editable }) => (editable ? PrimaryColor : '#d8d8d8')};
  border-radius: 4px;
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  justify-content: center;
  align-items: center;
`

export const InputActionIcon = styled.img`
  width: 14px;
`

export const IconNumberIncrement = styled.div`
  cursor: pointer;
  padding-right: 7px;
  padding-left: 7px;
`
export const NumberIncrement = styled.input`
  height:25px;
  text-align: center;
  background: white;
  border: 1px solid
    ${({ hasError }) => (hasError ? ErrorColor : BorderColor)};
  border-radius: 4px;
  cursor: text;
  width: ${({ $width }) => $width || '54px'};
  max-width: 100%;
  &:hover,
  &:focus,
  &:visited,
  &:active {
    border: 1px solid ${PrimaryButtonColor};
    outline: none;
  }

  &:disabled {
    border: 1px solid ${BorderColor};
    background: #f5f5f5;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::placeholder {
    opacity: 0.4;
  }
  :-ms-input-placeholder {
    opacity: 0.4;
  }
  ::-ms-input-placeholder {
    opacity: 0.4;
  }

  &:disabled {
    cursor: not-allowed;
    border: 1px solid ${BorderColor};
  }
`

export const PriorityInputStyled = styled.input`
  width: ${({ $width }) => $width || 32}px;
  height: ${({ $height }) => $height || 20}px;
  background-color: ${({ $backgroundColor }) =>
    $backgroundColor || 'transparent'};
  border: 1px solid ${({ $border }) => $border || BorderColor};
  border-radius: 4px;
  text-align: center;
  line-height: ${({ $height }) => $height || 20}px;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:hover,
  &:focus,
  &:visited,
  &:active {
    outline: none;
  }
`

export const TableTextStyled = styled.input`
  background: white;
  border: 1px solid
    ${({ hasError }) => (hasError ? ErrorColor : BorderColor)};
  padding: 3px 8px;
  cursor: text;
  display: inline-block;
  width: 100%;
  height: 24px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.65);
  &:hover,
  &:focus,
  &:visited,
  &:active {
    outline: none;
    border: 1px solid ${PrimaryButtonColor};
  }
  border-radius: 4px;

  ::placeholder {
    opacity: 0.4;
  }
  :-ms-input-placeholder {
    opacity: 0.4;
  }
  ::-ms-input-placeholder {
    opacity: 0.4;
  }
`

export const HourInputStyled = styled(TimePicker)`
  border-radius: 4px;
  height: 36px;
  border-color: ${BorderColor};
  &&& {
    &.ant-picker-focused {
      border-color: ${PrimaryButtonColor};
      box-shadow: none;
    }
    &:hover,
    .ant-picker-focused {
      border-color: ${PrimaryButtonColor};
      box-shadow: none;
    }
    &.ant-picker-disabled {
      background: ${BorderColor};
      border-color: ${BorderColor};
      cursor: not-allowed;
    }

    .ant-picker-suffix {
      display: none;
    }
  }
`

const SelectRightSection = styled.div`
  position: absolute;
  top: ${({ $small }) => ($small ? -6 : -12)}px;
  right: -10px;
  background: #d8d8d8;
  height: ${({ $small }) => ($small ? 22 : 34)}px;
  width: ${({ $small }) => ($small ? 22 : 34)}px;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 2;
  font-size: 16px;
`

export const SelectLoading = styled(({ small }) => (
  <SelectRightSection $small={small}>
    <LoadingOutlined />
  </SelectRightSection>
))``

export const NotFoundContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-direction: column;
  font-size: 16px;

  .anticon {
    font-size: 46px;
    margin-bottom: 15px;
  }
`

export const EANTextInputStyled = styled(Input)`
  background: none;
  border: none;
  padding: 0px;
  cursor: text;
  display: inline-block;
  width: 100%;
  height: 0px;
  opacity: 0;
  
  
 
  &:hover,
  &:focus,
  &:visited,
  &:active {
    border: none;
    outline: none;
    background: none;
  }

  &:disabled {
    background: none;
    &:hover,
    &:focus,
    &:visited,
    &:active { 
      border: none;
      outline: none;
    }
  }
  
  ::placeholder {
    opacity: 0.4;
  }
  :-ms-input-placeholder {
    opacity: 0.4;
  }
  ::-ms-input-placeholder {
    opacity: 0.4;
  }
`
