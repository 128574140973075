import moment from 'moment'

import client from '../config/AxiosConfig'

export const GetDefaultSlotConfig = async () =>
  client.get('/Fitting/getDefaultSlotConfig')

export const GetCentersDropDown = async () =>
  client.get('/Fitting/listFittingCenters')

export const GetTailorCurrentSlots = async (center, tailor, date) =>
  client.get(
    `/Fitting/getSchedule/${center}/${tailor}?StartDate=${moment
      .utc(date)
      .startOf('week')
      .toISOString()}&EndDate=${moment
      .utc(date)
      .endOf('week')
      .toISOString()}`
  )

export const SaveFittingSlot = async (center, tailor, data) =>
  client.post(`/Fitting/postSlot/${center}/${tailor}`, data)

export const SaveAvailability = async (center, tailor, data) =>
  client.post(`/Fitting/postSchedule/${center}/${tailor}`, data)

export const DeleteFittingSlot = async (id) =>
  client.delete(`/Fitting/deleteSlot/${id}`)

export const GetNextAppointments = async (center, tailor, search) =>
  client.get(
    `/Fitting/getSchedule/NextAppointments/${center}/${tailor}?ItemsPerPage=10&Page=1&search=${search}`
  )

export const GetCenterDetail = async (id) =>
  client.get(`/Fitting/getViewDetail/${id}`)

export const GetCenterUsers = async (id) =>
  client.get(`/Fitting/listTailors/${id}`)

export const EditFittingCenter = async (id, data) =>
  client.put(`/Fitting/putViewDetail/${id}`, data)

export const CreateNewFittingCenter = async (data) =>
  client.post('/Fitting/createNewFittingCenter', data)

export const GetUsersAvailable = async () =>
  client.get('/Fitting/getTailorsDrop')

export const EditDefaultSlotConfig = async (data) =>
  client.put('/Fitting/putDefaultSlotConfig', data)

export const GetFittingCenters = async (search) =>
  client.get(`/Fitting/listFittingCenters?Search=${search}`)

export const DeleteTailors = async (id, userId) =>
  client.delete(`/Fitting/DeleteTailors/${id}/${userId}`)

export const AddTailors = async (id, data) =>
  client.post(`/Fitting/PostTailors/${id}`, data)

export const GetAppointment = async (id) =>
  client.get(`/Fitting/getDetailAppointment/${id}`)

export const SaveEmployeeMeasures = async (employeeId, data) =>
  client.put(`/Fitting/putDetailAppoitment/${employeeId}`, data)

export const ConcludeAppointment = async (id) =>
  client.put(`/Fitting/ConcludeAppointment/${id}`)

export const UpdateEmployeeProducts = async (data) =>
  client.post('/Fitting/UpdateEmployeeProducts', data)
