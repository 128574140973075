import React, {useEffect, useState} from 'react'

import {SearchOutlined} from '@ant-design/icons'
import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {
  reduxForm,
  FieldArray,
  Field,
  initialize,
  getFormValues,
  change
} from 'redux-form'

import {
  GetPendingSupplierOrders,
  CreateSupplierReception,
  CreateClientReception,
  CreateClientReturn
} from '../../../infra/requests/ArrivalRequests'
import {GetSuppliersList} from '../../../infra/requests/BaseRequests'
import {GetWarehousesByOwner} from '../../../infra/requests/LogisticsRequests'
import AlertService from '../../../shared/components/alert/AlertService'
import BackButton from '../../../shared/components/buttons/BackButton'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import AutocompleteInput from '../../../shared/components/inputs/AutocompleteInput'
import DateInput from '../../../shared/components/inputs/DateInput'
import DocumentsInput from '../../../shared/components/inputs/Documents/NewDocumentsInput'
import SelectInput from '../../../shared/components/inputs/SelectInput'
import BaseLoading from '../../../shared/components/loading/BaseLoading'
import {ArrayPushIfNotExist} from '../../../shared/logic/arrays/ArrayFunctions'
import {
  Margin,
  PageForm,
  PageTitle
} from '../../../shared/styles/BasicStyles'
import ArrivalFormValidation, {
  PopupValidation
} from './ArrivalFormValidation'
import ArrivalProducts from './ArrivalProducts'
import ConstructArrivalFormData, {
  FilterSendProducts
} from './ConstructArrivalFormData'
import SupplierOrderOption, {
  SupplierOrderSelected
} from './SupplierOrderOption'

const CreateArrival = ({
  router,
  dispatch,
  handleSubmit,
  arrivalTypes,
  returnReasons,
  clients,
  formValues,
  translate
}) => {
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [suppliers, setSuppliers] = useState([])
  const [ownerList, setOwnerList] = useState([])
  const [warehouses, setWarehouses] = useState([])
  const [possibleWarehouses, setPossibleWarehouses] = useState()

  useEffect(() => {
    const init = async () => {
      setOwnerList(clients)
      const suppliersData = await GetSuppliersList()
      setSuppliers(suppliersData.data?.items || [])
      setLoading(false)
    }
    init()
  }, [])

  const resetSelectionList = () => {
    setOwnerList(clients)
    setPossibleWarehouses()
  }

  const onSubmit = async (values) => {
    const result = FilterSendProducts(values)
    const errors = PopupValidation(result)

    if (errors.length) {
      return AlertService.error(
        translate('MISSING_INFORMATION'),
        <div>
          {errors.map((err, index) => (
            <div key={index}>{err}</div>
          ))}
        </div>
      )
    }

    setSaving(true)
    const payload = ConstructArrivalFormData(result)

    if (result.movementTypeId === '1') {
      const {success} = await CreateSupplierReception(payload)
      if (success) return router.history.push('/logistics#arrivals')
    }

    if (result.movementTypeId === '2') {
      const {success} = await CreateClientReception(payload)
      if (success) return router.history.push('/logistics#arrivals')
    }
    if (result.movementTypeId === '3') {
      const {success} = await CreateClientReturn(payload)
      if (success) return router.history.push('/logistics#arrivals')
    }

    return setSaving(false)
  }

  const handleWarehouseSearch = async (value) => {
    const {data, success} = await GetWarehousesByOwner(value)
    if (success) {
      setWarehouses(data || [])
      if (data && formValues.contactDetailId) {
        const exist = data.find(
          (w) => w.contactDetailId == formValues.contactDetailId
        )
        if (!exist) dispatch(change('arrival_form', 'contactDetailId', ''))
      }
    }
  }

  const handleTypeChange = async (value) => {
    dispatch(initialize('arrival_form', {movementTypeId: value}))
    if (value == 3) {
      dispatch(change('arrival_form', 'ownerId', '1'))
      handleWarehouseSearch('1')
    }
    resetSelectionList()
  }

  const handleSupplierOrderRequest = async (value) => {
    const {data, success} = await GetPendingSupplierOrders(value)
    if (success) return data?.items || []
    return []
  }

  const addSupplierOrderProducts = async (key, option) => {
    let supplier
    const availableClients = []
    const availableOwners = []
    const availableLocations = []
    const products = []

    if (option && option.orderSupplier) {
      option.orderSupplier.forEach((so) => {
        supplier = supplier || so.supplierId

        if (so?.orderPo?.orderB2b?.b2bclient) {
          ArrayPushIfNotExist(
            availableClients,
            so?.orderPo?.orderB2b?.b2bclient,
            (x) =>
              x.b2bclientId ===
              so?.orderPo?.orderB2b?.b2bclient?.b2bclientId
          )
        }

        if (so?.orderPo?.orderB2b?.stockOwner) {
          ArrayPushIfNotExist(
            availableOwners,
            so?.orderPo?.orderB2b?.stockOwner,
            (x) =>
              x.b2bclientId ===
              so?.orderPo?.orderB2b?.stockOwner?.b2bclientId
          )
        }

        if (so.deliveryContactDetailId) {
          ArrayPushIfNotExist(
            availableLocations,
            so.deliveryContactDetailId,
            (x) => x === so.deliveryContactDetailId
          )
        }

        products.push({
          orderSupplierId: so.orderSupplierId,
          barcode: so.product?.barCode,
          colorId: so.color?.colorId,
          color: so.color,
          description: so.product?.productTranslation,
          measureUnit: so.product?.measureUnit,
          productId: so.product?.productId,
          productWidthId: so.productWidth?.productWidthId,
          productWidth: so.productWidth,
          quantity: 0,
          orderQuantity: so.quantityOrdered,
          reference: so.product?.referenceCode,
          referenceClient: so.product?.referenceClient,
          sizeId: so.size?.sizeId,
          size: so.size,
          totalValue: so.priceNegotiated,
          client: so?.orderPo?.orderB2b?.b2bclient?.b2bclientId,
          owner: so?.orderPo?.orderB2b?.stockOwner?.b2bclientId,
          location: so.deliveryContactDetailId
        })
      })

      if (products.length) {
        dispatch(change('arrival_form', 'items', products))
      }

      if (availableClients.length) {
        dispatch(
          change(
            'arrival_form',
            'clientId',
            availableClients[0].b2bclientId
          )
        )
      }
      if (availableOwners.length) {
        setOwnerList(availableOwners)
        dispatch(
          change('arrival_form', 'ownerId', availableOwners[0].b2bclientId)
        )

        const {data, success} = await GetWarehousesByOwner(
          availableOwners[0].b2bclientId
        )
        if (success) setWarehouses(data || [])

        if (availableLocations.length) {
          setPossibleWarehouses(availableLocations)
          const found = data.find(
            (wh) => wh.contactDetailId === availableLocations[0]
          )
          if (found) {
            dispatch(
              change('arrival_form', 'warehouseId', found.warehouseId)
            )
            dispatch(change('arrival_form', 'warehouse', found))
          }
        }
      }

      dispatch(change('arrival_form', 'supplierId', supplier))
    }
  }

  const filterWarehousesList = () => {
    if (possibleWarehouses && possibleWarehouses.length) {
      return warehouses.filter((wh) =>
        possibleWarehouses.includes(wh.contactDetailId)
      )
    }
    return warehouses
  }

  const addWarehouseObject = (warehouseId) => {
    const found = warehouses.find((wh) => wh.warehouseId == warehouseId)
    dispatch(change('arrival_form', 'warehouse', found))
  }

  const handleOwnerList = () => {
    if (formValues?.movementTypeId == 2) {
      return ownerList.filter((owner) => owner.b2bclientId != 1)
    }
    return ownerList
  }

  const handleClientList = () => {
    if (formValues?.movementTypeId == 3) {
      return ownerList.filter((owner) => owner.b2bclientId != 1)
    }
    return ownerList
  }

  if (loading) return <BaseLoading margin='200' />

  return (
    <PageForm
      novalidate
      autocomplete='off'
      onSubmit={handleSubmit(onSubmit)}
    >
      <Row align='bottom' gutter={[0, 50]}>
        <Col xs={24}>
          <BackButton
            label={<Translate id='BACK_TO_LOGISTICS' />}
            onClick={() => router.history.push('/logistics#arrivals')}
          />
        </Col>
        <Col xs={12}>
          <PageTitle>
            <Translate id='CREATE_ARRIVAL' />
          </PageTitle>
        </Col>
        <Col xs={12}>
          <BaseButton
            type='primary'
            auto
            htmlType='submit'
            loading={saving}
            disabled={saving}
            style={{float: 'right'}}
          >
            <Translate id='CREATE' />
          </BaseButton>
        </Col>
      </Row>
      <Margin size='30' />
      <Row gutter={[40, 20]}>
        <Col xs={6} offset={18}>
          <FieldArray component={DocumentsInput} name='attachments' />
        </Col>
        <Col xs={6}>
          <Field
            allowClear={false}
            component={SelectInput}
            name='movementTypeId'
            label={<Translate id='ARRIVAL_TYPE' />}
            placeholder={<Translate id='ARRIVAL_TYPE' />}
            data={arrivalTypes.filter((a) => a.movementTypeId != 2)}
            dataKey='movementTypeId'
            dataLabel='movementTypeTranslation'
            afterChange={handleTypeChange}
          />
        </Col>
        <Col xs={6}>
          <Field
            label={<Translate id='DATE' />}
            placeholder={translate('ARRIVAL_DATE')}
            name='date'
            component={DateInput}
          />
        </Col>
        <Col xs={12}>
          <Field
            disabled={formValues?.movementTypeId !== '1'}
            component={AutocompleteInput}
            name='supplierOrderSetId'
            label={<Translate id='SUPPLIER_ORDERS' />}
            placeholder={<Translate id='SELECT_SUPPLIER_ORDER' />}
            notFoundMessage={<Translate id='SEARCH_FOR_SUPPLIER_ORDERS' />}
            handleRequest={handleSupplierOrderRequest}
            dataKey='orderSupplierSetId'
            dataLabel='orderSupplierSetId'
            afterChange={addSupplierOrderProducts}
            renderLabel={SupplierOrderOption}
            renderSelected={SupplierOrderSelected}
          />
        </Col>
        <Col xs={6}>
          <Field
            disabled={
              !formValues?.movementTypeId ||
              formValues?.movementTypeId === '1'
            }
            allowClear={false}
            component={SelectInput}
            name='supplierId'
            label={<Translate id='SUPPLIER' />}
            placeholder={<Translate id='SELECT_SUPPLIER' />}
            data={suppliers}
            dataKey='supplierId'
            dataLabel='name'
          />
        </Col>
        <Col xs={4}>
          <Field
            disabled={
              !formValues?.movementTypeId ||
              formValues?.movementTypeId == 3 ||
              (formValues?.movementTypeId === '1' &&
                !formValues?.supplierOrderSetId)
            }
            allowClear={false}
            component={SelectInput}
            name='ownerId'
            label={<Translate id='OWNER' />}
            placeholder={<Translate id='SELECT_OWNER' />}
            data={handleOwnerList()}
            dataKey='b2bclientId'
            dataLabel='name'
            afterChange={handleWarehouseSearch}
          />
        </Col>
        <Col xs={4}>
          <Field
            disabled={
              !formValues?.movementTypeId ||
              (formValues?.movementTypeId === '1' &&
                !formValues?.supplierOrderSetId)
            }
            allowClear={false}
            component={SelectInput}
            name='clientId'
            label={<Translate id='CLIENT' />}
            placeholder={<Translate id='SELECT_CLIENT' />}
            data={handleClientList()}
            dataKey='b2bclientId'
            dataLabel='name'
          />
        </Col>
        <Col xs={6}>
          <Field
            disabled={
              !formValues?.movementTypeId ||
              (formValues?.movementTypeId === '1' &&
                !formValues?.supplierOrderSetId)
            }
            allowClear={false}
            component={SelectInput}
            name='warehouseId'
            label={<Translate id='WAREHOUSE' />}
            placeholder={<Translate id='SELECT_WAREHOUSE' />}
            data={filterWarehousesList()}
            dataKey='warehouseId'
            dataLabel='name'
            renderLabel={(option) =>
              option?.warehouseName || option?.contactName
            }
            notFoundIcon={<SearchOutlined />}
            notFoundMessage={<Translate id={formValues?.ownerId ? 'NO_AVAILABLE_WAREHOUSES' : 'SELECT_A_OWNER_FIRST'} />}
            afterChange={addWarehouseObject}
          />
        </Col>
        <Col xs={4}>
          <Field
            disabled={formValues?.movementTypeId !== '3'}
            allowClear={false}
            component={SelectInput}
            name='returnReasonId'
            label={<Translate id='RETURN_REASON' />}
            placeholder={<Translate id='SELECT_RETURN_REASON' />}
            data={returnReasons}
            dataKey='returnReasonId'
            dataLabel='returnReasonTranslation'
          />
        </Col>
      </Row>
      <Margin size='30' />
      <Row>
        <Col xs={24}>
          <FieldArray
            component={ArrivalProducts}
            name='items'
            client={formValues?.clientId}
            owner={formValues?.ownerId}
            warehouse={formValues?.warehouse}
          />
        </Col>
      </Row>
    </PageForm>
  )
}

CreateArrival.propTypes = {
  router: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  handleSubmit: PropTypes.func,
  formValues: PropTypes.object,
  arrivalTypes: PropTypes.array.isRequired,
  returnReasons: PropTypes.array.isRequired,
  clients: PropTypes.array.isRequired
}

CreateArrival.defaultProps = {
  dispatch: undefined,
  handleSubmit: undefined,
  formValues: {}
}

const CreateArrivalPage = withLocalize(reduxForm({
  form: 'arrival_form',
  validate: ArrivalFormValidation
})(CreateArrival))

export default connect((state) => ({
  clients: state.info.clients,
  arrivalTypes: state.info.arrivalTypes,
  returnReasons: state.info.returnReasons,
  formValues: getFormValues('arrival_form')(state)
}))(CreateArrivalPage)
