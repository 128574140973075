import client from '../config/AxiosConfig'

export const GetSupplierOrders = async (page, pageSize, filters) =>
  client.get(`/OrderSupplier/GetOrderSupplierSets?ItemsPerPage=${pageSize}&Page=${page}${filters}`)

export const RevertOrderPODraft = async (orderPoToRevert) =>
  client.delete(`/OrderPO/RevertOrderPODraft/${orderPoToRevert}`)

export const RevertOrderNeedsCreation = async (orderPoId) =>
  client.delete(`/OrderPO/RevertOrderNeedsCreation/${orderPoId}`)

export const RevertOrderSuplierCreation = async (orderSupplierSetId) =>
  client.delete(`/OrderSupplier/RevertOrderSupplierCreation/${orderSupplierSetId}`)

export const RevertSupplierSetToConsolidation = async (orderSupplierSetId) =>
  client.delete(`/OrderSupplier/RevertSupplierSetToConsolidation/${orderSupplierSetId}`)

export const RevertOrderSupplierSetToProcessingPO = async (orderSupplierSetId) =>
  client.delete(`/OrderSupplier/RevertOrderSupplierSetToProcessingPO/${orderSupplierSetId}`)

export const GetResendOrderSupplierSets = async (data) =>
  client.put(`/OrderSupplier/ResendOrderSupplierSets`, data)

export const UpdateSupplierOrder = async (id) =>
  client.put(`/OrderSupplier/UpdateOrderSupplier/${id}`)

export const UpdateOrderSupplierDate = async (id, data) =>
  client.put(`/OrderSupplier/UpdateOrderSupplierDate/${id}`, data)

export const EditMovement = async (movementId, data) =>
  client.put(`/Movements/EditMovement/${movementId}`, data)

