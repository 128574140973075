import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { Translate, withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getFormValues, reduxForm, initialize } from 'redux-form';

import Trash from '../../../assets/icons/remove_white.svg';
import {
  CreateSupplier,
  DeleteSupplier,
  EditSupplier,
  GetSupplierDetail
} from '../../../infra/requests/SupplierRequests';
import FormValidator from '../../../infra/services/validations/FormValidator';
import AlertService from '../../../shared/components/alert/AlertService';
import BackButton from '../../../shared/components/buttons/BackButton';
import BaseButton from '../../../shared/components/buttons/BaseButton';
import DeleteButton from '../../../shared/components/buttons/DeleteButton';
import BaseLoading from '../../../shared/components/loading/BaseLoading';
import {
  Margin,
  PageForm,
  PageTitle,
  StyledTabPane,
  StyledTabs,
  StyledTabTitle,
  StyledTabTitleContainer
} from '../../../shared/styles/BasicStyles';
import InformationComponent from './components/InformationComponent';
import SupplierServices from './components/SupplierServices';

const validations = (values) => {
  const errors = FormValidator.make({
    name: 'required',
    vatnumber: 'required',
    currencyId: 'required',
  })(values);

  return errors;
};

const ManageSupplier = ({
  router,
  handleSubmit,
  dispatch,
  currentFormValues,
  services,
  countries,
  currencies,
  translate
}) => {
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    const getInfo = async () => {
      if (id && id !== 'new') {
        const { data, success } = await GetSupplierDetail(id);
        if (success) {
          dispatch(initialize('manage_supplier', {
            ...data,
            telephone: data?.contact?.contactDetail[0]?.telephone,
            mobile: data?.contact?.contactDetail[0]?.mobile,
            languageId: data?.language?.languageId,
            currencyId: data?.currencyId || 1 // Ensure default value of 1 if not provided
          }));
        } else {
          dispatch(initialize('manage_supplier', { currencyId: 1 }));  // Define default value for new supplier
        }
      } else {
        dispatch(initialize('manage_supplier', { currencyId: 1 }));  // Default for new supplier
      }
      setLoading(false);
    };
    getInfo();
  }, [dispatch, id]);

  const onSubmit = async (values) => {
    try {
      if (!Array.isArray(values.contact?.contactDetail) || !values.contact.contactDetail[0] || !values.contact.contactDetail[0].addressLine1) {
        return AlertService.error(translate('ERROR'), translate('INSERT_ADDRESS_ERROR'));
      }

      const payload = { ...values };
      payload.contact.contactDetail[0].telephone = payload.telephone;
      payload.contact.contactDetail[0].mobile = payload.mobile;

      const objectSup = [];

      if (values?.supplierService?.length > 0) {
        values.supplierService.forEach((element) => {
          if (element.active === true) {
            objectSup.push({ serviceId: element.service.serviceId });
          }
        });
      }

      payload.supplierService = objectSup;

      if (id && id !== 'new') {
        const { success } = await EditSupplier(id, payload);

        if (success) {
          AlertService.success(
            translate('SUCCESS'),
            translate('SUPPLIER_EDITED_SUCCESSFULLY')
          );
          router.history.push('/suppliers#suppliers');
        }
      } else {
        const { success } = await CreateSupplier(payload);

        if (success) {
          AlertService.success(
            translate('SUCCESS'),
            translate('SUPPLIER_CREATED_SUCCESSFULLY')
          );
          router.history.push('/suppliers#suppliers');
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteSupplier = async () => {
    try {
      const { success } = await DeleteSupplier(id);

      if (success) {
        AlertService.success(
          translate('SUCCESS'),
          translate('SUPPLIER_DELETED_SUCCESSFULLY')
        );
        router.history.push('/suppliers#suppliers');
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) return <BaseLoading />;

  return (
    <PageForm onSubmit={handleSubmit(onSubmit)}>
      <Row gutter={[0, 20]}>
        <Col xs={24}>
          <BackButton
            label={<Translate id='BACK_SUPPLIERS' />}
            onClick={() => router.history.push('/suppliers#suppliers')}
          />
        </Col>
        <Col xs={12}>
          <PageTitle>{currentFormValues.name}</PageTitle>
        </Col>
        <Col xs={12}>
          <div style={{ textAlign: 'right' }}>
            <BaseButton
              onClick={handleSubmit(onSubmit)}
              type='primary'
              auto
            >
              <Translate id='SAVE' />
            </BaseButton>
          </div>
        </Col>
        <Margin size={30} />
        {id !== 'new' && (
          <>
            <Row>
              <Col>
                <DeleteButton
                  imagePath={Trash}
                  type='error'
                  auto
                  onClick={deleteSupplier}
                >
                  <Translate id='DELETE_SUPPLIER' />
                </DeleteButton>
              </Col>
            </Row>
            <Margin size={38} />
          </>
        )}
        <Col xs={24}>
          <StyledTabs>
            <StyledTabPane
              tab={
                <StyledTabTitleContainer>
                  <StyledTabTitle>
                    <Translate id='INFORMATION' />
                  </StyledTabTitle>
                </StyledTabTitleContainer>
              }
              key={1}
            >
              <InformationComponent countries={countries} currencies={currencies} />
            </StyledTabPane>
            <StyledTabPane
              tab={
                <StyledTabTitleContainer>
                  <StyledTabTitle>
                    <Translate id='SERVICES' />
                  </StyledTabTitle>
                </StyledTabTitleContainer>
              }
              key={2}
            >
              <SupplierServices services={services} />
            </StyledTabPane>
          </StyledTabs>
        </Col>
      </Row>
    </PageForm>
  );
};

ManageSupplier.propTypes = {
  router: PropTypes.object.isRequired,
  currentFormValues: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  services: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired,
  currencies: PropTypes.array.isRequired,
  translate: PropTypes.func.isRequired,
};

ManageSupplier.defaultProps = {
  currentFormValues: {},
};

const ManageSupplierPage = reduxForm({
  form: 'manage_supplier',
  validate: validations,
})(ManageSupplier);

export default withLocalize(
  connect((state) => ({
    currentFormValues: getFormValues('manage_supplier')(state),
    services: state.info.services,
    currencies: state.info.currencies,
    countries: state.info.countries,
  }))(ManageSupplierPage)
);
