import React, {useState, useEffect} from 'react'

import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'

import DropdownIcon from '../../../../assets/icons/dropdown_blue.svg'
import {GetOrderPO} from '../../../../infra/requests/OrdersB2BRequests'
import CollapsableHeaderTitle from '../../../../pages/suppliers/components/CollapsableHeaderTitle'
import {
  POBaseContainer,
  POHeaderCollapsable,
  POLeftContainer,
  POTableContainer,
  POTableFooter,
  POTotalSection,
  FooterLine
} from '../../../../pages/suppliers/components/POStyles'
import RoundCurrency from '../../../logic/numbers/RoundCurrency'
import ActiveTranslation from '../../../logic/translations/ActiveTranslation'
import {ToggleImage} from '../../../styles/BasicStyles'
import BaseLoading from '../../loading/BaseLoading'
import BaseTable from '../../table/BaseTable'
import {EmptyMessage} from './ProductsStyles'

const InternalPOTable = ({order}) => {
  const [poList, setList] = useState([])
  const [open, setOpen] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const init = async () => {
      const {data, success} = await GetOrderPO(order)
      if (success) setList(data?.items || [])
      setLoading(false)
    }
    init()
  }, [])

  const calculateTotal = (PO) => {
    let total = 0
    PO.orderPoitem.forEach((item) => {
      if (item.currencyId !== 1) {
        total = parseFloat(total) + parseFloat(item.quantity * item.unitPrice * item.exchangeRateUsed)
      } else {
        total = parseFloat(total) + parseFloat(item.quantity * item.unitPrice)
      }
    })
    return RoundCurrency(total)
  }

  const renderColumns = (PO) => {
    const columns = [
      {
        type: 'text',
        dataIndex: 'sku',
        title: <Translate id='REF_CODE' />
      },
      {
        type: 'text',
        title: <Translate id='DESCRIPTION' />,
        dataIndex: 'productCombination',
        render: (value) => (
          <ActiveTranslation
            value={value?.product?.productTranslation}
            tag='name'
          />
        )
      },
      {
        type: 'text',
        title: <Translate id='COLOR' />,
        dataIndex: 'productCombination',
        render: (value) => (
          <ActiveTranslation
            value={value?.color?.colorTranslation}
            tag='name'
          />
        )
      },
      {
        type: 'text',
        title: <Translate id='SIZE' />,
        dataIndex: 'productCombination',
        render: (value) => value?.size?.number
      },
      {
        type: 'text',
        dataIndex: 'productCombination',
        title: <Translate id='UM' />,
        render: (value) => value?.product?.measureUnit?.code
      },
      {
        type: 'text',
        dataIndex: 'quantity',
        title: <Translate id='C_ORDER_QTY' />
      },
      {
        type: 'text',
        dataIndex: 'productCombination',
        title: <Translate id='STOCK_QTY' />,
        render: (value) => value?.product?.stock
      },
      {
        type: 'text',
        dataIndex: 'reserve',
        title: <Translate id='RESERVE' />
      },
      {
        type: 'text',
        title: <Translate id='PO_QTY' />,
        dataIndex: 'quantity'
      },
      {
        type: 'text',
        dataIndex: 'unitPrice',
        title: <Translate id='PRICE_EUROS' />,
        render: (value, row) => `${RoundCurrency(value * row.exchangeRateUsed)}€`
      },
      {
        type: 'text',
        title: <Translate id='SUB_TOTAL_EUROS' />,
        render: (text, row) => `${RoundCurrency(row.unitPrice * row.quantity * row.exchangeRateUsed)}€`
      }
    ]

    const otherCurrency = PO.orderPoitem.find((i) => i.currencyId !== 1)

    if (otherCurrency) {
      columns.push({
        title: <Translate id='PRICE' />,
        dataIndex: 'unitPrice',
        render: (value, row) => row.currencyId !== 1
          ? `${RoundCurrency(value)} ${row?.currency?.code}`
          : '-'
      })
      columns.push({
        title: <Translate id='SUB_TOTAL' />,
        render: (data) => data.currencyId !== 1
          ? `${RoundCurrency(data.quantity * data.unitPrice)} ${data?.currency?.code}`
          : '-'
      })
    }
    return columns
  }

  const openPO = (index) => {
    if (index === open) {
      setOpen()
    } else {
      setOpen(index)
    }
  }

  if (loading) return <BaseLoading margin='30' />

  if (poList.length === 0) {
    return (
      <EmptyMessage>
        <Translate id='NO_INTERNAL_PURCHASE_ORDERS' />
      </EmptyMessage>
    )
  }

  return poList.map((PO, index) => (
    <Row key={index}>
      <Col xs={24}>
        <POBaseContainer $jointly>
          <POHeaderCollapsable onClick={() => openPO(index)}>
            <POLeftContainer>
              <ToggleImage
                $marginTop
                src={DropdownIcon}
                $open={open === index}
              />

              <CollapsableHeaderTitle
                name={<Translate id='REF_SKYPRO' />}
                description={PO?.product?.referenceCode || 'N/A'}
              />
              <CollapsableHeaderTitle
                name={<Translate id='DESCRIPTION' />}
                description={
                  <ActiveTranslation
                    value={PO?.product?.productTranslation}
                    tag='name'
                  />
                }
              />
              <CollapsableHeaderTitle
                name={<Translate id='TYPE' />}
                description={
                  <ActiveTranslation
                    value={
                      PO?.product?.productType?.productTypeTranslation
                    }
                    tag='name'
                  />
                }
              />
              <CollapsableHeaderTitle
                name={<Translate id='CLIENT' />}
                description={PO?.orderB2b?.b2bclient?.name}
              />
              <CollapsableHeaderTitle
                name={<Translate id='MOQ' />}
                description={PO.product?.moq || 0}
              />
              <CollapsableHeaderTitle
                name={<Translate id='MAQ' />}
                description={PO.product?.maq || 0}
              />
              <CollapsableHeaderTitle
                name={<Translate id='STOCK_TOTAL' />}
                description={PO?.product?.stock || 0}
              />
              <CollapsableHeaderTitle
                name={<Translate id='TOTAL_ORDER_QTY' />}
                description={PO?.totalQuantity}
              />
              <CollapsableHeaderTitle
                name={<Translate id='TOTAL_PRICE_EUROS' />}
                description={`${calculateTotal(PO)}€`}
                last
              />
            </POLeftContainer>
          </POHeaderCollapsable>
          <POTableContainer open={open === index}>
            {open === index && (
              <BaseTable
                rowKey='orderPoitemId'
                columns={renderColumns(PO)}
                datasource={PO.orderPoitem}
                pagination={{render: false}}
                scroll={{}}
              />
            )}
            <POTableFooter>
              <POTotalSection>
                <FooterLine>
                  <Translate id='TOTAL_ORDER_QTY' />:{' '}
                  <span>{PO?.totalQuantity}</span>
                </FooterLine>
                <FooterLine bold>
                  <Translate id='TOTAL_PRICE' />:{' '}
                  <span>{calculateTotal(PO)}€</span>
                </FooterLine>
              </POTotalSection>
            </POTableFooter>
          </POTableContainer>
        </POBaseContainer>
      </Col>
    </Row>
  ))
}

InternalPOTable.propTypes = {
  order: PropTypes.string.isRequired
}

export default InternalPOTable
