import React, { useState } from 'react'

import { PropTypes } from 'prop-types'
import { Translate } from 'react-localize-redux'

import SelectInput from '../../../shared/components/inputs/SelectInput'
import BaseModal from '../../../shared/components/modal/BaseModal'
import { FittingModalInputContainer, ModalLabelContainer, SlotDetailsSpan } from '../FittingStyles'

const ScheduleFittingModal = ({ open, onClose, date, slot, employees, onScheduleFittingSubmit }) => {
  const [selectedEmployeeId, setSelectedEmployeeId] = useState()
  const handleSubmit = () => {
    const selectedEmployee = employees.find((e) => e.employeeId === parseInt(selectedEmployeeId, 10))
    onScheduleFittingSubmit(selectedEmployee, slot)
    setSelectedEmployeeId(null)
    onClose()
  }

  return (
    <BaseModal
      okText={<Translate id='SAVE' />}
      onOk={handleSubmit}
      title={<Translate id='SCHEDULE_FITTING' />}
      open={open}
      onClose={onClose}
      hasMinHeight={false}
    >
      {open &&
        <ModalLabelContainer>
          <FittingModalInputContainer>
            <SelectInput
              input={{ value: selectedEmployeeId, onChange: setSelectedEmployeeId }}
              label={<Translate id='SELECT_EMPLOYEE' />}
              data={employees}
              dataLabel='fullName'
              dataKey='employeeId'
            />
          </FittingModalInputContainer>
          <SlotDetailsSpan><Translate id='APPOINTMENT_DATE_AND_TIME' data={{ date }} /> </SlotDetailsSpan>
        </ModalLabelContainer>
      }
    </BaseModal>
  )
}

ScheduleFittingModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  date: PropTypes.string,
  employees: PropTypes.array.isRequired,
  onScheduleFittingSubmit: PropTypes.func.isRequired,
  slot: PropTypes.string
}

ScheduleFittingModal.defaultProps = {
  date: undefined,
  slot: undefined
}

export default ScheduleFittingModal
