export const NewOrderStatusList = (statusList) =>
  statusList.filter((s) =>
    s.orderStatusId == 1 ||
    s.orderStatusId == 3 ||
    s.orderStatusId == 4 ||
    s.orderStatusId == 7 ||
    s.orderStatusId == 8 ||
    s.orderStatusId == 9)

export const BackOrderStatusList = (statusList) => statusList.filter((s) => s.orderStatusId == 2)

export const HistoricalOrderStatusList = (statusList) =>
  statusList.filter((s) => s.orderStatusId == 5 ||
    s.orderStatusId == 6 ||
    s.orderStatusId == 10 ||
    s.orderStatusId == 11)
