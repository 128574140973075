import React from 'react'

import PropTypes from 'prop-types'

import {
  EditOrderTitleContainer,
  EditOrderName,
  EditOrderDescription
} from './Styles'

const EditOrderHeaderTitle = ({
  label,
  value,
  valueColor,
  last,
  margin
}) => (
  <EditOrderTitleContainer $margin={margin} $last={last}>
    <EditOrderName>{label}</EditOrderName>
    <EditOrderDescription $color={valueColor}>
      {value}
    </EditOrderDescription>
  </EditOrderTitleContainer>
)

EditOrderHeaderTitle.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number
  ]),
  value: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number
  ]),
  last: PropTypes.bool,
  margin: PropTypes.bool,
  valueColor: PropTypes.string
}

EditOrderHeaderTitle.defaultProps = {
  label: '',
  value: '',
  last: false,
  margin: false,
  valueColor: undefined
}

export default EditOrderHeaderTitle
