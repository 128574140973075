import React from 'react'

import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'
import styled from 'styled-components'

import {
  TableRow,
  ListCol,
  TableTitle,
  TextTooltip
} from '../../../../../shared/components/table/CustomTableStyles'
import ActiveTranslation from '../../../../../shared/logic/translations/ActiveTranslation'

const DropdownList = styled.div`
  display: inline-block;
  transform: ${({$open}) => ($open ? 'scale(1,1)' : 'scale(1,0)')};
  height: ${({$open}) => ($open ? 'auto' : '0')};
  transform-origin: top center;
  margin-top: 5px;
  margin-bottom: -9px;
  margin-left: -1px;
  margin-right: -1px;
  width: calc(100% + 2px);
`

const ItemsInBox = ({box}) => {
  const products = box?.items || []
  if (!box.open) return null

  return (
    <DropdownList xs={24} $open={box.open}>
      <TableRow $header>
        <ListCol xs={4}>
          <TableTitle first subtitle text={<Translate id='REFERENCE' />} />
        </ListCol>
        <ListCol xs={3}>
          <TableTitle subtitle text={<Translate id='EAN_CODE' />} />
        </ListCol>
        <ListCol xs={2}>
          <TableTitle subtitle text={<Translate id='CLIENT_REF' />} />
        </ListCol>
        <ListCol xs={5}>
          <TableTitle subtitle text={<Translate id='DESCRIPTION' />} />
        </ListCol>
        <ListCol xs={2}>
          <TableTitle subtitle text={<Translate id='COLOR' />} />
        </ListCol>
        <ListCol xs={2}>
          <TableTitle subtitle text={<Translate id='SIZE' />} />
        </ListCol>
        <ListCol xs={2}>
          <TableTitle subtitle text={<Translate id='WIDTH' />} />
        </ListCol>
        <ListCol xs={2}>
          <TableTitle subtitle text={<Translate id='SEND_QUANTITY' />} abbr={<Translate id='SEND_QTY' />} />
        </ListCol>
        <ListCol xs={2}>
          <TableTitle subtitle text={<Translate id='BOX_QUANTITY' />} abbr={<Translate id='BOX_QTY' />} />
        </ListCol>
      </TableRow>

      {products.map((product, index) => (
        <TableRow key={index} $error={product.error}>
          <ListCol xs={4}>
            <TextTooltip first text={product.reference || '-'} />
          </ListCol>
          <ListCol xs={3}>
            <TextTooltip text={product.barcode || '-'} />
          </ListCol>
          <ListCol xs={2}>
            <TextTooltip text={product.referenceClient || '-'} />
          </ListCol>
          <ListCol xs={5}>
            <TextTooltip
              text={
                <ActiveTranslation
                  value={product.description}
                  tag='name'
                />
              }
            />
          </ListCol>
          <ListCol xs={2}>
            <TextTooltip
              text={
                <ActiveTranslation
                  value={product?.color?.colorTranslation || '-'}
                  tag='name'
                />
              }
            />
          </ListCol>
          <ListCol xs={2}>
            <TextTooltip text={product?.size?.nameEu || '-'} />
          </ListCol>
          <ListCol xs={2}>
            <TextTooltip text={product?.productWidth?.code || '-'} />
          </ListCol>
          <ListCol xs={2}>
            <TextTooltip align='center' text={product?.departQty || '-'} />
          </ListCol>
          <ListCol xs={2}>
            <TextTooltip align='center' text={product?.quantity || '-'} />
          </ListCol>
        </TableRow>
      ))}
    </DropdownList>
  )
}

ItemsInBox.propTypes = {
  box: PropTypes.object.isRequired
}

export default ItemsInBox
