import React, {useState} from 'react'

import {Col} from 'antd'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import CheckboxInput from '../inputs/CheckboxInput'
import {CheckBoxInputBox} from '../inputs/InputStyles'
import SearchInput from '../inputs/SearchInput'

let timeout

const FilterSection = styled.div`
  display: flex;
  height: 34px;
  align-items: center;

  ${CheckBoxInputBox} {
    margin-right: 20px;
  }
`

const PermissionsFilters = ({handleSearch, initialFilters}) => {
  const [filters, setFilters] = useState(initialFilters)

  const setFilter = (label, value, time) => {
    const newFilters = {...filters}
    newFilters[label] = value
    setFilters(newFilters)
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(() => handleSearch(newFilters), time)
  }

  return (
    <>
      <Col xs={6}>
        <SearchInput
          input={{
            value: filters.search,
            onChange: (value) => setFilter('search', value, 500)
          }}
          placeholder='Search functionality'
        />
      </Col>
      <Col xs={18}>
        <FilterSection>
          <CheckboxInput
            input={{
              value: filters.used,
              onChange: (value) => setFilter('used', value, 0)
            }}
            label='In Use'
          />
          <CheckboxInput
            input={{
              value: filters.see,
              onChange: (value) => setFilter('see', value, 0)
            }}
            label='See'
          />
          <CheckboxInput
            input={{
              value: filters.edit,
              onChange: (value) => setFilter('edit', value, 0)
            }}
            label='Edit'
          />
          <CheckboxInput
            input={{
              value: filters.create,
              onChange: (value) => setFilter('create', value, 0)
            }}
            label='Create'
          />
          <CheckboxInput
            input={{
              value: filters.delete,
              onChange: (value) => setFilter('delete', value, 0)
            }}
            label='Delete'
          />
        </FilterSection>
      </Col>
    </>
  )
}

PermissionsFilters.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  initialFilters: PropTypes.object
}

PermissionsFilters.defaultProps = {
  initialFilters: {search: '', used: false, see: false, edit: false, create: false, delete: false}
}

export default PermissionsFilters
