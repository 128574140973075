import React from 'react'

import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'

import BaseButton from '../buttons/BaseButton'
import {
  AddressContainer,
  AddressName,
  AddressDescription,
  ButtonSection
} from './AddressStyles'

const AddressItem = ({
  address,
  index,
  countries,
  onDelete,
  openAddress,
  translate
}) => {
  const getCountry = (id) => {
    const found = countries.find((c) => c.countryID == id)
    return found?.name
  }

  return (
    <AddressContainer>
      <AddressName>
        {address?.name || `${translate('ADDRESS')} ${index + 1}`}
      </AddressName>
      <AddressDescription>
        {address?.addressLine1} {address?.addressLine2}{' '}
        {address?.addressLine3} {address?.streetNumber}
      </AddressDescription>
      <AddressDescription>
        {address?.zipCode} {address?.city} {address?.region}
      </AddressDescription>
      <AddressDescription>
        {getCountry(address?.countryId)}
      </AddressDescription>
      <ButtonSection>
        {openAddress && (
          <BaseButton
            type='primary'
            size='small'
            onClick={() => openAddress(address, index)}
            auto
          >
            <Translate id='EDIT' />
          </BaseButton>
        )}
        {onDelete && (
          <BaseButton
            type='secondary'
            size='small'
            onClick={() => onDelete(index)}
            auto
          >
            <Translate id='DELETE' />
          </BaseButton>
        )}
      </ButtonSection>
    </AddressContainer>
  )
}

AddressItem.propTypes = {
  address: PropTypes.object.isRequired,
  index: PropTypes.number,
  onDelete: PropTypes.func,
  openAddress: PropTypes.func,
  countries: PropTypes.array.isRequired
}

AddressItem.defaultProps = {
  index: 0,
  openAddress: undefined,
  onDelete: undefined
}

export default withLocalize(AddressItem)
