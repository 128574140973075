import React from 'react'
import BaseButton from '../../shared/components/buttons/BaseButton'
import {
  StyledNotFoundContainer,
  StyledTitle,
  StyledDescription,
  StyledNotFoundLogo,
  StyledNotFoundGroup,
  MarginBackNotFound
} from './NotFoundStyles'
import page_not_found from '../../assets/page_not_found.svg'
import {Margin} from '../../shared/styles/BasicStyles'
import {Translate} from 'react-localize-redux'

const NotFound = ({history}) => (
  <StyledNotFoundContainer>
    <StyledNotFoundGroup>
      <StyledNotFoundLogo src={page_not_found} />
      <Margin size={30} />
      <StyledTitle>
        <Translate id='OOPS_PAGE_NOT_FOUND' />
      </StyledTitle>
      <Margin size={30} />
      <StyledDescription>
        <Translate id='PAGE_NOT_FOUND_DESCRIPTION_1' /> <br />{' '}
        <Translate id='PAGE_NOT_FOUND_DESCRIPTION_2' />
      </StyledDescription>
      <Margin size={40} />
      <MarginBackNotFound>
        <BaseButton
          disabled={false}
          loading={false}
          onClick={() => history.push('/')}
          type='primary'
          style={{height: 45, width: 300, borderRadius: 100}}
        >
          <Translate id='BACK_TO_INITIAL_PAGE' />
        </BaseButton>
      </MarginBackNotFound>
    </StyledNotFoundGroup>
  </StyledNotFoundContainer>
)

export default NotFound
