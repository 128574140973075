import React, {useEffect, useState} from 'react'

import {Col, Row} from 'antd'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {useParams} from 'react-router'
import {reduxForm, Field, initialize} from 'redux-form'

import Add from '../../../../../assets/icons/add_bordered.svg'
import Recover from '../../../../../assets/icons/recover_blue.svg'
import Delete from '../../../../../assets/icons/remove.svg'
import Active from '../../../../../assets/icons/status_active.svg'
import Inactive from '../../../../../assets/icons/status_inactive.svg'
import {
  GetDepartment,
  GetUsersInSection,
  EditDepartment
} from '../../../../../infra/requests/SkyproConfigsRequests'
import AlertService from '../../../../../shared/components/alert/AlertService'
import BackButton from '../../../../../shared/components/buttons/BackButton'
import BaseButton from '../../../../../shared/components/buttons/BaseButton'
import CheckboxInput from '../../../../../shared/components/inputs/CheckboxInput'
import {InputLabel} from '../../../../../shared/components/inputs/InputStyles'
import SelectInput from '../../../../../shared/components/inputs/SelectInput'
import SwitchInput from '../../../../../shared/components/inputs/SwitchInput'
import TextInput from '../../../../../shared/components/inputs/TextInput'
import BaseTable from '../../../../../shared/components/table/BaseTable'
import {
  PrimaryColor,
  Sections,
  StatusActive,
  StatusInactive
} from '../../../../../shared/styles/_colors'
import {
  BodyH5,
  ContentContainer,
  Margin,
  PageForm,
  PageTitle,
  StyledTabPane,
  StyledTabs,
  StyledTabTitle,
  StyledTabTitleContainer
} from '../../../../../shared/styles/BasicStyles'
import {
  PasswordText,
  StatusText
} from '../../../../settings/SettingsStyles'

const Tabs = ['config', 'users']
const ManageDepartment = ({
  router,
  dispatch,
  handleSubmit,
  receivingServers,
  translate
}) => {
  const [section, setSection] = useState('config')
  const [hasFetchedDepartment, setHasFetchedDepartment] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingList, setLoadingList] = useState(false)
  const [rows, setRows] = useState([])
  const [total, setTotal] = useState()
  const [name, setName] = useState(<Translate id='NEW_DEPARTMENT' />)
  const {id} = useParams()
  const [enabled, setEnabled] = useState(undefined)

  const updateTable = async () => {
    try {
      setLoadingList(true)

      const {data, success} = await GetUsersInSection(id)

      if (success) {
        setRows(data?.items)
        setTotal(data?.totalItems)
        setLoadingList(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    async function fetchDepartment() {
      if (id != 'new' && id) {
        const {data, success} = await GetDepartment(id)
        updateTable()

        if (success) {
          setName(data?.name)
          dispatch(initialize('department_form', data || {}))
          setHasFetchedDepartment(true)
        }
      }
      setLoading(false)
    }
    if (!hasFetchedDepartment) {
      fetchDepartment()
    }
    function init() {
      const hash = router.location.hash.replace('#', '')
      if (Tabs.find((s) => s === hash)) {
        setSection(hash)
      }
    }
    init()
  }, [])

  const onSubmit = async (values) => {
    try {
      if (id != 'new' && id) {
        const info = {
          name: values?.name,
          active: values?.active,
          sectionId: values?.sectionId,
          sectionParentId: values?.sectionParentId,
          Slaid: values?.priority,
          mailServerId: values?.mailServer?.mailServerId
        }

        const {success} = await EditDepartment(id, info)
        if (success) {
          AlertService.success(
            translate('SUCCESS'),
            translate('DEPARTMENT_EDITED_SUCCESSFULLY')
          )
          router.history.replace('/mailConfiguration')
        }
      } else {
        const info = {
          name: values?.name,
          active: values?.active,
          sectionId: values?.sectionId,
          sectionParentId: values?.sectionParentId,
          Slaid: values?.priority,
          mailServerId: values?.mailServer?.mailServerId
        }
        const {success} = await EditDepartment(id, info)

        if (success) {
          AlertService.success(
            translate('SUCCESS'),
            translate('DEPARTMENT_CREATED_SUCCESSFULLY')
          )
          router.history.replace('/mailConfiguration')
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  const onChangeTab = (active) => {
    setSection(active)
    router.history.replace(`/mailConfiguration/department/${id}#${active}`)
  }

  const enableEdit = async () => {
    await setEnabled(true)
  }

  const getColumns = () => [
    {
      dataIndex: 'userName',
      type: 'text',
      title: <Translate id='USERNAME' />
    },
    {
      dataIndex: 'aspnetMembership',
      type: 'text',
      title: <Translate id='EMAIL' />,
      render: (value) => <div>{value?.email}</div>
    },
    {
      dataIndex: 'sectionUser',
      type: 'text',
      title: <Translate id='SECTIONS' />,
      render: (value) => (
        <BodyH5 $color={Sections}>
          {value.map(
            (item, index) =>
              item?.section?.name +
              (index === value?.length - 1 ? '' : ' / ')
          )}
        </BodyH5>
      )
    },
    {
      dataIndex: 'profile',
      type: 'text',
      title: <Translate id='PROFILE' />,
      render: (value) => <div>{value?.name}</div>
    },
    {
      dataIndex: 'status',
      type: 'text',
      title: <Translate id='STATUS' />,
      render: (text, row) => (
        <>
          <StatusText
            color={
              row.isLockedOut === true ? StatusActive : StatusInactive
            }
          >
            {row.isLockedOut === true ? (
              <Translate id='ACTIVE' />
            ) : (
              <Translate id='INACTIVE' />
            )}
            <img
              src={row.isLockedOut === true ? Active : Inactive}
              alt={<Translate id='STATE' />}
              style={{marginLeft: 7}}
            />
          </StatusText>
        </>
      )
    },
    {
      dataIndex: 'id',
      title: <Translate id='PASSWORD' />,
      render: () => (
        <>
          <PasswordText $color={PrimaryColor}>
            <Translate id='RECOVER' />
            <img
              src={Recover}
              alt={<Translate id='RECOVER' />}
              style={{marginLeft: 7, width: 19, height: 17}}
            />
          </PasswordText>
        </>
      )
    },
    {
      dataIndex: 'incoming',
      title: <Translate id='INCOMING' />,
      render: (text, row) => (
        <>
          <CheckboxInput input={{value: row.incoming}} />
        </>
      )
    },
    {
      dataIndex: 'ongoing',
      title: <Translate id='ONGOING' />,
      render: (text, row) => (
        <>
          <CheckboxInput input={{value: row.ongoing}} />
        </>
      )
    },
    {
      dataIndex: 'contact',
      title: <Translate id='CONTACT' />,
      render: (text, row) => (
        <>
          <CheckboxInput input={{value: row.contact}} />
        </>
      )
    }
  ]

  const renderConfig = () => (
    <>
      <Row gutter={[40, 20]}>
        <Col xs={6}>
          <Field
            component={SelectInput}
            name='mailServer.mailServerId'
            label={<Translate id='SERVER' />}
            placeholder={<Translate id='SELECT' />}
            data={receivingServers}
            dataKey='mailServerId'
            dataLabel='name'
          />
        </Col>
        <Col xs={6}>
          <Field
            component={TextInput}
            name='slaid'
            label={<Translate id='PRIORITY' />}
          />
        </Col>
        <Col xs={4} offset={2}>
          <InputLabel>
            <Translate id='STATUS' />:
          </InputLabel>
          <Field
            component={SwitchInput}
            name='active'
            checkedText={<Translate id='ACTIVE' />}
            uncheckedText={<Translate id='INACTIVE' />}
          />
        </Col>
      </Row>
      <Margin size={45} />
      <Row gutter={[40, 20]} align='bottom'>
        <Col xs={6}>
          <Field
            component={TextInput}
            name='name'
            label={<Translate id='NAME' />}
            disabled={id != 'new' || enabled}
          />
        </Col>
        <Col xs={6}>
          <Field
            component={TextInput}
            name='sectionParent.name'
            label={<Translate id='LEVEL_1' />}
            disabled={id != 'new' || enabled}
          />
        </Col>
        {/* <Col xs={6}>
          <Field
            component={TextInput}
            label={<Translate id='LEVEL_2' />}
            disabled
          />
        </Col> */}
        {id != 'new' && (
          <Col xs={5}>
            <BaseButton auto type='secondary' onClick={() => enableEdit()}>
              <Translate id='EDIT_DEPARTMENT' />
            </BaseButton>
          </Col>
        )}
      </Row>
    </>
  )

  const renderUsers = () => (
    <>
      <Row>
        <Col xs={12}>
          <BaseButton imagePath={Delete} type='secondary' auto>
            <Translate id='DELETE' />
          </BaseButton>
        </Col>
        <Col xs={12} style={{textAlign: 'right'}}>
          <BaseButton
            imagePath={Add}
            type='primary'
            auto
            onClick={() =>
              router.history.push('/mailConfiguration/user/new')
            }
          >
            <Translate id='ADD_USER' />
          </BaseButton>
        </Col>
      </Row>
      <Margin size={19} />
      <Row>
        <Col xs={24}>
          <BaseTable
            rowKey='userId'
            datasource={rows}
            columns={getColumns()}
            pagination={{
              render: false
            }}
            loading={loading}
          />
        </Col>
      </Row>
    </>
  )

  return (
    <>
      <PageForm onSubmit={handleSubmit(onSubmit)}>
        <Row align='bottom' gutter={[0, 50]}>
          <Col xs={24}>
            <BackButton
              label={<Translate id='BACK' />}
              onClick={() => router.history.push('/mailConfiguration')}
            />
          </Col>
          <Col xs={12}>
            <PageTitle>{name}</PageTitle>
          </Col>
          <Col xs={12} align='end'>
            <BaseButton htmlType='submit' type='primary' auto>
              <Translate id='SAVE' />
            </BaseButton>
          </Col>
        </Row>
        <ContentContainer>
          <StyledTabs activeKey={section} onChange={onChangeTab}>
            <StyledTabPane
              tab={
                <StyledTabTitleContainer>
                  <StyledTabTitle>
                    <Translate id='CONFIG' />
                  </StyledTabTitle>
                </StyledTabTitleContainer>
              }
              key='config'
            >
              {renderConfig()}
            </StyledTabPane>
            <StyledTabPane
              tab={
                <StyledTabTitleContainer>
                  <StyledTabTitle>
                    <Translate id='USERS' />
                  </StyledTabTitle>
                </StyledTabTitleContainer>
              }
              key='users'
            >
              {renderUsers()}
            </StyledTabPane>
          </StyledTabs>
        </ContentContainer>
      </PageForm>
    </>
  )
}

ManageDepartment.propTypes = {
  router: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  handleSubmit: PropTypes.func
}

ManageDepartment.defaultProps = {
  dispatch: undefined,
  handleSubmit: undefined
}

const ManageDepartmentPage = reduxForm({
  form: 'department_form'
  // validate: validations
})(withLocalize(ManageDepartment))

export default connect((state) => ({
  clients: state.info.clients,
  receivingServers: state.info.receivingServers
}))(ManageDepartmentPage)
