import React, {useEffect, useState} from 'react'

import {Row, Col} from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {reduxForm, Field, initialize} from 'redux-form'

import {
  EditDefaultSlotConfig,
  GetDefaultSlotConfig
} from '../../../infra/requests/FittingRequests'
import AlertService from '../../../shared/components/alert/AlertService'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import DescriptiveTextInput from '../../../shared/components/inputs/DescriptiveTextInput'
import BaseLoading from '../../../shared/components/loading/BaseLoading'
import {
  Margin,
  PageForm,
  SubTitle
} from '../../../shared/styles/BasicStyles'

const validations = (values) => {
  const error = {}
  const formats = ['HH:mm:ss']
  if (
    !moment(values.Beginning, formats, true).isValid()
  ) {
    error.Beginning = {
      _error: <Translate id='START_DURATION_INVALID' />
    }
  }
  if (!moment(values.End, formats, true).isValid()) {
    error.End = {
      _error: <Translate id='END_SCHEDULE_INVALID' />
    }
  }

  if (!moment(values.Slots, formats, true).isValid()) {
    error.Slots = {
      _error: <Translate id='SLOT_DURATION_INVALID' />
    }
  }
  return error
}

const ConfigurationsTab = ({handleSubmit, dispatch, translate}) => {
  const [ready, setReady] = useState(false)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    async function fetchPlatformConfigs() {
      try {
        const {data, success} = await GetDefaultSlotConfig()
        if (success) {
          dispatch(initialize('platform_configurations_form', data))
          setReady(true)
        }
      } catch (error) {
        console.log(error)
      }
    }
    fetchPlatformConfigs()
  }, [])

  const onSubmit = async (values) => {
    try {
      setLoading(true)
      const {success} = await EditDefaultSlotConfig(values)

      if (success) {
        setLoading(false)
        AlertService.success(
          translate('SUCCESS'),
          translate('DEFAULT_SLOT_CONFIG_EDITED_SUCCESSFULLY')
        )
      }
    } catch (error) {
      console.log(error)
    }
  }

  if (!ready) return <BaseLoading margin='100' />

  return (
    <>
      <PageForm onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs={12}>
            <SubTitle>
              <Translate id='TAILOR_SCHEDULES' />
            </SubTitle>
          </Col>
          <Col xs={12} style={{textAlign: 'right'}}>
            <BaseButton auto htmlType='submit' loading={loading}>
              <Translate id='SAVE' />
            </BaseButton>
          </Col>
        </Row>
        <Margin size={19} />
        <Row gutter={[20, 20]}>
          <Col xs={6}>
            <Field
              component={DescriptiveTextInput}
              name='Beginning'
              label={<Translate id='BEGINNING' />}
              descriptiveText='00:00:00'
            />
          </Col>
          <Col xs={6}>
            <Field
              component={DescriptiveTextInput}
              name='End'
              label={<Translate id='END' />}
              descriptiveText='00:00:00'
            />
          </Col>
          <Col xs={6}>
            <Field
              component={DescriptiveTextInput}
              name='Slots'
              label={<Translate id='SLOTS' />}
              descriptiveText='00:00:00'
            />
          </Col>
        </Row>
      </PageForm>
    </>
  )
}

ConfigurationsTab.propTypes = {
  dispatch: PropTypes.func,
  handleSubmit: PropTypes.func
}

ConfigurationsTab.defaultProps = {
  dispatch: undefined,
  handleSubmit: undefined
}

const ConfigurationsTabPage = reduxForm({
  form: 'platform_configurations_form',
  validate: validations
})(ConfigurationsTab)

export default withLocalize(ConfigurationsTabPage)
