import React from 'react'

import {PropTypes} from 'prop-types'
import {Translate} from 'react-localize-redux'

import {InputError} from '../../../shared/components/inputs/InputStyles'
import {AuthInputBox, TextInputStyled} from '../AuthStyles'

const AuthImageTextInput = ({
  input,
  image,
  meta,
  type,
  placeholder,
  afterChange,
  disabled,
  width
}) => {
  const handleChange = (event) => {
    input.onChange(event.target.value)
    if (typeof afterChange === 'function') {
      afterChange(event.target.value)
    }
  }

  const hasError = meta.invalid && meta.submitFailed

  return (
    <AuthInputBox disabled={disabled} $width={width} $hasError={hasError}>
      {image || null}
      <TextInputStyled
        type={type}
        placeholder={placeholder}
        value={input.value}
        onChange={handleChange}
        disabled={disabled}
      />
      {hasError && (
        <InputError>
          {' '}
          <Translate id={meta.error} />
        </InputError>
      )}
    </AuthInputBox>
  )
}

AuthImageTextInput.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  image: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number
  ]),
  placeholder: PropTypes.string,
  type: PropTypes.string,
  afterChange: PropTypes.func,
  disabled: PropTypes.bool,
  width: PropTypes.string
}

AuthImageTextInput.defaultProps = {
  input: {value: undefined, onChange: () => null},
  image: undefined,
  placeholder: undefined,
  meta: {},
  type: 'text',
  afterChange: undefined,
  disabled: false,
  width: undefined
}

export default AuthImageTextInput
