import React, {Component} from 'react'

import {Row, Col, Checkbox} from 'antd'
import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'
import {connect} from 'react-redux'
import {getFormValues} from 'redux-form'
import styled from 'styled-components'

import CancelBordered from '../../../assets/icons/cancel_border.svg'
import SaveIcon from '../../../assets/icons/save.svg'
import {GetAvailableProductRawMaterials} from '../../../infra/requests/ProductsRequests'
import BackButton from '../../../shared/components/buttons/BackButton'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import BordelessButton from '../../../shared/components/buttons/BordelessButton'
import SearchInput from '../../../shared/components/inputs/SearchInput'
import SelectInput from '../../../shared/components/inputs/SelectInput'
import BaseList from '../../../shared/components/table/BaseList'
import GetCoverImage from '../../../shared/logic/arrays/ImageCoverFunction'
import {ConstructQuery} from '../../../shared/logic/queries/EndpointQueries'
import ActiveTranslation from '../../../shared/logic/translations/ActiveTranslation'
import {TableHeaderColor} from '../../../shared/styles/_colors'
import {SemiBoldFontWeight} from '../../../shared/styles/_texts'
import {
  Margin,
  StyledHR,
  PageContent,
  TitleH2
} from '../../../shared/styles/BasicStyles'
import {
  ProductImage,
  ProductRefContainer,
  ProductTitle,
  ProductDescription,
  ProductColor
} from '../../orders/manage_b2b_b2b2c/components/Styles'

const ColorSection = styled.div``

const ColorOption = styled.div`
 padding: 5px 0;
 display: flex;
align-items: center;
justify-content: space-between;
`
const Flex = styled.div`
display: flex;
align-items: center;
`

const ColorIcon = styled.div`
  margin-left: 10px;
`

class AddMaterialsPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      rows: [],
      page: 1,
      pageSize: 24,
      total: 0,
      filters: {search: ''},
      selected: []
    }
  }

  componentDidMount = async () => {
    try {
      this.updateTable()
    } catch (error) {
      console.warn(error)
    }
  }

  getAddedMaterials = () =>
    []
    // DELETED REMOVED PRODUCTS ALREADY IN TABLE BECAUSE OF OTHER PEOPLES
    // const {currentProductForm} = this.props
    // const list = []
    // if (currentProductForm?.composition) {
    //   currentProductForm.composition.forEach((material) => {
    //     if (material.type === 'family') {
    //       material.list.forEach((item) => list.push(item.productId))
    //     }
    //     if (material.type === 'material') list.push(material.productId)
    //   })
    // }
    // return list

  updateTable = async () => {
    const {page, pageSize, filters} = this.state
    this.setState({loading: true})
    const {data} = await GetAvailableProductRawMaterials(
      page,
      pageSize,
      ConstructQuery(filters),
      this.getAddedMaterials()
    )
    this.setState({
      rows: data?.items,
      total: data?.totalItems,
      loading: false
    })
  }

  onChangePagination = (page) => this.setState({page}, this.updateTable)

  clearSearch = async () => {
    await this.setState({filters: {search: ''}})
    this.updateTable()
  }

  handleToggleSelection = (item, combination) => {
    const {selected} = this.state

    const product = {...item,
      rawMaterialColor: combination.color,
      rawMaterialColorId: combination.color.colorId}

    const found = selected.findIndex((x) =>
      x.productId === product.productId &&
      x.rawMaterialColorId === combination.color.colorId
    )
    if (found > -1) {
      selected.splice(found, 1)
      this.setState({selected})
    } else {
      selected.push(product)
      this.setState({selected})
    }
  }

  renderItem = (product) => {
    const {selected} = this.state
    return (
      <>
        <Margin size={25} />
        <ProductRefContainer>
          <ProductTitle>
            <Translate id='REF' />
            :&ensp;
          </ProductTitle>
          <ProductDescription
            $weight={SemiBoldFontWeight}
            $color={TableHeaderColor}
          >
            {product?.referenceCode}
          </ProductDescription>
        </ProductRefContainer>
        <ProductDescription>
          <ActiveTranslation
            value={product?.productTranslation}
            tag='name'
          />
        </ProductDescription>
        <Margin size={10} />
        <ProductImage src={GetCoverImage(product?.productImage)} />
        <Margin size={10} />
        <ProductTitle>
          <Translate id='FAMILY' />
        </ProductTitle>
        <ProductDescription>
          <ActiveTranslation
            value={
              product?.rawMaterialFamily?.rawMaterialFamilyTranslation ||
              'N/A'
            }
            tag='name'
          />
        </ProductDescription>
        <Margin size={10} />
        <ProductTitle>
          <Translate id='SUPPLIER' />
        </ProductTitle>
        <ProductDescription>
          {product?.defaultSupplier || <Translate id='NA' />}
        </ProductDescription>
        <Margin size={10} />
        <ProductTitle>
          <Translate id='REF_SUPPLIER' />
        </ProductTitle>
        <ProductDescription>
          {product?.referenceSupplier || <Translate id='NA' />}
        </ProductDescription>
        <ProductTitle>
          <Translate id='SELECT_BY_COLOR' />
        </ProductTitle>
        <StyledHR $space='10' />
        {(product?.productCombination || []).map(
          (combination, index) => (
            <ColorSection key={index} onClick={() => this.handleToggleSelection(product, combination)}>
              <ColorOption>
                <Flex>
                  <ActiveTranslation value={combination?.color?.colorTranslation} tag='name' />
                  <ColorIcon>
                    <ProductColor
                      $file={
                    combination?.color?.file
                      ? URL.createObjectURL(combination?.color?.file)
                      : undefined
                      }
                      $color={combination?.color?.html}
                      $border={combination?.color?.borderColor}
                    />
                  </ColorIcon>
                </Flex>
                <Flex>
                  <Checkbox
                    checked={
                      !!selected.find((x) =>
                        x.productId === product.productId &&
                      x.rawMaterialColorId === combination?.color?.colorId)
                    }
                  />
                </Flex>
              </ColorOption>
              <StyledHR $space='10' />
            </ColorSection>
          )
        )}
      </>
    )
  }

  render() {
    const {loading, rows, page, pageSize, total, filters, selected} =
      this.state
    const {
      onClose,
      addMaterials,
      rawMaterialFamily,
      suppliersRawMaterial,
      productColors
    } = this.props
    return (
      <PageContent $noMargin $noMinHeight>
        <Margin size={20} />
        <Row>
          <Col xs={24}>
            <BackButton
              label={<Translate id='BACK' />}
              onClick={onClose}
            />
          </Col>
        </Row>
        <Margin size={24} />
        <Row justify='end'>
          <Col xs={12} lg={18}>
            <TitleH2>
              <Translate id='ADD_RAW_MATERIALS' />
            </TitleH2>
          </Col>
          <Col xs={12} lg={6}>
            <BaseButton
              onClick={() => addMaterials(selected)}
              type='secondary'
              disabled={selected.length === 0}
              imagePath={SaveIcon}
            >
              <Translate id='ADD_TO_COMPOSITION' />
            </BaseButton>
          </Col>
        </Row>
        <StyledHR $space='20' />
        <Row align='bottom' gutter={[20, 20]}>
          <Col xs={4}>
            <SelectInput
              label={<Translate id='FAMILY' />}
              input={{
                value: filters.rawMaterialFamilyId,
                onChange: (value) =>
                  this.setState(
                    {
                      page: 1,
                      filters: {...filters, rawMaterialFamilyId: value}
                    },
                    this.updateTable
                  )
              }}
              dataKey='rawMaterialFamilyId'
              dataLabel='rawMaterialFamilyTranslation'
              placeholder={<Translate id='TYPE' />}
              data={rawMaterialFamily}
            />
          </Col>
          <Col xs={4}>
            <SelectInput
              label={<Translate id='COLOR' />}
              input={{
                value: filters.rawMaterialColorId,
                onChange: (value) =>
                  this.setState(
                    {page: 1, filters: {...filters, rawMaterialColorId: value}},
                    this.updateTable
                  )
              }}
              placeholder={<Translate id='SELECT' />}
              data={productColors}
              dataKey='colorId'
              dataLabel='colorTranslation'
            />
          </Col>
          <Col xs={4}>
            <SelectInput
              label={<Translate id='SUPPLIER' />}
              input={{
                value: filters.supplierId,
                onChange: (value) =>
                  this.setState(
                    {page: 1, filters: {...filters, supplierId: value}},
                    this.updateTable
                  )
              }}
              dataKey='supplierId'
              dataLabel='name'
              placeholder={<Translate id='SUPPLIER' />}
              data={suppliersRawMaterial}
            />
          </Col>
          <Col xs={8} style={{textAlign: 'right'}}>
            <SearchInput
              auto
              input={{
                value: filters.search,
                onChange: (value) =>
                  this.setState({
                    page: 1,
                    filters: {...filters, search: value}
                  }),
                onSearch: this.updateTable
              }}
            />
          </Col>

          <Col xs={4} style={{textAlign: 'right'}}>
            <BordelessButton
              auto
              onClick={() => this.clearSearch()}
              imagePath={CancelBordered}
            >
              <Translate id='CLEAR_SEARCH' />
            </BordelessButton>
          </Col>
        </Row>
        <StyledHR $space='20' />
        <Row>
          <Col xs={24}>
            <BaseList
              grid={{
                gutter: 20,
                xs: 1,
                sm: 2,
                md: 4,
                lg: 4,
                xl: 6,
                xxl: 6
              }}
              datasource={rows}
              renderItem={this.renderItem}
              pagination={{
                render: true,
                page,
                pageSize,
                total,
                onChangePagination: this.onChangePagination
              }}
              loading={loading}
            />
          </Col>
        </Row>
      </PageContent>
    )
  }
}

AddMaterialsPage.propTypes = {
  onClose: PropTypes.func.isRequired,
  addMaterials: PropTypes.func.isRequired,
  rawMaterialFamily: PropTypes.array.isRequired,
  suppliersRawMaterial: PropTypes.array.isRequired,
  productColors: PropTypes.array.isRequired,
  currentProductForm: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  rawMaterialFamily: state.info.rawMaterialFamily,
  suppliersRawMaterial: state.info.suppliersRawMaterial,
  productColors: state.info.productColors,
  currentProductForm: getFormValues('manage_product')(state)
})

export default connect(mapStateToProps)(AddMaterialsPage)
