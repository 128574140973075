import React, { useState } from 'react'

import { Translate } from 'react-localize-redux'
import { useParams } from 'react-router'

import { GenerateEmployeeKits } from '../../../../../infra/requests/DepartureRequests'
import AlertService from '../../../../../shared/components/alert/AlertService'
import MultipleSelectInput from '../../../../../shared/components/inputs/MultipleSelectInput'
import BaseModal from '../../../../../shared/components/modal/BaseModal'
import AsyncForEach from '../../../../../shared/logic/arrays/AsyncForEach'
import { GetEmployeeAddressesFromArray } from '../../components/deliveryLocations/DeliveryTransforms'

const EmployeeKitsModal = ({ open, onClose, workgroups, products, setProducts, setBoxes, setToggle }) => {
  const [loading, setLoading] = useState(false)
  const [selectWork, setSelectWork] = useState()
  const { id } = useParams()

  const createEmployeeKits = async () => {
    setLoading(true)
    if (products.length > 20) setToggle(false)
    const selected = selectWork && selectWork.length > 0 && selectWork[0] !== 'all' ? selectWork : []
    const { data, success } = await GenerateEmployeeKits(id, selected)
    if (success && data.length > 0) {
      const employees = data.map((d) => d.employeeId)
      const addresses = await GetEmployeeAddressesFromArray(employees)
      const stock = products.map((p) => {
        p.left = p.departQty
        return p
      })
      const boxList = []
      await AsyncForEach(data, async (item, index) => {
        const box = { ...item }
        const location = addresses.find((a) => a.employeeId == item.employeeId)
        box.realIndex = index
        box.generatedKit = true
        box.clientAddresses = location?.addresses || []
        box.deliveryAddressId = item.deliveryAddressId ? `${item.deliveryAddressId}` : undefined
        box.finalAddressId = item.finalAddressId ? `${item.finalAddressId}` : item.deliveryAddressId ? `${item.deliveryAddressId}` : undefined
        const items = []
        box.items.forEach((i) => {
          const found = stock.findIndex((f) => f.barcode === i.barcode)
          if (found > -1) {
            stock[found].left -= i.departQty
            items.push({
              ...i,
              quantity: parseInt(i.departQty, 10),
              error: i.departQty < i.empNeededQty || stock[found].left < 0
            })
          } else {
            items.push({
              ...i,
              quantity: parseInt(i.departQty, 10)
            })
          }
        })
        box.items = items
        box.error = !!items.find((i) => i.error)
        boxList.push(box)
      })
      setProducts(stock)
      setBoxes(boxList)
      onClose()
    } else {
      AlertService.error(
        'These products are not assign to employees',
        'Unable to create automatic kits because these products are not assign to employees'
      )
    }
    setLoading(false)
  }

  return (
    <BaseModal
      open={open}
      onClose={onClose}
      title='Create employee kits'
      onOk={createEmployeeKits}
      okButtonProps={{ loading, disabled: loading }}
      okText={<Translate id='CREATE' />}
    >
      <MultipleSelectInput
        disabled={workgroups.length === 0}
        input={{ value: selectWork, onChange: setSelectWork }}
        data={workgroups}
        dataKey='positionId'
        dataLabel='name'
        placeholder='Select workgroups'
        label='Select the workgroups to generate kits'
      />
    </BaseModal>
  )
}

export default EmployeeKitsModal
