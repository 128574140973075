import React from 'react'

import {Col, Row} from 'antd'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Translate} from 'react-localize-redux'

import SearchInput from '../../shared/components/inputs/SearchInput'
import StatusColumn from '../../shared/components/Status/StatusColumn'
import BaseTable from '../../shared/components/table/BaseTable'
import {Margin} from '../../shared/styles/BasicStyles'

const UsersTab = ({fields}) => {
  const users = fields || []

  const getColumnsUsers = () => [
    {
      dataIndex: 'fullName',
      type: 'text',
      title: <Translate id='NAME' />
    },
    {
      dataIndex: 'userName',
      type: 'text',
      title: <Translate id='USERNAME' />
    },
    {
      dataIndex: 'aspnetMembership',
      type: 'text',
      title: <Translate id='EMAIL' />,
      render: (value) => <div>{value.email}</div>
    },
    {
      dataIndex: 'aspnetMembership',
      type: 'text',
      title: <Translate id='STATUS' />,
      render: (value) => <StatusColumn value={value.isApproved} />
    }
  ]

  return (
    <>
      <Row justify='end'>
        <Col xs={8}>
          <SearchInput />
        </Col>
      </Row>
      <Margin size={29} />
      <Row>
        <Col xs={24}>
          <BaseTable
            rowKey='userId'
            datasource={users}
            columns={getColumnsUsers()}
            pagination={{render: false}}
          />
        </Col>
      </Row>
    </>
  )
}

UsersTab.propTypes = {
  fields: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired
}

export default connect()(UsersTab)
