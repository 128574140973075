import React from 'react'

import PropTypes from 'prop-types'
import {useDrag} from 'react-dnd'

import MoveIcon from '../../../../assets/icons/move.svg'
import {
  ContactContainer,
  ContactDragImageContainer,
  ContactDragImage,
  ContactName
} from '../ProcessStyles'

const DraggableContacts = ({contact}) => {
  const [{isDragging}, drag] = useDrag({
    type: 'contacts',
    item: contact,
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  })

  return (
    <ContactContainer ref={drag} $isDragging={isDragging}>
      <ContactDragImageContainer>
        <ContactDragImage src={MoveIcon} />
      </ContactDragImageContainer>
      <ContactName>{contact.name}</ContactName>
    </ContactContainer>
  )
}

DraggableContacts.propTypes = {
  contact: PropTypes.object.isRequired
}

export default DraggableContacts
