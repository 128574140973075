import React from 'react'

import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import {Translate} from 'react-localize-redux'
import AttachIcon from '../../../../assets/icons/attach.svg'
import TrashIcon from '../../../../assets/icons/remove.svg'
import GetFileType from '../../../../shared/components/files/GetFileTypeIcon'
import {
  FileBox,
  FilesMessagingContainer,
  DeleteAttachmentIcon,
  AttachmentIcon,
  AttachmentName
} from '../../MessagingStyles'
import {AttatchIconImg} from '../ProcessStyles'

const Attachments = ({fields}) => {
  const files = fields.getAll() || []

  const handleFileDrop = (accepted, rejected) => {
    if (Array.isArray(accepted)) {
      accepted.forEach((file) => fields.push(file))
    } else console.warn(rejected)
  }

  return (
    <>
      <Dropzone onDrop={handleFileDrop}>
        {({getRootProps, getInputProps}) => (
          <>
            <input {...getInputProps()} />
            <AttatchIconImg src={AttachIcon} {...getRootProps()} />
          </>
        )}
      </Dropzone>
      {files.length > 0 && (
        <FilesMessagingContainer>
          {files.map((file, index) => (
            <FileBox key={index}>
              <AttachmentIcon src={GetFileType(file)} />
              <AttachmentName>
                {file?.name
                  ? file.name
                  : file.split('/')[file.split('/')?.length - 1]}
              </AttachmentName>
              <DeleteAttachmentIcon
                src={TrashIcon}
                alt={<Translate id='REMOVE_ICON' />}
                onClick={() => fields.remove(index)}
              />
            </FileBox>
          ))}
        </FilesMessagingContainer>
      )}
    </>
  )
}

Attachments.propTypes = {
  fields: PropTypes.object.isRequired
}

Attachments.defaultProps = {}

export default Attachments
