import { createReducer } from '@reduxjs/toolkit'

import { setBackendVersion, updateInfo } from './InfoActions'

const initialState = {
  ready: false,
  currencies: [],
  orderStatus: [],
  paymentConditions: [],
  deliveryLocations: [],
  shippingConditions: [],
  clients: [],
  paymentMethods: [],
  countries: [],
  services: [],
  productTypes: [],
  productCategories: [],
  productStyles: [],
  productCharacteristics: [],
  productTechnologies: [],
  productMeasures: [],
  productColors: [],
  productSizes: [],
  productWidths: [],
  productTaxRates: [],
  suppliers: [],
  positions: [],
  contractTypes: [],
  modulationTypes: [],
  employeeCategory: [],
  employeeStatus: [],
  employeeCostCenter: [],
  employeePosition: [],
  contactTypes: [],
  bannersType: [],
  receivingServers: [],
  users: [],
  processes: [],
  rawMaterialFamily: [],
  suppliersRawMaterial: [],
  productMeasuresUnit: [],
  arrivalTypes: [],
  departureTypes: [],
  attachmentTypes: [],
  returnReasons: [],
  warehouseTypes: [],
  backendVersion: null
}

const InfoReducer = createReducer(initialState, {
  [updateInfo]: (state, action) => ({ ...state, ...action.payload }),
  [setBackendVersion]: (state, action) => {
    state.backendVersion = action.payload
  }
})

export default InfoReducer
