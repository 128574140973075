import { createReducer } from '@reduxjs/toolkit'

import { clearEmail, setEmail } from './AuthActions'

const initialState = {
  email: ''
}

const EmailReducer = createReducer(initialState, {
  [setEmail]: (state, action) => {
    state.email = action.payload
  },
  [clearEmail]: (state) => {
    state.email = ''
  }
})

export default EmailReducer
