import React from 'react'

import { Row, Col } from 'antd'
import PropTypes from 'prop-types'
import { Translate, withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import { Field } from 'redux-form'

import TextInput from '../../../shared/components/inputs/TextInput'
import ProductLink from '../../../shared/components/links/ProductLink'
import BaseTable from '../../../shared/components/table/BaseTable'
import ActiveTranslation from '../../../shared/logic/translations/ActiveTranslation'
import { Margin } from '../../../shared/styles/BasicStyles'

const Columns = [
  {
    title: <Translate id='STOCK_OWNER' />,
    type: 'text',
    dataIndex: 'stockOwnerName'
  },
  {
    title: <Translate id='EAN13' />,
    type: 'text',
    dataIndex: 'eaN13'
  },
  {
    title: <Translate id='CLIENT_REF' />,
    type: 'text',
    dataIndex: 'clientRef',
    render: (value) => (
      <ActiveTranslation value={value?.nameEu} tag='name' />
    )
  },
  {
    title: <Translate id='REF_SKYPRO' />,
    type: 'text',
    dataIndex: 'productRef'
  },
  {
    title: <Translate id='DESCRIPTION' />,
    type: 'text',
    render: (value) => <ProductLink product={value} />
  },
  {
    title: <Translate id='COLOR' />,
    type: 'text',
    dataIndex: 'color',
    render: (value) => <ActiveTranslation value={value} tag='name' />
  },
  {
    title: <Translate id='SIZE' />,
    type: 'text',
    dataIndex: 'size',
    render: (value) => (
      <ActiveTranslation value={value.nameEu} tag='name' />
    )
  },
  {
    title: <Translate id='STOCK' />,
    type: 'text',
    dataIndex: 'quantity'
  },
  {
    title: <Translate id='STOCK_TO_MOVE' />,
    type: 'text',
    render: (value, row, index) => (
      <Field
        name={`items[${index}].stockToMove`}
        component={TextInput}
        placeholder='-'
      />
    )
  },
  {
    title: <Translate id='UM' />,
    type: 'text',
    dataIndex: 'um',
    render: (value) => <ActiveTranslation value={value?.code} tag='name' />
  }
]

const StockTransferTable = ({
  fields,
  page,
  total,
  pageSize,
  onChangePagination,
  loading
}) => {
  const inventoryitems = fields.getAll() || []
  return (
    <>
      <Margin size={20} />
      <Row>
        <Col xs={24}>
          <BaseTable
            rowKey={(value) =>
              `${value.productRef}_${value?.warehouseCode}_${value?.pcwMovementId}`
            }
            datasource={inventoryitems}
            columns={Columns}
            loading={loading}
            pagination={{
              render: true,
              page,
              pageSize,
              total,
              onChangePagination
            }}
          />
        </Col>
      </Row>
    </>
  )
}

StockTransferTable.propTypes = {
  dispatch: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  meta: PropTypes.object.isRequired,
  client: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  refresh: PropTypes.number
}

StockTransferTable.defaultProps = {
  editable: true,
  client: undefined,
  refresh: undefined
}

export default withLocalize(connect()(StockTransferTable))
