import React, { createRef, useEffect, useState } from 'react'

import { Fade } from '@egjs/flicking-plugins'
import Flicking from '@egjs/react-flicking'
import { Row, Col } from 'antd'
import PropTypes from 'prop-types'
import { Translate, withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import { useParams } from 'react-router'
import {
  reduxForm,
  initialize,
  getFormValues,
  FieldArray,
  Field,
  change
} from 'redux-form'

import ArrowLeftIcon from '../../../assets/icons/arrow_left.svg'
import ArrowRightIcon from '../../../assets/icons/arrow_right.svg'
import DropDownIcon from '../../../assets/icons/down.svg'
import DropdownBlue from '../../../assets/icons/dropdown_blue.svg'
import EmptyImageIcon from '../../../assets/placeholder.jpg'
import {
  EditRawMateria,
  GetRawMaterial
} from '../../../infra/requests/ProductsRequests'
import AlertService from '../../../shared/components/alert/AlertService'
import BackButton from '../../../shared/components/buttons/BackButton'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import PropertyDescription from '../../../shared/components/description/PropertyDescription'
import ProductUploadFileInput from '../../../shared/components/inputs/Products/ProductUploadFileInput'
import BaseLoading from '../../../shared/components/loading/BaseLoading'
import GetImage from '../../../shared/logic/arrays/ImageFunction'
import ActiveTranslation from '../../../shared/logic/translations/ActiveTranslation'
import { BorderColor } from '../../../shared/styles/_colors'
import {
  PageForm,
  StyledFlex,
  HeaderCollapsable,
  ToggleImage,
  ContentCollapsable,
  CollapsableContainer,
  CollapsableSubGroup,
  TitleH1,
  TitleH4,
  BodyH5,
  StyledHR,
  PageContent,
  Margin,
  TitleH3,
  TitleH2
} from '../../../shared/styles/BasicStyles'
import ProductDescriptions from '../manage_products/components/5_ProductDescriptions'
import {
  ProductDropDownIcon,
  FlickingArrow,
  CarrouselImageContainer,
  CarrouselImage,
  EmptyImageText,
  SaveButton
} from '../ProductsStyles'
import GeneralInformation from './components/1_GeneralInformation'
import Suppliers from './components/2_Suppliers'
import RawMaterialImages from './components/3_RawMaterialImages'
import RawMaterialPrices from './components/4_RawMaterialPrices'
import ColorsAndCodes from './components/5_ColorsAndCodes'
import ValidateRawMaterialData from './components/ValidateRawMaterialData'

const ManageRawMaterialPage = ({
  router,
  handleSubmit,
  dispatch,
  currentFormValues,
  suppliersRawMaterial,
  rawMaterialFamily,
  productMeasuresUnit,
  translate
}) => {
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [mainCategoryToggle, setMainCategoryToggle] = useState(undefined)
  const [subCategoryToggle, setSubCategoryToggle] = useState(undefined)
  const [headerIsOpened, setHeaderIsOpened] = useState(true)
  const { id } = useParams()

  const flicking = createRef()

  const onClickLeft = () => {
    flicking.current.prev()
  }

  const onClickRight = () => {
    flicking.current.next()
  }

  useEffect(() => {
    async function fetchOrder() {
      const { data, success } = await GetRawMaterial(id)
      if (success) {
        if (data?.rawMaterialPropertiesSection?.productTypeId != 4) {
          return router.history.push(`/products/${data.productId}`)
        }
        dispatch(initialize('manage_raw_material', {
          ...data,
          rawMaterialPropertiesSection: {
            ...data.rawMaterialPropertiesSection,
            rawMaterialFamilyId: data?.rawMaterialPropertiesSection?.rawMaterialFamily?.rawMaterialFamilyId
          }
        }))
        setLoading(false)
      }
    }
    fetchOrder()
  }, [id])

  const onSubmit = async (values) => {
    try {
      const errors = ValidateRawMaterialData(values)

      if (errors.length) {
        return AlertService.error(
          translate('ERROR'),
          <div>{errors.map((err) => <div>{translate(err.tag, { value: err.value })}</div>)}</div>
        )
      }

      setSaving(true)
      const tax =
        parseFloat(values.rawMaterialPriceSection.baseValue, 10) *
        (values.rawMaterialPriceSection.vatvalue
          ? `0.${parseFloat(values.rawMaterialPriceSection.vatvalue, 10)}`
          : 0)

      const object = {
        ...values,
        rawMaterialPriceSection: {
          ...values.rawMaterialPriceSection,
          vatvalue: values.rawMaterialPriceSection.vatvalue || 0,
          totalValue: parseFloat(values.rawMaterialPriceSection.baseValue, 10) + tax
        }
      }

      const { success } = await EditRawMateria(id, object)

      if (success) {
        router.history.push('/products#materials')
      }
      setSaving(false)
    } catch (error) {
      console.log(error)
    }
  }

  if (loading) return <BaseLoading margin={200} />

  return (
    <PageForm $margin='20px 0px 0px 0px' onSubmit={handleSubmit(onSubmit)}>
      <PageContent $noMargin $noMinHeight>
        <Row>
          <Col xs={24}>
            <BackButton
              label={<Translate id='BACK_PRODUCTS' />}
              onClick={() => router.history.push('/products#materials')}
            />
          </Col>
        </Row>
        <Margin size={24} />
        <StyledHR $space={15} />
        {headerIsOpened ? (
          <>
            <Row>
              <Col xs={24} lg={16}>
                <FlickingArrow
                  $left
                  src={ArrowLeftIcon}
                  onClick={onClickLeft}
                />
                <Flicking
                  ref={flicking}
                  gap={10}
                  circular
                  deceleration={0.015}
                  moveType='snap'
                  plugins={[new Fade()]}
                  collectStatistics={false}
                >
                  {currentFormValues?.rawMaterialPropertiesSection?.productImage
                    ?.length > 0 ? (
                    currentFormValues.rawMaterialPropertiesSection?.productImage
                      .filter((x) => !x.layout)
                      .map((currentImage, index) => (
                        <CarrouselImageContainer key={index}>
                          <CarrouselImage src={GetImage(currentImage)} />
                        </CarrouselImageContainer>
                      ))
                  ) : (
                    <CarrouselImageContainer>
                      <CarrouselImage src={EmptyImageIcon} />
                      <EmptyImageText>
                        <Translate id='REF' />:{' '}
                        {
                          currentFormValues?.rawMaterialPropertiesSection
                            ?.referenceCode
                        }
                      </EmptyImageText>
                    </CarrouselImageContainer>
                  )}
                </Flicking>
                <FlickingArrow
                  $right
                  src={ArrowRightIcon}
                  onClick={onClickRight}
                />
              </Col>
              <Col xs={24} lg={8}>
                <Row style={{ padding: '0px 15px' }}>
                  <ProductDropDownIcon
                    src={DropDownIcon}
                    $open={headerIsOpened}
                    onClick={() => setHeaderIsOpened(!headerIsOpened)}
                  />
                  <Col xs={24}>
                    <BodyH5 $opacity='50%'>
                      <Translate id='REF' />:{' '}
                      {
                        currentFormValues?.rawMaterialPropertiesSection
                          ?.referenceCode
                      }
                    </BodyH5>
                  </Col>
                  <Col xs={24}>
                    <StyledFlex
                      $direction='row'
                      $justify='flex-start'
                      $align='flex-start'
                    >
                      <TitleH2>
                        <ActiveTranslation
                          value={
                            currentFormValues?.rawMaterialPropertiesSection
                              ?.productTranslation
                          }
                          tag='name'
                        />
                      </TitleH2>
                    </StyledFlex>
                  </Col>
                  <Col xs={24}>
                    <Row
                      justify='middle'
                      align='middle'
                      style={{
                        padding: '30px 0px'
                      }}
                    >
                      <Col xs={12} lg={12}>
                        <StyledFlex
                          $align='flex-start'
                          $justify='center'
                          style={{
                            padding: '3px 0px',
                            height: '100%',
                            borderTop: `1px solid ${BorderColor}`,
                            borderBottom: `1px solid ${BorderColor}`,
                            borderRight: `1px solid ${BorderColor}`
                          }}
                        >
                          <PropertyDescription
                            label={<Translate id='STOCK_AVAILABLE' />}
                            value={currentFormValues.stock}
                          />
                        </StyledFlex>
                      </Col>

                      <Col xs={12} lg={12}>
                        <StyledFlex
                          style={{
                            borderTop: `1px solid ${BorderColor}`,
                            borderBottom: `1px solid ${BorderColor}`
                          }}
                        >
                          <TitleH1>
                            {`${currentFormValues?.rawMaterialPriceSection
                              ?.totalValue
                              ? currentFormValues.rawMaterialPriceSection
                                .totalValue
                              : '0'
                              }€`}
                          </TitleH1>
                        </StyledFlex>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24}>
                    <div
                      style={{
                        paddingTop: 20,
                        paddingBottom: 20
                      }}
                    >
                      <Field
                        name='rawMaterialPropertiesSection.rawMaterialAttachment'
                        component={ProductUploadFileInput}
                        top={35}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row
              justify='middle'
              align='middle'
              style={{
                padding: '0px 10px'
              }}
              gutter={[20, 0]}
            >
              <Col
                xs={12}
                lg={8}
                style={{ borderRight: `1px solid ${BorderColor}` }}
              >
                <PropertyDescription
                  label={<Translate id='STOCK_AVAILABLE' />}
                  value={currentFormValues.stock}
                />
              </Col>
              <Col
                xs={12}
                lg={6}
                style={{ borderRight: `1px solid ${BorderColor}` }}
              >
                <TitleH1>
                  {`${currentFormValues?.rawMaterialPriceSection?.totalValue
                    ? currentFormValues.rawMaterialPriceSection.totalValue
                    : '0'
                    }€`}
                </TitleH1>
              </Col>
              <Col xs={24} lg={10}>
                <ProductDropDownIcon
                  src={DropDownIcon}
                  $open={headerIsOpened}
                  onClick={() => setHeaderIsOpened(!headerIsOpened)}
                />
                <StyledFlex
                  $direction='row'
                  $justify='flex-start'
                  $align='flex-start'
                >
                  <TitleH3 $left='5'>
                    <ActiveTranslation
                      value={
                        currentFormValues?.rawMaterialPropertiesSection
                          ?.productTranslation
                      }
                      tag='name'
                    />
                  </TitleH3>
                </StyledFlex>
              </Col>
            </Row>
            <Margin size={15} />
          </>
        )}
      </PageContent>
      <div
        style={{
          backgroundColor: 'transparent',
          paddingBottom: '50px',
          display: 'flex',
          position: 'relative'
        }}
      >
        <PageContent $noMargin $noMinHeight>
          <Row>
            <Col xs={24}>
              <CollapsableContainer $hasBorderBottom>
                <HeaderCollapsable
                  $direction='flex-start'
                  onClick={() => {
                    setSubCategoryToggle(undefined)
                    setMainCategoryToggle(
                      mainCategoryToggle === 0 ? undefined : 0
                    )
                  }}
                >
                  <ToggleImage
                    src={DropdownBlue}
                    $open={mainCategoryToggle === 0}
                  />
                  <TitleH4>
                    <Translate id='PROPERTIES' />
                  </TitleH4>
                </HeaderCollapsable>
                <ContentCollapsable open={mainCategoryToggle === 0}>
                  <CollapsableSubGroup>
                    <CollapsableContainer $hasBorderBottom>
                      <HeaderCollapsable
                        $direction='flex-start'
                        onClick={() => {
                          setSubCategoryToggle(
                            subCategoryToggle === 0 ? undefined : 0
                          )
                        }}
                      >
                        <ToggleImage
                          src={DropdownBlue}
                          $open={subCategoryToggle === 0}
                        />
                        <TitleH4 $light>
                          <Translate id='GENERAL_INFO' />
                        </TitleH4>
                      </HeaderCollapsable>
                      <ContentCollapsable open={subCategoryToggle === 0}>
                        <CollapsableSubGroup $content>
                          <GeneralInformation
                            info={
                              currentFormValues?.rawMaterialPropertiesSection
                            }
                            rawMaterialFamily={rawMaterialFamily}
                            productMeasuresUnit={productMeasuresUnit}
                          />
                        </CollapsableSubGroup>
                      </ContentCollapsable>
                    </CollapsableContainer>
                    <CollapsableContainer $hasBorderBottom>
                      <HeaderCollapsable
                        $direction='flex-start'
                        onClick={() => {
                          setSubCategoryToggle(
                            subCategoryToggle === 1 ? undefined : 1
                          )
                        }}
                      >
                        <ToggleImage
                          src={DropdownBlue}
                          $open={subCategoryToggle === 1}
                        />
                        <TitleH4 $light>
                          <Translate id='NAME_DESCRIPTION' />
                        </TitleH4>
                      </HeaderCollapsable>
                      <ContentCollapsable open={subCategoryToggle === 1}>
                        <CollapsableSubGroup $content>
                          <FieldArray
                            dispatch={dispatch}
                            name='rawMaterialPropertiesSection.productTranslation'
                            component={ProductDescriptions}
                          />
                        </CollapsableSubGroup>
                      </ContentCollapsable>
                    </CollapsableContainer>
                    <CollapsableContainer $hasBorderBottom>
                      <HeaderCollapsable
                        $direction='flex-start'
                        onClick={() => {
                          setSubCategoryToggle(
                            subCategoryToggle === 5 ? undefined : 5
                          )
                        }}
                      >
                        <ToggleImage
                          src={DropdownBlue}
                          $open={subCategoryToggle === 5}
                        />
                        <TitleH4 $light>
                          <Translate id='COLORS_AND_BARCODE' />
                        </TitleH4>
                      </HeaderCollapsable>
                      <ContentCollapsable open={subCategoryToggle === 5}>
                        <CollapsableSubGroup $content>
                          <FieldArray
                            name='rawMaterialPropertiesSection.infosRawMaterial'
                            component={ColorsAndCodes}
                          />
                        </CollapsableSubGroup>
                      </ContentCollapsable>
                    </CollapsableContainer>
                    <CollapsableContainer $hasBorderBottom>
                      <HeaderCollapsable
                        $direction='flex-start'
                        onClick={() => {
                          setSubCategoryToggle(
                            subCategoryToggle === 2 ? undefined : 2
                          )
                        }}
                      >
                        <ToggleImage
                          src={DropdownBlue}
                          $open={subCategoryToggle === 2}
                        />
                        <TitleH4 $light>
                          <Translate id='SUPPLIERS' />
                        </TitleH4>
                      </HeaderCollapsable>
                      <ContentCollapsable open={subCategoryToggle === 2}>
                        <CollapsableSubGroup $content>
                          <FieldArray
                            name='rawMaterialPropertiesSection.productSupplier'
                            component={Suppliers}
                            change={change}
                            suppliers={suppliersRawMaterial}
                          />
                        </CollapsableSubGroup>
                      </ContentCollapsable>
                    </CollapsableContainer>
                    <CollapsableContainer $hasBorderBottom>
                      <HeaderCollapsable
                        $direction='flex-start'
                        onClick={() => {
                          setSubCategoryToggle(
                            subCategoryToggle === 3 ? undefined : 3
                          )
                        }}
                      >
                        <ToggleImage
                          src={DropdownBlue}
                          $open={subCategoryToggle === 3}
                        />
                        <TitleH4 $light>
                          <Translate id='IMAGES' />
                        </TitleH4>
                      </HeaderCollapsable>
                      <ContentCollapsable open={subCategoryToggle === 3}>
                        <CollapsableSubGroup $content>
                          <FieldArray
                            component={RawMaterialImages}
                            name='rawMaterialPropertiesSection.productImage'
                            changeImages={(data) =>
                              dispatch(
                                change(
                                  'manage_raw_material',
                                  'rawMaterialPropertiesSection.productImage',
                                  data
                                )
                              )
                            }
                          />
                        </CollapsableSubGroup>
                      </ContentCollapsable>
                    </CollapsableContainer>
                  </CollapsableSubGroup>
                </ContentCollapsable>
              </CollapsableContainer>
              <CollapsableContainer $hasBorderBottom>
                <HeaderCollapsable
                  $direction='flex-start'
                  onClick={() => {
                    setSubCategoryToggle(undefined)
                    setMainCategoryToggle(
                      mainCategoryToggle === 4 ? undefined : 4
                    )
                  }}
                >
                  <ToggleImage
                    src={DropdownBlue}
                    $open={mainCategoryToggle === 4}
                  />
                  <TitleH4>
                    <Translate id='PRICE' />
                  </TitleH4>
                </HeaderCollapsable>
                <ContentCollapsable open={mainCategoryToggle === 4}>
                  <RawMaterialPrices name='rawMaterialPriceSection' />
                </ContentCollapsable>
              </CollapsableContainer>
            </Col>
          </Row>
        </PageContent>
        <SaveButton htmlType='submit'>
          <BaseButton auto htmlType='submit' type='primary' loading={saving}>
            <Translate id='SAVE' />
          </BaseButton>
        </SaveButton>
      </div>
    </PageForm>
  )
}

ManageRawMaterialPage.propTypes = {
  router: PropTypes.object.isRequired,
  suppliersRawMaterial: PropTypes.array.isRequired,
  rawMaterialFamily: PropTypes.array.isRequired,
  currentFormValues: PropTypes.object,
  dispatch: PropTypes.func,
  handleSubmit: PropTypes.func
}

ManageRawMaterialPage.defaultProps = {
  currentFormValues: {},
  dispatch: undefined,
  handleSubmit: undefined
}

const myComponent = reduxForm({
  form: 'manage_raw_material'
})(ManageRawMaterialPage)

export default withLocalize(connect((state) => ({
  currentFormValues: getFormValues('manage_raw_material')(state),
  suppliersRawMaterial: state.info.suppliersRawMaterial,
  rawMaterialFamily: state.info.rawMaterialFamily,
  productMeasuresUnit: state.info.productMeasuresUnit
}))(myComponent))
