import React from 'react'

import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'

import ProductTotalFooter from '../../../shared/components/inputs/Products/ProductTotalFooter'
import BaseTable from '../../../shared/components/table/BaseTable'
import ActiveTranslation from '../../../shared/logic/translations/ActiveTranslation'

const SmartProductsTable = ({fields}) => {
  const products = fields.getAll() || []
  const columns = [
    {
      title: <Translate id='REFERENCE' />,
      render: (data) => data?.productCombination?.reference || '-'
    },
    {
      title: <Translate id='EAN_CODE' />,
      render: (data) => data?.productCombination?.barcode || '-'
    },
    {
      title: <Translate id='CLIENT_REF' />,
      render: (data) => data?.productCombination?.referenceClient || '-'
    },
    {
      title: <Translate id='DESCRIPTION' />,
      dataIndex: 'productName'
    },
    {
      title: <Translate id='COLOR' />,
      render: (data) => (
        <ActiveTranslation
          value={data?.productCombination?.color?.colorTranslation || '-'}
          tag='name'
        />
      )
    },
    {
      title: <Translate id='SIZE' />,
      render: (data) => data?.productCombination?.size?.nameEu || '-'
    },
    {
      title: <Translate id='QUANTITY' />,
      dataIndex: 'units'
    },
    {
      title: <Translate id='PRICE' />,
      dataIndex: 'unitValue',
      render: (value) => `${value || 0}€`
    },
    {
      title: <Translate id='SUB_TOTAL' />,
      render: (data) => (
        <div>
          {((data.units || 0) * (data.unitValue || 0)).toFixed(2)}€
        </div>
      )
    }
  ]

  return (
    <>
      <BaseTable
        rowKey='reference'
        datasource={products}
        columns={columns}
        pagination={{render: false}}
      />
      <ProductTotalFooter
        products={products}
        quantityLabel='units'
        priceLabel='unitValue'
      />
    </>
  )
}

SmartProductsTable.propTypes = {
  fields: PropTypes.object.isRequired
}

export default SmartProductsTable
