import React, { useEffect, useState } from 'react'

import { Row, Col } from 'antd'
import PropTypes from 'prop-types'
import { Translate, withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'

import CancelBordered from '../../../assets/icons/cancel_border.svg'
import { GetB2bclientWorkgroupsDrop } from '../../../infra/requests/ClientRequests'
import BordelessButton from '../../../shared/components/buttons/BordelessButton'
import SearchInput from '../../../shared/components/inputs/SearchInput'
import SelectInput from '../../../shared/components/inputs/SelectInput'

const ProductFilters = ({
  filters,
  setFilters,
  productTypes,
  suppliers,
  productCategories,
  translate,
  isB2c,
  productStyles
}) => {
  const location = useLocation()
  const history = useHistory()

  const removeQueryParam = (paramKey) => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.delete(paramKey)
    const newSearch = searchParams.toString()
    history.replace({
      pathname: location.pathname,
      search: newSearch ? `?${newSearch}` : ''
    })
  }

  const clearSearch = () => {
    setFilters({ Search: '' })
    removeQueryParam('search')
  }

  const [clients, setClients] = useState([])
  useEffect(() => {
    async function init() {
      const { data, success } = await GetB2bclientWorkgroupsDrop()
      if (success) setClients(data.items)
    }
    init()
  }, [])

  const mainCategoryOptions = productCategories.filter(
    (cat) => !cat.parentCategoryId
  )

  const categoryOptions = productCategories.filter(
    (cat) => cat.parentCategoryId == filters.MainCategoryId
  )

  let subCategoryOptions = []
  if (filters.CategoryId) {
    subCategoryOptions = productCategories.filter(
      (cat) => cat.parentCategoryId == filters.CategoryId
    )
  }

  const queryParams = new URLSearchParams(location.search)
  const urlParam = queryParams.get('search')

  const getWorkgroup = () => {
    if (!filters.b2bclientId) {
      return []
    }
    const client = clients.find((wk) => wk.b2bclientId == filters.b2bclientId)
    return client.position
  }

  const onChangeField = (key, value, timeout = 0) => {
    const result = { ...filters };
    result[key] = value;

    if (key === 'CategoryId' && !value) {
      result.SubCategoryId = ''; // Clear SubCategoryId when CategoryId is deselected
    }

    // Clear workgroup filter when client filter is cleared OR changed
    if (key === 'b2bclientId') {
      result.positionId = ''; // Clear workgroup filter
      result.workgroups = []; // Clear workgroups list (if you have one)
    }

    if (key === 'MainCategoryId') {
      result.CategoryId = ''; // Clear CategoryId when MainCategoryId changes
      result.SubCategoryId = ''; // Clear SubCategoryId when MainCategoryId changes

      // Update categoryOptions based on the new MainCategoryId value
      const categoryOptions = productCategories.filter(
        (cat) => cat.parentCategoryId === value
      );
      result.CategoryOptions = categoryOptions;

      // Clear CategoryId if the selected Category is not present in the new categoryOptions
      if (!categoryOptions.find((cat) => cat.categoryId === result.CategoryId)) {
        result.CategoryId = '';
      }

      // Clear SubCategoryId if the selected SubCategory is not present in the new categoryOptions
      if (
        !categoryOptions.find(
          (cat) => cat.categoryId === result.SubCategoryId
        )
      ) {
        result.SubCategoryId = '';
      }
    }

    // Reset lower filters if parent filter is changed
    if (key === 'CategoryId') {
      result.SubCategoryId = ''; // Clear SubCategoryId when CategoryId changes
    }

    // Clear workgroup filter when client filter is cleared
    if (key === 'b2bclientId' && !value) {
      result.positionId = ''; // Clear workgroup filter
    }

    setFilters(result, timeout);
  };

  useEffect(() => {
    if (urlParam) {
      onChangeField('Search', urlParam, 1000)
    }
  }, [urlParam])

  return (
    <Row align='bottom' gutter={[20, 20]}>
      <Col xs={5}>
        <SelectInput
          label={<Translate id='TYPE' />}
          input={{
            value: filters.ProductTypeId,
            onChange: (value) => onChangeField('ProductTypeId', value)
          }}
          dataKey='productTypeId'
          dataLabel='productTypeTranslation'
          placeholder={<Translate id='TYPE' />}
          data={productTypes.filter((t) => t.productTypeId !== 4)}
        />
      </Col>
      <Col xs={5}>
        <SelectInput
          label={<Translate id='CLIENT' />}
          input={{
            value: filters.b2bclientId,
            onChange: (value) => onChangeField('b2bclientId', value)
          }}
          dataKey='b2bclientId'
          dataLabel='name'
          placeholder={<Translate id='CLIENT' />}
          data={clients}
        />
      </Col>
      {isB2c && (
        <Col xs={5}>
          <SelectInput
            label={<Translate id='STYLE' />}
            input={{
              value: filters.characteristicValueId,
              onChange: (value) =>
                onChangeField('characteristicValueId', value)
            }}
            dataKey='characteristicValueId'
            dataLabel='characteristicValueTranslation'
            placeholder={<Translate id='STYLE' />}
            data={productStyles}
          />
        </Col>
      )}
      {!isB2c && (
        <Col xs={5}>
          <SelectInput
            label={<Translate id='WORKGROUP' />}
            input={{
              value: filters.positionId,
              onChange: (value) => onChangeField('positionId', value)
            }}
            disabled={!filters.b2bclientId}
            dataKey='positionId'
            dataLabel='name'
            placeholder={<Translate id='WORKGROUP' />}
            data={getWorkgroup()}
          />
        </Col>
      )}

      <Col xs={9} style={{ textAlign: 'right' }}>
        <SearchInput
          auto
          input={{
            value: filters.Search,
            onChange: (value) => onChangeField('Search', value, 1000),
            onSearch: () => onChangeField('', '')
          }}
          placeholder={translate('SEARCH')}
        />
      </Col>

      <Col xs={5}>
        <SelectInput
          label={<Translate id='SUPPLIER' />}
          input={{
            value: filters.supplierId,
            onChange: (value) => onChangeField('supplierId', value)
          }}
          dataKey='supplierId'
          dataLabel='name'
          placeholder={<Translate id='SUPPLIER' />}
          data={suppliers}
        />
      </Col>
      <Col xs={5}>
        <SelectInput
          label={<Translate id='MAIN_CATEGORY' />}
          input={{
            value: filters.MainCategoryId,
            onChange: (value) => {
              onChangeField('MainCategoryId', value)
              if (!value) {
                onChangeField('CategoryId', '')
                onChangeField('MainCategoryId', '')
              }
            }
          }}
          dataKey='categoryId'
          dataLabel='categoryTranslation'
          placeholder={<Translate id='MAIN_CATEGORY' />}
          data={mainCategoryOptions}
        />
      </Col>
      <Col xs={5}>
        <SelectInput
          label={<Translate id='CATEGORY' />}
          input={{
            value: filters.CategoryId,
            onChange: (value) => onChangeField('CategoryId', value)
          }}
          dataKey='categoryId'
          dataLabel='categoryTranslation'
          placeholder={<Translate id='CATEGORY' />}
          disabled={!filters.MainCategoryId}
          data={categoryOptions}
        />
      </Col>
      <Col xs={5}>
        <SelectInput
          label={<Translate id='SUB_CATEGORY' />}
          input={{
            value: filters.SubCategoryId,
            onChange: (value) => onChangeField('SubCategoryId', value)
          }}
          dataKey='categoryId'
          dataLabel='categoryTranslation'
          placeholder={<Translate id='SUB_CATEGORY' />}
          disabled={!filters.CategoryId || subCategoryOptions.length === 0}
          data={subCategoryOptions}
        />
      </Col>
      <Col xs={4} style={{ textAlign: 'right' }}>
        <BordelessButton auto onClick={clearSearch} imagePath={CancelBordered}>
          <Translate id='CLEAR_SEARCH' />
        </BordelessButton>
      </Col>
    </Row>
  )
}

ProductFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  productTypes: PropTypes.array.isRequired,
  suppliers: PropTypes.array.isRequired,
  productCategories: PropTypes.array.isRequired,
  productStyles: PropTypes.array.isRequired,
  isB2c: PropTypes.bool
}

ProductFilters.defaultProps = {
  isB2c: false
}

const mapStateToProps = (state) => ({
  productTypes: state.info.productTypes,
  suppliers: state.info.suppliers,
  productCategories: state.info.productCategories,
  productStyles: state.info.productStyles
})

export default withLocalize(connect(mapStateToProps)(ProductFilters))
