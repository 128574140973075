import React from 'react'

import PropTypes from 'prop-types'

import {
  EditOrderTitleContainer,
  EditOrderName,
  EditOrderStatusContainer,
  EditOrderStatusContent
} from './Styles'

const EditOrderStatus = ({label, values, last}) => (
  <EditOrderTitleContainer $last={last}>
    <EditOrderName>{label}</EditOrderName>
    {values.map((value, index) => (
      <EditOrderStatusContainer
        key={index}
        $last={values.length === index + 1}
        $background={value.background}
        $border={value.border}
        $color={value.color}
      >
        <EditOrderStatusContent>
          {value.icon}
          {value.text}
        </EditOrderStatusContent>
      </EditOrderStatusContainer>
    ))}
  </EditOrderTitleContainer>
)

EditOrderStatus.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number
  ]),
  values: PropTypes.array,
  last: PropTypes.bool
}

EditOrderStatus.defaultProps = {
  label: '',
  values: [],
  last: false
}

export default EditOrderStatus
