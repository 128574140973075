import React, {Component} from 'react'

import {Col, Row} from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import EditIcon from '../../../../assets/icons/edit_blue.svg'
import {GetContactMessages} from '../../../../infra/requests/ContactRequests'
import DateRangeInput from '../../../../shared/components/inputs/DateRangeInput'
import SearchInput from '../../../../shared/components/inputs/SearchInput'
import BaseTable from '../../../../shared/components/table/BaseTable'
import ConstructQuery from '../../../../shared/logic/queries/EndpointQueries'

let timeout

class UserMessages extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      rows: [],
      page: 1,
      pageSize: 20,
      total: 0,
      filters: {}
    }
    this.columns = [
      {
        title: <Translate id='PROCESS' />,
        type: 'text',
        render: (data) => (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            {data?.process?.number}
            <img
              src={EditIcon}
              alt='edit'
              style={{width: 16, height: 16, cursor: 'pointer'}}
              onClick={() =>
                props.router.history.push(
                  `/messaging/process/${data?.process?.processId}`,
                  {from: `/contact/:id`}
                )
              }
            />
          </div>
        )
      },
      {
        title: <Translate id='STATE' />,
        type: 'text',
        render: (data) => data?.process?.state?.name || ''
      },
      {
        title: <Translate id='ORDER' />,
        type: 'text',
        render: (data) =>
          data?.process?.order && data?.process?.order[0]
            ? data.process.order[0].orderId
            : ''
      },
      {
        title: <Translate id='SUBJECT' />,
        type: 'text',
        render: (data) => data?.process?.subject || ''
      },
      {
        title: <Translate id='DATE' />,
        type: 'text',
        render: (data) => moment(data?.date).fromNow()
      }
    ]
  }

  componentDidMount() {
    this.updateTable()
  }

  updateTable = async () => {
    const {filters, page, pageSize} = this.state
    const {id} = this.props

    if (id && id !== 'new') {
      this.setState({loading: true})
      const {data} = await GetContactMessages(
        id,
        page,
        pageSize,
        ConstructQuery(filters)
      )
      this.setState({
        rows: data?.items,
        total: data?.totalItems,
        loading: false
      })
    }
  }

  onChangePagination = (page) => this.setState({page}, this.updateTable)

  setFilters = (values, time) => {
    this.setState({filters: values}, () => {
      if (timeout) clearTimeout(timeout)
      timeout = setTimeout(this.updateTable, time)
    })
  }

  render() {
    const {
      search,
      filters,
      dateRange,
      rows,
      page,
      pageSize,
      total,
      loading
    } = this.state
    const {translate} = this.props

    return (
      <Row gutter={[24, 16]}>
        <Col xs={6} offset={12}>
          <SearchInput
            input={{
              value: search,
              onChange: (value) =>
                this.setFilters({...filters, search: value}, 1000),
              onSearch: () => this.setFilters({...filters, '': ''}, 0)
            }}
            placeholder={translate('SEARCH')}
          />
        </Col>
        <Col xs={6}>
          <DateRangeInput
            input={{
              value: dateRange,
              onChange: (date) => {
                this.setFilters(
                  {
                    ...filters,
                    StartDate: date ? date[0].startOf('day') : undefined,
                    EndDate: date ? date[1].endOf('day') : undefined
                  },
                  0
                )
              }
            }}
          />
        </Col>
        <Col xs={24}>
          <BaseTable
            rowKey='messageId'
            datasource={rows}
            columns={this.columns}
            emptyMessage={<Translate id='THERE_ARE_NO_MESSAGES' />}
            pagination={{
              render: true,
              page,
              pageSize,
              total,
              onChangePagination: this.onChangePagination
            }}
            loading={loading}
          />
        </Col>
      </Row>
    )
  }
}

UserMessages.propTypes = {
  router: PropTypes.object.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
}

export default withLocalize(connect(() => ({}))(UserMessages))
