import React, {useState, useEffect} from 'react'

import {DownloadOutlined} from '@ant-design/icons'
import {Row, Col, Popover} from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'

import DropdownIcon from '../../../../assets/icons/dropdown_blue.svg'
import Info from '../../../../assets/icons/info.svg'
import {GetOrderOS} from '../../../../infra/requests/OrdersB2BRequests'
import CollapsableHeaderTitle from '../../../../pages/suppliers/components/CollapsableHeaderTitle'
import {
  POBaseContainer,
  POHeaderCollapsable,
  POLeftContainer,
  POTableContainer
} from '../../../../pages/suppliers/components/POStyles'
import DownloadAttachment from '../../../logic/attachments/DownloadAttachment'
import RoundCurrency from '../../../logic/numbers/RoundCurrency'
import ActiveTranslation from '../../../logic/translations/ActiveTranslation'
import {
  ErrorColor,
  PrimaryColor,
  SuccessColor,
  TableTextColor
} from '../../../styles/_colors'
import {
  ToggleImage,
  TextPopover,
  CircleIcon,
  StyledHR,
  OverdueAlert
} from '../../../styles/BasicStyles'
import BaseLoading from '../../loading/BaseLoading'
import BaseTable from '../../table/BaseTable'
import {EmptyMessage, HoverFile} from './ProductsStyles'

const calculateOrderTotal = (SO) => {
  let total = 0
  if (SO && SO.orderSupplier) {
    SO.orderSupplier.forEach((item) => {
      if (item.currencyId !== 1) {
        total = parseFloat(total) + parseFloat(item.priceNegotiated * item.quantityOrdered * item.exchangeRateUsed)
      } else {
        total = parseFloat(total) + parseFloat(item.priceNegotiated * item.quantityOrdered)
      }
    })
  }
  return RoundCurrency(total)
}

const OrderSupplierTable = ({order}) => {
  const [osList, setList] = useState([])
  const [open, setOpen] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const init = async () => {
      const {data, success} = await GetOrderOS(order)
      if (success) setList(data?.items || [])
      setLoading(false)
    }
    init()
  }, [])

  const calculateDaysOverdue = (value) => {
    let dif = 0

    if (
      value.orderStatus.orderStatusId == 1 ||
      value.orderStatus.orderStatusId == 2
    ) {
      if (value?.dateExpected) {
        const compare = moment().isAfter(value?.dateExpected)

        if (compare) {
          dif = moment().diff(moment.min(value.dateExpected), 'days')
        }
      }
    }

    return dif
  }

  const renderHeaderFiles = (attachments) => {
    if (Array.isArray(attachments) && attachments.length) {
      const content = attachments.map((attach) => (
        <HoverFile
          key={attach.attachmentId}
          onClick={() => DownloadAttachment(attach)}
        >
          {attach.name}
        </HoverFile>
      ))
      return (
        <CollapsableHeaderTitle
          name={<Translate id='FILES' />}
          description={
            <Popover content={content} title={<Translate id='ATTACHMENTS' />} trigger='hover'>
              <DownloadOutlined style={{cursor: 'pointer'}} />
            </Popover>
          }
        />
      )
    }
    return null
  }

  const renderFiles = (attachments) => {
    if (Array.isArray(attachments) && attachments.length) {
      const content = attachments.map((attach) => (
        <HoverFile
          key={attach.attachmentId}
          onClick={() => DownloadAttachment(attach)}
        >
          {attach.name}
        </HoverFile>
      ))
      return (
        <Popover content={content} title={<Translate id='ATTACHMENTS' />} trigger='hover'>
          <DownloadOutlined style={{cursor: 'pointer'}} />
        </Popover>
      )
    }
    return null
  }

  const renderDescription = (data) => {
    if (data.serviceId) {
      const product = data?.product?.productTranslation
      const color = data?.color?.colorTranslation
      const size = data?.size?.nameEu
      const width = data?.productWidth?.productWidthTranslation

      return (
        <div>
          <ActiveTranslation
            value={data?.service?.serviceTranslation}
            tag='name'
          />{' '}
          (
          <span>
            <ActiveTranslation value={product} tag='name' />
          </span>
          ,{' '}
          <span>
            <ActiveTranslation value={color} tag='name' />
          </span>
          , <span>{size}</span>,{' '}
          <span>
            <ActiveTranslation value={width} tag='name' />
          </span>
          )
        </div>
      )
    }
    return (
      <ActiveTranslation
        value={data?.product?.productTranslation}
        tag='name'
      />
    )
  }

  const renderColumns = (OS) => {
    const columns = [
      {
        type: 'text',
        title: 'PO',
        render: (data) => `#${data?.orderPo?.orderPoid}`
      },
      {
        type: 'text',
        title: <Translate id='REF_SKYPRO' />,
        render: (value) => {
          const content = (
            <>
              <div style={{display: 'flex'}}>
                <TextPopover $bold>
                  <Translate id='REF_SUPPLIER' />:
                </TextPopover>
                <TextPopover>{value.referenceSupplier || 'N/A'}</TextPopover>
              </div>
              <StyledHR $space='7' />
              <div style={{display: 'flex'}}>
                <TextPopover $bold>
                  <Translate id='EAN_CODE' />:
                </TextPopover>
                <TextPopover>{value.product.barcode || 'N/A'}</TextPopover>
              </div>
            </>
          )
          return (
            <>
              <Popover
                placement='bottomRight'
                trigger='hover'
                content={content}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  {value.referenceSkypro || 'N/A'}
                  <CircleIcon>
                    <img src={Info} alt={<Translate id='INFO' />} />
                  </CircleIcon>
                </div>
              </Popover>
            </>
          )
        }
      },
      {
        type: 'text',
        title: <Translate id='DESCRIPTION' />,
        render: renderDescription
      },
      {
        type: 'text',
        dataIndex: 'quantityOrdered',
        title: <Translate id='ORDER_QTY' />
      },
      {
        type: 'text',
        dataIndex: 'priceNegotiated',
        title: <Translate id='UNIT_COST_EUR' />,
        render: (value, row) => `${RoundCurrency(value * row.exchangeRateUsed)}€`
      },
      {
        type: 'text',
        title: <Translate id='PRICE_EUROS' />,
        render: (data) => `${RoundCurrency(data.priceNegotiated * data.quantityOrdered * data.exchangeRateUsed)}€`
      },
      {
        type: 'text',
        dataIndex: 'deliveryContactDetail',
        title: <Translate id='LOCATION' />,
        render: (value) => value?.warehouseName || 'N/A'
      },
      {
        type: 'text',
        dataIndex: 'orderStatus',
        title: <Translate id='ORDER_STATUS' />,
        render: (value) => (
          <div
            style={{
              color:
              value?.orderStatusId == 1
                ? PrimaryColor
                : value?.orderStatusId == 5
                  ? SuccessColor
                  : value?.orderStatusId == 6
                    ? ErrorColor
                    : TableTextColor
            }}
          >
            <ActiveTranslation
              value={value?.orderStatusTranslation}
              tag='name'
            />
          </div>
        )
      },
      {
        type: 'text',
        title: <Translate id='DELIVERY' />,
        render: (value) => {
          const dif = calculateDaysOverdue(value)

          const content = (
            <div style={{display: 'flex', alignItems: 'center'}}>
              <TextPopover $bold>
                <Translate id='DAYS_OVERDUE' />:
              </TextPopover>
              <TextPopover>
                {dif !== 0 && (
                  <OverdueAlert>{dif > 9 ? dif : `0${dif}`}</OverdueAlert>
                )}
              </TextPopover>
            </div>
          )
          return (
            <>
              {dif !== 0 ? (
                <Popover
                  placement='bottomRight'
                  trigger='hover'
                  content={content}
                >
                  <div
                    style={{
                      color: ErrorColor,
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    {value.dateExpected
                      ? moment(value.dateExpected).format('DD/MM/YYYY')
                      : 'N/A'}
                  </div>
                </Popover>
              ) : value.dateExpected ? (
                moment(value.dateExpected).format('DD/MM/YYYY')
              ) : (
                'N/A'
              )}
            </>
          )
        }
      },
      {
        type: 'text',
        title: <Translate id='FILES' />,
        dataIndex: 'attachment',
        render: (value) => renderFiles(value)
      }
    ]

    const otherCurrency = OS.orderSupplier.find((o) => o.currencyId !== 1)

    if (otherCurrency) {
      columns.splice(5, 0, {
        type: 'text',
        dataIndex: 'priceNegotiated',
        title: <Translate id='UNIT_COST' />,
        render: (value, row) => row.currencyId !== 1 ?
          `${RoundCurrency(value)} ${row?.currency?.currencyCode}`
          : '-'
      })
      columns.splice(7, 0, {
        type: 'text',
        title: <Translate id='PRICE' />,
        render: (data) => data.currencyId !== 1
          ? `${RoundCurrency(data.priceNegotiated * data.quantityOrdered)} ${data.currency?.currencyCode}`
          : '-'
      })
    }

    return columns
  }

  const openPO = (index) => {
    if (index === open) {
      setOpen()
    } else {
      setOpen(index)
    }
  }

  if (loading) return <BaseLoading margin='30' />

  if (osList.length === 0) {
    return (
      <EmptyMessage>
        <Translate id='NO_SUPPLIER_ORDERS' />
      </EmptyMessage>
    )
  }

  return osList.map((OS, index) => (
    <Row key={index}>
      <Col xs={24}>
        <POBaseContainer $jointly>
          <POHeaderCollapsable onClick={() => openPO(index)}>
            <POLeftContainer $left>
              <ToggleImage
                $marginTop
                src={DropdownIcon}
                $open={open === index}
              />
              <CollapsableHeaderTitle
                name={<Translate id='SET_ID' />}
                description={`#${OS?.orderSupplierSetId}`}
              />
              <CollapsableHeaderTitle
                name={<Translate id='SUPPLIER' />}
                description={OS?.supplier?.name}
              />
              <CollapsableHeaderTitle
                name={<Translate id='ORDER_DATE' />}
                description={moment(OS?.dateCreated).format('DD/MM/YYYY')}
              />
              <CollapsableHeaderTitle
                name={<Translate id='PAYMENT_CONDITIONS' />}
                description={OS?.paymentCondition?.name}
              />
              <CollapsableHeaderTitle
                name={<Translate id='TOTAL_VALUE_EUR' />}
                description={`${calculateOrderTotal(OS)}€`}
              />
              {renderHeaderFiles(OS?.attachment)}
            </POLeftContainer>
          </POHeaderCollapsable>
          <POTableContainer open={open === index}>
            {open === index && (
              <BaseTable
                rowKey='orderSupplierId'
                columns={renderColumns(OS)}
                datasource={OS.orderSupplier}
                pagination={{render: false}}
                scroll={{}}
              />
            )}
          </POTableContainer>
        </POBaseContainer>
      </Col>
    </Row>
  ))
}

OrderSupplierTable.propTypes = {
  order: PropTypes.string.isRequired
}

export default OrderSupplierTable
