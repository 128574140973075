/* eslint-disable react/prefer-stateless-function */
import React, {useState} from 'react'

import {Row, Col} from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import Trash from '../../../assets/icons/remove.svg'
import AlertService from '../../../shared/components/alert/AlertService'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import DateInput from '../../../shared/components/inputs/DateInput'
import DateRangeInput, {FutureRanges} from '../../../shared/components/inputs/DateRangeInput'
import SelectInput from '../../../shared/components/inputs/SelectInput'
import TextInput from '../../../shared/components/inputs/TextInput'
import BaseTable from '../../../shared/components/table/BaseTable'
import {Margin} from '../../../shared/styles/BasicStyles'
import {SubTitle} from '../ClientStyles'

const Columns = [
  {
    dataIndex: 'contractType',
    type: 'text',
    title: <Translate id='CONTRACT_TYPE' />,
    render: (value) => <div>{value?.name}</div>
  },
  {
    dataIndex: 'total',
    type: 'text',
    title: <Translate id='TOTAL' />
  },
  {
    dataIndex: 'dateFrom',
    type: 'text',
    title: <Translate id='FROM' />,
    render: (value) => <div>{moment(value).format('DD-MM-YYYY')}</div>
  },
  {
    dataIndex: 'dateTo',
    type: 'text',
    title: <Translate id='TO' />,
    render: (value) => <div>{moment(value).format('DD-MM-YYYY')}</div>
  },
  {
    dataIndex: 'contractEnd',
    type: 'text',
    title: <Translate id='CONTRACT_END' />,
    render: (value) => <div>{moment(value).format('DD-MM-YYYY')}</div>
  }
]

const ContractPlanning = ({fields, contractTypes, translate}) => {
  const [selected, setSelected] = useState([])
  const [type, setType] = useState('')
  const [total, setTotal] = useState('')
  const [date, setDate] = useState('')
  const [final, setFinal] = useState('')

  const rows = fields.getAll() || []

  const addContract = () => {
    const contractType = contractTypes.find((x) => x.contractTypeId == type)

    if (!contractType) {
      return AlertService.error(
        translate('INVALID_CONTRACT_TYPE'),
        translate('INVALID_CONTRACT_TYPE_MESSAGE')
      )
    }

    if (final.isBefore(date[1])) {
      return AlertService.error(
        translate('INVALID_CONTRACT_END'),
        translate('INVALID_CONTRACT_END_MESSAGE')
      )
    }

    const object = {
      employeePlanId: rows?.length,
      contractType,
      contractTypeId: type,
      total,
      dateFrom: date[0],
      dateTo: date[1],
      contractEnd: final
    }
    fields.push(object)
    setType('')
    setTotal('')
    setDate('')
    setFinal('')
  }

  const removeContract = () => {
    const indexes = selected.map((element) =>
      rows.findIndex((x) => x.employeePlanId === element.key)
    )
    indexes
      .sort((a, b) => (a > b ? -1 : 1))
      .forEach((i) => fields.remove(i))
    setSelected([])
  }

  return (
    <>
      <Row xs={24}>
        <Col xs={24}>
          <SubTitle>
            <Translate id='CONTACT_PLANNING' />
          </SubTitle>
        </Col>
      </Row>
      <Margin size={30} />
      <Row gutter={[24, 24]} align='bottom'>
        <Col xs={4}>
          <SelectInput
            input={{value: type, onChange: setType}}
            label={<Translate id='CONTRACT_TYPE' />}
            data={contractTypes}
            placeholder={<Translate id='CHOOSE_TYPE' />}
            dataLabel='name'
            dataKey='contractTypeId'
          />
        </Col>
        <Col xs={4}>
          <TextInput
            input={{value: total, onChange: setTotal}}
            label={<Translate id='TOTAL_EMPLOYEES' />}
            placeholder={translate('TOTAL_EMPLOYEES')}
          />
        </Col>
        <Col xs={7}>
          <DateRangeInput
            input={{value: date, onChange: setDate}}
            label={<Translate id='DURATION' />}
            ranges={FutureRanges}
            disabledDate={(value) => moment().startOf('day').isAfter(value.endOf('day'))}
          />
        </Col>
        <Col xs={5}>
          <DateInput
            input={{value: final, onChange: setFinal}}
            label={<Translate id='CONTRACT_END' />}
            disabledDate={(value) => moment().startOf('day').isAfter(value.endOf('day'))}
          />
        </Col>
        <Col xs={4}>
          <BaseButton
            auto
            type='secondary'
            onClick={addContract}
            disabled={type === '' || date === '' || total === '' || final === ''}
          >
            <Translate id='ADD' />
          </BaseButton>
        </Col>
      </Row>
      <Margin size={40} />
      <Row>
        <Col xs={24}>
          <BaseButton
            auto
            type='secondary'
            imagePath={Trash}
            onClick={removeContract}
            disabled={selected.length <= 0}
          >
            <Translate id='DELETE' />
          </BaseButton>
        </Col>
      </Row>
      <Margin size={14} />
      <Row>
        <Col xs={24}>
          <BaseTable
            rowKey='employeePlanId'
            datasource={rows}
            columns={Columns}
            pagination={{
              render: false
            }}
            rowSelection={{
              selected,
              onRowSelection: setSelected
            }}
          />
        </Col>
      </Row>
    </>
  )
}

ContractPlanning.propTypes = {
  fields: PropTypes.object.isRequired
}

export default withLocalize(
  connect((state) => ({
    contractTypes: state.info.contractTypes
  }))(ContractPlanning)
)
