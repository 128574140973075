import React, {useState} from 'react'

import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {Field, initialize} from 'redux-form'

import {ChangeOrderAddressBilling, ChangeOrderAddressDelivery} from '../../../../infra/requests/OrdersB2CRequests'
import Address from '../../../../shared/components/address/AddressComponent'
import AddressModal from '../../../../shared/components/address/AddressModal'
import AlertService from '../../../../shared/components/alert/AlertService'
import BackButton from '../../../../shared/components/buttons/BackButton'
import NextButton from '../../../../shared/components/buttons/NextButton'
import CheckboxInput from '../../../../shared/components/inputs/CheckboxInput'
import {Margin} from '../../../../shared/styles/BasicStyles'
import {TransformOrderBillingAddress, TransformOrderShippingAddress} from '../helpers/TransformContactsAddresses'
import ValidateAddresses from '../helpers/ValidateAddresses'
import EditOrderHeaderTitle from './EditOrderHeaderTitle'
import {EditOrderTitle} from './Styles'

const AddressData = ({
  formValues,
  countries,
  handleNext,
  setCurrentStep,
  dispatch,
  translate
}) => {
  const [openModal, setModal] = useState(false)
  const shippingAddress = TransformOrderShippingAddress(formValues)
  const billingAddress = TransformOrderBillingAddress(formValues)

  const disabled = formValues?.orderStatusId > 2

  const onPressNext = () => {
    const errors = ValidateAddresses(formValues)
    if (errors.length > 0) {
      return AlertService.showTranslatedValidations('Missing information', errors, translate)
    }
    handleNext(2, formValues)
  }

  const closeModal = () => {
    setModal(false)
    dispatch(initialize('manage_new_address', {}))
  }

  const onSaveAddress = async (values) => {
    if (formValues.billingDataSet) {
      await ChangeOrderAddressDelivery(formValues.orderId, values)
      await ChangeOrderAddressBilling(formValues.orderId, values)
      dispatch(
        initialize('manage-order-b2b2c-b2c', {
          ...formValues,
          billingName: values.name,
          billingAddressLine1: values.addressLine1,
          billingAddressLine2: values.addressLine2,
          billingAddressLine3: values.addressLine3,
          billingCity: values.city,
          billingCountryId: values.countryId,
          billingRegion: values.region,
          billingZipCode: values.zipCode,
          billingStreetNumber: values.streetNumber,
          deliveryName: values.name,
          deliveryAddressLine1: values.addressLine1,
          deliveryAddressLine2: values.addressLine2,
          deliveryAddressLine3: values.addressLine3,
          deliveryCity: values.city,
          deliveryCountryId: values.countryId,
          deliveryRegion: values.region,
          deliveryZipCode: values.zipCode,
          deliveryStreetNumber: values.streetNumber
        })
      )
    } else if (values.openIndex === 0) {
      await ChangeOrderAddressDelivery(formValues.orderId, values)
      dispatch(
        initialize('manage-order-b2b2c-b2c', {
          ...formValues,
          deliveryName: values.name,
          deliveryAddressLine1: values.addressLine1,
          deliveryAddressLine2: values.addressLine2,
          deliveryAddressLine3: values.addressLine3,
          deliveryCity: values.city,
          deliveryCountryId: values.countryId,
          deliveryRegion: values.region,
          deliveryZipCode: values.zipCode,
          deliveryStreetNumber: values.streetNumber
        })
      )
    } else {
      await ChangeOrderAddressBilling(formValues.orderId, values)
      dispatch(
        initialize('manage-order-b2b2c-b2c', {
          ...formValues,
          billingName: values.name,
          billingAddressLine1: values.addressLine1,
          billingAddressLine2: values.addressLine2,
          billingAddressLine3: values.addressLine3,
          billingCity: values.city,
          billingCountryId: values.countryId,
          billingRegion: values.region,
          billingZipCode: values.zipCode,
          billingStreetNumber: values.streetNumber
        })
      )
    }
    setModal(false)
    dispatch(initialize('manage_new_address', {}))
  }

  const openEditAddress = (address, index) => {
    dispatch(
      initialize('manage_new_address', {...address, openIndex: index})
    )
    setModal(true)
  }

  const handleToggleAddress = async (value) => {
    if (value) {
      await ChangeOrderAddressBilling(formValues.orderId, TransformOrderShippingAddress(formValues))
      dispatch(
        initialize('manage-order-b2b2c-b2c', {
          ...formValues,
          billingDataSet: true,
          billingName: formValues?.deliveryName,
          billingAddressLine1: formValues?.deliveryAddressLine1,
          billingAddressLine2: formValues?.deliveryAddressLine2,
          billingAddressLine3: formValues?.deliveryAddressLine3,
          billingCity: formValues?.deliveryCity,
          billingCountryId: formValues?.deliveryCountryId,
          billingRegion: formValues?.deliveryRegion,
          billingZipCode: formValues?.deliveryZipCode,
          billingStreetNumber: formValues?.deliveryStreetNumber
        })
      )
    } else {
      dispatch(
        initialize('manage-order-b2b2c-b2c', {
          ...formValues,
          billingDataSet: false,
          billingName: 'Billing info',
          billingAddressLine1: '',
          billingAddressLine2: '',
          billingAddressLine3: '',
          billingCity: '',
          billingCountryId: '',
          billingRegion: '',
          billingZipCode: '',
          billingStreetNumber: ''
        })
      )
    }
  }

  return (
    <>
      <Row>
        <Col xs={12} lg={6}>
          <EditOrderTitle>
            <Translate id='SHIPPING_ADDRESS' />
          </EditOrderTitle>
          <Address
            countries={countries}
            address={shippingAddress}
            openAddress={disabled ? false : () => openEditAddress(shippingAddress, 0)}
          />
        </Col>
        <Col xs={12} lg={6}>
          <EditOrderTitle>
            <Translate id='BILLING_ADDRESS' />
          </EditOrderTitle>
          <Address
            countries={countries}
            address={billingAddress}
            openAddress={formValues.billingDataSet || disabled ?
              false :
              () => openEditAddress(billingAddress, 1)}
          />
          <Margin size={20} />
          <Field
            name='billingDataSet'
            component={CheckboxInput}
            label={<Translate id='SAME_SHIPPING_ADDRESS' />}
            afterChange={handleToggleAddress}
            disabled={disabled}
          />
        </Col>
      </Row>
      <Margin size={50} />
      <Row>
        <Col xs={24}>
          <EditOrderTitle>
            <Translate id='CONTACTS' />
          </EditOrderTitle>
        </Col>
      </Row>
      <Margin size={15} />
      <Row>
        <Col xs={12} lg={4}>
          <EditOrderHeaderTitle
            label={<Translate id='NAME' />}
            value={formValues?.deliveryContactDetail?.name || 'N/A'}
          />
        </Col>
        <Col xs={12} lg={4}>
          <EditOrderHeaderTitle
            label={<Translate id='MOBILE_NUMBER' />}
            value={formValues?.deliveryTelephone || formValues?.deliveryContactDetail?.telephone || formValues?.deliveryContactDetail?.mobile || 'N/A'}
          />
        </Col>
        <Col xs={12} lg={4}>
          <EditOrderHeaderTitle
            label={<Translate id='EMAIL' />}
            value={formValues?.deliveryContactDetail?.email || 'N/A'}
          />
        </Col>
      </Row>
      <Margin size={50} />
      <Row>
        <Col xs={12}>
          <BackButton
            label={<Translate id='BACK' />}
            onClick={() => setCurrentStep(0)}
          />
        </Col>
        <Col xs={12} style={{textAlign: 'right'}}>
          <NextButton
            label={<Translate id='NEXT' />}
            onClick={onPressNext}
          />
        </Col>
      </Row>

      <AddressModal
        open={openModal}
        onClose={closeModal}
        onSave={onSaveAddress}
        countries={countries}
      />
    </>
  )
}

AddressData.propTypes = {
  countries: PropTypes.array.isRequired
}

AddressData.defaultProps = {
}

export default withLocalize(AddressData)
