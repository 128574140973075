import EmptyImageIcon from '../../../assets/placeholder.jpg'

const GetCoverImage = (images) => {
  if (images?.length > 0) {
    const found = images.find((elem) => elem.cover === true)

    if (found && found?.image?.url) {
      return found.image.url
    }
    if (found && found?.image?.filename) {
      return `${window.env.REACT_APP_API_FILES}/products/${found?.image?.filename}`
    }
    return EmptyImageIcon
  }
  return EmptyImageIcon
}

export default GetCoverImage
