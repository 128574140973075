import React, {useEffect, useState} from 'react'

import {Row, Col} from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {useParams} from 'react-router'
import {change, FieldArray, arrayPush, arrayRemove} from 'redux-form'

import {
  GetCarriers,
  GetOrderB2CDetails, GetOrderPackagesInfo, GetOrderShipments, SendOrderItems
} from '../../../../infra/requests/OrdersB2CRequests'
import AlertService from '../../../../shared/components/alert/AlertService'
import BackButton from '../../../../shared/components/buttons/BackButton'
import NextButton from '../../../../shared/components/buttons/NextButton'
import BaseLoading from '../../../../shared/components/loading/BaseLoading'
import AsyncForEach from '../../../../shared/logic/arrays/AsyncForEach'
import {Margin} from '../../../../shared/styles/BasicStyles'
import TransformPendingShipment from '../helpers/TransformPendingShipment'
import ShippingOrderBoxes from './ShippingOrderBoxes'

const ShippingData = ({
  formValues,
  translate,
  dispatch,
  handleNext,
  setCurrentStep
}) => {
  const {id} = useParams()
  const [carriers, setCarriers] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedService, setSelectedService] = useState([])

  useEffect(() => {
    async function initialize() {
      const {data} = await GetCarriers()
      setCarriers(data || [])
    }

    const shippingOrderBoxes = formValues.orderBox.filter((element) => !element.sent)
    if (shippingOrderBoxes.length > 0) {
      const total = shippingOrderBoxes.reduce((acc, item) => acc + item.items.reduce((accSub, itemSub) => {
        const product = formValues.orderItem.find((i) => i.orderItemId == itemSub.orderItemId)
        return accSub + itemSub.itemQuantity * product?.unitTotalValueFinal
      }, 0), 0)

      const ids = shippingOrderBoxes.map((item) => item.orderPackageId)

      if (!Array.isArray(formValues.shipping) ||
      (Array.isArray(formValues.shipping) && !formValues.shipping.some((x) => !x.shipmentId))
      ) {
        dispatch(arrayPush('manage-order-b2b2c-b2c', 'shipping', {
          shippingValue: total.toFixed(2),
          shippingDate: moment(),
          incoterms: 5,
          orderPackageId: ids
        }))
      } else {
        // shipping exists, change the draft only
        const draftIndex = formValues.shipping.findIndex((x) => !x.shipmentId)
        if (draftIndex >= 0) {
          dispatch(arrayRemove('manage-order-b2b2c-b2c', 'shipping', draftIndex))
          dispatch(arrayPush('manage-order-b2b2c-b2c', 'shipping', {
            shippingValue: total.toFixed(2),
            incoterms: 5,
            shippingDate: moment(),
            orderPackageId: ids
          }))
        }
      }
    }

    initialize()
  }, [])

  const goToOverview = async () => {
    let valid = true
    const shipmentsToSend = formValues.shipping.filter((x) => !x.shipmentId)

    const {shipments, errors} = TransformPendingShipment(shipmentsToSend, carriers, selectedService, formValues?.sendNotifications)

    if (errors.length) {
      return (AlertService.error(
        translate('ERROR'),
        <div>{errors.map((err, i) => <div key={i}>{translate(err)}</div>)}</div>
      ))
    }

    setLoading(true)

    await AsyncForEach(shipments, async (data) => {
      const {success} = await SendOrderItems(formValues.orderId, data)
      if (!success) valid = false
    })

    if (valid) {
      const {data} = await GetOrderB2CDetails(id)
      const resultShipping = await GetOrderShipments(id)
      const resultPackages = await GetOrderPackagesInfo(id)

      dispatch(change('manage-order-b2b2c-b2c', 'shipping', resultShipping?.data.map((x) => ({
        carrierId: x.carrierId,
        incoterms: x.incoterms,
        shippingValue: x.shippingValue,
        shippingDate: x.date,
        contents: x.contents,
        estimatedShippingValue: x.shipmentCost,
        estimatedShippingDate: x.shipmentDeliveryDate,
        shipmentId: x.shipmentId,
        shipmentType: x.shipmentType,
        totalValue: x.totalValue,
        trackingNumber: x.trackingNumber,
        boxes: x.boxes
      }))))
      dispatch(change('manage-order-b2b2c-b2c', 'orderBox', resultPackages?.data))
      dispatch(change('manage-order-b2b2c-b2c', 'orderStatus', data.orderStatus))
      dispatch(change('manage-order-b2b2c-b2c', 'orderStatusId', data.orderStatusId))
      handleNext(5)
    }
    setLoading(false)
  }

  if (loading) return <BaseLoading margin='100' />

  return (
    <>
      <FieldArray
        component={ShippingOrderBoxes}
        name='shipping'
        carriers={carriers}
        selectedService={selectedService}
        setSelectedService={setSelectedService}
        order={{
          orderBox: formValues.orderBox,
          deliveryCountryId: formValues.deliveryCountryId,
          deliveryZipCode: formValues.deliveryZipCode,
          deliveryRegion: formValues.deliveryRegion,
          deliveryCity: formValues.deliveryCity,
          deliveryStreetNumber: formValues.deliveryStreetNumber,
          deliveryAddressLine1: formValues.deliveryAddressLine1,
          deliveryAddressLine2: formValues.deliveryAddressLine2,
          deliveryAddressLine3: formValues.deliveryAddressLine3}}
      />
      <Margin size={50} />
      <Row>
        <Col xs={12}>
          <BackButton
            label={<Translate id='BACK' />}
            onClick={() => setCurrentStep(3)}
          />
        </Col>
        <Col xs={12} style={{textAlign: 'right'}}>
          <NextButton
            label={<Translate id='NEXT' />}
            onClick={goToOverview}
          />
        </Col>
      </Row>
    </>
  )
}

ShippingData.propTypes = {
  formValues: PropTypes.object.isRequired
}

export default withLocalize(ShippingData)
