import ManageSupplierPage from '../../pages/suppliers/ManageSuppliers/ManageSupplierPage'
import SuppliersPage from '../../pages/suppliers/SuppliersPage'

export default [
  {
    path: '/suppliers',
    component: SuppliersPage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  },
  {
    path: '/suppliers/:id',
    component: ManageSupplierPage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  },
  {
    path: '/suppliers/new',
    component: ManageSupplierPage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  }
]
