import React, {useEffect, useState} from 'react'

import {Col, Row} from 'antd'
import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'

import {
  ContentContainer,
  Margin,
  PageTitle,
  StyledTabPane,
  StyledTabs,
  StyledTabTitle,
  StyledTabTitleContainer
} from '../../../shared/styles/BasicStyles'
import ImageManagerTab from './tabs/ImageManagerTab'
import MarketingAutomationTab from './tabs/MarketingAutomationTab'
import ProductCharacteristicsTab from './tabs/ProductCharacteristicsTab'
import TrendingProductsTab from './tabs/TrendingProductsTab'
import VouchersTab from './tabs/VouchersTab'

const Sections = ['product', 'banners', 'trending', 'marketing', 'vouchers']

const MarketingPage = ({router}) => {
  const [section, setSection] = useState('product')

  useEffect(() => {
    function initialize() {
      const hash = router.location.hash.replace('#', '')
      if (Sections.find((s) => s === hash)) {
        setSection(hash)
      }
    }
    initialize()
  }, [])

  const onChangeTab = (active) => {
    setSection(active)
    router.history.replace(`/marketing#${active}`)
  }

  return (
    <>
      <Margin size={62} />
      <Row>
        <Col xs={24}>
          <PageTitle>
            <Translate id='MARKETING' />
          </PageTitle>
        </Col>
      </Row>
      <ContentContainer>
        <StyledTabs destroyInactiveTabPane activeKey={section} onChange={onChangeTab}>
          <StyledTabPane
            tab={
              <StyledTabTitleContainer>
                <StyledTabTitle smaller>
                  <Translate id='PRODUCT_CHARACTERISTICS' />
                </StyledTabTitle>
              </StyledTabTitleContainer>
            }
            key='product'
          >
            <ProductCharacteristicsTab router={router} />
          </StyledTabPane>
          <StyledTabPane
            tab={
              <StyledTabTitleContainer>
                <StyledTabTitle smaller>
                  <Translate id='BANNERS' />
                </StyledTabTitle>
              </StyledTabTitleContainer>
            }
            key='banners'
          >
            <ImageManagerTab router={router} />
          </StyledTabPane>
          <StyledTabPane
            tab={
              <StyledTabTitleContainer>
                <StyledTabTitle smaller>
                  <Translate id='TRENDING_PRODUCTS' />
                </StyledTabTitle>
              </StyledTabTitleContainer>
            }
            key='trending'
          >
            <TrendingProductsTab />
          </StyledTabPane>
          <StyledTabPane
            tab={
              <StyledTabTitleContainer>
                <StyledTabTitle smaller>
                  <Translate id='MARKETING_AUTOMATION' />
                </StyledTabTitle>
              </StyledTabTitleContainer>
            }
            key='marketing'
          >
            <MarketingAutomationTab />
          </StyledTabPane>
          <StyledTabPane
            tab={
              <StyledTabTitleContainer>
                <StyledTabTitle smaller>
                  <Translate id='VOUCHERS' />
                </StyledTabTitle>
              </StyledTabTitleContainer>
            }
            key='vouchers'
          >
            <VouchersTab router={router} />
          </StyledTabPane>
        </StyledTabs>
      </ContentContainer>
    </>
  )
}

MarketingPage.propTypes = {
  router: PropTypes.object.isRequired
}

export default MarketingPage
