import React from 'react'

import {Col, Row} from 'antd'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {Field, change} from 'redux-form'

import SelectInput from '../../../../shared/components/inputs/SelectInput'
import TextInput from '../../../../shared/components/inputs/TextInput'
import {Margin} from '../../../../shared/styles/BasicStyles'

const Business = [
  {id: 'false', name: 'B2B'},
  {id: 'true', name: 'B2C'}
]

const GeneralInformation = ({
  productTypes,
  productCategories,
  productPropertiesSection,
  dispatch,
  translate
}) => (
  <>
    <Row gutter={[20, 20]}>
      <Col xs={12} sm={6}>
        <Field
          component={SelectInput}
          name='productPropertiesSection.productTypeId'
          label={<Translate id='TYPE' />}
          dataKey='productTypeId'
          dataLabel='productTypeTranslation'
          placeholder={translate('TYPE')}
          data={productTypes.filter((t) => t.productTypeId !== 4)}
          allowClear={false}
        />
      </Col>
      <Col xs={12} sm={6}>
        <Field
          component={SelectInput}
          name='productPropertiesSection.isB2c'
          label={<Translate id='BUSINESS' />}
          placeholder={<Translate id='BUSINESS' />}
          data={Business}
          allowClear={false}
        />
      </Col>
      <Col xs={12} sm={6}>
        <Field
          component={TextInput}
          name='productPropertiesSection.referenceCode'
          disabled
          label={<Translate id='REFERENCE' />}
          placeholder={translate('REFERENCE')}
        />
      </Col>
    </Row>
    {/* <Margin size={20} />
    <Row gutter={[20, 20]}>
      <Col xs={12} sm={6}>
        <Field
          component={TextInput}
          name='productPropertiesSection.idnormal'
          label={<Translate id='NORMAL' />}
          placeholder={translate('NORMAL')}
        />
      </Col>
      <Col xs={12} sm={6}>
        <Field
          component={TextInput}
          name='productPropertiesSection.idwide'
          label={<Translate id='WIDE' />}
          placeholder={translate('WIDE')}
        />
      </Col>
      <Col xs={12} sm={6}>
        <Field
          component={TextInput}
          name='productPropertiesSection.idshort'
          label={<Translate id='SHORT' />}
          placeholder={translate('SHORT')}
        />
      </Col>
      <Col xs={12} sm={6}>
        <Field
          component={TextInput}
          name='productPropertiesSection.idlong'
          label={<Translate id='LONG' />}
          placeholder={translate('LONG')}
        />
      </Col>
      <Col xs={12} sm={6}>
        <Field
          component={TextInput}
          name='productPropertiesSection.idextraLong'
          label={<Translate id='EXTRA_LONG' />}
          placeholder={translate('EXTRA_LONG')}
        />
      </Col>
      <Col xs={12} sm={6}>
        <Field
          component={TextInput}
          name='productPropertiesSection.iddoubleWide'
          label={<Translate id='DOUBLE_WIDE' />}
          placeholder={translate('DOUBLE_WIDE')}
        />
      </Col>
    </Row> */}
    <Margin size={20} />
    <Row gutter={[20, 20]}>
      <Col xs={12} lg={6}>
        <Field
          component={SelectInput}
          name='productPropertiesSection.mainCategoryId'
          label={<Translate id='MAIN_CATEGORY' />}
          dataKey='categoryId'
          dataLabel='categoryTranslation'
          placeholder={<Translate id='MAIN_CATEGORY' />}
          allowClear={false}
          data={productCategories.filter((cat) => !cat.parentCategoryId)}
          afterChange={() => {
            dispatch(
              change(
                'manage_product',
                'productPropertiesSection.categoryId',
                ''
              )
            )
            dispatch(
              change(
                'manage_product',
                'productPropertiesSection.subCategoryId',
                ''
              )
            )
          }}
        />
      </Col>
      <Col xs={12} lg={6}>
        <Field
          component={SelectInput}
          name='productPropertiesSection.categoryId'
          label={<Translate id='CATEGORY' />}
          dataKey='categoryId'
          dataLabel='categoryTranslation'
          placeholder={<Translate id='CATEGORY' />}
          disabled={!productPropertiesSection.mainCategoryId}
          data={productCategories.filter(
            (cat) =>
              cat.parentCategoryId ===
              productPropertiesSection.mainCategoryId
          )}
          afterChange={() => {
            dispatch(
              change(
                'manage_product',
                'productPropertiesSection.subCategoryId',
                ''
              )
            )
          }}
        />
      </Col>
      <Col xs={12} lg={6}>
        <Field
          component={SelectInput}
          name='productPropertiesSection.subCategoryId'
          label={<Translate id='SUB_CATEGORY' />}
          dataKey='categoryId'
          dataLabel='categoryTranslation'
          placeholder={<Translate id='SUB_CATEGORY' />}
          disabled={
            !productPropertiesSection.categoryId ||
            productCategories.filter(
              (cat) =>
                cat.parentCategoryId ===
                productPropertiesSection.categoryId
            ).length === 0
          }
          data={productCategories.filter(
            (cat) =>
              cat.parentCategoryId === productPropertiesSection.categoryId
          )}
        />
      </Col>
    </Row>
    <Margin size={20} />
  </>
)

GeneralInformation.propTypes = {
  dispatch: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  productTypes: PropTypes.array,
  productCategories: PropTypes.array,
  productPropertiesSection: PropTypes.object
}

GeneralInformation.defaultProps = {
  productTypes: [],
  productCategories: [],
  productPropertiesSection: {}
}

export default withLocalize(GeneralInformation)
