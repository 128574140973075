import React from 'react'

import {Col, Row} from 'antd'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {Field} from 'redux-form'

import FloatInput from '../../../shared/components/inputs/FloatInput'
import BaseTable from '../../../shared/components/table/BaseTable'
import GetTranslationFromArray from '../../../shared/logic/translations/Translations'
import {Margin} from '../../../shared/styles/BasicStyles'
import {SubTitle} from '../ClientStyles'

const Columns = [
  {
    dataIndex: 'modulationType',
    type: 'text',
    title: <Translate id='MODULATION' />,
    render: (value) => (
      <div>
        {GetTranslationFromArray(value.modulationTypeTranslation, 2)?.name}
      </div>
    )
  },
  {
    dataIndex: 'rate',
    type: 'text',
    title: <Translate id='RATE_A' />
  },
  {
    dataIndex: 'weight',
    type: 'text',
    title: <Translate id='WEIGHT' />
  },
  {
    dataIndex: 'rateB',
    type: 'text',
    title: <Translate id='RATE_B' />
  }
]

const ModulationConfigured = ({fields, translate}) => {
  const rows = fields.getAll() || []

  return (
    <>
      <Row xs={24}>
        <Col xs={24}>
          <SubTitle>
            <Translate id='MODULATION_CONFIGURED' />
          </SubTitle>
        </Col>
      </Row>
      <Margin size={30} />
      <Row>
        <Col xs={4}>
          <Field
            component={FloatInput}
            name='positionModulationsSection.pickupRate'
            label={<Translate id='PICKUP_RATE' />}
            inputDescriptionText='%'
            placeholder={translate('PICKUP_RATE')}
          />
        </Col>
        <Col xs={24}>
          <Margin size={20} />
          <BaseTable
            datasource={rows}
            columns={Columns}
            pagination={{
              render: false
            }}
            rowKey='modulationTypeId'
          />
        </Col>
      </Row>
    </>
  )
}

ModulationConfigured.propTypes = {
  fields: PropTypes.object.isRequired
}

export default withLocalize(ModulationConfigured)
