import {Carousel} from 'antd'
import styled from 'styled-components'

import {
  BorderColor,
  FooterTextColor,
  PrimaryColor
} from '../../styles/_colors'

export const SliderContainer = styled.div`
  border: 1px solid ${BorderColor};
  border-radius: 25px;
  width: 100%;
  display: inline-block;
  padding: 10px 50px;
  position: absolute;
`

export const SliderBox = styled(Carousel)``

export const Measure = styled.div`
  cursor: pointer;
  text-align: center;
  display: inline-block;
  border-radius: 25px;
  background-color: ${({$selected}) => ($selected ? PrimaryColor : null)};
  color: ${({$selected}) => ($selected ? FooterTextColor : null)};
  width: 100px;
  float: left;
`

export const ArrowsImage = styled.img`
  cursor: pointer;
  position: absolute;
  top: 15px;
  left: ${({$left}) => ($left ? '10px' : 'auto')};
  right: ${({$right}) => ($right ? '10px' : 'auto')};
`
