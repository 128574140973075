import React from 'react'

import {DragOutlined} from '@ant-design/icons'
import {Col, Row} from 'antd'
import PropTypes from 'prop-types'
import {useDrop} from 'react-dnd'
import styled from 'styled-components'

import ProductUploadImageInput from '../../../../../shared/components/inputs/ProductUploadImageInput'
import GetTranslationFromArray from '../../../../../shared/logic/translations/Translations'
import {
  Margin,
  StyledHR,
  TitleH4
} from '../../../../../shared/styles/BasicStyles'
import ProductImageCard from './ProductImageCard'
import {Translate} from 'react-localize-redux'

const DroppableImage = styled.div`
  display: inline-block;
  width: 100%;
  position: relative;
`
const DroppableOverview = styled.div`
  position: absolute;
  top: 10px;
  left: -10px;
  right: -10px;
  bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  font-size: 30px;
  color: white;
  flex-direction: column;
  .anticon {
    font-size: 50px;
  }
`

const ColorImageSection = ({
  color,
  images,
  loading,
  addNewImage,
  deleteImage,
  changeCover,
  changeFitting,
  handleColorRemove,
  onImageDrop
}) => {
  const [{canDrop}, drop] = useDrop({
    accept: 'image',
    drop: (item) => onImageDrop(item, color),
    canDrop: (item) => item.colorId !== color.colorId,
    collect: (monitor) => ({
      canDrop: monitor.canDrop()
    })
  })

  return (
    <DroppableImage ref={drop}>
      {canDrop && (
        <DroppableOverview>
          <DragOutlined />
          <Translate id='ASSIGN_IMAGE_TO_COLOR' />
        </DroppableOverview>
      )}
      <Row gutter={[20, 20]}>
        <Col xs={24}>
          <StyledHR />
        </Col>
        <Col xs={24}>
          <TitleH4 $opacity='0.5'>
            Color -{' '}
            {GetTranslationFromArray(color?.colorTranslation, 2)?.name}
          </TitleH4>
        </Col>
        {images.length < 8 && (
          <Col xs={8} lg={4}>
            <ProductUploadImageInput
              loading={loading}
              input={{
                value: undefined,
                onChange: (value) => addNewImage(value, color.colorId)
              }}
            />
          </Col>
        )}
        {images
          .filter((img) =>
            img.productImageColor.find((c) => c.colorId === color.colorId)
          )
          .map((currentImage) => (
            <Col
              xs={8}
              lg={4}
              key={`${color.colorId}_${currentImage.imageId}`}
            >
              <ProductImageCard
                deleteImage={deleteImage}
                changeCover={changeCover}
                changeFitting={changeFitting}
                removeColor={() =>
                  handleColorRemove(currentImage.imageId, color.colorId)
                }
                currentColor={color}
                currentImage={currentImage}
                dropCard={() => null}
              />
            </Col>
          ))}
        <Col xs={24}>
          <Margin size={20} />
        </Col>
      </Row>
    </DroppableImage>
  )
}

ColorImageSection.propTypes = {
  color: PropTypes.object.isRequired,
  images: PropTypes.array,
  loading: PropTypes.bool,
  addNewImage: PropTypes.func.isRequired,
  deleteImage: PropTypes.func.isRequired,
  changeCover: PropTypes.func.isRequired,
  changeFitting: PropTypes.func.isRequired,
  handleColorRemove: PropTypes.func.isRequired,
  onImageDrop: PropTypes.func.isRequired
}

ColorImageSection.defaultProps = {
  images: [],
  loading: false
}

export default ColorImageSection
