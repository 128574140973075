import React, {useEffect, useState} from 'react'

import {Col, Row} from 'antd'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {useParams} from 'react-router-dom'
import {reduxForm, initialize} from 'redux-form'

import {
  CreateUser,
  EditUser,
  GetUser,
  GetProfilesDrop,
  ChangeUserPassword
} from '../../infra/requests/ClientRequests'
import FormValidator from '../../infra/services/validations/FormValidator'
import AlertService from '../../shared/components/alert/AlertService'
import BackButton from '../../shared/components/buttons/BackButton'
import BaseButton from '../../shared/components/buttons/BaseButton'
import BaseLoading from '../../shared/components/loading/BaseLoading'
import {
  ContentContainer,
  PageForm,
  PageTitle,
  StyledTabPane,
  StyledTabs,
  StyledTabTitle,
  StyledTabTitleContainer
} from '../../shared/styles/BasicStyles'
import Information from './Information'
import Permissions from './Permissions'

const validations = (values) => {
  const errors = FormValidator.make({
    userName: 'required',
    fullName: 'required'
  })(values)

  errors.aspnetMembership = {}
  if (!values?.aspnetMembership?.email) {
    errors.aspnetMembership.email = {
      _error: 'EMAIL_REQUIRED'
    }
  }
  errors.profile = {}
  if (!values?.profile?.profileId) {
    errors.profile.profileId = {
      _error: 'PROFILE_REQUIRED'
    }
  }
  return errors
}

const ManageUsers = ({router, dispatch, handleSubmit, translate}) => {
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const {client, id} = useParams()
  const [profilesList, setProfilesList] = useState([])
  const [name, setName] = useState(
    client ? <Translate id='NEW_USER' /> : <Translate id='USER' />
  )

  useEffect(() => {
    async function init() {
      if (id && id !== 'new') {
        const {data, success} = await GetUser(id)
        if (success) {
          setName(data?.fullName)
          dispatch(initialize('user_form', data || {}))
        }
      }
      const resultProfiles = await GetProfilesDrop()
      setProfilesList(resultProfiles?.data || [])
      setLoading(false)
    }
    init()
  }, [])

  const handleClose = () =>
    client
      ? router.history.push(`/clients/${client}#users`)
      : router.history.push('/skypro#users')

  const onSubmit = async (values) => {
    try {
      setSaving(true)
      const info = {
        ...values,
        aspnetProfile: {
          propertyValuesString: values?.profile?.profileId
        }
      }

      delete info?.profile

      if (!id || id === 'new') {
        info.aspnetMembership.password = values.password

        const result = await CreateUser(client || 1, info)

        if (result.success) {
          AlertService.success(
            translate('SUCCESS'),
            translate('USER_CREATED_SUCCESSFULLY')
          )
          return client
            ? router.history.push(`/clients/${client}#users`)
            : router.history.push('/skypro#users')
        }
      } else {
        const result = await EditUser(id, info)

        if (values.password && values.password !== '') {
          const {success} = await ChangeUserPassword(id, {
            NewPassword: values.password,
            ConfirmNewPassword: values.confirm_password
          })
          if (!success) return setSaving(false)
        }

        if (result.success) {
          AlertService.success(
            translate('SUCCESS'),
            translate('USER_EDITED_SUCCESSFULLY')
          )
          return client
            ? router.history.push(`/clients/${client}#users`)
            : router.history.push('/skypro#users')
        }
      }
      setSaving(false)
    } catch (error) {
      console.log(error)
    }
  }

  if (loading) return <BaseLoading margin='100' />

  return (
    <PageForm onSubmit={handleSubmit(onSubmit)}>
      <Row align='bottom' gutter={[0, 50]}>
        <Col xs={24}>
          <BackButton
            label={<Translate id='BACK' />}
            onClick={handleClose}
          />
        </Col>
        <Col xs={12}>
          <PageTitle>{name}</PageTitle>
        </Col>
        <Col xs={12} align='end'>
          <BaseButton htmlType='submit' type='primary' auto loading={saving}>
            <Translate id='SAVE' />
          </BaseButton>
        </Col>
      </Row>
      <ContentContainer>
        <StyledTabs>
          <StyledTabPane
            tab={
              <StyledTabTitleContainer>
                <StyledTabTitle>
                  <Translate id='INFORMATION' />
                </StyledTabTitle>
              </StyledTabTitleContainer>
            }
            key='info'
          >
            <Information id={id} profiles={profilesList} />
          </StyledTabPane>
          <StyledTabPane
            tab={
              <StyledTabTitleContainer>
                <StyledTabTitle>
                  <Translate id='PERMISSIONS' />
                </StyledTabTitle>
              </StyledTabTitleContainer>
            }
            key='permissions'
          >
            <Permissions profiles={profilesList} />
          </StyledTabPane>
        </StyledTabs>
      </ContentContainer>
    </PageForm>
  )
}

ManageUsers.propTypes = {
  router: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  handleSubmit: PropTypes.func
}

ManageUsers.defaultProps = {
  dispatch: undefined,
  handleSubmit: undefined
}

const ManageUsersPage = reduxForm({
  form: 'user_form',
  validate: validations
})(ManageUsers)

export default withLocalize(
  connect((state) => ({
    clients: state.info.clients
  }))(ManageUsersPage)
)
