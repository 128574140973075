import {createReducer} from '@reduxjs/toolkit'

import {updatePermissions} from './PermissionsActions'

const initialState = []

const PermissionsReducer = createReducer(initialState, {
  [updatePermissions]: (state, action) => ({...state, ...action.payload})
})

export default PermissionsReducer
