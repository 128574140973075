import React, { useState, useEffect } from 'react'

import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'

import {
  StyledTabs,
  StyledTabPane,
  StyledTabTitleContainer,
  StyledTabTitle,
  Margin
} from '../../shared/styles/BasicStyles'
import CalendarTab from './tabs/CalendarTab'
import ConfigurationsTab from './tabs/ConfigurationsTab'
import FittingCentersTab from './tabs/FittingCentersTab'

const Sections = ['calendar', 'centers', 'configs']

const SuppliersPage = ({ router }) => {
  const [section, setSection] = useState()
  const iFrameDetection = ((window === window.parent || window.opener))

  useEffect(() => {
    function initialize() {
      const hash = router.location.hash.replace('#', '')
      if (Sections.find((s) => s === hash)) {
        setSection(hash)
      } else setSection('calendar')
    }
    initialize()
  }, [router])

  const onChangeTab = (active) => {
    setSection(active)
    router.history.replace(`/fitting#${active}`)
  }

  return (
    <>
      <Margin size={30} />
      <StyledTabs destroyInactiveTabPane activeKey={section} onChange={onChangeTab} $hidden={!iFrameDetection}>
        <StyledTabPane
          tab={
            <StyledTabTitleContainer>
              <StyledTabTitle>
                <Translate id='FITTING' />
              </StyledTabTitle>
            </StyledTabTitleContainer>
          }
          key='calendar'
        >
          <CalendarTab router={router} />
        </StyledTabPane>
        <StyledTabPane
          tab={
            <StyledTabTitleContainer>
              <StyledTabTitle>
                <Translate id='MANAGEMENT' />
              </StyledTabTitle>
            </StyledTabTitleContainer>
          }
          key='centers'
        >
          <FittingCentersTab router={router} />
        </StyledTabPane>
        <StyledTabPane
          tab={
            <StyledTabTitleContainer>
              <StyledTabTitle>
                <Translate id='PLATFORM_CONFIGS' />
              </StyledTabTitle>
            </StyledTabTitleContainer>
          }
          key='configs'
        >
          <ConfigurationsTab />
        </StyledTabPane>
      </StyledTabs>
    </>
  )
}

SuppliersPage.propTypes = {
  router: PropTypes.object.isRequired
}

export default SuppliersPage
