import React from 'react'

import {DropboxOutlined} from '@ant-design/icons'
import {Row, Col} from 'antd'
import {PropTypes} from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {arrayPush, change, Field, formValueSelector} from 'redux-form'

import AddIcon from '../../../../assets/icons/add.svg'
import CloseBorderedIcon from '../../../../assets/icons/close.svg'
import BaseButton from '../../../../shared/components/buttons/BaseButton'
import NumberIncrementInput from '../../../../shared/components/inputs/NumberIncrementInput'
import SelectInput from '../../../../shared/components/inputs/SelectInput'
import TextAreaInput from '../../../../shared/components/inputs/TextAreaInput'
import {EmptyTable, FooterRow, ListCheck, ListCol, TableInput, TableRow, TableTitle} from '../../../../shared/components/table/CustomTableStyles'
import {Margin} from '../../../../shared/styles/BasicStyles'
import {EditOrderTitle} from './Styles'

const ReturnsAndExchangesModalProducts = ({fields, selected, setSelected, orderProducts, loading, dispatch, translate, motives, products}) => {

  const availableOrderProducts = orderProducts.filter((x) => {
    if(x.status?.orderStatusId !== 4 && x.status?.orderStatusId !== 5) return false
      const productAlreadyAdded = products?.filter((product) => product.orderItemId == x.orderItemId)
      if (productAlreadyAdded?.length > 0) {
        const productAddedTotal = productAlreadyAdded.reduce((acc, item) => acc + parseInt(item.quantity, 10), 0)
        if (productAddedTotal < x.quantity) {
          return true
        }
        return false
      }
      return true
  })

  const checkSelectedProductMaxValue = (value, index) => {
    const currentProduct = fields.get(index)
    const selectedOrderItem = orderProducts.find((x) => x.orderItemId == currentProduct?.orderItemId)
    if (selectedOrderItem) {
      const productsAlreadyAdded = products.filter((product, currentIndex) => product.orderItemId == currentProduct.orderItemId && currentIndex != index)
      const productTotal = productsAlreadyAdded.reduce((acc, item) => acc + item.quantity, 0)
      const maxValue = selectedOrderItem.quantity - productTotal
      if (selectedOrderItem.quantity < productTotal + value) {
        dispatch(change('returns-and-exchange-form', `products[${index}].quantity`, maxValue))
      }
      return maxValue
    }
    return 0
  }

  const handleCheck = (e, index) => { 
    const copy = [...selected]
    if (e.target.checked) {
      copy.push(index)
      setSelected(copy)
    } else {
      setSelected(copy.filter((i) => i !== index))
    }
  }

  const createNewRow = () => {
    dispatch(arrayPush('returns-and-exchange-form', 'products', {}))
  }

  const handleCheckAll = (e) => {
    if (e.target.checked) {
      setSelected(products.map((b, i) => i))
    } else {
      setSelected([])
    }
  }

  const handleRemoveProducts = async () => {
    let newProducts = [...products]
    selected.forEach((index) => {
      newProducts = newProducts.filter((product, productIndex) => productIndex !== index)
    })
    dispatch(change('returns-and-exchange-form', 'products', newProducts))
    setSelected([])
  }

  return (
    <>
      <Row>
        <Col xs={12} lg={18}>
          <EditOrderTitle>
            <Translate id='PRODUCTS' />
          </EditOrderTitle>
        </Col>
        <Col xs={12} lg={6}>
          <BaseButton
            onClick={() => createNewRow()}
            type='secondary'
            imagePath={AddIcon}
            disabled={availableOrderProducts.length === 0}
          >
            <Translate id='ADD_ITEM' />
          </BaseButton>
        </Col>
        <Col xs={24}>
          Can only select products that were shipped or delivered
        </Col>
      </Row>
      <Margin size={20} />

      <Row gutter={[16, 40]}>
        <Col xs={24}>
          <TableRow $header>
            <ListCol xs={2}>
              <ListCheck
                disabled={!products?.length || loading}
                checked={selected.length > 0}
                onChange={handleCheckAll}
                indeterminate={
                  selected.length > 0 && selected.length < products.length
                }
              />
            </ListCol>
            <ListCol xs={6}>
              <TableTitle text='Product' />
            </ListCol>
            <ListCol xs={2}>
              <TableTitle text='Quantity' />
            </ListCol>
            <ListCol xs={3}>
              <TableTitle text='Return type' />
            </ListCol>
            <ListCol xs={3}>
              <TableTitle text='Motive' />
            </ListCol>
            <ListCol xs={8}>
              <TableTitle text='Comments' />
            </ListCol>
          </TableRow>
          {(products === undefined || (products?.length === 0)) && (
            <EmptyTable>
              <DropboxOutlined />
              {translate('NO_PRODUCTS')}
            </EmptyTable>
          )}
          {products?.map((product, index) => (
            <TableRow key={index}>
              <ListCol xs={2}>
                <ListCheck
                  checked={selected.includes(index)}
                  onChange={(e) => handleCheck(e, index)}
                  disabled={loading}
                />
              </ListCol>
              <ListCol xs={6}>
                <TableInput>
                  <Field
                    name={`products[${index}].orderItemId`}
                    component={SelectInput}
                    width='100%'
                    small
                    data={availableOrderProducts}
                    dataKey='orderItemId'
                    dataLabel='productCombination.reference'
                    placeholder={<Translate id='SELECT' />}
                  />
                </TableInput>
              </ListCol>
              <ListCol xs={2}>
                <TableInput>
                  <Field
                    name={`products[${index}].quantity`}
                    component={NumberIncrementInput}
                    placeholder={checkSelectedProductMaxValue(products[index].quantity, index)}
                    min={1}
                    afterChange={(value) => checkSelectedProductMaxValue(value, index)}
                    width='80%'
                  />
                </TableInput>
              </ListCol>
              <ListCol xs={3}>
                <TableInput>
                  <Field
                    name={`products[${index}].type`}
                    component={SelectInput}
                    data={[{id: 0, name: translate('RETURN')}, {id: 1, name: translate('EXCHANGE')}]}
                    dataKey='id'
                    dataLabel='name'
                    placeholder={<Translate id='SELECT' />}
                    width='100%'
                    small
                  />
                </TableInput>
              </ListCol>
              <ListCol xs={3}>
                <TableInput>
                  <Field
                    name={`products[${index}].motive`}
                    component={SelectInput}
                    placeholder={<Translate id='SELECT_MOTIVE' />}
                    data={motives}
                    dataLabel='Name'
                    dataKey='ID'
                    small
                  />
                </TableInput>
              </ListCol>
              <ListCol xs={8}>
                <TableInput>
                  <Field
                    component={TextAreaInput}
                    rows={3}
                    name={`products[${index}].comments`}
                  />
                </TableInput>
              </ListCol>
            </TableRow>
          ))}
          <FooterRow>
            <ListCol xs={3}>
              <BaseButton
                disabled={selected.length <= 0 || loading}
                type='secondary'
                size='small'
                onClick={handleRemoveProducts}
                imagePath={CloseBorderedIcon}
              >
                {translate('REMOVE')}
              </BaseButton>
            </ListCol>
          </FooterRow>
        </Col>
      </Row>
    </>
  )
}

ReturnsAndExchangesModalProducts.propTypes = {
  fields: PropTypes.object.isRequired
}

ReturnsAndExchangesModalProducts.defaultProps = {
}

const selector = formValueSelector('returns-and-exchange-form')

export default withLocalize(connect((state) => ({
  products: selector(state, 'products')
}))(ReturnsAndExchangesModalProducts))
