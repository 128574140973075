import React, {useState, useEffect} from 'react'

import {Row, Col} from 'antd'
import {Translate} from 'react-localize-redux'

import {GetInformation} from '../../../infra/requests/LogisticsRequests'
import DynamicNumberInput from '../../../shared/components/inputs/DynamicNumberInput'
import Baseloading from '../../../shared/components/loading/BaseLoading'
import {ProgressBar} from '../../../shared/components/progress/ProgressBar'
import {ConstructQuery} from '../../../shared/logic/queries/EndpointQueries'
import {
  Margin,
  TitleH7,
  TitleH8,
  TitleH9
} from '../../../shared/styles/BasicStyles'
import OverviewFilters from '../Overview/OverviewFilters'
import {WhiteCard} from './InformationPageStyles'

const InformationPage = () => {
  const [filters, setFilters] = useState({})
  const [statistics, setStatistics] = useState({
    SalesInventoryValue: 1,
    SalesInventory: 1,
    ClientInventoryValue: 1,
    ClientInventory: 1,
    skyproInventoryValue: 1,
    skyproInventory: 1,
    materialsInventoryValue: 1,
    materialsInventory: 1,
    stockRotation: 1,
    returns: 1,
    outOfStock: 1,
    searchInventoryValue: 1,
    searchInventory: 1
  })
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function initstatistics() {
      const {data, success} = await GetInformation(ConstructQuery(filters))
      if (success) setStatistics(data || [])
      setLoading(false)
    }
    initstatistics()
  }, [filters])

  if (loading) return <Baseloading margin={200} />
  return (
    <>
      <OverviewFilters filters={filters} setFilters={setFilters} />
      <Margin size={60} />
      <Row gutter={[20, 20]}>
        <Col xs={6}>
          <WhiteCard>
            <TitleH7>
              <Translate id='SALES_PC_INVENTORY_IN_HANDS' />
            </TitleH7>
            <TitleH8>
              <DynamicNumberInput
                value={statistics.salesInventory}
                disabled
              />
            </TitleH8>
            <TitleH9 $op>
              €
              <DynamicNumberInput
                value={parseFloat(statistics.SalesInventoryValue).toFixed(
                  2
                )}
                disabled
              />
            </TitleH9>
          </WhiteCard>
        </Col>
        <Col xs={6}>
          <WhiteCard>
            <TitleH7>
              <Translate id='CLIENT_INVENTORY_IN_HANDS' />
            </TitleH7>
            <TitleH8>
              <DynamicNumberInput
                value={statistics.clientInventory}
                disabled
              />
            </TitleH8>
            <TitleH9 $op>
              €
              <DynamicNumberInput
                value={statistics.ClientInventoryValue}
                disabled
              />
            </TitleH9>
          </WhiteCard>
        </Col>
        <Col xs={6}>
          <WhiteCard>
            <TitleH7>
              <Translate id='SKYPRO_INVENTORY_IN_HANDS' />
            </TitleH7>
            <TitleH8>
              <DynamicNumberInput
                value={statistics.skyproInventory}
                disabled
              />
            </TitleH8>
            <TitleH9 $op>
              {' '}
              €
              <DynamicNumberInput
                value={statistics.skyproInventoryValue}
                disabled
              />
            </TitleH9>
          </WhiteCard>
        </Col>
        <Col xs={6}>
          <WhiteCard>
            <TitleH7>
              <Translate id='RM_INVENTORY_IN_HANDS' />
            </TitleH7>
            <TitleH8>
              <DynamicNumberInput
                value={statistics.materialsInventory}
                disabled
              />
            </TitleH8>
            <TitleH9 $op>
              €
              <DynamicNumberInput
                value={statistics.materialsInventoryValue}
                disabled
              />
            </TitleH9>
          </WhiteCard>
        </Col>
        <Col xs={6}>
          <WhiteCard>
            <TitleH7>
              <Translate id='STOCK_ROTATION_RATE' />
            </TitleH7>
            <TitleH8>
              {parseFloat(statistics.stockRotation).toFixed(2)}%
            </TitleH8>
            <ProgressBar size='small' value={statistics.stockRotation} />
          </WhiteCard>
        </Col>
        <Col xs={6}>
          <WhiteCard>
            <TitleH7>
              <Translate id='RETURN_RATE' />
            </TitleH7>
            <TitleH8>{parseFloat(statistics.returns).toFixed(2)}%</TitleH8>
            <ProgressBar size='small' value={statistics.returns} />
          </WhiteCard>
        </Col>
        <Col xs={6}>
          <WhiteCard>
            <TitleH7>
              <Translate id='OUT_OF_STOCK_RATE' />
            </TitleH7>
            <TitleH8>
              {parseFloat(statistics.outOfStock).toFixed(2)}%
            </TitleH8>
            <ProgressBar size='small' value={statistics.outOfStock} />
          </WhiteCard>
        </Col>
        <Col xs={6}>
          <WhiteCard>
            <TitleH7>
              <Translate id='SEARCH_INVENTORY_IN_HANDS' />
            </TitleH7>
            <TitleH8>
              <DynamicNumberInput
                value={statistics.searchInventory}
                disabled
              />
            </TitleH8>
            <TitleH9 $op>
              €
              <DynamicNumberInput
                value={statistics.searchInventoryValue}
              />
            </TitleH9>
          </WhiteCard>
        </Col>
      </Row>
    </>
  )
}

InformationPage.propTypes = {}

export default InformationPage
