import React from 'react'

import PropTypes from 'prop-types'

import {TableRow, ListCol, TableTitle, TextTooltip, TableInput} from '../../../../../shared/components/table/CustomTableStyles'
import ActiveTranslation from '../../../../../shared/logic/translations/ActiveTranslation'
import {Margin, PageSubtitle} from '../../../../../shared/styles/BasicStyles'
import DepartureHeader from './DepartureHeader'

const FirstStepResume = ({
  formValues,
  products
}) => (
  <>
    <DepartureHeader formValues={formValues} />
    <PageSubtitle>Products</PageSubtitle>
    <Margin size={20} />
    <TableRow $header>
      <ListCol xs={4}>
        <TableTitle first text='Reference' />
      </ListCol>
      <ListCol xs={3}>
        <TableTitle text='EAN Code' />
      </ListCol>
      <ListCol xs={2}>
        <TableTitle text='Client Ref' />
      </ListCol>
      <ListCol xs={6}>
        <TableTitle text='Description' />
      </ListCol>
      <ListCol xs={3}>
        <TableTitle text='Color' />
      </ListCol>
      <ListCol xs={2}>
        <TableTitle text='Size' />
      </ListCol>
      <ListCol xs={2}>
        <TableTitle text='Width' />
      </ListCol>
      <ListCol xs={2}>
        <TableTitle text='Send Quantity' abbr='Send Qty' />
      </ListCol>
    </TableRow>

    {products.map((product, index) => (
      <TableRow key={index}>
        <ListCol xs={4}>
          <TextTooltip first text={product.reference || '-'} />
        </ListCol>
        <ListCol xs={3}>
          <TextTooltip text={product.barcode || '-'} />
        </ListCol>
        <ListCol xs={2}>
          <TextTooltip text={product.referenceClient || '-'} />
        </ListCol>
        <ListCol xs={6}>
          <TextTooltip
            text={
              <ActiveTranslation
                value={product.description}
                tag='name'
              />
            }
          />
        </ListCol>
        <ListCol xs={3}>
          <TextTooltip
            text={
              <ActiveTranslation
                value={product?.color?.colorTranslation || '-'}
                tag='name'
              />
            }
          />
        </ListCol>
        <ListCol xs={2}>
          <TextTooltip text={product?.size?.nameEu || '-'} />
        </ListCol>
        <ListCol xs={2}>
          <TextTooltip text={product?.productWidth?.code || '-'} />
        </ListCol>
        <ListCol xs={2}>
          <TableInput>
            {product?.departQty}
          </TableInput>
        </ListCol>
      </TableRow>
    ))}
  </>
)

FirstStepResume.propTypes = {
  formValues: PropTypes.object
}

FirstStepResume.defaultProps = {
  formValues: {}
}

export default FirstStepResume
