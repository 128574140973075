import React, { useState, useEffect } from "react";
import { Col, Row } from "antd";
import PropTypes from "prop-types";
import { Translate, withLocalize } from "react-localize-redux";
import { useParams } from "react-router";
import { change } from "redux-form";

// Importing icons, API requests, and utility components
import SuccessIcon from "../../../../assets/icons/status_active.svg";
import ErrorIcon from "../../../../assets/icons/status_inactive.svg";
import {
  EditBaseSKU,
} from "../../../../infra/requests/ProductsRequests";
import AlertService from "../../../../shared/components/alert/AlertService";
import BaseButton from "../../../../shared/components/buttons/BaseButton";
import CheckboxInput from "../../../../shared/components/inputs/CheckboxInput";
import ActiveTranslation from "../../../../shared/logic/translations/ActiveTranslation";
import { ErrorColor, SuccessColor } from "../../../../shared/styles/_colors";
import SimpleCheckbox from "../../../../shared/components/inputs/SimpleCheckbox";

// Importing styled components and styles
import {
  BodyH5,
  Margin,
  StyledFlex,
  TitleH6,
} from "../../../../shared/styles/BasicStyles";
import {
  ProductColor,
  SingleColorContainer,
} from "../../../orders/manage_b2b_b2b2c/components/Styles";
import { RowProduct } from "../../ProductsStyles";

const ProductSKUs = ({
  fields,
  dispatch,
  colors,
  sizes,
  widths,
  translate,
}) => {
  // State variables for selected products and their status
  const [selectedProducts, setSelectedProduct] = useState({});
  const [selectedStatus, setSelectedStatus] = useState({});
  const [allCheck, setallchecked] = useState(false);
  const { id } = useParams();
  const SKUs = fields.getAll() || [];

  // Function to determine if the base SKU button should be disabled
  const disabledBaseSKU = () => {
    let selected = 0;
    Object.keys(selectedProducts).forEach((key) => {
      if (selectedProducts[key] === true) selected += 1;
    });
    return selected !== 1;
  };

  // Function to handle checkbox change
  const handleCheckboxChange = async (index, value) => {
    setSelectedStatus({
      ...selectedStatus,
      [index]: value,
    });

    // Update the active value in Redux store
    dispatch(
      change(
        "manage_product",
        `productSKUSection.productCombination[${index}].active`,
        value
      )
    );
  };


  // Effect hook to set default status for each row
  useEffect(() => {
    const defaultSelectedStatus = {};
    SKUs.forEach((currentField, index) => {
      defaultSelectedStatus[index] = currentField.active;
    });
    setSelectedStatus(defaultSelectedStatus);

  }, [SKUs]);

  useEffect(() => {
    // Check if all checkboxes are selected, if yes, check the "Select All" checkbox
    const allSelected = Object.values(selectedStatus).every((status) => status);
    setallchecked(allSelected);
  }, [selectedStatus]);


  // Function to define the mean value
  const handleCheckiboxChange = () => {
    const toggledStatus = {};
    const allSelected = !allCheck; // Toggle the "Select All" checkbox status

    Object.keys(selectedStatus).forEach((index) => {
      toggledStatus[index] = allSelected; // Set all checkboxes' status to the status of "Select All" checkbox
      handleCheckboxChange(index, allSelected); // Call handleCheckboxChange to update Redux store
    });
    setSelectedStatus(toggledStatus); // Update the local state
    setallchecked(allSelected);

  };

  // In the defineMeanValue function, replace selectedProducts with selectedStatus
  const defineMeanValue = async () => {
    const index = Object.keys(selectedStatus).find(
      (key) => selectedStatus[key] === true
    );

    const object = {
      productId: SKUs[index].productId,
      sizeId: SKUs[index].sizeId,
      colorId: SKUs[index].colorId,
      productWidthId: SKUs[index].productWidthId,
    };
    const { success } = await EditBaseSKU(id, object);

    if (success) {
      AlertService.success(
        translate("SUCCESS"),
        translate("BASE_SKU_EDITED_SUCCESSFULLY")
      );
      const newSKUs = [];

      SKUs.forEach((item, ind) => {
        const obj = { ...item, meanValue: ind === index };
        newSKUs.push(obj);
      });

      // Update product combination in Redux store
      dispatch(
        change(
          "manage_product",
          "productSKUSection.productCombination",
          newSKUs
        )
      );

      setSelectedProduct({});


    }
  };


  return (
    <>
      {/* Margin */}
      <Margin size={20} />

      <RowProduct gutter={[20, 10]}>
        {/* Checkbox */}

        {/* Reference */}
        <Col xs={6}>
          <div
            style={{ height: "100%", display: "flex", alignItems: "center" }}
          >
            <BaseButton
              type="primary"
              auto
              disabled={disabledBaseSKU()}
              onClick={defineMeanValue}
            >
              <Translate id="BASE_SKU" />
            </BaseButton>
          </div>
        </Col>
        {/* Warehouse */}
        <Col xs={15} />

        {/* Active in Store */}
        <Col xs={3}>
          <div>
            <TitleH6>
              <Translate id="SELECT_ALL" />
            </TitleH6>
            <SimpleCheckbox
              input={{
                value: allCheck, // Pass the current state value to the checkbox
                onChange: handleCheckiboxChange, // Handle checkbox change event
              }}
            />
          </div>
        </Col>
      </RowProduct>

      {SKUs.map((currentField, index) => (
        <RowProduct
          gutter={[20, 10]}
          align="middle"
          $base={currentField.meanValue}
          key={index}
        >
          {/* Checkbox */}
          <Col xs={1} key={index}>
            <StyledFlex>
              <CheckboxInput
                input={{
                  value: selectedProducts[index] || false,
                  onChange: (value) =>
                    setSelectedProduct({
                      [index]: value,
                    }),
                }}
              />
            </StyledFlex>
          </Col>

          {/* Reference */}
          <Col xs={5}>
            <TitleH6>
              <Translate id="REFERENCE" />
            </TitleH6>
            <BodyH5>{currentField?.reference || "N/A"}</BodyH5>
          </Col>

          {/* EAN */}
          <Col xs={4}>
            <TitleH6>
              <Translate id="EAN" />
            </TitleH6>
            <BodyH5>{currentField?.barcode || "N/A"}</BodyH5>
          </Col>

          {/* Color */}
          <Col xs={2}>
            <TitleH6>
              <Translate id="COLOR" />
            </TitleH6>
            <SingleColorContainer $marginLeft="0px" $marginRight="0px">
              <ProductColor
                $file={
                  currentField?.color?.file
                    ? URL.createObjectURL(currentField?.color?.file)
                    : undefined
                }
                $color={currentField?.color?.html}
              />
            </SingleColorContainer>
          </Col>

          {/* Width */}
          <Col xs={3}>
            <TitleH6>
              <Translate id="WIDTH" />
            </TitleH6>
            <BodyH5>
              <ActiveTranslation
                value={currentField?.productWidth?.productWidthTranslation}
                tag="name"
              />{" "}
            </BodyH5>
          </Col>

          {/* Size */}
          <Col xs={2}>
            <TitleH6>
              <Translate id="SIZE" />
            </TitleH6>
            <BodyH5>{currentField?.size?.number}</BodyH5>
          </Col>

          {/* Warehouse */}
          <Col xs={4}>
            <TitleH6>
              <Translate id="WAREHOUSE" />
            </TitleH6>
            <BodyH5>{currentField.stock}</BodyH5>
          </Col>

          {/* Active in Store */}
          <Col xs={3}>
            <TitleH6>
              <Translate id="ACTIVE_IN_STORE" />
            </TitleH6>
            <StyledFlex $direction="row">
              <SimpleCheckbox
                input={{
                  value: selectedStatus[index] || false,
                  onChange: (value) => handleCheckboxChange(index, value),
                }}
              />
            </StyledFlex>
          </Col>
        </RowProduct>
      ))}

      {/* Margin */}
      <Margin size={20} />
    </>
  );
};

// PropTypes for type-checking
ProductSKUs.propTypes = {
  dispatch: PropTypes.func,
  fields: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  colors: PropTypes.array,
  sizes: PropTypes.array,
  widths: PropTypes.array,
};

// Default props
ProductSKUs.defaultProps = {
  dispatch: undefined,
  fields: [],
  colors: {},
  sizes: [],
  widths: [],
};

// Exporting component with localization
export default withLocalize(ProductSKUs);
