import React, {useState, useRef} from 'react'

import {CloseCircleOutlined} from '@ant-design/icons'
import {PropTypes} from 'prop-types'
import {useDrop} from 'react-dnd'
import {Translate, withLocalize} from 'react-localize-redux'

import AlertService from '../../alert/AlertService'
import {
  InlineInputContainer,
  InlineInputLabel,
  InputError
} from '../InputStyles'
import {
  EmailListInput,
  EmailTag,
  HiddenEmailInput
} from './EmailInputStyles'

const IsValidEmail = (email) => {
  const compare = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
  const matches = email.match(compare)
  if (matches && matches.length === 1) {
    if (matches[0] === email) return true
    if (email.indexOf(`<${matches[0]}>`) > -1) return true
  }
  if (matches && matches.length === 2) {
    if (email.indexOf(`<${matches[1]}>`) > -1) return true
  }
  return false
}

const EmailDroppableInput = ({input, label, meta}, translate) => {
  const [search, setSearch] = useState('')
  const inputRef = useRef(null)

  const handleChange = (value) => {
    input.onChange(value || [])
  }

  const [{canDrop}, drop] = useDrop({
    accept: 'contacts',
    drop: (item) => {
      if (!item.email || item.email === '' || item.email.trim() === '') {
        AlertService.error(
          translate('NO_EMAIL'),
          translate('CONTACT_NO_EMAIL')
        )
      } else {
        handleChange([...input.value, item.email.trim()])
      }
      return item
    },
    collect: (monitor) => ({
      canDrop: monitor.canDrop()
    })
  })

  const emails = Array.isArray(input.value) ? input.value : []

  const handleDelete = (e, index) => {
    e.stopPropagation()
    const list = [...emails]
    list.splice(index, 1)
    handleChange(list)
  }

  const focusInput = () => inputRef.current.focus()

  const handleBlur = (e) => {
    const text = e.target.value
    if (text && text !== '') {
      const list = [...emails]
      list.push(text)
      handleChange(list)
      setSearch('')
    }
  }

  const handleKeyDown = (e) => {
    const text = e.target.value
    if (e.key === 'Enter' && text && text !== '') {
      const list = [...emails]
      list.push(text)
      handleChange(list)
      setSearch('')
    }
    return null
  }

  const hasError = meta.invalid && meta.submitFailed

  return (
    <InlineInputContainer ref={drop} onClick={focusInput}>
      {label && <InlineInputLabel>{label}</InlineInputLabel>}
      <EmailListInput
        hasError={hasError}
        $empty={emails.length === 0}
        $drop={canDrop}
      >
        {emails.map((email, index) => (
          <EmailTag
            key={index}
            onClick={(e) => e.stopPropagation()}
            $valid={IsValidEmail(email)}
          >
            {email}{' '}
            <CloseCircleOutlined onClick={(e) => handleDelete(e, index)} />
          </EmailTag>
        ))}
        <HiddenEmailInput
          ref={inputRef}
          value={search}
          onBlur={handleBlur}
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={handleKeyDown}
        />
      </EmailListInput>
      {hasError && (
        <InputError style={{bottom: '-15px'}}>
          <Translate id={meta.error} />
        </InputError>
      )}
    </InlineInputContainer>
  )
}

EmailDroppableInput.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
    PropTypes.element
  ])
}

EmailDroppableInput.defaultProps = {
  input: {value: undefined, onChange: () => null},
  meta: {},
  label: undefined
}

export default withLocalize(EmailDroppableInput)
