import React, {useEffect, useState} from 'react'

import {Col, Row} from 'antd'
import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'
import {connect} from 'react-redux'
import {useParams} from 'react-router'
import {reduxForm, Field, initialize} from 'redux-form'

import {
  GetReceiveServer,
  PostReceivingServer,
  PutReceivingServer,
  PutSendServer
} from '../../../../../infra/requests/BaseRequests'
import FormValidator from '../../../../../infra/services/validations/FormValidator'
import BackButton from '../../../../../shared/components/buttons/BackButton'
import BaseButton from '../../../../../shared/components/buttons/BaseButton'
import {InputLabel} from '../../../../../shared/components/inputs/InputStyles'
import SwitchInput from '../../../../../shared/components/inputs/SwitchInput'
import TextInput from '../../../../../shared/components/inputs/TextInput'
import {
  PageForm,
  PageTitle,
  Margin
} from '../../../../../shared/styles/BasicStyles'

const validations = FormValidator.make({
  // name: 'required',
  // hostname: 'required',
  // imapPort: 'required',
  // active: 'required',
  // username: 'required',
  // password: 'required'
})

const ManageReceiveServer = ({router, dispatch, handleSubmit}) => {
  const {id} = useParams()

  useEffect(() => {
    async function fetchReceiveServer() {
      if (id != 'new') {
        const {data, success} = await GetReceiveServer(id)
        if (success) {
          dispatch(initialize('receive_server_form', data))
        }
      }
    }
    fetchReceiveServer()
  }, [])

  const onSubmit = async (values) => {
    if (id != 'new') {
      try {
        const {success} = await PutReceivingServer(id, values)
        if (success) router.history.push('/mailConfiguration#receive')
      } catch (error) {
        console.log(error)
      }
    } else {
      try {
        const {success} = await PostReceivingServer(values)
        if (success) router.history.push('/mailConfiguration#receive')
      } catch (error) {
        console.log(error)
      }
    }
  }

  return (
    <>
      <PageForm onSubmit={handleSubmit(onSubmit)}>
        <Row align='bottom' gutter={[0, 50]}>
          <Col xs={24}>
            <BackButton
              label={<Translate id='BACK' />}
              onClick={() => router.history.push('/mailConfiguration#receive')}
            />
          </Col>
          <Col xs={12}>
            <PageTitle>
              <Translate id='RECEIVE_SERVER' />
            </PageTitle>
          </Col>
          <Col xs={12} align='end'>
            <BaseButton auto htmlType='submit'>
              <Translate id='SAVE' />
            </BaseButton>
          </Col>
        </Row>
        <Margin size='40' />
        <Row gutter={[40, 20]}>
          <Col xs={6}>
            <Field
              component={TextInput}
              name='name'
              label={<Translate id='NAME_1' />}
            />
          </Col>
          <Col xs={6}>
            <Field
              component={TextInput}
              name='hostname'
              label={<Translate id='HOST_NAME' />}
            />
          </Col>
          <Col xs={6}>
            <Field
              component={TextInput}
              name='imapPort'
              label={<Translate id='PORT' />}
            />
          </Col>
          <Col xs={6}>
            <InputLabel>
              <Translate id='STATUS_1' />
            </InputLabel>
            <Field
              component={SwitchInput}
              name='active'
              checkedText={<Translate id='ACTIVE' />}
              uncheckedText={<Translate id='INACTIVE' />}
            />
          </Col>
          <Col xs={6}>
            <Field
              component={TextInput}
              name='username'
              label={<Translate id='USERNAME_1' />}
            />
          </Col>
          <Col xs={6}>
            <Field
              component={TextInput}
              name='password'
              type='password'
              label={<Translate id='PASSWORD' />}
            />
          </Col>
        </Row>
      </PageForm>
    </>
  )
}

ManageReceiveServer.propTypes = {
  router: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  handleSubmit: PropTypes.func
}

ManageReceiveServer.defaultProps = {
  dispatch: undefined,
  handleSubmit: undefined
}

const ManageReceiveServerPage = reduxForm({
  form: 'receive_server_form',
  validate: validations
})(ManageReceiveServer)

export default connect((state) => ({
  clients: state.info.clients
}))(ManageReceiveServerPage)
