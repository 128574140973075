import client from '../config/AxiosConfig'

export const LoginAuth = async (data) => client.post('/token', data)

export const ValidateEmail = async (data) => client.put('/AspnetUsers/VerifyUsername', data)

export const LogoutAuth = async (data) =>
  client.post('/token/logout', data)

export const ChangePassword = async (data) =>
  client.put('/AspnetUsers/ChangePassword', data)

export const RecoverPassword = async (data) =>
  client.post('AspnetUsers/SendUserPasswordRecovery', data)

// export const RecoverPassword = async (data) =>
//   client.post('/EmployeeAuth/password/recovery', data)
