import React from 'react'

import { Popover } from 'antd'
import PropTypes from 'prop-types'
import { Translate, withLocalize } from 'react-localize-redux'

import Info from '../../../assets/icons/info.svg'
import ActiveTranslation from '../../../shared/logic/translations/ActiveTranslation'
import { StyledHR } from '../../../shared/styles/BasicStyles'
import {
  Flex,
  LineContent,
  CircleNote,
  NoteIcon,
  TextPopover
} from '../LogisticStyles'

const ReferencePopup = ({ item, translate }) => {

  const content = (
    <>
      <Flex>
        <TextPopover $bold>
          <Translate id='SUPPLIER' />:
        </TextPopover>
        <TextPopover>{item?.supplierName || <Translate id='NA' />}</TextPopover>
      </Flex>
      <StyledHR $space='7' />
      <Flex>
        <TextPopover $bold>
          <Translate id='EAN13' />:
        </TextPopover>
        <TextPopover>{item?.barcode || <Translate id='NA' />}</TextPopover>
      </Flex>
      <Flex>
        <TextPopover $bold>
          <Translate id='COLOR' />:
        </TextPopover>
        <TextPopover>
          <ActiveTranslation value={item?.colorName} tag='name' />
        </TextPopover>
      </Flex>
      <Flex>
        <TextPopover $bold>
          <Translate id='SIZE' />:
        </TextPopover>
        <TextPopover>
          {item?.sizeName || <Translate id='NA' />}
        </TextPopover>
      </Flex>
      <Flex>
        <TextPopover $bold>
          <Translate id='WIDTH' />:
        </TextPopover>
        <TextPopover>
          <ActiveTranslation value={item?.widthName} tag='name' />
        </TextPopover>
      </Flex>
    </>
  )
  return (
    <Popover placement='top' trigger='hover' content={content}>
      <LineContent>
        {item.reference || <Translate id='NA' />}
        <CircleNote>
          <NoteIcon src={Info} alt={translate('INFO')} />
        </CircleNote>
      </LineContent>
    </Popover>
  )
}

ReferencePopup.propTypes = {
  item: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired
}

export default withLocalize(ReferencePopup)
