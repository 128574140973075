import React, { Component } from 'react'

import { Row, Col } from 'antd'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'

import AddWhite from '../../assets/icons/add_white.svg'
import { SyncMessages } from '../../infra/requests/MessagingRequests'
import BaseButton from '../../shared/components/buttons/BaseButton'
import {
  StyledTabs,
  StyledTabPane,
  StyledTabTitleContainer,
  StyledTabTitle,
  ContentContainer
} from '../../shared/styles/BasicStyles'
import Archive from './archive/Archive'
import Contacts from './contacts/Contacts'
import Incoming from './incoming/Incoming'
import { MessagingContainer } from './MessagingStyles'
import Ongoing from './ongoing/Ongoing'

const Sections = ['incoming', 'ongoing', 'archive', 'contacts']

class MessagingPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      section: '',
      syncButton: false
    }
  }

  componentDidMount() {
    const { router } = this.props

    const hash = router.location.hash.replace('#', '')
    if (Sections.find((s) => s === hash)) {
      this.setState({ section: hash })
    } else this.setState({ section: 'incoming' })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.router !== this.props.router) {
      const { router } = this.props

      const hash = router.location.hash.replace('#', '')
      if (Sections.find((s) => s === hash)) {
        this.setState({ section: hash })
      } else this.setState({ section: 'incoming' })
    }
  }

  onChangeTab = (active) => {
    const { router } = this.props

    this.setState({ section: active })
    router.history.replace(`/messaging#${active}`)
  }

  Synchronize = async () => {
    const { section } = this.state

    try {
      this.setState({ syncButton: true })
      const { success } = await SyncMessages()

      if (success) {
        if (section === 'incoming') {
          this.incoming.updateTable()
        } else if (section === 'ongoing') {
          this.ongoing.updateTable()
        } else if (section === 'archive') {
          this.archive.updateTable()
        }
        this.setState({ syncButton: false })
      }
    } catch (error) {
      console.log(error)
      this.setState({ syncButton: false })
    }
  }

  render() {
    const { router } = this.props
    const { section, syncButton } = this.state

    return (
      <ContentContainer>
        <Row gutter={[20, 20]}>
          <Col>
            <BaseButton
              onClick={() => router.history.push('/messaging/process/new')}
              imagePath={AddWhite}
            >
              <Translate id='NEW_PROCESS' />
            </BaseButton>
          </Col>
          {section !== 'contacts' && (
            <Col>
              <BaseButton
                onClick={() => this.Synchronize()}
                type='secondary'
                loading={syncButton}
              >
                <Translate id='SYNC' />
              </BaseButton>
            </Col>
          )}
        </Row>
        <MessagingContainer>
          <StyledTabs destroyInactiveTabPane activeKey={section} onChange={this.onChangeTab}>
            <StyledTabPane
              tab={
                <StyledTabTitleContainer>
                  <StyledTabTitle>
                    <Translate id='INCOMING' />
                  </StyledTabTitle>
                  {/* <StyledTabBadge>04</StyledTabBadge> */}
                </StyledTabTitleContainer>
              }
              key='incoming'
            >
              {section === 'incoming' && (
                <Incoming
                  router={router}
                  onRef={(ref) => (this.incoming = ref)}
                />
              )}
            </StyledTabPane>
            <StyledTabPane
              tab={
                <StyledTabTitleContainer>
                  <StyledTabTitle>
                    <Translate id='ONGOING_PROCESS' />
                  </StyledTabTitle>
                </StyledTabTitleContainer>
              }
              key='ongoing'
            >
              {section === 'ongoing' && (
                <Ongoing
                  router={router}
                  onRef={(ref) => (this.ongoing = ref)}
                />
              )}
            </StyledTabPane>
            <StyledTabPane
              tab={
                <StyledTabTitleContainer>
                  <StyledTabTitle>
                    <Translate id='ARCHIVE' />
                  </StyledTabTitle>
                </StyledTabTitleContainer>
              }
              key='archive'
            >
              {section === 'archive' && (
                <Archive
                  router={router}
                  onRef={(ref) => (this.archive = ref)}
                />
              )}
            </StyledTabPane>
            <StyledTabPane
              tab={
                <StyledTabTitleContainer>
                  <StyledTabTitle>
                    <Translate id='CONTACTS' />
                  </StyledTabTitle>
                </StyledTabTitleContainer>
              }
              key='contacts'
            >
              {section === 'contacts' && <Contacts router={router} />}
            </StyledTabPane>
          </StyledTabs>
        </MessagingContainer>
      </ContentContainer>
    )
  }
}

MessagingPage.propTypes = {
  router: PropTypes.object.isRequired
}

export default MessagingPage
