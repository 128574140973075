import React, {useState, useEffect} from 'react'

import {Col, Row} from 'antd'
import PropTypes from 'prop-types'
import {useParams} from 'react-router'

import {GetFullProductList} from '../../infra/requests/ProductsRequests'
import BackButton from '../../shared/components/buttons/BackButton'
import BaseList from '../../shared/components/table/BaseList'
import ConstructQuery from '../../shared/logic/queries/EndpointQueries'
import {
  ContentContainer,
  Margin,
  StyledHR,
  TitleH2
} from '../../shared/styles/BasicStyles'
import DuplicateProductCard from './components/DuplicateProductCard'
import ProductFilters from './components/ProductFilters'
import {Translate} from 'react-localize-redux'

const DuplicateProductsPage = ({router}) => {
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [rows, setRows] = useState([])
  const [filters, setFilters] = useState({})
  const [selected, setSelected] = useState()
  const pageSize = 24
  const {id} = useParams()

  useEffect(() => {
    async function init() {
      const {data} = await GetFullProductList(
        page,
        pageSize,
        ConstructQuery(filters)
      )
      setTotal(data?.totalItems || 0)
      setRows(data?.items || [])
      setLoading(false)
    }
    init()
  }, [filters])

  const updateTable = async (p) => {
    setLoading(true)
    const {data} = await GetFullProductList(
      p,
      pageSize,
      ConstructQuery(filters)
    )
    setTotal(data?.totalItems || 0)
    setRows(data?.items || [])
    setLoading(false)
  }

  const onChangePagination = (p) => {
    setPage(p)
    updateTable(p)
  }

  return (
    <ContentContainer>
      <Row gutter={[0, 50]}>
        <Col xs={24}>
          <BackButton
            label={<Translate id='BACK_TO_PRODUCT' />}
            onClick={() => router.history.push(`/products/${id}`)}
          />
        </Col>
      </Row>
      <Margin size={24} />
      <Row gutter={24}>
        <Col xs={24}>
          <TitleH2>
            <Translate id='DUPLICATE_INFORMATION' />
          </TitleH2>
        </Col>
      </Row>
      <Margin size={29} />
      <StyledHR $space='20' />
      <ProductFilters filters={filters} setFilters={setFilters} />
      <StyledHR $space='20' />
      <Row>
        <Col xs={24}>
          <BaseList
            grid={{
              gutter: 20,
              xs: 1,
              sm: 2,
              md: 4,
              lg: 4,
              xl: 6,
              xxl: 6
            }}
            datasource={rows}
            renderItem={(item) => (
              <DuplicateProductCard
                product={item}
                disabled={item.productId === id}
                selected={selected}
                onClose={() => setSelected()}
              />
            )}
            pagination={{
              render: true,
              page,
              pageSize,
              total,
              onChangePagination
            }}
            onClickRow={(item) => {
              if (item.productId !== id) setSelected(item.productId)
            }}
            loading={loading}
          />
        </Col>
      </Row>
    </ContentContainer>
  )
}

DuplicateProductsPage.propTypes = {
  router: PropTypes.object.isRequired
}

export default DuplicateProductsPage
