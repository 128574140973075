import React, { useState } from 'react'

import { PlusOutlined, DeleteOutlined, DownloadOutlined, UploadOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import { Translate, withLocalize } from 'react-localize-redux'
import { change } from 'redux-form'

import CompositionCard from './composition/CompositionCard'
import { CompositionFloatButton } from './composition/CompositionStyles'
import CompositionTable from './composition/CompositionTable'
import { AuthTokenKey } from '../../../../infra/config/LocalStorageKeys'
import AlertService from '../../../../shared/components/alert/AlertService'
import BaseButton from '../../../../shared/components/buttons/BaseButton'
import { Margin } from '../../../../shared/styles/BasicStyles'

const ProductComposition = ({
  showMaterials,
  setShowServices,
  fields,
  colors,
  sizes,
  widths,
  dispatch,
  serviceSuppliers,
  reference,
  translate
}) => {
  const [exporting, setExporting] = useState(false)
  const [selected, setSelected] = useState([])
  const materials = fields.getAll() || []

  const handleDelete = () => {
    const result = []
    materials.forEach((material) => {
      if (material.type === 'family') {
        const subs = []
        material.list.forEach((item) => {
          if (!selected.includes(`${material.lineId}_${item.lineId}`)) {
            subs.push(item)
          }
        })
        if (subs.length > 0) result.push({ ...material, list: subs })
      } else if (!selected.includes(material.lineId)) {
        result.push(material)
      }
    })
    dispatch(change('manage_product', 'composition', result))
    setSelected([])
  }

  const handleDownload = () => {
    const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(materials))}`
    const downloadAnchorNode = document.createElement('a')
    downloadAnchorNode.setAttribute('href', dataStr)
    downloadAnchorNode.setAttribute('download', `${reference || 'product'} ${moment().format('YYYY MM DD HH mm ss')}.json`)
    document.body.appendChild(downloadAnchorNode)
    downloadAnchorNode.click()
    downloadAnchorNode.remove()
  }

  const handleUpload = async (accepted) => {
    if (accepted && accepted.length) {
      const file = accepted[0]
      const text = await file.text()
      const result = JSON.parse(text)

      return AlertService.confirm(
        'Are you sure you want to upload a file?',
        'We do not validate the information you are about to upload. Make sure to use a valid file from a previous download or the system may break!',
        () => dispatch(change('manage_product', 'composition', result))
      )
    }
  }

  const handleReport = () => {
    setExporting(true)
    const payload = {
      storeProcedureName: '[CompositionFile]',
      reportParameters: [{
        parameterLabel: 'Reference',
        parameterName: 'referencecode',
        parameterType: 1,
        parameterValue: reference
      }]
    }

    const authToken = localStorage.getItem(AuthTokenKey)
    fetch(
      `${window.env.REACT_APP_API}/ReportGeneric/ExecuteReport`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      }
    )
      .then((resp) => {
        if (resp.status == 200) {
          resp.blob().then((blob) => {
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.style.display = 'none'
            a.href = url
            a.download = `${reference} ${moment().format('YYYY MM DD HH mm ss')}.csv`
            document.body.appendChild(a)
            a.click()
            window.URL.revokeObjectURL(url)
            setExporting(false)
          }).catch(() => {
            AlertService.error(translate('ERROR'), translate('ERROR_DOWNLOAD_FILE'))
            setExporting(false)
          })
        } else {
          resp.json().then((json) => {
            setExporting(false)
            return AlertService.error(
              translate('ERROR'),
              json.Message || translate('ERROR_DOWNLOAD_FILE')
            )
          }).catch(() => {
            AlertService.error(translate('ERROR'), translate('ERROR_DOWNLOAD_FILE'))
            setExporting(false)
          })
        }
      })
      .catch(() => {
        setExporting(false)
        AlertService.error(translate('ERROR'), translate('ERROR_DOWNLOAD_FILE'))
      })
  }

  return (
    <>
      <Row>
        <Col xs={24} xl={20}>
          <CompositionCard composition={materials} />
        </Col>
      </Row>
      <Margin size={50} />
      <Row gutter={[16, 20]}>
        <Col xs={24}>
          <CompositionFloatButton>
            <BaseButton
              auto
              type='secondary'
              onClick={() => showMaterials(true)}
              icon={<PlusOutlined />}
            >
              <Translate id='ADD_RAW_MATERIALS' />
            </BaseButton>
          </CompositionFloatButton>
          <CompositionFloatButton>
            <BaseButton
              auto
              type='secondary'
              onClick={() => setShowServices(true)}
              icon={<PlusOutlined />}
            >
              <Translate id='ADD_SERVICES' />
            </BaseButton>
          </CompositionFloatButton>
          <CompositionFloatButton $right>
            <BaseButton
              disabled={selected.length < 1}
              type='error'
              onClick={handleDelete}
              icon={<DeleteOutlined />}
              auto
            >
              <Translate id='DELETE' />
            </BaseButton>
          </CompositionFloatButton>
          <CompositionFloatButton $right>
            <Dropzone onDrop={handleUpload} maxFiles={1} accept='.json'>
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <BaseButton
                    onClick={handleUpload}
                    icon={<UploadOutlined />}
                    auto
                  >
                    <Translate id='UPLOAD_COST_FILE' />
                  </BaseButton>
                </div>
              )}
            </Dropzone>
          </CompositionFloatButton>
          <CompositionFloatButton $right>
            <BaseButton
              onClick={handleDownload}
              icon={<DownloadOutlined />}
              auto
            >
              <Translate id='DOWNLOAD_COST_FILE' />
            </BaseButton>
          </CompositionFloatButton>
          <CompositionFloatButton $right>
            <BaseButton
              onClick={handleReport}
              icon={<DownloadOutlined />}
              auto
              loading={exporting}
            >
              <Translate id='DOWNLOAD_COMPOSITION_EXCEL' />
            </BaseButton>
          </CompositionFloatButton>
        </Col>
      </Row>
      <Margin size={20} />
      <Row>
        <Col xs={24}>
          <CompositionTable
            materials={materials}
            form={fields.name}
            colors={colors}
            sizes={sizes}
            widths={widths}
            selected={selected}
            setSelected={setSelected}
            serviceSuppliers={serviceSuppliers}
          />
        </Col>
      </Row>
      <Margin size={20} />
    </>
  )
}

ProductComposition.propTypes = {
  dispatch: PropTypes.func.isRequired,
  showMaterials: PropTypes.func.isRequired,
  setShowServices: PropTypes.func.isRequired,
  serviceSuppliers: PropTypes.array.isRequired,
  fields: PropTypes.object.isRequired,
  colors: PropTypes.array,
  sizes: PropTypes.array,
  widths: PropTypes.array
}

ProductComposition.defaultProps = {
  colors: [],
  sizes: [],
  widths: []
}

export default withLocalize(ProductComposition)
