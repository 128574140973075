import React, { useState, useEffect } from 'react'

import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'

import {
  StyledTabs,
  StyledTabPane,
  StyledTabTitleContainer,
  StyledTabTitle,
  ContentContainer
} from '../../shared/styles/BasicStyles'
import ProductsB2BTab from './tabs/ProductsB2BTab'
import ProductsB2CTab from './tabs/ProductsB2CTab'
import RawMaterialsTabs from './tabs/RawMaterialsTabs'
import ServicesTab from './tabs/ServicesTabs'

const Sections = ['b2b', 'b2c', 'materials', 'services']

const ProductsPage = ({ router }) => {
  const [section, setSection] = useState()
  const iFrameDetection = ((window === window.parent || window.opener))

  useEffect(() => {
    function initialize() {
      const hash = router.location.hash.replace('#', '')
      if (Sections.find((s) => s === hash)) {
        setSection(hash)
      } else setSection('b2b')
    }
    initialize()
  }, [router])

  const onChangeTab = (active) => {
    setSection(active)
    router.history.replace(`/products#${active}`)
  }

  return (
    <ContentContainer>
      <StyledTabs destroyInactiveTabPane activeKey={section} onChange={onChangeTab} $hidden={!iFrameDetection}>
        <StyledTabPane
          tab={
            <StyledTabTitleContainer>
              <StyledTabTitle>
                <Translate id='PRODUCTS_B2B' />
              </StyledTabTitle>
            </StyledTabTitleContainer>
          }
          key='b2b'
        >
          <ProductsB2BTab router={router} />
        </StyledTabPane>
        <StyledTabPane
          tab={
            <StyledTabTitleContainer>
              <StyledTabTitle>
                <Translate id='PRODUCTS_B2C' />
              </StyledTabTitle>
            </StyledTabTitleContainer>
          }
          key='b2c'
        >
          <ProductsB2CTab router={router} />
        </StyledTabPane>
        <StyledTabPane
          tab={
            <StyledTabTitleContainer>
              <StyledTabTitle>
                <Translate id='RAW_MATERIALS' />
              </StyledTabTitle>
            </StyledTabTitleContainer>
          }
          key='materials'
        >
          <RawMaterialsTabs router={router} />
        </StyledTabPane>
        <StyledTabPane
          tab={
            <StyledTabTitleContainer>
              <StyledTabTitle>
                <Translate id='SERVICES' />
              </StyledTabTitle>
            </StyledTabTitleContainer>
          }
          key='services'
        >
          <ServicesTab router={router} />
        </StyledTabPane>
      </StyledTabs>
    </ContentContainer>
  )
}

ProductsPage.propTypes = {
  router: PropTypes.object.isRequired
}

export default ProductsPage
