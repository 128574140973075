import React, { Component } from 'react'

import { Row, Col, Popover } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Translate, withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'

import DropdownIcon from '../../../assets/icons/dropdown_blue.svg'
import ForwardIcon from '../../../assets/icons/forward.svg'
import ReplyIcon from '../../../assets/icons/reply.svg'
import ArchiveIcon from '../../../assets/icons/unarchive.svg'
import {
  AssociateProcess,
  GetIncomingMessages
} from '../../../infra/requests/MessagingRequests'
import AlertService from '../../../shared/components/alert/AlertService'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import DateRangeInput from '../../../shared/components/inputs/DateRangeInput'
import PriorityInput from '../../../shared/components/inputs/PriorityInput'
import SearchInput from '../../../shared/components/inputs/SearchInput'
import SelectInput from '../../../shared/components/inputs/SelectInput'
import BaseTable from '../../../shared/components/table/BaseTable'
import ConstructQuery from '../../../shared/logic/queries/EndpointQueries'
import {
  ToggleImageContainer,
  ToggleImage,
  StyledFlex,
  PopOverTitle,
  PopOverDescription,
  Margin
} from '../../../shared/styles/BasicStyles'
import { BottomIncoming } from '../MessagingStyles'
import IncomingButton from './IncomingButton'


let timeout

class Incoming extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      rows: [],
      page: 1,
      pageSize: 20,
      total: 0,
      filters: {},
      associateProcess: '',
      associatePop: {}
    }
    // Table columns
    this.columns = [
      {
        dataIndex: 'from',
        title: <Translate id='FROM' />,
        render: (text, row) => {
          const content = (
            <StyledFlex $align='flex-start' style={{ padding: '10px 20px' }}>
              <PopOverTitle>
                <Translate id='FROM' />:
              </PopOverTitle>
              <PopOverDescription>{row.from}</PopOverDescription>
              <PopOverTitle>
                <Translate id='TO' />:
              </PopOverTitle>
              {row.to_arr.map((email, index) => (
                <PopOverDescription key={index}>
                  {email}
                </PopOverDescription>
              ))}
              {Array.isArray(row.cc_arr) && row.cc_arr.length > 0 && (
                <>
                  <PopOverTitle>
                    <Translate id='CC' />:
                  </PopOverTitle>
                  {row.cc_arr.map((email, index) => (
                    <PopOverDescription key={index}>
                      {email}
                    </PopOverDescription>
                  ))}
                </>
              )}
            </StyledFlex>
          )

          return (
            <Popover placement='topLeft' content={content} trigger='hover'>
              {row.from}
            </Popover>
          )
        }
      },
      {
        dataIndex: 'client',
        title: <Translate id='CLIENT' />,
        render: (value) => value || 'N/A'
      },
      {
        dataIndex: 'subject',
        title: <Translate id='SUBJECT' />
      },
      {
        dataIndex: 'section',
        title: <Translate id='DEPARTMENT' />,
        render: (value) => value?.name || 'N/A'
      },
      {
        dataIndex: 'date',
        title: <Translate id='DATE' />,
        render: (value) => moment(value).fromNow()
      },
      {
        dataIndex: 'slaid',
        title: <Translate id='PRIORITY' />,
        render: (value) => (
          <PriorityInput input={{ value: value || 3 }} disabled />
        )
      }
    ]
  }

  componentDidMount() {
    const { onRef } = this.props

    this.updateTable()
    onRef(this)
  }

  componentWillUnmount() {
    const { onRef } = this.props
    onRef(undefined)
  }

  setFilters = (values, time) => {
    this.onChangePagination(1)
    this.setState({ filters: values }, () => {
      if (timeout) clearTimeout(timeout)
      timeout = setTimeout(this.updateTable, time)
    })
  }

  updateTable = async () => {
    const { filters, page, pageSize } = this.state
    this.setState({ loading: true })
    const { data } = await GetIncomingMessages(
      page,
      pageSize,
      ConstructQuery(filters)
    )
    this.setState({
      rows: data?.items || [],
      total: data?.totalItems || 0,
      loading: false
    })
  }

  onChangePagination = (page) => this.setState({ page }, this.updateTable)

  saveAssociateProcess = async (messageId, translate) => {
    const { associateProcess } = this.state
    try {
      const object = {
        messageId,
        processId: associateProcess
      }
      const { success } = await AssociateProcess(object)

      if (success) {
        AlertService.success(
          translate('SUCCESS'),
          translate('MESSAGE_ASSOCIATED_SUCCESSFULLY')
        )
        this.updateTable()
      }
    } catch (error) {
      console.log(error)
    }
  }

  renderAssociatePopUp = (record, index) => {
    const { processes, translate } = this.props
    const { associateProcess } = this.state

    return (
      <StyledFlex>
        <Margin size={10} />
        <SelectInput
          label={<Translate id='ASSOCIATE_EXISTING_PROCESS' />}
          placeholder={translate('SELECT')}
          input={{
            value: associateProcess,
            onChange: (value) => this.setState({ associateProcess: value })
          }}
          data={processes}
          dataKey='processId'
          dataLabel='subject'
        />
        <Margin size={20} />
        <StyledFlex style={{ width: '100%' }} $align='flex-end'>
          <BaseButton
            type='primary'
            size='small'
            auto
            onClick={() => this.saveAssociateProcess(record.messageId)}
          >
            <Translate id='SAVE' />
          </BaseButton>
        </StyledFlex>
        <Margin size={10} />
      </StyledFlex>
    )
  }

  render() {
    const { router, translate } = this.props
    const {
      rows,
      page,
      pageSize,
      total,
      search,
      dateRange,
      associatePop,
      loading,
      filters
    } = this.state

    return (
      <>
        <Row gutter={[24, 16]} justify='end'>
          <Col xs={24} md={24} lg={6}>
            <SearchInput
              input={{
                value: search,
                onChange: (value) =>
                  this.setFilters({ ...filters, search: value }, 1000),
                onSearch: () => this.setFilters({ ...filters, '': '' }, 0)
              }}
              placeholder={translate('SEARCH')}
            />
          </Col>
          <Col xs={24} md={24} lg={6}>
            <DateRangeInput
              input={{
                value: dateRange,
                onChange: (date) => {
                  this.setFilters(
                    {
                      ...filters,
                      StartDate: date ? date[0].startOf('day') : undefined,
                      EndDate: date ? date[1].endOf('day') : undefined
                    },
                    0
                  )
                }
              }}
            />
          </Col>
        </Row>
        <Margin size='16' />
        <Row gutter={[24, 16]}>
          <Col xs={24}>
            <BaseTable
              datasource={rows}
              columns={this.columns}
              loading={loading}
              emptyMessage={<Translate id='NO_INCOMING_MESSAGES' />}
              pagination={{
                render: true,
                page,
                pageSize,
                total,
                onChangePagination: this.onChangePagination
              }}
              highlightRow={(record) => record.seen}
              isWhiteTable
              rowKey='messageId'
              expandable={{
                expandIcon: ({ expanded, onExpand, record }) => (
                  <ToggleImageContainer
                    role='button'
                    tabIndex={0}
                    onClick={(e) => onExpand(record, e)}
                  >
                    <ToggleImage
                      src={DropdownIcon}
                      $open={expanded}
                      $noMarginRight
                    />
                  </ToggleImageContainer>
                ),
                expandedRowRender: (record, index) => (

                  //Table Dropdown render
                  <>
                    <div
                      style={{ padding: '10px 20px' }}
                      dangerouslySetInnerHTML={{ __html: record.bodyHtml }}
                    />
                    <BottomIncoming>
                      <Popover
                        placement='topLeft'
                        content={this.renderAssociatePopUp(record, index)}
                        trigger='click'
                        visible={associatePop[index] || false}
                        onVisibleChange={(visible) => {
                          if (visible) {
                            this.setState({
                              associatePop: {
                                ...associatePop,
                                [index]: true
                              }
                            })
                          } else {
                            this.setState({
                              associateProcess: '',
                              associatePop: {
                                ...associatePop,
                                [index]: false
                              }
                            })
                          }
                        }}
                      >
                        <IncomingButton
                          text={<Translate id='ASSOCIATE_PROCESS' />}
                          imgSource={ArchiveIcon}
                        />
                      </Popover>
                      <StyledFlex $direction='row'>
                        <IncomingButton
                          text={<Translate id='REPLY' />}
                          imgSource={ReplyIcon}
                          onClick={() =>
                            router.history.push(
                              `/messaging/message/${record.messageId}/reply`
                            )
                          }
                          hasRightBorder
                        />
                        <IncomingButton
                          text={<Translate id='FORWARD' />}
                          imgSource={ForwardIcon}
                          onClick={() =>
                            router.history.push(
                              `/messaging/message/${record.messageId}/forward`
                            )
                          }
                        />
                      </StyledFlex>
                    </BottomIncoming>
                  </>
                )
              }}
            />
          </Col>
        </Row>
      </>
    )
  }
}

Incoming.propTypes = {
  router: PropTypes.object.isRequired
}

export default withLocalize(
  connect((state) => ({
    processes: state.info.processes
  }))(Incoming)
)
