import React from 'react'

import Captcha from 'react-numeric-captcha'

import {CaptchaWrapper} from '../AuthStyles'

const CaptchaInput = ({
  input,
  meta
}) => {
  const hasError = meta.invalid && meta.submitFailed
  const handleChange = (value) => {
    input.onChange(value)
  }
  return (
    <CaptchaWrapper hasError={hasError}>
      <Captcha onChange={handleChange} />
    </CaptchaWrapper>
  )
}

export default CaptchaInput
