const ArrayToString = (array) => {
  if (array?.length > 0) {
    let string = ''
    array.forEach((element) => {
      if (string === '') {
        string += element.position.name
      } else {
        string += `, ${element.position.name}`
      }
    })
    return string
  }
  return 'N/A'
}

export default ArrayToString
