import React, { useState } from 'react'

import { Col } from 'antd'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'

import BaseButton from '../../../shared/components/buttons/BaseButton'
import TextAreaInput from '../../../shared/components/inputs/TextAreaInput'
import { StyledOrderNotesCol } from '../OrdersStyles'

const OrderNotes = ({ initialNotesValue, updateOrderNotes, savingNotes, shouldShowUpdateButton, setShouldShowUpdateButton }) => {
  const [notesValue, setNotesValue] = useState(initialNotesValue)
  const [isFocused, setIsFocused] = useState(false)
  return (
    <>
      <Col xs={12} lg={8}>
        <TextAreaInput
          rows={5}
          input={{ value: notesValue, onChange: setNotesValue }}
          afterChange={() => setShouldShowUpdateButton(true)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          backgroundColor={isFocused ? 'white' : 'transparent'}
        />
      </Col>
      <StyledOrderNotesCol xs={12} lg={4}>
        {shouldShowUpdateButton &&
          <BaseButton onClick={() => updateOrderNotes(notesValue)} loading={savingNotes}>
            <Translate id='UPDATE_NOTES' />
          </BaseButton>}
      </StyledOrderNotesCol>
    </>
  )
}

OrderNotes.propTypes = {
  initialNotesValue: PropTypes.string.isRequired,
  updateOrderNotes: PropTypes.func.isRequired,
  savingNotes: PropTypes.bool.isRequired,
  shouldShowUpdateButton: PropTypes.bool.isRequired,
  setShouldShowUpdateButton: PropTypes.func.isRequired
}

export default OrderNotes
