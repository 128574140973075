export const ArrayPushIfNotExist = (array, element, comparer) => {
  const compareFunc = (elem) => elem === element
  const found =
    typeof comparer === 'function'
      ? array.find(comparer)
      : array.find(compareFunc)
  if (!found) array.push(element)
}

export const MaxValueFromArray = (array, comparer) => {
  const {max} = Math
  return max.apply(Math, array.map(comparer))
}
