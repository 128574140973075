import client from '../config/AxiosConfig'

export const GetInfoOrdersB2B2C = async () =>
  client.get('Orders/GetInfoOrdersB2B2C')

export const GetOrderB2B2CDetails = async (id) =>
  client.get(`/Order/GetOrderFullDetail/${id}`)

export const UpdateOrderB2B2C = async (id, data) =>
  client.post(`/Orders/UpdateOrder/${id}`, data)

export const UpdateOrderNotes = async (id, data) =>
  client.put(`/Orders/UpdateOrder/${id}?updateNotes=true`, data)
