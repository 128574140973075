import React, {useEffect, useState} from 'react'

import {Col, Row} from 'antd'
import moment from 'moment'
import {Translate} from 'react-localize-redux'
import {Field} from 'redux-form'

import {GetIncotermsDrop, GetShipmentRates} from '../../../../infra/requests/DepartureRequests'
import DateInput from '../../../../shared/components/inputs/DateInput'
import ProductPriceInput from '../../../../shared/components/inputs/ProductPriceInput'
import SelectInput from '../../../../shared/components/inputs/SelectInput'
import TextInput from '../../../../shared/components/inputs/TextInput'
import BaseTable from '../../../../shared/components/table/BaseTable'
import {Margin, TitleH4} from '../../../../shared/styles/BasicStyles'

const ShippingOrderBoxes = ({
  fields,
  carriers,
  selectedService,
  setSelectedService,
  order: {
    orderBox,
    deliveryCountryId,
    deliveryZipCode,
    deliveryRegion,
    deliveryCity,
    deliveryStreetNumber,
    deliveryAddressLine3,
    deliveryAddressLine2,
    deliveryAddressLine1}
}) => {
  const [incoterms, setIncoterms] = useState([])
  const [loadOptions, setLoad] = useState(false)
  const [options, setOptions] = useState([])

  useEffect(() => {
    async function initialize() {
      const result = await GetIncotermsDrop()

      if (Array.isArray(result.data)) {
        setIncoterms(result.data.map((v, i) => ({id: i, name: v[i]})))
      }
    }
    initialize()
  }, [])

  const columns = [
    {
      type: 'text',
      title: <Translate id='SERVICE' />,
      dataIndex: 'name'
    },
    {
      type: 'text',
      title: <Translate id='ESTIMATED_DATE' />,
      dataIndex: 'deliveryTime',
      render: (value) => moment(value).format('DD-MM-YYYY')
    },
    {
      type: 'text',
      title: <Translate id='DELIVERY_UNTIL' />,
      dataIndex: 'deliveryTime',
      render: (value) => moment(value).format('HH:mm')
    },
    {
      type: 'text',
      title: <Translate id='ESTIMATED_PRICE' />,
      dataIndex: 'amount',
      render: (value) => <div>{value}€</div>
    }
  ]

  const getCarrierShipmentRates = async (selectedIndex, date) => {
    const currentShipping = fields.get(selectedIndex)
    if (currentShipping.carrierId === '3') {
      setLoad(true)

      const shippingDateFormatted = (date || currentShipping.shippingDate).set({hour: 10, minute: 0, second: 0})

      const info = {
        originAddressLine1: '',
        originAddressLine2: '',
        originAddressLine3: '',
        originStreetNumber: '',
        originCity: '',
        originRegion: '',
        originZipCode: '',
        originCountryId: '1',
        deliveryAddressLine1,
        deliveryAddressLine2,
        deliveryAddressLine3,
        deliveryStreetNumber,
        deliveryCity,
        deliveryRegion,
        deliveryZipCode,
        deliveryCountryId,
        shippingDate: shippingDateFormatted,
        shippingValue: currentShipping.shippingValue,
        packages: (orderBox || []).map((item) => ({
          weight: item.weight,
          height: item.dimHeight,
          depth: item.dimDepth,
          width: item.dimWidth,
          quantity: orderBox.length
        }))
      }
      const {success, data} = await GetShipmentRates(info)
      if (success) {
        const newOptions = [...options]
        newOptions[selectedIndex] = data
        setOptions(newOptions)
        setLoad(false)
      }
    }
  }

  const renderOptions = (selectedIndex) => {
    const currentShipping = fields.get(selectedIndex)
    if (currentShipping?.carrierId) {
      if (currentShipping?.carrierId == '3' && !currentShipping.shipmentId) {
        return (
          <Col xs={24}>
            <BaseTable
              rowKey='type'
              datasource={options[selectedIndex]}
              columns={columns}
              pagination={{render: false}}
              loading={loadOptions}
              rowSelection={{
                selected: selectedService,
                onRowSelection: setSelectedService
              }}
            />
          </Col>
        )
      }
      return (
        <>
          <Col xs={6}>
            <Field
              component={DateInput}
              name={`shipping.${selectedIndex}.estimatedShippingDate`}
              label='Estimated Delivery Date'
              placeholder='Select'
              disabledDate={(value) => moment().startOf('day').isAfter(value.endOf('day'))}
              disabled={currentShipping.shipmentId && currentShipping.shipmentId > 0}

            />
          </Col>
          <Col xs={6}>
            <Field
              component={ProductPriceInput}
              name={`shipping.${selectedIndex}.estimatedShippingValue`}
              label='Estimated Shipping Value'
              placeholder='Define estimated shipping value'
              disabled={currentShipping.shipmentId && currentShipping.shipmentId > 0}
            />
          </Col>
          {currentShipping?.carrierId != '3' && (
            <Col xs={6}>
              <Field
                component={TextInput}
                name={`shipping.${selectedIndex}.trackingNumber`}
                label='Tracking Number'
                placeholder='Define the tracking number for the client'
                disabled={currentShipping.shipmentId && currentShipping.shipmentId > 0}
              />
            </Col>
          )}
        </>
      )
    }
    return null
  }

  const renderPackagesNames = (orderPackageId, boxes) => {
    if (orderPackageId && Array.isArray(orderPackageId)) {
      return orderPackageId.map((x, currentIndex) => currentIndex === orderPackageId.length - 1 ? `${x}` : `${x}, `)
    }

    if (boxes && Array.isArray(boxes)) {
      return boxes.map((x, currentIndex) => currentIndex === boxes.length - 1 ? `${x.boxId}` : `${x.boxId}, `)
    }
    return ''
  }

  return (
    <>
      {fields.map((member, index) => {
        const {orderPackageId, shipmentId, boxes} = fields.get(index)
        return (
          <div key={index}>
            <TitleH4 $light>
              Package - { renderPackagesNames(orderPackageId, boxes)}
            </TitleH4>
            <Margin size={30} />
            <Row gutter={[24, 24]}>
              <Col xs={6}>
                <Field
                  component={SelectInput}
                  name={`${member}.carrierId`}
                  label={<Translate id='AVAILABLE_CARRIERS' />}
                  placeholder='Select carrier'
                  data={carriers}
                  allowClear={false}
                  dataLabel='name'
                  dataKey='carrierID'
                  afterChange={() => getCarrierShipmentRates(index)}
                  disabled={shipmentId && shipmentId > 0}
                />
              </Col>
              <Col xs={4}>
                <Field
                  component={SelectInput}
                  name={`${member}.incoterms`}
                  label='Incoterms'
                  placeholder='Define incoterms'
                  data={incoterms}
                  disabled={shipmentId && shipmentId > 0}
                />
              </Col>
              <Col xs={4}>
                <Field
                  component={ProductPriceInput}
                  name={`${member}.shippingValue`}
                  label='Shipping Value'
                  placeholder='Define shipping value'
                  disabled={shipmentId && shipmentId > 0}
                />
              </Col>
              <Col xs={4}>
                <Field
                  component={DateInput}
                  name={`${member}.shippingDate`}
                  label='Shipping Date'
                  placeholder='Select shipping date'
                  disabledDate={(value) => moment().startOf('day').isAfter(value.endOf('day'))}
                  afterChange={(date) => getCarrierShipmentRates(index, date)}
                  disabled={shipmentId && shipmentId > 0}
                />
              </Col>
              <Col xs={6}>
                <Field
                  component={TextInput}
                  name={`${member}.contents`}
                  label='Shipping Contents'
                  placeholder='Content information'
                  disabled={shipmentId && shipmentId > 0}
                />
              </Col>
            </Row>
            <Margin size={30} />
            <Row gutter={[24, 24]}>{renderOptions(index)}</Row>
            <Margin size={20} />
          </div>
        )
      })}
    </>
  )
}

export default ShippingOrderBoxes
