import React, {useState} from 'react'

import {Row, Col} from 'antd'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {change, FieldArray, getFormValues} from 'redux-form'

import {UpdatePackages} from '../../../../infra/requests/OrdersB2CRequests'
import AlertService from '../../../../shared/components/alert/AlertService'
import BackButton from '../../../../shared/components/buttons/BackButton'
import NextButton from '../../../../shared/components/buttons/NextButton'
import BaseLoading from '../../../../shared/components/loading/BaseLoading'
import {Margin} from '../../../../shared/styles/BasicStyles'
import VerifyBoxesInfo from '../helpers/VerifyBoxesInfo'
import CreatePackages from './CreatePackages'
import OrderBoxes from './OrderBoxes'

const PackingData = ({setCurrentStep, handleNext, currentFormValues, dispatch, translate}) => {
  const [loading, setLoading] = useState(false)

  const saveBoxes = async () => {
    const sentBoxes = currentFormValues.orderBox?.some((b) => b.sent)
    const pendingBoxes = currentFormValues.orderBox.filter((x) => !x.sent)
    if (pendingBoxes.length == 0 && !sentBoxes) {
      return AlertService.error(
        translate('ERROR'),
        translate('SELECT_PRODUCTS_BOXES_MESSAGE')
      )
    }

    const errors = VerifyBoxesInfo(pendingBoxes)
    if (errors.length) {
      return AlertService.error(
        translate('ERROR'),
        <div>
          {errors.map((t) => <div>{translate(t)}</div>)}
        </div>
      )
    }

    setLoading(true)
    const {success, data} = await UpdatePackages(currentFormValues.orderId, pendingBoxes)

    if (success) {
      if (Array.isArray(data) && data.length > 0) {
        dispatch(
          change(
            'manage-order-b2b2c-b2c',
            'orderBox',
            data
          )
        )
      }
      handleNext(4)
    }
    setLoading(false)
  }

  const showBackButton = () => currentFormValues?.orderItem.some((x) => x.quantity !== x.quantityConfirmed)

  if (loading) return <BaseLoading margin='100' />

  return (
    <>
      <Margin size={30} />
      <Row gutter={[30, 30]}>
        <Col xs={24}>
          <FieldArray component={CreatePackages} name='orderItem' />
        </Col>
        <Col xs={24}>
          <FieldArray component={OrderBoxes} name='orderBox' />
        </Col>
      </Row>
      <Margin size={50} />
      <Row>
        {showBackButton() &&
        <Col xs={12}>
          <BackButton
            label={<Translate id='BACK' />}
            onClick={() => setCurrentStep(2)}
          />
        </Col>
        }
        <Col xs={24} style={{textAlign: 'right'}}>
          <NextButton
            label={<Translate id='NEXT' />}
            onClick={saveBoxes}
          />
        </Col>
      </Row>
    </>
  )
}

export default withLocalize(connect((state) => ({
  currentFormValues: getFormValues('manage-order-b2b2c-b2c')(state)
}))(PackingData))
