import React from 'react'

import {Translate} from 'react-localize-redux'
import styled from 'styled-components'

import {PrimaryColor} from '../../../shared/styles/_colors'

const Container = styled.div``
const Line = styled.div``
const Label = styled.div`
  display: inline-block;
  color: ${PrimaryColor};
  font-size: 14px;
  margin-right: 5px;
`
const Value = styled.div`
  display: inline-block;
  font-size: 16px;
`

const GetSupplier = (item) => {
  if (item && item.orderSupplier) {
    const order = item.orderSupplier.find(
      (i) => typeof i.supplier === 'object'
    )
    return order?.supplier?.name || <Translate id='NA' />
  }
  return <Translate id='NA' />
}

const SupplierOrderOption = (item) => (
  <Container key={item.orderSupplierSetId}>
    <Line>
      <Label><Translate id='SUPPLIER' />:</Label>
      <Value>{GetSupplier(item)}</Value>
    </Line>
    <Line>
      <Label><Translate id='ORDER_SET_ID' /> :</Label>
      <Value>{item.orderSupplierSetId}</Value>
    </Line>
    <Line>
      <Label><Translate id='SUPPLIER_ORDERS' />:</Label>
      {item.orderSupplier.map((so, index) => (
        <span key={so.orderSupplierId}>
          {so.orderSupplierId}
          {index + 1 < item.orderSupplier.length && ', '}
        </span>
      ))}
    </Line>
  </Container>
)

export const SupplierOrderSelected = (item) =>
  `(${item.orderSupplierSetId}) ${GetSupplier(item)}`

export default SupplierOrderOption
