import React from 'react'

import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {Field} from 'redux-form'

import AlertService from '../../../shared/components/alert/AlertService'
import FileInput from '../../../shared/components/inputs/FileInput'
import IntegerInput from '../../../shared/components/inputs/IntegerInput'
import PriorityInput from '../../../shared/components/inputs/PriorityInput'
import SwitchInput from '../../../shared/components/inputs/SwitchInput'
import TextInput from '../../../shared/components/inputs/TextInput'
import {Margin} from '../../../shared/styles/BasicStyles'
import {
  BannerImage,
  BannerImageContainer
} from '../../menuSettings/marketing/MarketingStyles'
import {SubTitle} from '../ClientStyles'

const Information = ({
  picture,
  setPicture,
  video,
  fileError,
  failed,
  translate
}) => {
  const setMovie = (value) => {
    if (value.type == 'video/webm' || value.type == 'video/mp4') {
      AlertService.success(
        translate('SUCCESS'),
        translate('VIDEO_SUCCESSFULLY_ACCEPTED')
      )
      return value
    }
    AlertService.error(
      translate('ERROR'),
      translate('VIDEO_FORMAT_NOT_ACCEPTED')
    )
  }

  const renderImg = () => {
    const image =
      picture !== undefined &&
      (picture?.name || picture?.path) &&
      URL.createObjectURL(picture)

    return (
      <>
        {picture !== undefined && (
          <BannerImageContainer>
            <BannerImage
              src={picture?.name || picture?.path ? image : `${picture}`}
            />
          </BannerImageContainer>
        )}
      </>
    )
  }

  return (
    <>
      <Row gutter={[24, 50]}>
        <Col xs={8}>
          <Field
            component={TextInput}
            name='positionInformationSection.name'
            label={<Translate id='NAME' />}
            placeholder={translate('NAME')}
          />
        </Col>
        <Col xs={5}>
          <Field
            component={IntegerInput}
            name='positionInformationSection.orderQuantity'
            label={<Translate id='ORDERS_PERIOD' />}
            placeholder={translate('ORDERS_PERIOD')}
          />
        </Col>
        <Col xs={5}>
          <Field
            component={IntegerInput}
            name='positionInformationSection.orderPeriod'
            label={<Translate id='RENOVATION_ORDERS' />}
            inputDescriptionText={<Translate id='MONTHS' />}
            placeholder={translate('RENOVATION_ORDERS')}
          />
        </Col>
        <Col xs={2}>
          <Field
            component={SwitchInput}
            name='positionInformationSection.active'
            checkedText={<Translate id='TRUE' />}
            uncheckedText={<Translate id='FALSE' />}
            label={<Translate id='ACTIVE' />}
          />
        </Col>
        <Col xs={4}>
          <Field
            component={SwitchInput}
            name='positionInformationSection.freeDotationChoice'
            checkedText={<Translate id='TRUE' />}
            uncheckedText={<Translate id='FALSE' />}
            label={<Translate id='FREE_DOTATION_CHOICE' />}
          />
        </Col>
        <Col xs={4}>
          <Field
            component={TextInput}
            name='positionInformationSection.b2bclient.name'
            label={<Translate id='COMPANY' />}
            disabled
          />
        </Col>
        <Col xs={4}>
          <Field
            component={TextInput}
            name='positionInformationSection.b2bclient.countryHeadquarters.name'
            label={<Translate id='COUNTRY' />}
            disabled
          />
        </Col>
      </Row>
      <Margin size={20} />
      <Row>
        <Col xs={24}>
          <SubTitle>
            <Translate id='MESSAGING_PRIORITY' />
          </SubTitle>
        </Col>
        <Col xs={24}>
          <span style={{marginRight: '8px'}}>
            <Translate id='LEVEL' />
          </span>
          <Field
            name='positionInformationSection.sla.order'
            component={PriorityInput}
            label={<Translate id='LEVEL' />}
          />
        </Col>
      </Row>
      <Margin size={20} />
      <Row>
        <Col xs={24}>
          <SubTitle>
            <Translate id='MEDIA' />
          </SubTitle>
        </Col>
      </Row>
      <Margin size={31} />
      <Row gutter={[40, 20]}>
        <Col xs={12}>
          <FileInput
            accept='image/*'
            label={<Translate id='PICTURE' />}
            input={{
              value: picture,
              onChange: setPicture
            }}
            meta={{
              invalid: fileError,
              submitFailed: failed,
              error: <Translate id='MISSING_FILE' />
            }}
          />
          <Margin size={23} />
          {renderImg()}
        </Col>
        <Col xs={12}>
          <FileInput
            accept='video/*'
            label={<Translate id='VIDEO' />}
            input={{
              value: video,
              onChange: (value) => setMovie(value)
            }}
            meta={{
              invalid: fileError,
              submitFailed: failed,
              error: <Translate id='MISSING_FILE' />
            }}
          />
        </Col>
      </Row>
    </>
  )
}

Information.propTypes = {
  picture: PropTypes.string,
  setPicture: PropTypes.func,
  video: PropTypes.string,
  fileError: PropTypes.bool,
  failed: PropTypes.bool
}

Information.defaultProps = {
  picture: undefined,
  setPicture: undefined,
  video: undefined,
  fileError: false,
  failed: false
}

export default withLocalize(Information)
