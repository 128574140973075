import AVIIcon from '../../../assets/icons/files/avi.svg'
import BMPIcon from '../../../assets/icons/files/bmp.svg'
import DOCIcon from '../../../assets/icons/files/doc.svg'
import FLAIcon from '../../../assets/icons/files/fla.svg'
import FLVIcon from '../../../assets/icons/files/flv.svg'
import GIFIcon from '../../../assets/icons/files/gif.svg'
import JPGIcon from '../../../assets/icons/files/jpg.svg'
import MOVIcon from '../../../assets/icons/files/mov.svg'
import MP3Icon from '../../../assets/icons/files/mp3.svg'
import MPGIcon from '../../../assets/icons/files/mpg.svg'
import PDFIcon from '../../../assets/icons/files/pdf.svg'
import PNGIcon from '../../../assets/icons/files/png.svg'
import PPTIcon from '../../../assets/icons/files/ppt.svg'
import RAWIcon from '../../../assets/icons/files/raw.svg'
import SVGIcon from '../../../assets/icons/files/svg.svg'
import TXTIcon from '../../../assets/icons/files/txt.svg'
import WMVIcon from '../../../assets/icons/files/wmv.svg'
import XLSIcon from '../../../assets/icons/files/xls.svg'
import ZIPIcon from '../../../assets/icons/files/zip.svg'

const GetFileType = (file) => {
  let type
  const re = /(?:\.([^.]+))?$/
  if (file.type) {
    type = file.type
  } else {
    type = re.exec(file.name)[1]
  }

  switch (type) {
    case 'avi':
      return AVIIcon
    case 'bmp':
      return BMPIcon
    case 'doc':
    case 'docx':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return DOCIcon
    case 'fla':
      return FLAIcon
    case 'flv':
      return FLVIcon
    case 'gif':
      return GIFIcon
    case 'image/jpg':
    case 'image/jpeg':
    case 'jpg':
    case 'jpeg':
      return JPGIcon
    case 'mov':
      return MOVIcon
    case 'mp3':
      return MP3Icon
    case 'mpg':
      return MPGIcon
    case 'application/pdf':
    case 'pdf':
      return PDFIcon
    case 'image/png':
    case 'png':
      return PNGIcon
    case 'ppt':
    case 'pptx':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return PPTIcon
    case 'raw':
      return RAWIcon
    case 'image/svg':
    case 'svg':
      return SVGIcon
    case 'image/svg+xml':
      return SVGIcon
    case 'txt':
      return TXTIcon
    case 'wmv':
      return WMVIcon
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'xls':
    case 'xlsx':
      return XLSIcon
    case 'application/x-zip-compressed':
    case 'zip':
    case 'rar':
      return ZIPIcon
    default:
      return RAWIcon
  }
}

export default GetFileType
