import React, {useState} from 'react'

import {SearchOutlined, Loading3QuartersOutlined} from '@ant-design/icons'
import {PropTypes} from 'prop-types'
import {Translate} from 'react-localize-redux'

import ActiveTranslation from '../../logic/translations/ActiveTranslation'
import {
  InputBox,
  InputLabel,
  SelectInputStyled,
  SelectOption,
  SelectArrow,
  SelectLoading,
  NotFoundContainer
} from './InputStyles'

let timeout

const AutocompleteInput = ({
  input,
  label,
  meta,
  placeholder,
  dataKey,
  dataLabel,
  allowClear,
  mode,
  disabled,
  small,
  renderLabel,
  clearValue,
  translateTag,
  handleRequest,
  notFoundMessage,
  afterChange,
  renderSelected
}) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  const handleChange = (value) => {
    input.onChange(value)
    if (typeof afterChange === 'function') {
      const option = data.find((d) => d[dataKey] === value)
      setTimeout(() => afterChange(value, option), 100)
    }
  }

  const hasError = meta.invalid && meta.submitFailed

  const defineValue = (value) => {
    if (clearValue) return placeholder
    if (typeof value === 'boolean') return `${value}`
    if ((value || value === 0) && value !== '') {
      if (typeof renderSelected === 'function') {
        const option = data.find((d) => d[dataKey] === value)
        return option ? renderSelected(option) : undefined
      }
      return value
    }
    return undefined
  }

  const handleLabel = (current) => {
    if (renderLabel) return renderLabel(current)
    if (!current) return undefined
    let result
    if (Array.isArray(result)) {
      return <ActiveTranslation value={result} tag={translateTag} />
    }
    return current[dataLabel]
  }

  const handleSearch = async (value) => {
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(async () => {
      if (value && value !== '') {
        setLoading(true)
        const result = await handleRequest(value)
        setData(result)
        setLoading(false)
      }
    }, 1000)
  }

  return (
    <InputBox>
      {label && <InputLabel>{label}</InputLabel>}
      <SelectInputStyled
        mode={mode}
        $small={small}
        hasError={hasError}
        placeholder={placeholder}
        value={defineValue(input.value)}
        showSearch
        onChange={handleChange}
        allowClear={allowClear}
        notFoundContent={
          <NotFoundContainer>
            {loading ? (
              <Loading3QuartersOutlined spin />
            ) : (
              <SearchOutlined />
            )}
            {notFoundMessage}
          </NotFoundContainer>
        }
        suffixIcon={
          loading ? (
            <SelectLoading small={small} />
          ) : (
            <SelectArrow $small={small} />
          )
        }
        filterOption={false}
        onSearch={handleSearch}
        disabled={disabled}
        loading={loading}
      >
        {data.map((current) => (
          <SelectOption
            key={current[dataKey]}
            disabled={current.disabled}
            value={current[dataKey]}
          >
            {handleLabel(current)}
          </SelectOption>
        ))}
      </SelectInputStyled>
    </InputBox>
  )
}

AutocompleteInput.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.element
  ]),
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.element
  ]),
  dataKey: PropTypes.string,
  dataLabel: PropTypes.string,
  translateTag: PropTypes.string,
  mode: PropTypes.string,
  allowClear: PropTypes.bool,
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  renderLabel: PropTypes.func,
  notFoundMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.element
  ]),
  renderSelected: PropTypes.func
}

AutocompleteInput.defaultProps = {
  input: {value: undefined, onChange: () => null},
  label: undefined,
  placeholder: undefined,
  meta: {},
  dataKey: 'id',
  dataLabel: 'name',
  mode: undefined,
  allowClear: false,
  disabled: false,
  small: false,
  renderLabel: undefined,
  translateTag: 'name',
  notFoundMessage: <Translate id='SEARCH_FOR' />,
  renderSelected: undefined
}

export default AutocompleteInput
