import React, { Component } from 'react'

import { Row, Col } from 'antd'
import PropTypes from 'prop-types'
import { Translate, withLocalize } from 'react-localize-redux'

import Add from '../../../assets/icons/add_bordered.svg'
import { GetServices } from '../../../infra/requests/ProductsRequests'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import SearchInput from '../../../shared/components/inputs/SearchInput'
import StatusColumn from '../../../shared/components/Status/StatusColumn'
import BaseTable from '../../../shared/components/table/BaseTable'
import ActiveTranslation from '../../../shared/logic/translations/ActiveTranslation'
import { Margin } from '../../../shared/styles/BasicStyles'

let timeout

class ServicesTab extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      rows: [],
      page: 1,
      pageSize: 24,
      total: 0,
      search: ''
    }
  }

  getColumns = () => [
    {
      title: <Translate id='NAME' />,
      type: 'text',
      dataIndex: 'serviceTranslation',
      render: (value) => <ActiveTranslation value={value} tag='name' />
    },
    {
      title: <Translate id='CODE' />,
      type: 'text',
      dataIndex: 'code'
    },
    {
      title: <Translate id='REFERENCE' />,
      type: 'text',
      dataIndex: 'reference'
    },
    {
      title: <Translate id='STATE' />,
      type: 'text',
      dataIndex: 'active',
      render: (value) => <StatusColumn value={value} />
    }
  ]

  componentDidMount = async () => {
    try {
      this.updateTable()
    } catch (error) {
      console.warn(error)
    }
  }

  updateTable = async () => {
    const { page, pageSize, search } = this.state
    this.setState({ loading: true })
    const { data } = await GetServices(page, pageSize, search)
    this.setState({
      rows: data?.items,
      total: data?.totalItems,
      loading: false
    })
  }

  onChangePagination = (page) => this.setState({ page }, this.updateTable)

  openService = (row) => {
    const { router } = this.props
    router.history.push(`/services/${row.serviceId}`)
  }

  setFilter = (search) => {
    this.setState({ search, page: 1 }, () => {
      if (timeout) clearTimeout(timeout)
      timeout = setTimeout(this.updateTable, 1000)
    })
  }

  render() {
    const { loading, rows, page, pageSize, total, search } = this.state
    const { router, translate } = this.props
    return (
      <>
        <Row gutter={[24, 24]}>
          <Col xs={6}>
            <BaseButton
              auto
              imagePath={Add}
              type='primary'
              onClick={() => router.history.push('/services/new')}
            >
              <Translate id='ADD_NEW' />
            </BaseButton>
          </Col>
          <Col xs={6} offset={12}>
            <SearchInput
              auto
              input={{
                value: search,
                onChange: this.setFilter,
                onSearch: this.updateTable
              }}
              placeholder={translate('SEARCH')}
            />
          </Col>
        </Row>
        <Margin size={30} />
        <Row>
          <Col xs={24}>
            <BaseTable
              rowKey='serviceId'
              datasource={rows}
              columns={this.getColumns()}
              pagination={{
                render: true,
                page,
                pageSize,
                total,
                onChangePagination: this.onChangePagination
              }}
              loading={loading}
              onClickRow={this.openService}
            />
          </Col>
        </Row>
      </>
    )
  }
}

ServicesTab.propTypes = {
  router: PropTypes.object.isRequired
}

export default withLocalize(ServicesTab)
