import React, {useEffect, useState} from 'react'

import {Row, Col, Radio} from 'antd'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Field, change, getFormValues} from 'redux-form'

import AlertService from '../../../../shared/components/alert/AlertService'
import BaseButton from '../../../../shared/components/buttons/BaseButton'
import NumberIncrementInput from '../../../../shared/components/inputs/NumberIncrementInput'
import {
  TableRow,
  ListCol,
  TableTitle,
  TableInput,
  TextTooltip,
  TableText,
  FooterRow
} from '../../../../shared/components/table/CustomTableStyles'
import ActiveTranslation from '../../../../shared/logic/translations/ActiveTranslation'
import {TitleH3} from '../../../../shared/styles/BasicStyles'

const CreatePackages = ({fields, dispatch, disabled, formValues}) => {
  const [boxNumber, setBoxNumber] = useState(1)
  const [isKit, setKit] = useState(false)
  const products = fields.getAll() || []
  const selectedItems = products.reduce(
    (a, b) => a + parseInt(b.box || 0, 10),
    0
  )

  useEffect(() => {
    formValues.orderItem.forEach((item, index) => {
      let quantityOnBoxes = 0
      if (Array.isArray(formValues.orderBox) && formValues.orderBox.length > 0) {
        formValues.orderBox.forEach((box) => {
          box.items.forEach((boxItem) => {
            if (boxItem.orderItemId == item.orderItemId) {
              quantityOnBoxes += boxItem.itemQuantity
            }
          })
        })
      }

      if (item.quantityConfirmed > quantityOnBoxes) {
        dispatch(
          change(
            'manage-order-b2b2c-b2c',
            `${fields.name}[${index}].left`,
            item.quantityConfirmed - quantityOnBoxes
          )
        )
      }
    })
  }, [])

  const create = () => {
    if (!boxNumber || boxNumber === '') {
      return AlertService.error(
        'Cannot create box',
        'Select the number of boxes to add'
      )
    }

    const added = []
    const errors = []

    const prods = products.map((product) => {
      const prod = {...product}
      if (prod.box > 0) {
        const quantity = prod.box * boxNumber
        if (quantity > prod.left) {
          errors.push(
            `There are no quantity left for ${product?.productCombination?.reference}`
          )
        }
        added.push({orderItemId: prod.orderItemId, itemQuantity: parseInt(prod.box, 10)})
        prod.left -= quantity
      }
      prod.box = 0
      return prod
    })
    if (errors.length) {
      return AlertService.showValidations('Cannot create box', errors)
    }
    if (!added.length) {
      return AlertService.error(
        'Cannot create box',
        'Please select item quantities'
      )
    }

    dispatch(change('manage-order-b2b2c-b2c', 'orderItem', prods))
    let loop = parseInt(boxNumber, 10)
    const boxes = formValues?.orderBox || []
    while (loop--) {
      boxes.push({
        orderPackageId: 0,
        isKit,
        items: added
      })
    }
    dispatch(change('manage-order-b2b2c-b2c', 'orderBox', boxes))

    return setBoxNumber(1)
  }

  return (
    <Row gutter={[16, 40]}>
      <Col xs={24}>
        <TitleH3 $op>Create Kits and Boxes</TitleH3>
      </Col>
      <Col xs={24}>
        <TableRow $header>
          <ListCol xs={4}>
            <TableTitle first text='Reference' />
          </ListCol>
          <ListCol xs={4}>
            <TableTitle text='EAN Code' />
          </ListCol>
          <ListCol xs={4}>
            <TableTitle text='Description' />
          </ListCol>
          <ListCol xs={2}>
            <TableTitle text='Color' />
          </ListCol>
          <ListCol xs={1}>
            <TableTitle text='Size' />
          </ListCol>
          <ListCol xs={2}>
            <TableTitle text='Width' />
          </ListCol>
          <ListCol xs={1}>
            <TableTitle text='Total Quantity' abbr='Total' />
          </ListCol>
          <ListCol xs={1}>
            <TableTitle text='Quantity delivered' abbr='Sent' />
          </ListCol>
          <ListCol xs={2}>
            <TableTitle text='Quantity confirmed' abbr='Confirmed' />
          </ListCol>
          <ListCol xs={1}>
            <TableTitle text='Quantity left' abbr='Left' />
          </ListCol>
          <ListCol xs={2}>
            <TableTitle text='Box quantity' abbr='Box Qty' />
          </ListCol>
        </TableRow>

        {products.map((product, index) => (
          <TableRow
            key={index}
            $disable={!product.left || product?.left === 0}
          >
            <ListCol xs={4}>
              <TextTooltip
                first
                text={product?.productCombination?.reference || '-'}
              />
            </ListCol>
            <ListCol xs={4}>
              <TextTooltip
                text={product?.productCombination?.barcode || '-'}
              />
            </ListCol>
            <ListCol xs={4}>
              <TextTooltip
                text={
                  <ActiveTranslation
                    value={
                      product?.productCombination?.product
                        ?.productTranslation
                    }
                    tag='name'
                  />
                }
              />
            </ListCol>
            <ListCol xs={2}>
              <TextTooltip
                text={
                  <ActiveTranslation
                    value={
                      product?.productCombination?.color
                        ?.colorTranslation || '-'
                    }
                    tag='name'
                  />
                }
              />
            </ListCol>
            <ListCol xs={1}>
              <TextTooltip
                text={product?.productCombination?.size?.nameEu || '-'}
              />
            </ListCol>
            <ListCol xs={2}>
              <TextTooltip
                text={
                  product?.productCombination?.productWidth?.code || '-'
                }
              />
            </ListCol>
            <ListCol xs={1}>
              <TableText>{product?.quantity || '0'}</TableText>
            </ListCol>
            <ListCol xs={1}>
              <TableText>{product?.quantityDelivered || '0'}</TableText>
            </ListCol>
            <ListCol xs={2}>
              <TableText>{product?.quantityConfirmed || '0'}</TableText>
            </ListCol>
            <ListCol xs={1}>
              <TableText>{product?.left || '-'}</TableText>
            </ListCol>
            <ListCol xs={2}>
              <TableInput>
                <Field
                  component={NumberIncrementInput}
                  name={`${fields.name}[${index}].box`}
                  step='1'
                  disabled={
                    !product.left || product?.left === 0 || disabled
                  }
                />
              </TableInput>
            </ListCol>
          </TableRow>
        ))}
        <FooterRow gutter={[16]} justify='end'>
          <ListCol xs={2}>Items: {selectedItems}</ListCol>
          <ListCol xs={3}>
            <Radio.Group
              disabled={selectedItems === 0}
              onChange={(e) => setKit(e.target.value)}
              value={isKit}
            >
              <Radio value={false}>Box</Radio>
              {!formValues?.isB2B2C && <Radio value>Kit</Radio>}

            </Radio.Group>
          </ListCol>
          <ListCol xs={2}>
            <NumberIncrementInput
              disabled={selectedItems === 0 || disabled}
              width='100%'
              input={{
                value: boxNumber,
                onChange: setBoxNumber
              }}
              step='1'
            />
          </ListCol>
          <ListCol xs={2}>
            <BaseButton
              size='small'
              type='primary'
              disabled={selectedItems === 0 || disabled}
              onClick={() => create(true)}
            >
              Create
            </BaseButton>
          </ListCol>
        </FooterRow>
      </Col>
    </Row>
  )
}

CreatePackages.propTypes = {
  dispatch: PropTypes.func.isRequired,
  formValues: PropTypes.object
}

CreatePackages.defaultProps = {
  formValues: {}
}

export default connect((state) => ({
  formValues: getFormValues('manage-order-b2b2c-b2c')(state)
}))(CreatePackages)
