import EmptyImageIcon from '../../../assets/placeholder.jpg'

const GetLayoutImage = (images) => {
  if (images?.length > 0) {
    const found = images.find((elem) => elem.layout === true)
    if (found && found?.image?.url) {
      return found.image.url
    }
    if (found && !found?.image?.url) {
      return `${window.env.REACT_APP_API_FILES}/products/${found?.image?.filename}`
    }

    const cover = images.find((elem) => elem.cover === true)
    if (cover && cover?.image?.url) {
      return cover.image.url
    }
    if (cover && !cover?.image?.url) {
      return `${window.env.REACT_APP_API_FILES}/products/${cover?.image?.filename}`
    }

    return EmptyImageIcon
  }
  return EmptyImageIcon
}

export default GetLayoutImage
