import React from 'react'

import {PropTypes} from 'prop-types'

import Search from '../../../assets/icons/search.svg'
import {
  SearchInputContainer,
  SearchInputStyled,
  SearchImage
} from './InputStyles'

const SearchInput = ({input, placeholder, noBorderBottom, disabled}) => {
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !disabled && input.onSearch) {
      return input.onSearch()
    }
    return null
  }
  return (
    <SearchInputContainer
      $noBorderBottom={noBorderBottom}
      $disabled={disabled}
    >
      <SearchInputStyled
        disabled={disabled}
        type='text'
        placeholder={placeholder}
        value={input.value}
        onChange={(event) => input.onChange(event.target.value)}
        onKeyDown={handleKeyDown}
      />
      <SearchImage
        src={Search}
        onClick={input.onSearch}
        $disabled={disabled}
      />
    </SearchInputContainer>
  )
}

SearchInput.propTypes = {
  input: PropTypes.object,
  placeholder: PropTypes.string,
  noBorderBottom: PropTypes.bool,
  disabled: PropTypes.bool
}

SearchInput.defaultProps = {
  input: {value: undefined, onChange: () => null},
  placeholder: undefined,
  noBorderBottom: false,
  disabled: false
}

export default SearchInput
