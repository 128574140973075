import React from 'react'

import {PropTypes} from 'prop-types'

import {
  EANTextInputStyled
} from './InputStyles'

const EANTextInput = ({
  input,
  afterChange,
  disabled,
  inputRef,
  onBlur,
  onFocus
}) => {
  const onPressEnter = (event) => {
    input.onChange(event.target.value)
    if (typeof afterChange === 'function') {
      afterChange(event.target.value)
    }
  }

  return (
    <EANTextInputStyled
      value={input.value}
      onChange={(e) => input.onChange(e.target.value)}
      type='text'
      ref={inputRef}
      onBlur={onBlur}
      onFocus={onFocus}
      disabled={disabled}
      onPressEnter={onPressEnter}
    />
  )
}

EANTextInput.propTypes = {
  input: PropTypes.object,
  afterChange: PropTypes.func,
  disabled: PropTypes.bool
}

EANTextInput.defaultProps = {
  input: {value: undefined, onChange: () => null},
  afterChange: undefined,
  disabled: false
}

export default EANTextInput
