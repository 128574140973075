import React, { useEffect, useState } from 'react'

import { Row, Col } from 'antd'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import {
  initialize,
  Field,
  reduxForm,
  formValueSelector,
  change
} from 'redux-form'

import ConsolidationHeader from './ConsolidationHeader'
import DropdownIcon from '../../../assets/icons/dropdown_blue.svg'
import EmptyStateIcon from '../../../assets/icons/empty_state.svg'
import {
  GetConsolidation,
  SaveConsolidation,
  SendToConfirmation
} from '../../../infra/requests/InternalPORequests'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import NumberIncrementInput from '../../../shared/components/inputs/NumberIncrementInput'
import BaseLoading from '../../../shared/components/loading/BaseLoading'
import BaseTable from '../../../shared/components/table/BaseTable'
import RoundCurrency from '../../../shared/logic/numbers/RoundCurrency'
import ActiveTranslation from '../../../shared/logic/translations/ActiveTranslation'
import { ToggleImage } from '../../../shared/styles/BasicStyles'
import {
  POBaseContainer,
  POHeaderCollapsable,
  POLeftContainer,
  POTableContainer,
  ValidationSection,
  ValidationMessage,
  EmptyContainer,
  EmptyMessage,
  EmptyIcon,
  MessageTrigger,
  Message,
  POTableFooter,
  FooterLine,
  POTotalSection
} from '../components/POStyles'

const calculateConsolidationProfit = (supplierOrder) => {
  let suggested = 0
  let total = 0
  if (supplierOrder && supplierOrder.orderSupplier) {
    supplierOrder.orderSupplier.forEach((line) => {
      if (line.currency.currencyId !== 1) {
        suggested = parseFloat(suggested) + parseFloat(line.quantitySuggested) * parseFloat(line.priceLast) * parseFloat(line.inverseExchangeRate)
        total = parseFloat(total) + parseFloat(line.quantityOrdered) * parseFloat(line.priceNegotiated) * parseFloat(line.inverseExchangeRate)
      } else {
        suggested = parseFloat(suggested) + parseFloat(line.quantitySuggested) * parseFloat(line.priceLast)
        total = parseFloat(total) + parseFloat(line.quantityOrdered) * parseFloat(line.priceNegotiated)
      }
    })
  }
  const diff = suggested - total
  return {
    variation:
      total !== 0 ? ((diff / total) * 100).toFixed(2) : (0).toFixed(2),
    amount: RoundCurrency(diff)
  }
}

const Consolidation = ({ orders, dispatch, setSection, onChangeTab }) => {
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)

  let completed = true
  orders.forEach((elem) => {
    if (!elem.consolidationReviewed) completed = false
  })

  useEffect(() => {
    async function fetchOrders() {
      const { data } = await GetConsolidation()
      dispatch(
        initialize('manage_consolidation', { orders: data.items || [] })
      )
      setLoading(false)
    }
    fetchOrders()
  }, [])

  const openPO = (index) => {
    dispatch(
      change(
        'manage_consolidation',
        `orders[${index}].open`,
        !orders[index].open
      )
    )
  }

  const resetReview = (index) =>
    dispatch(
      change(
        'manage_consolidation',
        `orders[${index}].consolidationReviewed`,
        false
      )
    )

  const renderDescription = (data) => {
    if (data.serviceId) {
      const product = data?.product?.productTranslation
      const color = data?.color?.colorTranslation
      const size = data?.size?.nameEu
      const width = data?.productWidth?.productWidthTranslation

      return (
        <div>
          <ActiveTranslation
            value={data?.service?.serviceTranslation}
            tag='name'
          />{' '}
          (
          <span>
            <ActiveTranslation value={product} tag='name' />
          </span>
          ,{' '}
          <span>
            <ActiveTranslation value={color} tag='name' />
          </span>
          , <span>{size}</span>,{' '}
          <span>
            <ActiveTranslation value={width} tag='name' />
          </span>
          )
        </div>
      )
    }
    return (
      <ActiveTranslation
        value={data?.product?.productTranslation}
        tag='name'
      />
    )
  }

  const renderColumns = (OrderIndex, SO) => {
    const columns = [
      {
        type: 'text',
        title: <Translate id='PO_NR' />,
        dataIndex: 'orderPo',
        render: (value) => value?.orderPoid
      },
      {
        type: 'text',
        title: <Translate id='ORDER_H' />,
        dataIndex: 'orderPo',
        render: (value) => value?.orderB2b?.number || '-'
      },
      {
        type: 'text',
        title: <Translate id='REF_SKYPRO' />,
        dataIndex: 'referenceSkypro',
        render: (value) => value || '-'
      },
      {
        type: 'text',
        title: <Translate id='REF_SUPPLIER' />,
        dataIndex: 'referenceSupplier',
        render: (value) => value || '-'
      },
      {
        type: 'text',
        title: <Translate id='DESCRIPTION' />,
        render: renderDescription
      },
      {
        type: 'text',
        title: <Translate id='UM' />,
        render: (data) =>
          data.serviceId ? 'unit' : data.product?.measureUnit?.code
      },
      {
        type: 'text',
        title: <Translate id='STOCK' />,
        render: (data) => (data.serviceId ? '-' : data.product?.stock)
      },
      {
        type: 'text',
        title: <Translate id='QTY_ORDER' />,
        dataIndex: 'quantityOrdered',
        render: (text, row, index) => (
          <Field
            component={NumberIncrementInput}
            name={`orders[${OrderIndex}].orderSupplier[${index}].quantityOrdered`}
            type='number'
            afterChange={() => resetReview(OrderIndex)}
            width='100%'
          />
        )
      },
      {
        type: 'text',
        title: <Translate id='PRICE_NEGOTIATED_EUR' />,
        dataIndex: 'priceNegotiated',
        render: (text, row, index) => row.currency?.currencyId !== 1
          ? RoundCurrency(row.priceNegotiated * row.exchangeRateUsed)
          : (
            <Field
              component={NumberIncrementInput}
              name={`orders[${OrderIndex}].orderSupplier[${index}].priceNegotiated`}
              type='number'
              afterChange={() => resetReview(OrderIndex)}
              width='100%'
            />
          )
      },
      {
        type: 'text',
        title: <Translate id='LAST_VALUE_EUR' />,
        render: (data) => data.currency?.currencyId !== 1
          ? `${RoundCurrency(data.priceLast * data.exchangeRateUsed)}€`
          : `${RoundCurrency(data.priceLast)}€`
      },
      {
        type: 'text',
        title: <Translate id='TABLE_VALUE_EUR' />,
        render: (data) => data.currency?.currencyId !== 1
          ? `${RoundCurrency(data.tableValue * data.exchangeRateUsed)}€`
          : `${RoundCurrency(data.tableValue)}€`
      },
      {
        type: 'text',
        title: <Translate id='WEIGHTED_COST_EUR' />,
        render: (data) => data.currency?.currencyId !== 1
          ? `${RoundCurrency(data.averagePrice * data.exchangeRateUsed)}€`
          : `${RoundCurrency(data.averagePrice)}€`
      },
      {
        type: 'text',
        title: <Translate id='PRICE_EUROS' />,
        render: (data) =>
          `${RoundCurrency(data.quantityOrdered * data.priceNegotiated * data.exchangeRateUsed)}€`
      }
    ]

    const currency = SO.orderSupplier.find((s) => s.currency.currencyId !== 1)

    if (currency) {
      columns.push({
        type: 'text',
        title: <Translate id='PRICE_NEGOTIATED' />,
        dataIndex: 'priceNegotiated',
        render: (text, row, index) => row.currency?.currencyId !== 1
          ? (
            <Field
              component={NumberIncrementInput}
              name={`orders[${OrderIndex}].orderSupplier[${index}].priceNegotiated`}
              type='number'
              afterChange={() => resetReview(OrderIndex)}
              width='100%'
            />
          )
          : '-'
      })
      columns.push({
        type: 'text',
        title: <Translate id='LAST_VALUE' />,
        render: (data) => data.currency?.currencyId !== 1
          ? `${RoundCurrency(data.priceLast)} ${data.currency?.code}`
          : '-'
      })
      columns.push({
        type: 'text',
        title: <Translate id='TABLE_VALUE' />,
        render: (data) => data.currency?.currencyId !== 1
          ? `${RoundCurrency(data.tableValue)} ${data.currency?.code}`
          : '-'
      })
      columns.push({
        type: 'text',
        title: <Translate id='WEIGHTED_COST' />,
        render: (data) => data.currency?.currencyId !== 1
          ? `${RoundCurrency(data.averagePrice)} ${data.currency?.code}`
          : '-'
      })
      columns.push({
        type: 'text',
        title: <Translate id='PRICE' />,
        render: (data) => data.currency?.currencyId !== 1
          ? `${RoundCurrency(data.quantityOrdered * data.priceNegotiated)} ${data.currency?.code}`
          : '-'
      })
    }

    return columns
  }

  const saveSO = async (value, index) => {
    dispatch(
      change(
        'manage_consolidation',
        `orders[${index}].consolidationReviewed`,
        value
      )
    )
    const { variation, amount } = calculateConsolidationProfit(orders[index])

    const so = {
      OrderSupplierSetId: orders[index].orderSupplierSetId,
      ProfitVariation: variation,
      ProfitAmount: amount,
      ConsolidationReviewed: value,
      OrderSupplier: orders[index].orderSupplier.map((item) => ({
        OrderSupplierId: item.orderSupplierId,
        ProductId: item.product.productId,
        QuantityOrdered: item.quantityOrdered,
        PriceNegotiated: item.priceNegotiated
      }))
    }
    const { success } = await SaveConsolidation(
      orders[index].orderSupplierSetId,
      so
    )
    if (!success) {
      dispatch(
        change(
          'manage_consolidation',
          `orders[${index}].consolidationReviewed`,
          false
        )
      )
      return { hasErros: true }
    }

    return { hasErrors: false }
  }

  const evolveSO = async (so, index) => {
    setSaving(true)
    const { hasErrors } = await saveSO(true, index)

    if (!hasErrors) {
      const payload = [so.orderSupplierSetId]
      const { success } = await SendToConfirmation(payload)
      setSaving(false)
      if (success) setSection(2)
    } else {
      setSaving(false)
    }
  }

  const transferToConfirmation = async () => {
    if (completed) {
      setSaving(true)
      const payload = orders.map((order) => order.orderSupplierSetId)
      const { success } = await SendToConfirmation(payload)
      setSaving(false)
      if (success) setSection(2)
    }
  }

  const calculateTotal = (SO) => {
    let total = 0
    SO.orderSupplier.forEach((order) => {
      if (order.currency.currencyId !== 1) {
        total = parseFloat(total) + parseFloat(order.quantityOrdered * order.priceNegotiated * order.inverseExchangeRate)
      } else {
        total = parseFloat(total) + parseFloat(order.quantityOrdered * order.priceNegotiated)
      }
    })
    return RoundCurrency(total)
  }

  if (loading) return <BaseLoading margin={100} />

  if (orders.length === 0) {
    return (
      <Row>
        <Col xs={24}>
          <ValidationSection>
            <BaseButton type='primary' onClick={() => setSection(2)} auto>
              <Translate id='NEXT' />
            </BaseButton>
          </ValidationSection>
        </Col>
        <Col xs={24}>
          <EmptyContainer>
            <EmptyMessage>
              <Message>
                <Translate id='NEEDS_MAP_EMPTY_DESC1' />
              </Message>
              <MessageTrigger onClick={() => onChangeTab('open')}>
                <Translate id='OPEN_PO' />
              </MessageTrigger>
              <Message>
                <Translate id='NEEDS_MAP_EMPTY_DESC2' />
              </Message>
            </EmptyMessage>
            <EmptyIcon src={EmptyStateIcon} />
          </EmptyContainer>
        </Col>
      </Row>
    )
  }

  return (
    <>
      <Row style={{ marginBottom: 24 }}>
        <Col xs={24}>
          <ValidationSection>
            <BaseButton
              type='primary'
              onClick={transferToConfirmation}
              auto
              disabled={!completed}
              loading={saving}
            >
              <Translate id='CONTINUE' />
            </BaseButton>
            {!completed && (
              <ValidationMessage>
                <Translate id='ERROR_CONTINUE' />
              </ValidationMessage>
            )}
          </ValidationSection>
        </Col>
      </Row>
      {orders.map((SO, index) => (
        <POBaseContainer key={index} $jointly>
          <POHeaderCollapsable onClick={() => openPO(index)}>
            <POLeftContainer $left>
              <ToggleImage $marginTop src={DropdownIcon} $open={SO.open} />
              <ConsolidationHeader
                SO={SO}
                index={index}
                onSave={(value) => saveSO(value, index)}
                profit={calculateConsolidationProfit(SO)}
                evolveSO={(value) => evolveSO(value, index)}
                saving={saving}
              />
            </POLeftContainer>
          </POHeaderCollapsable>
          <POTableContainer open={SO.open}>
            {SO.open && (
              <BaseTable
                rowKey='orderSupplierId'
                columns={renderColumns(index, SO)}
                datasource={SO.orderSupplier}
                pagination={{ render: false }}
                scroll={{ x: true }}
              />
            )}
            <POTableFooter>
              <POTotalSection $last>
                <FooterLine bold>
                  <Translate id='TOTAL_VALUE' />:{' '}
                  <span>{calculateTotal(SO)}€</span>
                </FooterLine>
              </POTotalSection>
            </POTableFooter>
          </POTableContainer>
        </POBaseContainer>
      ))}
    </>
  )
}

Consolidation.propTypes = {
  dispatch: PropTypes.func.isRequired,
  orders: PropTypes.array.isRequired,
  setSection: PropTypes.func.isRequired,
  onChangeTab: PropTypes.func.isRequired
}

Consolidation.defaultProps = {}

const myComponent = reduxForm({
  form: 'manage_consolidation',
  keepDirtyOnReinitialize: true,
  enableReinitialize: true
})(Consolidation)

const selector = formValueSelector('manage_consolidation')

const mapStateToProps = (state) => ({
  orders: selector(state, 'orders') || [],
  initialValues: { orders: [] }
})

export default connect(mapStateToProps)(myComponent)
