import React, {useEffect, useState} from 'react'

import {Row, Col} from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import {DndProvider} from 'react-dnd'
import {HTML5Backend} from 'react-dnd-html5-backend'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {useParams} from 'react-router-dom'
import {reduxForm, Field, FieldArray, getFormValues, change} from 'redux-form'

import {
  CreateProcess,
  GetMessageDetail,
  ReplyMessage
} from '../../../../infra/requests/MessagingRequests'
import FormValidator from '../../../../infra/services/validations/FormValidator'
import AlertService from '../../../../shared/components/alert/AlertService'
import BackButton from '../../../../shared/components/buttons/BackButton'
import BaseButton from '../../../../shared/components/buttons/BaseButton'
import EmailDroppableInput from '../../../../shared/components/inputs/Emails/EmailDroppableInput'
import InlineTextInput from '../../../../shared/components/inputs/InlineTextInput'
import RichTextInput from '../../../../shared/components/inputs/RichTextInput'
import BaseLoading from '../../../../shared/components/loading/BaseLoading'
import {TableHeaderBackground} from '../../../../shared/styles/_colors'
import {
  Margin,
  PageForm,
  PageTitle
} from '../../../../shared/styles/BasicStyles'
import AnswerTemplates from '../../process/components/AnswerTemplates'
import Attachments from '../../process/components/Attachments'
import DraggableContactList from '../../process/components/DraggableContactList'
import {
  BottomButtonsContainer,
  MessageContactsSection,
  MessageSubjectSection,
  MessageContainer
} from '../../process/ProcessStyles'

const DefineSubject = (isReply, isForward, data) => {
  if (isForward) return `FW: ${data.subject}`
  if (isReply) {
    if (data.subject.indexOf('Re: ') === 0) return data.subject
    return `Re: ${data.subject}`
  }
  return data.subject
}

const DefineHeader = (
  isReply,
  data,
  translate
) => `<p></p><p></p><p></p><p></p>
<p style="color: rgba(35,31,32, 0.5)">----------- ${
  isReply ? translate('LAST_MESSAGE') : translate('FORWARDED_MESSAGE')
}-----------</p><p style="color: rgba(35,31,32, 0.5)">${translate(
  'FROM'
)}: ${data.from}</p><p style="color: rgba(35,31,32, 0.5)">${translate(
  'TO'
)}: ${data.to_arr.map(
  (to, index) => `${to}${index < data.to_arr.length - 1 ? ', ' : ''}`
)}${
  data.cc_arr.length > 0
    ? `</p><p style="color: rgba(35,31,32, 0.5)">${translate(
      'CC'
    )}: ${data.cc_arr.map(
      (cc, index) => `${cc}${index < data.cc_arr.length - 1 ? ', ' : ''}`
    )}`
    : ''
}</p><p style="color: rgba(35,31,32, 0.5)">${translate('DATE')}: ${moment(
  data.date
).format(
  'DD/MM/YYYY HH:mm:ss'
)}</p><p style="color: rgba(35,31,32, 0.5)">${translate('SUBJECT')}: ${
  data.subject
}
<br/></p>${data.bodyText}<p></p>`

const formRules = FormValidator.make({
  to: 'required|array',
  subject: 'required'
})

const ManageMessage = ({router, handleSubmit, initialize, translate, dispatch}) => {
  const [ready, setReady] = useState(false)
  const [restart, setRestart] = useState(false)
  const [template, setTemplate] = useState()
  const [loading, setLoading] = useState(false)

  const getCurrentPathType = () => {
    const path = router.location.pathname
    const pathLocations = path.split('/')
    const currentPath = pathLocations.length > 4 && pathLocations[4]

    if (currentPath) return currentPath
    return 'new'
  }

  const type = getCurrentPathType()
  const isReply = type === 'reply'
  const isForward = type === 'forward'

  const {id} = useParams()

  useEffect(() => {
    async function init() {
      if (id && id !== 'new') {
        const {data, success} = await GetMessageDetail(id)
        if (success) {
          const initialData = {}
          if (isReply) {
            initialData.to = data.to_arr
            initialData.cc = data.cc_arr
          }
          initialData.subject = DefineSubject(
            isReply,
            isForward,
            data,
            translate
          )
          initialData.message = DefineHeader(isReply, data, translate)
          setTemplate(initialData.message)
          initialize(initialData)
        }
      }
      setReady(true)
    }
    init()
  }, [])

  const onSubmit = async (values) => {
    const testemail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    let numError = 0
    values.to.forEach((element) => {
      if (!testemail.test(element)) {
        numError = 1
      }
    })

    if (values.cc && values.cc.length) {
      values.cc.forEach((element) => {
        if (!testemail.test(element)) {
          numError = 1
        }
      })
    }

    if (numError == 0) {
      try {
        setLoading(true)
        const payload = new FormData()
        if (values.attachments && values.attachments.length) {
          values.attachments.forEach((element) => {
            payload.append('Attachments', element)
          })
        }
        values.to.forEach((element) => {
          payload.append('To', element)
        })

        if (values.cc && values.cc.length) {
          values.cc.forEach((element) => {
            payload.append('CC', element)
          })
        } else {
          payload.append('CC', '')
        }
        payload.append('BodyHtml', values?.message ? values.message : '')
        payload.append('BodyText', values?.message ? values.message : '')
        payload.append(
          'Subject',
          values?.data?.subject ? values.data.subject : values.subject
        )

        if (id && id !== 'new') {
          const {success} = await ReplyMessage(id, payload)
          if (success) {
            setLoading(false)
            AlertService.success(
              translate('SUCCESS'),
              translate('MESSAGE_SENT')
            )
            router.history.push('/messaging')
          }
        } else {
          const {success} = await CreateProcess(payload)
          if (success) {
            setLoading(false)
            AlertService.success(
              translate('SUCCESS'),
              translate('PROCESS_CREATED')
            )
            router.history.push('/messaging')
          }
        }
      } catch (error) {
        console.log(error)
        AlertService.error(
          translate('ERROR'),
          translate('ERROR_SENDING_MESSAGE')
        )

        setLoading(false)
      }
    } else {
      AlertService.error(
        translate('ERROR'),
        translate('INVALID_EMAIL_ADDRESS')
      )
    }
  }

  const onUseTemplate = (text) => {
    setRestart(true)
    const message = `<p>${text}</p> ${template}`
    dispatch(change('manage_message', 'message', message))
    setTimeout(() => setRestart(false), 100)
  }

  if (!ready) return <BaseLoading margin='100' />

  return (
    <DndProvider backend={HTML5Backend}>
      <PageForm onSubmit={handleSubmit(onSubmit)}>
        <BackButton
          label={<Translate id='BACK' />}
          onClick={() => router.history.push('/messaging')}
        />
        <PageTitle $centered $top={20} $bottom={10}>
          <Translate
            id={
              isReply ? 'REPLY' : isForward ? 'FORWARD' : 'CREATE_PROCESS'
            }
          />
        </PageTitle>

        <Row>
          <Col xs={24} lg={18}>
            <MessageContactsSection $bigger>
              <Field
                name='to'
                component={EmailDroppableInput}
                label={<Translate id='TO' />}
              />
              <Margin size={12} />
              <Field
                name='cc'
                component={EmailDroppableInput}
                label={<Translate id='CC' />}
              />
            </MessageContactsSection>
            <MessageSubjectSection $bigger>
              <Margin size={2} />
              <Field
                name='subject'
                component={InlineTextInput}
                label={<Translate id='SUBJECT' />}
                backgroundColor={TableHeaderBackground}
              />
              <Margin size={18} />
            </MessageSubjectSection>
            <MessageContainer $bigger>
              {restart ?
                <div style={{height: '600px'}}><BaseLoading margin='300' /></div>
                : <Field name='message' component={RichTextInput} />}
              <BottomButtonsContainer>
                <AnswerTemplates onUseTemplate={onUseTemplate} />
                <FieldArray name='attachments' component={Attachments} />
                <BaseButton
                  htmlType='submit'
                  type='primary'
                  style={{marginRight: '10px'}}
                  auto
                  loading={loading}
                >
                  <Translate
                    id={isReply ? 'REPLY' : isForward ? 'FORWARD' : 'SEND'}
                  />
                </BaseButton>
              </BottomButtonsContainer>
            </MessageContainer>
          </Col>
          <Col xs={6}>
            <DraggableContactList />
          </Col>
        </Row>
      </PageForm>
    </DndProvider>
  )
}

ManageMessage.propTypes = {
  router: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired
}

const ManageMessagePage = reduxForm({
  form: 'manage_message',
  validate: formRules
})(ManageMessage)

export default withLocalize(connect()(ManageMessagePage))
