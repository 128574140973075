import React from 'react'

import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'

import RoundCurrency from '../../../logic/numbers/RoundCurrency'
import {
  ProductsFooter,
  ProductsFooterContainer,
  ProductsFooterTitle,
  ProductsFooterValue
} from './ProductsStyles'

const ProductTotalFooter = ({products, currency, quantityLabel, priceLabel}) => {
  const calculateTotal = () => {
    let total = 0
    products.forEach((product) => {
      total =
        parseFloat(total) +
        (product[quantityLabel] * product[priceLabel] || 0)
    })
    return RoundCurrency(total)
  }

  return (
    <ProductsFooter>
      <ProductsFooterContainer>
        <ProductsFooterTitle $total>
          <Translate id='TOTAL' />:
        </ProductsFooterTitle>
      </ProductsFooterContainer>
      <ProductsFooterContainer>
        <ProductsFooterValue $total>
          {currency && currency.currencyId != 1
            ? RoundCurrency(calculateTotal() * currency.exchangeRate)
            : calculateTotal()}€
        </ProductsFooterValue>
        {currency && currency.currencyId != 1 && (
          <ProductsFooterValue $total>
            {RoundCurrency(calculateTotal())} {currency.code}
          </ProductsFooterValue>
        )}
      </ProductsFooterContainer>
    </ProductsFooter>
  )
}

ProductTotalFooter.propTypes = {
  products: PropTypes.array.isRequired,
  quantityLabel: PropTypes.string,
  priceLabel: PropTypes.string
}

ProductTotalFooter.defaultProps = {
  quantityLabel: 'quantity',
  priceLabel: 'totalValue'
}

export default ProductTotalFooter
