import React, {useState} from 'react'

import {DeleteOutlined, CameraOutlined} from '@ant-design/icons'
import {Popconfirm} from 'antd'
import PropTypes from 'prop-types'
import {useDrag} from 'react-dnd'

import GetImage from '../../../../../shared/logic/arrays/ImageFunction'
import {
  ErrorColor,
  PrimaryButtonColor,
  PrimaryColor,
  TableTextColor
} from '../../../../../shared/styles/_colors'
import {BodyH5} from '../../../../../shared/styles/BasicStyles'
import {
  ProductLocalImageContainer,
  ProductLocalImage,
  HoverImageButton,
  ImageOverlay
} from '../../../ProductsStyles'
import {Translate} from 'react-localize-redux'

const ProductImageCard = ({
  deleteImage,
  changeCover,
  currentImage,
  currentColor
}) => {
  const [loading, setLoading] = useState(false)

  const [collected, drag] = useDrag(() => ({
    type: 'image',
    item: {imageId: currentImage.imageId, colorId: currentColor?.colorId}
  }))

  const handleDelete = async () => {
    setLoading(true)
    await deleteImage(currentImage.imageId)
  }

  const handleCover = async () => {
    setLoading(true)
    await changeCover(currentImage.imageId)
    setLoading(false)
  }

  return (
    <>
      <BodyH5 style={{textAlign: 'center'}} $color={PrimaryButtonColor}>
        {currentImage?.image?.filename}
      </BodyH5>
      <ProductLocalImageContainer ref={drag}>
        <ProductLocalImage src={GetImage(currentImage)} />

        <HoverImageButton $top='5px' $right='5px' $color={ErrorColor}>
          <Popconfirm
            placement='topRight'
            title={<Translate id='REMOVE_IMAGE_MESSAGE' />}
            onConfirm={handleDelete}
            okButtonProps={{loading}}
          >
            <DeleteOutlined />
          </Popconfirm>
        </HoverImageButton>

        <HoverImageButton
          $top='5px'
          $left='5px'
          $color={currentImage.cover ? PrimaryColor : TableTextColor}
        >
          <Popconfirm
            placement='topRight'
            title={<Translate id='COVER_IMAGE_MESSAGE' />}
            onConfirm={handleCover}
            okButtonProps={{loading}}
          >
            <CameraOutlined />
          </Popconfirm>
        </HoverImageButton>
        <ImageOverlay $show={loading} />
      </ProductLocalImageContainer>
    </>
  )
}

ProductImageCard.propTypes = {
  currentImage: PropTypes.object,
  currentColor: PropTypes.object,
  deleteImage: PropTypes.func.isRequired,
  changeCover: PropTypes.func.isRequired
}

ProductImageCard.defaultProps = {
  currentImage: {},
  currentColor: undefined
}

export default ProductImageCard
