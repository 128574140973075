import AppointmentPage from '../../pages/fitting/appointment/AppointmentPage'
import ManageFittingCentersPage from '../../pages/fitting/fittingCenters/ManageFittingCentersPage'
import FittingPage from '../../pages/fitting/FittingPage'

export default [
  {
    path: '/fitting',
    component: FittingPage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  },
  {
    path: '/fitting/centers/:id',
    component: ManageFittingCentersPage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  },
  {
    path: '/fitting/centers/new',
    component: ManageFittingCentersPage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  },
  {
    path: '/fitting/appointment/:id',
    component: AppointmentPage,
    isAuthenticated: true,
    shouldRenderFooter: true,
    shouldRenderHeader: true,
    role: 1
  }
]
