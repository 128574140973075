import styled from 'styled-components'

import {
  PrimaryColor,
  BorderColor,
  ErrorColor,
  TableTextColor
} from '../../../styles/_colors'

export const EmailListInput = styled.div`
  border-radius: 4px;
  width: 100%;
  border: 1px solid ${({$drop, hasError}) => ($drop ? PrimaryColor : hasError ? ErrorColor : BorderColor)};
  min-height: 36px;
  outline: none;
  box-shadow: none;
  padding: 5px 11px ${({$empty}) => ($empty ? 5 : 0)}px 11px;
  transition: all 1s ease;

  &:hover {
    border: 1px solid ${PrimaryColor};
  }
`

export const EmailTag = styled.div`
  display: inline-block;
  margin-right: 5px;
  padding: 2px 8px;
  color: ${({$valid}) => ($valid ? TableTextColor : ErrorColor)};
  border: 1px solid ${({$valid}) => ($valid ? BorderColor : ErrorColor)};
  border-radius: 20px;
  margin-bottom: 5px;
`

export const HiddenEmailInput = styled.input`
  display: inline-block;
  border: none;
  background: transparent;
  outline: none;
  height: 28px;
  min-width: 120px;
  max-width: 100%;

  &:hover,
  &:focus,
  &:active,
  &:visited {
    border: none;
    outline: none;
  }
`
