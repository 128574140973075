import React, {useEffect, useState} from 'react'

import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {reduxForm, initialize} from 'redux-form'

import BackButton from '../../../../../shared/components/buttons/BackButton'
import BaseButton from '../../../../../shared/components/buttons/BaseButton'
import FileInput from '../../../../../shared/components/inputs/FileInput'
import {
  Margin,
  PageForm,
  PageTitle,
  TitleH3
} from '../../../../../shared/styles/BasicStyles'
import WorkgroupDetailsExample from '../../example/WorkgroupDetailExample'
import {Translate} from 'react-localize-redux'

const ManageWorkgroup = ({router, dispatch, handleSubmit}) => {
  const [hasFetchedWorkgroup, setHasFetchedWorkgroup] = useState(false)
  const [workgroup, setWorkgroup] = useState(false)
  const [picture, setPicture] = useState(undefined)
  const [video, setVideo] = useState(undefined)
  const [fileError, setFileError] = useState(false)
  const [failed, setFailed] = useState(false)

  useEffect(() => {
    async function fetchWorkgroup() {
      const result = WorkgroupDetailsExample()
      dispatch(initialize('workgroup_form', result))

      setWorkgroup(result)
      setHasFetchedWorkgroup(true)
    }
    if (!hasFetchedWorkgroup) {
      fetchWorkgroup()
    }
  }, [hasFetchedWorkgroup, dispatch, workgroup])

  const onSubmit = async (values) => {
    console.log(values)
  }

  return (
    <>
      <PageForm onSubmit={handleSubmit(onSubmit)}>
        <Row align='bottom' gutter={[0, 50]}>
          <Col xs={24}>
            <BackButton
              label={<Translate id='BACK' />}
              onClick={() => router.history.push('/marketing#image')}
            />
          </Col>
          <Col xs={12}>
            <PageTitle>{workgroup.name}</PageTitle>
          </Col>

          <Col xs={12} align='end'>
            <BaseButton
              onClick={() => console.warn(<Translate id='SAVE' />)}
              type='primary'
              auto
            >
              <Translate id='SAVE' />
            </BaseButton>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <TitleH3 $op>
              <Translate id='MEDIA' />
            </TitleH3>
          </Col>
        </Row>
        <Margin size={31} />
        <Row gutter={[40, 20]}>
          <Col xs={12}>
            <FileInput
              label={<Translate id='POSITION_PICTURE' />}
              input={{
                value: picture,
                onChange: (value) => {
                  setPicture(value)
                  setFileError(false)
                }
              }}
              meta={{
                invalid: fileError,
                submitFailed: failed,
                error: <Translate id='MISSING_FILE' />
              }}
            />
          </Col>
          <Col xs={12}>
            <FileInput
              label={<Translate id='VIDEO_FILE' />}
              input={{
                value: video,
                onChange: (value) => {
                  setVideo(value)
                  setFileError(false)
                }
              }}
              meta={{
                invalid: fileError,
                submitFailed: failed,
                error: <Translate id='MISSING_FILE' />
              }}
            />
          </Col>
        </Row>
      </PageForm>
    </>
  )
}

ManageWorkgroup.propTypes = {
  router: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  handleSubmit: PropTypes.func
}

ManageWorkgroup.defaultProps = {
  dispatch: undefined,
  handleSubmit: undefined
}

const ManageWorkgroupPage = reduxForm({
  form: 'workgroup_form'
  // validate: validations
})(ManageWorkgroup)

export default connect((state) => ({
  clients: state.info.clients
}))(ManageWorkgroupPage)
