import React, {useState} from 'react'

import {Col, Row} from 'antd'
import PropTypes from 'prop-types'
import {DndProvider} from 'react-dnd'
import {HTML5Backend} from 'react-dnd-html5-backend'
import {Translate} from 'react-localize-redux'
import {useParams} from 'react-router'

import {
  ChangeCoverImage,
  DeleteProductImage,
  SaveProductImage
} from '../../../../infra/requests/ProductsRequests'
import ProductUploadImageInput from '../../../../shared/components/inputs/ProductUploadImageInput'
import {
  BodyH3,
  BodyH5,
  Margin,
  TitleH3
} from '../../../../shared/styles/BasicStyles'
import ProductImageCard from './ImageCard/ProductImageCard'

const RawMaterialImages = ({fields, changeImages}) => {
  const [loading, setLoading] = useState(false)
  const {id} = useParams()
  const list = fields.getAll() || []
  const images = list.filter((img) => !img.layout)

  const addNewImage = async (file) => {
    setLoading(true)
    const payload = new FormData()
    payload.append('File', file)
    const {data, success} = await SaveProductImage(id, payload)
    if (success) {
      changeImages(data?.productPropertiesSection?.productImage)
    }
    setLoading(false)
  }

  const changeCover = async (image) => {
    const {data, success} = await ChangeCoverImage(id, image)
    if (success) {
      changeImages(data?.productPropertiesSection?.productImage)
    }
    return true
  }

  const deleteImage = async (image) => {
    const {data, success} = await DeleteProductImage(id, image)
    if (success) {
      changeImages(data?.productPropertiesSection?.productImage)
    }
    return true
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Row>
        <Col xs={24}>
          <TitleH3 $opacity='0.5'>
            <Translate id='PRODUCT_IMAGES' />
          </TitleH3>
        </Col>
        <Col xs={24}>
          <BodyH3 $opacity='0.5'>
            <Translate id='PROD_IMG_DESC1' />
          </BodyH3>
        </Col>
        <Col xs={24}>
          <BodyH5 $opacity='0.5'>
            <Translate id='PROD_IMG_DESC2' />
          </BodyH5>
        </Col>
      </Row>
      <Margin size={20} />
      <Row gutter={[20, 20]}>
        {images.length < 8 && (
          <Col xs={8} lg={4}>
            <ProductUploadImageInput
              loading={loading}
              input={{value: undefined, onChange: addNewImage}}
            />
          </Col>
        )}
        {images
          .filter((img) => img.productImageColor.length === 0)
          .map((img) => (
            <Col xs={8} lg={4} key={img.imageId}>
              <ProductImageCard
                deleteImage={deleteImage}
                changeCover={changeCover}
                currentImage={img}
                dropCard={() => null}
              />
            </Col>
          ))}
      </Row>
      <Margin size={20} />
    </DndProvider>
  )
}

RawMaterialImages.propTypes = {
  changeImages: PropTypes.func.isRequired
}

RawMaterialImages.defaultProps = {}

export default RawMaterialImages
