import React, {useState} from 'react'

import {Row, Col} from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {getFormValues, Field, FieldArray, change} from 'redux-form'

import AddressList from '../../../shared/components/address/AddressList'
import DateInput from '../../../shared/components/inputs/DateInput'
import DateRangeInput from '../../../shared/components/inputs/DateRangeInput'
import FloatInput from '../../../shared/components/inputs/FloatInput'
import SelectInput from '../../../shared/components/inputs/SelectInput'
import SwitchInput from '../../../shared/components/inputs/SwitchInput'
import TextInput from '../../../shared/components/inputs/TextInput'
import {Margin} from '../../../shared/styles/BasicStyles'
import {SubTitle} from '../ClientStyles'

const Gender = [
  {id: 'false', name: 'Male'},
  {id: 'true', name: 'Female'}
]

const Effective = [
  {id: 'false', name: 'No'},
  {id: 'true', name: 'Yes'}
]

const Information = ({
  id,
  dispatch,
  countries,
  contractTypes,
  employeeCategory,
  employeeStatus,
  employeeCostCenter,
  currentFormValues,
  translate,
  workgroups,
  stopovers
}) => {
  const [showPassword, setShowPassword] = useState(!id)

  const disabledFields = () => currentFormValues?.employeeInformationSection?.effective === undefined ||
      currentFormValues?.employeeInformationSection?.effective === '' ||
      currentFormValues?.employeeInformationSection?.effective === 'true' ||
      currentFormValues?.employeeInformationSection?.effective === true

  const handleResetPassword = (value) => {
    setShowPassword(value)
    if (!value) {
      dispatch(change('manage_employee', 'employeeInformationSection.password', ''))
      dispatch(change('manage_employee', 'employeeInformationSection.confirm_password', ''))
    }
  }

  const selectPrimaryAddress = (addressIndex) => {
    const adresses = currentFormValues?.employeeInformationSection.contactEmployee[0].contactDetail || []
    const list = adresses.map((addr, index) => ({
      ...addr,
      preferred: addressIndex === index
    }))
    dispatch(change('manage_employee', 'employeeInformationSection.contactEmployee[0].contactDetail', list))
  }

  return (
    <>
      <Row gutter={[24, 40]}>
        <Col xs={7}>
          <Field
            component={TextInput}
            name='employeeInformationSection.firstName'
            label={<Translate id='NAME' />}
            placeholder={translate('NAME')}
          />
        </Col>
        <Col xs={7}>
          <Field
            component={TextInput}
            name='employeeInformationSection.lastName'
            label={<Translate id='SURNAME' />}
            placeholder={translate('SURNAME')}
          />
        </Col>
        <Col xs={7}>
          <Field
            component={TextInput}
            name='employeeInformationSection.email'
            label={<Translate id='EMAIL' />}
            placeholder={translate('EMAIL')}
            disabled={id && id !== 'new'}
          />
        </Col>
        <Col xs={3}>
          <Field
            component={SwitchInput}
            name='employeeInformationSection.active'
            checkedText={<Translate id='TRUE' />}
            uncheckedText={<Translate id='FALSE' />}
            label={<Translate id='ACTIVE' />}
          />
        </Col>
        <Col xs={5}>
          <Field
            component={SelectInput}
            name='employeeInformationSection.birthCountryId'
            label={<Translate id='COUNTRY' />}
            data={countries}
            dataLabel='name'
            dataKey='countryID'
            placeholder={<Translate id='SELECT' />}
          />
        </Col>
        <Col xs={5}>
          <Field
            component={DateInput}
            name='employeeInformationSection.birthDate'
            disabledDate={(value) => value.isAfter(moment().subtract(18, 'years'))}
            label={<Translate id='BIRTHDAY' />}
            showToday={false}
          />
        </Col>
        <Col xs={4}>
          <Field
            component={SelectInput}
            name='employeeInformationSection.gender'
            label={<Translate id='GENDER' />}
            data={Gender}
            allowClear={false}
            placeholder={<Translate id='SELECT' />}
          />
        </Col>
        <Col xs={5}>
          <Field
            component={TextInput}
            name='employeeInformationSection.mobile'
            label={<Translate id='MOBILE_NUMBER' />}
            placeholder={translate('MOBILE_NUMBER')}
          />
        </Col>
        <Col xs={5}>
          <Field
            component={TextInput}
            name='employeeInformationSection.phone'
            label={<Translate id='TELEPHONE' />}
            placeholder={translate('TELEPHONE')}
          />
        </Col>
        <Col xs={12}>
          <Field
            component={SelectInput}
            name='employeeInformationSection.stopoverId'
            label={<Translate id='STOPOVER' />}
            data={stopovers}
            dataLabel='fullAddressString'
            dataKey='contactDetailId'
            placeholder={<Translate id='STOPOVER' />}
          />
        </Col>
      </Row>
      <Margin size={40} />
      <Row gutter={[24, 40]}>
        {id && (
          <Col xs={4}>
            <SwitchInput
              input={{value: showPassword, onChange: handleResetPassword}}
              checkedText={<Translate id='YES' />}
              uncheckedText={<Translate id='NO' />}
              label={<Translate id='NEW_PASSWORD' />}
            />
          </Col>
        )}
        {showPassword && (
          <Col xs={5}>
            <Field
              component={TextInput}
              name='employeeInformationSection.password'
              label={<Translate id='PASSWORD' />}
              placeholder={translate('PASSWORD')}
              type='password'
            />
          </Col>
        )}
        {showPassword && (
          <Col xs={5}>
            <Field
              component={TextInput}
              name='employeeInformationSection.confirm_password'
              label={<Translate id='CONFIRM_PASSWORD' />}
              placeholder={translate('CONFIRM_PASSWORD')}
              type='password'
            />
          </Col>
        )}
        <Col xs={4}>
          <Field
            component={SwitchInput}
            name='employeeInformationSection.forcereset'
            checkedText={<Translate id='YES' />}
            uncheckedText={<Translate id='NO' />}
            label={<Translate id='FORCE_RESET' />}
          />
        </Col>
      </Row>
      <Margin size={40} />
      <Row gutter={[24, 40]}>
        <Col xs={4}>
          <Field
            component={SwitchInput}
            name='employeeInformationSection.acceptedRgpd'
            checkedText={<Translate id='YES' />}
            uncheckedText={<Translate id='NO' />}
            label={<Translate id='ACCEPTED_RGPD' />}
          />
        </Col>
        <Col xs={5}>
          <Field
            component={DateInput}
            disabled
            name='employeeInformationSection.dateRgpdaccept'
            label={<Translate id='DATE_ACCEPTED_RGPD' />}
          />
        </Col>
      </Row>
      <Margin size={40} />
      <Row gutter={[24, 40]}>
        <Col xs={24}>
          <FieldArray
            name='employeeInformationSection.contactEmployee[0].contactDetail'
            component={AddressList}
            countries={countries}
            selectPrimary={selectPrimaryAddress}
          />
        </Col>
      </Row>
      <Margin size={50} />
      <Row gutter={[24, 40]}>
        <Col xs={24}>
          <SubTitle>
            <Translate id='EMPLOYEE_INFORMATION' />
          </SubTitle>
        </Col>
        <Col xs={6}>
          <Field
            component={TextInput}
            name='employeeInformationSection.number'
            label={<Translate id='EMPLOYEE_NUMBER' />}
            placeholder={translate('EMPLOYEE_NUMBER')}
          />
        </Col>
        <Col xs={6}>
          <Field
            component={TextInput}
            name='employeeInformationSection.vatnumber'
            label={<Translate id='VAT' />}
            placeholder={translate('VAT')}
          />
        </Col>
        <Col xs={6}>
          <Field
            component={DateInput}
            name='employeeInformationSection.admissionDate'
            label={<Translate id='ADMISSION_DATE' />}
          />
        </Col>
        <Col xs={6}>
          <Field
            component={SelectInput}
            name='employeeInformationSection.effective'
            allowClear={false}
            label={<Translate id='EFFECTIVE' />}
            data={Effective}
            placeholder={<Translate id='SELECT' />}
          />
        </Col>
        <Col xs={6}>
          <Field
            component={DateInput}
            name='employeeInformationSection.contractEnd'
            label={<Translate id='CONTRACT_END' />}
            disabled={disabledFields()}
          />
        </Col>
        <Col xs={6}>
          <Field
            component={SelectInput}
            name='employeeInformationSection.contractTypeId'
            label={<Translate id='CONTRACT_DURATION' />}
            data={contractTypes}
            disabled={disabledFields()}
            dataLabel='name'
            dataKey='contractTypeId'
            placeholder={<Translate id='SELECT' />}
          />
        </Col>

        <Col xs={6}>
          <Field
            component={SelectInput}
            name='employeeInformationSection.positionId'
            label={<Translate id='WORKGROUP' />}
            data={workgroups}
            dataKey='positionId'
            dataLabel='name'
            placeholder={<Translate id='SELECT' />}
          />
        </Col>
        <Col xs={6}>
          <Field
            component={SelectInput}
            name='employeeInformationSection.employeeCategoryId'
            label={<Translate id='CATEGORY' />}
            data={employeeCategory}
            dataLabel='name'
            dataKey='employeeCategoryId'
            placeholder={<Translate id='SELECT' />}
          />
        </Col>
        <Col xs={4}>
          <Field
            component={SelectInput}
            name='employeeInformationSection.employeeStatusId'
            label={<Translate id='STATE' />}
            data={employeeStatus}
            dataKey='employeeStatusId'
            dataLabel='employeeStatusTranslation'
            placeholder={<Translate id='SELECT' />}
          />
        </Col>
        <Col xs={8}>
          <Field
            component={TextInput}
            name='employeeInformationSection.statusComment'
            label={<Translate id='STATE_REASON' />}
            placeholder={translate('STATE_REASON')}
          />
        </Col>
        <Col xs={6}>
          <Field
            component={DateRangeInput}
            name='intervalState'
            label={<Translate id='INTERVAL_STATE' />}
          />
        </Col>
        <Col xs={6}>
          <Field
            component={SelectInput}
            name='employeeInformationSection.costCenterId'
            label={<Translate id='COST_CENTER' />}
            data={employeeCostCenter}
            dataKey='costCenterId'
            dataLabel='name'
            placeholder={<Translate id='SELECT' />}
          />
        </Col>
        <Col xs={4}>
          <Field
            component={FloatInput}
            name='employeeInformationSection.flightHours'
            label={<Translate id='FLIGHT_HOURS' />}
            placeholder={translate('FLIGHT_HOURS')}
          />
        </Col>
        <Col xs={4}>
          <Field
            component={FloatInput}
            name='employeeInformationSection.flightMiles'
            label={<Translate id='MILES_FLOWN' />}
            placeholder={translate('MILES_FLOWN')}
          />
        </Col>
        <Col xs={6}>
          <Field
            component={FloatInput}
            name='employeeInformationSection.flightAssistances'
            label={<Translate id='FLIGHT_ASSISTANCE' />}
            placeholder={translate('FLIGHT_ASSISTANCE')}
          />
        </Col>
        <Col xs={6}>
          <Field
            component={DateInput}
            name='employeeInformationSection.employeePlan[0].dateFrom'
            label={<Translate id='FIRED_DATA' />}
          />
        </Col>
        <Col xs={4}>
          <Field
            component={SwitchInput}
            name='employeeInformationSection.groundEmployee'
            checkedText={<Translate id='YES' />}
            uncheckedText={<Translate id='NO' />}
            label={<Translate id='GROUND_EMPLOYEE' />}
          />
        </Col>
      </Row>
    </>
  )
}

Information.propTypes = {
  id: PropTypes.string
}

Information.defaultProps = {
  id: ''
}

export default withLocalize(
  connect((state) => ({
    currentFormValues: getFormValues('manage_employee')(state),
    countries: state.info.countries,
    contractTypes: state.info.contractTypes,
    employeeCategory: state.info.employeeCategory,
    employeeStatus: state.info.employeeStatus,
    employeeCostCenter: state.info.employeeCostCenter
  }))(Information)
)
