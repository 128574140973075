import React, {useState} from 'react'

import {DeleteOutlined} from '@ant-design/icons'
import {Row, Col, Popconfirm} from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'
import {connect} from 'react-redux'

import Document from '../../../../assets/icons/archive.svg'
import Open from '../../../../assets/icons/down.svg'
import DownloadAttachment from '../../../logic/attachments/DownloadAttachment'
import ActiveTranslation from '../../../logic/translations/ActiveTranslation'
import {Flex} from '../../../styles/BasicStyles'
import BaseButton from '../../buttons/BaseButton'
import GetFileType from '../../files/GetFileTypeIcon'
import FileInput from '../FileInput'
import SelectInput from '../SelectInput'
import {
  Collapsible,
  DocumentCard,
  DocumentInfo,
  DocumentName,
  DocumentNameContainer,
  DocumentsInputSection,
  NumberDocs,
  NumberDocsText,
  UploadImage,
  DocumentTitle,
  DocumentsPopupContainer,
  ToggleIcon,
  LineSeparator,
  DocumentTypeLabel,
  EmptyMessage,
  RemoveButton
} from './NewDocumentsStyles'

const NewDocumentInput = ({
  fields,
  attachmentTypes,
  disabled,
  addDocument,
  deleteDocument
}) => {
  const [open, setOpen] = useState(false)
  const [file, setFile] = useState(undefined)
  const [fileType, setFileType] = useState(undefined)
  const documents = fields.getAll() || []

  const addFile = async () => {
    const doc = {file, type: fileType, date: moment.utc()}
    if (typeof addDocument === 'function') {
      const {success, data} = await addDocument(doc)
      if (!success) return
      doc.newId = data
    }
    fields.push(doc)
    setFileType()
    setFile()
  }

  const DocumentType = (type) => {
    if (typeof type === 'object') return 'object'
    return attachmentTypes.find((f) => f.attachmentTypeId == type)
      ?.attachmentTypeTranslation
  }

  const handleDelete = async (e, document, index) => {
    e.stopPropagation()
    if (typeof deleteDocument === 'function') {
      const id = document.attachmentId || document.newId
      if (id) {
        const {success} = await deleteDocument(id)
        if (success) return fields.remove(index)
      } else return fields.remove(index)
    } else return fields.remove(index)
  }

  const handleRenderFile = (document, index) => {
    if (!document.attachmentId) {
      return (
        <DocumentCard>
          <UploadImage src={GetFileType(document.file)} />
          <div style={{width: '100%'}}>
            <DocumentNameContainer>
              <DocumentName>{document.file.name}</DocumentName>
              {!disabled && (
                <RemoveButton onClick={(e) => e.stopPropagation()}>
                  <Popconfirm
                    placement='topRight'
                    title='This request will automatically remove the attachment and will be lost. Are you sure?'
                    onConfirm={(e) => handleDelete(e, document, index)}
                  >
                    <DeleteOutlined />
                  </Popconfirm>
                </RemoveButton>
              )}
            </DocumentNameContainer>
            <DocumentInfo>
              <DocumentTypeLabel>
                <ActiveTranslation
                  value={DocumentType(document.type)}
                  tag='name'
                />
              </DocumentTypeLabel>
              {moment(document.DateCreated).format('DD/MM/YYYY')}
            </DocumentInfo>
          </div>
        </DocumentCard>
      )
    }

    return (
      <DocumentCard $pointer onClick={() => DownloadAttachment(document)}>
        <UploadImage src={GetFileType(document)} />
        <div style={{width: '100%'}}>
          <DocumentNameContainer>
            <DocumentName>{document.name}</DocumentName>
            {!disabled && (
              <RemoveButton onClick={(e) => e.stopPropagation()}>
                <Popconfirm
                  placement='topRight'
                  title='This request will automatically remove the attachment and will be lost. Are you sure?'
                  onConfirm={(e) => handleDelete(e, document, index)}
                >
                  <DeleteOutlined />
                </Popconfirm>
              </RemoveButton>
            )}
          </DocumentNameContainer>
          <DocumentInfo>
            <DocumentTypeLabel>
              <ActiveTranslation
                value={DocumentType(
                  document.attachmentType.attachmentTypeId
                )}
                tag='name'
              />
            </DocumentTypeLabel>
            {moment(document.DateCreated).format('DD/MM/YYYY')}
          </DocumentInfo>
        </div>
      </DocumentCard>
    )
  }

  return (
    <DocumentsPopupContainer>
      <DocumentsInputSection onClick={() => setOpen(!open)}>
        <Flex>
          <img src={Document} alt={<Translate id='DOCUMENT' />} />
          <DocumentTitle>
            <Translate id='DOCUMENTS' />
          </DocumentTitle>
        </Flex>
        <Flex>
          {documents.length > 0 && (
            <NumberDocs>
              <NumberDocsText>
                {documents.length <= 9
                  ? `0${documents.length}`
                  : documents.length}
              </NumberDocsText>
            </NumberDocs>
          )}
          <ToggleIcon src={Open} $open={open} />
        </Flex>
      </DocumentsInputSection>
      <Collapsible open={open}>
        {!disabled && (
          <Row gutter={[24, 6]} align='bottom'>
            <Col xs={24}>
              <FileInput
                label='File'
                placeholder='Add a file'
                input={{
                  value: file,
                  onChange: setFile
                }}
              />
            </Col>
            <Col xs={12}>
              <SelectInput
                label='Type'
                placeholder='Type of file'
                input={{value: fileType, onChange: setFileType}}
                data={attachmentTypes}
                dataKey='attachmentTypeId'
                dataLabel='attachmentTypeTranslation'
                allowClear={false}
              />
            </Col>
            <Col xs={12}>
              <BaseButton
                onClick={addFile}
                type='secondary'
                disabled={!file || !fileType}
              >
                Add
              </BaseButton>
            </Col>
          </Row>
        )}
        {documents.length > 0 && !disabled && <LineSeparator />}
        {!documents.length && disabled && (
          <EmptyMessage>No documents attached</EmptyMessage>
        )}
        <Row gutter={[12, 12]}>
          {documents.map((document, index) => (
            <Col xs={24} key={index}>
              {handleRenderFile(document, index)}
            </Col>
          ))}
        </Row>
      </Collapsible>
    </DocumentsPopupContainer>
  )
}

NewDocumentInput.propTypes = {
  fields: PropTypes.object.isRequired,
  attachmentTypes: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  addDocument: PropTypes.func,
  deleteDocument: PropTypes.func
}

NewDocumentInput.defaultProps = {
  disabled: false,
  addDocument: undefined,
  deleteDocument: undefined
}

export default connect((state) => ({
  attachmentTypes: state.info.attachmentTypes
}))(NewDocumentInput)
