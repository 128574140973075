import styled from 'styled-components'

import {
  NavBackgroundColor,
  TableHeaderColor
} from '../../../shared/styles/_colors'
import {CaptionText, LightFontWeight} from '../../../shared/styles/_texts'

export const WhiteCard = styled.div`
  background-color: ${NavBackgroundColor};
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
  padding: 10px;
  width: 100%;
  position: relative;
  border-radius: 4px;
  height: 130px;
`
export const Text = styled.div`
  color: ${TableHeaderColor};
  font-size: ${CaptionText};
  font-weight: ${LightFontWeight};
  line-height: 16px;
  margin-top: 15px;
`

export const GraphImage = styled.img`
  position: absolute;
  bottom: 12px;
  right: 10px;
  width: 145px;
`
