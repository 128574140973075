import React, {useState} from 'react'

import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {FieldArray} from 'redux-form'

import Trash from '../../../assets/icons/remove.svg'
import AlertService from '../../../shared/components/alert/AlertService'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import IntegerInput from '../../../shared/components/inputs/IntegerInput'
import SelectInput from '../../../shared/components/inputs/SelectInput'
import TextInput from '../../../shared/components/inputs/TextInput'
import ProductLink from '../../../shared/components/links/ProductLink'
import BaseTable from '../../../shared/components/table/BaseTable'
import {Margin} from '../../../shared/styles/BasicStyles'

const Columns = [
  {
    title: <Translate id='PRODUCT' />,
    dataIndex: 'product',
    type: 'text',
    render: (value) => <ProductLink product={value} />
  },
  {
    dataIndex: 'order',
    type: 'text',
    title: <Translate id='ORDER' />
  },
  {
    dataIndex: 'quantity',
    type: 'text',
    title: <Translate id='DOTATION' />
  },
  {
    dataIndex: 'time',
    type: 'text',
    title: <Translate id='RENOVATION' />
  }
]

const DotationComponent = ({fields, products, translate}) => {
  const [selected, setSelected] = useState([])
  const [type, setType] = useState('')
  const [dot, setDot] = useState('')
  const [order, setOrder] = useState('')
  const [renovation, setRenovation] = useState('')

  const rows = fields.getAll() || []

  const filteredProducts = () =>
    products.filter((p) => !rows.find((t) => t.productId == p.productId))

  const addDotation = () => {
    const item = products.find((x) => x.productId == type)

    const samePosition = rows.find((r) => r.order == order)
    if (samePosition) {
      return AlertService.error(
        translate('DOTATION_DUPLICATED_ORDER'),
        translate('DOTATION_DUPLICATED_ORDER_MESSAGE')
      )
    }

    if (item !== undefined) {
      const object = {
        productId: type,
        product: {
          productTranslation: item?.productTranslation,
          productId: type
        },
        order,
        quantity: dot,
        time: renovation
      }
      fields.push(object)
      setType('')
      setDot('')
      setOrder('')
      setRenovation('')
    }
  }

  const removeDotation = () => {
    const indexes = selected.map((element) =>
      rows.findIndex((x) => x.productId === element.key)
    )
    indexes
      .sort((a, b) => (a > b ? -1 : 1))
      .forEach((i) => fields.remove(i))
    setSelected([])
  }

  return (
    <>
      <Row gutter={[24]} align='bottom'>
        <Col xs={8}>
          <SelectInput
            input={{value: type, onChange: setType}}
            label={<Translate id='PRODUCTS' />}
            data={filteredProducts()}
            placeholder={<Translate id='CHOOSE' />}
            dataLabel='productTranslation'
            dataKey='productId'
          />
        </Col>
        <Col xs={4}>
          <IntegerInput
            input={{value: order, onChange: setOrder}}
            label={<Translate id='ORDER' />}
          />
        </Col>
        <Col xs={4}>
          <IntegerInput
            input={{value: dot, onChange: setDot}}
            label={<Translate id='DOTATION' />}
            inputDescriptionText={<Translate id='UNITS' />}
          />
        </Col>
        <Col xs={4}>
          <IntegerInput
            input={{value: renovation, onChange: setRenovation}}
            label={<Translate id='RENOVATION_TIME' />}
            inputDescriptionText={<Translate id='DAYS' />}
          />
        </Col>
        <Col xs={4}>
          <BaseButton
            auto
            type='secondary'
            onClick={addDotation}
            disabled={
              type === '' ||
              dot === '' ||
              renovation === '' ||
              order === ''
            }
          >
            <Translate id='ADD' />
          </BaseButton>
        </Col>
      </Row>
      <Margin size={38} />
      <Row>
        <Col xs={24}>
          <BaseButton
            auto
            type='secondary'
            imagePath={Trash}
            onClick={removeDotation}
            disabled={selected?.length <= 0}
          >
            <Translate id='DELETE' />
          </BaseButton>
        </Col>
      </Row>
      <Margin size={14} />
      <Row>
        <Col xs={24}>
          <BaseTable
            datasource={rows}
            columns={Columns}
            pagination={{
              render: false
            }}
            rowSelection={{
              selected,
              onRowSelection: setSelected
            }}
            rowKey='productId'
            // onClickRow={(row) =>
            //   router.history.push(`/products/${row.productId}`)
            // }
          />
        </Col>
      </Row>
    </>
  )
}

DotationComponent.propTypes = {
  router: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired
}

const dotation = ({products, router}) => (
  <FieldArray
    component={withLocalize(DotationComponent)}
    name='positionEDSection.positionProduct'
    products={products}
    router={router}
  />
)

dotation.propTypes = {
  products: PropTypes.array.isRequired
}

export default withLocalize(dotation)
