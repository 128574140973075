import React from 'react'

import {Col, Row} from 'antd'
import {Translate} from 'react-localize-redux'
import {Field, FormSection} from 'redux-form'

import ProductPriceInput from '../../../../shared/components/inputs/ProductPriceInput'
import {
  BodyH3,
  Margin,
  TitleH3
} from '../../../../shared/styles/BasicStyles'

const RawMaterialPrices = () => (
  <FormSection name='rawMaterialPriceSection'>
    <Row gutter={[20, 20]}>
      <Col xs={24}>
        <Col xs={24}>
          <TitleH3 $opacity='0.5'>
            <Translate id='PRICE_CONFIG' />
          </TitleH3>
        </Col>
        <Row gutter={[20, 20]}>
          <Col xs={6} style={{marginLeft: '16px', marginTop: '45px'}}>
            <Field
              name='baseValue'
              label={<Translate id='UNIT_COST' />}
              component={ProductPriceInput}
              inputDescriptionText='EUR'
            />
          </Col>
          <Col xs={6} style={{marginLeft: '173px'}}>
            <Col xs={24} style={{marginBottom: '-10px'}}>
              <BodyH3 $opacity='0.5'>
                <Translate id='TAXES' />
              </BodyH3>
            </Col>
            <Margin size={30} />
            <Col xs={24}>
              <Field
                name='vatvalue'
                label={<Translate id='VAT_RATE' />}
                component={ProductPriceInput}
                disabled
              />
            </Col>
          </Col>
        </Row>
        <Margin size={20} />
        <Row gutter={[20, 20]}>
          <Col xs={6} style={{marginLeft: '16px'}}>
            <Field
              name='totalValue'
              label={<Translate id='FINAL_COST' />}
              component={ProductPriceInput}
              disabled
              inputDescriptionText='EUR'
            />
          </Col>
        </Row>
      </Col>
    </Row>
    <Margin size='60' />
    <Row gutter={[20, 20]}>
      <Col xs={24}>
        <TitleH3 $opacity='0.5'>
          <Translate id='OTHER_PRICES' />
        </TitleH3>
      </Col>
      <Col xs={6}>
        <Field
          name='tableValue'
          label={<Translate id='TABLE_VALUE' />}
          component={ProductPriceInput}
          disabled
          inputDescriptionText='EUR'
        />
      </Col>
      <Col xs={6}>
        <Field
          name='costValue'
          label={<Translate id='WEIGHTED_COST' />}
          component={ProductPriceInput}
          disabled
          inputDescriptionText='EUR'
        />
      </Col>
    </Row>
  </FormSection>
)

RawMaterialPrices.propTypes = {}

RawMaterialPrices.defaultProps = {}

export default RawMaterialPrices
