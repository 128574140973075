import React from 'react'

import {Popover} from 'antd'
import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'

import Info from '../../../assets/icons/info.svg'
import {
  Flex,
  LineContent,
  CircleNote,
  NoteIcon,
  TextPopover
} from '../LogisticStyles'

const ValuePopup = ({item}) => {
  const content = (
    <Flex>
      <TextPopover $bold>
        <Translate id='UNITARY_VALUE' />
      </TextPopover>
      <TextPopover>{item.unitValue.toFixed(3) || 0}€</TextPopover>
    </Flex>
  )
  return (
    <Popover placement='top' trigger='hover' content={content}>
      <LineContent>
        {item.totalValue.toFixed(3) || 0}€
        <CircleNote>
          <NoteIcon src={Info} alt='info' />
        </CircleNote>
      </LineContent>
    </Popover>
  )
}

ValuePopup.propTypes = {
  item: PropTypes.object.isRequired
}

export default ValuePopup
