import client from '../config/AxiosConfig'

export const GetArrivalsList = async (page, pageSize, filters, sort) =>
  client.get(
    `/Logistics/ListArrivals?ItemsPerPage=${pageSize}&Page=${page}${filters}${sort}`
  )

export const GetArrivalDetail = async (id) =>
  client.get(`/Logistics/ArrivalViewDetail/${id}`)

export const GetPendingSupplierOrders = (search = '') =>
  client.get(`Logistics/GetOrderSupplierSetFromOS?search=${search}`)

export const CreateSupplierReception = (data) =>
  client.post('/Logistics/CreateSupplierReception', data)

export const CreateClientReception = (data) =>
  client.post('/Logistics/CreateClientReception', data)

export const CreateClientReturn = (data) =>
  client.post('/Logistics/CreateClientReturn', data)

export const ValidateArrival = (id, data) =>
  client.put(`/Logistics/ValidateProductsReception/${id}`, data)

  export const UpdateMovementPrices = (id, data) =>
  client.put(`/Movements/UpdateMovementPrices/${id}`, data)
