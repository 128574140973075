import CreateWayBillPage from '../../pages/logistics/Departures/WaybillKits/CreateWayBillPage'
import ManageWayBillPage from '../../pages/logistics/Departures/WaybillKits/ManageWayBillPage'
import CreateWHTransfer from '../../pages/logistics/Departures/WHTransfer/CreateWHTransfer'
import TransferDetailPage from '../../pages/logistics/Departures/WHTransfer/TransferDetailPage'

// import TransferDetailPage from '../../pages/logistics/Departures/WHTransfer/TransferDetailPage'

export default [
  {
    path: '/logistics/departures/waybill-kits/new',
    component: CreateWayBillPage,
    isAuthenticated: true,
    shouldRenderHeader: true,
    shouldRenderFooter: true,
    role: 1
  },
  {
    path: '/logistics/departures/waybill-kits/:id',
    component: ManageWayBillPage,
    isAuthenticated: true,
    shouldRenderHeader: true,
    shouldRenderFooter: true,
    role: 1
  },
  {
    path: '/logistics/departures/transfers/new',
    component: CreateWHTransfer,
    isAuthenticated: true,
    shouldRenderHeader: true,
    shouldRenderFooter: true,
    role: 1
  },
  {
    path: '/logistics/departures/transfers/:id',
    component: TransferDetailPage,
    isAuthenticated: true,
    shouldRenderHeader: true,
    shouldRenderFooter: true,
    role: 1
  }
]
