import React from 'react'

import {Col, Row} from 'antd'
import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'
import {Field, FormSection} from 'redux-form'

import SelectInput from '../../../../shared/components/inputs/SelectInput'
import TextInput from '../../../../shared/components/inputs/TextInput'
import {Margin} from '../../../../shared/styles/BasicStyles'

const GeneralInformation = ({rawMaterialFamily, productMeasuresUnit}) => (
  <FormSection name='rawMaterialPropertiesSection'>
    <Row gutter={[20, 20]}>
      <Col xs={12} lg={6}>
        <Field
          component={TextInput}
          name='referenceCode'
          disabled
          label={<Translate id='REFERENCE' />}
          placeholder={<Translate id='REFERENCE' />}
        />
      </Col>
      <Col xs={12} lg={6}>
        <Field
          component={SelectInput}
          name='measureUnitId'
          label={<Translate id='MEASURE_UNIT' />}
          dataKey='measureUnitId'
          allowClear={false}
          dataLabel='code'
          placeholder={<Translate id='MEASURE_UNIT' />}
          data={productMeasuresUnit}
        />
      </Col>
      <Col xs={12} lg={6}>
        <Field
          component={SelectInput}
          name='rawMaterialFamilyId'
          label={<Translate id='RAW_MATERIAL_FAMILY' />}
          dataKey='rawMaterialFamilyId'
          dataLabel='rawMaterialFamilyTranslation'
          placeholder={<Translate id='RAW_MATERIAL_FAMILY' />}
          data={rawMaterialFamily}
        />
      </Col>
    </Row>
    <Margin size={20} />
  </FormSection>
)

GeneralInformation.propTypes = {
  rawMaterialFamily: PropTypes.array,
  productMeasuresUnit: PropTypes.array
}

GeneralInformation.defaultProps = {
  rawMaterialFamily: [],
  productMeasuresUnit: []
}
export default GeneralInformation
