import React, { useState, useEffect } from 'react'

import {
  TableOutlined,
  SearchOutlined,
  ContainerOutlined
} from '@ant-design/icons'
import { Spin } from 'antd'
import { PropTypes } from 'prop-types'
import { withLocalize } from 'react-localize-redux'

import ProductMatrix from './ProductMatrix'
import { SearchProducts } from '../../../../infra/requests/OrdersB2BRequests'
import { GetTranslationFromArray } from '../../../logic/translations/Translations'
import OutsideAlerter from '../../OutsideAlerter/OutsideAlerter'
import {
  AutocompleteContainer,
  StyledAutocompleteSearch,
  AutoCompleteIcon,
  AutoCompleteMenu,
  AutoCompleteOption,
  AutoCompleteEmpty
} from '../InputStyles'

let searchTimeout = null

const AddProductInput = ({
  onSelectValues,
  placeholderTag,
  dataKey,
  dataLabel,
  activeLanguage,
  field,
  client,
  clientRequired,
  owner,
  ownerRequired,
  warehouseId,
  destinyWarehouseId,
  translate,
  disabled
}) => {
  const [loading, setLoading] = useState(false)
  const [menu, toggleMenu] = useState(false)
  const [options, setOptions] = useState([])
  const [search, setSearch] = useState('')
  const [selected, setSelected] = useState(undefined)
  const [openModal, setModal] = useState(false)

  useEffect(() => {
    function resetForm() {
      setSearch('')
      toggleMenu(false)
      setSelected(undefined)
      setOptions([])
    }
    resetForm()
  }, [client, owner])

  const setItemLabel = (item) => {
    if (dataLabel === 'productTranslation') {
      return GetTranslationFromArray(item[dataLabel], activeLanguage.code)
        ?.name
    }

    if (dataLabel === 'referenceCode') {
      if (
        item.productCombination &&
        item.productCombination.length === 1
      ) {
        const combination = item.productCombination[0]
        return combination.reference
      }
    }
    return item[dataLabel]
  }

  const asyncSearch = async (value) => {
    if (value && value !== '') {
      setLoading(true)
      let baseUrl = `?${field}=${value}`
      if (clientRequired) {
        baseUrl += `&clientId=${client}`
      }
      if (ownerRequired) {
        baseUrl += `&ownerId=${owner}`
      }
      if (warehouseId) {
        baseUrl += `&originWarehouseId=${warehouseId}`
      }
      if (destinyWarehouseId) {
        baseUrl += `&destinyWarehouseId=${destinyWarehouseId}`
      }
      const { data, success } = await SearchProducts(baseUrl)

      const newOptions = success && data
        ? data?.items.map((item) => ({
          ...item,
          value: item[dataKey],
          label: setItemLabel(item)
        }))
        : []

      // Request must also contain items from skyproHQ, so the next part is joining the products from it.
      // In the future, change the request instead of doing 2 times.

      if (clientRequired && client != 1) {
        let baseSkyproUrl = `?${field}=${value}&clientId=${1}`

        if (ownerRequired) {
          baseSkyproUrl += `&ownerId=${owner}`
        }

        if (warehouseId) {
          baseSkyproUrl += `&originWarehouseId=${warehouseId}`
        }

        const result = await SearchProducts(baseSkyproUrl)
        newOptions.push(
          ...(result?.data?.items || []).map((item) => ({
            ...item,
            value: item[dataKey],
            label: setItemLabel(item)
          })))
      }

      setOptions(newOptions)

      setLoading(false)
      toggleMenu(true)
    } else setLoading(false)
  }

  const onChange = (e) => {
    const { value } = e.target
    setSearch(value)
    setSelected(undefined)
    if (searchTimeout) clearTimeout(searchTimeout)
    searchTimeout = setTimeout(() => {
      asyncSearch(value)
    }, 1000)
  }

  const selectOption = (opt) => {
    setSearch(opt.label)
    setSelected(opt)
    toggleMenu(false)
  }

  const shouldOpenMenu = () => {
    if (!loading && options.length) toggleMenu(true)
  }

  const onOpenMatrix = (e) => {
    e.stopPropagation()
    setModal(true)
  }

  const onSaveMatrix = (result) => {
    setModal(false)
    onSelectValues(result)
    setSearch('')
    setSelected(undefined)
    setOptions([])
  }

  const renderOption = () => {
    if (loading) {
      return (
        <AutoCompleteIcon>
          <Spin style={{ marginTop: '4px' }} size='small' />
        </AutoCompleteIcon>
      )
    }
    if (selected) {
      return (
        <AutoCompleteIcon $active onClick={onOpenMatrix}>
          <TableOutlined />
        </AutoCompleteIcon>
      )
    }
    return (
      <AutoCompleteIcon>
        <SearchOutlined />
      </AutoCompleteIcon>
    )
  }

  const handlePlaceholder = () => {
    if (clientRequired && !client) {
      return translate('SELECT_CLIENT_FIRST')
    }
    if (ownerRequired && !owner) {
      return translate('SELECT_OWNER_FIRST')
    }
    return translate(placeholderTag)
  }

  const renderEmptyOptions = () => {
    if (options.length > 0) return null
    let message = 'No products were found'
    if (warehouseId) {
      message = 'No products were found for the search associated to this warehouse'
    } else if (owner) {
      message = 'No products were found for the search associated to this owner'
    } else if (client) {
      message = 'No products were found for the search associated to this client'
    }
    return (
      <AutoCompleteEmpty>
        <ContainerOutlined />
        {message}
      </AutoCompleteEmpty>
    )
  }

  return (
    <>
      <AutocompleteContainer onClick={shouldOpenMenu}>
        <StyledAutocompleteSearch
          disabled={
            (clientRequired && !client) ||
            (ownerRequired && !owner) ||
            disabled
          }
          placeholder={handlePlaceholder()}
          value={search}
          onChange={onChange}
          onClick={shouldOpenMenu}
        />
        {renderOption()}
        <OutsideAlerter callback={() => toggleMenu(false)}>
          <AutoCompleteMenu opened={menu}>
            {renderEmptyOptions()}
            {options.map((opt, index) => (
              <AutoCompleteOption
                key={index}
                onClick={(e) => {
                  selectOption(opt)
                  e.stopPropagation()
                }}
              >
                {opt.label}
              </AutoCompleteOption>
            ))}
          </AutoCompleteMenu>
        </OutsideAlerter>
      </AutocompleteContainer>
      <ProductMatrix
        open={openModal}
        onClose={() => setModal(false)}
        product={selected}
        onSave={onSaveMatrix}
      />
    </>
  )
}

AddProductInput.prototype = {}

AddProductInput.propTypes = {
  onSelectValues: PropTypes.func.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  field: PropTypes.string,
  placeholderTag: PropTypes.string,
  dataKey: PropTypes.string,
  dataLabel: PropTypes.string,
  client: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  clientRequired: PropTypes.bool,
  owner: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  warehouseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  destinyWarehouseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ownerRequired: PropTypes.bool,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  translate: PropTypes.func
}

AddProductInput.defaultProps = {
  placeholderTag: 'SEARCH',
  dataKey: 'productId',
  dataLabel: 'referenceCode',
  field: 'ref',
  client: undefined,
  clientRequired: false,
  owner: undefined,
  ownerRequired: false,
  warehouseId: undefined,
  destinyWarehouseId: undefined,
  disabled: false,
  translate: undefined
}

export default withLocalize(AddProductInput)
