export const StatusTypes = [
  {
    text: 'New Paid',
    value: 'newPaid'
  },
  {
    text: 'New Unpaid',
    value: 'newUnpaid'
  },
  {
    text: 'Approved',
    value: 'approved'
  },
  {
    text: 'Processing',
    value: 'processing'
  },

  {
    text: 'Partially Shipped',
    value: 'partShipped'
  },
  {
    text: 'Shipped',
    value: 'shipped'
  },
  {
    text: 'Returned',
    value: 'returned'
  },
  {
    text: 'Back Order',
    value: 'backOrder'
  },
  {
    text: 'Cancelled',
    value: 'cancelled'
  },
  {
    text: 'Finished',
    value: 'finished'
  }
]

export const GetStatusType = (type, orderStatus) => {
  const status = orderStatus.find((elem) => elem.OrderStatusID === type)
  return status
}

export const GetStatusColor = (type) => {
  switch (type) {
    case 0:
      return '#29ABE2'

    case 1:
      return '#FCCD21'

    case 2:
    case 6:
    case 10:
      return '#ED1C00'

    case 3:
    case 8:
      return '#8CD73F'

    case 4:
    case 7:
      return '#2243E0'

    case 5:
    case 9:
    case 11:
      return '#39B54A'

    default:
      return ''
  }
}
