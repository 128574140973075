import React, {Component} from 'react'

import {ImportOutlined} from '@ant-design/icons'
import {Row, Col, Popover} from 'antd'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'

import Add from '../../../assets/icons/add_bordered.svg'
import {
  GetB2BClientEmployees
} from '../../../infra/requests/ClientRequests'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import SearchInput from '../../../shared/components/inputs/SearchInput'
import StatusColumn from '../../../shared/components/Status/StatusColumn'
import BaseTable from '../../../shared/components/table/BaseTable'
import {Margin} from '../../../shared/styles/BasicStyles'
import ImportsPopover from './ImportsPopover'

let timeout

class EmployeesTab extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      rows: [],
      page: 1,
      pageSize: 24,
      total: 0,
      search: ''
    }
  }

  getColumns = () => [
    {
      title: <Translate id='NAME' />,
      type: 'text',
      render: (value) => (
        <div>
          {value?.firstName} {value?.lastName}
        </div>
      )
    },
    {
      title: <Translate id='WORKGROUP' />,
      type: 'text',
      dataIndex: 'position',
      render: (value) => <div>{value?.name}</div>
    },
    {
      title: <Translate id='USER' />,
      type: 'text',
      dataIndex: 'hasUser',
      render: (value) => (
        <StatusColumn
          value={value}
          onText={<Translate id='YES' />}
          offText={<Translate id='NO' />}
        />
      )
    },
    {
      title: <Translate id='STATE' />,
      type: 'text',
      dataIndex: 'active',
      render: (value) => <StatusColumn value={value} />
    }
  ]

  componentDidMount = async () => {
    try {
      this.updateTable()
    } catch (error) {
      console.warn(error)
    }
  }

  updateTable = async () => {
    const {page, pageSize, search} = this.state
    const {id} = this.props
    try {
      this.setState({loading: true})

      const {data, success} = await GetB2BClientEmployees(
        id,
        page,
        pageSize,
        search
      )

      if (success) {
        this.setState({
          rows: data?.items,
          loading: false,
          total: data?.totalItems
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  onChangePagination = (page) => this.setState({page}, this.updateTable)

  openEmployee = (row) => {
    const {router, id} = this.props
    router.history.push(`/clients/${id}/employee/${row.employeeId}`)
  }

  setFilter = (search) => {
    this.setState({search, page: 1}, () => {
      if (timeout) clearTimeout(timeout)
      timeout = setTimeout(this.updateTable, 1000)
    })
  }

  render() {
    const {loading, rows, page, pageSize, total, search} = this.state
    const {router, id, translate} = this.props

    return (
      <>
        <Row gutter={[24, 24]}>
          <Col xs={18}>
            <BaseButton
              auto
              imagePath={Add}
              style={{marginRight: '15px'}}
              type='primary'
              onClick={() =>
                router.history.push(`/clients/${id}/employee/new`)
              }
            >
              <Translate id='ADD_NEW' />
            </BaseButton>

            <Popover
              content={<ImportsPopover id={id} />}
              placement='rightTop'
              trigger='click'
            >
              <BaseButton
                auto
                icon={<ImportOutlined />}
                type='secondary'
              >
                <Translate id='IMPORT_EMPLOYEES' />
              </BaseButton>
            </Popover>
          </Col>
          <Col xs={6}>
            <SearchInput
              input={{
                value: search,
                onChange: this.setFilter,
                onSearch: this.updateTable
              }}
              placeholder={translate('SEARCH')}
            />
          </Col>
        </Row>
        <Margin size={15} />
        <Row>
          <Col xs={24}>
            <BaseTable
              rowKey='employeeId'
              datasource={rows}
              columns={this.getColumns()}
              pagination={{
                render: true,
                page,
                pageSize,
                total,
                onChangePagination: this.onChangePagination
              }}
              loading={loading}
              onClickRow={this.openEmployee}
            />
          </Col>
        </Row>
      </>
    )
  }
}

EmployeesTab.propTypes = {
  router: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired
}

export default withLocalize(EmployeesTab)
