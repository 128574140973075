import React, {Component} from 'react'

import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'

import Add from '../../../../assets/icons/add_bordered.svg'
import Active from '../../../../assets/icons/status_active.svg'
import Inactive from '../../../../assets/icons/status_inactive.svg'
import {GetDepartments} from '../../../../infra/requests/SkyproConfigsRequests'
import BaseButton from '../../../../shared/components/buttons/BaseButton'
import StatusColumn from '../../../../shared/components/Status/StatusColumn'
import BaseTable from '../../../../shared/components/table/BaseTable'
import {
  StatusActive,
  StatusInactive
} from '../../../../shared/styles/_colors'
import {Margin} from '../../../../shared/styles/BasicStyles'
import {StatusText} from '../../../settings/SettingsStyles'

class DepartmentTab extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      rows: [],
      page: 1,
      pageSize: 20,
      total: 0
    }
  }

  getColumns = () => [
    {
      dataIndex: 'name',
      type: 'text',
      title: <Translate id='NAME' />
    },
    {
      dataIndex: 'mailServer',
      type: 'text',
      title: <Translate id='SERVER' />,
      render: (value) => <div>{value?.name || '-'}</div>
    },
    {
      dataIndex: 'priority',
      type: 'text',
      title: <Translate id='PRIORITY' />,
      render: (value) => <div>{value || '-'}</div>
    },
    {
      dataIndex: 'active',
      type: 'text',
      title: <Translate id='STATUS' />,
      render: (value) => <StatusColumn value={value} />
    }
  ]

  componentDidMount = async () => {
    try {
      this.updateTable()
    } catch (error) {
      console.warn(error)
    }
  }

  updateTable = async () => {
    try {
      this.setState({loading: true})

      const {data, success} = await GetDepartments()

      if (success) {
        this.setState({
          rows: data?.items,
          total: data?.totalItems,
          loading: false
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  onChangePagination = (page) => this.setState({page}, this.updateTable)

  render() {
    const {loading, rows, page, pageSize, total} = this.state
    const {router} = this.props

    return (
      <>
        <Row>
          <Col xs={24}>
            <BaseButton
              auto
              imagePath={Add}
              type='primary'
              onClick={() => router.history.push('/skypro/department/new')}
            >
              <Translate id='ADD_DEPARTMENT' />
            </BaseButton>
          </Col>
        </Row>
        <Margin size={17} />
        <Row>
          <Col xs={24}>
            <BaseTable
              rowKey='sectionId'
              datasource={rows}
              columns={this.getColumns()}
              pagination={{
                render: true,
                page,
                pageSize,
                total,
                onChangePagination: this.onChangePagination
              }}
              onClickRow={(row) => {
                router.history.push(`/skypro/department/${row.sectionId}`)
              }}
              loading={loading}
            />
          </Col>
        </Row>
      </>
    )
  }
}

DepartmentTab.propTypes = {
  router: PropTypes.object.isRequired
}

export default DepartmentTab
