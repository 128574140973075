import React, {useEffect, useState} from 'react'

import {Row, Col} from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {useParams} from 'react-router'
import {reduxForm, Field, initialize, FieldArray} from 'redux-form'

import Trash from '../../../../../assets/icons/remove_white.svg'
import {
  CreateBanner,
  EditBanner,
  EditBannerImage,
  EditBannerImageMobile,
  EditBannerLPImage,
  EditBannerLPImageMobile,
  GetBanner,
  DeleteBanner
} from '../../../../../infra/requests/MarketingRequests'
import AlertService from '../../../../../shared/components/alert/AlertService'
import BackButton from '../../../../../shared/components/buttons/BackButton'
import BaseButton from '../../../../../shared/components/buttons/BaseButton'
import DeleteButton from '../../../../../shared/components/buttons/DeleteButton'
import FileInput from '../../../../../shared/components/inputs/FileInput'
import SelectInput from '../../../../../shared/components/inputs/SelectInput'
import SwitchInput from '../../../../../shared/components/inputs/SwitchInput'
import TextInput from '../../../../../shared/components/inputs/TextInput'
import {
  Margin,
  PageForm,
  PageTitle,
  TitleH6
} from '../../../../../shared/styles/BasicStyles'
import {
  BannerImage,
  BannerImageContainer,
  DateText
} from '../../MarketingStyles'
import BannerTranslations from './components/BannerTranslations'

const ManageBanner = ({
  router,
  dispatch,
  handleSubmit,
  bannersType,
  translate
}) => {
  const [hasFetchedBanner, setHasFetchedBanner] = useState(false)
  const [imageWeb, setImageWeb] = useState(undefined)
  const [imageMobile, setImageMobile] = useState(undefined)
  const [imageLPWeb, setImageLPWeb] = useState(undefined)
  const [imageLPMobile, setImageLPMobile] = useState(undefined)
  const [fileError, setFileError] = useState(false)
  const [failed, setFailed] = useState(false)
  const {id} = useParams()

  useEffect(() => {
    async function fetchBanner() {
      if (id !== 'new') {
        try {
          const {data, success} = await GetBanner(id)

          if (success) {
            setImageWeb(data?.imagePath)
            setImageMobile(data?.imagePathMobile)
            setImageLPWeb(data?.imagePathLandingPage)
            setImageLPMobile(data?.imagePathLandingPageMobile)
            dispatch(initialize('banner_form', data || {}))
            setHasFetchedBanner(true)
          }
        } catch (error) {
          console.log(error)
        }
      }
    }
    if (!hasFetchedBanner) {
      fetchBanner()
    }
  }, [hasFetchedBanner, dispatch])

  const changeImages = async (bannerId) => {
    try {
      if (imageWeb?.name || imageWeb?.path) {
        const payload = new FormData()
        payload.append('File', imageWeb)

        await EditBannerImage(bannerId, payload)
      }
      if (imageMobile?.name || imageMobile?.path) {
        const payload = new FormData()
        payload.append('File', imageMobile)

        await EditBannerImageMobile(bannerId, payload)
      }
      if (imageLPWeb?.name || imageLPWeb?.path) {
        const payload = new FormData()
        payload.append('File', imageLPWeb)

        await EditBannerLPImage(bannerId, payload)
      }
      if (imageLPMobile?.name || imageLPMobile?.path) {
        const payload = new FormData()
        payload.append('File', imageLPMobile)

        await EditBannerLPImageMobile(bannerId, payload)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onSubmit = async (values) => {
    try {
      const object = {
        ...values,
        bannerTypeId: values?.bannerType?.bannerTypeId
      }
      if (id !== 'new') {
        const {success} = await EditBanner(id, object)

        if (success) {
          changeImages(id)
          AlertService.success(
            translate('SUCCESS'),
            translate('BANNER_EDITED_SUCCESSFULLY')
          )
          router.history.push('/marketing#banners')
        }
      } else {
        const {data, success} = await CreateBanner(object)

        if (success) {
          changeImages(data?.bannerId)
          AlertService.success(
            translate('SUCCESS'),
            translate('BANNER_CREATED_SUCCESSFULLY')
          )
          router.history.push('/marketing#banners')
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getImgDate = (img) => {
    const date = img.split('_')
    return `${date[3]}/${date[2]}/${date[1]}`
  }

  const renderImg = (img) => {
    const image =
      img !== undefined &&
      (img?.name || img?.path) &&
      URL.createObjectURL(img)

    return (
      <>
        {img !== undefined && (
          <BannerImageContainer>
            <BannerImage src={img?.name || img?.path ? image : img} />
            <div>
              <TitleH6 style={{marginLeft: 14}}>
                {img?.name || img?.path
                  ? img.name
                  : img.split('/')[img.split('/').length - 1]}
              </TitleH6>
              <DateText>
                {img?.name || img?.path
                  ? moment(img.lastModifiedDate).format('DD/MM/YYYY')
                  : getImgDate(img)}
              </DateText>
            </div>
          </BannerImageContainer>
        )}
      </>
    )
  }

  const deleteBanner = async () => {
    try {
      const {success} = await DeleteBanner(id)
      if (success) {
        AlertService.success(
          translate('SUCCESS'),
          translate('BANNER_DELETED_SUCCESSFULLY')
        )
        router.history.push('/marketing#banners')
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <PageForm onSubmit={handleSubmit(onSubmit)}>
        <Row align='bottom' gutter={[0, 50]}>
          <Col xs={24}>
            <BackButton
              label={<Translate id='BACK' />}
              onClick={() => router.history.push('/marketing#banners')}
            />
          </Col>
          <Col xs={12}>
            <PageTitle>
              <Translate id='BANNERS' />
            </PageTitle>
          </Col>

          <Col xs={12} align='end'>
            <BaseButton htmlType='submit' type='primary' auto>
              <Translate id='SAVE' />
            </BaseButton>
          </Col>
        </Row>
        <Margin size={40} />
        {id !== 'new' && (
          <>
            <Row>
              <Col xs={6}>
                <DeleteButton
                  imagePath={Trash}
                  type='error'
                  auto
                  onClick={() => deleteBanner()}
                >
                  <Translate id='DELETE_BANNER' />
                </DeleteButton>
              </Col>
            </Row>
            <Margin size={38} />
          </>
        )}
        <Row gutter={[40, 20]}>
          <Col xs={6}>
            <Field
              component={SelectInput}
              name='bannerType.bannerTypeId'
              label={<Translate id='TYPE' />}
              placeholder={<Translate id='SELECT' />}
              data={bannersType}
              dataKey='bannerTypeId'
              dataLabel='bannerTypeTranslation'
            />
          </Col>
          <Col xs={6}>
            <Field
              component={TextInput}
              name='url'
              label={<Translate id='URL_BANNER' />}
            />
          </Col>
          <Col xs={6}>
            <Field
              component={TextInput}
              name='urlButton1'
              label={<Translate id='URL_BUTTON' />}
            />
          </Col>
          <Col xs={6}>
            <Field
              component={TextInput}
              name='urlButton2'
              label={<Translate id='URL_BUTTON_2' />}
            />
          </Col>
          <Col xs={24}>
            <FieldArray
              name='bannerTranslation'
              component={BannerTranslations}
              dispatch={dispatch}
            />
          </Col>
        </Row>
        <Margin size={21} />
        <Row gutter={[40, 20]}>
          <Col xs={4}>
            <Field
              component={SwitchInput}
              name='landingPage'
              checkedText={<Translate id='YES' />}
              uncheckedText={<Translate id='NO' />}
              label={<Translate id='LANDING_PAGE' />}
            />
          </Col>
          <Col xs={4}>
            <Field
              component={SwitchInput}
              name='blackText'
              checkedText={<Translate id='YES' />}
              uncheckedText={<Translate id='NO' />}
              label={<Translate id='BLACK_TEXT' />}
            />
          </Col>
          <Col xs={4}>
            <Field
              component={SwitchInput}
              name='active'
              checkedText={<Translate id='YES' />}
              uncheckedText={<Translate id='NO' />}
              label={<Translate id='STATUS' />}
            />
          </Col>
        </Row>
        <Margin size={31} />
        <Row gutter={[40, 20]}>
          <Col xs={12}>
            <FileInput
              label={<Translate id='IMAGE_WEB' />}
              accept='image/*'
              input={{
                value: imageWeb,
                onChange: setImageWeb
              }}
              meta={{
                invalid: fileError,
                submitFailed: failed,
                error: <Translate id='MISSING_FILE' />
              }}
            />
            <Margin size={23} />
            {renderImg(imageWeb)}
          </Col>
          <Col xs={12}>
            <FileInput
              label={<Translate id='IMAGE_MOBILE' />}
              accept='image/*'
              input={{
                value: imageMobile,
                onChange: setImageMobile
              }}
              meta={{
                invalid: fileError,
                submitFailed: failed,
                error: <Translate id='MISSING_FILE' />
              }}
            />
            <Margin size={23} />
            {renderImg(imageMobile)}
          </Col>
          <Col xs={12}>
            <FileInput
              label={<Translate id='IMAGE_LP_WEB' />}
              accept='image/*'
              input={{
                value: imageLPWeb,
                onChange: setImageLPWeb
              }}
              meta={{
                invalid: fileError,
                submitFailed: failed,
                error: <Translate id='MISSING_FILE' />
              }}
            />
            <Margin size={23} />
            {renderImg(imageLPWeb)}
          </Col>
          <Col xs={12}>
            <FileInput
              label={<Translate id='IMAGE_LP_MOBILE' />}
              accept='image/*'
              input={{
                value: imageLPMobile,
                onChange: setImageLPMobile
              }}
              meta={{
                invalid: fileError,
                submitFailed: failed,
                error: <Translate id='MISSING_FILE' />
              }}
            />
            <Margin size={23} />
            {renderImg(imageLPMobile)}
          </Col>
        </Row>
      </PageForm>
    </>
  )
}

ManageBanner.propTypes = {
  router: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  handleSubmit: PropTypes.func
}

ManageBanner.defaultProps = {
  dispatch: undefined,
  handleSubmit: undefined
}

const ManageBannerPage = reduxForm({
  form: 'banner_form'
  // validate: validations
})(ManageBanner)

export default withLocalize(
  connect((state) => ({
    bannersType: state.info.bannersType
  }))(ManageBannerPage)
)
