import React, { Component } from 'react'

import { Row, Col } from 'antd'
import PropTypes from 'prop-types'
import { Translate, withLocalize } from 'react-localize-redux'

import PlusIcon from '../../../assets/icons/add_white.svg'
import { GetContactListFalse } from '../../../infra/requests/ContactRequests'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import SearchInput from '../../../shared/components/inputs/SearchInput'
import BaseTable from '../../../shared/components/table/BaseTable'
import ConstructQuery from '../../../shared/logic/queries/EndpointQueries'
import { Margin } from '../../../shared/styles/BasicStyles'

let timeout

class Contacts extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rows: [],
      page: 1,
      pageSize: 500,
      total: 0,
      filters: { search: '' },
      loading: true
    }
    this.columns = [
      {
        dataIndex: 'name',
        title: <Translate id='NAME' />
      },
      {
        dataIndex: 'email',
        title: <Translate id='EMAIL' />
      },
      {
        dataIndex: 'b2bclient',
        title: <Translate id='CLIENT' />,
        render: (value) => (value && value[0] ? value[0].name : 'N/A')
      }
    ]
  }

  componentDidMount() {
    this.updateTable()
  }

  updateTable = async () => {
    const { filters, page, pageSize } = this.state
    this.setState({ loading: true })
    const { data } = await GetContactListFalse(
      page,
      pageSize,
      ConstructQuery(filters)
    )

    this.setState({
      rows: data?.items,
      total: data?.totalItems,
      loading: false
    })
  }

  onChangePagination = (page) => this.setState({ page }, this.updateTable)

  handleSearch = (value) => {
    this.onChangePagination(1)
    this.setState({ filters: { search: value } }, () => {
      if (timeout) clearTimeout(timeout)
      timeout = setTimeout(this.updateTable, 1000)
    })
  }

  render() {
    const { router, translate } = this.props
    const { rows, filters, page, pageSize, total, loading } = this.state

    return (
      <>
        <Row gutter={[24, 16]}>
          <Col xs={12} md={6} lg={6}>
            <BaseButton
              auto
              imagePath={PlusIcon}
              onClick={() => router.history.push('/contacts/new')}
            >
              <Translate id='NEW_CONTACT' />
            </BaseButton>
          </Col>
          <Col
            xs={12}
            md={{ span: 6, offset: 12 }}
            lg={{ span: 6, offset: 12 }}
          >
            <SearchInput
              input={{
                value: filters.search,
                onChange: (value) => this.handleSearch(value),
                onSearch: this.updateDatasource
              }}
              placeholder={translate('SEARCH')}
            />
          </Col>
        </Row>
        <Margin size='16' />
        <Row gutter={[24, 16]}>
          <Col xs={24}>
            <BaseTable
              datasource={rows}
              columns={this.columns}
              pagination={{
                render: true,
                page,
                pageSize,
                total,
                onChangePagination: this.onChangePagination
              }}
              isWhiteTable
              onClickRow={(item) =>
                router.history.push(`/contacts/${item.contactId}`)
              }
              rowKey='contactId'
              loading={loading}
            />
          </Col>
        </Row>
      </>
    )
  }
}

Contacts.propTypes = {
  router: PropTypes.object.isRequired
}

export default withLocalize(Contacts)
