import React, { Component } from 'react'

import { Row, Col } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'

import Download from '../../../../assets/icons/download_file.svg'
import { GetNewOrders } from '../../../../infra/requests/OrdersB2CRequests'
import BaseButton from '../../../../shared/components/buttons/BaseButton'
import DateRangeInput from '../../../../shared/components/inputs/DateRangeInput'
import SearchInput from '../../../../shared/components/inputs/SearchInput'
import SelectInput from '../../../../shared/components/inputs/SelectInput'
import TableOrderStatus from '../../../../shared/components/orderStatus/TableOrderStatus'
import BaseTable from '../../../../shared/components/table/BaseTable'
import GetObjectByID from '../../../../shared/logic/arrays/GetObjectByID'
import { ConstructQuery, ConstructSort } from '../../../../shared/logic/queries/EndpointQueries'
import { Margin } from '../../../../shared/styles/BasicStyles'
import CheckIfReturn from '../../helpers/CheckIfReturn'
import { NewOrderStatusList } from '../../helpers/OrderStatusFilters'
import AdvancedB2CSearch from '../AdvancedB2CSearch'

let timeout

class NewOrdersTab extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      rows: [],
      page: 1,
      pageSize: 10,
      total: 0,
      selectedOrders: [],
      filters: { search: '' },
      dateRange: undefined,
      sort: undefined,
      ReturnType: undefined, // returnType is defined here
    }
  }



  getColumns = (type) => {
    const { paymentMethods } = this.props

    const columns = [
      {
        dataIndex: 'orderId',
        type: 'ordered',
        sorter: true,
        title: <Translate id='ORDER_NMR' />,
        render: (value, row) => (row.number && row.number != 'NULL') ? row.number : value
      },
      {
        dataIndex: 'date',
        type: 'ordered',
        sorter: true,
        title: <Translate id='DATE' />,
        render: (date) => moment(date).format('DD/MM/YYYY')
      },
      {
        dataIndex: 'deliveryCountry',
        render: (country) => country?.name,
        title: <Translate id='COUNTRY' />
      },
      {
        dataIndex: 'userEmail',
        title: <Translate id='NAME' />
      },
      {
        dataIndex: 'totalQuantity',
        title: <Translate id='PRODUCT_QTY' />
      },
      {
        dataIndex: 'totalValue',
        title: <Translate id='AMOUNT' />,
        render: (value) => `${value}€`
      },
      {
        dataIndex: 'paymentMethod',
        type: 'ordered',
        sorter: true,
        title: <Translate id='PAYMENT_METHOD' />,
        render: (value) =>
          GetObjectByID(paymentMethods, 'paymentMethodID', value)?.name
      },
      {
        dataIndex: 'orderStatus',
        type: 'ordered',
        sorter: true,
        title: <Translate id='ORDER_STATUS' />,
        render: (data) => <TableOrderStatus type={type} id={data?.orderStatusId} paid={data} />
      }
    ]

    return columns
  }

  componentDidMount = async () => {
    try {
      this.updateTable()
    } catch (error) {
      console.warn(error)
    }
  }

  updateTable = async () => {
    const { page, pageSize, filters, sort, ReturnType } = this.state
    this.setState({ loading: true })

    const { data } = await GetNewOrders(
      false,
      page,
      pageSize,
      ConstructQuery(filters),
      ConstructSort(sort),
    );
    this.setState({
      rows: data?.items,
      total: data?.totalItems,
      loading: false
    })
  }

  onChangePagination = (page) => this.setState({ page }, this.updateTable)

  onRowSelection = (selectedOrders) => this.setState({ selectedOrders })

  onChangeSort = (sort) => this.setState({ sort }, this.updateTable)

  setFilters = (values, time, ReturnType) => {
    this.setState({ filters: values, page: 1, ReturnType }, () => {
      if (timeout) clearTimeout(timeout)
      timeout = setTimeout(this.updateTable, time)
    })
  }

  render() {
    const {
      loading,
      selectedOrders,
      rows,
      filters,
      dateRange,
      page,
      pageSize,
      total
    } = this.state
    const { orderStatus } = this.props

    const count = 0

    const type = "b2c"

    return (
      <>
        <Row gutter={[24, 16]}>
          <Col style={{ display: "flex", justifyContent: "end" }} xs={24} md={12} lg={8}>
            <SearchInput
              input={{
                value: filters.search,
                onChange: (value) =>
                  this.setFilters({ ...filters, search: value }, 1000),
                onSearch: () => this.setFilters({ ...filters }, 0)
              }}
            />
          </Col>

          <Col style={{ display: "flex", justifyContent: "center" }} xs={8} md={6} lg={4}>
            <AdvancedB2CSearch count={count} filters={filters} setFilters={this.setFilters} />
          </Col>
        </Row >
        <Margin size={24} />
        <Row>
          <Col xs={24}>
            <BaseTable
              rowKey='orderId'
              datasource={rows}
              columns={this.getColumns(type)}
              sortCallback={this.onChangeSort}
              pagination={{
                render: true,
                page,
                pageSize,
                total,
                onChangePagination: this.onChangePagination
              }}
              rowSelection={{
                selected: selectedOrders,
                onRowSelection: this.onRowSelection
              }}
              linkAddress={(row) => {
                if (CheckIfReturn(row)) {
                  return `/returns/b2c/${row.orderId}`
                }
                return `/orders/b2c/${row.orderId}`
              }}
              loading={loading}
            />
          </Col>
        </Row>
      </>
    )
  }
}

NewOrdersTab.propTypes = {
  orderStatus: PropTypes.array.isRequired,
  paymentMethods: PropTypes.array.isRequired
}

const mapStateToProps = (state) => ({
  orderStatus: state.info.orderStatus,
  paymentMethods: state.info.paymentMethods
})

export default connect(mapStateToProps)(NewOrdersTab)
