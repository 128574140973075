import React from 'react'

import {CaretRightOutlined} from '@ant-design/icons'
import {Collapse, Row, Col, Checkbox, Tooltip} from 'antd'
import styled from 'styled-components'

import {
  BorderColor,
  TableHeaderColor,
  TableTextColor,
  PrimaryButtonColor
} from '../../../../../shared/styles/_colors'

const {Panel} = Collapse

export const TableRow = styled(Row)`
  padding: 5px 0;
  border: 1px solid ${BorderColor};
  background: ${({$header}) => ($header ? '#FBFBFB' : 'transparent')};
  margin-bottom: 3px;
  border-radius: 5px;
`

export const ListRow = styled(Row)`
  padding: 5px 0;
  border-bottom: 1px solid ${BorderColor};

  &:last-child {
    border-bottom: 1px solid transparent;
  }
`

export const ListCol = styled(Col)``

const Title = styled.div`
  padding: 0 3px;
  border-left: 1px solid ${BorderColor};
  color: ${TableHeaderColor};
  font-size: 14px;
  line-height: 22px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 22px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
`

export const TableTitle = styled(({text}) => (
  <Tooltip title={text} mouseEnterDelay={0.2} placement='topLeft'>
    <Title>{text}</Title>
  </Tooltip>
))``

export const TableText = styled.div`
  position: relative;
  padding: 0 3px;
  border-left: 1px solid
    ${({$first}) => ($first ? 'transparent' : BorderColor)};
  color: ${TableTextColor};
  font-size: 14px;
  line-height: 22px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 22px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
  text-align: ${({$align}) => $align || 'center'};
`

export const TableInput = styled.div`
  padding: 0 3px;
  border-left: 1px solid ${BorderColor};
  text-align: ${({$left}) => ($left ? 'left' : 'center')};
`

export const CompositionCollapse = styled(Collapse)`
  margin-top: 5px;
  margin-bottom: -6px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-right: -1px;
  margin-left: -1px;

  &&& {
    .ant-collapse-header {
      padding: 5px;
      color: ${TableTextColor};
      background: white;
    }
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0;
      background: #f8f8f8;
    }
  }
`

export const CompositionPanel = styled(Panel)``

export const CollapseIcon = styled(CaretRightOutlined)`
  color: ${PrimaryButtonColor};
`

const CheckboxContainer = styled.div`
  width: 100%;
  text-align: center;
  display: inline-block;
`

export const ListCheck = styled((props) => (
  <CheckboxContainer>
    <Checkbox {...props} />
  </CheckboxContainer>
))``

export const CompositionFloatButton = styled.div`
  float: ${({$right}) => ($right ? 'right' : 'left')};
  margin-right: ${({$right}) => ($right ? '0' : '15')}px;
  margin-left: ${({$right}) => ($right ? '15' : '0')}px;
  margin-top: 10px;
`

export const TextTooltip = styled(({text, first, align = 'left'}) => (
  <Tooltip title={text} mouseEnterDelay={1} placement='topLeft'>
    <TableText $first={first} $align={align}>
      {text}
    </TableText>
  </Tooltip>
))``

export const EmptyTable = styled.div`
  display: flex;
  border: 1px solid ${BorderColor};
  padding: 30px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 4px;
`

export const EmptyTableMessage = styled.div`
  color: rgb(35, 31, 32);
  font-size: 16px;
  line-height: 19px;
  opacity: 0.5;
  text-align: center;
  margin-bottom: 29px;
`

export const EmptyTableIcon = styled.img`
  width: 92px;
`

export const ColorFlex = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    & > div {
        margin-right: 10px;
    }
`
