import React, {useEffect, useState} from 'react'

import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {useParams} from 'react-router'
import {
  reduxForm,
  initialize,
  getFormValues,
  FieldArray,
  Field
} from 'redux-form'

import Trash from '../../../assets/icons/remove_white.svg'
import {
  CreateService,
  DeleteService,
  EditService,
  GetService
} from '../../../infra/requests/ProductsRequests'
import FormValidator from '../../../infra/services/validations/FormValidator'
import AlertService from '../../../shared/components/alert/AlertService'
import BackButton from '../../../shared/components/buttons/BackButton'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import DeleteButton from '../../../shared/components/buttons/DeleteButton'
import SwitchInput from '../../../shared/components/inputs/SwitchInput'
import TextInput from '../../../shared/components/inputs/TextInput'
import BaseLoading from '../../../shared/components/loading/BaseLoading'
import ActiveTranslation from '../../../shared/logic/translations/ActiveTranslation'
import {
  PageForm,
  PageContent,
  Margin,
  PageTitle
} from '../../../shared/styles/BasicStyles'
import ServiceTranslations from './components/ServiceTranslations'

const validations = (values) => {
  const errors = FormValidator.make({
    code: 'required',
    reference: 'required',
    externalCode: 'required',
    serviceTranslation: 'required|array'
  })(values)

  if (values.serviceTranslation && values.serviceTranslation.length) {
    errors.serviceTranslation = []
    values.serviceTranslation.forEach((lang, index) => {
      if (!lang.name || lang.name == '') {
        errors.serviceTranslation[index] = {name: {_error: 'REQUIRED'}}
      }
    })
  }

  return errors
}

const ManageServicePage = ({
  router,
  handleSubmit,
  dispatch,
  translate
}) => {
  const [loading, setLoading] = useState(true)
  const [name, setName] = useState(<Translate id='NEW_SERVICE' />)
  const [hasFetchedService, setHasFetchedService] = useState(false)
  const {id} = useParams()

  useEffect(() => {
    async function fetchService() {
      if (id !== 'new') {
        const {data, success} = await GetService(id)

        if (success) {
          dispatch(initialize('manage_service', data))
          setName(
            <ActiveTranslation
              value={data?.serviceTranslation}
              tag='name'
            />
          )
        }
      }
      setHasFetchedService(true)
      setLoading(false)
    }
    if (!hasFetchedService) {
      fetchService()
    }
  }, [hasFetchedService, dispatch])

  const onSubmit = async (values) => {
    try {
      if (id !== 'new') {
        const {success} = await EditService(id, values)

        if (success) {
          AlertService.success(
            translate('SUCCESS'),
            translate('SERVICE_EDITED_SUCESSFULLY')
          )
          router.history.push('/products#services')
        }
      } else {
        const {success} = await CreateService(values)

        if (success) {
          AlertService.success(
            translate('SUCCESS'),
            translate('SERVICE_CREATED_SUCESSFULLY')
          )
          router.history.push('/products#services')
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const deleteService = async () => {
    try {
      const {success} = await DeleteService(id)

      if (success) {
        router.history.push('/products#services')
      }
    } catch (error) {
      console.log(error)
    }
  }

  if (loading) return <BaseLoading margin={200} />

  return (
    <PageForm $margin='20px 0px 0px 0px' onSubmit={handleSubmit(onSubmit)}>
      <PageContent $noMargin $noMinHeight>
        <Row align='bottom' gutter={[0, 50]}>
          <Col xs={24}>
            <BackButton
              label={<Translate id='BACK_SERVICES' />}
              onClick={() => router.history.push('/products#services')}
            />
          </Col>
          <Col xs={12}>
            <PageTitle>{name}</PageTitle>
          </Col>
          <Col xs={12} align='end'>
            <BaseButton htmlType='submit' type='primary' auto>
              <Translate id='SAVE' />
            </BaseButton>
          </Col>
        </Row>
        <Margin size={24} />
        <Margin size={15} />
        {id !== 'new' && (
          <>
            <Row>
              <Col xs={6}>
                <DeleteButton
                  imagePath={Trash}
                  type='error'
                  auto
                  onClick={() => deleteService()}
                >
                  <Translate id='DELETE_SERVICE' />
                </DeleteButton>
              </Col>
            </Row>
            <Margin size={38} />
          </>
        )}
        <Row gutter={[40, 20]}>
          <Col xs={12}>
            <Field
              component={TextInput}
              name='code'
              label={<Translate id='CODE' />}
            />
          </Col>
          <Col xs={12}>
            <Field
              component={TextInput}
              name='reference'
              label={<Translate id='REFERENCE' />}
            />
          </Col>
          <Col xs={12}>
            <Field
              component={TextInput}
              name='externalCode'
              label={<Translate id='EXTERNAL_CODE' />}
            />
          </Col>
          <Col xs={6}>
            <Field
              component={SwitchInput}
              name='definesMoq'
              label={<Translate id='DEFINES_MOQ' />}
              disabled
            />
          </Col>
          <Col xs={6}>
            <Field
              component={SwitchInput}
              name='active'
              label={<Translate id='STATE' />}
            />
          </Col>
          <Col xs={24}>
            <FieldArray
              name='serviceTranslation'
              component={ServiceTranslations}
              dispatch={dispatch}
            />
          </Col>
        </Row>
      </PageContent>
    </PageForm>
  )
}

ManageServicePage.propTypes = {
  router: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  handleSubmit: PropTypes.func
}

ManageServicePage.defaultProps = {
  dispatch: undefined,
  handleSubmit: undefined
}

const myComponent = reduxForm({
  form: 'manage_service',
  validate: validations
})(ManageServicePage)

export default withLocalize(
  connect((state) => ({
    currentFormValues: getFormValues('manage_service')(state),
    suppliersRawMaterial: state.info.suppliersRawMaterial,
    rawMaterialFamily: state.info.rawMaterialFamily
  }))(myComponent)
)
