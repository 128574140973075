import {
  LeftOutlined,
  RightOutlined,
  CalendarOutlined
} from '@ant-design/icons'
import {Col} from 'antd'
import styled from 'styled-components'

import {
  PrimaryColor,
  TableTextColor,
  BorderColor,
  SecondaryButtonColor,
  NotSeenColor,
  ErrorColor,
  SuccessColor
} from '../../styles/_colors'
import {BodyText} from '../../styles/_texts'

export const BaseCalendarContainer = styled.div`
  display: inline-block;
  width: 100%;
  position: relative;
`

export const CenteredCol = styled(Col)`
  text-align: center;
`

export const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`

export const DisableLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: #f8f8f8;
  opacity: 0.4;
`

export const DatePickerSection = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .ant-picker {
    padding: 0;
  }

  input {
    display: none;
  }
  .ant-picker-suffix {
    pointer-events: auto;
  }
`

export const DateSelectorSection = styled.div`
  position: relative;
  display: inline-block;
  padding: 0 40px;
  text-align: center;
  font-size: 21px;
  color: ${TableTextColor};
`

export const DateLabel = styled.div`
  display: inline-block;
  text-align: center;
  font-size: 21px;
  color: ${TableTextColor};
  opacity: 0.5;
  cursor: pointer;
  font-weight: bold;
`

export const CalendarIcon = styled(CalendarOutlined)`
  background: ${SecondaryButtonColor};
  padding: 8px;
  color: ${({$active}) => ($active ? 'white' : TableTextColor)};
  border-radius: 50%;
  cursor: pointer;
  font-weight: bold;
  opacity: ${({$active}) => ($active ? 1 : 0.5)};
  margin-left: 10px;
`

export const PreviousDate = styled(LeftOutlined)`
  cursor: pointer;
  font-size: 16px;
  padding: 4px;
  margin-right: 15px;
`

export const NextDate = styled(RightOutlined)`
  cursor: pointer;
  font-size: 16px;
  padding: 4px;
  margin-left: 15px;
`

export const WeekDayLabel = styled.div`
  padding: 20px 0;
  text-align: center;
  font-size: ${BodyText};
  color: ${TableTextColor};
  opacity: 0.5;
`

export const HeaderCell = styled.div`
  padding: 10px 0;
  font-size: ${BodyText};
  color: ${({$today}) => ($today ? 'white' : TableTextColor)};
  text-align: center;
  border: 1px solid ${BorderColor};
  border-left: ${({$first}) =>
    $first ? `1px solid ${BorderColor}` : 'none'};
  background: ${({$today}) => ($today ? PrimaryColor : 'transparent')};
`

export const EmptyHeader = styled.div`
  margin-top: 105px;
  border-bottom: 1px solid ${BorderColor};
`

export const TimeSlot = styled.div`
  font-size: ${BodyText};
  color: ${TableTextColor};
  opacity: 0.5;
  text-align: center;
  padding: 6px 0;
  border-bottom: 1px solid ${BorderColor};
`

export const CalendarSlot = styled.div`
  position: relative;
  font-size: ${BodyText};
  color: ${TableTextColor};
  height: 35px;
  border-bottom: 1px solid ${BorderColor};
  border-right: 1px solid ${BorderColor};
  background: ${({$available}) => ($available ? NotSeenColor : 'white')};
  border-left: ${({$first}) =>
    $first ? `1px solid ${BorderColor}` : 'none'};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 3px;
`

export const SlotHover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  opacity: 0;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  font-size: 20px;

  &:hover {
    opacity: 1;
  }
`

export const CurrentButton = styled.div`
  background: white;
  color: ${TableTextColor};
  border: 1px solid ${BorderColor};
  padding: 5px 10px;
  text-align: center;
  opacity: 0.5;
  cursor: pointer;
`

export const ScheduleLine = styled.div`
  display: inline-block;
  width: 100%;
  background: white;
  border-radius: 5px;
  border-left: 5px solid
    ${({$passed, $concluded}) =>
      $concluded ? SuccessColor : $passed ? ErrorColor : PrimaryColor};
  padding: 2px 5px;
  cursor: default;
`
export const ScheduleName = styled.div`
  font-size: 12px;
  color: ${PrimaryColor};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 18px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`

export const PopoverLine = styled.div`
  color: ${({$title}) => ($title ? PrimaryColor : TableTextColor)};
  b {
    color: ${TableTextColor};
  }
`

export const PopoverLink = styled.div`
  margin-top: 10px;
  text-align: center;
  color: white;
  background: ${PrimaryColor};
  padding: 3px 8px;
  border-radius: 10px;
  cursor: pointer;
`
