import React, { useState, useEffect } from 'react'

import { Col, Row } from 'antd'
import PropTypes from 'prop-types'
import { Translate, withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { reduxForm, initialize } from 'redux-form'

import {
  CreateWorkgroup,
  EditWorkgroupED,
  EditWorkgroupInformation,
  EditWorkgroupModulation,
  EditWorkgroupRelatedProducts,
  GetWorkgroup,
  EditWorkgroupImage
} from '../../../infra/requests/ClientRequests'
import {
  GetEDProducts,
  GetRelatedProducts
} from '../../../infra/requests/ProductsRequests'
import AlertService from '../../../shared/components/alert/AlertService'
import BackButton from '../../../shared/components/buttons/BackButton'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import BaseLoading from '../../../shared/components/loading/BaseLoading'
import {
  ContentContainer,
  Margin,
  PageForm,
  StyledTabPane,
  StyledTabs,
  StyledTabTitle,
  StyledTabTitleContainer
} from '../../../shared/styles/BasicStyles'
import { PageTitle } from '../ClientStyles'
import Dotation from './Dotation'
import Information from './Information'
import Modulations from './Modulations'
import RelatedProducts from './RelatedProducts'

const validations = (values) => {
  const error = {}
  error.positionInformationSection = {}
  if (!values.positionInformationSection?.name) {
    error.positionInformationSection.name = {
      _error: 'NAME_REQUIRED'
    }
  }
  if (!values.positionInformationSection?.orderQuantity) {
    error.positionInformationSection.orderQuantity = {
      _error: 'ORDERS_PERIOD_REQUIRED'
    }
  }
  if (!Number(values.positionInformationSection?.orderQuantity)) {
    error.positionInformationSection.orderQuantity = {
      _error: 'ORDERS_PERIOD_INVALID'
    }
  }

  if (!values.positionInformationSection?.orderPeriod) {
    error.positionInformationSection.orderPeriod = {
      _error: 'RENOVATION_ORDERS_REQUIRED'
    }
  }
  if (!Number(values.positionInformationSection?.orderPeriod)) {
    error.positionInformationSection.orderPeriod = {
      _error: 'RENOVATION_ORDERS_INVALID'
    }
  }
  return error
}

const ManageWorkgroupPage = ({
  router,
  dispatch,
  handleSubmit,
  translate
}) => {
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [name, setName] = useState(<Translate id='NEW_WORKGROUP' />)
  const [relatedProductsList, setRelatedProductsList] = useState([])
  const [EDProductsList, setEDProductsList] = useState([])
  const [picture, setPicture] = useState(undefined)
  const [video, setVideo] = useState(undefined)
  const [fileError, setFileError] = useState(false)
  const [failed, setFailed] = useState(false)
  const { client, id } = useParams()

  useEffect(() => {
    async function init() {
      if (id) {
        const { data, success } = await GetWorkgroup(id)

        if (success) {
          setName(data?.positionInformationSection?.name)
          setPicture(data?.positionInformationSection?.url)
          dispatch(initialize('manage_workgroup', data || {}))
        }
      }
      const resultRelatedProducts = await GetRelatedProducts(client)
      setRelatedProductsList(resultRelatedProducts?.data?.items || [])

      const resultEDProducts = await GetEDProducts(client)
      setEDProductsList(resultEDProducts?.data?.items || [])
      setLoading(false)
    }
    init()
  }, [])


  const changePicture = async () => {
    try {
      if (picture?.name || picture?.path) {
        const payload = new FormData()
        payload.append('File', picture)
        const { data, success } = await EditWorkgroupImage(id, payload)
        if (success) {
          setPicture(data?.positionInformationSection?.url)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onSubmit = async (values) => {
    setSaving(true)
    let idResult = id
    try {
      if (id) {
        // INFORMATION
        const informationData = {
          name: values?.positionInformationSection?.name,
          orderQuantity: values?.positionInformationSection?.orderQuantity,
          orderPeriod: values?.positionInformationSection?.orderPeriod,
          b2bclientId: values?.positionInformationSection?.b2bclientId,
          active: values?.positionInformationSection?.active,
          freeDotationChoice: values?.positionInformationSection?.freeDotationChoice,
          slaId: values?.positionInformationSection?.sla?.order
        }

        const resultInfo = await EditWorkgroupInformation(
          id,
          informationData
        )
        if (resultInfo.success) {
          idResult = id
        }
      } else {
        const data = {
          name: values?.positionInformationSection?.name,
          orderQuantity: values?.positionInformationSection?.orderQuantity,
          orderPeriod: values?.positionInformationSection?.orderPeriod,
          pickupRate: values?.positionModulationsSection?.pickupRate,
          active: values?.positionInformationSection?.active
            ? values?.positionInformationSection?.active
            : false,
          freeDotationChoice: values?.positionInformationSection?.freeDotationChoice ? values?.positionInformationSection?.freeDotationChoice
            : false,
          slaId: values?.positionInformationSection?.sla?.order
        }

        const resultInfo = await CreateWorkgroup(client, data)

        if (resultInfo.success) {
          idResult = resultInfo.data.positionId
          AlertService.success(
            translate('SUCCESS'),
            translate('WORKGROUP_CREATED_SUCCESSFULLY')
          )
          router.history.push(`/clients/${client}#workgroup`)
        }
      }

      // MODULATIONS
      const employeePlan = []
      const positionModulation = []

      if (values?.positionModulationsSection?.employeePlan) {
        values.positionModulationsSection.employeePlan.forEach((elem) => {
          employeePlan.push({
            employeePlanId: elem.employeePlanId,
            contractTypeId: elem.contractTypeId,
            total: elem.total,
            dateFrom: elem.dateFrom,
            dateTo: elem.dateTo,
            contractEnd: elem.contractEnd
          })
        })
      }

      if (values?.positionModulationsSection?.employeePlan?.length > 0) {
        values.positionModulationsSection.employeePlan.forEach((elem) =>
          employeePlan.push({
            employeePlanId: elem.employeePlanId,
            contractTypeId: elem.contractTypeId,
            total: elem.total,
            dateFrom: elem.dateFrom,
            dateTo: elem.dateTo,
            contractEnd: elem.contractEnd
          })
        )
      }

      if (
        values?.positionModulationsSection?.positionModulation?.length > 0
      ) {
        values.positionModulationsSection.positionModulation.forEach(
          (elem) =>
            positionModulation.push({
              positionModulationId: elem.positionModulationId,
              modulationTypeId: elem.modulationTypeId,
              rate: elem.rate,
              weight: elem.rateB,
              rateB: elem.rateB,
              dateFrom: elem.dateFrom,
              dateTo: elem.dateTo,
              active: elem.active
            })
        )
      }

      const modulationsData = {
        pickupRate: values?.positionModulationsSection?.pickupRate,
        employeePlan,
        positionModulation
      }

      // ED
      const ED = []

      if (values?.positionEDSection?.positionProduct?.length > 0) {
        values.positionEDSection.positionProduct.forEach((elem) =>
          ED.push({
            productId: elem.productId,
            quantity: elem.quantity,
            time: elem.time,
            order: elem.order,
            active: elem.active
          })
        )
      }

      const EDData = {
        positionProduct: ED
      }

      // RELATED PRODUCTS
      const relatedProducts = []

      if (
        values?.positionRelatedProductsSection?.positionProductRelation
          ?.length > 0
      ) {
        values.positionRelatedProductsSection.positionProductRelation.forEach(
          (elem) =>
            relatedProducts.push({
              productId: elem.productId,
              active: elem.active
            })
        )
      }

      const relatedProductsData = {
        positionProductRelation: relatedProducts
      }
      const resultModulation = await EditWorkgroupModulation(
        idResult,
        modulationsData
      )

      const resultED = await EditWorkgroupED(idResult, EDData)

      const resultRelated = await EditWorkgroupRelatedProducts(
        idResult,
        relatedProductsData
      )

      changePicture(idResult)

      if (
        resultModulation?.success &&
        resultED?.success &&
        resultRelated?.success
      ) {
        AlertService.success(
          translate('SUCCESS'),
          id
            ? translate('WORKGROUP_EDITTED_SUCCESSFULLY')
            : translate('WORKGROUP_CREATED_SUCCESSFULLY')
        )
        router.history.push(`/clients/${client}#workgroup`)
      }
      setSaving(false)
    } catch (error) {
      console.log(error)
    }
  }

  if (loading) return <BaseLoading margin='200' />

  return (
    <ContentContainer>
      <PageForm $margin='0' onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs={24}>
            <BackButton
              label={<Translate id='BACK_WORKGROUPS' />}
              onClick={() =>
                router.history.push(`/clients/${client}#workgroup`)
              }
            />
          </Col>
        </Row>
        <Margin size={29} />
        <Row xs={24}>
          <Col xs={12}>
            <PageTitle>{name}</PageTitle>
          </Col>
          <Col xs={12} align='end'>
            <BaseButton auto htmlType='submit' loading={saving}>
              <Translate id='SAVE' />
            </BaseButton>
          </Col>
        </Row>
        <Margin size={37} />
        <StyledTabs>
          <StyledTabPane
            tab={
              <StyledTabTitleContainer>
                <StyledTabTitle smaller>
                  <Translate id='INFORMATION' />
                </StyledTabTitle>
              </StyledTabTitleContainer>
            }
            key={1}
          >
            <Information
              id={id}
              picture={picture}
              setPicture={setPicture}
              video={video}
              fileError={fileError}
              setFileError={setFileError}
              failed={failed}
              setVideo={setVideo}
            />
          </StyledTabPane>
          <StyledTabPane
            tab={
              <StyledTabTitleContainer>
                <StyledTabTitle smaller>
                  <Translate id='MODULATIONS' />
                </StyledTabTitle>
              </StyledTabTitleContainer>
            }
            key={3}
          >
            <Modulations />
          </StyledTabPane>
          <StyledTabPane
            tab={
              <StyledTabTitleContainer>
                <StyledTabTitle smaller>
                  <Translate id='ENTITLEMENT_DOTATION' />
                </StyledTabTitle>
              </StyledTabTitleContainer>
            }
            key={4}
          >
            <Dotation products={EDProductsList} router={router} />
          </StyledTabPane>
          <StyledTabPane
            tab={
              <StyledTabTitleContainer>
                <StyledTabTitle smaller>
                  <Translate id='RELATED_PRODUCTS' />
                </StyledTabTitle>
              </StyledTabTitleContainer>
            }
            key={5}
          >
            <RelatedProducts
              products={relatedProductsList}
              router={router}
            />
          </StyledTabPane>
        </StyledTabs>
      </PageForm>
    </ContentContainer>
  )
}

ManageWorkgroupPage.propTypes = {
  router: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func
}

ManageWorkgroupPage.defaultProps = {
  handleSubmit: undefined
}

const myComponent = reduxForm({
  form: 'manage_workgroup',
  validate: validations
})(ManageWorkgroupPage)

export default withLocalize(connect()(myComponent))
