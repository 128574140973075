import React, {useEffect, useState} from 'react'

import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {useParams} from 'react-router-dom'
import {reduxForm, initialize, Field, getFormValues} from 'redux-form'

import {
  GetCarrierDetail,
  CreateNewCarrier,
  SaveCarrier
} from '../../../infra/requests/LogisticsRequests'
import FormValidator from '../../../infra/services/validations/FormValidator'
import BackButton from '../../../shared/components/buttons/BackButton'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import HourInput from '../../../shared/components/inputs/HourInput'
import SelectInput from '../../../shared/components/inputs/SelectInput'
import SwitchInput from '../../../shared/components/inputs/SwitchInput'
import TextInput from '../../../shared/components/inputs/TextInput'
import BaseLoading from '../../../shared/components/loading/BaseLoading'
import {
  Margin,
  PageForm,
  PageTitle
} from '../../../shared/styles/BasicStyles'

const validations = FormValidator.make({
  name: 'required',
  email: 'email|required',
  addressLine1: 'required',
  zipCode: 'required',
  city: 'required',
  region: 'required',
  countryId: 'required'
})

const ManageCarrier = ({
  router,
  dispatch,
  handleSubmit,
  formValues,
  countries, translate
}) => {
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const {id} = useParams()

  useEffect(() => {
    async function init() {
      if (id && id !== 'new') {
        const {data} = await GetCarrierDetail(id)
        dispatch(
          initialize('carrier_form', {
            name: data?.name,
            carrierId: data?.carrierId,
            active: data?.active,
            hasIntegration: data?.hasIntegration,
            pickupStartHours: data?.pickupStartHours,
            pickupEndHours: data?.pickupEndHours,
            trackingNumber: data?.trackingNumber,
            addressLine1: data?.contact?.contactDetail[0]?.addressLine1,
            addressLine2: data?.contact?.contactDetail[0]?.addressLine2,
            addressLine3: data?.contact?.contactDetail[0]?.addressLine3,
            streetNumber: data?.contact?.contactDetail[0]?.streetNumber,
            zipCode: data?.contact?.contactDetail[0]?.zipCode,
            city: data?.contact?.contactDetail[0]?.city,
            region: data?.contact?.contactDetail[0]?.region,
            countryId: data?.contact?.contactDetail[0]?.countryId,
            email: data?.contact?.contactDetail[0]?.email,
            telephone: data?.contact?.contactDetail[0]?.telephone,
            mobile: data?.contact?.contactDetail[0]?.mobile
          })
        )
      } else dispatch(initialize('carrier_form', {}))
      setLoading(false)
    }
    init()
  }, [])

  const onSubmit = async (values) => {
    setSaving(true)
    if (id !== 'new') {
      const {success} = await SaveCarrier(id, values)
      setSaving(false)
      if (success) return router.history.push('/logistics/carriers')
    } else {
      const {success} = await CreateNewCarrier(values)
      setSaving(false)
      if (success) return router.history.push('/logistics/carriers')
    }
    return setSaving(false)
  }

  if (loading) return <BaseLoading margin='200' />

  return (
    <PageForm onSubmit={handleSubmit(onSubmit)} noValidate>
      <Row align='bottom' gutter={[0, 50]}>
        <Col xs={24}>
          <BackButton
            label={<Translate id='BACK' />}
            onClick={() => router.history.push('/logistics/carriers')}
          />
        </Col>
        <Col xs={12}>
          <PageTitle>
            {id !== 'new' ? formValues.name : <Translate id='NEW_CARRIER' />}
          </PageTitle>
        </Col>

        <Col xs={12} align='end'>
          <BaseButton
            htmlType='submit'
            type='primary'
            auto
            loading={saving}
            disabled={saving}
          >
            <Translate id='SAVE' />
          </BaseButton>
        </Col>
      </Row>
      <Margin size={40} />
      <Row gutter={[24, 24]}>
        <Col xs={6}>
          <Field
            label={<Translate id='NAME' />}
            placeholder={translate('CARRIER_NAME')}
            name='name'
            component={TextInput}
          />
        </Col>
        <Col xs={6}>
          <Field
            label={<Translate id='EMAIL' />}
            placeholder={translate('INSERT_CARRIER_EMAIL')}
            name='email'
            component={TextInput}
          />
        </Col>
        <Col xs={4}>
          <Field
            label={<Translate id='TELEPHONE' />}
            placeholder={translate('INSERT_TELEPHONE_NUMBER')}
            name='telephone'
            component={TextInput}
          />
        </Col>
        <Col xs={4}>
          <Field
            label={<Translate id='MOBILE' />}
            placeholder={translate('INSERT_MOBILE_NUMBER')}
            name='mobile'
            component={TextInput}
          />
        </Col>
        <Col xs={3} offset={1}>
          <Field
            component={SwitchInput}
            name='active'
            checkedText={<Translate id='YES' />}
            uncheckedText={<Translate id='NO' />}
            label={<Translate id='ACTIVE' />}
          />
        </Col>
        <Col xs={8}>
          <Field
            label={<Translate id='ADDRESS1' />}
            placeholder={translate('INSERT_ADDRESS')}
            name='addressLine1'
            component={TextInput}
          />
        </Col>
        <Col xs={8}>
          <Field
            label={<Translate id='LINE2' />}
            placeholder={translate('INSERT_ADDRESS')}
            name='addressLine2'
            component={TextInput}
          />
        </Col>
        <Col xs={8}>
          <Field
            label={<Translate id='LINE3' />}
            placeholder={translate('INSERT_ADDRESS')}
            name='addressLine3'
            component={TextInput}
          />
        </Col>
        <Col xs={4}>
          <Field
            label={<Translate id='STREET_NUMBER' />}
            placeholder={translate('INSERT_STREET_NUMBER')}
            name='streetNumber'
            component={TextInput}
          />
        </Col>
        <Col xs={4}>
          <Field
            label={<Translate id='ZIP_CODE' />}
            placeholder={translate('INSERT_ZIP_CODE')}
            name='zipCode'
            component={TextInput}
          />
        </Col>
        <Col xs={6}>
          <Field
            label={<Translate id='CITY' />}
            placeholder={translate('INSERT_CITY')}
            name='city'
            component={TextInput}
          />
        </Col>
        <Col xs={6}>
          <Field
            label={<Translate id='REGION' />}
            placeholder={translate('INSERT_REGION')}
            name='region'
            component={TextInput}
          />
        </Col>
        <Col xs={4}>
          <Field
            name='countryId'
            component={SelectInput}
            label={<Translate id='COUNTRY' />}
            placeholder={<Translate id='COUNTRY' />}
            data={countries}
            dataLabel='name'
            dataKey='countryID'
          />
        </Col>
      </Row>
      <Margin size='40' />
      <Row gutter={[24, 24]}>
        <Col xs={4}>
          <Field
            component={SwitchInput}
            name='hasIntegration'
            checkedText={<Translate id='YES' />}
            uncheckedText={<Translate id='NO' />}
            label={<Translate id='HAS_INTEGRATION' />}
          />
        </Col>
        <Col xs={8}>
          <Field
            label='Tracking Number'
            placeholder={translate('BASE_URL_FOR_TRACKING_PACKAGE')}
            name='trackingNumber'
            component={TextInput}
            disabled={!formValues?.hasIntegration}
          />
        </Col>
        <Col xs={6}>
          <Field
            label={<Translate id='PICKUP_START' />}
            placeholder={translate('SELECT_THE_PICKUP_START_HOURS')}
            name='pickupStartHours'
            component={HourInput}
            disabled={!formValues?.hasIntegration}
          />
        </Col>
        <Col xs={6}>
          <Field
            label={<Translate id='PICKUP_END' />}
            placeholder={translate('SELECT_THE_PICKUP_END_HOURS')}
            name='pickupEndHours'
            component={HourInput}
            disabled={!formValues?.hasIntegration}
          />
        </Col>
      </Row>
    </PageForm>
  )
}

ManageCarrier.propTypes = {
  router: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  formValues: PropTypes.object,
  countries: PropTypes.array.isRequired
}

ManageCarrier.defaultProps = {
  formValues: {}
}

const ManageCarrierPage = withLocalize(reduxForm({
  form: 'carrier_form',
  validate: validations
})(ManageCarrier))

export default connect((state) => ({
  formValues: getFormValues('carrier_form')(state),
  countries: state.info.countries
}))(ManageCarrierPage)
