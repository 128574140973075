import React, {Component} from 'react'

import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import {GetB2CProducts} from '../../../infra/requests/ProductsRequests'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import BaseList from '../../../shared/components/table/BaseList'
import {ConstructQuery} from '../../../shared/logic/queries/EndpointQueries'
import {Margin, StyledHR} from '../../../shared/styles/BasicStyles'
import ProductCard from '../components/ProductCard'
import ProductFilters from '../components/ProductFilters'

let timeout

class ProductsB2CTab extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      rows: [],
      page: 1,
      pageSize: 24,
      total: 0,
      filters: {}
    }
  }

  componentDidMount = async () => {
    try {
      this.updateTable()
    } catch (error) {
      console.warn(error)
    }
  }

  updateTable = async () => {
    const {page, pageSize, filters} = this.state
    this.setState({loading: true})
    const {data} = await GetB2CProducts(
      page,
      pageSize,
      ConstructQuery(filters)
    )
    this.setState({
      rows: data?.items,
      total: data?.totalItems,
      loading: false
    })
  }

  onChangePagination = (page) => this.setState({page}, this.updateTable)

  setFilters = (values, time) => {
    this.setState({filters: values, page: 1}, () => {
      if (timeout) clearTimeout(timeout)
      timeout = setTimeout(this.updateTable, time)
    })
  }

  render() {
    const {loading, rows, page, pageSize, total, filters} = this.state
    const {router} = this.props
    return (
      <>
        {/* <Row xs={24}>
          <Col xs={24} style={{textAlign: 'right'}}>
            <BaseButton
              type='secondary'
              onClick={() =>
                console.warn(<Translate id='EXPORT_TO_EXCEL' />)
              }
              auto
              // imagePath={Download}
            >
              <Translate id='EXPORT_VIEW_TO_EXCEL' />
            </BaseButton>
          </Col>
        </Row>
        <Margin size={29} />
        <StyledHR $space='20' /> */}
        <ProductFilters
          isB2c
          filters={filters}
          setFilters={this.setFilters}
        />
        <StyledHR $space='20' />
        <Row>
          <Col xs={24}>
            <BaseList
              grid={{
                gutter: 20,
                xs: 1,
                sm: 2,
                md: 4,
                lg: 4,
                xl: 6,
                xxl: 6
              }}
              datasource={rows}
              renderItem={(item) => <ProductCard product={item} />}
              pagination={{
                render: true,
                page,
                pageSize,
                total,
                onChangePagination: this.onChangePagination
              }}
              onClickRow={(item) =>
                router.history.push(`/products/${item.productId}`)
              }
              loading={loading}
            />
          </Col>
        </Row>
      </>
    )
  }
}

ProductsB2CTab.propTypes = {
  router: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  productTypes: state.info.productTypes,
  suppliers: state.info.suppliers,
  positions: state.info.positions,
  productCategories: state.info.productCategories,
  productStyles: state.info.productStyles,
  clients: state.info.clients
})

export default withLocalize(connect(mapStateToProps)(ProductsB2CTab))
