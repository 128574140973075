import React from 'react'

import {uniqBy} from 'lodash'
import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'

import ArrayToString from '../../../shared/logic/arrays/ArrayToString'
import GetCoverImage from '../../../shared/logic/arrays/ImageCoverFunction'
import ActiveTranslation from '../../../shared/logic/translations/ActiveTranslation'
import {TableHeaderColor} from '../../../shared/styles/_colors'
import {SemiBoldFontWeight} from '../../../shared/styles/_texts'
import {Margin, StyledFlex} from '../../../shared/styles/BasicStyles'
import {
  ProductImage,
  ProductRefContainer,
  ProductTitle,
  ProductDescription,
  ColorsContainer,
  SingleColorContainer,
  ProductColor,
  ProductCardSection
} from '../../orders/manage_b2b_b2b2c/components/Styles'
import DuplicatePopup, {PopupContainer} from './DuplicatePopup'

const DuplicateProductCard = ({product, disabled, selected, onClose}) => (
  <ProductCardSection $disabled={disabled}>
    <ProductRefContainer>
      <ProductTitle>
        <Translate id='REF' />
        :&ensp;
      </ProductTitle>
      <ProductDescription
        $weight={SemiBoldFontWeight}
        $color={TableHeaderColor}
      >
        {product?.referenceCode || <Translate id='NA' />}
      </ProductDescription>
    </ProductRefContainer>
    <ProductDescription>
      <ActiveTranslation
        value={product?.productTranslation || <Translate id='NA' />}
        tag='name'
      />
    </ProductDescription>
    <Margin size={10} />
    <ProductImage src={GetCoverImage(product?.productImage)} />
    <Margin size={15} />
    <ProductTitle>
      <Translate id='TYPE' />
    </ProductTitle>
    <ProductDescription>
      <ActiveTranslation
        value={
          product?.productType?.productTypeTranslation || (
            <Translate id='NA' />
          )
        }
        tag='name'
      />
    </ProductDescription>
    <Margin size={10} />
    <ProductTitle>
      <Translate id='COMPANY' />
    </ProductTitle>
    <ProductDescription>
      {product?.company || <Translate id='NA' />}
    </ProductDescription>
    <Margin size={10} />
    <ProductTitle>
      <Translate id='WORKGROUP' />
    </ProductTitle>
    <ProductDescription>
      {ArrayToString(product?.positionProductRelation)}
    </ProductDescription>
    <Margin size={10} />
    <ProductTitle>
      <Translate id='MAIN_CATEGORY' />
    </ProductTitle>
    <ProductDescription>
      <ActiveTranslation
        value={
          product?.mainCategory?.categoryTranslation || (
            <Translate id='NA' />
          )
        }
        tag='name'
      />
    </ProductDescription>
    <Margin size={10} />
    <ProductTitle>
      <Translate id='CATEGORY' />
    </ProductTitle>
    <ProductDescription>
      <ActiveTranslation
        value={
          product?.category?.categoryTranslation || <Translate id='NA' />
        }
        tag='name'
      />
    </ProductDescription>
    <Margin size={10} />
    <ProductTitle>
      <Translate id='SUB_CATEGORY' />
    </ProductTitle>
    <ProductDescription>
      <ActiveTranslation
        value={
          product?.subCategory?.categoryTranslation || (
            <Translate id='NA' />
          )
        }
        tag='name'
      />
    </ProductDescription>
    <Margin size={10} />
    <ColorsContainer>
      <Translate id='COLORS' />
      <StyledFlex $justify='center' $align='center' $direction='row'>
        {uniqBy(product?.productCombination, 'colorId').map(
          (color, index) => (
            <SingleColorContainer
              key={index}
              $marginLeft={
                index < product.productCombination.length - 1
                  ? '9px'
                  : false
              }
            >
              <ProductColor
                $file={
                  color.file
                    ? URL.createObjectURL(color?.color?.file)
                    : undefined
                }
                $color={color?.color?.html}
              />
            </SingleColorContainer>
          )
        )}
      </StyledFlex>
    </ColorsContainer>
    <PopupContainer open={selected === product.productId}>
      {selected === product.productId && (
        <DuplicatePopup onClose={onClose} selected={selected} />
      )}
    </PopupContainer>
  </ProductCardSection>
)

DuplicateProductCard.propTypes = {
  product: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  selected: PropTypes.number
}

DuplicateProductCard.defaultProps = {
  disabled: false,
  selected: undefined
}

export default DuplicateProductCard
