import React, {useState} from 'react'

import {DatePicker} from 'antd'
import moment from 'moment'
import {PropTypes} from 'prop-types'

import {
  DatePickerSection,
  CalendarIcon,
  DateLabel,
  PreviousDate,
  NextDate
} from './BaseCalendarStyles'

const CalendarSelector = ({date, setDate}) => {
  const [open, setOpen] = useState(false)
  return (
    <DatePickerSection>
      <PreviousDate
        onClick={() => setDate(moment(date).subtract(1, 'week'))}
      />
      <DateLabel onClick={() => setOpen(!open)}>
        {moment.utc(date).format('MMMM YYYY')}
      </DateLabel>
      <DatePicker
        open={open}
        value={date}
        allowClear={false}
        onChange={(value) => {
          setDate(value)
          setOpen(false)
        }}
        suffixIcon={
          <CalendarIcon $active={open} onClick={() => setOpen(!open)} />
        }
        picker='week'
        bordered={false}
        format='MMMM YYYY'
      />
      <NextDate onClick={() => setDate(moment(date).add(1, 'week'))} />
    </DatePickerSection>
  )
}

CalendarSelector.propTypes = {
  date: PropTypes.object.isRequired,
  setDate: PropTypes.func.isRequired
}

export default CalendarSelector
