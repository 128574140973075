import React from 'react'

import { OktaAuth } from '@okta/okta-auth-js'
import { Security } from '@okta/okta-react'
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom'

import NotFound from '../pages/not_found/NotFound'
import CombinedRoutes from './CombinedRoutes'
import AuthenticatedRoute from './route_types/AuthenticatedRoute'
import UnauthenticatedRoute from './route_types/UnauthenticatedRoute'
import ScrollToTop from './ScrollToTop'

const { REACT_APP_OKTA_REDIRECT, REACT_APP_OKTA_ISSUER, REACT_APP_OKTA_CLIENT } = window.env

const oktaAuth = new OktaAuth({
  issuer: REACT_APP_OKTA_ISSUER,
  clientId: REACT_APP_OKTA_CLIENT,
  redirectUri: REACT_APP_OKTA_REDIRECT
})

const ApplicationRoutes = () => {
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    console.log('_oktaAuth', _oktaAuth, originalUri)
  }

  const renderApplicationRoute = (route, key) => {
    if (route.isAuthenticated) {
      return (
        <Route
          key={key}
          exact
          path={route.path}
          render={(routerProps) => (
            <AuthenticatedRoute route={route} routerProps={routerProps} />
          )}
        />
      )
    }

    return (
      <Route
        key={key}
        exact
        path={route.path}
        component={(routerProps) => (
          <UnauthenticatedRoute route={route} routerProps={routerProps} />
        )}
      />
    )
  }

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Router>
        <ScrollToTop />
        <Switch>
          {CombinedRoutes.map(renderApplicationRoute)}
          <Route component={NotFound} />
        </Switch>
      </Router>
    </Security>
  )
}

export default ApplicationRoutes
