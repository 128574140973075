import React from 'react'

import { Popover } from 'antd'
import styled from 'styled-components'

import Info from '../../../../assets/icons/info.svg'
import { ErrorColor } from '../../../../shared/styles/_colors'
import { BoldFontWeight, BookFontWeight, FooterText } from '../../../../shared/styles/_texts'
import isInIframe from '../../../../shared/utils/isInIframe';

export const Flex = styled.div`
  display: flex;
  flex-direction: column;
`

export const LineContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CircleNote = styled.div`
  display: flex;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: ${ErrorColor};
  justify-content: center;
  align-items: center;
`

export const NoteIcon = styled.img`
  height: 11px;
`

export const TextPopover = styled.div`
  font-size: ${FooterText};
  font-weight: ${({ $bold }) => ($bold ? BoldFontWeight : BookFontWeight)};
  line-height: 17px;
  padding: 2px;
`

const PriceAlertPopup = ({ product }) => {
  const inIframe = isInIframe();

  const content = (
    <Flex>
      <div>This product does not have a price for this currency</div>
      <div>Please open the product and add the information in the price section</div>
      <a target={inIframe ? '_self' : '_blank'} rel='noreferrer' href={`/products/${product?.productId}`}>Go to product</a>
    </Flex>
  )
  return (
    <Popover placement='top' trigger='hover' content={content}>
      <LineContent>
        <CircleNote>
          <NoteIcon src={Info} alt='Info' />
        </CircleNote>
      </LineContent>
    </Popover>
  )
}

export default PriceAlertPopup
