import React, { Component } from 'react'

import { Col, Row } from 'antd'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'

import IconAdd from '../../../../assets/icons/icon_add.svg'
import Active from '../../../../assets/icons/status_active.svg'
import Inactive from '../../../../assets/icons/status_inactive.svg'
import { GetBanners } from '../../../../infra/requests/MarketingRequests'
import BaseButton from '../../../../shared/components/buttons/BaseButton'
import SearchInput from '../../../../shared/components/inputs/SearchInput'
import StatusColumn from '../../../../shared/components/Status/StatusColumn'
import BaseTable from '../../../../shared/components/table/BaseTable'
import ActiveTranslation from '../../../../shared/logic/translations/ActiveTranslation'
import {
  StatusActive,
  StatusInactive
} from '../../../../shared/styles/_colors'
import { Margin } from '../../../../shared/styles/BasicStyles'
import { BackgroundImg, StatusText } from '../../../settings/SettingsStyles'

let timeout

class ImageManagerTab extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      rows: [],
      page: 1,
      pageSize: 12,
      total: 0,
      search: ''
    }
  }

  getColumnsBanners = () => [
    {
      dataIndex: 'imagePath',
      title: <Translate id='BACKGROUND' />,
      render: (value) => (
        <>
          {value ? (
            <BackgroundImg
              src={`${window.env.REACT_APP_API_FILES}/banners/${value}`}
            />
          ) : (
            <div>-</div>
          )}
        </>
      )
    },
    {
      dataIndex: 'bannerType',
      type: 'text',
      title: <Translate id='TYPE' />,
      render: (value) => <ActiveTranslation value={value?.bannerTypeTranslation || 'N/A'} tag='name' />
    },
    {
      dataIndex: 'bannerTranslation',
      type: 'text',
      title: <Translate id='TITLE' />,
      render: (value) => <ActiveTranslation value={value} tag='title' />
    },
    {
      dataIndex: 'bannerTranslation',
      type: 'text',
      title: <Translate id='TEXT' />,
      render: (value) => <ActiveTranslation value={value} tag='description' />
    },
    {
      dataIndex: 'bannerTranslation',
      title: <Translate id='BUTTON' />,
      render: (value) => <ActiveTranslation value={value} tag='buttonText' />
    },
    {
      dataIndex: 'bannerTranslation',
      title: <Translate id='BUTTON_2' />,
      render: (value) => <ActiveTranslation value={value} tag='buttonText2' />
    },
    {
      dataIndex: 'landingPage',
      type: 'text',
      title: <Translate id='LANDING_PAGE' />,
      render: (value) => (
        <StatusColumn
          value={value}
          onText={<Translate id='YES' />}
          offText={<Translate id='NO' />}
        />
      ),
      width: 100
    },
    {
      dataIndex: 'blackText',
      type: 'text',
      title: <Translate id='BLACK_TEXT' />,
      render: (value) => (
        <StatusColumn
          value={value}
          onText={<Translate id='YES' />}
          offText={<Translate id='NO' />}
        />
      ),
      width: 100
    },
    {
      dataIndex: 'active',
      type: 'text',
      title: <Translate id='STATE' />,
      render: (value) => (
        <StatusColumn value={value} />
      ),
      width: 100
    }
  ]

  componentDidMount = async () => {
    try {
      this.updateTable()
    } catch (error) {
      console.warn(error)
    }
  }

  updateTable = async () => {
    try {
      const { page, pageSize, search } = this.state
      this.setState({ loading: true })

      const { data, success } = await GetBanners(page, pageSize, search)

      if (success) {
        this.setState({
          rows: data?.items,
          total: data?.totalItems,
          loading: false
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  onChangePagination = (page) => this.setState({ page }, this.updateTable)

  setFilter = (search) => {
    this.setState({ search, page: 1 }, () => {
      if (timeout) clearTimeout(timeout)
      timeout = setTimeout(this.updateTable, 1000)
    })
  }

  render() {
    const { loading, rows, page, pageSize, total, search } = this.state
    const { router } = this.props
    return (
      <>
        <Row gutter={[24, 24]}>
          <Col xs={6}>
            <BaseButton
              auto
              imagePath={IconAdd}
              onClick={() => router.history.push('/marketing/banner/new')}
            >
              <Translate id='ADD_NEW' />
            </BaseButton>
          </Col>
          <Col xs={6} offset={12}>
            <SearchInput
              input={{
                value: search,
                onChange: this.setFilter,
                onSearch: this.updateTable
              }}
            />
          </Col>
        </Row>
        <Margin size={17} />
        <Row>
          <Col xs={24}>
            <BaseTable
              loading={loading}
              datasource={rows}
              columns={this.getColumnsBanners()}
              pagination={{
                render: true,
                page,
                pageSize,
                total,
                onChangePagination: this.onChangePagination
              }}
              onClickRow={(row) => {
                router.history.push(`/marketing/banner/${row.bannerId}`)
              }}
              rowKey='bannerId'
            />
          </Col>
        </Row>
      </>
    )
  }
}

ImageManagerTab.propTypes = {
  router: PropTypes.object.isRequired
}

export default ImageManagerTab
