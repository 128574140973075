import React, {useState} from 'react'

import {
  DeleteOutlined,
  CameraOutlined,
  VideoCameraOutlined,
  CloseCircleOutlined
} from '@ant-design/icons'
import {Popconfirm} from 'antd'
import PropTypes from 'prop-types'
import {useDrag} from 'react-dnd'
import {Translate} from 'react-localize-redux'

import GetImage from '../../../../../shared/logic/arrays/ImageFunction'
import ActiveTranslation from '../../../../../shared/logic/translations/ActiveTranslation'
import {
  ErrorColor,
  PrimaryButtonColor,
  PrimaryColor,
  TableTextColor,
  SuccessColor
} from '../../../../../shared/styles/_colors'
import {
  BodyH5,
  Margin,
  StyledFlex
} from '../../../../../shared/styles/BasicStyles'
import {
  ProductColor,
  SingleColorContainer
} from '../../../../orders/manage_b2b_b2b2c/components/Styles'
import {
  ProductLocalImageContainer,
  ProductLocalImage,
  RemovableItemContainer,
  HoverImageButton,
  ImageOverlay
} from '../../../ProductsStyles'

const ProductImageCard = ({
  deleteImage,
  changeCover,
  changeFitting,
  removeColor,
  currentImage,
  currentColor
}) => {
  const [loading, setLoading] = useState(false)

  const [collected, drag] = useDrag(() => ({
    type: 'image',
    item: {imageId: currentImage.imageId, colorId: currentColor?.colorId}
  }))

  const handleDelete = async () => {
    setLoading(true)
    await deleteImage(currentImage.imageId)
  }

  const handleCover = async () => {
    setLoading(true)
    await changeCover(currentImage.imageId)
    setLoading(false)
  }

  const handleFitting = async () => {
    setLoading(true)
    await changeFitting(currentImage.imageId)
    setLoading(false)
  }

  return (
    <>
      <BodyH5 style={{textAlign: 'center'}} $color={PrimaryButtonColor}>
        {currentImage?.image?.filename}
      </BodyH5>
      <ProductLocalImageContainer ref={drag}>
        <ProductLocalImage src={GetImage(currentImage)} />

        <HoverImageButton $top='5px' $right='5px' $color={ErrorColor}>
          <Popconfirm
            placement='topRight'
            title={<Translate id='REMOVE_IMAGE_MESSAGE' />}
            onConfirm={handleDelete}
            okButtonProps={{loading}}
          >
            <DeleteOutlined />
          </Popconfirm>
        </HoverImageButton>

        <HoverImageButton
          $top='5px'
          $left='5px'
          $color={currentImage.cover ? PrimaryColor : TableTextColor}
        >
          <Popconfirm
            placement='topRight'
            title={<Translate id='COVER_IMAGE_MESSAGE' />}
            onConfirm={handleCover}
            okButtonProps={{loading}}
          >
            <CameraOutlined />
          </Popconfirm>
        </HoverImageButton>

        <HoverImageButton
          $top='40px'
          $left='5px'
          $color={currentImage.fitting ? SuccessColor : TableTextColor}
        >
          <Popconfirm
            placement='topRight'
            title={<Translate id='FITTING_IMAGE_MESSAGE' />}
            onConfirm={handleFitting}
            okButtonProps={{loading}}
          >
            <VideoCameraOutlined />
          </Popconfirm>
        </HoverImageButton>
        <ImageOverlay $show={loading} />
      </ProductLocalImageContainer>
      <Margin size={10} />
      {currentColor && (
        <RemovableItemContainer>
          <StyledFlex $direction='row'>
            <SingleColorContainer $marginLeft='0px' $marginRight='10px'>
              <ProductColor
                $file={
                  currentColor.file
                    ? URL.createObjectURL(currentColor.file)
                    : undefined
                }
                $color={currentColor?.html}
              />
            </SingleColorContainer>
            <BodyH5
              style={{
                overflow: 'hidden',
                lineHeight: '30px',
                height: '30px'
              }}
            >
              <ActiveTranslation value={currentColor?.colorTranslation} tag='name' />
            </BodyH5>
          </StyledFlex>
          <Popconfirm
            placement='topRight'
            title={<Translate id='DELETE_COLOR_IMAGE_MESSAGE' />}
            onConfirm={removeColor}
          >
            <CloseCircleOutlined />
          </Popconfirm>
        </RemovableItemContainer>
      )}
    </>
  )
}

ProductImageCard.propTypes = {
  removeColor: PropTypes.func,
  currentImage: PropTypes.object,
  currentColor: PropTypes.object,
  deleteImage: PropTypes.func.isRequired,
  changeCover: PropTypes.func.isRequired,
  changeFitting: PropTypes.func.isRequired
}

ProductImageCard.defaultProps = {
  currentImage: {},
  removeColor: () => null,
  currentColor: undefined
}

export default ProductImageCard
