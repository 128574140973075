import React, { Component } from "react";

import { Row, Col } from "antd";
import { connect } from "react-redux";

import { GetB2BClients, GetPositions } from "../../infra/requests/BaseRequests";
import { GetCockpitInformation } from "../../infra/requests/CockpitRequests";
import BaseLoading from "../../shared/components/loading/BaseLoading";
import { ContentContainer } from "../../shared/styles/BasicStyles";
import CockpitGraphic from "./components/CockpitGraphic";

class DashboardPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      blocks: [],
      clients: [],
      positions: [],
    };
  }

  componentDidMount = () => {
    this.init();
  };

  init = async () => {
    const { data } = await GetCockpitInformation();
    const positionsData = await GetPositions();
    const clientsData = await GetB2BClients();
    this.setState({
      loading: false,
      blocks: data || [],
      clients: clientsData?.data?.items || [],
      positions: positionsData?.data?.items || [],
    });
  };

  render() {
    const { loading, blocks, clients, positions } = this.state;

    if (loading) return <BaseLoading size="300" />;

    return (
      <ContentContainer>
        <Row gutter={[24, 24]}>
          {blocks.map((graph, index) => (
            <Col xs={24} lg={12} key={index}>
              <CockpitGraphic
                graph={graph}
                clients={clients}
                positions={positions}
              />
            </Col>
          ))}
        </Row>
      </ContentContainer>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(DashboardPage);
