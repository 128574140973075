import React, {Component} from 'react'

import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'

import SaveIcon from '../../../assets/icons/save.svg'
import {GetB2CProducts} from '../../../infra/requests/ProductsRequests'
import BackButton from '../../../shared/components/buttons/BackButton'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import BaseList from '../../../shared/components/table/BaseList'
import {ConstructQuery} from '../../../shared/logic/queries/EndpointQueries'
import {
  Margin,
  PageContent,
  TitleH2,
  StyledHR
} from '../../../shared/styles/BasicStyles'
import ProductCard from '../components/ProductCard'
import ProductFilters from '../components/ProductFilters'

let timeout

class RelatedProducts extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      rows: [],
      page: 1,
      pageSize: 24,
      total: 0,
      filters: {Search: ''},
      selected: undefined
    }
  }

  componentDidMount = async () => {
    try {
      this.updateTable()
    } catch (error) {
      console.warn(error)
    }
  }

  updateTable = async () => {
    const {page, pageSize, filters} = this.state

    this.setState({loading: true})
    const {data} = await GetB2CProducts(
      page,
      pageSize,
      ConstructQuery(filters)
    )

    this.setState({
      rows: data?.items,
      total: data?.totalItems,
      loading: false,
      selected: undefined
    })
  }

  onChangePagination = (page) => this.setState({page}, this.updateTable)

  setFilters = (values, time) => {
    this.setState({filters: values}, () => {
      if (timeout) clearTimeout(timeout)
      timeout = setTimeout(this.updateTable, time)
    })
  }

  render() {
    const {setRelatedProduct, original} = this.props
    const {filters, rows, loading, page, pageSize, total, selected} =
      this.state
    return (
      <PageContent $noMargin $noMinHeight>
        <Margin size={20} />
        <Row>
          <Col xs={24}>
            <BackButton
              label={<Translate id='BACK' />}
              onClick={() => setRelatedProduct()}
            />
          </Col>
        </Row>
        <Margin size={24} />
        <Row justify='end'>
          <Col xs={12} lg={20}>
            <TitleH2>
              <Translate id='RELATED_PRODUCTS' />
            </TitleH2>
          </Col>
          <Col xs={12} lg={4}>
            <BaseButton
              onClick={() => setRelatedProduct(selected)}
              type='secondary'
              disabled={!selected}
              imagePath={SaveIcon}
            >
              <Translate id='SAVE' />
            </BaseButton>
          </Col>
        </Row>
        <StyledHR $space='20' />
        <ProductFilters filters={filters} setFilters={this.setFilters} />
        <StyledHR $space='20' />
        <Row>
          <Col xs={24}>
            <BaseList
              grid={{
                gutter: 20,
                xs: 1,
                sm: 2,
                md: 4,
                lg: 4,
                xl: 6,
                xxl: 6
              }}
              datasource={rows}
              renderItem={(item) => (
                <ProductCard
                  disabled={item.productId == original}
                  key={item.productId}
                  product={item}
                  selection
                  selected={item.productId === selected?.productId}
                />
              )}
              pagination={{
                render: true,
                page,
                pageSize,
                total,
                onChangePagination: this.onChangePagination
              }}
              onClickRow={(item) => { if (item.productId != original) this.setState({selected: item}) }}
              loading={loading}
            />
          </Col>
        </Row>
      </PageContent>
    )
  }
}

RelatedProducts.propTypes = {
  setRelatedProduct: PropTypes.func.isRequired,
  original: PropTypes.string.isRequired
}

export default RelatedProducts
