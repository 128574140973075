import React, {useEffect, useState} from 'react'

import {Col, Row} from 'antd'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {useParams} from 'react-router-dom'
import {reduxForm, initialize, FieldArray, Field} from 'redux-form'

import {
  GetProfile,
  CreateProfile,
  GetProfilePermissions,
  GetProfileUsers
} from '../../infra/requests/ClientRequests'
import AlertService from '../../shared/components/alert/AlertService'
import BackButton from '../../shared/components/buttons/BackButton'
import BaseButton from '../../shared/components/buttons/BaseButton'
import SelectInput from '../../shared/components/inputs/SelectInput'
import SwitchInput from '../../shared/components/inputs/SwitchInput'
import TextInput from '../../shared/components/inputs/TextInput'
import BaseLoading from '../../shared/components/loading/BaseLoading'
import {
  ContentContainer,
  PageForm,
  PageTitle,
  StyledTabPane,
  StyledTabs,
  StyledTabTitle,
  StyledTabTitleContainer
} from '../../shared/styles/BasicStyles'
import PermissionsTab from './PermissionsTab'
import UsersTab from './UsersTab'

const ManageProfile = ({
  router,
  dispatch,
  handleSubmit,
  clients,
  translate
}) => {
  const [loading, setLoading] = useState(true)
  const [hasFetchedProfile, setHasFetchedProfile] = useState(false)
  const [name, setName] = useState(<Translate id='PROFILE' />)
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(12)
  const [total, setTotal] = useState(0)
  const [users, setUsers] = useState([])
  const {client, id} = useParams()

  useEffect(() => {
    async function getProfile() {
      if (id !== 'new') {
        const resultInfo = await GetProfile(id)
        const {data, success} = await GetProfileUsers(
          id,
          page,
          pageSize,
          search
        )

        if (resultInfo.success && success) {
          dispatch(
            initialize('profile_form', {...data, info: resultInfo?.data})
          )
          // setName(data.name)
          setTotal(data?.totalItems)
          setUsers(data?.items)
        }

        const result = await GetProfilePermissions(id)
      }
      setLoading(false)
      setHasFetchedProfile(true)
    }
    if (!hasFetchedProfile) {
      getProfile()
    }
  }, [hasFetchedProfile, name])

  const handleClose = () =>
    client
      ? router.history.push(`/clients/${client}#profiles`)
      : router.history.push('/skypro#profiles')

  const onSubmit = async (values) => {
    try {
      if (id === 'new') {
        const object = {
          name: values?.info?.name,
          active: values?.info?.active,
          b2bClientId: values?.info?.b2bclient?.b2bclientId,
          isSystem: true
        }

        const {success} = await CreateProfile(object)

        if (success) {
          AlertService.success(
            translate('SUCCESS'),
            translate('PROFILE_CREATE_SUCCESSFULLY')
          )
        }
        router.history.push(
          client
            ? router.history.push(`/clients/${client}#profiles`)
            : router.history.push('/skypro#profiles')
        )
      }
    } catch (error) {
      console.log(error)
    }
  }

  const renderInfo = () => (
    <Row gutter={[40, 20]}>
      <Col xs={6}>
        <Field
          component={TextInput}
          name='info.name'
          label={<Translate id='NAME' />}
          disabled={id !== 'new'}
        />
      </Col>
      <Col xs={6}>
        <Field
          component={SelectInput}
          name='info.b2bclient.b2bclientId'
          label={<Translate id='CLIENT' />}
          data={clients}
          dataLabel='name'
          dataKey='b2bclientId'
          disabled={id !== 'new'}
        />
      </Col>
      <Col xs={6}>
        <Field
          component={SwitchInput}
          name='info.active'
          label={<Translate id='ACTIVE' />}
          checkedText={<Translate id='YES' />}
          uncheckedText={<Translate id='NO' />}
          disabled={id !== 'new'}
        />
      </Col>
    </Row>
  )

  if (loading) return <BaseLoading margin='100' />

  return (
    <PageForm onSubmit={handleSubmit(onSubmit)}>
      <Row align='bottom' gutter={[0, 50]}>
        <Col xs={24}>
          <BackButton
            label={<Translate id='BACK' />}
            onClick={handleClose}
          />
        </Col>
        <Col xs={12}>
          <PageTitle>{name}</PageTitle>
        </Col>
        <Col xs={12} align='end'>
          <BaseButton htmlType='submit' type='primary' auto>
            <Translate id='SAVE' />
          </BaseButton>
        </Col>
      </Row>
      <ContentContainer>
        <StyledTabs>
          <StyledTabPane
            tab={
              <StyledTabTitleContainer>
                <StyledTabTitle>
                  <Translate id='INFORMATION' />
                </StyledTabTitle>
              </StyledTabTitleContainer>
            }
            key='info'
          >
            {renderInfo()}
          </StyledTabPane>
          <StyledTabPane
            tab={
              <StyledTabTitleContainer>
                <StyledTabTitle>
                  <Translate id='USERS' />
                </StyledTabTitle>
              </StyledTabTitleContainer>
            }
            key='users'
            disabled={id === 'new'}
          >
            <UsersTab fields={users} />
          </StyledTabPane>
          <StyledTabPane
            tab={
              <StyledTabTitleContainer>
                <StyledTabTitle>
                  <Translate id='PERMISSIONS' />
                </StyledTabTitle>
              </StyledTabTitleContainer>
            }
            key='permissions'
            disabled={id === 'new'}
          >
            <FieldArray component={PermissionsTab} name='permissions' />
          </StyledTabPane>
        </StyledTabs>
      </ContentContainer>
    </PageForm>
  )
}

ManageProfile.propTypes = {
  router: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  handleSubmit: PropTypes.func
}

ManageProfile.defaultProps = {
  dispatch: undefined,
  handleSubmit: undefined
}

const ManageProfilePage = reduxForm({
  form: 'profile_form'
  // validate: validations
})(ManageProfile)

export default withLocalize(
  connect((state) => ({
    clients: state.info.clients
  }))(ManageProfilePage)
)
