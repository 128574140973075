import {Select} from 'antd'
import styled from 'styled-components'

import {TableFooterTextColor} from '../../styles/_colors'
import {BodyText} from '../../styles/_texts'

export const PaginationSection = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  color: ${TableFooterTextColor};
  font-size: ${BodyText};
`
export const LeftPagination = styled.div`
  display: flex;
  align-items: center;
`
export const RightPagination = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`

export const PaginationBack = styled.div`
  margin-right: 20px;
  cursor: pointer;
  opacity: ${({disabled}) => (disabled ? 0.5 : 1)};
`

export const PaginationNext = styled.div`
  cursor: pointer;
  opacity: ${({disabled}) => (disabled ? 0.5 : 1)};
`

export const PaginationInput = styled(Select)`
  margin: 0 10px;
  width: 50px;
  text-align: center;
  
  &.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
      height: 20px;
    }
    &.ant-select-single .ant-select-selector
     .ant-select-selection-item, .ant-select-single 
     .ant-select-selector .ant-select-selection-placeholder {
      line-height: 18px;
    }
`
