import React from 'react'

import {Col, Row} from 'antd'
import PropTypes from 'prop-types'
import {withLocalize, Translate} from 'react-localize-redux'
import {Field} from 'redux-form'

import TextInput from '../../../../shared/components/inputs/TextInput'
import GetObjectByID from '../../../../shared/logic/arrays/GetObjectByID'
import {Margin, TitleH4} from '../../../../shared/styles/BasicStyles'

const SEOData = ({fields, languages, translate}) => {
  const list = fields.getAll()
  return (
    <>
      <Margin size={20} />
      {list.map((currentField, index) => (
        <Row gutter={[20, 20]} key={index}>
          <Col xs={24}>
            <Margin size={20} />
            <TitleH4 $op>
              {
                  GetObjectByID(
                    languages,
                    'languageId',
                    currentField?.languageID
                  )?.name
              }
            </TitleH4>
          </Col>
          <Col xs={12}>
            <Field
              name={`${fields.name}[${index}].metaTitle`}
              label={<Translate id='META_TITLE' />}
              placeholder={translate('PLACEHOLDER_META_TITLE')}
              component={TextInput}
            />
          </Col>
          <Col xs={12}>
            <Field
              name={`${fields.name}[${index}].metaDescription`}
              label={<Translate id='META_DESC' />}
              component={TextInput}
              placeholder={translate('PLACEHOLDER_META_DESC')}
            />
          </Col>
        </Row>
      ))}
      <Margin size={20} />
    </>
  )
}

SEOData.propTypes = {
  fields: PropTypes.object.isRequired,
  languages: PropTypes.array.isRequired
}

export default withLocalize(SEOData)
