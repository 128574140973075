import React from 'react'

import {PropTypes} from 'prop-types'
import Dropzone from 'react-dropzone'
import {Translate, withLocalize} from 'react-localize-redux'

import AlertService from '../alert/AlertService'
import {
  InputBox,
  InputLabel,
  InputError,
  FileInputStyled,
  FileButton,
  FileName
} from './InputStyles'

const FileInput = ({input, label, meta, loading, disabled, accept, translate}) => {
  const handleFileDrop = (accepted) => {
    if (accepted && accepted[0]) {
      input.onChange(accepted[0])
    } else AlertService.error(translate('ERROR'), translate('INVALID_FILE_FORMAT'))
  }

  const hasError = meta.invalid && meta.submitFailed

  return (
    <InputBox>
      <InputLabel>{label}</InputLabel>
      <Dropzone onDrop={handleFileDrop} maxFiles={1} disabled={loading || disabled} accept={accept}>
        {({getRootProps, getInputProps}) => (
          <FileInputStyled
            {...getRootProps()}
            hasError={hasError}
            $loading={loading || disabled}
          >
            <input {...getInputProps()} />
            <FileButton>
              <Translate id='CHOOSE_FILE' />
            </FileButton>
            <FileName>
              {input.value ? (
                input?.value?.filename ? (
                  input?.value?.filename
                ) : input?.value?.name ? (
                  input?.value?.name
                ) : (
                  input?.value
                )
              ) : (
                <Translate id='NO_FILE_CHOSEN' />
              )}
            </FileName>
          </FileInputStyled>
        )}
      </Dropzone>
      {hasError && (
        <InputError>
          {' '}
          <Translate id={meta.error} />
        </InputError>
      )}
    </InputBox>
  )
}

FileInput.propTypes = {
  translate: PropTypes.func.isRequired,
  input: PropTypes.object,
  meta: PropTypes.object,
  accept: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object
  ]),
  loading: PropTypes.bool,
  disabled: PropTypes.bool
}

FileInput.defaultProps = {
  input: {value: undefined, onChange: () => null},
  label: <Translate id='CHOOSE_FILE' />,
  accept: undefined,
  meta: {},
  loading: false,
  disabled: false
}

export default withLocalize(FileInput)
