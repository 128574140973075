import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'

import { NumberIncrement } from './InputStyles'

const NumberIncrementInput = ({
  input,
  meta,
  id,
  placeholder,
  afterChange,
  min,
  max,
  disabled,
  step,
  width,
  showError,
  defaultValue
}) => {
  const initialValue = (input.value !== undefined && input.value !== '') ? input.value : defaultValue
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    input.onChange(value)
    if (typeof afterChange === 'function') {
      afterChange(value)
    }
  }, [value])

  const handleFormat = (event) => {
    if (Number.isNaN(event.target.value) || event.target.value === '') {
      input.onChange(0)
      if (typeof afterChange === 'function') {
        afterChange(0)
      }
    }
  }

  // Invoke showError as a function if it's defined
  const hasError = (meta.invalid && meta.submitFailed) || (typeof showError === 'function' && showError({ value }))

  return (
    <NumberIncrement
      id={id}
      $width={width}
      type='number'
      value={value}
      hasError={hasError}
      placeholder={placeholder}
      onChange={(e) => setValue(e.target.value)}
      onBlur={handleFormat}
      min={min}
      max={max}
      step={step}
      disabled={disabled}
    />
  )
}

NumberIncrementInput.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func
  }),
  meta: PropTypes.object,
  placeholder: PropTypes.string,
  afterChange: PropTypes.func,
  min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  step: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  showError: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

NumberIncrementInput.defaultProps = {
  input: { value: undefined, onChange: () => null },
  placeholder: undefined,
  meta: {},
  afterChange: undefined,
  min: 0,
  max: undefined,
  step: 'any',
  disabled: false,
  width: '54px',
  showError: false,
  defaultValue: 0,
  id: undefined
}

export default NumberIncrementInput
