import React, {useState, useEffect} from 'react'

import {Col, Row} from 'antd'
import moment from 'moment'
import {Translate} from 'react-localize-redux'
import {useParams} from 'react-router-dom'

import {GetContactVouchers} from '../../../../infra/requests/ContactRequests'
import BaseTable from '../../../../shared/components/table/BaseTable'

const UserVouchers = () => {
  const [loading, setLoading] = useState(true)
  const [rows, setRows] = useState([])
  const {id} = useParams()
  useEffect(() => {
    async function init() {
      if (id && id !== 'new') {
        const {data, success} = await GetContactVouchers(id)
        if (success) setRows(data?.items || [])
      }
      setLoading(false)
    }
    init()
  }, [id])

  const columns = [
    {
      title: <Translate id='DATE' />,
      type: 'text',
      render: (data) =>
        moment(data?.dateCreated).format('DD/MM/YYYY HH:mm')
    },
    {
      title: <Translate id='VALUE' />,
      type: 'text',
      dataIndex: 'totalValue',
      render: (value) => (value ? `${value.toFixed(2)}€` : '')
    },
    {
      title: <Translate id='REMAINING' />,
      type: 'text',
      dataIndex: 'totalValueRemaining',
      render: (value) => (value ? `${value.toFixed(2)}€` : '')
    },
    {
      title: <Translate id='USED' />,
      type: 'text',
      dataIndex: 'usageCounter',
      render: (value) =>
        value ? <Translate id='YES' /> : <Translate id='NO' />
    }
  ]

  return (
    <Row>
      <Col xs={24}>
        <BaseTable
          rowKey='dateCreated'
          datasource={rows}
          columns={columns}
          emptyMessage={<Translate id='THERE_ARE_NO_VOUCHERS' />}
          pagination={{
            render: false
          }}
          loading={loading}
        />
      </Col>
    </Row>
  )
}

export default UserVouchers
