import React, {useState} from 'react'

import {
  CheckCircleOutlined,
  RetweetOutlined,
  CloseCircleOutlined
} from '@ant-design/icons'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {Field, getFormValues} from 'redux-form'
import styled from 'styled-components'

import {UpdateOrderItems} from '../../../../infra/requests/OrdersB2CRequests'
import AlertService from '../../../../shared/components/alert/AlertService'
import NumberIncrementInput from '../../../../shared/components/inputs/NumberIncrementInput'
import AddProductInput from '../../../../shared/components/inputs/Products/AddProductInput'
import {
  ProductsFooter,
  ProductsFooterContainer,
  ProductsFooterTitle,
  ProductsFooterValue
} from '../../../../shared/components/inputs/Products/ProductsStyles'
import BaseTable from '../../../../shared/components/table/BaseTable'
import ActiveTranslation from '../../../../shared/logic/translations/ActiveTranslation'
import {
  SuccessColor,
  ErrorColor,
  PrimaryColor
} from '../../../../shared/styles/_colors'

const ValidationSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 50px;
  font-size: 18px;
  justify-content: space-between;
  color: #c6c8ca;
`

const CheckIcon = styled(CheckCircleOutlined)`
  color: ${({color}) => color};
`

const CloseIcon = styled(CloseCircleOutlined)`
  color: ${({color}) => color};
`

const ChangeProductIcon = styled.div`
  width: 100%;
  text-align: center;
  color: ${PrimaryColor};
  cursor: pointer;
  font-size: 18px;
`

const EditOrderProducts = ({fields, meta, translate, orderId, currentFormValues}) => {
  const [productToChange, setProductToChange] = useState(null)
  const products = fields.getAll() || []
  const total = products.reduce(
    (accumulator, currentProduct) =>
      accumulator +
      currentProduct.quantity * currentProduct.unitTotalValueFinal,
    0
  )

  const addTableProducts = async (list) => {
    const itemToChange = products[productToChange]
    const itemToChangeTotal = itemToChange.quantity * itemToChange.unitTotalValueFinal
    const listTotal = list.reduce(
      (accumulator, currentProduct) =>
        accumulator +
        parseInt(currentProduct.quantity, 10) * currentProduct.totalValue,
      0
    )

    if (listTotal < itemToChangeTotal) {
      const requestBody = []
      list.forEach((element) => {
        requestBody.push({
          orderItemId: 0,
          originalOrderItemId: itemToChange.orderItemId,
          productId: element.productId,
          sizeId: element.sizeId,
          colorId: element.colorId,
          productWidthId: element.productWidthId,
          quantity: parseInt(element.quantity, 10)
        })
      })
      await UpdateOrderItems(orderId, !currentFormValues.sendNotifications, requestBody)
    } else {
      // the new items total is higher
      AlertService.error(
        translate('ERROR'),
        translate('CHANGED_ITEMS_VALUE_HIGHER')
      )
    }
  }

  const columns = [
    // {
    //   type: 'text',
    //   title: <div style={{opacity: 0}}>.</div>,
    //   render: (value, row, index) => (
    //     <ChangeProductIcon onClick={() => setProductToChange(index)}>
    //       <RetweetOutlined />
    //     </ChangeProductIcon>
    //   )
    // },
    {
      type: 'text',
      title: <Translate id='REF_SKYPRO' />,
      dataIndex: 'productCombination',
      render: (value, row, index) => {
        if (productToChange === index) {
          return (
            <AddProductInput
              onSelectValues={addTableProducts}
              field='changeProduct'
            />
          )
        }
        return value?.reference || 'N/A'
      }
    },
    {
      type: 'text',
      title: <Translate id='EAN_CODE' />,
      dataIndex: 'productCombination',
      render: (value) => value?.barcode || 'N/A'
    },
    {
      type: 'text',
      title: <Translate id='DESCRIPTION' />,
      dataIndex: 'productCombination',
      render: (value) => (
        <ActiveTranslation
          value={value?.product?.productTranslation || 'N/A'}
          tag='name'
        />
      )
    },
    {
      type: 'text',
      title: <Translate id='SIZE' />,
      dataIndex: 'productCombination',
      render: (value) => value?.size?.nameEu
    },
    {
      type: 'text',
      title: <Translate id='COLOR' />,
      dataIndex: 'productCombination',
      render: (value) => (
        <ActiveTranslation
          value={value?.color?.colorTranslation}
          tag='name'
        />
      )
    },
    {
      type: 'text',
      title: <Translate id='WIDTH' />,
      dataIndex: 'productCombination',
      render: (value) => value?.productWidth?.code || 'N/A'
    },
    {
      type: 'text',
      dataIndex: 'productCombination',
      title: <Translate id='STOCK' />,
      render: (value) => value?.stock
    },
    {
      type: 'text',
      dataIndex: 'quantity',
      title: <Translate id='QTY' />
    },
    {
      type: 'text',
      dataIndex: 'quantityConfirmed',
      title: <Translate id='QTY_CONFIRMED' />
    },
    {
      type: 'text',
      dataIndex: 'quantityDelivered',
      title: <Translate id='QTY_DELIVERED' />
    },
    {
      type: 'text',
      dataIndex: 'quantity',
      title: <Translate id='SEND' />,
      render: (value, row, index) => (
        <Field
          component={NumberIncrementInput}
          disabled={row?.status?.orderStatusId == 0 || row?.quantity === row?.quantityConfirmed}
          name={`${fields.name}[${index}].sendQty`}
          min={0}
          max={parseInt(value, 10)}
        />
      )
    },
    {
      type: 'text',
      dataIndex: 'valid',
      title: 'Validation',
      render: (text, row) => (
        <ValidationSection>
          <CheckIcon
            color={
              row.sendQty != '' &&
              parseInt(row.sendQty, 10) == parseInt(row.quantity, 10)
                ? SuccessColor
                : '#c6c8ca'
            }
          />
          <CloseIcon
            color={
              row.sendQty != '' &&
              parseInt(row.sendQty, 10) < parseInt(row.quantity, 10)
                ? ErrorColor
                : '#c6c8ca'
            }
          />
        </ValidationSection>
      )
    },
    {
      type: 'text',
      dataIndex: 'status',
      title: <Translate id='STATUS' />,
      render: (value) => value ? (
        <ActiveTranslation
          value={value?.orderStatusTranslation}
          tag='name'
        />
      ) : 'N/A'
    },
    {
      type: 'text',
      dataIndex: 'currency',
      title: <Translate id='CURRENCY' />,
      render: (value) => value?.code || 'N/A'
    },
    {
      type: 'text',
      dataIndex: 'unitTotalValueFinal',
      title: <Translate id='PRICE_UN' />,
      render: (value) => `${parseFloat(value).toFixed(2)}€`
    },
    {
      type: 'text',
      title: <Translate id='TOTAL' />,
      render: (data) =>
        `${parseFloat(data.quantity * data.unitTotalValueFinal).toFixed(
          2
        )}€`
    }
  ]

  return (
    <>
      <BaseTable
        rowKey='orderItemId'
        meta={meta}
        datasource={products}
        columns={columns}
        pagination={{render: false}}
      />
      <ProductsFooter>
        <ProductsFooterContainer>
          <ProductsFooterTitle $total>
            <Translate id='TOTAL' />:
          </ProductsFooterTitle>
        </ProductsFooterContainer>
        <ProductsFooterContainer>
          <ProductsFooterValue $total>
            €{(total > 0 ? total : 0).toFixed(2)}
          </ProductsFooterValue>
        </ProductsFooterContainer>
      </ProductsFooter>
    </>
  )
}

EditOrderProducts.propTypes = {
  fields: PropTypes.object,
  meta: PropTypes.object
}

EditOrderProducts.defaultProps = {
  fields: {},
  meta: {}
}

export default withLocalize(connect((state) => ({
  currentFormValues: getFormValues('manage-order-b2b2c-b2c')(state)
}))(EditOrderProducts))
