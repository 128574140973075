import client from '../config/AxiosConfig'

export const GetProductCombinations = async (id) =>
  client.get(`/OrderItems/GetProductCombinationByID/${id}`)

export const EditOrderItems = async (data) =>
  client.put('/OrderItems/EditOrderItems', data)

export const CancelOrderItems = async (data) =>
  client.put('/OrderItems/CancelOrderItems', data)
