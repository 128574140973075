import {isEqual} from 'lodash'

import {TransformOrderShippingAddress, TransformOrderBillingAddress} from './TransformContactsAddresses'

export default (order) => {
  if (order != null) {
    if (isEqual(TransformOrderShippingAddress(order), TransformOrderBillingAddress(order))) {
      return true
    }
    return false
  }
}
