import React from 'react'

import { Table, Select, List } from 'antd'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import {
  PrimaryButtonColor,
  BorderColor,
  TableHeaderColor,
  TableHeaderBackground,
  TableFooterTextColor,
  ErrorColor,
  TableTextColor,
  NotSeenColor,
  NavBackgroundColor,
  PageBackground,
  FooterTextColor
} from '../../styles/_colors'
import { LabelText, BodyText, H3Text, ButtonText } from '../../styles/_texts'

const { Option } = Select

export const StyledList = styled(List)`
  .ant-spin-nested-loading > div > .ant-spin {
    background: #f8f8f8;
  }
`
export const StyledListItem = styled(List.Item)`
  cursor: ${({ $hasOnClick }) => ($hasOnClick ? 'pointer' : 'auto')};
`

export const StyledNewOrderTable = styled(Table)`
  .ant-table {
    background: transparent;
    overflow-x: scroll;
    overflow-y: scroll;
    height: 60vh;

    &::-webkit-scrollbar {
      background-color: transparent;
      width: 6px;
      height: 6px;
      margin-right: 10px;
      margin-bottom: 10px;
    }

    &::-webkit-scrollbar-track {
      border: none; /* Remove any border */
    }

    &::-webkit-scrollbar-corner {
      background-color: transparent; /* Hide the corner where scrollbars meet */
    }

    &::-webkit-scrollbar-thumb {
      background-color: #888; /* Color of the thumb */
      border-radius: 5px; /* Rounded corners */
    }

    .notSeenRow {
      td {
        background-color: ${NotSeenColor};
        font-weight: bold;
      }
    }

    table {
      border-collapse: separate;
      border-spacing: 0px 3px;
      border: none;
      background: transparent;

      thead {
        white-space: nowrap;
      }

      tbody {
        white-space: wrap;
      }

      th {
        padding: 8px 0px;
        color: ${TableHeaderColor};
        font-size: ${LabelText};
        text-decoration: none;
        border: none;
        background-color: ${TableHeaderBackground};
      }

      td {
        font-size: ${BodyText};
        padding: 6px 0px;
        background-color: ${({ $isWhiteTable }) =>
    $isWhiteTable ? TableHeaderBackground : 'transparent'};
      }

      th,
      td {
        border-left: none;
        border-right: none;
        border-top: 1px solid
          ${({ $hasError }) => ($hasError ? ErrorColor : BorderColor)};
        border-bottom: 1px solid
          ${({ $hasError }) => ($hasError ? ErrorColor : BorderColor)};
      }

      th:first-child,
      td:first-child {
        border-left: 1px solid
          ${({ $hasError }) => ($hasError ? ErrorColor : BorderColor)};
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px !important;
        z-index: 1;
      }

      th:last-child,
      td:last-child {
        border-top-right-radius: 5px !important;
        border-bottom-right-radius: 5px;
        border-right: 1px solid
          ${({ $hasError }) => ($hasError ? ErrorColor : BorderColor)};

        &.ant-select {
          border-right: none;
        }
      }

      table > thead > tr:first-child th:first-child {
        border-top-left-radius: 5px;
      }
    }

    .ant-checkbox-wrapper {
      height: 100%;
      border-right: 1px solid ${BorderColor};
      width: 100%;
      display: inline-block;
      display: flex;
      justify-content: center;
      padding-right: 10px;
      padding-left: 10px;
    }

    .ant-checkbox-wrapper-disabled {
      .ant-checkbox-inner {
        display: none;
      }
    }
    .ant-table-selection-column {
      width: auto;
    }
  }

  .ant-table-thead th.ant-table-column-has-sorters:hover {
    background: ${TableHeaderBackground};
  }

  .ant-table-column-sorter {
    display: none;
    padding: 0;
  }
  .ant-table-column-sorters {
    padding: 0;
    width: 100%;
    span {
      width: 100%;
    }
  }
  .ant-table-column-sorters-with-tooltip {
    float: left;
  }

  .ant-table-filter-column {
    margin: 0;
  }

  .ant-table-filter-column-title {
    padding: 0;
  }

  .ant-table-filter-trigger-container {
    display: none;
    padding: 0;
  }

  .ant-table-footer {
    background: none;
  }
  .ant-table-expanded-row {
    width: 1px;
    .ant-table-cell {
      background-color: ${({ color }) => color || NavBackgroundColor};
      width: inherit;
    }
  }

  .ant-table-row-expand-icon-cell {
    padding-left: 15px !important;
  }

  .ant-table-row-expand-icon {
    background-color: ${PrimaryButtonColor} !important;
    border-color: ${FooterTextColor} !important;
    color: ${FooterTextColor} !important;
    border-radius: 3px;
  }
`

export const StyledTable = styled(Table)`
  overflow-x: auto;

  && {
    .ant-table {
      background: transparent;

      .notSeenRow {
        td {
          background-color: ${NotSeenColor};
          font-weight: bold;
        }
      }

      table {
        border-collapse: separate;
        border-spacing: 0px 3px;
        border: none;
        background: transparent;

        thead {
          white-space: nowrap;
        }

        tbody {
          white-space: wrap;
          tr {
            cursor: ${({ $hasRowClick }) => $hasRowClick ? 'pointer' : 'initial'}
          }
        }


        th {
          padding: 8px 0px;
          color: ${TableHeaderColor};
          font-size: ${LabelText};
          text-decoration: none;
          border: none;
          background-color: ${TableHeaderBackground};
        }

        td {
          font-size: ${BodyText};
          padding: 6px 0px;
          background-color: ${({ $isWhiteTable }) =>
    $isWhiteTable ? TableHeaderBackground : 'transparent'};
        }

        th,
        td {
          border-left: none;
          border-right: none;
          border-top: 1px solid
            ${({ $hasError }) => ($hasError ? ErrorColor : BorderColor)};
          border-bottom: 1px solid
            ${({ $hasError }) => ($hasError ? ErrorColor : BorderColor)};
        }

        th:first-child,
        td:first-child {
          border-left: 1px solid
            ${({ $hasError }) => ($hasError ? ErrorColor : BorderColor)};
          border-bottom-left-radius: 5px;
          border-top-left-radius: 5px !important;
          z-index: 1;
        }

        th:last-child,
        td:last-child {
          border-top-right-radius: 5px !important;
          border-bottom-right-radius: 5px;
          border-right: 1px solid
            ${({ $hasError }) => ($hasError ? ErrorColor : BorderColor)};

          &.ant-select {
            border-right: none;
          }
        }

        table > thead > tr:first-child th:first-child {
          border-top-left-radius: 5px;
        }
      }

      .ant-checkbox-wrapper {
        height: 100%;
        border-right: 1px solid ${BorderColor};
        width: 100%;
        display: inline-block;
        display: flex;
        justify-content: center;
        padding-right: 10px;
        padding-left: 10px;
      }

      .ant-checkbox-wrapper-disabled {
        .ant-checkbox-inner {
          display: none;
        }
      }
      .ant-table-selection-column {
        width: auto;
      }
    }

    .ant-table-thead th.ant-table-column-has-sorters:hover {
      background: ${TableHeaderBackground};
    }

    .ant-table-column-sorter {
      display: none;
      padding: 0;
    }
    .ant-table-column-sorters {
      padding: 0;
      width: 100%;
      span {
        width: 100%;
      }
    }
    .ant-table-column-sorters-with-tooltip {
      float: left;
    }

    .ant-table-filter-column {
      margin: 0;
    }

    .ant-table-filter-column-title {
      padding: 0;
    }

    .ant-table-filter-trigger-container {
      display: none;
      padding: 0;
    }

    .ant-table-footer {
      background: none;
    }
    .ant-table-expanded-row {
      width: 1px;
      .ant-table-cell {
        background-color: ${({ color }) => color || NavBackgroundColor};
        width: inherit;
      }
    }

    .ant-table-row-expand-icon-cell {
      padding-left: 15px !important;
    }

    .ant-table-row-expand-icon {
      background-color: ${PrimaryButtonColor} !important;
      border-color: ${FooterTextColor} !important;
      color: ${FooterTextColor} !important;
      border-radius: 3px;
    }
  }
`

export const StyledTableText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-right: ${({ $noBorder }) =>
    $noBorder ? 'none' : `1px solid ${BorderColor}`};
  padding-left: 10px;
  padding-right: 10px;
  cursor: 'pointer';
  justify-content: ${({ $align }) => $align === 'right' ? 'flex-end' : 'space-between'};
`

export const UnStyledLink = styled(Link)`
  color: ${TableTextColor};
  

  :hover,
  :active {
    color: ${TableTextColor};
  }
`

export const SelectTitleStyled = styled(({ $hasError, ...rest }) => (
  <Select {...rest} />
))`
  width: 100%;
  height: 100%;
  padding-left: 16px;
  padding-right: 10px;
  border-right: ${({ $noBorder }) =>
    $noBorder ? 'none' : `1px solid ${BorderColor}`};
  font-size: ${LabelText};
  color: ${TableHeaderColor};
  text-decoration: none;

  &&& {
    .ant-select-selector {
      width: 100%;
      height: 25px;
      padding: 0px;
      border-radius: 0px;
      border: none;
      outline: none;
      box-shadow: none;
      background-color: transparent;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      &:hover,
      &:focus,
      &:visited,
      &:active {
        border: none;
        outline: none;
        box-shadow: none;
      }
    }

    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      font-size: ${LabelText};
      color: ${TableHeaderColor};
      opacity: 1;
    }

    .ant-select-clear {
      background: #d8d8d8;
    }
  }
`

export const SelectOption = styled(Option)``

export const SelectArrow = styled.div`
  position: absolute;
  top: -12px;
  right: -10px;
  height: 34px;
  width: 34px;
  border-radius: 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  ::after {
    content: "";
    position: absolute;
    right: 10px;
    top: 16px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 7px solid ${PrimaryButtonColor};
    clear: both;
    transition: all 0.5s ease;
  }
`
export const StyledOrderedContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-right: ${({ $noBorder }) =>
    $noBorder ? 'none' : `1px solid ${BorderColor}`};
  padding-right: 5px;
  cursor: ${({ onClick }) =>
    typeof onClick === 'function' ? 'pointer' : 'initial'};
`

export const StyledOrderedIcons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
`

export const OrderUpIcon = styled.div`
  content: "";
  right: 10px;
  top: 13px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 7px solid ${PrimaryButtonColor};
  clear: both;
  transition: all 0.5s ease;
  margin-bottom: 1px;
  opacity: ${({ isOrdered }) => (isOrdered ? 1 : 0.3)};
`

export const OrderDownIcon = styled.div`
  content: "";
  right: 10px;
  top: 13px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 7px solid ${PrimaryButtonColor};
  clear: both;
  transition: all 0.5s ease;
  margin-top: 1px;
  opacity: ${({ isOrdered }) => (isOrdered ? 1 : 0.3)};
`

export const TablePager = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  color: ${TableFooterTextColor};
  font-size: ${BodyText};
  border-bottom: 1px solid
    ${({ $small }) => ($small ? 'transparent' : BorderColor)};
  padding-bottom: ${({ $small }) => ($small ? 0 : 15)}px;
  margin-top: ${({ $small }) => ($small ? 15 : 40)}px;
`
export const LeftPager = styled.div`
  display: flex;
  align-items: center;
`
export const RightPager = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`

export const FooterBack = styled.div`
  margin-right: 20px;
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`

export const FooterNext = styled.div`
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`

export const TableBodyField = styled.div`
  color: ${({ color }) => color || TableTextColor};
  font-size: ${BodyText};
  text-decoration: none;
  border: none;
`

export const BaseTableFooter = styled.div`
  width: 100%;
  display: inline-block;
  background-color: ${TableHeaderBackground};
  border: 1px solid ${BorderColor};
  padding: 10px 20px 15px 20px;
  border-radius: 5px;
  margin-top: 3px;
`

export const EmptyTableIconContainer = styled.div`
  width: 100%;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const EmptyTableIcon = styled.img`
  width: 92px;
`
export const EmptyTableTitle = styled.h3`
  color: ${TableTextColor};
  font-size: ${H3Text};
  line-height: 34px;
  text-align: center;
`

export const EmptyTableText = styled.div`
  color: ${TableTextColor};
  font-size: ${ButtonText};
  line-height: 19.2px;
  opacity: 0.5;
  text-align: center;
  margin-bottom: 29px;
`

export const ExpandableContent = styled.div`
  display: block;
  width: 100%;
  margin-top: -15px;

  &&& {
    .ant-table-scroll-horizontal {
      margin: 0 !important;
    }

    .ant-table table th {
      color: ${TableTextColor};
      background-color: ${PageBackground};
    }
    td,
    table {
      background-color: ${PageBackground};
    }
  }
`

export const StyledSmallTable = styled(Table)`
  .ant-table-thead > tr > th {
    background: white;
    border-bottom: 1px solid white;
    text-transform: capitalize;
    color: ${PrimaryButtonColor};
    padding: 2px 5px;
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    display: none;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #fafafa;
  }
  .ant-table-tbody > tr.ant-table-row > td {
    background: #fafafa;
    padding: 2px 5px;
    border-bottom: 5px solid white;

    div {
      line-height: 20px;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      max-height: 20px;
      height: 20px;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
  .ant-table-tbody > tr.ant-table-row > td:first-child {
    font-weight: bold;
  }
`

export const PaginationInput = styled(Select)`
  margin: 0 10px;
  width: 80px;
  text-align: center;

  ${({ $small }) =>
    $small
      ? `
    &.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
      height: 20px;
    }
    &.ant-select-single .ant-select-selector
     .ant-select-selection-item, .ant-select-single 
     .ant-select-selector .ant-select-selection-placeholder {
      line-height: 18px;
    }
  `
      : ''}
`
