import React, {useEffect, useState} from 'react'

import {Col, Row} from 'antd'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {reduxForm, Field, initialize} from 'redux-form'

import {
  GetSendServer,
  PutSendServer
} from '../../../../../infra/requests/BaseRequests'
import FormValidator from '../../../../../infra/services/validations/FormValidator'
import AlertService from '../../../../../shared/components/alert/AlertService'
import BackButton from '../../../../../shared/components/buttons/BackButton'
import BaseButton from '../../../../../shared/components/buttons/BaseButton'
import {InputLabel} from '../../../../../shared/components/inputs/InputStyles'
import SwitchInput from '../../../../../shared/components/inputs/SwitchInput'
import TextInput from '../../../../../shared/components/inputs/TextInput'
import {
  PageForm,
  PageTitle,
  Margin
} from '../../../../../shared/styles/BasicStyles'

const validations = FormValidator.make({
  name: 'required',
  hostname: 'required',
  imapPort: 'required',
  active: 'required',
  username: 'required',
  password: 'required'
})

const ManageSendServer = ({router, dispatch, handleSubmit, translate}) => {
  useEffect(() => {
    async function fetchSendServer() {
      const {data, success} = await GetSendServer()

      if (success) {
        dispatch(initialize('send_server_form', data))
      }
    }
    fetchSendServer()
  }, [])

  const onSubmit = async (values) => {
    try {
      const {success} = await PutSendServer(values)
      if (success) {
        AlertService.success(
          translate('SUCCESS'),
          translate('SERVER_SAVED_SUCCESSFULLY')
        )
        router.history.push('/mailConfiguration#send')
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <PageForm onSubmit={handleSubmit(onSubmit)}>
        <Row align='bottom' gutter={[0, 50]}>
          <Col xs={24}>
            <BackButton
              label={<Translate id='BACK' />}
              onClick={() => router.history.push('/mailConfiguration#send')}
            />
          </Col>
          <Col xs={12}>
            <PageTitle>
              <Translate id='SEND_SERVER' />
            </PageTitle>
          </Col>
          <Col xs={12} align='end'>
            <BaseButton htmlType='submit' type='primary' auto>
              <Translate id='SAVE' />
            </BaseButton>
          </Col>
        </Row>
        <Margin size='40' />
        <Row gutter={[40, 20]}>
          <Col xs={6}>
            <Field
              component={TextInput}
              name='name'
              label={<Translate id='NAME_1' />}
            />
          </Col>
          <Col xs={6}>
            <Field
              component={TextInput}
              name='hostname'
              label={<Translate id='HOST_NAME' />}
            />
          </Col>
          <Col xs={6}>
            <Field
              component={TextInput}
              name='imapPort'
              label={<Translate id='PORT' />}
            />
          </Col>
          <Col xs={6}>
            <InputLabel>
              <Translate id='STATUS_1' />
            </InputLabel>
            <Field
              component={SwitchInput}
              name='active'
              checkedText={<Translate id='ACTIVE' />}
              uncheckedText={<Translate id='INACTIVE' />}
            />
          </Col>
          <Col xs={6}>
            <Field
              component={TextInput}
              name='username'
              label={<Translate id='USERNAME_1' />}
            />
          </Col>
          <Col xs={6}>
            <Field
              component={TextInput}
              name='password'
              type='password'
              label={<Translate id='PASSWORD' />}
            />
          </Col>
        </Row>
      </PageForm>
    </>
  )
}

ManageSendServer.propTypes = {
  router: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  handleSubmit: PropTypes.func
}

ManageSendServer.defaultProps = {
  dispatch: undefined,
  handleSubmit: undefined
}

const ManageSendServerPage = reduxForm({
  form: 'send_server_form',
  validate: validations
})(withLocalize(ManageSendServer))

export default connect((state) => ({
  clients: state.info.clients
}))(ManageSendServerPage)
