import React, {useState} from 'react'

import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {Field, FieldArray} from 'redux-form'

import AddIcon from '../../../assets/icons/down.svg'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import PriorityInput from '../../../shared/components/inputs/PriorityInput'
import TextAreaInput from '../../../shared/components/inputs/TextAreaInput'
import GetObjectByID from '../../../shared/logic/arrays/GetObjectByID'
import {
  HeaderCollapsable,
  ContentCollapsable,
  Margin,
  StyledFlex
} from '../../../shared/styles/BasicStyles'
import RelatedUsers from './components/RelatedUsers'
import {
  Title,
  ProcessContainer,
  ProcessSubContainer,
  ProcessTitle,
  ProcessDescription,
  BottomSubContainer,
  ContentWithoutMargin,
  ProcessTabArrow,
  PriorityLabel
} from './ProcessStyles'

//   <ProcessSubContainer $noBorderBottom>
//                   <TextInput
//                     label='Associate order'
//                     input={{
//                       value: associateOrderInput,
//                       onChange: (value) => setAssociateOrderInput(value)
//                     }}
//                   />
//                   <StyledFlex
//                     style={{width: '100%', marginTop: '10px'}}
//                     $align='flex-end'
//                   >
//                     <BaseButton
//                       type='primary'
//                       size='small'
//                       onClick={associateOrder}
//                       auto
//                     >
//                       Associate
//                     </BaseButton>
//                   </StyledFlex>
//                 </ProcessSubContainer>

const ProcessTab = ({
  paymentMethods,
  process,
  loading,
  reply,
  translate
}) => {
  const [toggle, setToggle] = useState(undefined)
  return (
    <ProcessContainer $bottom={17}>
      <StyledFlex
        $direction='column'
        $justify='center'
        $top={5}
        $bottom={7}
      >
        <Title $centered>
          <Translate id='PROCESS_NMR' />
        </Title>
        <ProcessDescription $left='5'>
          {process?.number}
        </ProcessDescription>
      </StyledFlex>
      <StyledFlex
        $direction='column'
        $justify='center'
        $top={5}
        $bottom={7}
      >
        <PriorityLabel>
          <Translate id='PRIORITY' />:
        </PriorityLabel>
        {reply ? (
          <PriorityInput input={{value: process?.slaid}} disabled />
        ) : (
          <Field component={PriorityInput} name='slaid' max={4} />
        )}
      </StyledFlex>
      <StyledFlex
        $direction='column'
        $justify='center'
        $top={5}
        $bottom={7}
      >
        <PriorityLabel>
          <Translate id='STATE' />:
        </PriorityLabel>
        <ProcessDescription $left='5'>
          {process?.stateId === 1 || process?.stateId === 2 ? (
            <Translate id='UNARCHIVED' />
          ) : (
            <Translate id='ARCHIVED' />
          )}
        </ProcessDescription>
      </StyledFlex>
      <Margin size={10} />
      <ProcessSubContainer $noBorderBottom $pLeft='15' $pRight='15'>
        <HeaderCollapsable
          onClick={() =>
            setToggle(toggle !== 'information' ? 'information' : undefined)
          }
        >
          <ProcessTitle>
            <Translate id='INFORMATION' />
          </ProcessTitle>
          <ProcessTabArrow
            src={AddIcon}
            $active={toggle === 'information'}
          />
        </HeaderCollapsable>
        <ContentCollapsable open={toggle === 'information'}>
          <ContentWithoutMargin>
            <div
              style={{
                padding: 29,
                marginLeft: 5,
                marginRight: 5
              }}
            >
              <ProcessTitle>
                <Translate id='NAME' />
              </ProcessTitle>
              <ProcessDescription>
                {process?.contact?.name || <Translate id='NA' />}
              </ProcessDescription>
              <ProcessTitle $top={20}>
                <Translate id='COMPANY' />
              </ProcessTitle>
              <ProcessDescription>
                {process?.contact?.company || <Translate id='NA' />}
              </ProcessDescription>
            </div>
          </ContentWithoutMargin>
        </ContentCollapsable>
      </ProcessSubContainer>
      {process?.processOrder && (
        <ProcessSubContainer $noBorderBottom $pLeft='15' $pRight='15'>
          <HeaderCollapsable
            onClick={() =>
              setToggle(toggle !== 'order' ? 'order' : undefined)
            }
          >
            <ProcessTitle>
              <Translate id='ORDER_DETAILS' />
            </ProcessTitle>
            <ProcessTabArrow src={AddIcon} $active={toggle === 'order'} />
          </HeaderCollapsable>
          <ContentCollapsable open={toggle === 'order'}>
            <ContentWithoutMargin>
              <div
                style={{
                  padding: 29,
                  marginLeft: 5,
                  marginRight: 5
                }}
              >
                <ProcessTitle>
                  <Translate id='REF' />
                </ProcessTitle>
                <ProcessDescription>
                  {process?.processOrder?.orderId}
                </ProcessDescription>
                <ProcessTitle $top={20}>
                  <Translate id='QTY' />
                </ProcessTitle>
                <ProcessDescription $bottom='20'>
                  {process?.processOrder?.numberOfItems}
                </ProcessDescription>
                <ProcessTitle>
                  <Translate id='PAYMENT_METHOD' />
                </ProcessTitle>
                <ProcessDescription $bottom='20'>
                  {
                    GetObjectByID(
                      paymentMethods,
                      'paymentMethodId',
                      process?.processOrder?.paymentMethod
                    )?.Name
                  }
                </ProcessDescription>
                <ProcessTitle $top={20}>
                  <Translate id='PAYMENT_AMOUNT' />
                </ProcessTitle>
                <ProcessDescription $bottom='20'>
                  {process?.processOrder?.totalValue}€
                </ProcessDescription>
              </div>
            </ContentWithoutMargin>
          </ContentCollapsable>
        </ProcessSubContainer>
      )}
      <ProcessSubContainer $noBorderBottom $pLeft='15' $pRight='15'>
        <HeaderCollapsable
          onClick={() =>
            setToggle(toggle !== 'users' ? 'users' : undefined)
          }
        >
          <ProcessTitle>
            <Translate id='RELATED_USERS' />
          </ProcessTitle>
          <ProcessTabArrow src={AddIcon} $active={toggle === 'users'} />
        </HeaderCollapsable>
        <ContentCollapsable open={toggle === 'users'}>
          <ContentWithoutMargin>
            <FieldArray
              name='processUser'
              component={RelatedUsers}
              processUser={process?.processUser}
              reply={reply}
            />
          </ContentWithoutMargin>
        </ContentCollapsable>
      </ProcessSubContainer>
      <ProcessSubContainer $pLeft='15' $pRight='15'>
        <HeaderCollapsable
          onClick={() =>
            setToggle(toggle !== 'notes' ? 'notes' : undefined)
          }
        >
          <ProcessTitle>
            <Translate id='NOTES' />
          </ProcessTitle>
          <ProcessTabArrow src={AddIcon} $active={toggle === 'notes'} />
        </HeaderCollapsable>
        <ContentCollapsable open={toggle === 'notes'}>
          <ContentWithoutMargin>
            <div style={{marginLeft: 35, marginRight: 35}}>
              {reply ? (
                <ProcessDescription>
                  {process?.details ? (
                    process.details
                  ) : (
                    <Translate id='NO_NOTES' />
                  )}
                </ProcessDescription>
              ) : (
                <Field
                  name='details'
                  component={TextAreaInput}
                  placeholder={translate('WRITE_NOTE_DISPLAY_PROCESS')}
                  rows={7}
                />
              )}
            </div>
          </ContentWithoutMargin>
        </ContentCollapsable>
      </ProcessSubContainer>
      {!reply && (
        <BottomSubContainer $noBorderTop>
          <ContentWithoutMargin>
            <div
              style={{
                textAlign: 'center',
                paddingRight: '10px',
                paddingTop: '10px'
              }}
            >
              <BaseButton
                type='primary'
                htmlType='submit'
                size='small'
                auto
                loading={loading}
              >
                <Translate id='SAVE_PROCESS' />
              </BaseButton>
            </div>
          </ContentWithoutMargin>
        </BottomSubContainer>
      )}
    </ProcessContainer>
  )
}

ProcessTab.propTypes = {
  reply: PropTypes.bool,
  loading: PropTypes.bool
}

ProcessTab.defaultProps = {
  reply: false,
  loading: false
}

export default withLocalize(
  connect((state) => ({
    paymentMethods: state.info.paymentMethods
  }))(ProcessTab)
)
