import React, {Component} from 'react'

import {Col, Row, Switch} from 'antd'
import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'

import IconAdd from '../../../../assets/icons/icon_add.svg'
import {GetProductsCharacteristics, EditProductCharacteristics} from '../../../../infra/requests/MarketingRequests'
import BaseButton from '../../../../shared/components/buttons/BaseButton'
import SearchInput from '../../../../shared/components/inputs/SearchInput'
import BaseLoading from '../../../../shared/components/loading/BaseLoading'
import BaseTable from '../../../../shared/components/table/BaseTable'
import {Margin} from '../../../../shared/styles/BasicStyles'
import {
  ChildrenText,
  TableChildrenContainer
} from '../../../settings/SettingsStyles'

let timeout

class ProductCharacteristicsTab extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      rows: [],
      page: 1,
      pageSize: 12,
      total: 0,
      search: ''
    }
  }

  toggleFilterable = async (data, index) => {
    const {success} = await EditProductCharacteristics(data.characteristicId, {...data, filterable: !data.filterable})
    if (success) {
      const {rows} = this.state
      rows[index].filterable = !rows[index].filterable
      this.setState({rows})
    }
  }

  getColumns = () => [
    {
      title: <Translate id='FILTERABLE' />,
      render: (data, row, index) => (
        <div onClick={(e) => e.stopPropagation()}>
          <Switch checked={data.filterable} onClick={() => this.toggleFilterable(data, index)} />
        </div>
      ),
      width: 100
    },
    {
      dataIndex: 'characteristicId',
      type: 'text',
      title: <Translate id='ID' />,
      width: 50
    },
    {
      dataIndex: 'characteristicTranslation',
      title: <Translate id='PT' />,
      render: (value) =>
        value?.length > 0 && value.find((x) => x?.languageId === 1)?.name,
      width: 130
    },
    {
      dataIndex: 'characteristicTranslation',
      title: <Translate id='EN' />,
      render: (value) =>
        value?.length > 0 && value.find((x) => x?.languageId === 2)?.name,
      width: 130
    },
    {
      dataIndex: 'characteristicTranslation',
      title: <Translate id='FR' />,
      render: (value) =>
        value?.length > 0 && value.find((x) => x?.languageId === 3)?.name,
      width: 130
    },
    {
      dataIndex: 'characteristicTranslation',
      title: <Translate id='DE' />,
      render: (value) =>
        value?.length > 0 && value.find((x) => x?.languageId === 4)?.name,
      width: 130
    },
    {
      dataIndex: 'characteristicTranslation',
      title: <Translate id='ES' />,
      render: (value) =>
        value?.length > 0 && value.find((x) => x?.languageId === 5)?.name,
      width: 130
    },
    {
      dataIndex: 'characteristicTranslation',
      title: <Translate id='NL' />,
      render: (value) =>
        value?.length > 0 && value.find((x) => x?.languageId === 6)?.name,
      width: 130
    }
  ]

  componentDidMount = async () => {
    try {
      this.updateTable()
    } catch (error) {
      console.warn(error)
    }
  }

  updateTable = async () => {
    try {
      const {page, pageSize, search} = this.state
      this.setState({loading: true})

      const {data, success} = await GetProductsCharacteristics(
        page,
        pageSize,
        search
      )

      if (success) {
        this.setState({
          rows: data?.items,
          total: data?.totalItems,
          loading: false
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  onChangePagination = (page) => this.setState({page}, this.updateTable)

  setFilter = (search) => {
    this.setState({search, page: 1}, () => {
      if (timeout) clearTimeout(timeout)
      timeout = setTimeout(this.updateTable, 1000)
    })
  }

  render() {
    const {loading, rows, page, pageSize, total, search} = this.state
    const {router} = this.props

    if (loading) return <BaseLoading />
    return (
      <>
        <Row gutter={[24, 24]}>
          <Col xs={6}>
            <BaseButton
              auto
              imagePath={IconAdd}
              onClick={() => router.history.push('marketing/product/new')}
            >
              <Translate id='ADD_NEW' />
            </BaseButton>
          </Col>
          <Col xs={6} offset={12}>
            <SearchInput
              input={{
                value: search,
                onChange: this.setFilter,
                onSearch: this.updateTable
              }}
            />
          </Col>
        </Row>
        <Margin size={17} />
        <Row>
          <Col xs={24}>
            <BaseTable
              rowKey='characteristicId'
              datasource={rows}
              columns={this.getColumns()}
              pagination={{
                render: true,
                page,
                pageSize,
                total,
                onChangePagination: this.onChangePagination
              }}
              onClickRow={(row) => {
                router.history.push(
                  `/marketing/product/${row.characteristicId}`
                )
              }}
              expandable={{
                expandedRowRender: (record) => (
                  <>
                    {record?.characteristicValue.map((value, index) => (
                      <TableChildrenContainer key={index}>
                        <ChildrenText width={60}>
                          {value?.characteristicValueId}
                        </ChildrenText>
                        <ChildrenText width={153}>
                          {
                              value?.characteristicValueTranslation?.find(
                                (x) => x?.languageId === 1
                              )?.name
                          }
                        </ChildrenText>
                        <ChildrenText width={154}>
                          {
                              value?.characteristicValueTranslation?.find(
                                (x) => x?.languageId === 2
                              )?.name
                          }
                        </ChildrenText>
                        <ChildrenText width={155}>
                          {
                              value?.characteristicValueTranslation?.find(
                                (x) => x?.languageId === 3
                              )?.name
                          }
                        </ChildrenText>
                        <ChildrenText width={154}>
                          {
                              value?.characteristicValueTranslation?.find(
                                (x) => x?.languageId === 4
                              )?.name
                          }
                        </ChildrenText>
                        <ChildrenText width={155}>
                          {
                              value?.characteristicValueTranslation?.find(
                                (x) => x?.languageId === 5
                              )?.name
                          }
                        </ChildrenText>
                        <ChildrenText width={145}>
                          {
                              value?.characteristicValueTranslation?.find(
                                (x) => x?.languageId === 6
                              )?.name
                          }
                        </ChildrenText>
                      </TableChildrenContainer>
                    ))}
                  </>
                )
              }}
              colorEx='#F1EFEF'
            />
          </Col>
        </Row>
      </>
    )
  }
}

ProductCharacteristicsTab.propTypes = {
  router: PropTypes.object.isRequired
}

export default ProductCharacteristicsTab
